import { useRef } from 'react';
import './EditPayments.scss';
import { translate } from '../../../../../Module/Translate/Translate';
import { H5 } from '../../../../../Component/Text/Text';
import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { PaymentStatusEnum } from '../../../../../../../types/payments/enums/payment-status.enum';
import { editPaymentStatusAction } from '../../../../../Network/Actions/Payment';

export default function EditPayments({ editor, payments, setRefresh }: any) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      status: payments ? payments.status : '',
    },

    onSubmit: (values: any) => {
      editPaymentStatusAction(payments.id, {
        status: values.status,
        updatedBy: editor.email,
      })
        .then(() => {
          setRefresh(true);
          showMessage('success', translate('default.message.success'));
        })
        .catch((error: any) => {
          showMessage('error', translate('default.message.fail') + error);
        });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const statuses = [
    {
      label: translate('adminTool.payments.success'),
      value: PaymentStatusEnum.success,
    },
    {
      label: translate('adminTool.payments.failed'),
      value: PaymentStatusEnum.failed,
    },
    {
      label: translate('adminTool.payments.pending'),
      value: PaymentStatusEnum.pending,
    },
    {
      label: translate('adminTool.payments.cancelled'),
      value: PaymentStatusEnum.cancelled,
    },
  ];

  return (
    <div className="default-settings-main-container border-details">
      <div className="default-settings-header-container">
        <Messages ref={message} />
      </div>
      <div className="default-settings-data-container">
        <div className="default-settings-top-container">
          <div className="default-settings-info-left-container">
            <H5 className="default-settings-h5">
              {translate('adminTool.payments.orderId')}
            </H5>
            <p>{payments.orderId}</p>
          </div>
          <div className="default-settings-info-right-container">
            <form
              className="default-settings-form"
              onSubmit={formik.handleSubmit}
            >
              <label className="rents-h5">Status</label>
              <Dropdown
                value={formik.values.status}
                options={statuses}
                className="type-dropdown"
                name="status"
                optionLabel="label"
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />

              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.values.status !== payments.status ? '' : 'inactive'
                }`}
              >
                {translate('adminTool.defaultSettings.btn')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
