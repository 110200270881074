import Axios from 'axios';
import { translate } from '../Module/Translate/Translate';
import { store } from '../store';
import { SERVER_NETWORK_ERROR } from './Actions/Types';
import * as envConfig from '../../envConfig';
const baseURL = envConfig.SERVER_URL + '/api';
const axios = Axios.create({
  baseURL: baseURL,
  timeout: 60000,
  headers: {},
  withCredentials: true,
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.message === translate('errors.networkError')) {
      store.dispatch({
        type: SERVER_NETWORK_ERROR,
        payload: error.message,
      });
    }
    if (error.response?.status === 401) {
      logout();
    }
    throw error;
  },
);

async function logout() {
  await axios.get(baseURL + '/auth/logout').then(() => {
    store.dispatch({
      type: 'USER_LOGOUT',
      payload: {},
    });
  });
}

export const http = axios;
export default axios;
