import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import plus from '../../../../../Asset/images/faq/plus.png';
import minus from '../../../../../Asset/images/faq/minus.png';
import './QuestionAndAnswer.scss';
import { textFormater } from '@util/TextFormater';

type questionAndAnswerProps = {
  question: string;
  answer: any;
  type: string;
  id: string;
  subAnswers?: any;
  link?: any;
  exLink?: any;
  onClick: (id: string, type: string) => void;
  active: boolean;
};

const getSubAnswerContent = (
  id: string,
  type: string,
  subAnswers: any,
  redirect: any,
  link: any,
  exLink: any,
): JSX.Element => {
  if ((id === 'q1' || id === 'q4') && type === 'general') {
    return (
      <React.Fragment>
        <span onClick={() => redirect(link)} className="answer_content_link">
          {subAnswers[0]}
        </span>
        <span>{subAnswers[1]}</span>
      </React.Fragment>
    );
  } else if (id === 'q3' && type === 'orders') {
    return (
      <React.Fragment>
        <a
          className="answer_content_link"
          href={exLink}
          target="_blank"
          rel="noreferrer"
        >
          {subAnswers[0]}
        </a>
        <span> {subAnswers[1]}</span>
        <span onClick={() => redirect(link)} className="answer_content_link">
          {subAnswers[2]}
        </span>
        <span> {subAnswers[3]}</span>
      </React.Fragment>
    );
  } else if ((id === 'q8' || id === 'q9') && type === 'delivery-and-return') {
    return (
      <React.Fragment>
        <a
          className="answer_content_phrase"
          href={exLink}
          target="_blank"
          rel="noreferrer"
        >
          {subAnswers[0]}
        </a>
      </React.Fragment>
    );
  } else if (id === 'q1' && type === 'boxes') {
    return (
      <React.Fragment>
        <span onClick={() => redirect(link)} className="answer_content_link">
          {subAnswers[0]}
        </span>
        <span> {subAnswers[1]}</span>
      </React.Fragment>
    );
  }

  return <></>;
};

const QuestionAndAnswer = ({
  question,
  answer,
  type,
  id,
  subAnswers,
  link,
  exLink,
  onClick,
  active,
}: questionAndAnswerProps): JSX.Element => {
  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const [questionExpanded, setQuestionExpanded] = useState(false);
  const [questionHeight, setQuestionHeight] = useState('0px');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    onIconClickHandler(isActive);
  }, [isActive]);

  const content = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const onIconClickHandler = (isActive: boolean) => {
    setQuestionExpanded(isActive);
    isActive
      ? setQuestionHeight(
          questionHeight === '0px'
            ? `${content.current?.scrollHeight}px`
            : '0px',
        )
      : setQuestionHeight('0px');
  };

  const redirect = (path: string) => {
    history.push(`/${path}`);
  };

  return (
    <div className="faq-question">
      <div
        className="faq-question_container"
        onClick={() => {
          isActive ? onClick((id = ''), (type = '')) : onClick(id, type);
        }}
      >
        <div className="question">
          <h5 className="question_content">{question}</h5>
          <img
            className="question_icon"
            src={questionExpanded ? minus : plus}
            alt={questionExpanded ? 'minus' : 'plus'}
          />
        </div>
        <div
          ref={content}
          style={{
            maxHeight: `${questionHeight}`,
            borderTop: questionExpanded ? '1px solid #f3c8a1' : 'none',
          }}
          className="answer"
        >
          <p className="answer_content">
            {textFormater(answer)}

            {subAnswers &&
              getSubAnswerContent(id, type, subAnswers, redirect, link, exLink)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuestionAndAnswer;
