import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import RouterSwitch from './Router.Switch';
import ScrollToTop from '../../Util/ScrollToTop';
import { setGAPageview } from '../../Util/useGAEventTracker';

const Router = () => {
  useEffect(() => {
    setGAPageview();
  }, [window.location.pathname]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <RouterSwitch />
    </BrowserRouter>
  );
};

export default Router;
