import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Users.scss';
import { translate } from '@module/Translate/Translate';
import { formatDateToLocalTime } from '@util/Dates';
import { InputText } from 'primereact/inputtext';
import useDebounce from '@util/Debounse';
import { getLoginAttempts } from '@network/Actions/Users';

const LIMIT_ON_PAGE = 7;

export default function LoginAttempts() {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const [search, setSearch] = useState('');
  const [data, setData] = useState({
    items: [],
    meta: { totalItems: 0 },
  });

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
  });

  useEffect(() => {
    if (mount) {
      getData(lazyParams);
      setMount(false);
    } else if (refresh) {
      getData(lazyParams);
      setRefresh(false);
    } else {
      getData(lazyParams);
    }
  }, [refresh, lazyParams]);

  const getData = (params: any) => {
    setLoading(true);
    getLoginAttempts(params)
      .then((data) => setData(data))
      .finally(() => setLoading(false));
  };

  const onPage = (event: any) => {
    setLazyParams({ ...event, value: search });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event, value: search });
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'address') {
      return `${(rowData.address && ',') || ''} ${
        (rowData.city && ',') || ''
      } ${rowData.country ?? ''}`;
    }
    if (item.field === 'position') {
      return `${(rowData.longitude && 'x') || ''}${rowData.latitude ?? ''}`;
    }
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLazyParams({
      first: 0,
      rows: LIMIT_ON_PAGE,
      sortField: '',
      sortOrder: null,
    });
    setSearch(value);
  };

  useEffect(() => {
    if (search !== '') {
      searchData();
    } else {
      setLazyParams({
        first: 0,
        rows: LIMIT_ON_PAGE,
        sortField: '',
        sortOrder: null,
      });
      setRefresh(true);
    }
  }, [useDebounce(search, 500)]);

  const searchData = () => {
    setLoading(true);
    getLoginAttempts({
      ...lazyParams,
      value: search,
    })
      .then((data) => setData(data))
      .finally(() => setLoading(false));
  };

  return (
    <div className="users-container">
      <InputText
        value={search}
        onChange={onGlobalFilterChange}
        placeholder={translate('adminTool.common.searchByEmail')}
        title={translate('adminTool.common.searchByEmail')}
      />
      <DataTable
        value={data.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={loading}
        first={lazyParams.first}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        currentPageReportTemplate={translate(
          'adminTool.users.currentPageReportTemplate',
        )}
        rows={LIMIT_ON_PAGE}
        totalRecords={data.meta.totalItems}
        onPage={onPage}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
      >
        <Column field="id" sortable header={translate('adminTool.users.id')} />
        <Column
          field="loginName"
          sortable
          header={translate('adminTool.users.email')}
        />
        <Column field="ip" sortable header="ip" />
        <Column field="status" sortable header="status" />
        <Column field="address" sortable header="user-agent" />
        {/* <Column
          field="position"
          sortable
          header="position"
          body={labelChecker}
        /> */}

        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.users.createdAt')}
        />
        <Column
          field="updatedAt"
          body={formatDateToLocalTime}
          sortable
          header={translate('adminTool.users.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.users.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
