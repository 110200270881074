import React, {
  useEffect,
  useCallback,
  FunctionComponent,
  useState,
  useRef,
} from 'react';
import { useSpring, animated } from 'react-spring';
import ModalI from './Modal.type';
import './Modal.scss';
import { translate } from '../../Module/Translate/Translate';
import arrowIcon from '../../Asset/images/modal/arrowIcon.png';
import closeIcon from '../../Asset/images/modal/closeIcon.jpg';

export const Modal: FunctionComponent<React.PropsWithChildren<ModalI>> = (
  props,
) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [showContent, setShowContent] = useState(false);
  const duration = 250;

  const showModal = props.showModal;
  const setShowModal = props.setShowModal;
  const closeCallback = props.closeCallback;

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  window.addEventListener('resize', () =>
    setIsDesktop(window.innerWidth > 768),
  );

  const animStartPosition = isDesktop ? 'translateY(0%)' : 'translateX(0%)';
  const animEndPosition = isDesktop ? 'translateY(-100%)' : 'translateX(100%)';

  const animation = useSpring({
    config: {
      duration: duration,
    },
    opacity: showModal ? 1 : isDesktop ? 0 : 1,
    transform: showModal ? animStartPosition : animEndPosition,
  });

  useEffect(() => {
    if (showModal) {
      const body = document.querySelector('body');
      if (body) {
        body.classList.add('modal-open');
      }

      setShowContent(true);
    } else {
      setTimeout(() => {
        closeModal();
      }, duration);
    }
  }, [showModal]);

  const closeModal = () => {
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove('modal-open');
    }

    if (closeCallback && showContent) {
      closeCallback();
    }

    setShowContent(false);
  };

  const keyPress = useCallback(
    (e: any) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  //close modal by swipe
  let touchstartX = 0;
  let touchendX = 0;
  function handleGesture() {
    if (touchendX - touchstartX > 100 && !isDesktop) {
      setShowModal(false);
    }
  }

  return (
    <>
      {showContent ? (
        <>
          <div
            className={`dialog-mask ${props.dialogClassName || ''}`}
            ref={modalRef}
            onClick={(e) => {
              if (modalRef.current === e.target) {
                setShowModal(false);
              }
            }}
          >
            <animated.div
              className="d-md-flex"
              style={animation}
              onTouchStart={(e) => {
                touchstartX = e.changedTouches[0].screenX;
              }}
              onTouchEnd={(e) => {
                touchendX = e.changedTouches[0].screenX;
                handleGesture();
              }}
            >
              <div className={`dialog_wrapper ${props.className || ''}`}>
                <div
                  className="dialog_close"
                  data-e2e="close-modal"
                  aria-label={translate('modal.closeModal')}
                  onClick={() => setShowModal(false)}
                >
                  <img
                    className="d-none d-md-block"
                    data-e2e="close-modal-img"
                    src={closeIcon}
                    alt={translate('modal.closeModal')}
                  />
                  <img
                    className="d-block d-md-none"
                    src={arrowIcon}
                    alt={translate('modal.closeModal')}
                  />
                </div>

                <div className="dialog_content">{props.children}</div>
              </div>
            </animated.div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
