import {
  CURRENT_ORDER_FULFILLED,
  CURRENT_ORDER_PENDING,
  CURRENT_ORDER_REJECTED,
} from '../Network/Actions/Types';
import { CurrentOrderState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const currentOrderReducer = (
  state: CurrentOrderState = { loading: false, order: {}, error: null },
  action: Action,
) => {
  switch (action.type) {
    case CURRENT_ORDER_PENDING:
      return { loading: true, order: state.order, error: null };
    case CURRENT_ORDER_FULFILLED:
      localStorage.setItem('currentOrder', JSON.stringify(action.payload));
      return { loading: false, order: action.payload, error: null };
    case CURRENT_ORDER_REJECTED:
      return { loading: false, order: state.order, error: action.payload };
    default:
      return state;
  }
};
