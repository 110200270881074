import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { onMessageListener } from '../../firebaseInit';
import P from '../Text/Text';
import './PushNotification.scss';
import { PNMessageData } from '../../../../types/push-notifications/push-notification-message.dto';
import logoImg from '../../Asset/images/favicon.png';

const PushNotification = () => {
  const [notification, setNotification] = useState({ title: '', body: '' });

  const notify = () =>
    toast(<Message />, {
      closeOnClick: true,
      autoClose: false,
      containerId: 'notification',
    });

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  onMessageListener()
    .then((payload: any) => {
      const data: PNMessageData = payload.notification;
      if (data) {
        setNotification({
          title: data.title,
          body: `${data.body}`,
        });
      }
    })
    .catch((error) =>
      console.error({
        service: PushNotification,
        method: onMessageListener,
        error: error,
      }),
    );

  const Message = () => (
    <div className="notify-container">
      <div>
        <img className="notify-logo" alt="logo" src={logoImg} />
      </div>
      <div className="notify">
        <P className="notify_message">
          <b>{notification?.title}</b>
        </P>
        <P className="notify_message">{notification?.body}</P>
      </div>
    </div>
  );

  return (
    <div className="notification">
      <ToastContainer
        className="notification-toast"
        position="top-center"
        closeOnClick
        autoClose={false}
        containerId="notification"
        enableMultiContainer
      />
    </div>
  );
};

export default PushNotification;
