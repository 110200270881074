import React, { useRef, useState } from 'react';
import './FormPolicy.scss';
import { translate } from '../../../../../Module/Translate/Translate';
import { useFormik } from 'formik';
import { FormFieldFormik } from '../../../../../Component/FormFieldFormik/FormFieldFormik';
import { Dropdown } from 'primereact/dropdown';
import { PolicyEnum } from '../../../../../../../types/policies/policy-type.enum';
import { PolicyStatus } from '../../../../../../../types/policies/policy-status.enum';
import { enumToOptions } from '../../../../../Util/EnumToOptions';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { Panel } from 'primereact/panel';
import { FileUpload } from 'primereact/fileupload';
import { User } from '../../../../../models/user.model';

interface Props {
  setPolicy: any;
  updatePolicy?: any;
  policy?: any;
  editor: User;
}
export default function FormPolicy({
  setPolicy,
  updatePolicy,
  policy,
  editor,
}: Props) {
  const fileUploadRef = useRef(null);
  const [detailsCollapsed, setDetailsCollapsed] = useState(!!policy);
  const [file, setFile] = useState();

  const formik = useFormik({
    initialValues: {
      status: policy ? policy.status : PolicyStatus.Draft,
      type: policy ? policy.type : PolicyEnum.Privacy,
      title: policy ? policy.title : '',
      content: policy ? policy.content : '',
      updatedBy: editor.email,
    },

    onSubmit: (values: any) => {
      if (!policy) {
        setPolicy(values);
      } else {
        updatePolicy({ id: policy.id, ...values }, file);
      }
    },
  });

  const fileUploader = (event: any) => {
    setFile(event.files[event.files.length - 1]);
  };

  const itemTemplate = (file: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <span
          style={{ fontSize: '1.0em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          {translate('adminTool.products.drag')}
        </span>
      </div>
    );
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
  };
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  const options = {
    statuses: enumToOptions(PolicyStatus),
    types: enumToOptions(PolicyEnum),
  };

  const checkIsValid = () => {
    return formik.isValid && formik.values.title !== '';
  };

  return (
    <div className="policies-main-container border-details">
      <label className="rents-h5">Type</label>
      <Dropdown
        value={formik.values.type}
        options={options.types}
        className="type-dropdown"
        name="type"
        optionLabel="label"
        optionValue="value"
        onChange={formik.handleChange}
        itemTemplate={(option) => {
          return <span>{option.label}</span>;
        }}
      />
      {policy && (
        <React.Fragment>
          <label className="rents-h5">Status</label>
          <Dropdown
            value={formik.values.status}
            options={options.statuses}
            className="type-dropdown"
            name="status"
            optionLabel="label"
            optionValue="value"
            onChange={formik.handleChange}
            itemTemplate={(option) => {
              return <span>{option.label}</span>;
            }}
          />
        </React.Fragment>
      )}
      <FormFieldFormik
        label="Title"
        name="title"
        type="text"
        value={formik.values.title}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {policy && (
        <>
          <FileUpload
            ref={fileUploadRef}
            name="demo"
            accept="file_pdf"
            maxFileSize={10000000}
            multiple={false}
            auto
            customUpload
            uploadHandler={fileUploader}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
          />
          <p>{`Wgrany plik pdf: ${
            policy.fileUrl ? policy.fileUrl : 'brak'
          }`}</p>
        </>
      )}
      <Panel
        header="Treść regulaminu"
        toggleable
        collapsed={detailsCollapsed}
        onToggle={() => setDetailsCollapsed(!detailsCollapsed)}
      >
        <DefaultEditor
          className="policies-editor_textarea form-control mx-auto my-4"
          disabled={
            formik.values.status === PolicyStatus.Published ? true : false
          }
          //style={{ height: '31.25rem' }}
          value={formik.values.content}
          name="content"
          onChange={formik.handleChange}
        />
      </Panel>
      <button
        type="submit"
        form="policy-form"
        onClick={() => formik.handleSubmit()}
        className={`submitForm m-auto button_accept ${
          checkIsValid() ? '' : 'inactive'
        }`}
      >
        {policy ? translate('buttons.save') : translate('buttons.add')}
      </button>
    </div>
  );
}
