import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLimitsAction } from '../../../../Network/Actions/Limits';
import { RootState } from '../../../../store';
import { LimitsState } from '../../../../Type/Custom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Limits.scss';
import EditLimit from './components/EditLimits';
import { translate } from '../../../../Module/Translate/Translate';
import { UserProps } from '../../interfaces/interfaces';
import { formatDateToLocalTime } from '../../../../Util/Dates';

const Limits = ({ user }: UserProps) => {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { limits }: LimitsState = useSelector<RootState, LimitsState>(
    ({ limits }) => limits,
  );

  useEffect(() => {
    getData();
    setRefresh(false);
  }, [refresh]);

  const getData = async () => {
    await dispatch(getLimitsAction());
  };

  const rowExpand = (data: any) => {
    return <EditLimit limit={data} setRefresh={setRefresh} editor={user} />;
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'status') {
      return rowData[item.field]
        ? translate('adminTool.limits.statusActive')
        : translate('adminTool.limits.statusUnActive');
    }
  };

  return (
    <div className="limits-table-container">
      <DataTable
        value={limits}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nolimit')}
        className="datatable-responsive"
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpand}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="id" sortable header="Id" />
        <Column field="type" header={translate('adminTool.limits.type')} />
        <Column
          field="status"
          header={translate('adminTool.limits.status')}
          body={labelChecker}
        />
        <Column
          field="defaultAmount"
          header={translate('adminTool.limits.defaultAmount')}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.limits.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.limits.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.limits.updatedBy')}
        />
      </DataTable>
    </div>
  );
};

export default Limits;
