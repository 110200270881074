import React from 'react';
import './Info.scss';
import P from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { Link } from 'react-router-dom';

interface IProps {
  setModal: (value: boolean) => void;
}

export default function Info({ setModal }: IProps) {
  return (
    <div className="info-container">
      <P className="info-container_text">
        {translate('contactForm.removeAccount.message')}
        <Link to={`tel:${translate('footer.contactNumber')}`}>
          {translate('footer.contactNumber')}
        </Link>
      </P>
      <button
        className="info-container_button btn btn-100"
        onClick={() => setModal(true)}
      >
        {translate('contactForm.removeAccount.title')}
      </button>
    </div>
  );
}
