import { formatDateToLocalTime } from '@util/Dates';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import Spinner from '../../../../../../Component/Spinner/Spinner';
import { translate } from '../../../../../../Module/Translate/Translate';
import { http } from '../../../../../../Network/NetworkHTTP';

const LIMIT_ON_PAGE = 3;

export default function StatusesHistoryTable(props: { orderNo: string }) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [statusesList, setStatusesList] = useState<any>();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    sortField: 'createdAt',
    sortOrder: null,
  });

  const getData = async () => {
    try {
      setIsLoading(true);
      const { data } = await http.get(
        `statuses/admin-order?take=${LIMIT_ON_PAGE}&skip=${
          lazyParams.first
        }&orderNo=${props.orderNo}&sortField=${
          lazyParams.sortField
        }&sortOrder=${
          lazyParams.sortOrder === null ? 1 : lazyParams.sortOrder
        }`,
      );
      setStatusesList(data);
      setIsLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [lazyParams.sortField, lazyParams.sortOrder, lazyParams.first]);

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };
  if (statusesList)
    return (
      <DataTable
        value={statusesList.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={isLoading}
        first={lazyParams.first}
        totalRecords={statusesList.meta.totalItems}
        stripedRows
        selectionPageOnly
        emptyMessage="No data found"
        className="datatable-responsive"
        rows={LIMIT_ON_PAGE}
        onPage={onPage}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
      >
        <Column
          field="id"
          sortable
          header={translate('adminTool.orders.orderId')}
        />
        <Column
          field="date"
          sortable
          header={translate('adminTool.orders.date')}
          body={formatDateToLocalTime}
        />
        <Column
          field="status"
          sortable
          header={translate('adminTool.orders.status')}
        />
        <Column
          field="origin"
          sortable
          header={translate('adminTool.orders.origin')}
        />
        <Column
          field="description"
          sortable
          header={translate('adminTool.orders.description')}
        />
        <Column
          field="createdAt"
          sortable
          header={translate('adminTool.orders.createdAt')}
          body={formatDateToLocalTime}
        />
        <Column
          field="updatedAt"
          sortable
          header={translate('adminTool.orders.updatedAt')}
          body={formatDateToLocalTime}
        />
        <Column
          field="createdBy"
          sortable
          header={translate('adminTool.orders.createdBy')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.orders.updatedBy')}
        />
      </DataTable>
    );
  else return <Spinner />;
}
