import { translate } from '@module/Translate/Translate';
import React from 'react';
import { IRegisterUserInterface } from './interfaces/RegisterUserInterface';

export default function UserNameInpt({
  formik,
  userType,
}: IRegisterUserInterface) {
  return (
    <div className="mb-lg-3">
      <label
        htmlFor="first_name"
        className="d-sm-block font-weight-bold label--orange"
      >
        <span
          className={`visible-for-individual ${
            formik.touched.firstName && formik.errors.firstName
              ? 'error-label'
              : ''
          }`}
        >
          {userType === 'individual'
            ? translate('registrationForm.firstName')
            : translate('registrationForm.companyName')}
        </span>
      </label>
      <input
        type="text"
        name={userType === 'individual' ? 'firstName' : 'name'}
        className={`custom-input ${
          formik.touched.firstName && formik.errors.firstName
            ? 'error-input'
            : ''
        }`}
        id={userType === 'individual' ? 'firstName' : 'name'}
        autoComplete="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={
          userType === 'individual'
            ? translate('registrationForm.firstName')
            : translate('registrationForm.companyName')
        }
        value={
          userType === 'individual'
            ? formik.values.firstName
            : formik.values.name
        }
      />
      {userType === 'individual' ? (
        formik.touched.firstName && formik.errors.firstName ? (
          <div className="error-feedback">{`${formik.errors.firstName}`}</div>
        ) : null
      ) : formik.touched.name && formik.errors.name ? (
        <div className="error-feedback">{`${formik.errors.name}`}</div>
      ) : null}
    </div>
  );
}
