import React, { useEffect, useState } from 'react';
import LoginUser from './components/LoginUser/LoginUser';
import RegistrationUser from './components/RegistrationUser/RegistrationUser';
import './AuthenticationStep.scss';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import { checkoutEvent } from '@src/GTM/GTM';

const AuthenticationStep = ({ authenticationType }: any) => {
  const [authentication, setAuthentication] = useState(authenticationType);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    checkoutEvent(2);
  }, []);

  function changeAuthentication(value: string) {
    setAuthentication(value);
    window.history.pushState(
      null,
      '',
      value === 'login' ? '/logowanie' : '/rejestracja',
    );
  }

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.orderCannotCreate')}
          activeContactLink={false}
        />
      </Modal>
      <div className="authentication-step">
        <div className="d-flex d-md-none authentication-step_navigation">
          <button
            className={`authentication-step_button ${
              authentication === 'login' ? 'active' : ''
            }`}
            onClick={() => changeAuthentication('login')}
          >
            {translate('registrationUser.loginPanel.logined')}
          </button>
          <button
            className={`authentication-step_button ${
              authentication === 'register' ? 'active' : ''
            }`}
            onClick={() => changeAuthentication('register')}
          >
            {translate('registrationUser.registrationPanel.register')}
          </button>
        </div>

        {authentication === 'register' ? (
          <RegistrationUser
            changeAuthentication={changeAuthentication}
            showError={(value) => setShowError(value)}
          />
        ) : (
          <LoginUser
            changeAuthentication={changeAuthentication}
            showError={(value) => setShowError(value)}
          />
        )}
      </div>
    </>
  );
};

export default AuthenticationStep;
