import getApiEndPoint from '@network/NetworkApiEndpoint';
import { http } from '@network/NetworkHTTP';
import { OrderValidation } from '@rootTypes/orders/order-validation.enum';
import { CreatePaymentResponseDTO } from '@rootTypes/payments/dto/create-payment-response.dto';
import { CreatePaymetDTO } from '@rootTypes/payments/dto/create-payment.dto';
import { CreateTransactionResponseDto } from '@rootTypes/payments/dto/create-transaction-response.dto';
import { CreateTransactionDto } from '@rootTypes/payments/dto/create-transaction.dto';
import { PaymentTypeEnum } from '@rootTypes/payments/enums/payment-type.enum';
import { RootState } from '@src/store';
import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { createPayment, registerTransaction } from './Payment';
import { ORDER_REQUEST, ORDER_FAIL, ORDER_SUCCESS } from './Types';

export const orderAction = (
  order: any,
  isTransportsAmortizationBasket: boolean,
  onSuccess: (
    transactionToken: string,
    cardRedirect: boolean,
    order: any,
    paymentId: number,
  ) => void,
  onError: (error: string) => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ORDER_REQUEST,
      });
      const endpoint = getApiEndPoint('orders');
      await http
        .post(endpoint, order)
        .then((res: any) => {
          if (!res.data) {
            dispatch({
              type: ORDER_FAIL,
              payload: res.response.data.error,
            });
            onError(res.response.data.error.message);
          } else {
            const createPaymentEndpoint = getApiEndPoint('payments');
            const payment: CreatePaymetDTO = {
              type: PaymentTypeEnum.single,
              amount: Math.ceil(+res.data.amount * 100),
              description: res.data.id,
              orderId: res.data.id,
              method: order.paymentType,
              period: 0,
            };

            const transactionEndpoint = getApiEndPoint('transaction');

            createPayment(createPaymentEndpoint, payment).then(
              (paymentResponse: AxiosResponse<CreatePaymentResponseDTO>) => {
                const transaction: CreateTransactionDto = {
                  transaction: {
                    //do dyskusji
                    amount: Math.ceil(+res.data.amount * 100),
                    description: res.data.id,
                    email: order.email,
                    method: order.paymentType,
                  },
                  paymentId: paymentResponse.data.id,
                };
                registerTransaction(transactionEndpoint, transaction).then(
                  (res: AxiosResponse<CreateTransactionResponseDto>) => {
                    if (res.data.transactionToken) {
                      onSuccess(
                        res.data.transactionToken,
                        false,
                        order,
                        paymentResponse.data.id,
                      );
                    }
                    if (res.data.redirectUrl) {
                      onSuccess(
                        res.data.redirectUrl,
                        true,
                        order,
                        paymentResponse.data.id,
                      );
                    }
                  },
                );
              },
            );

            dispatch({
              type: ORDER_SUCCESS,
              payload: res.data,
            });
          }
        })
        .catch((err: any) => {
          dispatch({
            type: ORDER_FAIL,
            payload: onError(OrderValidation.CANNOT_CREATE),
          });
        });
    } catch (err) {
      onError(OrderValidation.CANNOT_CREATE);
      dispatch({
        type: ORDER_FAIL,
        payload: 'Przepraszamy ale serwis niedostępny. Spróbuj później.',
      });
    }
  };
};

export async function editOrderAction(orderId: number, payload: any) {
  try {
    return await http.patch(`orders/${orderId}`, payload);
  } catch (error) {
    throw error;
  }
}

export async function createWMSOrderAction(orderId: string) {
  return await http.post(`orders/wms/create/${orderId}`);
}

export async function editOrderStocksEndDate(orderId: number, payload: any) {
  return await http.patch(`orders/stocks/${orderId}`, payload);
}
