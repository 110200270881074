import { useEffect, useCallback, useState } from 'react';
import SingleOrder from './components/SingleOrder';
import './OrderHistory.scss';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import P, { H1, H3 } from '@component/Text/Text';
import { getUserOrdersAction } from '@network/Actions/Orders';
import { FindOrderDto } from '@rootTypes/orders/find-order.dto';
import { RootState } from '@src/store';
import { OrdersState } from '@type/Custom';

interface Props {
  userId: number;
}

const OrderHistory = ({ userId }: Props) => {
  const { orders } = useSelector<RootState, OrdersState>(
    (state) => state.orders,
  );
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    dispatch(getUserOrdersAction(userId, error));
  }, []);

  const error = () => {
    setShowError(true);
  };

  const renderItems = useCallback(() => {
    return (
      orders &&
      orders.length > 0 &&
      orders.map((order: FindOrderDto, index) => (
        <div key={index}>
          <SingleOrder order={order} />
        </div>
      ))
    );
  }, [orders]);

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <div className="orderHistory">
        <div className="container-header orderHistory_titleWrapper">
          <H1 className="container-title titleWrapper__title">
            orderHistory.header
          </H1>
          <P className="container-subtitle titleWrapper__text">
            orderHistory.subheader
            <br className="d-lg-none" />
            orderHistory.yourOrders
          </P>
        </div>

        <div className="orderHistory_headers">
          <H3 className="headers_title">orderHistory.orderNumberHeader</H3>
          <H3 className="headers_title headers_title-date">
            orderHistory.dateTitle
          </H3>
          <H3 className="headers_title headers_title-service">
            orderHistory.service
          </H3>
          <span className="line orange-border" />
        </div>

        <div className="orderHistory_singleOrderWrapper">{renderItems()}</div>
      </div>
    </>
  );
};

export default OrderHistory;
