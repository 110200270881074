import { SERVER_URL } from '@/envConfig';
import P, { H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import {
  HeaderStorageDescription,
  HeaderStorageTypeOfLink,
} from '@util/HeaderStorageDescription';
import { useState } from 'react';
import './HidingPlaceElementHeader.scss';
interface IProps {
  title: string;
  firstBtnHandleClick: () => void;
  firstBtnText: string;
  firstBtnDisabled?: boolean;
  date?: string;
  secondButton?: boolean;
  secondBtnHandleClick?: () => void;
  secondBtnText?: string;
  darkFirstButton?: boolean;
}
export const HeaderStorage = ({
  title,
  firstBtnDisabled,
  firstBtnHandleClick,
  firstBtnText,
  secondBtnHandleClick,
  secondBtnText,
  secondButton,
  date,
  darkFirstButton,
}: IProps) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  window.addEventListener('resize', () =>
    setIsDesktop(window.innerWidth > 768),
  );
  const componentTitle = `hidingPlaceBlock.hidingPlaceElement.orderTitle${title}`;
  const downloadInstruction = () => {
    return `${SERVER_URL}/api/utils/additional/instrukcja.pdf`;
  };
  const downloadInstructionReturn = () => {
    return `${SERVER_URL}/api/utils/additional/instrukcja-zwrotu.pdf`;
  };
  return (
    <div className="header-storage">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="title-with-date">
          <H3 className="subtitle-title">
            {componentTitle}
            {date && date}
          </H3>
        </div>
        <P className="subtitle-text">
          {HeaderStorageDescription(title) ? (
            <div>
              <span className="subtitle-text">
                {translate(
                  `hidingPlaceBlock.hidingPlaceElement.orderSubtitle${title}`,
                )}{' '}
              </span>
              <span className="subtitle-link">
                {HeaderStorageTypeOfLink(title) ? (
                  <a
                    href="https://www.youtube.com/watch?v=oTCe0N46FNE&ab_channel=Skitraj%E2%80%A4pl"
                    target="youtube"
                  >
                    {translate(`hidingPlaceBlock.hidingPlaceElement.tutorial`)}
                  </a>
                ) : (
                  <a
                    href="https://www.youtube.com/watch?v=Dr64RJHwC3A&ab_channel=Skitraj%E2%80%A4pl"
                    target="youtube"
                  >
                    {translate(`hidingPlaceBlock.hidingPlaceElement.tutorial`)}
                  </a>
                )}
              </span>
              <span className="subtitle-text">
                {translate('hidingPlaceBlock.hidingPlaceElement.or')}
              </span>

              <span>
                {HeaderStorageTypeOfLink(title) ? (
                  <a
                    className="subtitle-link"
                    href={downloadInstructionReturn()}
                    target="instruction"
                    data-e2e="instruction"
                  >
                    <span>
                      {translate(
                        'hidingPlaceBlock.hidingPlaceElement.instruction',
                      )}
                    </span>
                  </a>
                ) : (
                  <a
                    className="subtitle-link"
                    href={downloadInstruction()}
                    target="instruction"
                    data-e2e="instruction"
                  >
                    <span>
                      {translate(
                        'hidingPlaceBlock.hidingPlaceElement.instruction',
                      )}
                    </span>
                  </a>
                )}
              </span>
            </div>
          ) : (
            translate(
              `hidingPlaceBlock.hidingPlaceElement.orderSubtitle${title}`,
            )
          )}
        </P>
      </div>
      {isDesktop && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button
            className={`btn btn-small btn-100 subtitle-button-receive subtitle-button ${
              darkFirstButton ? 'btn-dark' : ''
            }`}
            disabled={firstBtnDisabled}
            onClick={() => firstBtnHandleClick()}
          >
            {firstBtnText}
          </button>
          {secondButton && secondBtnHandleClick && (
            <button
              type="button"
              className="subtitle-button btn btn-small w-100 btn-dark "
              onClick={() => secondBtnHandleClick()}
            >
              {secondBtnText}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
