import React from 'react';
import './Rating.scss';
import ActiveIcon from '../../Asset/images/offer/activeItem.png';
import InactiveIcon from '../../Asset/images/offer/inactiveItem.png';

interface IRatingProps {
  value: any;
  onChange?: (value: any) => void;
}

const Rating: React.FC<React.PropsWithChildren<IRatingProps>> = ({
  value,
  onChange,
}) => {
  const count = 5;

  const handleChange = (value: any) => {
    if (onChange) {
      onChange(value + 1);
    }
  };

  return (
    <div className={`peanut-rating ${!!onChange ? 'interactive' : ''}`}>
      {[...Array(count)].map((peanut, index) => (
        <img
          key={index}
          className={`peanut ${!!onChange ? '' : 'small'}`}
          alt="peanut"
          data-e2e={`opinion-rating-${index}`}
          src={index < value ? ActiveIcon : InactiveIcon}
          onClick={() => handleChange(index)}
        />
      ))}
    </div>
  );
};

export default Rating;
