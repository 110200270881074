import React, { useState } from 'react';
import './ContactForm.scss';
import { useFormik } from 'formik';
import { translate } from '@module/Translate/Translate';
import { H5, P } from '@component/Text/Text';
import { User } from '@models/user.model';
import { sendEmail } from '@network/Actions/Email';
import Modal from '@component/Modal/Modal';
import CompletedSentForm from '@form/CompletedSentForm/CompletedSentForm';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';

interface Props {
  user: User;
  setSpinner: (value: boolean) => void;
}

export const ContactForm = ({ user, setSpinner }: Props) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: user.isCompanyAccount
        ? `${user.companyDetails?.name} ${
            user.companyDetails?.restOfName
              ? user.companyDetails?.restOfName
              : ''
          }`
        : `${user.personalDetails?.firstName} ${user.personalDetails?.lastName}`,
      email: user.email,
      phone: user.phone,
      message: '',
    },
    onSubmit: (values) => {
      setSpinner(true);
      sendEmail({
        email: values.email,
        subject: 'Pomoc | Kontakt',
        message: {
          name: user.isCompanyAccount
            ? user.companyDetails?.name +
                ' ' +
                user.companyDetails?.restOfName || ''
            : user.personalDetails?.firstName +
              ' ' +
              user.personalDetails?.lastName,
          email: user.email,
          phoneNumber: user.phone,
          messageDetails: values.message,
        },
      })
        .then(() => {
          setShowConfirm(true);
          values.message = '';
        })
        .catch(() => {
          setShowError(true);
        })
        .finally(() => {
          setSpinner(false);
        });
    },
  });

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <Modal
        showModal={showConfirm}
        setShowModal={setShowConfirm}
        className="dialog-ml"
      >
        <CompletedSentForm setShowConfirm={setShowConfirm} />
      </Modal>

      <form className="contact-form" onSubmit={formik.handleSubmit}>
        <H5 className="contact-form_title">
          {translate('contactForm.outContactForm')}
        </H5>
        <P className="contact-form_subtitle container-header">
          {translate('contactForm.enterContact')}
        </P>

        <div className="contact-form_group">
          <label htmlFor="first_name" className="label--orange">
            {translate('contactForm.firstName')}
          </label>

          <input
            type="text"
            disabled={true}
            name="firstName"
            className="custom-input"
            id="firstName"
            autoComplete="off"
            defaultValue={formik.values.firstName}
          />
        </div>

        <div className="d-block d-md-flex justify-content-between">
          <div className="contact-form_group small-input">
            <label
              htmlFor="email"
              className={`label--orange ${
                formik.touched.email && formik.errors.email ? 'error-label' : ''
              }`}
            >
              {translate('contactForm.e-mail')}
            </label>
            <input
              type="email"
              disabled={true}
              name="email"
              className="custom-input"
              id="email"
              autoComplete="off"
              value={formik.values.email}
            />
          </div>

          <div className="contact-form_group small-input">
            <label htmlFor="phone" className="label--orange">
              {translate('contactForm.phoneNumber')}
            </label>
            <input
              type="text"
              name="phone"
              disabled={true}
              className="custom-input"
              id="phone"
              autoComplete="off"
              value={formik.values.phone}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="message" className="label--orange">
            {translate('contactForm.message')}
          </label>
          <textarea
            name="message"
            className="custom-input"
            id="message"
            autoComplete="off"
            style={{ height: '253px' }}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
        </div>

        <button
          type="submit"
          className={`contact-form_btn btn `}
          disabled={!formik.values.message}
        >
          {translate('contactForm.send')}
        </button>
      </form>
    </>
  );
};
