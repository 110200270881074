import React from 'react';
import { FunctionComponent } from 'react';
import ShowIfHOC from '../../HOC/ShowIfHOC';
import { TypographyProps } from './Typography.type';
import { parser } from './Typography.util';
import TouchableHOC from './Typography.Touchable';
import { translateHOC } from '../../Module/Translate/Translate';

const Typography: FunctionComponent<
  React.PropsWithChildren<TypographyProps>
> = (props) => {
  let TextComponent = (
    <div style={{ ...props.style }}>
      {props.children && translateHOC(parser(props))(props.children)}
    </div>
  );

  if (props.onClick) {
    TextComponent = TouchableHOC(TextComponent, props);
  }

  return TextComponent;
};

export default ShowIfHOC(Typography);
