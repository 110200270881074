import { PolicyStatus } from '../../../types/policies/policy-status.enum';
import { PolicyEnum } from '../../../types/policies/policy-type.enum';
import {
  PUBLISHED_POLICY_FAIL,
  PUBLISHED_POLICY_REQUEST,
  PUBLISHED_POLICY_SUCCES,
} from '../Network/Actions/Types';
import { PublishedPoliciesState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: Record<string, unknown>;
}

export const publishedPoliciesReducer = (
  state: PublishedPoliciesState = {
    loading: false,
    publishedPolicies: {
      id: 0,
      type: PolicyEnum.Privacy,
      status: PolicyStatus.Published,
      title: '',
      content: 'BRAK REGULAMINU',
      fileUrl: '',
    },
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case PUBLISHED_POLICY_REQUEST:
      return {
        loading: true,
        publishedPolicies: state.publishedPolicies,
        error: null,
      };
    case PUBLISHED_POLICY_SUCCES:
      return { loading: false, publishedPolicies: action.payload, error: null };
    case PUBLISHED_POLICY_FAIL:
      return {
        loading: false,
        publishedPolicies: state.publishedPolicies,
        error: action.payload,
      };
    default:
      return state;
  }
};
