import React, { useState } from 'react';
import './Certificate.scss';
import photo from '@images/rent-page/image-certificate.jpg';
import { CertificateModal } from './CertificateModal';
import Modal from '@component/Modal/Modal';
import { translate } from '@module/Translate/Translate';
import { H2 } from '@component/Text/Text';

const Certificate: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className="dialog--large"
      >
        <CertificateModal />
      </Modal>
      <section className="certificate">
        <div className="container certificate-container">
          <div className="certificate_text">
            <H2>{translate('rentPage.certificate.text')}</H2>
            {/* <button onClick={openModal} className="btn btn-dark">
              {translate('rentPage.certificate.buttonText')}
            </button> */}
          </div>
          <div className="certificate_image">
            <img src={photo} alt="certificate" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Certificate;
