import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { useRef, useState } from 'react';

import './EditOrder.scss';
import { Panel } from 'primereact/panel';
import { Messages } from 'primereact/messages';
import StatusesHistoryTable from './StatusesHistory/StatusesHistoryTable';
import StockToOrderTable from './getStockRelatedToOrder/StockToOrderTable';
import { StockType } from '@rootTypes/stocks/stock-type.enum';
import { H5 } from '@component/Text/Text';
import { User } from '@models/user.model';
import { translate } from '@module/Translate/Translate';
import {
  createWMSOrderAction,
  editOrderAction,
  editOrderStocksEndDate,
} from '@network/Actions/Order';
import { OrderStatus } from '@rootTypes/orders/order-status.enum';
import { enumToOptions } from '@util/EnumToOptions';
import Modal from '@component/Modal/Modal';

interface Props {
  order: any;
  editor: User;
  setRefresh: (refresh: boolean) => void;
}

const statuses = enumToOptions(OrderStatus);

export default function EditOrder({ order, editor, setRefresh }: Props) {
  const message: any = useRef(null);
  const [collapsed, setCollapsed] = useState(true);
  const [processingRequest, setProcessingRequest] = useState(false);

  const createWMSOrder = async (orderId: string) => {
    const waitTime = 1000;
    setProcessingRequest(true);
    try {
      await createWMSOrderAction(orderId);
      showMessage('success', translate('adminTool.orders.wmsOrderCreated'));
      setTimeout(() => setRefresh(true), waitTime);
    } catch (error) {
      showMessage('error', translate(`adminTool.orders.wmsOrderError`));
    }
    setTimeout(() => setProcessingRequest(false), waitTime);
  };

  const formik = useFormik({
    initialValues: {
      status: order ? order.status : OrderStatus.A,
      days: 0,
    },
    onSubmit: (values: any) => {
      const waitTime = 1000;
      setProcessingRequest(true);

      editOrderAction(order.id, {
        status: values.status,
        updatedBy: editor.email,
      })
        .then((res: any) => {
          if (res.response) {
            showMessage(
              'error',
              translate(`adminTool.orders.${res.response.data.error.message}`),
            );
          } else {
            if (values.days > 0) {
              editOrderStocksEndDate(order.id, {
                days: values.days,
                updatedBy: editor.email,
              })
                .then((res: any) => {
                  if (res.response) {
                    showMessage(
                      'error',
                      translate(
                        `adminTool.orders.${res.response.data.error.message}`,
                      ),
                    );
                  } else {
                    showMessage(
                      'success',
                      translate('adminTool.orders.updateMessage'),
                    );
                    setTimeout(() => setRefresh(true), waitTime);
                    formik.setFieldValue('days', 0);
                  }
                })
                .catch((error) => {
                  showMessage('error', translate(`adminTool.orders.fail`));
                });
            } else {
              showMessage(
                'success',
                translate('adminTool.orders.updateMessage'),
              );
              setTimeout(() => setRefresh(true), waitTime);
            }
          }
        })
        .catch((error) => {
          showMessage('error', translate(`adminTool.orders.fail`));
        });

      setTimeout(() => setProcessingRequest(false), waitTime);
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const getWarehouseData = (order: any) => {
    return (
      <Panel
        header={translate('adminTool.orders.history-statuses')}
        toggleable
        collapsed={collapsed}
        onToggle={(e) => setCollapsed(e.value as boolean)}
      >
        <ul className="order-statuses">
          <StatusesHistoryTable orderNo={order.id} />
        </ul>
      </Panel>
    );
  };
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <H5 className="added-WMS_text">
          {translate('adminTool.orders.addedToWMS')} {`${order.id}`}
        </H5>
        <button
          className="btn btn-100"
          onClick={() => {
            createWMSOrder(order.id);
            setShowModal(false);
          }}
        >
          {translate('adminTool.orders.buttonSubmit')}
        </button>
      </Modal>
      <div className="order-main-container border-details">
        <div className="order-header-container">
          <Messages ref={message} />
        </div>
        <div className="order-data-container">
          <div className="order-data-container-main">
            <form className="order-form" onSubmit={formik.handleSubmit}>
              <>
                <H5 className="order-h5">
                  {translate('adminTool.orders.endDate')}
                </H5>
                <div className="input-date">
                  <input
                    type="number"
                    name="days"
                    onChange={formik.handleChange}
                    value={formik.values.days}
                  />
                </div>
                <H5 className="order-h5">
                  {translate('adminTool.orders.status')}
                </H5>
                <Dropdown
                  value={formik.values.status}
                  options={statuses}
                  className="type-dropdown"
                  name="status"
                  optionLabel="label"
                  optionValue="value"
                  onChange={formik.handleChange}
                  itemTemplate={(option) => {
                    return <span>{option.label}</span>;
                  }}
                />
                <button
                  type="submit"
                  className={`submitForm invoice__button m-auto button_accept ${
                    formik.isValid && !processingRequest ? '' : 'inactive'
                  }`}
                >
                  {translate('adminTool.orders.update')}
                </button>
              </>
            </form>
            <div className="order-wms">
              <H5 className="order-h5">
                {translate('adminTool.orders.wmsOrder')}
              </H5>
              <button
                onClick={() => setShowModal(true)}
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.isValid && !processingRequest ? '' : 'inactive'
                }`}
              >
                {translate('buttons.add')}
              </button>
            </div>
          </div>
          <div className="order-data-container-section">
            {getWarehouseData(order)}
            <StockToOrderTable
              orderId={order.id}
              stockType={StockType.STORAGE}
              sectionLabel={translate(`adminTool.orders.storage`)}
            />
            <StockToOrderTable
              orderId={order.id}
              stockType={StockType.RENT}
              sectionLabel={translate(`adminTool.orders.rent`)}
            />
            <StockToOrderTable
              orderId={order.id}
              stockType={StockType.SELL}
              sectionLabel={translate(`adminTool.orders.sell`)}
            />
          </div>
        </div>
      </div>
    </>
  );
}
