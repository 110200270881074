import React from 'react';
import './Comparison.scss';
import box from '../../../../Asset/images/rent-page/box-top.jpg';
import cardboard from '../../../../Asset/images/rent-page/cardboard-top.jpg';
import icon from '../../../../Asset/images/rent-page/icon.jpg';
import union from '../../../../Asset/images/rent-page/Union.jpg';
import vs from '../../../../Asset/images/rent-page/VS.png';
import { P } from '@component/Text/Text';

const Comparison: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <section className="comparison">
      <div className="comparison_item">
        <img src={box} alt="box" className="box-image" />
        <div className="comparison_item_grid">
          <div className="comparison_item_grid_item">
            <img src={icon} alt="icon" />
            <P>rentComparisonItems.item1</P>
          </div>
          <div className="comparison_item_grid_item">
            <img src={icon} alt="icon" />
            <P>rentComparisonItems.item2</P>
          </div>
          <div className="comparison_item_grid_item">
            <img src={icon} alt="icon" />
            <P>rentComparisonItems.item3</P>
          </div>
          <div className="comparison_item_grid_item">
            <img src={icon} alt="icon" />
            <P>rentComparisonItems.item4</P>
          </div>
        </div>
      </div>
      <p className="comparison_triggle">
        <img src={vs} alt="vs" />
      </p>
      <div className="comparison_item">
        <img src={cardboard} alt="card" className="box-image" />
        <div className="comparison_item_grid">
          <div className="comparison_item_grid_item">
            <img src={union} alt="union" />
            <P>rentComparisonItems.item5</P>
          </div>
          <div className="comparison_item_grid_item">
            <img src={union} alt="union" />
            <P>rentComparisonItems.item6</P>
          </div>
          <div className="comparison_item_grid_item">
            <img src={union} alt="union" />
            <P>rentComparisonItems.item7</P>
          </div>
          <div className="comparison_item_grid_item">
            <img src={union} alt="union" />
            <P>rentComparisonItems.item8</P>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Comparison;
