export const textFormater = (text: string): string => {
  let output = ``;
  const textArray = text.split(' ');
  textArray.forEach((e: string, count: number) => {
    if (e.length === 1) {
      output = `${output}${e}\u00a0`;
    } else {
      output = `${output}${e} `;
    }
  });
  return output;
};
export const textFormaterOrder = (text: string | null, max: number): string => {
  if (text != null) {
    let output = ``;
    const textArray = text.split('').filter((text) => text !== ',');
    let letter = 1;
    textArray.forEach((e: string, count: number) => {
      if (letter === max) {
        output = `${output}${e}\n`;
        letter = 0;
      } else {
        output = `${output}${e}`;
      }
      letter++;
    });
    return output;
  } else {
    return '';
  }
};
