export function calculateTotalMonthsOfStorage(
  startDate: Date,
  endDate: Date,
  isTotal?: boolean
) {
  let yearDiffrence = endDate.getFullYear() - startDate.getFullYear();
  let monthDiffrence = endDate.getMonth() - startDate.getMonth();

  if (startDate.getDate() <= endDate.getDate() && !isTotal) {
    monthDiffrence = monthDiffrence + 1;
  }

  if (
    monthDiffrence < 0 ||
    (monthDiffrence === 0 && endDate.getDate() < startDate.getDate())
  ) {
    monthDiffrence = monthDiffrence + 12;
    yearDiffrence--;
  }

  return yearDiffrence * 12 + monthDiffrence;
}
