import React, { useState } from 'react';
import './AddNewCommentForm.scss';
import { AddNewCommentFormMobile } from './AddNewCommentFormMobile/AddNewCommentFormMobile';
import { AddNewCommentFormDesktop } from './AddNewCommentFormDesktop/AddNewCommentFormDesktop';
import Modal from '@component/Modal/Modal';
import CompleteSendComment from '@form/CompleteSendComment/CompleteSendComment';
import { translate } from '@module/Translate/Translate';
import { FindUserDto } from '@rootTypes/users/find-user.dto';

interface IProps {
  visibleSpinner: (value: boolean) => void;
  showError: (value: boolean) => void;
  user: FindUserDto;
}

export const AddNewCommentForm = ({
  visibleSpinner,
  showError,
  user,
}: IProps) => {
  const [formSubmit, setFormSubmit] = useState(false);

  return (
    <div className="add-new-comment_form">
      <Modal
        className="dialog-ml"
        showModal={formSubmit}
        setShowModal={setFormSubmit}
      >
        <CompleteSendComment message={translate('offer.contact.added')} />
      </Modal>
      <AddNewCommentFormDesktop
        onHandleSubmit={() => setFormSubmit(true)}
        isLoggedUser={user?.isActive}
        visibleSpinner={visibleSpinner}
        showError={showError}
      />
      <AddNewCommentFormMobile
        onHandleSubmit={() => setFormSubmit(true)}
        isLoggedUser={user?.isActive}
        visibleSpinner={visibleSpinner}
        showError={showError}
      />
    </div>
  );
};
