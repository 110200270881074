export const calculateCeilRentPriceByMonth = (
  rentWeeks: number,
  boxesPriceRent: number,
  boxesValueRent: number,
  stackTransportPrice: number,
) => {
  if (rentWeeks < 1) {
    return 0;
  } else {
    const transportRentPrice = (boxesValueRent / 5) * stackTransportPrice * 2;
    const rentPrice = transportRentPrice + boxesPriceRent * rentWeeks;
    return Math.ceil((rentPrice / rentWeeks) * 100) / 100;
  }
};
