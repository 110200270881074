import React from 'react';
import './SidebarSubItem.scss';

const SidebarSubItem = ({ setActivePanel, activePanel, item }: any) => {
  return (
    <li className={'sidebar-sub-item'}>
      <a
        className={`sidebar-sub-item_title ${
          activePanel === item.template && 'active'
        }`}
        onClick={() => setActivePanel(item)}
      >
        {item.displayName}
      </a>
    </li>
  );
};

export default SidebarSubItem;
