import React, { useState } from 'react';
import './HidingPlaceElement.scss';
import { StorageBoxesGroup } from './StorageBoxesGroup';
import { H2 } from '@component/Text/Text';
import { FindOrderDto } from '@rootTypes/orders/find-order.dto';
import { createGruppedOrder } from './logic/filterOrderDayAndAddress';
import plusImage from '@images/faq/plus.png';
import minusImage from '@images/faq/minus.png';
import { translate } from '@module/Translate/Translate';

export interface Props {
  order: FindOrderDto;
  setRefresh: any;
  visibleSpinner: (value: boolean) => void;
  showError: (value: boolean) => void;
  shouldBeOpened?: boolean;
}

export const HidingPlaceElement = ({
  order,
  setRefresh,
  visibleSpinner,
  showError,
  shouldBeOpened,
}: Props) => {
  const stocks = createGruppedOrder(order);
  const [expandCollapse, setExpandCollapse] = useState(shouldBeOpened || false);

  return (
    <>
      {stocks.length > 0 && (
        <div
          key={order.id}
          className={`hiding-element hiding-element-${
            expandCollapse ? 'open' : 'close'
          }`}
        >
          <div
            className="hiding-element-title-container"
            onClick={() => setExpandCollapse(!expandCollapse)}
          >
            <H2 className="hiding-element_title">
              <span className="hiding-element-title-comment">
                {translate('hidingPlaceBlock.hidingPlaceElement.oderNumber')}
                &nbsp;
              </span>
              <span>{order.id}</span>
            </H2>
            <img
              src={expandCollapse ? minusImage : plusImage}
              alt="plusIcon"
              className="hiding-element-title-expander"
              onClick={() => setExpandCollapse(!expandCollapse)}
            />
          </div>
          {expandCollapse &&
            stocks.map((parentArray, counter: number) => {
              return (
                <StorageBoxesGroup
                  key={counter}
                  visibleSpinner={visibleSpinner}
                  order={order}
                  setRefresh={setRefresh}
                  stocks={parentArray}
                  showError={showError}
                />
              );
            })}
        </div>
      )}
    </>
  );
};
