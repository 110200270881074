import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import { http } from '@network/NetworkHTTP';
import { CountUnreadNotificationsDto } from '@rootTypes/notifications/count-unread-notifications.dto';
import { FindAllNotificationsDto } from '@rootTypes/notifications/find-all-notifications.dto';
import { UpdateNotificationDto } from '@rootTypes/notifications/update-notification.dto';
import { RootState } from '@src/store';
import {
  COUNT_UNREAD_NOTIFICATIONS_REQUEST,
  COUNT_UNREAD_NOTIFICATIONS_SUCCESS,
  COUNT_UNREAD_NOTIFICATIONS_FAIL,
  INCREMENT_UNREAD_NOTIFICATIONS_REQUEST,
  DECREMENT_UNREAD_NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAIL,
  NOTIFICATIONS_PUSH,
  NOTIFICATIONS_DELETE_FULFILLED,
  NOTIFICATIONS_DELETE_REJECTED,
} from './Types';

export const countUnreadNotificationsAction = (
  userId: number,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: COUNT_UNREAD_NOTIFICATIONS_REQUEST,
      });

      const { data }: AxiosResponse<CountUnreadNotificationsDto> =
        await http.get(`users/${userId}/notifications/count-unread`);

      dispatch({
        type: COUNT_UNREAD_NOTIFICATIONS_SUCCESS,
        unreadCount: data.unreadCount,
      });
    } catch (err) {
      dispatch({
        type: COUNT_UNREAD_NOTIFICATIONS_FAIL,
        error: 'Zaloguj się jeszcze raz, Twoja sesja wygasła.',
      });
    }
  };
};
export const incrementUnreadNotificationsAction = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): void => {
    dispatch({
      type: INCREMENT_UNREAD_NOTIFICATIONS_REQUEST,
    });
  };
};
export const decrementUnreadNotificationsAction = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): void => {
    dispatch({
      type: DECREMENT_UNREAD_NOTIFICATIONS_REQUEST,
    });
  };
};

export const updateNotificationAction = (
  userId: number,
  notificationId: number,
  notification: any,
  index: number,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: NOTIFICATIONS_REQUEST,
      });

      const { data }: AxiosResponse<UpdateNotificationDto> = await http.patch(
        `users/${userId}/notifications/${notificationId}`,
        notification,
      );
      dispatch({
        type: UPDATE_NOTIFICATION_SUCCESS,
        notification: data,
        index: index,
      });
    } catch (err) {
      onError();
      dispatch({
        type: UPDATE_NOTIFICATION_FAIL,
        error: 'Zaloguj się jeszcze raz, Twoja sesja wygasła.',
      });
    }
  };
};

export const getNotifcationsAction = (
  userId: number,
  currentLength: number,
  pageSize: number,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: NOTIFICATIONS_REQUEST,
      });

      const { data }: AxiosResponse<FindAllNotificationsDto> = await http.get(
        `users/${userId}/notifications?take=${pageSize}&skip=${
          currentLength > 0 ? currentLength : 0
        }`,
      );

      dispatch({
        type: NOTIFICATIONS_SUCCESS,
        notifications: data.items,
        totalCount: data.meta.totalItems,
      });
    } catch (err) {
      onError();
      dispatch({
        type: NOTIFICATIONS_FAIL,
        error: 'Zaloguj się jeszcze raz, Twoja sesja wygasła.',
      });
    }
  };
};

export const pushNotificationAction = (
  notification: any,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>): void => {
    dispatch({
      type: NOTIFICATIONS_PUSH,
      notification,
    });
  };
};

export const removeNotifications = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return (dispatch) => {
    try {
      dispatch({
        type: NOTIFICATIONS_DELETE_FULFILLED,
        notifications: [],
      });
    } catch (err) {
      dispatch({
        type: NOTIFICATIONS_DELETE_REJECTED,
        error: 'Bad request',
      });
    }
  };
};

export async function getAdminToolNotificationsAction(
  userId: number,
  currentLength: number,
  pageSize: number,
) {
  return await http.get(
    `users/${userId}/notifications?take=${pageSize}&skip=${
      currentLength > 0 ? currentLength : 0
    }`,
  );
}
