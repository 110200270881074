import { StockStatus } from '@rootTypes/stocks/stock-status.enum';

export const HeaderStorageDescription = (title: string): boolean => {
  if (
    title === StockStatus.STOCK_ATC ||
    title === StockStatus.STOCK_ATCF ||
    title === StockStatus.STOCK_RFRF ||
    title === StockStatus.STOCK_RFRE ||
    title === StockStatus.STOCK_RFR ||
    title === StockStatus.STOCK_RFD
  ) {
    return true;
  } else {
    return false;
  }
};
export const HeaderStorageTypeOfLink = (title: string): boolean => {
  if (title === StockStatus.STOCK_ATC || title === StockStatus.STOCK_RFRF) {
    return true;
  } else {
    return false;
  }
};
