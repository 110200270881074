import { FC, useState } from 'react';
import { translate } from '../../Module/Translate/Translate';
import { H5 } from '../Text/Text';
import './FormFieldFormik.style.scss';

interface Props {
  label?: any;
  type?: any;
  placeholder?: any;
  onChange?: any;
  onBlur?: any;
  name: any;
  value: any;
  fieldError?: any;
  fieldType?: any;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  inputProps?: {
    pattern?: string;
    title?: string;
  };
}

export const FormFieldFormik: FC<React.PropsWithChildren<Props>> = ({
  label,
  type,
  placeholder,
  onChange,
  onBlur,
  name,
  value,
  fieldError,
  fieldType,
  disabled,
  min,
  max,
  step,
  inputProps,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputTypeCondition =
    type !== 'password' ? type : showPassword ? 'text' : 'password';

  const [inputValue, setInputValue] = useState(value);

  return (
    <label className="input_label d-flex flex-column">
      {label && <H5>{label}</H5>}
      <div className="showPassword_btn_container">
        {!fieldType ? (
          <input
            id={name}
            name={name}
            placeholder={placeholder ? placeholder : ''}
            value={inputValue}
            type={inputTypeCondition}
            className={`custom-input ${fieldError ? 'error-input' : ''}`}
            onChange={(e: any) => {
              onChange(e, type);
              setInputValue(e.target.value);
            }}
            onBlur={onBlur}
            disabled={disabled}
            min={min}
            max={max}
            step={step}
            {...inputProps}
          />
        ) : (
          <textarea
            id={name}
            name={name}
            placeholder={placeholder ? placeholder : ''}
            value={value}
            className={`textarea ${fieldError ? 'error-input' : ''}`}
            onChange={(e: any) => onChange(e, type)}
            onBlur={onBlur}
          />
        )}

        {type === 'password' && (
          <div
            className="showPassword_btn"
            onMouseDown={() => setShowPassword(true)}
            onTouchStart={() => setShowPassword(true)}
            onMouseUp={() => setShowPassword(false)}
            onMouseLeave={() => setShowPassword(false)}
            onTouchEnd={() => setShowPassword(false)}
          >
            {translate('registrationForm.show')}
          </div>
        )}
      </div>
    </label>
  );
};
