import React from 'react';
import Morcik from '../../Asset/images/morcik.png';
import Nuts from '../../Asset/images/newstler/orzeszki.png';
import MorcikSmall from '../../Asset/images/morcik-small.png';
import NutsSmall from '../../Asset/images/newstler/orzeszki-small.png';
import './NutsBackground.scss';

const NutsBackground = () => (
  <>
    <div className="background desktop d-md-block">
      <div className="morcik_container">
        <img src={Morcik} alt="Morcik" />
      </div>
      <div className="peanuts_container">
        <img src={Nuts} alt="Nuts" />
      </div>
    </div>

    <div className="background mobile d-md-none">
      <div className="peanuts_container d-md-block">
        <img src={NutsSmall} alt="Nuts" />
      </div>
      <div className="morcik_container--small d-md-block">
        <img src={MorcikSmall} alt="Morcik" />
      </div>
    </div>
  </>
);

export default NutsBackground;
