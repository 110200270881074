import {
  POLICIES_FAIL,
  POLICIES_SUCCESS,
  POLICIES_REQUEST,
} from '../Network/Actions/Types';
import { PoliciesState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: Record<string, unknown>;
}

export const policiesReducer = (
  state: PoliciesState = { loading: false, policies: [], error: null },
  action: Action,
) => {
  switch (action.type) {
    case POLICIES_REQUEST:
      return { loading: true, policies: state.policies, error: null };
    case POLICIES_SUCCESS:
      return { loading: false, policies: action.payload, error: null };
    case POLICIES_FAIL:
      return {
        loading: false,
        policies: state.policies,
        error: action.payload,
      };
    default:
      return state;
  }
};
