import React from 'react';
import add from '../../../../../../Asset/images/card/add.jpg';
import P from '../../../../../../Component/Text/Text';
import './AddCard.scss';
interface IProps {
  addCardModal: () => void;
}

const AddCard = ({ addCardModal }: IProps) => {
  return (
    <div onClick={() => addCardModal()} className="addcard">
      <P className="addcard_title">dashboard.addCard.title</P>
      <img src={add} alt="add card" />
    </div>
  );
};

export default AddCard;
