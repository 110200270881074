import React, { useRef } from 'react';
import './EditPromotion.scss';
import { translate } from '../../../../../../Module/Translate/Translate';
import { useFormik } from 'formik';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { Messages } from 'primereact/messages';
import { createPromotion, updatePromotion } from '@network/Actions/Promotion';
import { PromotionStatus } from '@rootTypes/promotions/promotion-status.enum';
import { enumToOptions } from '@util/EnumToOptions';
import { Dropdown } from 'primereact/dropdown';

export default function EditOrCreatePromotion({
  promotion,
  setRefresh,
  isCreate,
}: any) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      code: promotion?.code ?? '',
      name: promotion?.name ?? '',
      discount: promotion?.discount ?? 0.01,
      count: promotion?.count ?? 0,
      status: promotion?.status ?? '',
    },
    onSubmit: async (values: any) => {
      try {
        const promotionData = {
          code: values.code,
          name: values.name,
          discount: values.discount,
          count: values.count,
        };

        if (isCreate) {
          await createPromotion(promotionData);
        } else {
          await updatePromotion(promotion.id, {
            ...promotionData,
            status: values.status,
          });
        }
        const waitTime = 1000;
        showMessage('success', translate('default.message.success'));
        setTimeout(() => setRefresh(true), waitTime);
      } catch (error: any) {
        if (error.response.data.message.message) {
          const message = error.response.data.message.message;
          showMessage(
            'error',
            translate('default.message.fail') + ' ' + message,
          );
          return;
        }
        showMessage('error', translate('default.message.fail') + ' ' + error);
      }
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const statuses = enumToOptions(PromotionStatus);

  return (
    <div className="promotions-main-container border-details">
      <div className="default-settings-header-container">
        <Messages ref={message} />
      </div>
      <div className="promotions-data-container">
        <form className="promotions-form" onSubmit={formik.handleSubmit}>
          <FormFieldFormik
            name="name"
            type="string"
            onChange={formik.handleChange}
            value={formik.values.name}
            label={translate('adminTool.promotions.name')}
            onBlur={formik.handleBlur}
          />
          <FormFieldFormik
            name="code"
            type="string"
            onChange={formik.handleChange}
            value={formik.values.code}
            label={translate('adminTool.promotions.code')}
            onBlur={formik.handleBlur}
            inputProps={{
              pattern: '^[^\\s]*$',
              title: 'Please enter text without spaces',
            }}
          />
          <FormFieldFormik
            name="discount"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.discount}
            label={translate('adminTool.promotions.discount')}
            onBlur={formik.handleBlur}
            min={0.01} // sets minimum to 0
            max={100} // sets maximum to 100
            step={0.01} // allows decimal numbers
          />
          <FormFieldFormik
            name="count"
            type="number"
            onChange={formik.handleChange}
            value={formik.values.count}
            label={translate('adminTool.promotions.count')}
            onBlur={formik.handleBlur}
            min={0} // sets minimum to 0
          />
          {!isCreate && (
            <Dropdown
              value={formik.values.status}
              options={statuses}
              className="type-dropdown"
              name="status"
              optionLabel="label"
              optionValue="value"
              onChange={formik.handleChange}
              itemTemplate={(option) => {
                return <span>{option.label}</span>;
              }}
            />
          )}
          <button
            type="submit"
            className={`submitForm invoice__button m-auto button_accept`}
          >
            {`${translate('adminTool.promotions.update')}`}
          </button>
        </form>
      </div>
    </div>
  );
}
