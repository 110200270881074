import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import {
  TRANSPORT_FULFILLED,
  TRANSPORT_PENDING,
  TRANSPORT_REJECTED,
} from './Types';
import { http } from '../NetworkHTTP';

export async function editTransportAction(id: number, transport: any) {
  return await http.patch(`transport/prices/${id}`, transport);
}

export const getTransportAction = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: TRANSPORT_PENDING,
      });
      const { data } = await http.get(`transport/prices`);
      dispatch({
        type: TRANSPORT_FULFILLED,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: TRANSPORT_REJECTED,
        payload: 'Nie udało się pobrać cen transportu.',
      });
    }
  };
};
