import { FC, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { User } from '@models/user.model';
import { FormFieldFormik } from '@component/FormFieldFormik/FormFieldFormik';
import P, { H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { changeUserPasswordAction } from '@network/Actions/CurrentUser';
import { PasswordValidator } from '@rootTypes/auth/password-validator';
import { isEmpty } from '@util/isEmpty';

interface IProps {
  setShowModal: (value: boolean) => void;
  user: User;
  refreshUser: () => void;
  setSpinner: (value: boolean) => void;
  setError: (value: boolean) => void;
}

const Password: FC<React.PropsWithChildren<IProps>> = ({
  setShowModal,
  user,
  refreshUser,
  setSpinner,
  setError,
}: IProps) => {
  const [oldPasswordValid, setOldPasswordValid] = useState(true);
  const passwordValidator = new PasswordValidator();

  const onWrongPassword = (error: any) => {
    setOldPasswordValid(false);
    formik.setFieldError('newPassword', translate(`errors.${error.message}`));
  };

  const onSucces = () => {
    closeModalAndRefresh();
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      repNewPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required(translate('errors.fieldRequired')),
      newPassword: Yup.string()
        .test('', translate('errors.newPasswordIsTheSame'), function (value) {
          return this.parent.password !== value;
        })
        .test('', translate(`errors.invalidPasswordDetail`), function (value) {
          return value ? !passwordValidator.validatePassword(value) : false;
        })
        .required(translate('errors.fieldRequired')),
      repNewPassword: Yup.string()
        .test('', translate('errors.passwordNotMatch'), function (value) {
          return this.parent.newPassword === value;
        })
        .required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values: any) => {
      setSpinner(true);
      try {
        changeUserPasswordAction(
          user.id,
          {
            oldPassword: values.password,
            newPassword: values.newPassword,
          },
          onWrongPassword,
          onSucces,
        );
      } catch (error) {
        setError(true);
        setShowModal(false);
      } finally {
        setSpinner(false);
      }
    },
  });

  const passwordError = (function () {
    if (!oldPasswordValid)
      return (
        <div className="error-feedback">
          {translate('errors.invalidPassword')}
        </div>
      );

    if (formik.touched.password && formik.errors.password) {
      return <div className="error-feedback">{formik.errors.password}</div>;
    }
    return null;
  })();

  const newPasswordError = (function () {
    if (formik.touched.newPassword && formik.errors.newPassword) {
      return <div className="error-feedback">{formik.errors.newPassword}</div>;
    } else if (formik.touched.repNewPassword && formik.errors.repNewPassword) {
      return (
        <div className="error-feedback">{formik.errors.repNewPassword}</div>
      );
    }

    return null;
  })();

  const closeModalAndRefresh = () => {
    refreshUser();
    setShowModal(false);
  };

  return (
    <div className="modalwrapper">
      <H3 className="modal-title">{translate('passwordForm.title')}</H3>
      <P className="modal-text">{translate('passwordForm.subTitle')}</P>
      <form onSubmit={formik.handleSubmit}>
        <FormFieldFormik
          label={translate('registrationForm.password')}
          type="password"
          name="password"
          placeholder={translate('registrationForm.password')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          fieldError={passwordError}
        />
        {passwordError ? (
          passwordError
        ) : formik.touched.password ? null : (
          <div className="notifications-feedback">{}</div>
        )}

        <FormFieldFormik
          label={translate('changePassword.newPassword')}
          type="password"
          name="newPassword"
          placeholder={translate('changePassword.newPassword')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          fieldError={newPasswordError}
        />
        {newPasswordError && newPasswordError}
        <FormFieldFormik
          label={translate('changePassword.repeatPassword')}
          type="password"
          name="repNewPassword"
          placeholder={translate('changePassword.repeatPassword')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.repNewPassword}
          fieldError={newPasswordError}
        />
        {newPasswordError && newPasswordError}
        <br />
        <button
          type="submit"
          disabled={!(formik.isValid && isEmpty(formik.values))}
          className="btn btn-100"
        >
          {translate('changePassword.submitButton')}
        </button>
      </form>
    </div>
  );
};

export default Password;
