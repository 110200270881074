import { FindAllUsersDto } from '../../../types/users/find-all-users.dto';
import {
  ALL_USERS_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
} from '../Network/Actions/Types';
import { UsersState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: FindAllUsersDto;
}

export const usersReducer = (
  state: UsersState = {
    loading: false,
    users: { items: [], meta: { totalItems: 0 } },
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case ALL_USERS_REQUEST:
      return { loading: true, users: state.users, error: null };
    case ALL_USERS_SUCCESS:
      return { loading: false, users: action.payload, error: null };
    case ALL_USERS_FAIL:
      return { loading: false, users: state.users, error: null };
    default:
      return state;
  }
};
