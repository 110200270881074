import React, { createRef, useState } from 'react';
import './SingleOrderPaymentHistory.scss';
import P24 from '@images/registration/P24.png';
import Blik from '@images/registration/Blik.png';
import Card from '@images/card/card.png';
import Download from '@images/dashboard/download.png';
import {
  BillingDTO,
  PaymentHistoryDTO,
} from '@rootTypes/payments/dto/payment-history.dto';
import { INVOICES_ENDPOINT, SERVER_URL } from '@/envConfig';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import P from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

interface IProps {
  order: PaymentHistoryDTO;
  showConfirm: () => void;
  visibleSpinner: (value: boolean) => void;
}

const formatDate = (date: string): string => {
  if (date) {
    const newDate = date.split(' ');
    delete newDate[3];
    let returnString = '';
    newDate.forEach((e: string, c: number) => {
      returnString = `${returnString}\u00a0${e}`;
      if (c === 2) returnString = `${returnString}\u000a`;
    });
    return returnString;
  } else {
    return '';
  }
};

export default function SingleOrderPaymentHistory({
  order,
  showConfirm,
  visibleSpinner,
}: IProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const container = createRef<HTMLDivElement>();
  const onModuleToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const formatInvoiceUrl = (invoiceId: string): string => {
    return `${SERVER_URL}${INVOICES_ENDPOINT}${invoiceId}.pdf`;
  };

  //TODO: it is redundant please see PaymentsTable.tsx
  const getInvoiceBody = (billing: BillingDTO) => {
    if (billing?.invoiceNumber) {
      return (
        <a
          href={formatInvoiceUrl(billing.invoiceNumber.toString())}
          download="skitraj-document.pdf"
          target="_self"
        >
          <img
            className="itemBox_downloadPdfImg"
            src={Download}
            height="20"
            width="20"
            alt="Dokument PDF"
          />
        </a>
      );
    }
  };

  const paymentIcon = (method: PaymentMethodEnum) => {
    let currentPaymentType;

    switch (method) {
      case PaymentMethodEnum.P24:
        currentPaymentType = P24;
        break;
      case PaymentMethodEnum.BLIK:
        currentPaymentType = Blik;
        break;
      case PaymentMethodEnum.CARD:
        currentPaymentType = Card;
        break;
      default:
        currentPaymentType = P24;
        break;
    }

    return currentPaymentType;
  };
  return (
    <div className="singleOrderContainer" ref={container}>
      <div className="singleOrderHistoryContainer singleOrderHistoryContainer--desktop">
        {order.billingHistory.map((billing: BillingDTO, key) => (
          <React.Fragment key={key}>
            <div className="singleOrderHistoryContainer_wrapper">
              {key === 0 ? (
                <p
                  className="singleOrderContainer-text"
                  onClick={() => onModuleToggle()}
                >
                  {order.id}
                </p>
              ) : (
                <p
                  className="singleOrderContainer-text"
                  onClick={() => onModuleToggle()}
                ></p>
              )}
            </div>
            <div className="singleOrderHistoryContainer_wrapper">
              <P className="singleOrderHistoryContainer-text">{billing.date}</P>
            </div>
            <div className="singleOrderHistoryContainer_wrapper">
              <P className="singleOrderHistoryContainer-text">
                {formatDate(billing.billingPeroid)}
              </P>
            </div>
            <div className="singleOrderHistoryContainer_wrapper">
              <img
                className="itemBox_paymentImg"
                src={paymentIcon(billing.method)}
                alt={translate('orderHistory.paymentTitle')}
              />
            </div>
            <div className="singleOrderHistoryContainer_wrapper">
              <P className="singleOrderHistoryContainer-text">
                {billing.amount + ` ${translate('default.currency')}`}
              </P>
            </div>
            <div className="singleOrderHistoryContainer_wrapper">
              {getInvoiceBody(billing)}
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className="singleOrderHistoryContainer singleOrderHistoryContainer--mobile">
        {order.billingHistory.map((billing: BillingDTO, key) => {
          if (isModalOpen) {
            return (
              <div key={key} className="mobileContainer">
                <div className="mobileContainer_header">
                  <P className="mobileContainer_header_title">
                    {'FV ' + billing.date}
                  </P>
                  {getInvoiceBody(billing)}
                </div>
                <div className="mobileContainer_body">
                  <div className="mobileContainer_body_left">
                    <P className="mobileContainer_body_left_title">
                      {translate('orderHistory.dateTitle')}
                    </P>
                    <P className="mobileContainer_body_left_text">
                      {billing.date}
                      {billing.billingPeroid?.split(' ').length > 3 &&
                        '\n'}{' '}
                      &nbsp;
                    </P>
                    <P className="mobileContainer_body_left_title">
                      {translate('orderHistory.paymentTitle')}
                    </P>
                    <img
                      className="mobileContainer_body_left_img"
                      src={paymentIcon(billing.method)}
                      alt={translate('orderHistory.paymentTitle')}
                    />
                  </div>
                  <div className="mobileContainer_body_right">
                    <P className="mobileContainer_body_left_title">
                      {translate('orderHistory.billingPeroidTitle')}
                    </P>
                    <P className="mobileContainer_body_left_text">
                      {formatDate(billing.billingPeroid)}
                    </P>
                    <P className="mobileContainer_body_left_title">
                      {translate('orderHistory.sumTitle')}
                    </P>
                    <P className="mobileContainer_body_left_text">
                      {billing.amount + ` ${translate('default.currency')}`}
                      &nbsp;
                    </P>
                  </div>
                </div>
              </div>
            );
          } else {
            return '';
          }
        })}
      </div>
    </div>
  );
}
