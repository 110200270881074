import {
  BASKET_ADD_PENDING,
  BASKET_ADD_REJECTED,
  BASKET_ADD_FULFILLED,
  BASKET_DECREASE_PENDING,
  BASKET_DECREASE_REJECTED,
  BASKET_DECREASE_FULFILLED,
  BASKET_DELETE_PENDING,
  BASKET_DELETE_REJECTED,
  BASKET_DELETE_FULFILLED,
  BASKET_DATE_PENDING,
  BASKET_DATE_FULFILLED,
  BASKET_DATE_REJECTED,
  BASKET_PERIOD_REJECTED,
  BASKET_PERIOD_FULFILLED,
  BASKET_PERIOD_PENDING,
  BASKET_DAYS_PENDING,
  BASKET_DAYS_FULFILLED,
  BASKET_DAYS_REJECTED,
  BASKET_STOCKS_PENDING,
  BASKET_STOCKS_FULFILLED,
  BASKET_STOCKS_REJECTED,
} from '../Network/Actions/Types';
import { BasketState } from '../Type/Custom';

interface IAction {
  type: string;
  payload?: Record<string, unknown>[];
}

export const basketReducer = (
  state: BasketState = {
    products: [],
    error: null,
    loading: false,
    dates: [],
    periods: [],
    days: [],
    stocks: {
      selectedBox: [],
      selectedAllBox: false,
      amortizationTransportPrice: 0,
      orderId: '',
    },
  },
  action: IAction,
) => {
  switch (action.type) {
    case BASKET_ADD_PENDING:
      return {
        loading: true,
        products: [...state.products],
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_ADD_FULFILLED:
      localStorage.setItem('products', JSON.stringify(action.payload));
      return {
        products: action.payload,
        loading: false,
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_ADD_REJECTED:
    case BASKET_DECREASE_PENDING:
      return {
        loading: true,
        products: [...state.products],
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DECREASE_FULFILLED:
      localStorage.setItem('products', JSON.stringify(action.payload));
      return {
        products: action.payload,
        loading: false,
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DECREASE_REJECTED:
      return {
        products: [...state.products],
        loading: false,
        error: action.payload,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DELETE_PENDING:
      return {
        loading: true,
        products: [...state.products],
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DELETE_FULFILLED:
      localStorage.setItem('products', JSON.stringify(action.payload));
      return {
        products: action.payload,
        loading: false,
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DELETE_REJECTED:
      return {
        products: [...state.products],
        loading: false,
        error: action.payload,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DATE_PENDING:
      return {
        loading: true,
        products: [...state.products],
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DATE_FULFILLED:
      localStorage.setItem('dates', JSON.stringify(action.payload));
      return {
        products: [...state.products],
        loading: false,
        error: null,
        dates: action.payload,
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DATE_REJECTED:
      return {
        products: [...state.products],
        loading: false,
        error: action.payload,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_PERIOD_PENDING:
      return {
        loading: true,
        products: [...state.products],
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_PERIOD_FULFILLED:
      localStorage.setItem('periods', JSON.stringify(action.payload));
      return {
        products: [...state.products],
        loading: false,
        error: null,
        dates: [...state.dates],
        periods: action.payload,
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_PERIOD_REJECTED:
      return {
        products: [...state.products],
        loading: false,
        error: action.payload,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DAYS_PENDING:
      return {
        loading: true,
        products: [...state.products],
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_DAYS_FULFILLED:
      localStorage.setItem('days', JSON.stringify(action.payload));
      return {
        products: [...state.products],
        loading: false,
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: action.payload,
        stocks: state.stocks,
      };
    case BASKET_DAYS_REJECTED:
      return {
        products: [...state.products],
        loading: false,
        error: action.payload,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_STOCKS_PENDING:
      return {
        loading: true,
        products: [...state.products],
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    case BASKET_STOCKS_FULFILLED:
      localStorage.setItem('stocks', JSON.stringify(action.payload));
      return {
        products: [...state.products],
        loading: false,
        error: null,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: action.payload,
      };
    case BASKET_STOCKS_REJECTED:
      return {
        products: [...state.products],
        loading: false,
        error: action.payload,
        dates: [...state.dates],
        periods: [...state.periods],
        days: [...state.days],
        stocks: state.stocks,
      };
    default:
      return state;
  }
};
