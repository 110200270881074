import { http } from '@network/NetworkHTTP';
import { CreateMultipleNotificationDto } from '@rootTypes/notifications/create-multiple-notifcation';
import { CreateMultipleNotificationsByStatus } from '@rootTypes/notifications/create-multiple-notifications-by-status.dto';
import {
  PushNotificationObjectDto,
  TokenObjectDto,
} from '@rootTypes/push-notifications/push-notification-message.dto';
import { PNGroupEnum } from '@rootTypes/push-notifications/push-notifications-group.enum';

export const getAllPushNotificationsTokensAction = async (
  group: PNGroupEnum,
) => {
  try {
    return await http.get(`push-notification`, {
      params: {
        group: group,
      },
    });
  } catch (error) {
    console.error({
      from: 'PushNotifications.tsx',
      method: 'getAllPushNotificationsTokensAction',
      error: error,
    });
  }
};

export const showPushNotificationsAction = async (
  PNObject: PushNotificationObjectDto,
) => {
  try {
    return await http.post(
      'push-notification/send-push-notification',
      PNObject,
    );
  } catch (error) {
    console.error({
      from: 'PushNotifications.tsx',
      method: 'showPushNotificationsAction',
      error: error,
    });
  }
};

export const updateUserGroupByTokenAction = async (token: string) => {
  try {
    return await http.patch(`push-notification/update-group-by-token`, {
      token,
    });
  } catch (error) {
    console.error({
      from: 'PushNotifications.tsx',
      method: 'updateUserGroupByTokenAction',
      error: error,
    });
  }
};

export const setTokenAction = async (tokenObj: TokenObjectDto) => {
  try {
    return await http.post('push-notification/set-token', tokenObj);
  } catch (error) {
    console.error({
      from: 'PushNotifications.tsx',
      method: 'setTokenAction',
      error: error,
    });
    setTimeout(() => setTokenAction(tokenObj), 5000);
  }
};

export const pushMultipleSystemNotifications = async (
  createMultipleNotificationDto: CreateMultipleNotificationDto,
) => {
  try {
    await http.post(
      `users/${createMultipleNotificationDto.users[0].id}/notifications/multiple`,
      createMultipleNotificationDto,
    );
  } catch (error) {
    console.error({
      from: 'PushNotifications.tsx',
      method: 'pushMultipleSystemNotifications',
      error: error,
    });
  }
};

export const pushProfiledSystemNotifications = async (
  userId: number,
  createProfiledNotificationDto: CreateMultipleNotificationsByStatus,
) => {
  try {
    await http.post(
      `users/${userId}/notifications/status-notification`,
      createProfiledNotificationDto,
    );
  } catch (error) {
    console.error({
      from: 'PushNotifications.tsx',
      method: 'pushProfiledSystemNotifications',
      error: error,
    });
  }
};
