import {
  setTokenAction,
  updateUserGroupByTokenAction,
} from '@network/Actions/PushNotifications';
import { PNGroupEnum } from '@rootTypes/push-notifications/push-notifications-group.enum';
import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from 'firebase/messaging';
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_VAPID_KEY,
} from '../envConfig';

try {
} catch (error) {}

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

let app;
export let messaging: Messaging;

try {
  app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
} catch (error) {
  console.error({
    service: 'firebaseInit.ts',
    method: 'init app and messaging',
    error: error,
  });
}

export const requestPermission = async (email?: string) => {
  try {
    await Notification.requestPermission().then((permission: string) => {
      if (permission === 'granted') {
        requestForToken(email);
      }
    });
  } catch (error) {
    console.error({
      service: 'firebaseInit.ts',
      method: 'requestPermission',
      error: error,
    });
  }
};

export const requestForToken = (email?: string) => {
  return getToken(messaging, {
    vapidKey: FIREBASE_VAPID_KEY,
  })
    .then(async (currentToken: string) => {
      if (currentToken) {
        localStorage.setItem('PushNotificationToken', currentToken);
        if (email) {
          await setTokenAction({
            token: currentToken,
            group: PNGroupEnum.LOGGED,
          });
        } else {
          await setTokenAction({
            token: currentToken,
            group: PNGroupEnum.GUEST,
          });
        }
        if (
          localStorage.getItem('PushNotificationToken') === currentToken &&
          email
        ) {
          await updateUserGroupByTokenAction(currentToken);
        }
      }
    })
    .catch((err: any) => {
      console.error({
        service: 'firebaseInit.ts',
        metod: 'requestForToken',
        error: err,
      });
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    try {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    } catch (error) {
      console.error({
        service: 'firebaseInit.ts',
        method: 'onMessageListener',
        error: error,
      });
    }
  });
