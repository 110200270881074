import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import {
  REVIEWS_FAIL,
  REVIEWS_SUCCESS,
  REVIEWS_REQUEST,
  REVIEWS_ALL_FAIL,
  REVIEWS_ALL_SUCCESS,
  REVIEWS_ALL_REQUEST,
} from './Types';
import http from '../NetworkHTTP';
import { UpdateReviewDto } from '@rootTypes/reviews/update-review.dto';

export const getReviewsAction = (
  page: number,
  limit: number,
  onError: (value: boolean) => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: REVIEWS_REQUEST,
      });

      const { data } = await http.get(
        `reviews/accepted?page=${page}&limit=${limit}`,
      );

      dispatch({
        type: REVIEWS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REVIEWS_FAIL,
        payload: 'Nie udało się pobrać opini.',
      });
      onError(true);
    }
  };
};

export async function addNewCommentAction(comment: any) {
  const { data } = await http.post(`reviews`, comment);
  return data;
}

export async function editReviewAction(id: number, review: UpdateReviewDto) {
  const { data } = await http.patch(`reviews/${id}`, review);
  return data;
}

export async function getAverageGrades() {
  const { data } = await http.get('reviews/averages');
  return data;
}

export const getAllReviewsAction = (
  page: number,
  limit: number,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: REVIEWS_ALL_REQUEST,
      });

      const { data } = await http.get(`reviews/?page=${page}&limit=${limit}`);

      dispatch({
        type: REVIEWS_ALL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REVIEWS_ALL_FAIL,
        payload: 'Nie udało się pobrać opini.',
      });
    }
  };
};
