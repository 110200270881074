import React, { useCallback, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import SummaryOrder from './components/SummaryOrder/SummaryOrder';
import SummaryCard from './components/SummaryCard/SummaryCard';
import summaryData from './summary-data.json';
import './SummaryStep.scss';
import { useDispatch, useSelector } from 'react-redux';
import AddressInformation from '@component/AddressInformation/AddressInformation';
import BillingInformation from '@component/BillingInformation/BillingInformation';
import P, { H3, H5 } from '@component/Text/Text';
import SummaryForm from '@form/SummaryForm/SummaryForm';
import { translate } from '@module/Translate/Translate';
import {
  deleteStocksInBasket,
  removeProductFromBasket,
} from '@network/Actions/Basket';
import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '@rootTypes/default-settings/default-settings-type.enum';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { Variety } from '@rootTypes/variety/variety-type.enum';
import { RootState } from '@src/store';
import {
  CurrentOrderState,
  UserState,
  BasketState,
  DefaultSettingsState,
} from '@type/Custom';
import { checkoutEvent } from '@src/GTM/GTM';
import { removePromotionCode } from '@network/Actions/Promotion';

interface IProps {
  showError: (value: boolean) => void;
  visibleSpinner: (value: boolean) => void;
}

const SummaryStep = ({ showError, visibleSpinner }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { order }: CurrentOrderState = useSelector<
    RootState,
    CurrentOrderState
  >(({ currentOrder }) => currentOrder);
  const { user } = useSelector<RootState, UserState>((state) => state.user);
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);
  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);
  const stocks = state.stocks;
  const rentState = state.products.filter(
    (product) => product.type === ProductType.rent,
  );
  const storeState = state.products.filter(
    (product) => product.type === ProductType.storage,
  );
  const shopState = state.products.filter(
    (product) => product.group === ProductGroup.shop,
  );
  const stateStoreRange = state.periods.find(
    (date) => date.type === ProductType.storage && date.category === 'range',
  );
  const stateRentRange = state.periods.find(
    (date) => date.type === ProductType.rent && date.category === 'range',
  );
  const stateStorePeriod = state.periods.find(
    (date) => date.type === Variety.months && date.category === 'period',
  );
  const defaultOrderClosingTime =
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultOrderClosingTime,
    )?.limit || UndefinedSettingsType.undefinedOrderClosingTime;
  const onSubmit = useCallback(
    (values: any) => {
      history.push({
        ...location,
        state: {
          activeStep: 10,
        },
      });
      if (location.pathname === '/return-boxes') {
        dispatch(deleteStocksInBasket());
      } else {
        dispatch(removeProductFromBasket({ type: 'remove-all' }));
        removePromotionCode();
      }
    },
    [history, location],
  );
  const onEditAddress = useCallback(() => {
    history.push({
      ...location,
      state: {
        activeStep: 3,
      },
    });
  }, [history, location]);
  const onEditBilling = useCallback(() => {
    history.push({
      ...location,
      state: {
        activeStep: 4,
      },
    });
  }, [history, location]);
  const isTransportsAmortizationBasket = location.pathname === '/return-boxes';

  useEffect(() => {
    checkoutEvent(5);
  }, []);

  const date = (time: Date) => {
    const day = ('0' + time.getDate()).slice(-2);
    const month = ('0' + (time.getMonth() + 1)).slice(-2);
    const year = time.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const returnDate = stocks.date ? date(new Date(stocks.date)) : null;

  return (
    <>
      <div className="summary-step">
        <div className="summary-step_container">
          <H3 className="summary-step_title">summaryStep.title</H3>
          <div className="summary-step_items">
            <div className="orders">
              <H5 className="orders-title">{translate('summaryStep.order')}</H5>
              {isTransportsAmortizationBasket ? (
                <div className="orders-stocks">
                  <P className="orders-stocks_subtitle">summaryStep.store</P>
                  {stocks.selectedAllBox ? (
                    <div>
                      <P className="orders-stocks_transport">
                        summaryStep.returnedOrder
                      </P>
                      <P className="orders-stocks_transport">
                        {stocks.orderId}
                      </P>
                      {returnDate && (
                        <P className="orders-stocks_date">{returnDate}</P>
                      )}
                      <Link
                        className="orders-stocks_link"
                        to={{
                          pathname: '/panel-klienta-moja-skrytka',
                        }}
                      >
                        {translate('summaryOrder.edit')}
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <P className="orders-stocks_transport">
                        summaryStep.returnedStocks
                      </P>
                      <P className="orders-stocks_transport">
                        {stocks.selectedBox.map(
                          (i, index) => (index > 0 ? '/' : '') + i.stockNumber,
                        )}
                      </P>
                      {returnDate && (
                        <P className="orders-stocks_date">{returnDate}</P>
                      )}
                      <Link
                        className="orders-stocks_link"
                        to={{
                          pathname: '/panel-klienta-moja-skrytka',
                        }}
                      >
                        {translate('summaryOrder.edit')}
                      </Link>
                    </div>
                  )}
                </div>
              ) : (
                <div className="order-items">
                  <SummaryOrder
                    data={storeState}
                    title={translate('summaryStep.store')}
                    date={stateStoreRange?.date}
                    link="/zamowienie"
                    orderState={0}
                  />
                  <SummaryOrder
                    data={rentState}
                    title={translate('summaryStep.rent')}
                    date={stateRentRange?.date}
                    link="/zamowienie"
                    orderState={1}
                  />
                  <SummaryOrder
                    data={shopState}
                    link="/sklep"
                    title={translate('summaryStep.shop')}
                  />
                </div>
              )}
            </div>
            <div className="separator-line" />
            <div className="cards">
              <H5 className="cards-title">
                {translate('summaryStep.paymentType')}
              </H5>
              <div className="orders_container">
                <SummaryCard />
              </div>
            </div>
            <div className="separator-line" />
            <div className="addresses">
              <div className="addresses-items">
                {isTransportsAmortizationBasket && stocks ? (
                  <>
                    <AddressInformation
                      title={translate('summaryStep.addressClient')}
                      item={stocks.deliveryAddress}
                      onEdit={onEditAddress}
                    />
                    <AddressInformation
                      title={translate('summaryStep.returnAddress')}
                      item={stocks.returnAddress}
                      onEdit={onEditAddress}
                    />
                  </>
                ) : (
                  <>
                    <AddressInformation
                      title={
                        rentState.length > 0 || storeState.length > 0
                          ? translate('summaryStep.addressClient')
                          : translate('summaryStep.addressClientSecond')
                      }
                      item={order.deliveryAddress}
                      onEdit={onEditAddress}
                    />
                    {(rentState.length > 0 || storeState.length > 0) && (
                      <AddressInformation
                        title={translate('summaryStep.returnAddress')}
                        item={order.returnedAddress}
                        onEdit={onEditAddress}
                      />
                    )}
                  </>
                )}

                <BillingInformation
                  title={
                    user.isCompanyAccount
                      ? translate('summaryStep.billing')
                      : translate('summaryStep.billingPrivate')
                  }
                  item={order.billing}
                  onEdit={onEditBilling}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="summary-step_form">
          <SummaryForm
            onSubmit={onSubmit}
            data={summaryData.payData}
            storagePeriod={
              stateStorePeriod && stateStorePeriod?.date.slice(0, 2)
            }
            state={state}
            rentState={rentState}
            storeState={storeState}
            shopState={shopState}
            dates={state.dates}
            defaultOrderClosingTime={defaultOrderClosingTime}
            showError={showError}
            visibleSpinner={visibleSpinner}
          />
        </div>
      </div>
    </>
  );
};
export default SummaryStep;
