import React from 'react';
import './Counter.scss';

interface IProps {
  onCounterIncreaseClick: (value: number) => void;
  onCounterDecreaseClick: (value: number) => void;
  counterValue: number;
  stepValueCounter: number;
}

export default function Counter({
  onCounterIncreaseClick,
  onCounterDecreaseClick,
  counterValue,
  stepValueCounter,
}: IProps) {
  return (
    <>
      <div className="shop-counter">
        <button
          className="shop-counter_btn"
          onClick={() =>
            onCounterDecreaseClick(counterValue - stepValueCounter)
          }
          disabled={counterValue === 0}
        >
          -
        </button>
        <p>{counterValue}</p>
        <button
          className="shop-counter_btn"
          onClick={() =>
            onCounterIncreaseClick(counterValue + stepValueCounter)
          }
        >
          +
        </button>
      </div>
    </>
  );
}
