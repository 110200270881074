import React, { useState } from 'react';
import './InvoiceForm.scss';
import FormField from '@component/FormField/FormField';
import P, { H5 } from '@component/Text/Text';
import { User } from '@models/user.model';
import { translate } from '@module/Translate/Translate';
import { editUserCompanyDetailsAction } from '@network/Actions/CurrentUser';

interface Props {
  setShowModal: (value: boolean) => void;
  user: User;
  refreshUser: () => void;
  setSpinner: (value: boolean) => void;
  setError: (value: boolean) => void;
}

const InvoiceForm = ({
  setShowModal,
  user,
  refreshUser,
  setSpinner,
  setError,
}: Props) => {
  const currentCompanyDetails = user.companyDetails;
  const [formData, setFormData] = useState({
    name: '',
    restOfName: '',
    street: '',
    taxNumber: 0,
    postalCode: '',
    city: '',
    ...currentCompanyDetails,
  });

  const closeModalAndRefresh = () => {
    refreshUser();
    setShowModal(false);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSpinner(true);

    editUserCompanyDetailsAction(user.id, user.companyDetails?.id, formData)
      .then(() => {
        closeModalAndRefresh();
      })
      .catch(() => {
        setError(true);
        setShowModal(false);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const displayCurrentCompanyDetails = () => {
    if (currentCompanyDetails)
      return (
        <div className="invoice__data">
          <H5>{`${currentCompanyDetails.name} ${
            currentCompanyDetails.restOfName || ''
          }`}</H5>
          <H5>{`NIP ${currentCompanyDetails.taxNumber || ''}`}</H5>
          <H5>{`${currentCompanyDetails.street || ''}`} </H5>
          <H5>
            {`${currentCompanyDetails.postalCode || ''} ${
              currentCompanyDetails.city || ''
            }`}{' '}
          </H5>
        </div>
      );
  };

  return (
    <div className="invoiceModal">
      <P className="invoice__title">
        dashboard.invoiceModalDashboard.invoiceData
      </P>
      <P className="invoice__text">dashboard.invoiceModalDashboard.address</P>
      <div>{displayCurrentCompanyDetails()}</div>
      <form onSubmit={handleSubmit}>
        <FormField
          label={translate('dashboard.invoiceModalDashboard.companyName')}
          name={formData.name}
          onChange={(e: any) =>
            setFormData({ ...formData, name: e.target.value })
          }
        />
        <FormField
          label={translate('dashboard.invoiceModalDashboard.restOfName')}
          name={formData.restOfName || ''}
          onChange={(e: any) =>
            setFormData({ ...formData, restOfName: e.target.value })
          }
        />
        <FormField
          label={translate('dashboard.invoiceModalDashboard.street')}
          name={formData.street}
          onChange={(e: any) =>
            setFormData({ ...formData, street: e.target.value })
          }
        />
        <FormField
          label={translate('dashboard.invoiceModalDashboard.city')}
          name={formData.city}
          onChange={(e: any) =>
            setFormData({ ...formData, city: e.target.value })
          }
        />
        <FormField
          required={true}
          label={translate('dashboard.invoiceModalDashboard.postalCode')}
          name={formData.postalCode}
          onChange={(e: any) =>
            setFormData({ ...formData, postalCode: e.target.value })
          }
          pattern={'^[0-9]{2}-[0-9]{3}$'}
          placeholder={'XX-XXX'}
          title={translate('errors.postalCodeError')}
        />
        <button
          className="invoice__button btn mx-auto w-100"
          type="submit"
          disabled={
            !formData.name ||
            !formData.street ||
            !formData.city ||
            !formData.postalCode
          }
        >
          {translate('dashboard.invoiceModalDashboard.saveButton')}
        </button>
      </form>
    </div>
  );
};

export default InvoiceForm;
