import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import {
  DELIVERY_STATUSES_FULFILLED,
  DELIVERY_STATUSES_PENDING,
  DELIVERY_STATUSES_REJECTED,
} from './Types';
import { http } from '../NetworkHTTP';

export async function editDeliveryStatusesAction(
  id: number,
  deliveryStatuses: any,
) {
  return await http.patch(`delivery-statuses/${id}`, deliveryStatuses);
}

export async function addDeliveryStatusesAction(deliveryStatuses: any) {
  return await http.post(`delivery-statuses`, deliveryStatuses);
}

export const getDeliveryStatusesAction = (
  params: any,
  providerType: string,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: DELIVERY_STATUSES_PENDING,
      });
      const { data } = await http.get(`delivery-statuses`, {
        params: {
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          take: params.rows,
          skip: params.first,
          providerType: providerType,
        },
      });
      dispatch({
        type: DELIVERY_STATUSES_FULFILLED,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: DELIVERY_STATUSES_REJECTED,
        payload: 'Nie udało się pobrać statusów delivery.',
      });
    }
  };
};
