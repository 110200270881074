import { Dropdown } from 'primereact/dropdown';
import React, { useRef, useState } from 'react';
import { UserRole } from '../../../../../../../../../types/roles/user-role.enum';
import { H5 } from '../../../../../../../Component/Text/Text';
import { User } from '../../../../../../../models/user.model';
import { translate } from '../../../../../../../Module/Translate/Translate';
import { editUserRoleAction } from '../../../../../../../Network/Actions/Roles';
import './RolesData.scss';
import { getUserRoles } from '../../../../../../../Util/UserValidation';

interface Props {
  user: User;
  setRefresh: any;
  showMessage: any;
  editor: User;
}

export default function RolesData({
  user,
  setRefresh,
  showMessage,
  editor,
}: Props) {
  const userRole = user.roles[0];
  const [role, setRole] = useState(user.roles[0].type);
  const message: any = useRef(null);
  const onChange = (val: any) => {
    setRole(val);
  };
  const checkIsValid = () => {
    if (role !== userRole.type && userRole.type !== UserRole.SuperUser) {
      return true;
    }
    return false;
  };

  const onSubmit = async () => {
    await editUserRoleAction(user.id, userRole.id, {
      type: role,
      updatedBy: editor.email,
    })
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.users.updateMessage'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
    setRefresh(true);
  };

  return (
    <div className="roles-container">
      <H5 className="users-h5"> {translate('adminTool.users.roles')} </H5>
      <Dropdown
        value={role}
        options={getUserRoles(userRole.type)}
        className="type-dropdown"
        name="role"
        optionLabel="label"
        optionValue="value"
        onChange={(e) => onChange(e.target.value)}
        itemTemplate={(option) => {
          return <span>{option.label}</span>;
        }}
      />
      <button
        type="submit"
        onClick={() => onSubmit()}
        className={`submitForm invoice__button mb-4 button_accept ${
          checkIsValid() ? '' : 'inactive'
        }`}
      >
        {translate('adminTool.users.updateRole')}
      </button>
    </div>
  );
}
