import React, { useState } from 'react';
import DeleteAddressForm from '../../../../../../Form/DeleteAddressForm/DeleteAddressForm';
import EditAddressForm from '../../../../../../Form/EditAddressForm/EditAdressForm';
import Modal from '../../../../../../Component/Modal/Modal';
import './CreditCardItem.scss';
import editIcon from '../../../../../../Asset/images/card/editIcon.png';
import mastercard from '../../../../../../Asset/images/card/mastercard.png';
import { H5 } from '../../../../../../Component/Text/Text';
import { translate } from '../../../../../../Module/Translate/Translate';

export const CreditCardItem = ({ item, removeAddress, editAddress }: any) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <Modal showModal={showEditModal} setShowModal={setShowEditModal}>
        <EditAddressForm
          item={item}
          setShowModal={setShowEditModal}
          editAddress={editAddress}
          editItem={true}
          removeAddress={removeAddress}
        />
      </Modal>

      <Modal
        className="fit-content"
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      >
        <DeleteAddressForm
          item={item}
          setShowModal={setShowDeleteModal}
          removeAddress={removeAddress}
        />
      </Modal>

      <div className="credit-card-item">
        <img
          src={editIcon}
          alt="edit icon"
          className="credit-card-item_icon"
          onClick={() => setShowEditModal(true)}
        />

        <H5 className="credit-card-item_title">dashboard.cardItem.card</H5>

        <div className="credit-card-item_container">
          <div className="card-content">
            <H5 className="credit-card-item_name">{item.name}</H5>
            <H5 className="credit_data">{item.number}</H5>
            <H5 className="credit_data">
              {item.month}/{item.year}
            </H5>
          </div>
          {item.name && <img src={mastercard} alt="mastercardlogo" />}
        </div>

        <div className="btn_wrapper">
          <button
            className="btn btn-small btn-dark"
            onClick={() => setShowEditModal(true)}
          >
            {translate('dashboard.cardItem.buttonAdd')}
          </button>
          <button
            className="btn btn-small btn-dark"
            onClick={() => setShowDeleteModal(true)}
          >
            {translate('dashboard.cardItem.buttonDelete')}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreditCardItem;
