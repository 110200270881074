import React, { useState } from 'react';
import classnames from 'classnames';
import './MobileNavigation.scss';
import Bag from '@images/bag.png';
import User from '@images/customer/landing/user.png';
import logo from '@images/logo.png';
import morcik from '@images/morcikMenu.png';
import { translate } from '@module/Translate/Translate';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@src/store';
import { BasketState, NotificationsState, UserState } from '@type/Custom';
import MobileNavigationI from './MobileNavigation.type';
import { checkIsAllowed } from '@util/UserValidation';

const MobileNavigation: React.FC<MobileNavigationI> = ({
  customMobile = false,
}) => {
  const [show, setShow] = useState(false);
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const basketState = useSelector<RootState, BasketState>(
    ({ basket }) => basket,
  );
  const { unreadCount } = useSelector<RootState, NotificationsState>(
    (state) => state.notifications,
  );
  const itemsCounter = basketState.products.reduce((prev, next) => {
    return prev + +next.value;
  }, 0);
  const history = useHistory();

  return (
    <div
      className={`mobile-navigation ${
        customMobile ? 'mobile-navigation--custom' : ''
      } row no-gutters d-xl-none`}
    >
      <div
        onClick={() => setShow((value) => !value)}
        className={classnames('mobile-navigation_hamburger', { active: show })}
      >
        <span />
        <span />
        <span />
        <span />
      </div>

      <div className="counter">
        <Link
          to={{ pathname: '/zamowienie', state: { from: 'bagIcon' } }}
          onClick={() => {
            if (history.location.pathname === '/zamowienie') {
              document.documentElement.scrollTop =
                document.documentElement.scrollHeight;
            }
          }}
        >
          <span className="counter_cart-element">{itemsCounter}</span>
          <img src={Bag} alt={translate('navigation.orders')} />
        </Link>
      </div>

      <Link
        className="counter"
        to={userData.user?.isActive ? '/panel-klienta' : '/logowanie'}
      >
        {unreadCount > 0 && (
          <span className="counter_notifications-element"></span>
        )}
        <img src={User} alt={translate('navigation.users')} />
      </Link>
      <Link
        to="/zamowienie"
        className="order-btn btn btn-dark btn-small"
        onClick={() => {
          if (history.location.pathname === '/zamowienie') {
            document.documentElement.scrollTop = 0;
          }
        }}
      >
        <span className="d-block fw700 btn-heading">
          {translate('navigation.order')}
        </span>
      </Link>
      <div
        className={classnames('mobile-navigation_container', { active: show })}
      >
        <div className="navigation-menu_group">
          <Link
            className="mobile-navigation_element align-items-center nav-a d-flex flex-column justify-content-center text-center"
            to="/"
          >
            <img src={logo} alt="logo" />
          </Link>
          <Link
            className="mobile-navigation_element nav-a d-flex flex-column justify-content-center text-center"
            to="/przechowaj"
          >
            {translate('navigation.howWorking')}
          </Link>
          <Link
            to="/przeprowadz"
            data-e2e="move"
            className="mobile-navigation_element nav-a d-flex flex-column justify-content-center text-center"
          >
            {translate('navigation.move')}
          </Link>
          <Link
            to="/wynajem"
            data-e2e="rent"
            className="mobile-navigation_element nav-a d-flex flex-column justify-content-center text-center"
          >
            {translate('navigation.rent')}
          </Link>
          <Link
            to="/sklep"
            data-e2e="sell"
            className="mobile-navigation_element nav-a d-flex flex-column justify-content-center text-center"
          >
            {translate('navigation.sell')}
          </Link>
          <Link
            className="mobile-navigation_element nav-a d-flex flex-column justify-content-center text-center"
            to="/DlaFirm"
          >
            {translate('navigation.offer')}
          </Link>
          {/* <Link
            className="mobile-navigation_element nav-a d-flex flex-column justify-content-center text-center"
            to="/blog"
          >
            {translate('navigation.blog')}
          </Link> */}
          <Link
            className="mobile-navigation_element nav-a d-flex flex-column justify-content-center text-center"
            to="/pomoc"
          >
            {translate('navigation.frequentlyAsked')}
          </Link>
          {checkIsAllowed(userData?.user) && (
            <Link
              className="mobile-navigation_element nav-a d-flex flex-column justify-content-center text-center"
              to="/admin-tool"
            >
              Admin Tool
            </Link>
          )}
        </div>
        <div
          className="morcikImage d-flex"
          onClick={() => setShow((value) => !value)}
        >
          <img src={morcik} alt="morcik" />
        </div>
      </div>
    </div>
  );
};

export default MobileNavigation;
