import React from 'react';
import './Social.style.scss';
import Fb from '../../Asset/images/fb.png';
import Ig from '../../Asset/images/ig.png';
import BusinessConfig from '../../Config/Business.config';

const Social = () => (
  <div className="social">
    <div className="fb">
      <a href={BusinessConfig.social.fb.page} target="_blank" rel="noreferrer">
        <img src={Fb} alt="Facebook" />
      </a>
    </div>
    <div className="ig">
      <a href={BusinessConfig.social.ig.page} target="_blank" rel="noreferrer">
        <img src={Ig} alt="Instagram" />
      </a>
    </div>
  </div>
);

export default Social;
