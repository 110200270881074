import React from 'react';
import { H3 } from '../../Component/Text/Text';
import { translate } from '../../Module/Translate/Translate';
interface Props {
  setActivePanel: any;
  setShowModal: (value: boolean) => void;
}

const PhoneNumber = ({ setShowModal, setActivePanel }: Props) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    setActivePanel({
      displayName: 'Kontakt',
      template: 'contact',
      href: 'panel-klienta-kontakt',
    });
  };

  return (
    <div className="modalwrapper">
      <H3 className="modal-title">{translate('phoneForm.title')}</H3>
      <p className="text">{translate('phoneForm.subTitle')}</p>
      <div>
        <button onClick={handleSubmit} className="btn btn-100">
          {translate('phoneForm.formButton')}
        </button>
      </div>
    </div>
  );
};

export default PhoneNumber;
