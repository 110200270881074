import { Panel } from 'primereact/panel';
import React, { useState } from 'react';
import { CreateProductDescriptionDto } from '../../../../../../../../types/product-descriptions/create-product-description.dto';
import { CreateProductDetailsDto } from '../../../../../../../../types/product-details/create-product-details.dto';
import { UpdateProductDetailsDto } from '../../../../../../../../types/product-details/update-product-details.dto';
import { FindProductDto } from '../../../../../../../../types/products/find-product.dto';
import { H5 } from '../../../../../../Component/Text/Text';
import {
  addDescriptionAction,
  addImageAction,
  addProductDetailsAction,
  addProductPackageAction,
  deleteDescriptionAction,
  deleteImageAction,
  deleteProductPackageAction,
  editProductAction,
  editProductDetailsAction,
  editProductPackageAction,
  getProductPackagesAction,
} from '../../../../../../Network/Actions/Products';
import Descriptions from '../Descriptions/Descriptions';
import Details from '../Details/Details';
import Gallery from '../Gallery/Gallery';
import Images from '../Images/Images';
import ProductInfo from '../Info/ProductInfo';
import './EditProduct.scss';
import { translate } from '../../../../../../Module/Translate/Translate';
import ParentProduct from '../Parent/ParentProduct';
import { CreatePackageDto } from '../../../../../../../../types/packages/create-package.dto';
import { ProductsState } from '../../../../../../Type/Custom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import Options from '../Options/Options';
import WarehouseData from '../WarehouseData/WarehouseData';
import { ProductType } from '../../../../../../../../types/products/product-type.enum';
import { User } from '../../../../../../models/user.model';

interface Props {
  product: any;
  editor: User;
  setRefresh: any;
  showMessage: any;
}

export default function EditProduct({
  product,
  setRefresh,
  editor,
  showMessage,
}: Props) {
  const [imagesCollapsed, setImagesCollapsed] = useState(false);
  const [detailsCollapsed, setDetailsCollapsed] = useState(true);
  const [descriptionsCollapsed, setDescriptionsCollapsed] = useState(true);
  const [parentProductCollapsed, setParentProductCollapsed] = useState(true);
  const [productOptionsCollapsed, setProductOptionsCollapsed] = useState(true);
  const [warehouseDataCollapsed, setWarehouseDataCollapsed] = useState(true);
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );

  const onEditSubmit = (editedProduct: FindProductDto) => {
    editedProduct.updatedBy = editor.email;
    editProductAction(editedProduct)
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.products.productEdited'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const setImage = (img: any, productId: number) => {
    img.updatedBy = editor.email;
    addImageAction(productId, img)
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.products.imageAdded'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const setDescription = (
    description: CreateProductDescriptionDto,
    productId: number,
  ) => {
    description.updatedBy = editor.email;
    addDescriptionAction(productId, description)
      .then(() => {
        setRefresh(true);
        showMessage(
          'success',
          translate('adminTool.products.descriptionAdded'),
        );
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const removeDescription = (descriptionId: number, productId: number) => {
    deleteDescriptionAction(descriptionId, productId)
      .then(() => {
        setRefresh(true);
        showMessage(
          'success',
          translate('adminTool.products.descriptionRemoved'),
        );
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const updateProductDetails = (
    productDetails: UpdateProductDetailsDto,
    productId: number,
    detailsId: number,
  ) => {
    productDetails.updatedBy = editor.email;
    editProductDetailsAction(productDetails, productId, detailsId)
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.products.deatailsEdited'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const setProductDetails = (
    productDetails: CreateProductDetailsDto,
    productId: number,
  ) => {
    productDetails.updatedBy = editor.email;
    addProductDetailsAction(productDetails, productId)
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.products.deatailsAdded'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const removeImage = (imageId: number, productId: number) => {
    deleteImageAction(imageId, productId)
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.products.imageRemoved'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const setProductPackage = (
    productPackage: CreatePackageDto,
    productId: number,
  ) => {
    productPackage.updatedBy = editor.email;
    addProductPackageAction(productId, productPackage)
      .then(() => {
        showMessage('success', translate('adminTool.products.packageAdded'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const removeProductPackage = (packageId: number, productId: number) => {
    deleteProductPackageAction(packageId, productId)
      .then(() => {
        showMessage('success', translate('adminTool.products.packageRemoved'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const updateProductPackage = (
    productPackage: CreatePackageDto,
    productId: number,
    packageId: number,
  ) => {
    productPackage.updatedBy = editor.email;
    editProductPackageAction(productPackage, productId, packageId)
      .then(() => {
        showMessage('success', translate('adminTool.products.packageUpdated'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const getPackagePanel = (
    product: FindProductDto,
    setProductPackage: any,
    updateProductPackage: any,
    removeProductPackage: any,
    getProductPackagesAction: any,
  ) => {
    return (
      <Panel
        header={translate('adminTool.products.parentProducts')}
        toggleable
        collapsed={parentProductCollapsed}
        onToggle={(e) => setParentProductCollapsed(e.value)}
      >
        <div className="product-packages-container">
          <ParentProduct
            product={product}
            setProductPackage={setProductPackage}
            updateProductPackage={updateProductPackage}
            removeProductPackage={removeProductPackage}
            getProductPackagesAction={getProductPackagesAction}
            products={products.filter((item: any) => {
              if (item.type === ProductType.rent) {
                return item;
              }
            })}
          />
        </div>
      </Panel>
    );
  };

  const getProductOptions = (product: FindProductDto, onEditSubmit: any) => {
    return (
      <Panel
        header={translate('adminTool.products.productOptions')}
        toggleable
        collapsed={productOptionsCollapsed}
        onToggle={(e) => setProductOptionsCollapsed(e.value)}
      >
        <div className="product-options-container">
          <Options product={product} onEditSubmit={onEditSubmit} />
        </div>
      </Panel>
    );
  };

  const getWarehouseData = (product: FindProductDto) => {
    return (
      <Panel
        header={translate('adminTool.products.warehouseStocks')}
        toggleable
        collapsed={warehouseDataCollapsed}
        onToggle={(e) => setWarehouseDataCollapsed(e.value)}
      >
        <WarehouseData product={product} />
      </Panel>
    );
  };

  return (
    <div className="product-details-main border-details">
      <h2>
        {translate('adminTool.products.detailsFor')} {product.name}
      </h2>
      <div className="product-details-main-container">
        <div className="product-details-general">
          <ProductInfo
            product={product}
            editMode={true}
            setProduct={onEditSubmit}
          />
        </div>
        <div className="tabs-content">
          <Panel
            header={translate('adminTool.products.images')}
            toggleable
            collapsed={imagesCollapsed}
            onToggle={(e) => setImagesCollapsed(e.value)}
          >
            <div className="product-images-container">
              <Images setImage={setImage} productId={product.id} />
              <br />
              <H5 className="product-h5">
                {translate('adminTool.products.productImages')}
              </H5>
              <Gallery
                images={product.images}
                productId={product.id}
                removeImage={removeImage}
              />
            </div>
          </Panel>
          <Panel
            header={translate('adminTool.products.descriptions')}
            toggleable
            collapsed={descriptionsCollapsed}
            onToggle={(e) => setDescriptionsCollapsed(e.value)}
          >
            <div className="product-descriptions-container">
              <Descriptions
                descriptions={product.descriptions}
                setDescription={setDescription}
                productId={product.id}
                removeDescription={removeDescription}
              />
            </div>
          </Panel>
          <Panel
            header={translate('adminTool.products.productDetails')}
            toggleable
            collapsed={detailsCollapsed}
            onToggle={(e) => setDetailsCollapsed(e.value)}
          >
            <div className="product-details-container">
              <Details
                productDetails={product.productDetails}
                editMode={true}
                setProductDetails={setProductDetails}
                updateProductDetails={updateProductDetails}
                productId={product.id}
              />
            </div>
          </Panel>
          {getPackagePanel(
            product,
            setProductPackage,
            updateProductPackage,
            removeProductPackage,
            getProductPackagesAction,
          )}
          {getProductOptions(product, onEditSubmit)}
          {getWarehouseData(product)}
        </div>
      </div>
    </div>
  );
}
