import React, { useEffect } from 'react';
import './HowItWorks.scss';
import MainTemplate from '../../Component/MainTemplate/MainTemplate';

import ExplanationBlock from './components/ExplanationBlock/ExplanationBlock';
import TimelineBlock from './components/TimelineBlock/TimelineBlock';
import BoxLockBlock from './components/BoxLockBlock/BoxLockBlock';
import { Helmet } from 'react-helmet';
import StorageBlock from './components/Storage/Storage';
import HowtoBlock from './components/HowToBlock/HowToBlock';
import CarouselBlock from './components/CarouselBlock/CarouselBlock';
import { useDispatch, useSelector } from 'react-redux';
import { ProductsState } from '@type/Custom';
import { RootState } from '@src/store';
import { getProductsAction } from '@network/Actions/Products';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { DescriptionTypeEnum } from '@rootTypes/product-descriptions/description-type.enum';

const HomePage = () => {
  const dispatch = useDispatch();
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  useEffect(() => {
    dispatch(getProductsAction(true, ProductGroup.box));
  }, []);
  const carouselProducts = products.filter(
    (product) =>
      product.group === ProductGroup.box &&
      product.type === ProductType.storage &&
      product.isPromo,
  );
  return (
    <>
      <Helmet>
        <title>Bezpieczne przechowywanie rzeczy | skitraj.pl</title>
        <meta
          name="description"
          content="Gwarantujemy bezpieczne i dostosowane do Twoich potrzeb przechowywanie rzeczy w naszych mobilnych skrytkach! "
        />
        <meta name="robots" content="index,follow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MainTemplate>
        <StorageBlock />
        <HowtoBlock />
        <ExplanationBlock />
        <TimelineBlock />
        <CarouselBlock
          products={carouselProducts}
          descriptionPath={DescriptionTypeEnum.storage}
        />
        <BoxLockBlock />
      </MainTemplate>
    </>
  );
};

export default HomePage;
