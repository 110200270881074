import React from 'react';
import './PickupBoxesBeforeDeadlineModal.scss';
import { H5 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { textFormater } from '@util/TextFormater';

interface Props {
  setPickupBoxesBefore: (value: boolean) => void;
  setShowReceiveModal: (value: boolean) => void;
  selectedAllBoxLength: boolean;
  selectedBoxLength: number;
  storageTransportPrice: number;
  amortizationTransportPrice: number;
}

const PickupBoxesBeforeDeadlineModal: React.FC<
  React.PropsWithChildren<Props>
> = ({
  setPickupBoxesBefore,
  setShowReceiveModal,
  selectedAllBoxLength,
  selectedBoxLength,
  storageTransportPrice,
  amortizationTransportPrice,
}) => {
  return (
    <div className="pickup-before">
      {selectedAllBoxLength ? (
        <H5 className="pickup-before_text">
          {textFormater(translate('pickupBoxesBeforeDead.startTextAllBox'))}
          &nbsp;
          <span className="accent-color price-wrapping">
            {amortizationTransportPrice.toFixed(2)}{' '}
            {translate('default.currency')}.
          </span>
        </H5>
      ) : (
        <H5 className="pickup-before_text">
          {translate('pickupBoxesBeforeDead.startText')}&nbsp;
          <span className="accent-color price-wrapping">
            {selectedBoxLength * storageTransportPrice}{' '}
            {translate('default.currency')}{' '}
          </span>
          &nbsp;
          {translate('pickupBoxesBeforeDead.secondText')}
        </H5>
      )}

      <button
        onClick={() => {
          setShowReceiveModal(true);
          setPickupBoxesBefore(false);
        }}
        className="btn btn-100 orangebutton"
      >
        {translate('pickupBoxesBeforeDead.button')}
      </button>

      <button
        onClick={() => {
          setPickupBoxesBefore(false);
        }}
        className="btn btn-100 btn-dark"
      >
        {translate('pickupBoxesBeforeDead.secondButton')}
      </button>
    </div>
  );
};

export default PickupBoxesBeforeDeadlineModal;
