export enum Lang {
  en = 'en',
  pl = 'pl',
  ua = 'ua',
}

export type LanguageNameI = {
  [key in Lang]: string;
} & {
  [Lang.en]: string;
  [Lang.pl]: string;
  [Lang.ua]: string;
};
