import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { TransportPriceState } from '../../../../Type/Custom';
import EditTransportPrice from './components/EditComplaints/EditTransportPrice';
import { getTransportAction } from '../../../../Network/Actions/TransportPrice';
import { translate } from '../../../../Module/Translate/Translate';
import { UserProps } from '../../interfaces/interfaces';
import { formatDateToLocalTime } from '../../../../Util/Dates';

export default function TransportPrice({ user }: UserProps) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const { transport }: TransportPriceState = useSelector<
    RootState,
    TransportPriceState
  >(({ transportPrice }) => transportPrice);

  useEffect(() => {
    getData();
    setRefresh(false);
  }, [refresh]);

  const getData = async () => {
    setLoading(true);
    await dispatch(getTransportAction());
    setLoading(false);
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <EditTransportPrice
        transport={data}
        setRefresh={setRefresh}
        editor={user}
      />
    );
  };

  return (
    <div className="products-table-container">
      <DataTable
        value={transport}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        lazy
        loading={loading}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="id" sortable header="Id" />
        <Column field="type" header={translate('adminTool.transports.name')} />
        <Column
          field="price"
          header={translate('adminTool.transports.value')}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.transports.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.transports.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.transports.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
