import React, { useState } from 'react';
import Info from './components/Info/Info';
import './SupportTabBlock.scss';
import Modal from '@component/Modal/Modal';
import DeleteUserForm from '@form/DeleteUserForm/DeleteUserForm';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import P, { H1 } from '@component/Text/Text';
import { ContactForm } from '@form/ContactForm/ContactForm';
import { User } from '@models/user.model';
import { translate } from '@module/Translate/Translate';

interface Props {
  user: User;
  setSpinner: (value: boolean) => void;
}

export default function SupportTabBlock({ user, setSpinner }: Props) {
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [showError, setShowError] = useState(false);

  return (
    <>
      <Modal showModal={deleteUserModal} setShowModal={setDeleteUserModal}>
        <DeleteUserForm
          setShowModal={setDeleteUserModal}
          message={translate('contactForm.removeAccount.deleteModalText')}
          setSpinner={setSpinner}
          showError={(error) => setShowError(error)}
        />
      </Modal>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <div className="support-tab w-100">
        <div className="container-header support-wrapper">
          <H1 className="container-title support-title">
            {translate('supportTabBlock.contact')}
          </H1>
          <P className="container-subtitle support-text">
            {translate('supportTabBlock.writeToUs')}
          </P>
        </div>

        <ContactForm user={user} setSpinner={(value) => setSpinner(value)} />
        <Info setModal={setDeleteUserModal} />
      </div>
    </>
  );
}
