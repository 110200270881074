import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import {
  NEW_COMPLAINTS_FAIL,
  NEW_COMPLAINTS_REQUEST,
  NEW_COMPLAINTS_SUCCESS,
} from './Types';
import http from '../NetworkHTTP';

export const getNewComplaintsAction = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: NEW_COMPLAINTS_REQUEST,
      });

      const { data } = await http.get('complaints/count');

      dispatch({
        type: NEW_COMPLAINTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEW_COMPLAINTS_FAIL,
        payload: 0,
      });
    }
  };
};
