import React from 'react';
import Logo from '../../Asset/images/logo.png';
import Morcik_sad from '../../Asset/images/morcik_sad.png';
import Social from '../../Component/Social/Social';
import { H1, P } from '../../Component/Text/Text';
import { Link } from 'react-router-dom';
import './PageAccessDenied.scss';

const PageAccessDenied = () => {
  return (
    <>
      <div className="access-denied-section">
        <div className="access-denied_container container content padding20">
          <div className="row">
            <div className="col text">
              <div className="row">
                <Link className="m-auto" to={'/'}>
                  <img
                    src={Logo}
                    alt="Skitraj"
                    className="access-denied_logo logo"
                  />
                </Link>
              </div>
              <div className="row">
                <img
                  src={Morcik_sad}
                  alt="Skitraj"
                  className="access-denied_img"
                />
              </div>

              <H1 className="access-denied_title">pageAccessDenied.title</H1>
              <P className="access-denied_subtitle">
                pageAccessDenied.content.info
                <br />
                pageAccessDenied.content.promise
              </P>

              <Social />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageAccessDenied;
