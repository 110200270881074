import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import imageAdd from '@images/order-items/add-item.svg';
import imageAddHover from '@images/order-items/add-item--hover.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Product.scss';
import { translate } from '@module/Translate/Translate';
import { addProductInBasket } from '@network/Actions/Basket';
import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '@rootTypes/default-settings/default-settings-type.enum';
import { ImageTypeEnum } from '@rootTypes/product-images/image-type.enum';
import { RootState } from '@src/store';
import { BasketState, DefaultSettingsState } from '@type/Custom';
import { ShopMessage } from '../Toastify/Toastify';

interface IProps {
  productData: any;
  url: string;
}

export default function Product({ productData, url }: IProps) {
  const dispatch = useDispatch();
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);
  const [value, setValue] = useState(1);

  const stateProduct = state.products.find(
    (product) =>
      product.type === productData.type &&
      product.group === productData.group &&
      product.id === productData.id,
  );

  useEffect(() => {
    if (stateProduct) {
      setValue(+stateProduct.value + defaultCountForShop);
    }
  }, [productData, state]);

  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  const defaultCountForShop =
    defaultSettings.find(
      (setting) => setting.type === DefaultSettingsTypeEnum.defaultCountForShop,
    )?.limit || UndefinedSettingsType.undefinedCountForShop;

  const onBasketClick = (product: any) => {
    dispatch(
      addProductInBasket({ data: { ...product, value }, stepValueCounter: 1 }),
    );
    toast(<ShopMessage />, {
      closeOnClick: true,
      containerId: 'shop-product',
      autoClose: 1500,
    });
  };

  return (
    <div className="shop-product">
      <ToastContainer
        className="shop-toast"
        position="top-center"
        closeOnClick
        autoClose={1500}
        containerId="shop-product"
        enableMultiContainer
      />
      <button
        type="button"
        className="shop-productList_productAdded"
        data-e2e="add-shop-product"
        onClick={() => onBasketClick(productData)}
      >
        <div
          style={{ backgroundImage: `url(${imageAddHover})` }}
          className="shop-product_imageAdded shop-product_imageAdded--hover"
        />
        <div
          style={{ backgroundImage: `url(${imageAdd})` }}
          className="shop-product_imageAdded"
        />
      </button>
      <Link
        to={{
          pathname: `/${url}/${productData.pathUrl}`,
          state: { type: productData.group },
        }}
        className="shop-productList_link"
      >
        <img
          src={
            productData.images.find(
              (image: any) => image.type === ImageTypeEnum.miniature,
            )?.url
          }
          alt={productData.name}
          className="shop-product_imageItem"
        />
        <div className="shop-product_info">
          <p className="shop-product_name">{productData.name}</p>
          {productData.promotionPrice !== 0 ? (
            <div className="shop-product_price">
              <span className="old-price">
                {productData.price} {translate('default.currency')}
              </span>
              &nbsp;
              {productData.promotionPrice} {translate('default.currency')}
            </div>
          ) : (
            <div className="shop-product_price">
              {productData.price} {translate('default.currency')}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}
