import React, { useState } from 'react';
import './CardWrapper.scss';
import AddCard from '../AddCard/AddCard';
import CreditCardItem from '../CreditCardItem/CreditCardItem';
import ModalCard from '../ModalCard/ModalCard';
import ModalDelete from '../ModalDelete/ModalDelete';
import { translate } from '../../../../../../Module/Translate/Translate';

interface IData {
  name: string;
  number: number;
  month: number;
  year: number;
  CVV: number;
  checked: boolean;
  id: number;
}

const cardData = {
  name: 'Kamil Nowak',
  number: 1225122344564253,
  month: 2,
  year: 26,
  CVV: 0,
  checked: true,
  id: 123,
};
const CardWrapper = () => {
  const [modalAddShow, setModalAddShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(false);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [data, setData] = useState<IData | null>(cardData);

  return (
    <>
      {modalAddShow && (
        <ModalCard
          title={translate('dashBoard.cardWrapper.addCard')}
          onClick={() => setModalAddShow(!modalAddShow)}
          closeModal={() => setModalAddShow(!modalAddShow)}
        />
      )}
      {modalEditShow && (
        <ModalCard
          title={translate('dashBoard.cardWrapper.editCard')}
          onClick={() => setModalEditShow(!modalEditShow)}
          closeModal={() => setModalEditShow(!modalEditShow)}
          cardData={data}
        >
          <button type="submit" className="removeBtn">
            {translate('dashBoard.cardWrapper.deleteCard')}
          </button>
        </ModalCard>
      )}
      {modalDeleteShow && data && (
        <ModalDelete
          data={data}
          closeModal={() => setModalDeleteShow(!modalDeleteShow)}
          deleteCard={() => setData(null)}
        />
      )}

      {data ? (
        <>
          <div className="cardwrapper">
            <CreditCardItem
              removeAddress={() => setModalDeleteShow(!modalDeleteShow)}
              item={data}
              editAddress={() => setModalEditShow(!modalEditShow)}
            />
          </div>
        </>
      ) : (
        <AddCard addCardModal={() => setModalAddShow(!modalAddShow)} />
      )}
    </>
  );
};

export default CardWrapper;
