import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import './ProductDetail.scss';
import baner from '@images/rent-page/baner.jpg';
import morcik from '@images/rent-page/morcik-thinks.png';
import morcikMMobile from '@images/rent-page/morcik-thinks_mobile.png';
import ArrowBack from '@images/shop/arrow-back.png';
import MainTemplate from '@component/MainTemplate/MainTemplate';
import { translate } from '@module/Translate/Translate';
import {
  removeProductFromBasket,
  addProductInBasket,
  decreaseProductInBasket,
} from '@network/Actions/Basket';
import { getProductsByPathAction } from '@network/Actions/Products';
import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '@rootTypes/default-settings/default-settings-type.enum';
import { DescriptionTypeEnum } from '@rootTypes/product-descriptions/description-type.enum';
import { ImageTypeEnum } from '@rootTypes/product-images/image-type.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { RootState } from '@src/store';
import { ProductsState, DefaultSettingsState, BasketState } from '@type/Custom';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Counter from '@view/Shop/Components/Counter/Counter';
import { Helmet } from 'react-helmet';

const RentProdcutDetails: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { pathUrl } = useParams<{ pathUrl: string }>();

  useEffect(() => {
    dispatch(getProductsByPathAction(pathUrl, ProductType.rent));
  }, []);

  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  const [currentImg, setCurrentImg] = useState<null | any>(null);

  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  const defaultCountForPackage =
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultCountForPackage,
    )?.limit || UndefinedSettingsType.undefinedCountForPackage;

  const [packageItem, setPackage] = useState<null | any>(null);
  const [value, setValue] = useState<number>(0);

  const stateBasket = useSelector<RootState, BasketState>(
    ({ basket }) => basket,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    products.find((item) => {
      if (item.pathUrl === pathUrl) {
        setPackage(item);
      }
    });
  }, [pathUrl, products]);

  useEffect(() => {
    packageItem &&
      setCurrentImg(
        packageItem.images.find(
          (image: any) => image.type === ImageTypeEnum.offer,
        ),
      );
  }, [packageItem]);

  const filteredItem =
    packageItem &&
    stateBasket.products.find(
      (item) =>
        item.type === packageItem.type &&
        item.group === packageItem.group &&
        item.id === packageItem.id,
    );

  useEffect(() => {
    filteredItem ? setValue(filteredItem.value) : setValue(0);
  }, [filteredItem]);

  const onCounterIncreaseClick = (value: any) => {
    setValue(value);
  };

  const onCounterDecreaseClick = (value: any) => {
    if (value === 0) dispatch(removeProductFromBasket(packageItem));
    setValue(value);
  };

  const onHandleClick = () => {
    if (filteredItem) {
      if (filteredItem.value < value) {
        dispatch(
          addProductInBasket({
            data: { ...packageItem, value },
            stepValueCounter: value - filteredItem.value,
          }),
        );
      } else if (filteredItem.value > value) {
        dispatch(
          decreaseProductInBasket(
            { ...packageItem, value },
            filteredItem.value - value,
          ),
        );
      } else if (filteredItem.value === value) {
        return;
      }
    } else {
      dispatch(
        addProductInBasket({
          data: { ...packageItem, value },
        }),
      );
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  const history = useHistory();
  const handleClick = () => {
    history.push('/wynajem');
  };

  return (
    <MainTemplate>
      <Helmet>
        <title>
          Wygodne pudełka na{' '}
          {packageItem
            ? 'wynajem: ' + packageItem.name
            : window.location.pathname.substring(1)}{' '}
          | skitraj.pl
        </title>
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="description"
          content="Zapewnij swoim rzeczom bezpieczną podróż, a sobie wygodę dzięki wynajmowi naszych wytrzymałych i ekologicznych pudełek."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {packageItem && (
        <>
          <div className="container detailWrapper">
            <div className="imagesWrapper">
              <button
                type="button"
                className="button-back mobile"
                onClick={handleClick}
              >
                <img src={ArrowBack} alt="arrow back" />
              </button>
              <img
                src={currentImg?.url}
                alt="currentImg"
                className="imagesWrapper_package desktop"
              />
              <div className="galery desktop">
                {packageItem.images
                  .filter((item: any) => item.type === ImageTypeEnum.gallery)
                  ?.filter((item: any) => item.id !== currentImg?.id)
                  .map((item: any, idx: number) => (
                    <img
                      src={item.url}
                      key={idx}
                      onClick={() => setCurrentImg(item)}
                      alt="package"
                    />
                  ))}
                {currentImg?.id !==
                  packageItem.images.find(
                    (image: any) => image.type === ImageTypeEnum.offer,
                  )?.id && (
                  <img
                    alt="package"
                    src={
                      packageItem.images.find(
                        (image: any) => image.type === ImageTypeEnum.offer,
                      )?.url
                    }
                    onClick={() =>
                      setCurrentImg(
                        packageItem.images.find(
                          (image: any) => image.type === ImageTypeEnum.offer,
                        ),
                      )
                    }
                  />
                )}
              </div>
              <div className="mobile slider">
                <Slider {...settings}>
                  {packageItem.images
                    .filter((item: any) => item.type === ImageTypeEnum.gallery)
                    ?.map((item: any, idx: number) => (
                      <img src={item.url} alt="package" key={idx} />
                    ))}
                </Slider>
              </div>
            </div>

            <div className="detailInfo">
              <div className="detailInfo_header">
                <p className="detailInfo_header_type desktop">
                  {translate('rentProduct.package')}
                </p>
                <div className="detailInfo_header_price">
                  <p>
                    {packageItem.name.split(' ')[0]}&nbsp;
                    {packageItem.name.split(' ')[1]}
                  </p>

                  <span className="price_value">
                    {packageItem.weekPrice} {translate('default.currency')}
                  </span>
                </div>
              </div>
              <div className="mobile dropdown-group">
                <Counter
                  onCounterIncreaseClick={(value) =>
                    onCounterIncreaseClick(value)
                  }
                  onCounterDecreaseClick={(value) =>
                    onCounterDecreaseClick(value)
                  }
                  counterValue={value}
                  stepValueCounter={defaultCountForPackage}
                />
                <Link
                  to={{
                    pathname: '/zamowienie',
                    state: {
                      defaultIndex: 1,
                    },
                  }}
                  className={`btn-add ${value === 0 ? 'disabled' : ''}`}
                  onClick={() => onHandleClick()}
                >
                  {translate('rentProduct.buttonText')}
                </Link>
              </div>
              <p className="mobile package-name">
                {packageItem.name.split(' ')[0]} &nbsp;
                {packageItem.name.split(' ')[1]}
              </p>
              <p>
                <span className="accent-color">
                  {packageItem.weekPrice} {translate('default.currency')} &nbsp;
                </span>
                {translate('rentProduct.vat')} &nbsp;
                <span className="accent-color">
                  {packageItem.promotionPrice} {translate('default.currency')}
                </span>
              </p>
              <p className="standardText">
                {packageItem.descriptions
                  .filter(
                    (item: any) => item.type === DescriptionTypeEnum.offer,
                  )
                  ?.map((item: any) => item.text)}
              </p>
              <div className="d-flex justify-content-between desktop">
                <Counter
                  onCounterIncreaseClick={(value) =>
                    onCounterIncreaseClick(value)
                  }
                  onCounterDecreaseClick={(value) =>
                    onCounterDecreaseClick(value)
                  }
                  counterValue={value}
                  stepValueCounter={defaultCountForPackage}
                />
                <Link
                  to={{
                    pathname: '/zamowienie',
                    state: {
                      defaultIndex: 1,
                    },
                  }}
                  className={`btn-add ${value === 0 ? 'disabled' : ''}`}
                  onClick={() => onHandleClick()}
                >
                  {translate('rentProduct.buttonText')}
                </Link>
              </div>

              <img
                src={
                  packageItem.images.find(
                    (image: any) => image.type === ImageTypeEnum.offer,
                  )?.url
                }
                alt="package"
                className="detailInfo-mobile-image"
              />
              <div className="howWork">
                <p className="howWork_title">
                  {translate('rentProduct.howToWorkTitle')}
                </p>
                <p className="howWork_text">
                  {translate('rentProduct.howToWorkText')}
                </p>
              </div>
            </div>
          </div>
          <div className="morcik desktop container">
            <img src={morcik} alt="morcik" />
          </div>
          <div className="container inside_pacakge">
            <div className="inside_pacakge_left">
              <p className="inside_pacakge_left_title">
                {translate('rentProduct.listTitle')}
              </p>
              <p className="inside_pacakge_left_text desktop">
                {translate('rentProduct.listText')}
              </p>
              <ul className="inside_pacakge_left_list">
                {packageItem.packages.map((item: any, idx: number) => (
                  <li key={idx}>
                    <span className="accent-color">{`${item.quantity} x `}</span>
                    {item.child.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="morcik-mobile row no-gutters mobile">
            <img src={morcikMMobile} alt="morcik" />
          </div>

          <div className="banner desktop">
            <img src={baner} alt="baner" />
          </div>
        </>
      )}
    </MainTemplate>
  );
};

export default RentProdcutDetails;
