import React, { useRef } from 'react';
import './EditDeliveryStatuses.scss';
import { translate } from '../../../../../../Module/Translate/Translate';
import { useFormik } from 'formik';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { editDeliveryStatusesAction } from '../../../../../../Network/Actions/DeliveryStatuses';
import { StockStatus } from '../../../../../../../../types/stocks/stock-status.enum';

export default function EditDeliveryStatuses({
  deliveryStatus,
  setRefresh,
  editor,
}: any) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      warehouse: deliveryStatus.warehouse,
      providerType: deliveryStatus.providerType,
      provider: deliveryStatus.provider,
      providerText: deliveryStatus.providerText,
      status: deliveryStatus.status,
      updatedBy: editor.email,
    },

    onSubmit: (values: any) => {
      editDeliveryStatusesAction(deliveryStatus.id, {
        warehouse: values.warehouse,
        providerType: values.providerType,
        provider: values.provider,
        providerText: values.providerText,
        status: values.status,
        updatedBy: editor.email,
      })
        .then(() => {
          setRefresh(true);
          showMessage('success', translate('default.message.success'));
        })
        .catch((error: any) => {
          showMessage('error', translate('default.message.fail') + error);
        });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const statuses = [
    {
      label: translate('adminTool.storage.readyForSent'),
      value: StockStatus.STOCK_RFS,
    },
    {
      label: translate('adminTool.storage.sendedToTheCustomer'),
      value: StockStatus.STOCK_STTC,
    },
    {
      label: translate('adminTool.storage.atTheCustomer'),
      value: StockStatus.STOCK_ATC,
    },
    {
      label: translate('adminTool.storage.requestForReturnFull'),
      value: StockStatus.STOCK_RFRF,
    },
    {
      label: translate('adminTool.storage.inWarehouse'),
      value: StockStatus.STOCK_IW,
    },
    {
      label: translate('adminTool.storage.requestForDeliver'),
      value: StockStatus.STOCK_RFD,
    },
    {
      label: translate('adminTool.storage.atTheCustomerFull'),
      value: StockStatus.STOCK_ATCF,
    },
    {
      label: translate('adminTool.storage.requestForReturnEmpty'),
      value: StockStatus.STOCK_RFRE,
    },
    {
      label: translate('adminTool.storage.returned'),
      value: StockStatus.STOCK_R,
    },
  ];

  return (
    <div className="delivery-statuses-main-container border-details">
      <div className="delivery-statuses-header-container">
        <Messages ref={message} />
      </div>
      <div className="delivery-statuses-data-container">
        <div className="delivery-statuses-top-container">
          <div className="delivery-statuses-info-right-container">
            <form
              className="delivery-statuses-form"
              onSubmit={formik.handleSubmit}
            >
              <label className="rents-h5">
                {translate('adminTool.deliveryStatuses.status')}
              </label>
              <Dropdown
                value={formik.values.status}
                options={statuses}
                className="type-dropdown"
                name="status"
                optionLabel="label"
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <FormFieldFormik
                name="warehouse"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.warehouse}
                label={translate('adminTool.deliveryStatuses.warehouse')}
                onBlur={formik.handleBlur}
              />
              <FormFieldFormik
                name="providerType"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.providerType}
                label={translate('adminTool.deliveryStatuses.providerType')}
                onBlur={formik.handleBlur}
              />
              <FormFieldFormik
                name="providerText"
                type="text"
                fieldType={'textArea'}
                onChange={formik.handleChange}
                value={formik.values.providerText}
                label={translate('adminTool.deliveryStatuses.providerText')}
                onBlur={formik.handleBlur}
              />
              <FormFieldFormik
                name="provider"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.provider}
                label={translate('adminTool.deliveryStatuses.provider')}
                onBlur={formik.handleBlur}
              />
              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept`}
              >
                {translate('adminTool.defaultSettings.btn')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
