import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import {
  ANALYTICS_FULFILLED,
  ANALYTICS_PENDING,
  ANALYTICS_REJECTED,
} from './Types';

export interface AnalyticsData {
  GA?: string;
  Meta?: string;
}

export const analyticsChange = (
  analyticsData: AnalyticsData,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch) => {
    try {
      dispatch({
        type: ANALYTICS_PENDING,
      });
      dispatch({
        type: ANALYTICS_FULFILLED,
        payload: analyticsData,
      });
    } catch (err) {
      dispatch({
        type: ANALYTICS_REJECTED,
        payload: 'Nie udało się.',
      });
    }
  };
};
