import i18n from 'i18next';
import {
  initReactI18next,
  withTranslation,
  useTranslation,
  TFunction,
} from 'react-i18next';
import {
  LanguageName,
  DefaultLanguage,
  DefaultSeparator,
} from './Translate.const';
import { Lang } from './Translate.type';
import resources from './Translate.resources';
import { isString } from '@util/Check';

const t: TFunction = i18n.t.bind(i18n);

const translation: Promise<TFunction> = i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  lng: DefaultLanguage,
  keySeparator: DefaultSeparator,
  interpolation: {
    escapeValue: false,
  },
});

const getLanguage = (): Lang => i18n.language as Lang;

const getLanguageName = (language?: Lang): string =>
  LanguageName[language || getLanguage()];

const setLanguage = (lang: Lang): Promise<TFunction> =>
  i18n.changeLanguage(lang);

const translateHOC = (parse: any) => (children: any) =>
  !Array.isArray(children) && isString(children)
    ? parse((children as string) || '')
    : Array.isArray(children)
    ? (children as string[]).map(parse)
    : children;

const translate = translateHOC((el: any, defaultLang = DefaultLanguage) => {
  try {
    if (isString(el)) {
      if (t(el) === '') {
        const translation = i18n.t(el, { lng: defaultLang });
        return translation === '' ? el : translation;
      } else {
        return t(el);
      }
    } else {
      return el;
    }
  } catch (error) {
    return el;
  }
});

export {
  i18n,
  t,
  withTranslation,
  useTranslation,
  getLanguage,
  getLanguageName,
  setLanguage,
  translateHOC,
  translate,
};

export default translation;
