import { translate } from '@module/Translate/Translate';
import React from 'react';

interface IUserType {
  userType: string;
  setUserType: any;
}

export default function UserTypeSwitch({ userType, setUserType }: IUserType) {
  return (
    <div className="user-type">
      <div
        className={`form-check-inline ${
          userType === 'individual' ? 'active' : ''
        }`}
      >
        <input
          className="custom-checkbox form-check-input"
          type="radio"
          name="profile_type"
          id="profile_type1"
          data-e2e="individual-checkbox"
          value="individual"
          checked={userType === 'individual'}
          onChange={(e) => setUserType(e.target.value)}
        />

        <label
          className={`form-check-label ${
            userType === 'individual' ? 'active' : ''
          }`}
          data-e2e="individual-label"
          htmlFor="profile_type1"
        >
          {translate('registrationForm.individual')}
        </label>
      </div>

      <div className="user-type_company form-check form-check-inline">
        <input
          className="custom-checkbox form-check-input"
          type="radio"
          name="profile_type"
          id="profile_type2"
          data-e2e="company-checkbox"
          value="company"
          checked={userType === 'company'}
          onChange={(e) => setUserType(e.target.value)}
        />

        <label
          className={`form-check-label ${
            userType === 'company' ? 'active' : ''
          }`}
          data-e2e="company-label"
          htmlFor="profile_type2"
        >
          {translate('registrationForm.company')}
        </label>
      </div>
    </div>
  );
}
