import phone from '../../../../Asset/images/rent-page/phone.png';
import phoneMobile from '../../../../Asset/images/rent-page/phone-mobile.png';
import box from '../../../../Asset/images/rent-page/box.png';
import boxMobile from '../../../../Asset/images/rent-page/box-mobile.png';
import boxFull from '../../../../Asset/images/rent-page/boxFull.png';
import boxFullMobile from '../../../../Asset/images/rent-page/boxFull-mobile.png';
import { translate } from '@module/Translate/Translate';

export const data = [
  {
    id: 1,
    image: phone,
    imageMobile: phoneMobile,
    title: translate('rentPage.howToBlock.data.item-1.title'),
    subtitle: translate('rentPage.howToBlock.data.item-1.subtitle'),
  },
  {
    id: 2,
    image: box,
    imageMobile: boxMobile,
    title: translate('rentPage.howToBlock.data.item-2.title'),
    subtitle: translate('rentPage.howToBlock.data.item-2.subtitle'),
  },
  {
    id: 3,
    image: boxFull,
    imageMobile: boxFullMobile,
    title: translate('rentPage.howToBlock.data.item-3.title'),
    subtitle: translate('rentPage.howToBlock.data.item-3.subtitle'),
  },
  {
    id: 4,
    image: box,
    imageMobile: boxMobile,
    title: translate('rentPage.howToBlock.data.item-4.title'),
    subtitle: translate('rentPage.howToBlock.data.item-4.subtitle'),
  },
];
