import React, { useEffect, useMemo, useState } from 'react';
import './ReturnBoxesForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { disabledDays } from '@util/DisabledDays';
import { selectedDaysArray } from '@util/SelectedDaysArray';
import AddressInformation from '@component/AddressInformation/AddressInformation';
import P, { H1, H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { getLimitsAction } from '@network/Actions/Limits';
import { getOrderBlockedDaysAction } from '@network/Actions/OrderBlockedDays';
import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '@rootTypes/default-settings/default-settings-type.enum';
import { FindOrderDto } from '@rootTypes/orders/find-order.dto';
import { FindRentStockDto } from '@rootTypes/stocks/find-rent-stock.dto';
import { FindStockDto } from '@rootTypes/stocks/find-stock.dto';
import { StockStatus } from '@rootTypes/stocks/stock-status.enum';
import { UpdateStockDto } from '@rootTypes/stocks/update-stock.dto';
import { RootState } from '@src/store';
import { DefaultSettingsState, OrderBlockedDaysState } from '@type/Custom';
import { dateGetTime } from '@util/DateGetTime';
import unifiedFormatDate from '@util/Dates';
import { textFormaterOrder } from '@util/TextFormater';

interface Props {
  setShowConfirm: any;
  order?: FindOrderDto;
  setVerifyReturnModal?: any;
  stock?: FindRentStockDto;
  setEditedBoxes?: (box: any) => void;
  date: (date: Date) => void;
  currentStorageStock?: FindStockDto[];
}

const ReturnBoxesForm: React.FC<React.PropsWithChildren<Props>> = ({
  setShowConfirm,
  order = null,
  stock = null,
  setVerifyReturnModal,
  setEditedBoxes,
  date,
  currentStorageStock = null,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderBlockedDaysAction());
    dispatch(getLimitsAction());
  }, []);

  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  const defaultOrderClosingTime =
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultOrderClosingTime,
    )?.limit || UndefinedSettingsType.undefinedOrderClosingTime;

  const { dates }: OrderBlockedDaysState = useSelector<
    RootState,
    OrderBlockedDaysState
  >(({ orderBlockedDates }) => orderBlockedDates);

  const disabledAdminDays = dates.map((day) => day.date);

  const holidays = useMemo(
    () => disabledDays(disabledAdminDays),
    [disabledAdminDays],
  );

  const days = useMemo(() => {
    return selectedDaysArray({
      arrayLength: 6,
      holidays,
      defaultOrderClosingTime,
    });
  }, []);

  const weekday = [
    translate('returnBoxesForm.weekdays.0'),
    translate('returnBoxesForm.weekdays.1'),
    translate('returnBoxesForm.weekdays.2'),
    translate('returnBoxesForm.weekdays.3'),
    translate('returnBoxesForm.weekdays.4'),
    translate('returnBoxesForm.weekdays.5'),
    translate('returnBoxesForm.weekdays.6'),
  ];

  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [currentServiceDaysIndex, setCurrentServiceDaysIndex] =
    useState<any>(null);
  const [nextServiceDaysIndex, setNextServiceDaysIndex] = useState<any>(null);

  const handleSubmit = function () {
    if (currentStorageStock) {
      const editedBoxes:
        | UpdateStockDto[]
        | {
            id: number;
            returnDate: Date;
            stockStatus: StockStatus;
          }[] = [];
      currentStorageStock.map((item: FindStockDto) => {
        editedBoxes.push({
          id: item.id,
          returnDate: new Date(selectedDate),
          stockStatus: item.stockStatus,
        });
      });
      setEditedBoxes && setEditedBoxes(editedBoxes);
    }
    date(new Date(selectedDate));
    setVerifyReturnModal(true);
    setShowConfirm(false);
  };

  const currentServiceDays =
    (order &&
      currentStorageStock &&
      days.filter(
        (day) =>
          dateGetTime(day) <=
          dateGetTime(new Date(currentStorageStock[0].endDate)),
      )) ||
    (stock &&
      days.filter(
        (day) => dateGetTime(day) <= dateGetTime(new Date(stock.endDate)),
      ));

  const nextServiceDays =
    (order &&
      currentStorageStock &&
      days.filter(
        (day) =>
          dateGetTime(day) >
          dateGetTime(new Date(currentStorageStock[0].endDate)),
      )) ||
    (stock &&
      days.filter(
        (day) => dateGetTime(day) > dateGetTime(new Date(stock.endDate)),
      ));
  const boxesList =
    currentStorageStock &&
    currentStorageStock
      .map((i, index) => (index > 0 ? '/' : '') + i.stockNumber)
      .toString();

  return (
    <>
      <div className="return-boxes">
        <H1 className="return-boxes_title">
          {translate('returnBoxesForm.orderCourier')}
        </H1>
        {order && (
          <div className="row return-boxes_info-container">
            <div className="col col-12 col-md-7 return-boxes_text-group">
              <H3 className="return-boxes_storage-title mb-1 text-left">
                {translate('returnBoxesForm.storage')}
              </H3>
              {order.id && (
                <H3 className="return-boxes_list text-left mb-4">
                  <span>{translate('returnBoxesForm.orderNo')}</span>
                  <span>{order.id}</span>
                </H3>
              )}

              {currentStorageStock && (
                <>
                  <H3 className="receive-boxes_list text-left mb-3">
                    <span>{currentStorageStock.length}</span>
                    <span> x {translate('receiveBoxesForm.box')}</span>
                  </H3>
                  <H3 className="receive-boxes_list text-left mb-3">
                    <span className="list-text">
                      {textFormaterOrder(boxesList, 48)}
                    </span>
                  </H3>
                </>
              )}
            </div>
            <div className="address-container col col-12 col-md-5">
              <H3 className="return-boxes_storage-title mb-1 text-left">
                <span>{translate('returnBoxesForm.receivingAddress')}</span>
              </H3>
              {currentStorageStock && (
                <AddressInformation
                  item={currentStorageStock[0].returnAddress}
                />
              )}
            </div>
          </div>
        )}

        {stock && (
          <div className="row return-boxes_info-container">
            <div className="col col-12 col-md-7 return-boxes_text-group">
              <H3 className="return-boxes_storage-title mb-1 text-left">
                {translate('returnBoxesForm.rent')}
              </H3>
              <H3 className="receive-boxes_list text-left mb-4">
                <span>
                  {stock.products && stock.products.length === 1
                    ? stock.products[0].name
                    : translate('myRentalBlock.rentalBox.mixName')}
                </span>
              </H3>
              {stock.products.map((pack, idx) => (
                <div key={idx} className="text-left">
                  <span className="return-boxes_storage-title_name">
                    {pack.quantity} x {pack.name}
                  </span>
                </div>
              ))}
            </div>
            <div className="address-container col col-12 col-md-5">
              <H3 className="return-boxes_storage-title mb-1 text-left">
                <span>{translate('returnBoxesForm.receivingAddress')}</span>
              </H3>
              <AddressInformation item={stock.returnAddress} />
            </div>
          </div>
        )}

        <div className="return-boxes_container mb-4">
          <H1 className="return-boxes_date-title">
            {translate('returnBoxesForm.departureDate')}
          </H1>
          <P className="return-boxes_date-subtitle">
            {translate('returnBoxesForm.chooseDate')}&nbsp;
            {translate('returnBoxesForm.storageBoxes')}
          </P>
          {currentServiceDays && currentServiceDays.length !== 0 && (
            <div className="delivery-date row">
              {currentServiceDays.map((item, index) => (
                <div key={index} className="delivery-date_col col">
                  <div
                    className={`delivery-date_item text-center ${
                      index === currentServiceDaysIndex ? 'active' : ''
                    }`}
                    onClick={() => {
                      setNextServiceDaysIndex(null);
                      setCurrentServiceDaysIndex(index);
                      setSelectedDate(item);
                    }}
                  >
                    {weekday[item.getDay()]} <br />
                    {unifiedFormatDate(item)}
                  </div>
                </div>
              ))}
            </div>
          )}
          {nextServiceDays && nextServiceDays.length !== 0 && (
            <>
              {stock && (
                <P className="return-boxes_date-subtitle">
                  {translate('returnBoxesForm.choiceOfDates')}&nbsp;
                  {unifiedFormatDate(
                    new Date(
                      stock.continuationDate
                        ? stock.continuationDate
                        : stock.endDate,
                    ),
                  )}
                  ,&nbsp;
                  {translate('returnBoxesForm.weWillAddAFeeRent')}
                  {translate('returnBoxesForm.rentOfBoxes')}
                </P>
              )}
              {order && currentStorageStock && (
                <P className="return-boxes_date-subtitle">
                  {translate('returnBoxesForm.choiceOfDates')},&nbsp;
                  {unifiedFormatDate(new Date(currentStorageStock[0].endDate))}
                  ,&nbsp;
                  {translate('returnBoxesForm.weWillAddAFee')}
                  <br />
                  {translate('returnBoxesForm.storageOfBoxes')}
                </P>
              )}

              <div className="next-month-date row">
                {nextServiceDays.map((item, index) => (
                  <div key={index} className="col">
                    <div
                      className={`next-month-date_item text-center ${
                        index === nextServiceDaysIndex ? 'active' : ''
                      }`}
                      onClick={() => {
                        setCurrentServiceDaysIndex(null);
                        setNextServiceDaysIndex(index);
                        setSelectedDate(item);
                      }}
                    >
                      {weekday[item.getDay()]} <br />
                      {unifiedFormatDate(item)}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <button
          type="submit"
          className="contact-form_button btn mx-auto w-100"
          disabled={!selectedDate}
          onClick={handleSubmit}
        >
          {translate('returnBoxesForm.orderCourier')}
        </button>
      </div>
    </>
  );
};

export default ReturnBoxesForm;
