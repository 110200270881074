import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import AddressBlock from './components/AddressBlock/AddressBlock';
import PersonDataBlock from './components/PersonDataBlock/PersonDataBlock';
import SupportTabBlock from './components/SupportTabBlock/SupportTabBlock';
import ReclamationTab from './components/ReclamationBlock/ReclamationTab';
import OrderHistory from './components/OrderHistory/OrderHistory';
import CreditCards from './components/CreditCardsBlock/CreditCards';
import HidingPlaceBlock from './components/HidingPlaceBlock/HidingPlaceBlock';
import MessagesBlock from './components/MessagesBlock/MessagesBlock';

import './Dashboard.scss';
import Payment from './components/Payment/Payment';
import MyRentalBlock from './components/MyRentalBlock/MyRentalBlock';
import PaymentHistory from './components/PaymentHistory/PaymentHistory';
import { useDispatch, useSelector } from 'react-redux';
import LogoutBlock from './components/LogoutBlock/LogoutBlock';
import MainTemplate from '@component/MainTemplate/MainTemplate';
import DashboardSideBar from '@component/DashboardSideBar/DashboardSideBar';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import {
  incrementUnreadNotificationsAction,
  pushNotificationAction,
} from '@network/Actions/Notifications';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { SERVER_URL } from '@/envConfig';
import { FindNotificationDto } from '@rootTypes/notifications/find-notification.dto';
import { currentUserAction } from '@network/Actions/CurrentUser';
import Spinner from '@component/Spinner/Spinner';
import { Helmet } from 'react-helmet';

const Dashboard = ({ currentPanel }: any) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector<RootState, UserState>((state) => state.user);
  const dispatch = useDispatch();
  const { state = { activePanel: currentPanel || 'messages' } }: any =
    useLocation();
  const [visibleSpinner, setVisibleSpinner] = useState(false);
  useEffect(() => {
    const es = new EventSourcePolyfill(
      `${SERVER_URL}/api/users/${user.id}/notifications/sse`,
      {
        withCredentials: true,
      },
    );
    es.addEventListener('message', async (event: MessageEventInit) => {
      const parsedData = JSON.parse(event.data);
      const notification = new FindNotificationDto(parsedData);
      if (!notification) return;
      await dispatch(pushNotificationAction(notification));
      dispatch(incrementUnreadNotificationsAction());
    });
    return () => {
      es.close();
    };
  }, []);

  // page on new URL
  const changeUrl = true;
  const setActivePanel = useCallback(
    (item: any) => {
      if (changeUrl) {
        history.push(item.href);
      } else {
        history.push({ ...location, state: { activePanel: item.template } });
      }
    },
    [history, location],
  );

  const refreshUser = () => {
    dispatch(currentUserAction());
  };

  const renderActivePanel = function () {
    switch (state.activePanel) {
      case 'messages':
        return <MessagesBlock />;
      case 'personal':
        return (
          <PersonDataBlock
            refreshUser={refreshUser}
            setActivePanel={setActivePanel}
            user={user}
            setSpinner={(value) => setVisibleSpinner(value)}
          />
        );
      case 'address':
        return (
          <AddressBlock
            refreshUser={refreshUser}
            addresses={user.addresses}
            user={user}
            setSpinner={(value) => setVisibleSpinner(value)}
          />
        );
      case 'hidingPlace':
        return (
          <HidingPlaceBlock
            userId={user.id}
            setSpinner={(value) => setVisibleSpinner(value)}
          />
        );
      case 'myRental':
        return (
          <MyRentalBlock
            userId={user.id}
            setSpinner={(value) => setVisibleSpinner(value)}
          />
        );
      case 'orderHistory':
        return <OrderHistory userId={user.id} />;
      case 'payFor':
        return (
          <Payment
            user={user}
            setSpinner={(value) => setVisibleSpinner(value)}
          />
        );
      case 'paymentHistory':
        return (
          <PaymentHistory
            userId={user.id}
            setSpinner={(value) => setVisibleSpinner(value)}
          />
        );
      case 'creditCards':
        return <CreditCards />;
      case 'contact':
        return (
          <SupportTabBlock
            user={user}
            setSpinner={(value) => setVisibleSpinner(value)}
          />
        );
      case 'complaints':
        return (
          <ReclamationTab
            user={user}
            setSpinner={(value) => setVisibleSpinner(value)}
          />
        );
      case 'logout':
        return <LogoutBlock />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Skitraj.pl | {window.location.pathname.substring(1)}</title>
        <meta name="description" content="Panel klienta" />
        <meta name="robots" content="noindex,nofollow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MainTemplate customFooter={true}>
        <div className="dashboard-block">
          <div className="dashboard-block_container container">
            {visibleSpinner && <Spinner />}

            <DashboardSideBar
              setActivePanel={setActivePanel}
              activePanel={state.activePanel}
            />
            <div className="flex main_wrapper">{renderActivePanel()}</div>
          </div>
        </div>
      </MainTemplate>
    </>
  );
};
export default Dashboard;
