import { ProductType } from '@rootTypes/products/product-type.enum';
import { dateGetTime } from '@util/DateGetTime';
import unifiedFormatDate from '@util/Dates';
import { initEndDateOfPeriod } from '@util/InitEndDateOfPeriod';
import { selectedDaysArray } from '@util/SelectedDaysArray';

interface ICalculateLastDate {
  stateLastDate: Date;
  holidays: Date[];
  defaultOrderClosingTime: number;
  dayBeforeMinLastDate: Date;
  maxDate: Date;
}

export const calculateLastDate = ({
  stateLastDate,
  holidays,
  defaultOrderClosingTime,
  dayBeforeMinLastDate,
  maxDate,
}: ICalculateLastDate) => {
  const filteredDays = selectedDaysArray({
    arrayLength: 1,
    holidays,
    defaultOrderClosingTime,
    newDate: dayBeforeMinLastDate,
  });

  if (
    stateLastDate &&
    dateGetTime(stateLastDate) > dateGetTime(filteredDays[0])
  ) {
    if (dateGetTime(stateLastDate) > dateGetTime(maxDate)) {
      return maxDate;
    }
    return stateLastDate;
  }
  return filteredDays[0];
};

interface IcalculateLastDateWithCalculatorRange {
  type: ProductType.rent | ProductType.storage;
  dateStarted: Date;
  dayBeforeMinLastDate: Date;
  maxDate: Date;
  startDate: Date;
  range: number;
  holidays: Date[];
  defaultOrderClosingTime: number;
}

export const calculateLastDateWithCalculatorRange = ({
  type,
  dateStarted,
  dayBeforeMinLastDate,
  maxDate,
  startDate,
  range,
  holidays,
  defaultOrderClosingTime,
}: IcalculateLastDateWithCalculatorRange) => {
  const endDateOfPeriod =
    type === ProductType.storage
      ? initEndDateOfPeriod({
          startDate,
          type,
          rangeDate: range - 1,
        })
      : new Date(
          new Date(startDate).setUTCDate(
            dateStarted.getDate() + (range - 1) * 7 - 1,
          ),
        );

  const filteredDays = selectedDaysArray({
    arrayLength: 1,
    holidays,
    defaultOrderClosingTime,
    newDate:
      range === 1
        ? new Date(dateGetTime(dayBeforeMinLastDate))
        : new Date(dateGetTime(endDateOfPeriod)),
  });

  if (dateGetTime(filteredDays[0]) > dateGetTime(maxDate)) {
    return maxDate;
  }
  return filteredDays[0];
};

interface ICalculateLastDaysArray {
  holidays: Date[];
  startDate: Date;
  maxLastDate: number;
}

export const calculateLastDaysArray = ({
  holidays,
  startDate,
  maxLastDate,
}: ICalculateLastDaysArray) => {
  const firstDate = new Date(startDate);

  const array: Date[] = [];
  const dayIndexSaturday = 6;
  const dayIndexSunday = 0;

  while (array.length !== 2) {
    const isHoliday = holidays.find(
      (holiday) => unifiedFormatDate(holiday) === unifiedFormatDate(firstDate),
    );
    if (
      !isHoliday &&
      firstDate.getDay() !== dayIndexSaturday &&
      firstDate.getDay() !== dayIndexSunday
    ) {
      array.push(new Date(firstDate));
      firstDate.setDate(firstDate.getDate() + maxLastDate - 1);
    } else if (array.length === 0) {
      firstDate.setDate(firstDate.getDate() + 1);
    } else if (array.length === 1) {
      firstDate.setDate(firstDate.getDate() - 1);
    }
  }
  return array;
};
