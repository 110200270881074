import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState, useRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './OrderBlockedDays.scss';
import { Messages } from 'primereact/messages';
import { GetBlockedDatesWithPagination } from '@rootTypes/order-calendar/get-blocker-dates-with-pagination.dto';
import { translate } from '@module/Translate/Translate';
import {
  getPaginatedOrderBlockedDays,
  unblockDayAction,
  blockDayAction,
} from '@network/Actions/OrderBlockedDays';
import unifiedFormatDate, { formatDateToLocalTime } from '@util/Dates';
import { UserProps } from '@view/AdminTool/interfaces/interfaces';

const LIMIT_ON_PAGE = 3;

const INITIAL_STATE = {
  date: unifiedFormatDate(new Date()),
  name: 'Zablokowana data',
};

export default function OrderBlockedDays({ user }: UserProps) {
  const [loading, setLoading] = useState(false);
  const [blockData, setBlockedData] = useState(INITIAL_STATE);
  const message: any = useRef(null);
  const [records, setRecords] = useState();
  const [totalItems, setTotalItems] = useState<number>();
  const [lazyParams, setLazyParams] = useState<GetBlockedDatesWithPagination>({
    take: LIMIT_ON_PAGE,
    skip: 0,
    sortBy: '',
    order: 1,
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setBlockedData((prevState) => ({
      ...prevState,
      [name]: value,
      updatedBy: user.email,
    }));
  };

  const getData = async () => {
    setLoading(true);
    const { items, meta } = await getPaginatedOrderBlockedDays(lazyParams);
    setTotalItems(meta.totalItems);
    setRecords(items);
    setLoading(false);
  };

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const onDateDelete = async (id: any) => {
    if (!id) return;
    try {
      await unblockDayAction(id);
      await getData();
      showMessage(
        'success',
        translate('adminTool.orderBlockedDays.updateMessage'),
      );
    } catch (error) {
      showMessage('error', translate('adminTool.orderBlockedDays.fail'));
    }
  };

  const onSort = (event: any) => {
    const sortBy = event.sortField;
    const order = event.sortOrder;
    setLazyParams((prev: any) => ({
      ...prev,
      sortBy,
      order,
    }));
  };

  const onDateAdd = async (payload: any) => {
    if (!payload) return;
    try {
      await blockDayAction(payload);
      await getData();
      showMessage(
        'success',
        translate('adminTool.orderBlockedDays.addMessage'),
      );
    } catch (error) {
      showMessage('error', translate('adminTool.orderBlockedDays.fail'));
    }
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>
          <input
            type="text"
            name="name"
            value={blockData.name}
            onChange={handleChange}
          />
          <div className="input-date">
            <input type="date" name="date" onChange={handleChange} />
          </div>

          <button
            type="submit"
            className={`submitForm invoice__button m-auto button_accept`}
            onClick={() => {
              onDateAdd(blockData);
            }}
          >
            {translate('adminTool.orderBlockedDays.btnBlock')}
          </button>
        </div>
      </div>
    );
  };
  const onPage = (event: any) => {
    setLazyParams((prev: any) => ({ ...prev, skip: [event.first] }));
  };

  useEffect(() => {
    getData();
  }, [lazyParams.order, lazyParams.skip, lazyParams.take, lazyParams.sortBy]);

  return (
    <div className="products-table-container">
      <h5>{translate('adminTool.orderBlockedDays.blockInfo')}</h5>
      <Messages ref={message} />
      <DataTable
        value={records}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={loading}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        rows={LIMIT_ON_PAGE}
        totalRecords={totalItems}
        header={renderHeader}
        first={lazyParams.skip}
        sortOrder={lazyParams.order}
        sortField={lazyParams.sortBy}
        onPage={onPage}
        onSort={onSort}
      >
        <Column field="id" sortable header="Id" />
        <Column
          sortable
          field="date"
          body={(data) => unifiedFormatDate(new Date(data.date))}
          header={translate('adminTool.orderBlockedDays.blockedDay')}
        />
        <Column
          sortable
          field="name"
          header={translate('adminTool.orderBlockedDays.blockedDayName')}
        />
        <Column
          body={(data: any, options: ColumnBodyOptions) => (
            <div>
              <button
                className="delete-button"
                onClick={() => {
                  onDateDelete(data.id);
                }}
              >
                {translate('adminTool.orderBlockedDays.btnDelete')}
              </button>
            </div>
          )}
          header={translate('adminTool.orderBlockedDays.actionHeader')}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.orderBlockedDays.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.orderBlockedDays.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.orderBlockedDays.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
