import React, { useState, useContext } from 'react';
import './BillingForm.scss';
import FormField from '@component/FormField/FormField';
import { translate } from '@module/Translate/Translate';
import { ValidateFormContext } from '@view/RegistrationStepper/components/PaymentStep/PaymentStep';

export const BillingForm = ({ item, onEditAddress }: any) => {
  const [formData, setFormData] = useState({
    firstName: item ? item.firstName : '',
    lastName: item ? item.lastName : '',
    street: item ? item.street : '',
    postalCode: item ? item.postalCode : '',
    city: item ? item.city : '',
  });

  const validateForm = useContext<any>(ValidateFormContext);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    onEditAddress(formData);

    const checkIsFilled = () => {
      return (
        (formData.firstName &&
          formData.lastName &&
          formData.street &&
          formData.postalCode &&
          formData.city.trim()) !== ''
      );
    };
    validateForm(checkIsFilled());
  };
  return (
    <form className="account-address-form pb-5" onSubmit={handleSubmit}>
      <FormField
        name={formData.firstName}
        label={translate('paymentStep.name')}
        onChange={(e: any) =>
          setFormData({
            ...formData,
            firstName: e.target.value,
          })
        }
      />
      <FormField
        name={formData.lastName}
        label={translate('paymentStep.surname')}
        onChange={(e: any) =>
          setFormData({ ...formData, lastName: e.target.value })
        }
      />
      <FormField
        name={formData.street}
        isFullWidth
        label={translate('paymentStep.street')}
        onChange={(e: any) =>
          setFormData({ ...formData, street: e.target.value })
        }
      />
      <FormField
        name={formData.postalCode}
        label={translate('paymentStep.zipCode')}
        onChange={(e: any) =>
          setFormData({ ...formData, postalCode: e.target.value })
        }
        pattern={'^[0-9]{2}-[0-9]{3}$'}
        placeholder={'XX-XXX'}
        title={translate('errors.postalCodeError')}
      />
      <FormField
        name={formData.city}
        label={translate('paymentStep.city')}
        onChange={(e: any) =>
          setFormData({ ...formData, city: e.target.value })
        }
      />

      <button type="submit" className="btn btn-100 mt-5">
        {translate('paymentStep.changeAddress')}
      </button>
    </form>
  );
};

export default BillingForm;
