import BusinessConfigI from './Business.config.type';

const BusinessConfig: BusinessConfigI = {
  social: {
    fb: {
      page: 'https://www.facebook.com/skitrajpl',
    },
    ig: {
      page: 'https://www.instagram.com/skitraj.pl/',
    },
    yt: {
      page: 'https://www.youtube.com/channel/UC_XLo1bdzRGVfMIfM-jUSlw',
    },
  },
  moosend: {
    apiKey: '9bf191de-e818-40e2-9912-3089232e0180',
    newsletterFormId: '75551f5f-a433-43ca-a785-8c44e4187ee5',
  },
};

export default BusinessConfig;
