import React from 'react';
import './CertificateModal.scss';
import Certificate from './certificate.png';

export const CertificateModal: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <div className="modalWrapper">
      <img src={Certificate} alt="certyficate" />
    </div>
  );
};
