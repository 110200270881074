import { UpdateUserRolesDto } from '../../../../types/roles/update-user-roles.dto';
import { http } from '../NetworkHTTP';

export async function editUserRoleAction(
  userId: number,
  roleId: number,
  payload: UpdateUserRolesDto,
) {
  return await http.patch(`users/${userId}/roles/${roleId}`, payload);
}
