import { DateCategory } from '../../../types/calendar/categories-enum';
import { dateGetTime } from './DateGetTime';
import { selectedDaysArray } from './SelectedDaysArray';

export default function unifiedFormatDate(
  date: Date,
  withHours?: boolean,
  withoutSeconds?: boolean,
): string {
  if (!withHours) {
    return `${date.toLocaleDateString('pl-PL', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })}`;
  } else if (withoutSeconds) {
    return `${date
      .toLocaleDateString('pl-PL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      })
      .replace(',', '')
      .replace(/ o/g, ',')}`;
  } else {
    return `${date
      .toLocaleDateString('pl-PL', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/ o/g, ',')}`;
  }
}

//TODO please use formatter.service instead
export const unifiedWMSFormatDate = (date: Date): string => {
  const options = {
    yyyymmdd: `${date.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })}`,
  };

  return options.yyyymmdd;
};

export const formatDateToLocalTime = (rowData: any, item: any) => {
  if (item.field && item.field !== '' && rowData[item.field] !== '') {
    return unifiedFormatDate(new Date(rowData[item.field]), true);
  }
};

const getDaysOfMonth = (date: Date) => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return new Date(year, month, 0).getDate();
};

const monthDiffrence = (startDate: Date, endDate: Date) => {
  let months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  months -= startDate.getMonth();
  months += endDate.getMonth();
  return months <= 0 ? 0 : months;
};

export const calculateFullMonth = (startDate: Date, quantity: number) => {
  const start = new Date(startDate);
  const DAYS_INTERVAL = 30;
  const nextMonthDate = new Date(
    start.setDate(start.getDate() + quantity * DAYS_INTERVAL - 1),
  );
  return new Date(nextMonthDate);
};

interface IVerifyOrderClose {
  holidays: Date[];
  defaultOrderClosingTime: number;
  dates: Record<string, string | Date>[];
}

export const verifyOrderClose = ({
  holidays,
  defaultOrderClosingTime,
  dates,
}: IVerifyOrderClose) => {
  const daysArray = selectedDaysArray({
    arrayLength: 1,
    holidays,
    defaultOrderClosingTime,
  });

  const startsDates = dates.filter(
    (date) => date.category === DateCategory.start,
  );
  const orderClosingAfterTime = startsDates.find(
    (date) =>
      new Date().getHours() >= defaultOrderClosingTime &&
      dateGetTime(new Date(date.date)) <= dateGetTime(new Date()),
  );

  if (orderClosingAfterTime) {
    return true;
  } else {
    return startsDates.find(
      (date) => dateGetTime(new Date(date.date)) < dateGetTime(daysArray[0]),
    )
      ? true
      : false;
  }
};
