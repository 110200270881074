export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_PASSWORD_RESTORE_SUCCESS = 'USER_PASSWORD_RESTORE_SUCCESS';
export const USER_PASSWORD_RESTORE_REQUEST = 'USER_PASSWORD_RESTORE_REQUEST';
export const USER_PASSWORD_RESTORE_FAIL = 'USER_PASSWORD_RESTORE_FAIL';

export const PRODUCTS_FAIL = 'PRODUCTS_FAIL';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';

export const NUMBER_FAIL = 'NUMBER_FAIL';
export const NUMBER_SUCCESS = 'NUMBER_SUCCESS';
export const NUMBER_REQUEST = 'NUMBER_REQUEST';

export const CURRENT_USER_FAIL = 'CURRENT_USER_FAIL';
export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';

export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const BASKET_ADD_PENDING = 'BASKET_ADD_PENDING';
export const BASKET_ADD_REJECTED = 'BASKET_ADD_REJECTED';
export const BASKET_ADD_FULFILLED = 'BASKET_ADD_FULFILLED';

export const BASKET_DECREASE_PENDING = 'BASKET_DECREASE_PENDING';
export const BASKET_DECREASE_REJECTED = 'BASKET_DECREASE_REJECTED';
export const BASKET_DECREASE_FULFILLED = 'BASKET_DECREASE_FULFILLED';

export const BASKET_DELETE_PENDING = 'BASKET_DELETE_PENDING';
export const BASKET_DELETE_REJECTED = 'BASKET_DELETE_REJECTED';
export const BASKET_DELETE_FULFILLED = 'BASKET_DELETE_FULFILLED';

export const BASKET_DELETE_GROUP_PENDING = 'BASKET_DELETE_GROUP_PENDING';
export const BASKET_DELETE_GROUP_REJECTED = 'BASKET_DELETE_GROUP_REJECTED';
export const BASKET_DELETE_GROUP_FULFILLED = 'BASKET_DELETE_GROUP_FULFILLED';

export const BASKET_DATE_PENDING = 'BASKET_DATE_PENDING';
export const BASKET_DATE_REJECTED = 'BASKET_DATE_REJECTED';
export const BASKET_DATE_FULFILLED = 'BASKET_DATE_FULFILLED';

export const BASKET_PERIOD_PENDING = 'BASKET_PERIOD_PENDING';
export const BASKET_PERIOD_REJECTED = 'BASKET_PERIOD_REJECTED';
export const BASKET_PERIOD_FULFILLED = 'BASKET_PERIOD_FULFILLED';

export const BASKET_DAYS_PENDING = 'BASKET_DAYS_PENDING';
export const BASKET_DAYS_REJECTED = 'BASKET_DAYS_REJECTED';
export const BASKET_DAYS_FULFILLED = 'BASKET_DAYS_FULFILLED';

export const BASKET_STOCKS_PENDING = 'BASKET_STOCKS_PENDING';
export const BASKET_STOCKS_REJECTED = 'BASKET_STOCKS_REJECTED';
export const BASKET_STOCKS_FULFILLED = 'BASKET_STOCKS_FULFILLED';

export const REVIEWS_FAIL = 'REVIEWS_FAIL';
export const REVIEWS_SUCCESS = 'REVIEWS_SUCCESS';
export const REVIEWS_REQUEST = 'REVIEWS_REQUEST';

export const REVIEWS_ALL_FAIL = 'REVIEWS_ALL_FAIL';
export const REVIEWS_ALL_SUCCESS = 'REVIEWS_ALL_SUCCESS';
export const REVIEWS_ALL_REQUEST = 'REVIEWS_ALL_REQUEST';

export const NOT_MODIFIED_REVIEWS_FAIL = 'NOT_MODIFIED_REVIEWS_FAIL';
export const NOT_MODIFIED_REVIEWS_SUCCESS = 'NOT_MODIFIED_REVIEWS_SUCCESS';
export const NOT_MODIFIED_REVIEWS_REQUEST = 'NOT_MODIFIED_REVIEWS_REQUEST';

export const NOTIFICATIONS_FAIL = 'NOTIFICATIONS_FAIL';
export const NOTIFICATIONS_PUSH = 'NOTIFICATIONS_PUSH';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';

export const COUNT_UNREAD_NOTIFICATIONS_REQUEST =
  'COUNT_UNREAD_NOTIFICATIONS_REQUEST';
export const COUNT_UNREAD_NOTIFICATIONS_SUCCESS =
  'COUNT_UNREAD_NOTIFICATIONS_SUCCESS';
export const COUNT_UNREAD_NOTIFICATIONS_FAIL =
  'COUNT_UNREAD_NOTIFICATIONS_FAIL';

export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_FAIL = 'UPDATE_NOTIFICATION_FAIL';

export const INCREMENT_UNREAD_NOTIFICATIONS_REQUEST =
  'INCREMENT_UNREAD_NOTIFICATIONS_REQUEST';
export const DECREMENT_UNREAD_NOTIFICATIONS_REQUEST =
  'DECREMENT_UNREAD_NOTIFICATIONS_REQUEST';

export const NOTIFICATIONS_DELETE_REJECTED = 'NOTIFICATIONS_DELETE_REJECTED';
export const NOTIFICATIONS_DELETE_FULFILLED = 'NOTIFICATIONS_DELETE_FULFILLED';

export const ORDER_FAIL = 'ORDER_FAIL';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_REQUEST = 'ORDER_REQUEST';

export const ORDERS_FAIL = 'ORDERS_FAIL';
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
export const ORDERS_REQUEST = 'ORDERS_REQUEST';

export const COMPLAINTS_FAIL = 'COMPLAINTS_FAIL';
export const COMPLAINTS_SUCCESS = 'COMPLAINTS_SUCCESS';
export const COMPLAINTS_REQUEST = 'COMPLAINTS_REQUEST';

export const NEW_COMPLAINTS_FAIL = 'NEW_COMPLAINTS_FAIL';
export const NEW_COMPLAINTS_SUCCESS = 'NEW_COMPLAINTS_SUCCESS';
export const NEW_COMPLAINTS_REQUEST = 'NEW_COMPLAINTS_REQUEST';

export const ALL_USERS_FAIL = 'ALL_USERS_FAIL';
export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST';
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS';

export const TRANSPORT_PENDING = 'TRANSPORT_PENDING';
export const TRANSPORT_REJECTED = 'TRANSPORT_REJECTED';
export const TRANSPORT_FULFILLED = 'TRANSPORT_FULFILLED';

export const DEFAULT_SETTINGS_PENDING = 'DEFAULT_SETTINGS_PENDING';
export const DEFAULT_SETTINGS_REJECTED = 'DEFAULT_SETTINGS_REJECTED';
export const DEFAULT_SETTINGS_FULFILLED = 'DEFAULT_SETTINGS_FULFILLED';

export const DELIVERY_STATUSES_PENDING = 'DELIVERY_STATUSES_PENDING';
export const DELIVERY_STATUSES_REJECTED = 'DELIVERY_STATUSES_REJECTED';
export const DELIVERY_STATUSES_FULFILLED = 'DELIVERY_STATUSES_FULFILLED';

export const CURRENT_ORDER_PENDING = 'CURRENT_ORDER_PENDING';
export const CURRENT_ORDER_REJECTED = 'CURRENT_ORDER_REJECTED';
export const CURRENT_ORDER_FULFILLED = 'CURRENT_ORDER_FULFILLED';

export const PRODUCT_PACKAGES_FAIL = 'PRODUCT_PACKAGES_FAIL';
export const PRODUCT_PACKAGES_REQUEST = 'PRODUCT_PACKAGES_REQUEST';
export const PRODUCT_PACKAGES_SUCCESS = 'PRODUCT_PACKAGES_SUCCESS';

export const PAYMENT_HISTORY_FAIL = 'PAYMENT_HISTORY_FAIL';
export const PAYMENT_HISTORY_SUCCESS = 'PAYMENT_HISTORY_SUCCESS';
export const PAYMENT_HISTORY_REQUEST = 'PAYMENT_HISTORY_REQUEST';

export const PAYMENT_FAIL = 'PAYMENT_FAIL';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';

export const PAYMENT_UNPAID_ORDERS_FAIL = 'PAYMENT_UNPAID_ORDERS_FAIL';
export const PAYMENT_UNPAID_ORDERS_SUCCESS = 'PAYMENT_UNPAID_ORDERS_SUCCESS';
export const PAYMENT_UNPAID_ORDERS_REQUEST = 'PAYMENT_UNPAID_ORDERS_REQUEST';

export const ORDER_BLOCKED_DAYS_PENDING = 'ORDER_BLOCKED_DAYS_PENDING';
export const ORDER_BLOCKED_DAYS_REJECTED = 'ORDER_BLOCKED_DAYS_REJECTED';
export const ORDER_BLOCKED_DAYS_FULFILLED = 'ORDER_BLOCKED_DAYS_FULFILLED';

export const STOCKS_FAIL = 'STOCKS_FAIL';
export const STOCKS_SUCCESS = 'STOCKS_SUCCESS';
export const STOCKS_REQUEST = 'STOCKS_REQUEST';
export const ADMIN_STOCKS_FAIL = 'ADMIN_STOCKS_FAIL';
export const ADMIN_STOCKS_SUCCESS = 'ADMIN_STOCKS_SUCCESS';
export const ADMIN_STOCKS_REQUEST = 'ADMIN_STOCKS_REQUEST';

export const LIMITS_FAIL = 'LIMITS_FAIL';
export const LIMITS_SUCCESS = 'LIMITS_SUCCESS';
export const LIMITS_REQUEST = 'LIMITS_REQUEST';

export const ROLES_FAIL = 'ROLES_FAIL';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const ROLES_REQUEST = 'ROLES_REQUEST';

export const POLICIES_FAIL = 'POLICIES_FAIL';
export const POLICIES_SUCCESS = 'POLICIES_SUCCESS';
export const POLICIES_REQUEST = 'POLICIES_REQUEST';

export const PUBLISHED_POLICY_FAIL = 'PUBLISHED_POLICY_FAIL';
export const PUBLISHED_POLICY_SUCCES = 'PUBLISHED_POLICY_SUCCES';
export const PUBLISHED_POLICY_REQUEST = 'PUBLISHED_POLICY_REQUEST';

export const SERVER_NETWORK_ERROR = 'SERVER_NETWORK_ERROR';

export const USER_PAYMENT_METHOD_FAIL = 'USER_PAYMENT_METHOD_FAIL';
export const USER_PAYMENT_METHOD_SUCCESS = 'USER_PAYMENT_METHOD_SUCCESS';
export const USER_PAYMENT_METHOD_REQUEST = 'USER_PAYMENT_METHOD_REQUEST';

export const CHANGE_SITE_LANGUAGE = 'CHANGE_SITE_LANGUAGE';

export const ADMIN_ERRORS_FAIL = 'ADMIN_ERRORS_FAIL';
export const ADMIN_ERRORS_SUCCESS = 'ADMIN_ERRORS_SUCCESS';
export const ADMIN_ERRORS_REQUEST = 'ADMIN_ERRORS_REQUEST';

export const VERIFY_PROMOTION_PENDING = 'VERIFY_PROMOTION_PENDING';
export const VERIFY_PROMOTION__FULFILLED = 'VERIFY_PROMOTION__FULFILLED';
export const VERIFY_PROMOTION__REJECTED = 'VERIFY_PROMOTION__REJECTED';

export const ANALYTICS_PENDING = 'ANALYTICS_PENDING';
export const ANALYTICS_FULFILLED = 'ANALYTICS_FULFILLED';
export const ANALYTICS_REJECTED = 'ANALYTICS_REJECTED';
