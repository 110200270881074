import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { FindRentStockDto } from '../../../../../../../types/stocks/find-rent-stock.dto';
import { StockStatus } from '../../../../../../../types/stocks/stock-status.enum';
import { H5 } from '../../../../../Component/Text/Text';
import { User } from '../../../../../models/user.model';
import { translate } from '../../../../../Module/Translate/Translate';
import { updateStockAction } from '../../../../../Network/Actions/Stocks';
import './EditRent.scss';

interface Props {
  rent: FindRentStockDto;
  editor: User;
  setRefresh: (refresh: boolean) => void;
}

const statuses = [
  { label: translate('adminTool.rents.isActive'), value: true },
  { label: translate('adminTool.rents.notActive'), value: false },
];

const deliveredStatuses = [
  {
    label: translate('adminTool.rents.inWarehouse'),
    value: StockStatus.STOCK_IW,
  },
  {
    label: translate('adminTool.rents.sendedToTheCustomer'),
    value: StockStatus.STOCK_STTC,
  },
  {
    label: translate('adminTool.rents.atTheCustomer'),
    value: StockStatus.STOCK_ATC,
  },
  {
    label: translate('adminTool.rents.requestForReturn'),
    value: StockStatus.STOCK_RFR,
  },
  { label: translate('adminTool.rents.returned'), value: StockStatus.STOCK_R },
];

export default function EditRent({ rent, editor, setRefresh }: Props) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      isActive: rent ? rent.isActive : false,
      stockStatus: rent ? rent.stockStatus : null,
      stockNumber: rent.stockNumber ? rent.stockNumber : '',
    },
    validationSchema: Yup.object({
      stockNumber: Yup.string().required(),
    }),
    onSubmit: (values: any) => {
      if (values.stockStatus === StockStatus.STOCK_R) {
        values.isActive = false;
      }
      values.updatedBy = editor.email;
      updateStockAction(rent.id, values).then((res: any) => {
        if (res.response) {
          showMessage(
            'error',
            translate(`adminTool.rents.${res.response.data.error.message}`),
          );
        } else {
          setRefresh(true);
          showMessage('success', translate('adminTool.rents.updateMessage'));
        }
      });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  return (
    <div className="rents-main-container border-details">
      <div className="rents-header-container">
        <Messages ref={message} />
      </div>
      <div className="rents-data-container">
        <div className="rents-top-container">
          <div className="rents-info-left-container">
            <H5 className="rents-h5">adminTool.rents.email</H5>
            <p>{rent.userEmail}</p>
            <H5 className="rents-h5">adminTool.rents.orderId</H5>
            <p>{rent.orderId}</p>
            <H5 className="rents-h5">adminTool.rents.stockStatus</H5>
            <p>{translate(`adminTool.rents.${rent.stockStatus}`)}</p>
            <H5 className="rents-h5">adminTool.rents.returnDate</H5>
            <p>
              {rent.returnDate
                ? new Date(rent.returnDate).toLocaleDateString('pl-PL')
                : translate('adminTool.rents.nothing')}
            </p>
          </div>
          <div className="rents-info-middle-container">
            <form className="rents-form" onSubmit={formik.handleSubmit}>
              <H5 className="rents-h5">adminTool.rents.stockId</H5>
              <InputText
                name="stockNumber"
                value={formik.values.stockNumber}
                onChange={formik.handleChange}
                disabled={rent.stockNumber ? true : false}
                placeholder={translate('adminTool.rents.stockId')}
              />
              <H5 className="rents-h5">adminTool.rents.status</H5>
              <Dropdown
                value={formik.values.isActive}
                options={statuses}
                className="type-dropdown"
                name="isActive"
                optionLabel="label"
                disabled={rent.stockNumber ? false : true}
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <H5 className="rents-h5">adminTool.rents.stockStatus</H5>
              <Dropdown
                value={formik.values.stockStatus}
                options={deliveredStatuses}
                className="type-dropdown"
                name="stockStatus"
                optionLabel="label"
                optionValue="value"
                disabled={rent.stockNumber && rent.isActive ? false : true}
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.isValid && formik.values.stockNumber !== ''
                    ? rent.stockNumber
                      ? formik.values.isActive !== rent.isActive ||
                        formik.values.stockStatus !== rent.stockStatus
                        ? ''
                        : 'inactive'
                      : ''
                    : 'inactive'
                }`}
              >
                {translate('adminTool.rents.update')}
              </button>
            </form>
          </div>
          <div className="rents-info-right-container">
            <H5 className="storages-h5">adminTool.rents.productsList</H5>
            {rent.products.map((item: any) => {
              return <p key={item.id}>{`${item.quantity}x ${item.name}`}</p>;
            })}
          </div>
          <div className="rents-info-last-container">
            <H5 className="rents-h5">adminTool.storage.returnAddress</H5>
            <p>{`${rent.returnAddress?.street ?? ''}${
              rent.returnAddress?.street ? ',' : ''
            }`}</p>
            <p>
              {`${rent.returnAddress?.postalCode ?? ''} 
              ${rent.returnAddress?.city ?? ''}`}
            </p>
            <H5 className="rents-h5">adminTool.storage.deliveryAddress</H5>
            <p>{`${rent.deliveryAddress?.street ?? ''}${
              rent.deliveryAddress?.street ? ',' : ''
            } `}</p>
            <p>
              {`${rent.deliveryAddress?.postalCode ?? ''} 
              ${rent.deliveryAddress?.city ?? ''}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
