import { translate } from '@module/Translate/Translate';
import React from 'react';

export default function MailInput({ formik }: { formik: any }) {
  return (
    <div className="mb-lg-3">
      <label
        htmlFor="email"
        className={`d-sm-block font-weight-bold label--orange ${
          formik.touched.email && formik.errors.email ? 'error-label' : ''
        }`}
      >
        {translate('registrationForm.email')}
      </label>
      <input
        type="email"
        name="email"
        className={`custom-input ${
          formik.touched.email && formik.errors.email ? 'error-input' : ''
        }`}
        id="email"
        required
        autoComplete="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={translate('registrationForm.email')}
        value={formik.values.email}
      />
      {formik.touched.email && formik.errors.email ? (
        <div className="error-feedback">{`${formik.errors.email}`}</div>
      ) : null}
    </div>
  );
}
