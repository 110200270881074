import { useFormik } from 'formik';
import * as Yup from 'yup';
import { translate } from '../../../../../../Module/Translate/Translate';
import React from 'react';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { Dropdown } from 'primereact/dropdown';
import { FindProductDescriptionDto } from '../../../../../../../../types/product-descriptions/find-product-description.dto';
import DescriptionItem from './components/DescriptionItems';
import './Descriptions.scss';
import { H5 } from '../../../../../../Component/Text/Text';
import { DescriptionTypeEnum } from '../../../../../../../../types/product-descriptions/description-type.enum';
import { isEmpty } from '../../../../../../Util/isEmpty';

const types = [
  {
    label: translate('adminTool.products.offer'),
    value: DescriptionTypeEnum.offer,
  },
  {
    label: translate('adminTool.products.shop'),
    value: DescriptionTypeEnum.store,
  },
  {
    label: translate('adminTool.products.carousel'),
    value: DescriptionTypeEnum.carousel,
  },
  {
    label: translate('adminTool.products.rent'),
    value: DescriptionTypeEnum.rent,
  },
  {
    label: translate('adminTool.products.storage'),
    value: DescriptionTypeEnum.storage,
  },
  {
    label: translate('adminTool.products.move'),
    value: DescriptionTypeEnum.move,
  },
];

interface Props {
  descriptions: FindProductDescriptionDto[];
  setDescription: any;
  productId: number;
  removeDescription: any;
}

export default function Descriptions({
  descriptions,
  setDescription,
  productId,
  removeDescription,
}: Props) {
  const formik = useFormik({
    initialValues: {
      description: '',
      type: '',
    },
    validationSchema: Yup.object({
      description: Yup.string().required(translate('errors.fieldRequired')),
      type: Yup.string().required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values: any) => {
      setDescription(
        {
          text: values.description,
          type: values.type,
          product: productId,
        },
        productId,
      );
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <H5 className="product-h5">
          {translate('adminTool.products.descriptionType')}
        </H5>
        <Dropdown
          value={formik.values.type}
          options={types}
          className="type-dropdown"
          name="type"
          optionLabel="label"
          optionValue="value"
          onChange={formik.handleChange}
          placeholder={translate('adminTool.products.descriptionTypeSelect')}
          itemTemplate={(option) => {
            return <span>{option.label}</span>;
          }}
        />
        {formik.errors.type ? (
          translate('errors.fieldRequired')
        ) : (
          <div className="notifications-feedback">{}</div>
        )}

        <FormFieldFormik
          label={translate('adminTool.products.description')}
          type="text"
          name="description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
        />
        {formik.errors.description ? (
          translate('errors.fieldRequired')
        ) : (
          <div className="notifications-feedback">{}</div>
        )}

        <br />

        <button
          type="submit"
          className={`submitForm invoice__button m-auto button_accept ${
            formik.isValid && isEmpty(formik.values) ? '' : 'inactive'
          }`}
        >
          {translate('adminTool.products.addDescription')}
        </button>
      </form>
      <div className="descriptions-container">
        <H5 className="product-h5">
          {translate('adminTool.products.currentDescriptions')}
        </H5>
        {descriptions.length > 0 &&
          descriptions.map((description, index) => {
            return (
              <DescriptionItem
                key={index}
                description={description}
                removeDescription={removeDescription}
                productId={productId}
              />
            );
          })}
      </div>
    </div>
  );
}
