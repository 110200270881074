import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  PAYMENT_FAIL,
  PAYMENT_HISTORY_FAIL,
  PAYMENT_HISTORY_REQUEST,
  PAYMENT_HISTORY_SUCCESS,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_UNPAID_ORDERS_FAIL,
  PAYMENT_UNPAID_ORDERS_REQUEST,
  PAYMENT_UNPAID_ORDERS_SUCCESS,
  USER_PAYMENT_METHOD_FAIL,
  USER_PAYMENT_METHOD_REQUEST,
  USER_PAYMENT_METHOD_SUCCESS,
} from './Types';
import { P24_URL } from '@/envConfig';
import getApiEndPoint from '@network/NetworkApiEndpoint';
import { CreatePaymentResponseDTO } from '@rootTypes/payments/dto/create-payment-response.dto';
import { CreatePaymetDTO } from '@rootTypes/payments/dto/create-payment.dto';
import { CreateTransactionResponseDto } from '@rootTypes/payments/dto/create-transaction-response.dto';
import { CreateTransactionDto } from '@rootTypes/payments/dto/create-transaction.dto';
import { InvokeTransactionDTO } from '@rootTypes/payments/dto/invoke-transaction.dto';
import { UpdatePaymentDto } from '@rootTypes/payments/dto/update-payment.dto';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import { PaymentTypeEnum } from '@rootTypes/payments/enums/payment-type.enum';
import { StockInfo } from '@rootTypes/stocks/stock-info.dto';
import { AxiosResponse } from 'axios';
import { http } from '@network/NetworkHTTP';
import { RootState } from '@src/store';

export async function registerTransaction(
  url: string,
  payload: CreateTransactionDto,
) {
  return await http.post(url, payload);
}

export async function createPayment(url: string, payload: CreatePaymetDTO) {
  return await http.post(url, payload);
}

export async function invokePayment(
  url: string,
  payload: InvokeTransactionDTO,
) {
  return await http.post(url, payload);
}

export async function editPaymentStatusAction(
  id: number,
  updatePaymentDto: UpdatePaymentDto,
) {
  return await http.patch(`payments/${id}`, updatePaymentDto);
}

export const getAllPayments = (params: {
  rows: number;
  first: number;
  orderNumber: string;
  sortField: string;
  sortOrder: number | null;
}): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: PAYMENT_REQUEST,
      });
      const query = `payments?&take=${params.rows}&skip=${params.first}&orderNumber=${params.orderNumber}&sortField=${params.sortField}&sortOrder=${params.sortOrder}`;
      const { data } = await http.get(query);

      dispatch({
        type: PAYMENT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: PAYMENT_FAIL,
        payload: 'Coś poszlo nie tak',
      });
    }
  };
};

export const paymentHistoryAction = (
  userId: number,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: PAYMENT_HISTORY_REQUEST,
      });

      const { data } = await http.get(`payments/history/${userId}`);

      dispatch({
        type: PAYMENT_HISTORY_SUCCESS,
        payload: data,
      });
    } catch (err) {
      onError();
      dispatch({
        type: PAYMENT_HISTORY_FAIL,
        payload: 'Coś poszlo nie tak',
      });
    }
  };
};

export const unpiadOrdersAction = (
  userId: number,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: PAYMENT_UNPAID_ORDERS_REQUEST,
      });

      const { data } = await http.get(`payments/unpaid/${userId}`);

      dispatch({
        type: PAYMENT_UNPAID_ORDERS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      onError();
      dispatch({
        type: PAYMENT_UNPAID_ORDERS_FAIL,
        payload: 'Coś poszlo nie tak',
      });
    }
  };
};

export async function getOrdersPaymentHistory(orderId: string) {
  return await http.get(`payments/${orderId}`);
}

type PaymentData = {
  amount: number;
  orderId: string;
  paymentType: number;
  email: string;
  period?: number;
  stockInfo?: StockInfo;
};

export async function payment(
  data: PaymentData,
  isTransportsAmortizationBasket: boolean,
) {
  const createPaymentEndpoint = getApiEndPoint('payments');

  const payment: CreatePaymetDTO = {
    type: data.stockInfo ? PaymentTypeEnum.transport : PaymentTypeEnum.single,
    amount: data.amount * 100,
    description: data.orderId,
    orderId: data.orderId,
    method: data.paymentType,
    period: data.period ? data.period : 0,
    stockInfo: data.stockInfo ? data.stockInfo : undefined,
  };

  const transactionEndpoint = getApiEndPoint('transaction');

  createPayment(createPaymentEndpoint, payment).then(
    (paymentResponse: AxiosResponse<CreatePaymentResponseDTO>) => {
      const transaction: CreateTransactionDto = {
        transaction: {
          amount: data.amount * 100,
          description: data.orderId,
          email: data.email,
          method: data.paymentType,
        },
        paymentId: paymentResponse.data.id,
      };
      registerTransaction(transactionEndpoint, transaction).then(
        (res: AxiosResponse<CreateTransactionResponseDto>) => {
          if (res.data.transactionToken) {
            window.open(
              `${P24_URL}/trnRequest/${res.data.transactionToken}`,
              '_self',
            );
          }
          if (res.data.redirectUrl) {
            window.open(res.data.redirectUrl, '_self');
          }
        },
      );
    },
  );
}

export async function getInvoice(invoicesURL: string, invoiceId: string) {
  const { data } = await http.get(invoicesURL + invoiceId + '.pdf', {
    responseType: 'blob',
  });
  return data;
}

export function changeUserPaymentMethod(
  userId: number,
  paymentMethod: PaymentMethodEnum,
  isPermissionForPayingInTheFutureGranted: boolean,
  onError: () => void,
) {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: USER_PAYMENT_METHOD_REQUEST,
      });
      await http.patch(
        `payments/change-user-payment-method?userId=${userId}&paymentMethod=${paymentMethod}`,
      );
      await http.patch(`users/${userId}/user-settings`, {
        paymentMethod,
        isPermissionForPayingInTheFutureGranted,
      });
      dispatch({
        type: USER_PAYMENT_METHOD_SUCCESS,
      });
    } catch (error) {
      onError();
      dispatch({
        type: USER_PAYMENT_METHOD_FAIL,
      });
    }
  };
}

export async function getAdminPaymentHistory(userId: number) {
  const { data } = await http.get(`payments/history/${userId}`);
  return data;
}

export async function getAdminUnpaidPaymentHistory(userId: number) {
  const { data } = await http.get(`payments/unpaid/${userId}`);
  return data;
}
