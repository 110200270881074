import {
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAIL,
} from '@network/Actions/Types';
import { FindOrderDto } from '@rootTypes/orders/find-order.dto';
import { OrdersState } from '@type/Custom';

interface Action {
  type: string;
  payload?: FindOrderDto[];
}

export const ordersReducer = (
  state: OrdersState = {
    loading: false,
    orders: [],
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case ORDERS_REQUEST:
      return { loading: true, orders: state.orders, error: null };
    case ORDERS_SUCCESS:
      return { loading: false, orders: action.payload, error: null };
    case ORDERS_FAIL:
      return { loading: false, orders: state.orders, error: null };
    default:
      return state;
  }
};
