import { AnalyticsData } from '@network/Actions/Analytics';
import {
  ANALYTICS_PENDING,
  ANALYTICS_FULFILLED,
  ANALYTICS_REJECTED,
} from '../Network/Actions/Types';
import { AnalyticsState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: AnalyticsData;
}

export const analyticsReducer = (
  state: AnalyticsState = { loading: false, error: null, GA: '', Meta: '' },
  action: Action,
) => {
  switch (action.type) {
    case ANALYTICS_PENDING:
      return { loading: true, GA: state.GA, Meta: state.Meta, error: null };
    case ANALYTICS_FULFILLED:
      return {
        loading: false,
        GA: action.payload?.GA ?? state.GA,
        Meta: action.payload?.Meta ?? state.Meta,
        error: null,
      };
    case ANALYTICS_REJECTED:
      return {
        loading: false,
        GA: state.GA,
        Meta: state.Meta,
        error: action.payload,
      };
    default:
      return state;
  }
};
