import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import {
  PRODUCTS_FAIL,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCT_PACKAGES_FAIL,
  PRODUCT_PACKAGES_REQUEST,
  PRODUCT_PACKAGES_SUCCESS,
} from './Types';
import http from '../NetworkHTTP';
import { CreateProductDescriptionDto } from '../../../../types/product-descriptions/create-product-description.dto';
import { CreateProductDetailsDto } from '../../../../types/product-details/create-product-details.dto';
import { UpdateProductDetailsDto } from '../../../../types/product-details/update-product-details.dto';
import { CreateProductDto } from '../../../../types/products/create-product.dto';
import { CreatePackageDto } from '../../../../types/packages/create-package.dto';
import { UpdatePackageDto } from '../../../../types/packages/update-package.dto';
import { ProductGroup } from '../../../../types/products/product-group.enum';
import { ProductType } from '../../../../types/products/product-type.enum';
import { UpdateProductDto } from '../../../../types/products/update-product.dto';

export const getProductsAction = (
  status?: boolean | any,
  group?: ProductGroup | any,
  type?: ProductType | any,
  stockLimit?: number | any,
  setShowError?: (isOpen: boolean) => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: PRODUCTS_REQUEST,
      });

      const params = prepareParams(status, group, type, stockLimit);
      const { data } = await http.get(`products${params}`);

      for (const product of data) {
        if (product.group && product.group === ProductGroup.packageBox) {
          const packages = await http.get(`products/${product.id}/packages`);
          product.packages = packages.data;
        }
      }

      dispatch({
        type: PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      setShowError && setShowError(true);
      dispatch({
        type: PRODUCTS_FAIL,
        payload: 'Zaloguj się jeszcze raz, Twoja sesja wygasła.',
      });
    }
  };
};

export async function getProductsActionWithoutState(
  status?: boolean | any,
  group?: ProductGroup | any,
  type?: ProductType | any,
  stockLimit?: number | any,
) {
  const params = prepareParams(status, group, type, stockLimit);
  const { data } = await http.get(`products${params}`);
  return data;
}

export const getProductsByPathAction = (
  pathUrl?: string,
  type?: ProductType,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: PRODUCTS_REQUEST,
      });

      const { data } = await http.get(`products/pathUrl/${type}/${pathUrl}`);

      if (data.group === ProductGroup.packageBox) {
        const packages = await http.get(`products/${data.id}/packages`);
        data.packages = packages.data;
      }
      dispatch({
        type: PRODUCTS_SUCCESS,
        payload: [data],
      });
    } catch (err) {
      dispatch({
        type: PRODUCTS_FAIL,
        payload: 'Zaloguj się jeszcze raz, Twoja sesja wygasła.',
      });
    }
  };
};

export async function addProductAction(product: CreateProductDto) {
  return await http.post(`products`, product);
}

export async function editProductAction(product: UpdateProductDto) {
  return await http.patch(`products/${product.id}`, product);
}

export async function addImageAction(productId: number, img: any) {
  const formData = new FormData();
  formData.append('image', img.file);
  formData.append('type', img.type);
  formData.append('updatedBy', img.updatedBy);
  return await http.post(`products/${productId}/images`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function deleteImageAction(id: number, productId: number) {
  return await http.delete(`products/${productId}/images/${id}`);
}

export async function addDescriptionAction(
  productId: number,
  description: CreateProductDescriptionDto,
) {
  return await http.post(`products/${productId}/descriptions`, description);
}

export async function deleteDescriptionAction(id: number, productId: number) {
  return await http.delete(`products/${productId}/descriptions/${id}`);
}

export async function addProductDetailsAction(
  details: CreateProductDetailsDto,
  productId: number,
) {
  return await http.post(`products/${productId}/details`, details);
}

export async function editProductDetailsAction(
  details: UpdateProductDetailsDto,
  productId: number,
  detailsId: number,
) {
  return await http.patch(
    `products/${productId}/details/${detailsId}`,
    details,
  );
}

export async function deleteProductPackageAction(
  packageId: number,
  productId: number,
) {
  return await http.delete(`products/${productId}/packages/${packageId}`);
}

export async function addProductPackageAction(
  productId: number,
  productPackage: CreatePackageDto,
) {
  return await http.post(`products/${productId}/packages`, productPackage);
}

export async function editProductPackageAction(
  productPackage: UpdatePackageDto,
  productId: number,
  packageId: number,
) {
  return await http.patch(
    `products/${productId}/packages/${packageId}`,
    productPackage,
  );
}

export const getProductPackagesAction = (
  productId: number,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: PRODUCT_PACKAGES_REQUEST,
      });
      const { data } = await http.get(`products/${productId}/packages`);
      dispatch({
        type: PRODUCT_PACKAGES_SUCCESS,
        payload: data,
      });
      return data;
    } catch (err) {
      dispatch({
        type: PRODUCT_PACKAGES_FAIL,
        payload: 'Zaloguj się jeszcze raz, Twoja sesja wygasła.',
      });
    }
  };
};
const prepareParams = (
  status: boolean | any,
  group: ProductGroup | any,
  type: ProductType | any,
  stockLimit: number | any,
) => {
  let params = '';
  let query = '';
  const and = '&';
  const qmark = '?';
  if (status) {
    params = 'status=' + status;
  }

  if (group) {
    query = 'group=' + group;
    params += params !== '' ? and + query : query;
  }

  if (type) {
    query = 'type=' + type;
    params += params !== '' ? and + query : query;
  }

  if (stockLimit) {
    query = 'stockLimit=' + stockLimit;
    params += params !== '' ? and + query : query;
  }

  return qmark + params;
};

export async function syncProducts(idMag: string) {
  let data;
  try {
    data = await http.patch(`/wms/resources/all/${idMag}`);
  } catch (error) {
    throw error;
  }
  return data;
}

export async function syncPackages() {
  let data;
  try {
    data = await http.patch(`/wms/resources/packages`);
  } catch (error) {
    throw error;
  }
  return data;
}
