import React from 'react';
import './MessageOrderClosingTime.scss';
import { H5 } from '../../Component/Text/Text';
import { translate } from '../../Module/Translate/Translate';
import CompletedIcon from '../../Asset/images/completedProcess.png';

interface Props {
  setShowModal: (confirm: boolean) => void;
}

function MessageOrderClosingTime({ setShowModal }: Props) {
  return (
    <>
      <div className="order-closing" data-e2e="MessageOrderClosingTime">
        <img src={CompletedIcon} className="order-closing_img" alt="close" />

        <H5 className="order-closing_text" data-e2e="messageText">
          orderClosingTime.message
        </H5>

        <button
          type="submit"
          data-e2e="closeForm"
          className="btn mx-auto w-100 order-closing_button"
          onClick={() => setShowModal(false)}
        >
          {translate('orderClosingTime.button')}
        </button>
      </div>
    </>
  );
}

export default MessageOrderClosingTime;
