import { NotificationTypeEnum } from "./notification-type.enum";

export class FindNotificationDto {
  id: number;
  message: string;
  title: string;
  isRead: boolean;
  type: NotificationTypeEnum;
  createdAt: Date;
  updatedAt?: Date;
  isLinkToShow?: boolean;

  constructor({
    message,
    title,
    isRead,
    id,
    type,
    createdAt,
    updatedAt,
    isLinkToShow,
  }: FindNotificationDto) {
    if (!message || !title || !type || !createdAt) {
      return;
    }
    this.id = id;
    this.message = message;
    this.type = type;
    this.title = title;
    this.isRead = isRead;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.isLinkToShow = isLinkToShow;
  }
}
