import React from 'react';
import morcikSherlock from '../../../../Asset/images/faq/morcik-sherlock.png';
import morcikSherlockMobile from '../../../../Asset/images/faq/morcik_sherlock-mobile.png';
import { translate } from '../../../../Module/Translate/Translate';
import './Header.scss';
import { H2 } from '@component/Text/Text';

const Header = (): JSX.Element => {
  return (
    <div className="faq-header_container position-relative">
      <div className="faq-header container">
        <div className="faq-header_left">
          <H2 className="faq-header_title">faq.header.title</H2>
          <h4 className="faq-header_subtitle">
            {translate('faq.header.subtitle1')}
            <br />
            {translate('faq.header.subtitle2')}
          </h4>
        </div>
        <div className="faq-header_right">
          <img
            className="d-none d-md-block faq-header_image"
            src={morcikSherlock}
            alt={translate('faq.header.alt')}
          />
          <img
            className="d-block d-md-none faq-header_image--mobile"
            src={morcikSherlockMobile}
            alt={translate('faq.header.alt')}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
