import React from 'react';
import { FunctionComponent } from 'react';
import FormHOC from '../../HOC/FormHOC';
import { FormHocProps } from '../../HOC/FormHOC';
import Config from '../../Config/Business.config';
import './NewsletterForm.scss';

interface NewsletterFormI extends FormHocProps<Record<string, unknown>> {
  onSubmit: (data: Record<string, unknown>) => Promise<void>;
}

const NewsletterForm: FunctionComponent<
  React.PropsWithChildren<NewsletterFormI>
> = (props) => (
  <>
    <div className="moo" data-mooform-id={Config.moosend.newsletterFormId}>
      <label className="moo-label" htmlFor="Email">
        e-mail
      </label>
    </div>
  </>
);

export default FormHOC<Record<string, unknown>>(NewsletterForm);
