import React, { useCallback, useState } from 'react';
import './RegistrationNumberForm.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import * as Helpers from '../../View/RegistrationStepper/helpers/helpers';
import { useDispatch } from 'react-redux';
import { H1, H2 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { getRequestIdAction } from '@network/Actions/NumberConfirmation';
import { checkForSmsCookie } from '@util/countIntervalRemainingTime';

interface IProps {
  showError: (value: boolean) => void;
}

export const RegistrationNumberForm = ({ showError }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const [initialValues] = useState(Helpers.getFormData());
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    (values: any) => {
      Helpers.setFormData(values);
      history.push({
        ...location,
        state: {
          activeStep: 2,
        },
      });
    },
    [history, location],
  );

  const formik = useFormik({
    initialValues: {
      number: '',
    },
    validationSchema: Yup.object({
      number: Yup.string()
        .min(9, translate('errors.leastCharactersPassword'))
        .max(9, 'Max 9')
        .matches(/^\d+$/),
    }),
    onSubmit: (values: any) => {
      const result = Object.assign({}, initialValues, values);
      dispatch(
        getRequestIdAction(
          {
            phoneNumber: '48' + values.number,
          },
          () => showError(true),
        ),
      );
      onSubmit(result);
    },
  });

  return (
    <form className="number-form container" onSubmit={formik.handleSubmit}>
      <H1 className="number-form_title">
        {translate('addressStep.phoneNumber')}
      </H1>
      <H2 className="number-form_subtitle">
        {translate('addressStep.creaturesWorld')}
        <br />
        {translate('addressStep.providedNumber')}
        <br />
        {translate('addressStep.yourBoxes')}
      </H2>

      <div className="number-form_field form-group">
        <label
          htmlFor="number"
          className="d-block d-md-none font-weight-bold number-form_label"
        >
          <span className="visible-for-individual ">Numer telefonu</span>
        </label>

        <input
          type="number"
          name="number"
          placeholder="Numer telefonu"
          maxLength={9}
          minLength={9}
          id="number"
          autoComplete="off"
          onChange={(e) => {
            if (e.target.value.length > 9) {
              return;
            }
            formik.handleChange(e);
          }}
          onBlur={(e) => {
            if (e.target.value.length > 9) {
              return;
            }
            formik.handleChange(e);
          }}
          value={formik.values.number}
          className="custom-input"
        />
      </div>

      <button
        className={`button_accept number-form_button 
            ${
              formik.isValid &&
              !checkForSmsCookie() &&
              (formik.dirty || formik.values.number.length === 9)
                ? ''
                : 'inactive'
            }`}
      >
        {translate('addressStep.next')}
      </button>
    </form>
  );
};

export default RegistrationNumberForm;
