import React from 'react';
import { Helmet } from 'react-helmet';
import questionAnswers from './qustion-answer.json';
import MainTemplate from '../../Component/MainTemplate/MainTemplate';

import Header from './components/Header/Header';
import Faq from './components/FAQ/Faq';
import './FAQ.scss';
import ContactBlock from './components/ContactBlock/ContactBlock';

function FAQ(): JSX.Element {
  return (
    <>
      <Helmet>
        <title>
          Masz Pytania? Skontaktuj się z nami lub sprawdź FAQ | skitraj.pl
        </title>
        <meta
          name="description"
          content="Jesteśmy gotowi odpowiedzieć na Twoje pytania! Odwiedź naszą sekcję FAQ lub skontaktuj się bezpośrednio, korzystając z naszego formularza kontaktowego."
        />
        <meta name="robots" content="index,follow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MainTemplate>
        <div className="faq-panel">
          <ContactBlock />
          <Header />
          <Faq data={questionAnswers} />
        </div>
      </MainTemplate>
    </>
  );
}

export default FAQ;
