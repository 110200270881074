import { useEffect, useState } from 'react';
import Logo from '@images/customer/landing/logo_skitraj.svg';
import { translate } from '@module/Translate/Translate';
import './AdminToolSidebar.scss';
import sidebarItem from './sidebar-item.json';
import SidebarItem from './SidebarItem';
import { useDispatch } from 'react-redux';
import { getNotModifiedReviewsAction } from '@network/Actions/NotModifiedReviews';
import { getNewComplaintsAction } from '@network/Actions/NewComplaints';
const AdminToolSidebar = ({ setActivePanel, activePanel }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotModifiedReviewsAction());
    dispatch(getNewComplaintsAction());
  }, []);
  return (
    <nav className="admin-sidebar-wrapper">
      <div
        className={'admin-sidebar-navigation ' + (sidebarOpen ? 'hide' : '')}
      >
        <img className="brand-img" src={Logo} alt="Skitraj" />

        <p
          style={{ fontSize: '41px', fontWeight: '700' }}
          className="pb-0 pt-3 pl-3"
        >
          {translate('adminTool.title')}
        </p>

        <ul className="admin-sidebar-navigation_container list-unstyled components">
          {sidebarItem.map((item, index) => (
            <SidebarItem
              key={index}
              setActivePanel={setActivePanel}
              activePanel={activePanel}
              item={item}
            />
          ))}
        </ul>
      </div>

      <div className="admin-sidebar-navigation_content">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <button
              type="button"
              className="btn btn-info"
              onClick={() => setSidebarOpen((prev: any) => !prev)}
            >
              <span> {sidebarOpen ? '>' : '<'} </span>
            </button>
          </div>
        </nav>
      </div>
    </nav>
  );
};

export default AdminToolSidebar;
