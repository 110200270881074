import { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { activateAction } from '../../Network/Actions/ActivateAccount';

export const ActivateAccount: FC<React.PropsWithChildren<unknown>> = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  useEffect(() => {
    activateAction(token)
      .then(() => {
        history.push('/logowanie');
      })
      .catch(() => history.push('/invalidActivateToken'));
  }, []);

  return <></>;
};
