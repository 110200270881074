import React, { useRef } from 'react';
import './EditComplaints.scss';
import { translate } from '../../../../../../Module/Translate/Translate';
import { FindComplaintDto } from '../../../../../../../../types/complaints/find-complaint.dto';
import { H5 } from '../../../../../../Component/Text/Text';
import { Dropdown } from 'primereact/dropdown';
import { useFormik } from 'formik';
import { ServiceType } from '../../../../../../../../types/complaints/service-type.enum';
import { editComplaintAction } from '../../../../../../Network/Actions/Complaints';
import { Messages } from 'primereact/messages';
import { User } from '../../../../../../models/user.model';

interface Props {
  complaint: FindComplaintDto;
  editor: User;
  setRefresh: (refresh: boolean) => void;
}

const statuses = [
  { label: translate('adminTool.complaints.done'), value: true },
  { label: translate('adminTool.complaints.toAnalysis'), value: false },
];

export default function EditComplaints({
  complaint,
  setRefresh,
  editor,
}: Props) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      isDone: complaint ? complaint.isDone : false,
    },
    onSubmit: (values: any) => {
      values.updatedBy = editor.email;
      editComplaintAction(complaint.id, values)
        .then(() => {
          setRefresh(true);
          showMessage(
            'success',
            translate('adminTool.complaints.updateMessage'),
          );
        })
        .catch((error: any) => {
          showMessage('error', error);
        });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const serviceChecker = (service: ServiceType) => {
    switch (service) {
      case ServiceType.rent:
        return translate('adminTool.complaints.rent');
      case ServiceType.storage:
        return translate('adminTool.complaints.storage');
      case ServiceType.sell:
        return translate('adminTool.complaints.store');
    }
  };

  return (
    <div className="complaints-main-container border-details">
      <div className="complaints-header-container">
        <Messages ref={message} />
        <h2>
          {translate('adminTool.complaints.detailsFor')}
          {complaint.id}
        </h2>
      </div>
      <div className="complaints-data-container">
        <div className="complaints-top-container">
          <div className="complaints-info-left-container">
            <H5 className="complaints-h5">
              {translate('adminTool.complaints.username')}
            </H5>
            <p>{complaint.username}</p>
            <H5 className="complaints-h5">
              {translate('adminTool.complaints.email')}
            </H5>
            <p>{complaint.email}</p>
            <H5 className="complaints-h5">
              {translate('adminTool.complaints.phone')}
            </H5>
            <p>{complaint.phone}</p>
            <H5 className="complaints-h5">
              {translate('adminTool.complaints.service')}
            </H5>
            <p>{serviceChecker(complaint.service)}</p>
          </div>
          <div className="complaints-info-right-container">
            <H5 className="complaints-h5">
              {translate('adminTool.complaints.orderNumber')}
            </H5>
            <p>{complaint.orderNumber}</p>
            <form className="complaints-form" onSubmit={formik.handleSubmit}>
              <H5 className="complaints-h5">
                {translate('adminTool.complaints.status')}
              </H5>
              <Dropdown
                value={formik.values.isDone}
                options={statuses}
                className="type-dropdown"
                name="isDone"
                optionLabel="label"
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.isValid && formik.values.isDone !== complaint.isDone
                    ? ''
                    : 'inactive'
                }`}
              >
                {translate('adminTool.complaints.update')}
              </button>
            </form>
          </div>
        </div>
        <div className="complaints-info-bottom-container">
          <H5 className="complaints-h5">
            {translate('adminTool.complaints.description')}
          </H5>
          <p>{complaint.description}</p>
        </div>
      </div>
    </div>
  );
}
