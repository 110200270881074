import React from 'react';
import './Title.scss';
import { H1, P } from '../../../../Component/Text/Text';

export default function Title() {
  return (
    <div className="package-description">
      <H1 className="package-description_title">package.title</H1>
      <P className="package-description_subTitle">package.subtitle</P>
    </div>
  );
}
