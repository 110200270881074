import React, { useEffect, useState } from 'react';
import './Offer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CalculatorBlock from '@component/CalculatorBlock/CalculatorBlock';
import MainTemplate from '@component/MainTemplate/MainTemplate';
import { getProductsAction } from '@network/Actions/Products';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { RootState } from '@src/store';
import { ProductsState, UserState } from '@type/Custom';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import { Helmet } from 'react-helmet';
import CarouselBlock from './components/CarouselBlock/CarouselBlock';
import PrimaryBlockStore from './components/PrimaryBlock/PrimaryBlockStore';
import { H2, H3 } from '@component/Text/Text';
import HowtoBlock from './components/HowtoBlock/HowtoBlock';
import InfoBlock from './components/InfoBlock/InfoBlock';
import PrimaryBlockMove from './components/PrimaryBlock/PrimaryBlockMove';
import HowTo from './components/HowTo/HowTo';
import PrimaryBlockRent from './components/PrimaryBlock/PrimaryBlockRent';
import Comparison from '@view/RentPage/components/Comparison/Comparison';
import LookingMoreBlock from './components/LookingMore/LookingMore';
import B2B from './components/B2B/B2B';

const Offer = () => {
  const dispatch = useDispatch();
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  const [showError, setShowError] = useState(false);
  const { user } = useSelector<RootState, UserState>((state) => state.user);

  useEffect(() => {
    dispatch(getProductsAction(true, ProductGroup.box));
  }, []);

  const location = useLocation();
  const carouselProducts = products.filter(
    (product) =>
      product.group === ProductGroup.box &&
      product.type === ProductType.storage &&
      product.isPromo,
  );

  const isSubPath = location.pathname === 'DlaFirm/products';
  return (
    <>
      <Helmet>
        <title>
          Przechowywanie, przeprowadzki, wynajem dla firm | skitraj.pl
        </title>
        <meta
          name="description"
          content="Rozwiązania przechowywania, przeprowadzek i wynajmu pudełek dostosowane do potrzeb przedsiębiorców. Wybierz profesjonalizm i efektywność!"
        />
        <meta name="robots" content="index,follow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {showError && (
        <Modal showModal={showError} setShowModal={setShowError}>
          <AdminContactForm
            setShowConfirm={setShowError}
            message={
              user.id
                ? translate('errors.badRequest')
                : translate('errors.orderCannotCreate')
            }
            activeContactLink={user.id ? true : false}
          />
        </Modal>
      )}
      <MainTemplate>
        <div className="offer-section">
          <B2B />
          <PrimaryBlockStore />
          <HowtoBlock />
          <InfoBlock section="store" />
          <H2 className="title title-move" id="title-move">
            offer.primaryBlockMove.title
          </H2>
          <PrimaryBlockMove />
          <HowTo section="move" />
          <InfoBlock section="move" />
          <H2 className="title title-rent" id="title-rent">
            offer.primaryBlockRent.title
          </H2>
          <PrimaryBlockRent />
          <HowTo section="rent" />
          <InfoBlock section="rent" />
          <div className="offer-comparison-wrapper">
            <H3>offer.comparisonTitle</H3>
            <Comparison />
          </div>
          <CarouselBlock products={carouselProducts} isSubPath={isSubPath} />
          <CalculatorBlock />
          <LookingMoreBlock />
        </div>
      </MainTemplate>
    </>
  );
};

export default Offer;
