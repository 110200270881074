import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { DeliveryStatusesState } from '../../../../Type/Custom';
import EditDeliveryStatuses from './components/EditDeliveryStatuses/EditDeliveryStatuses';
import { translate } from '../../../../Module/Translate/Translate';
import { getDeliveryStatusesAction } from '../../../../Network/Actions/DeliveryStatuses';
import { InputText } from 'primereact/inputtext';
import useDebounce from '../../../../Util/Debounse';
import { UserProps } from '../../interfaces/interfaces';
import { formatDateToLocalTime } from '../../../../Util/Dates';

const LIMIT_ON_PAGE = 20;

export default function DeliveryStatuses({ user }: UserProps) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);
  const { deliveryStatuses }: DeliveryStatusesState = useSelector<
    RootState,
    DeliveryStatusesState
  >(({ deliveryStatuses }) => deliveryStatuses);

  const [searched, setSearched] = useState('');

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
  });

  useEffect(() => {
    if (mount) {
      getData(lazyParams, searched);
      setMount(false);
    } else if (refresh) {
      getData(lazyParams, searched);
      setRefresh(false);
    } else {
      getData(lazyParams, searched);
    }
  }, [refresh, lazyParams, useDebounce(searched, 500)]);

  const getData = (params: any, search: string) => {
    setLoading(true);
    dispatch(getDeliveryStatusesAction(params, search));
    setLoading(false);
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <EditDeliveryStatuses
        deliveryStatus={data}
        setRefresh={setRefresh}
        editor={user}
      />
    );
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div></div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={searched}
            onChange={(e: any) => setSearched(e.target.value)}
            placeholder={translate('adminTool.delivery-statuses.search')}
          />
        </span>
      </div>
    );
  };

  return (
    <div className="products-table-container">
      <DataTable
        value={deliveryStatuses.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        header={renderHeader}
        loading={loading}
        lazy
        first={lazyParams.first}
        totalRecords={deliveryStatuses.meta.totalItems}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        rows={LIMIT_ON_PAGE}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        onPage={onPage}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
      >
        <Column expander style={{ width: '3em' }} />
        <Column
          field="id"
          sortable
          header={translate('adminTool.deliveryStatuses.id')}
        />
        <Column
          field="warehouse"
          sortable
          header={translate('adminTool.deliveryStatuses.warehouse')}
        />
        <Column
          field="providerType"
          sortable
          header={translate('adminTool.deliveryStatuses.providerType')}
        />
        <Column
          field="provider"
          sortable
          header={translate('adminTool.deliveryStatuses.provider')}
        />
        <Column
          field="providerText"
          sortable
          header={translate('adminTool.deliveryStatuses.providerText')}
        />
        <Column
          field="status"
          sortable
          header={translate('adminTool.deliveryStatuses.status')}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.deliveryStatuses.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.deliveryStatuses.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.deliveryStatuses.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
