import React, { useState } from 'react';
import './ContactBlock.scss';
import { translate } from '@module/Translate/Translate';
import { H1, P } from '@component/Text/Text';
import Modal from '@component/Modal/Modal';
import ContactWithoutLoginForm from '@form/ContactWithoutLoginForm/ContactWithoutLoginForm';
import CompletedSentForm from '@form/CompletedSentForm/CompletedSentForm';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import Spinner from '@component/Spinner/Spinner';

const ContactBlock = () => {
  const [contactModal, setContactModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [visibleSpinner, setVisibleSpinner] = useState(false);

  return (
    <>
      {visibleSpinner && <Spinner />}
      <Modal
        showModal={contactModal}
        setShowModal={() => {
          setContactModal(!contactModal);
        }}
        className="dialog--large"
      >
        <ContactWithoutLoginForm
          setShowModal={() => {
            setContactModal(!contactModal);
          }}
          setShowConfirm={() => {
            setShowConfirmation(true);
          }}
          setSpinner={(value: boolean) => {
            setVisibleSpinner(value);
          }}
          showConfirm={() => {
            setShowError(true);
          }}
        />
      </Modal>
      <Modal
        className="dialog--large"
        showModal={showConfirmation}
        setShowModal={() => setShowConfirmation(false)}
      >
        <CompletedSentForm setShowConfirm={() => setShowConfirmation(false)} />
      </Modal>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <section className="faq-contact container">
        <H1 className="faq-contact_title">faq.contactBlock.title</H1>
        <P className="faq-contact_text">faq.contactBlock.phoneText</P>
        <a href={`tel:${translate('footer.contactNumber')}`}>
          <P className="faq-contact_text accent">footer.contactNumber</P>
        </a>
        <P className="faq-contact_text">faq.contactBlock.emailText</P>
        <a href={`mailto:${translate('faq.contactBlock.email')}`}>
          <P className="faq-contact_text accent">faq.contactBlock.email</P>
        </a>

        <button
          onClick={() => {
            setContactModal(true);
          }}
          className="faq-contact_btn btn btn-large btn-80"
        >
          {translate('faq.contactBlock.buttonText')}
        </button>
      </section>
    </>
  );
};
export default ContactBlock;
