import { useEffect, useState } from 'react';
import Spinner from '@component/Spinner/Spinner';
import { translate } from '@module/Translate/Translate';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { getAdminToolNotificationsAction } from '@network/Actions/Notifications';
import { formatDateToLocalTime } from '@util/Dates';

interface IProps {
  userId: number;
}

const LIMIT_ON_PAGE = 7;

export default function NotificationsTable({ userId }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(1);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
  });

  const getData = async () => {
    setIsLoading(true);

    await getAdminToolNotificationsAction(
      userId,
      lazyParams.first,
      lazyParams.rows,
    )
      .then(({ data }: any) => {
        setNotifications(data.items);
        setTotalItems(data.meta.totalItems);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [lazyParams.first]);

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };

  if (notifications)
    return (
      <DataTable
        value={notifications}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={isLoading}
        first={lazyParams.first}
        totalRecords={+totalItems}
        rows={LIMIT_ON_PAGE}
        stripedRows
        selectionPageOnly
        emptyMessage="No data found"
        className="datatable-responsive"
        onPage={onPage}
        onSort={onSort}
      >
        <Column field="id" header={translate('adminTool.users.id')} />
        <Column
          field="type"
          header={translate('adminTool.users.notificationsType')}
        />
        <Column
          field="title"
          header={translate('adminTool.users.notificationsTytle')}
        />
        <Column
          field="message"
          header={translate('adminTool.users.notificationsMessage')}
        />
        <Column
          field="createdAt"
          header={translate('adminTool.users.createdAt')}
          body={formatDateToLocalTime}
        />
        <Column
          field="updatedAt"
          header={translate('adminTool.users.updatedAt')}
          body={formatDateToLocalTime}
        />
      </DataTable>
    );
  else return <Spinner />;
}
