import { translate } from '@module/Translate/Translate';
import { FindComplaintDto } from '@rootTypes/complaints/find-complaint.dto';
import { formatDateToLocalTime } from '@util/Dates';
import { UserProps } from '@view/AdminTool/interfaces/interfaces';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState, useEffect } from 'react';
import EditComplaints from './components/EditComplaints/EditComplaints';
import { InputText } from 'primereact/inputtext';
import './Complaints.scss';
import useDebounce from '@util/Debounse';
import { findComplaints, getComplaints } from '@network/Actions/Complaints';
import { LazyParamsInterface } from './interfaces/LazyParamInterface';
const LIMIT_ON_PAGE = 7;

export default function Complaints({ user }: UserProps) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [complaints, setComplaints] = useState({
    items: [],
    meta: { totalItems: 0 },
  });

  const [lazyParams, setLazyParams] = useState<LazyParamsInterface>({
    skip: 0,
    take: LIMIT_ON_PAGE,
    sortField: '',
    order: null,
  });

  useEffect(() => {
    if (search === '') {
      getData();
    } else {
      searchData();
    }
    setRefresh(false);
  }, [JSON.stringify(lazyParams), refresh]);

  useEffect(() => {
    if (search !== '') {
      searchData();
    } else {
      setLazyParams({
        skip: 0,
        take: LIMIT_ON_PAGE,
        sortField: '',
        order: null,
      });
      setRefresh(true);
    }
  }, [useDebounce(search, 500)]);

  const searchData = async () => {
    setLoading(true);
    const data = await findComplaints({ ...lazyParams, searchedValue: search });
    setComplaints(data);
    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    const data = await getComplaints(lazyParams);
    setComplaints(data);
    setLoading(false);
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLazyParams({
      skip: 0,
      take: LIMIT_ON_PAGE,
      sortField: '',
      order: null,
    });
    setSearch(value);
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'isDone') {
      return rowData[item.field]
        ? translate('adminTool.complaints.done')
        : translate('adminTool.complaints.toAnalysis');
    }
  };

  const onChange = (event: any) => {
    setLazyParams({
      skip: event.first,
      take: LIMIT_ON_PAGE,
      sortField: event.sortField,
      order: event.sortOrder,
    });
  };

  const rowExpansionTemplate = (data: FindComplaintDto) => {
    return (
      <EditComplaints complaint={data} setRefresh={setRefresh} editor={user} />
    );
  };

  return (
    <div className="products-table-container">
      <InputText
        className="complaints-input"
        value={search}
        onChange={onGlobalFilterChange}
        placeholder={translate('adminTool.common.search')}
        title={translate('adminTool.complaints.inputHelp')}
      />
      <DataTable
        value={complaints.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={loading}
        first={lazyParams.skip}
        totalRecords={complaints.meta.totalItems}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        rows={LIMIT_ON_PAGE}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        onPage={onChange}
        onSort={onChange}
        sortOrder={lazyParams.order}
        sortField={lazyParams.sortField}
      >
        <Column expander style={{ width: '3em' }} />
        <Column
          field="id"
          sortable
          header={translate('adminTool.complaints.id')}
        />
        <Column
          field="username"
          sortable
          header={translate('adminTool.complaints.username')}
        />
        <Column
          field="email"
          sortable
          header={translate('adminTool.complaints.email')}
        />
        <Column
          field="orderNumber"
          sortable
          header={translate('adminTool.complaints.orderNumber')}
        />
        <Column
          field="isDone"
          sortable
          header={translate('adminTool.complaints.status')}
          body={labelChecker}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.complaints.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.complaints.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.complaints.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
