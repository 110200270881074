import {
  ADMIN_ERRORS_REQUEST,
  ADMIN_ERRORS_SUCCESS,
  ADMIN_ERRORS_FAIL,
} from '@network/Actions/Types';
import { FindStatusesForOrderDTO } from '@rootTypes/orders/find-all-order-statuses.dto';
import { AdminOrderErrorsState } from '@type/Custom';

interface IAction {
  type: string;
  payload?: FindStatusesForOrderDTO;
}

export const AdminOrderErrorsReducer = (
  state: AdminOrderErrorsState = {
    error: null,
    loading: false,
    data: { items: [], meta: { totalItems: 0 } },
  },
  action: IAction,
) => {
  switch (action.type) {
    case ADMIN_ERRORS_REQUEST:
      return {
        loading: true,
        data: state.data,
        error: 0,
      };
    case ADMIN_ERRORS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        error: 0,
      };
    case ADMIN_ERRORS_FAIL:
      return {
        data: state.data,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
