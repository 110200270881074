import React from 'react';
import { FindProductDetailsDto } from '../../../../../../../../types/product-details/find-product-details.dto';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { translate } from '../../../../../../Module/Translate/Translate';

interface Props {
  productDetails: FindProductDetailsDto;
  editMode: boolean;
  setProductDetails: any;
  updateProductDetails: any;
  productId: number;
}

export default function Details({
  productDetails,
  editMode,
  setProductDetails,
  updateProductDetails,
  productId,
}: Props) {
  const formik = useFormik({
    initialValues: {
      weightBrutto:
        editMode && productDetails ? productDetails.weightBrutto : 0,
      weightNetto: editMode && productDetails ? productDetails.weightNetto : 0,
      height: editMode && productDetails ? productDetails.height : 0,
      width: editMode && productDetails ? productDetails.width : 0,
      depth: editMode && productDetails ? productDetails.depth : 0,
      capacity: editMode && productDetails ? productDetails.capacity : 0,
    },
    validationSchema: Yup.object({
      weightBrutto: Yup.number(),
      weightNetto: Yup.number(),
      height: Yup.number(),
      width: Yup.number(),
      depth: Yup.number(),
      capacity: Yup.number(),
    }),
    onSubmit: (values: any) => {
      if (!editMode || !productDetails) {
        setProductDetails({ ...values, product: productId }, productId);
      } else {
        updateProductDetails(values, productId, productDetails.id);
      }
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FormFieldFormik
          label={translate('adminTool.products.weightBrutto')}
          type="number"
          name="weightBrutto"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.weightBrutto}
        />
        <br />
        <FormFieldFormik
          label={translate('adminTool.products.weightNetto')}
          type="number"
          name="weightNetto"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.weightNetto}
        />
        <br />
        <FormFieldFormik
          label={translate('adminTool.products.height')}
          type="number"
          name="height"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.height}
        />
        <br />
        <FormFieldFormik
          label={translate('adminTool.products.width')}
          type="number"
          name="width"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.width}
        />
        <br />
        <FormFieldFormik
          label={translate('adminTool.products.depth')}
          type="number"
          name="depth"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.depth}
        />
        <br />
        <FormFieldFormik
          label={translate('adminTool.products.capacity')}
          type="number"
          name="capacity"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.capacity}
        />
        <br />

        <button
          type="submit"
          className={`submitForm invoice__button m-auto button_accept ${
            formik.isValid ? '' : 'inactive'
          }`}
        >
          {translate('adminTool.products.updateDetails')}
        </button>
      </form>
    </div>
  );
}
