import { useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, TabPanel, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Calendar from '../Calendar/Calendar';
import ProductList from '../ProductList/ProductList';
import ProductPackageList from '../ProductPackageList/ProductPackageList';
import BoxList from '../BoxList/BoxList';
import './Panel.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  BasketState,
  DefaultSettingsState,
  OrderBlockedDaysState,
  ProductsState,
  UserState,
} from '@type/Custom';
import { RootState } from '@src/store';
import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '@rootTypes/default-settings/default-settings-type.enum';
import { getProductsAction } from '@network/Actions/Products';
import { getOrderBlockedDaysAction } from '@network/Actions/OrderBlockedDays';
import { getLimitsAction } from '@network/Actions/Limits';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { deleteDateInBasket } from '@network/Actions/Basket';
import Modal from '@component/Modal/Modal';
import MessageOrderClosingTime from '@form/MessageOrderClosingTime/MessageOrderClosingTime';
import { translate } from '@module/Translate/Translate';
import { BoxesSort } from '@util/BoxesSort';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import PaymentForm from '@form/PaymentForm/PaymentForm';
import { sendProductsEvent } from '@src/GTM/GTM';
import P, { H5 } from '@component/Text/Text';
import { disabledDays } from '@util/DisabledDays';
import { verifyOrderClose } from '@util/Dates';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';

interface LocationState {
  from: string;
}

export default function Panel(props: any) {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultIndex = parseInt(props.location?.defaultIndex);
  const weekQuantity = parseInt(props.location?.weekQuantity);
  const monthQuantity = parseInt(props.location?.monthQuantity);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const location = useLocation<LocationState>();

  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  const { user }: UserState = useSelector<RootState, UserState>(
    (state) => state.user,
  );

  const { dates, periods }: BasketState = useSelector<RootState, BasketState>(
    ({ basket }) => basket,
  );
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);

  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  const setting =
    defaultSettings.find(
      (elem) =>
        elem.type === DefaultSettingsTypeEnum.defaultMinValueForWarehouseStock,
    )?.limit || UndefinedSettingsType.undefinedMinValueForWarehouseStock;

  useEffect(() => {
    dispatch(
      getProductsAction(true, undefined, undefined, setting, setShowError),
    );
    dispatch(getOrderBlockedDaysAction(setShowError));
    dispatch(getLimitsAction(setShowError));
    sendProductsEvent(state.products, 1);
  }, []);

  useEffect(() => {
    if (
      window.screen.availWidth < 1200 &&
      location?.state?.from === 'bagIcon'
    ) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [products]);

  const defaultOrderClosingTime =
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultOrderClosingTime,
    )?.limit || UndefinedSettingsType.undefinedOrderClosingTime;

  const defaultCountForStorage =
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultCountForStorage,
    )?.limit || UndefinedSettingsType.undefinedCountForStorage;

  const defaultCountForRent =
    defaultSettings.find(
      (setting) => setting.type === DefaultSettingsTypeEnum.defaultCountForRent,
    )?.limit || UndefinedSettingsType.undefinedCountForRent;

  const defaultCountForShop =
    defaultSettings.find(
      (setting) => setting.type === DefaultSettingsTypeEnum.defaultCountForShop,
    )?.limit || UndefinedSettingsType.undefinedCountForShop;

  const defaultCountForPackage =
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultCountForPackage,
    )?.limit || UndefinedSettingsType.undefinedCountForPackage;

  const defaultMaxNumberOfDaysToStartTheRentService =
    defaultSettings.find(
      (setting) =>
        setting.type ===
        DefaultSettingsTypeEnum.defaultMaxNumberOfDaysToStartTheRentService,
    )?.limit ||
    UndefinedSettingsType.undefinedMaxNumberOfDaysToStartTheRentalService;

  const defaultMinNumberOfDaysOfDuragionOfTheRentService =
    defaultSettings.find(
      (setting) =>
        setting.type ===
        DefaultSettingsTypeEnum.defaultMinNumberOfDaysOfDuragionOfTheRentService,
    )?.limit ||
    UndefinedSettingsType.undefinedMinNumberOfDaysOfDuragionOfTheRentService;

  const defaultMaxNumberOfDaysOfDuragionOfTheRentService =
    defaultSettings.find(
      (setting) =>
        setting.type ===
        DefaultSettingsTypeEnum.defaultMaxNumberOfDaysOfDuragionOfTheRentService,
    )?.limit ||
    UndefinedSettingsType.undefinedMaxNumberOfDaysOfDuragionOfTheRentService;

  const defaultMaxNumberOfDaysToStartTheStorageService =
    defaultSettings.find(
      (setting) =>
        setting.type ===
        DefaultSettingsTypeEnum.defaultMaxNumberOfDaysToStartTheStorageService,
    )?.limit ||
    UndefinedSettingsType.undefinedMaxNumberOfDaysToStartTheStorageService;

  const defaultMinNumberOfDaysOfDuragionOfTheStorageService =
    defaultSettings.find(
      (setting) =>
        setting.type ===
        DefaultSettingsTypeEnum.defaultMinNumberOfDaysOfDuragionOfTheStorageService,
    )?.limit ||
    UndefinedSettingsType.undefinedMinNumberOfDaysOfDuragionOfTheStorageService;

  const defaultMaxNumberOfDaysOfDuragionOfTheStorageService =
    defaultSettings.find(
      (setting) =>
        setting.type ===
        DefaultSettingsTypeEnum.defaultMaxNumberOfDaysOfDuragionOfTheStorageService,
    )?.limit ||
    UndefinedSettingsType.undefinedMaxNumberOfDaysOfDuragionOfTheStorageService;

  const orderBlockedDates: OrderBlockedDaysState = useSelector<
    RootState,
    OrderBlockedDaysState
  >(({ orderBlockedDates }) => orderBlockedDates);

  const disabledAdminDays = orderBlockedDates.dates.map((day) => day.date);

  const holidays = useMemo(
    () => disabledDays(disabledAdminDays),
    [disabledAdminDays],
  );

  function onSubmit() {
    if (
      state.products.filter((product) => product.type === ProductType.rent)
        .length === 0 &&
      state.dates.filter((date) => date.type === ProductType.rent)
    ) {
      dispatch(deleteDateInBasket(ProductType.rent));
    }
    if (
      state.products.filter((product) => product.type === ProductType.storage)
        .length === 0 &&
      state.dates.filter((date) => date.type === ProductType.storage)
    ) {
      dispatch(deleteDateInBasket(ProductType.storage));
    }

    verifyOrderClose({ holidays, defaultOrderClosingTime, dates })
      ? setShowModal(true)
      : history.push({
          pathname: '/rejestracja',
          state: {
            showStepper: true,
            activeStep: user.phone ? 3 : 1,
          },
        });
  }

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.serverError')}
          activeContactLink={false}
        />
      </Modal>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <MessageOrderClosingTime setShowModal={setShowModal} />
      </Modal>
      <div className="panel-main-container">
        <div className="panel-container panel-wrapper">
          <h2 className="panel-container_title">
            {translate('orderPanel.panelTitle')}
          </h2>
          <Tabs defaultIndex={defaultIndex}>
            <TabList>
              <Tab>{translate('orderPanel.panelTabStore.title')}</Tab>
              <Tab>{translate('orderPanel.panelTabRent.title')}</Tab>
            </TabList>

            <TabPanel>
              <Calendar
                titleFirstDate={translate(
                  'orderPanel.panelTabStore.calendar.titleFirstDate',
                )}
                titleSecondDate={translate(
                  'orderPanel.panelTabStore.calendar.titleSecondDate',
                )}
                textTime={translate(
                  'orderPanel.panelTabStore.calendar.textTime',
                )}
                textEndDate={'orderPanel.panelTabRent.calendar.subtitle'}
                maxStartDate={defaultMaxNumberOfDaysToStartTheStorageService}
                minLastDate={
                  defaultMinNumberOfDaysOfDuragionOfTheStorageService
                }
                maxLastDate={
                  defaultMaxNumberOfDaysOfDuragionOfTheStorageService
                }
                type={ProductType.storage}
                rangeDate={monthQuantity}
                defaultOrderClosingTime={defaultOrderClosingTime}
                periods={periods}
                holidays={holidays}
              >
                <h2 className="panel-container_title">
                  {translate('orderPanel.panelTabStore.calendar.title')}
                </h2>
              </Calendar>

              <BoxList
                stepValueBoxCounter={defaultCountForStorage}
                productsData={BoxesSort(
                  products.filter(
                    (product) =>
                      product.group === ProductGroup.box &&
                      product.type === ProductType.storage,
                  ),
                )}
              />

              <ProductList
                text={translate('orderPanel.panelTabStore.productList.text')}
                productsData={BoxesSort(
                  products.filter(
                    (product) =>
                      (product.group === ProductGroup.shop &&
                        product.isPromo === true) ||
                      (product.group === ProductGroup.shop &&
                        product.isVisibleOnOrderPanel === true),
                  ),
                )}
                stepValueCounter={defaultCountForShop}
              >
                <h2 className="panel-container_title">
                  {translate('orderPanel.panelTabStore.productList.title')}
                </h2>
              </ProductList>
            </TabPanel>

            <TabPanel>
              <Calendar
                titleFirstDate={translate(
                  'orderPanel.panelTabRent.calendar.titleFirstDate',
                )}
                titleSecondDate={translate(
                  'orderPanel.panelTabRent.calendar.titleSecondDate',
                )}
                textTime={translate(
                  'orderPanel.panelTabRent.calendar.textTime',
                )}
                textEndDate={'orderPanel.panelTabRent.calendar.subtitle'}
                maxStartDate={defaultMaxNumberOfDaysToStartTheRentService}
                minLastDate={defaultMinNumberOfDaysOfDuragionOfTheRentService}
                maxLastDate={defaultMaxNumberOfDaysOfDuragionOfTheRentService}
                type={ProductType.rent}
                rangeDate={weekQuantity}
                defaultOrderClosingTime={defaultOrderClosingTime}
                periods={periods}
                holidays={holidays}
              >
                <h2 className="panel-container_title">
                  {translate('orderPanel.panelTabRent.calendar.title')}
                </h2>
              </Calendar>

              <BoxList
                stepValueBoxCounter={defaultCountForRent}
                productsData={BoxesSort(
                  products.filter(
                    (product) =>
                      product.group === ProductGroup.box &&
                      product.type === ProductType.rent,
                  ),
                )}
              />

              <ProductPackageList
                productsData={BoxesSort(
                  products.filter(
                    (product) =>
                      (product.isPromo === true &&
                        product.group === ProductGroup.packageBox &&
                        product.type === ProductType.rent) ||
                      (product.isVisibleOnOrderPanel === true &&
                        product.group === ProductGroup.packageBox &&
                        product.type === ProductType.rent),
                  ),
                )}
                stepValueCounter={defaultCountForPackage}
                text={translate(
                  'orderPanel.panelTabRent.productPackageList.text',
                )}
              >
                <h2 className="panel-container_title">
                  {translate(
                    'orderPanel.panelTabRent.productPackageList.title',
                  )}
                </h2>
              </ProductPackageList>
              <ProductList
                text={translate('orderPanel.panelTabRent.productList.text')}
                productsData={BoxesSort(
                  products.filter(
                    (product) =>
                      (product.group === ProductGroup.shop &&
                        product.isPromo === true) ||
                      (product.isVisibleOnOrderPanel === true &&
                        product.group === ProductGroup.shop),
                  ),
                )}
                stepValueCounter={defaultCountForShop}
              >
                <h2 className="panel-container_title">
                  {translate('orderPanel.panelTabRent.productList.title')}
                </h2>
              </ProductList>
            </TabPanel>
          </Tabs>
        </div>
        <div className="payment-container">
          <PaymentForm onSubmit={onSubmit} />
          <div className="packageInfo">
            <H5 className="packageproduct-title">
              &#9432; {translate('paymentForm.infoTitle')}
            </H5>
            <P className="packageproduct-info">
              {translate('paymentForm.infoText')}
            </P>
          </div>
        </div>
      </div>
    </>
  );
}
