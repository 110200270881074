import Holidays from 'date-holidays';

const hd = new Holidays('PL');

export const disabledDays = (disabledAdminDays: Date[]) => {
  const initHolidays = [];
  const currentYear = new Date().getFullYear();
  initHolidays.push(
    ...hd.getHolidays(currentYear),
    ...hd.getHolidays(currentYear + 1),
  );
  const holidays = initHolidays
    .filter((day) => day.type === 'public')
    .map((day) => day.start);
  const disabledForAdmin = disabledAdminDays.map((day) => new Date(day));
  holidays.push(...disabledForAdmin);
  return holidays;
};
