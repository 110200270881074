export enum AuthValidation {
  WRONG_EMAIL = "incorrectEmail",
  WRONG_PASSWORD = "invalidPassword",
  USER_NOT_EXIST = "userNotExist",
  USED_EMAIL = "existEmail",
  NOT_ACTIVE = "notActive",
  UNKNOWN_ERROR = "unknownError",
  SERVER_ERROR = "serverError",
  SUCCESS = "success",
  WMS_ERROR = "wmsError",
  TO_MANY_ATTEMPTS = "toManyAttempts",
}
