import { useEffect, useState } from 'react';
import Modal from '@component/Modal/Modal';
import AddDescriptionModal from '@form/AddDescriptionModal/AddDescriptionModal';
import CompletedSentForm from '@form/CompletedSentForm/CompletedSentForm';
import OrderDetailForm from '@form/OrderDetailForm/OrderDetailForm';
import PickupBoxesBeforeDeadlineModal from '@form/PickupBoxesBeforeDeadlineModal/PickupBoxesBeforeDeadlineModal';
import ReturnBoxesForm from '@form/ReturnBoxesForm/ReturnBoxesForm';
import VerifyReceiveReturnBoxesForm from '@form/VerifyReceiveReturnBoxesForm/VerifyReceiveReturnBoxesForm';
import VerifyReturnBoxesForm from '@form/VerifyReturnBoxesForm/VerifyReturnBoxesForm';
import { translate } from '@module/Translate/Translate';
import { getOrdersPaymentHistory } from '@network/Actions/Payment';
import { FindOrderDto } from '@rootTypes/orders/find-order.dto';
import { FindPaymentDTO } from '@rootTypes/payments/dto/find-payment.dto';
import { PaymentStatusEnum } from '@rootTypes/payments/enums/payment-status.enum';
import { PaymentTypeEnum } from '@rootTypes/payments/enums/payment-type.enum';
import { FindStockDto } from '@rootTypes/stocks/find-stock.dto';
import { StockStatus } from '@rootTypes/stocks/stock-status.enum';
import { StockType } from '@rootTypes/stocks/stock-type.enum';
import {
  TransportTypeEnum,
  UndefinedTransportCost,
} from '@rootTypes/transport/transport-type.enum';
import { dateGetTime } from '@util/DateGetTime';
import { calculateAdditionalStorePeriodPrice } from '@util/ProductsCalculate';
import {
  calculateAmortizationTransportPrice,
  calculateTransportStorePrice,
} from '@util/TransportPrice';
import HidingPlaceElementHeader from '../HidingPlaceElementHeader/HidingPlaceElementHeader';
import { StorageBox } from './StorageBox';
import boxImage from '@images/hidding-place/box.png';
import { calculateTotalMonthsOfStorage } from '@rootUtils/CalculateTotalMonthsOfStorage';
import './HidingPlaceElement.scss';
import ConfirmNumberForm from '@form/ConfirmNumberForm/ConfirmNumberForm';
import ReceiveBoxesForm from '@form/ReceiveBoxesForm/ReceiveBoxesForm';
interface IProps {
  order: FindOrderDto;
  setRefresh: any;
  stocks: FindStockDto[];
  visibleSpinner: (value: boolean) => void;
  showError: (value: boolean) => void;
}

export const StorageBoxesGroup = ({
  order,
  setRefresh,
  stocks,
  visibleSpinner,
  showError,
}: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBox, setSelectedBox] = useState<FindStockDto[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [selectedStock, setSelectedStock] = useState<FindStockDto>();
  const [showReceiveModal, setShowReceiveModal] = useState<boolean>(false);
  const [showDescriptionModal, setShowDescriptionModal] =
    useState<boolean>(false);
  const [isNumConfirmed, setIsNumConfirmed] = useState<boolean>(false);
  const [showReturnModal, setShowReturnModal] = useState<boolean>(false);
  const [showMobileBoxes, setShowMobileBoxes] = useState(false);

  const [verifyReturnModal, setVerifyReturnModal] = useState<boolean>(false);

  const [pickupBoxesBefore, setPickupBoxesBefore] = useState<boolean>(false);
  const [verifyReceiveReturnModal, setVerifyReceiveReturnModal] =
    useState<boolean>(false);
  const [courierOrdered, setCourierOrdered] = useState<boolean>(false);
  const [editedBoxes, setEditedBoxes] = useState<any>(null);
  const [date, setDate] = useState<any>(null);
  const [payments, setPayments] = useState<FindPaymentDTO[]>([]);
  const [numberVerified, setNumberVerified] = useState<boolean>(false);

  const [headerPayload, setHeaderPayload] = useState<any>({
    showModal,
    setShowModal,
    selectedBox,
    setSelectedBox,
    selectedAll,
    setSelectedAll,
    showReceiveModal,
    setShowReceiveModal,
    showReturnModal,
    setShowReturnModal,
    setPickupBoxesBefore,
  });

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  window.addEventListener('resize', () =>
    setIsDesktop(window.innerWidth > 768),
  );

  const toggleSelectedAll = function () {
    if (!selectedAll) {
      setSelectedAll(true);
      setSelectedBox(
        stocks
          .filter((el) => {
            if (checkStatus(el, 'select')) {
              return el;
            }
          })
          .map((i) => i),
      );
    } else {
      setSelectedAll(false);
      setSelectedBox([]);
    }
  };

  const checkStatus = function (item: FindStockDto, origin: string): boolean {
    if (origin === 'description') {
      if (item.stockStatus === StockStatus.STOCK_ATC && item.stockNumber) {
        return true;
      }
      return false;
    } else {
      if (item.stockStatus === StockStatus.STOCK_IW && item.stockNumber) {
        return true;
      }
      return false;
    }
  };

  const checkAreAvailableBoxes = function (items: FindStockDto[]): boolean {
    if (items.find((stock) => stock.stockStatus === StockStatus.STOCK_IW)) {
      return true;
    } else {
      return false;
    }
  };

  const clickedBox = function (item: FindStockDto) {
    if (!checkStatus(item, 'select')) {
      return;
    }

    if (selectedBox.indexOf(item) < 0) {
      setSelectedBox([...selectedBox, item]);
    } else {
      setSelectedAll(false);
      setSelectedBox(
        selectedBox.filter((i) => i.stockNumber !== item.stockNumber),
      );
    }
  };
  const setDescription = function (stock: FindStockDto) {
    setSelectedStock(stock);
    setShowDescriptionModal(!showDescriptionModal);
  };

  const selectedAllBoxLength = stocks.length === selectedBox.length;

  const getStorageTransportPrice = order.transportPrice?.find(
    (item) => item.type === TransportTypeEnum.storage,
  )?.price;

  const storageTransportPrice =
    getStorageTransportPrice === 0
      ? 0
      : getStorageTransportPrice || UndefinedTransportCost;

  const getSingleTransportPrice = order.transportPrice?.find(
    (item) => item.type === TransportTypeEnum.single,
  )?.price;

  const singleTransportPrice =
    getSingleTransportPrice === 0
      ? 0
      : getSingleTransportPrice || UndefinedTransportCost;

  const getStackTransportPrice = order.transportPrice?.find(
    (item) => item.type === TransportTypeEnum.stack,
  )?.price;

  const stackTransportPrice =
    getStackTransportPrice === 0
      ? 0
      : getStackTransportPrice || UndefinedTransportCost;

  useEffect(() => {
    visibleSpinner(true);
    getOrdersPaymentHistory(order.id)
      .then(({ data }) => {
        setPayments(data);
      })
      .catch(() => {
        showError(true);
      })
      .finally(() => visibleSpinner(false));
  }, []);

  const paymentsSuccess = payments.filter(
    (payment) =>
      payment.status === PaymentStatusEnum.success &&
      payment.type !== PaymentTypeEnum.transport,
  );

  const period = calculateTotalMonthsOfStorage(
    new Date(stocks[0].startDate),
    new Date(stocks[0].endDate),
  );

  const unpaidPeriods = period - paymentsSuccess.length;

  const boxesStackedStore = order.stocks.filter(
    (stock) =>
      stock.stockType === StockType.STORAGE && stock.products[0].isStack,
  );
  const boxesNotStackedStore = order.stocks.filter(
    (stock) =>
      stock.stockType === StockType.STORAGE && !stock.products[0].isStack,
  );

  const boxesValueStackedStore = boxesStackedStore.length;
  const boxesValueNotStackedStore = boxesNotStackedStore.length;

  const transportStorePrice = calculateTransportStorePrice({
    quantityStackedBoxForStore: boxesValueStackedStore,
    quantityNotStackedBoxForStore: boxesValueNotStackedStore,
    singleTransportPrice,
    stackTransportPrice,
  });

  const calculateAmortizationPrice = calculateAmortizationTransportPrice({
    transportStorePrice,
    period,
    unpaidPeriods,
  });

  const amortizationTransportPrice =
    calculateAmortizationPrice > 0 ? calculateAmortizationPrice : 0;

  const additionalStorePeriodPrice =
    calculateAdditionalStorePeriodPrice(stocks);

  const selectDescriptionForStocks = (stocks: FindStockDto[]): FindStockDto => {
    const filteredStocks = stocks.filter((el: FindStockDto) => {
      if (el.stockType === StockType.STORAGE) {
        return el;
      }
    });
    return filteredStocks[0];
  };

  const stock = selectDescriptionForStocks(stocks);
  const getCardActionButton = function () {
    switch (stock.stockStatus) {
      case StockStatus.STOCK_IW:
        return (
          <button
            className="btn btn-100 mb-2"
            onClick={() => setShowMobileBoxes(!showMobileBoxes)}
          >
            {translate('hidingPlaceBlock.hidingPlaceElement.receiveBoxes')}
          </button>
        );

      case StockStatus.STOCK_ATC:
        return (
          <button
            className="btn btn-100 mb-2"
            onClick={() => setShowMobileBoxes(!showMobileBoxes)}
          >
            {translate('hidingPlaceBlock.hidingPlaceElement.orderCourier')}
          </button>
        );

      case StockStatus.STOCK_ATCF:
        return (
          <button
            className="btn mb-2 btn-100"
            onClick={() => setShowMobileBoxes(!showMobileBoxes)}
          >
            {translate('hidingPlaceBlock.hidingPlaceElement.returnBoxes')}
          </button>
        );
      case StockStatus.STOCK_RFR:
      default:
        return (
          <button
            className="btn mb-2 btn-100"
            onClick={() => setShowMobileBoxes(!showMobileBoxes)}
          >
            {translate('hidingPlaceBlock.hidingPlaceElement.assignedBoxes')}
          </button>
        );
    }
  };

  const getModalActionButton = function () {
    switch (stock.stockStatus) {
      case StockStatus.STOCK_IW:
        return (
          <button
            className="btn mb-2 btn-100"
            disabled={selectedBox.length === 0 ? true : false}
            onClick={() => {
              setShowMobileBoxes(!showMobileBoxes);
              if (selectedAllBoxLength && amortizationTransportPrice === 0) {
                dateGetTime(new Date()) <
                  dateGetTime(new Date(stocks[0].endDate)) &&
                  setShowReceiveModal(true);
              } else
                dateGetTime(new Date()) <
                dateGetTime(new Date(stocks[0].endDate))
                  ? setPickupBoxesBefore(true)
                  : setShowReceiveModal(true);
            }}
          >
            {translate('hidingPlaceBlock.hidingPlaceElement.orderCourier')}
          </button>
        );

      case StockStatus.STOCK_ATC:
        return (
          <button
            className="btn mt-3 btn-100"
            disabled={stocks.filter((item) => !item.description).length > 0}
            onClick={() => {
              setShowMobileBoxes(!showMobileBoxes);
              setShowReturnModal(true);
            }}
          >
            {translate('hidingPlaceBlock.hidingPlaceElement.orderCourier')}
          </button>
        );
      case StockStatus.STOCK_ATCF:
        return (
          <button
            className="btn mt-3 btn-100"
            onClick={() => {
              setShowMobileBoxes(!showMobileBoxes);
              setShowReturnModal(true);
            }}
          >
            {translate('hidingPlaceBlock.hidingPlaceElement.returnBoxes')}
          </button>
        );
      case StockStatus.STOCK_RFR:
      default:
        return (
          <button
            className="btn mt-3 btn-100"
            onClick={() => setShowMobileBoxes(!showMobileBoxes)}
          >
            {translate('hidingPlaceBlock.hidingPlaceElement.back')}
          </button>
        );
    }
  };

  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <OrderDetailForm
          setShowConfirm={setShowModal}
          data={order}
          stocks={stocks}
          additionalStorePeriodPrice={additionalStorePeriodPrice}
        />
      </Modal>
      <Modal
        showModal={isNumConfirmed}
        setShowModal={setIsNumConfirmed}
        className="dialog--large"
      >
        <ConfirmNumberForm
          showError={showError}
          setVerification={(value) => {
            setIsNumConfirmed(false);
            setNumberVerified(value);
            setVerifyReceiveReturnModal(value);
          }}
          visibleSpinner={visibleSpinner}
        />
      </Modal>
      <Modal
        showModal={showReceiveModal}
        setShowModal={(value: boolean) => {
          setShowReceiveModal(value);
          !isDesktop && setShowMobileBoxes(true);
        }}
        className="dialog--large"
      >
        <ReceiveBoxesForm
          setShowConfirm={(val: boolean) => {
            setShowReturnModal(val);
            setShowReceiveModal(val);
          }}
          order={order}
          setVerifyReceiveReturnModal={(value) =>
            numberVerified
              ? setVerifyReceiveReturnModal(value)
              : setIsNumConfirmed(value)
          }
          setEditedBoxes={(box) => setEditedBoxes(box)}
          date={setDate}
          amortizationTransportPrice={amortizationTransportPrice}
          selectedBox={selectedBox}
          selectedAllBoxLength={selectedAllBoxLength}
          showError={showError}
        />
      </Modal>
      <Modal
        showModal={showDescriptionModal}
        setShowModal={(value: boolean) => {
          setShowDescriptionModal(value);
          !isDesktop && setShowMobileBoxes(true);
        }}
      >
        <AddDescriptionModal
          setRefresh={setRefresh}
          selectedBox={selectedStock}
          setShowDescriptionModal={(value: boolean) => {
            setShowDescriptionModal(value);
            !isDesktop && setShowMobileBoxes(true);
          }}
          description={selectedStock ? selectedStock.description : ''}
          visibleSpinner={visibleSpinner}
          setShowError={(value) => showError(value)}
        />
      </Modal>
      <Modal
        showModal={showReturnModal}
        setShowModal={setShowReturnModal}
        className="dialog--large"
      >
        <ReturnBoxesForm
          setShowConfirm={setShowReturnModal}
          order={order}
          setVerifyReturnModal={setVerifyReturnModal}
          setEditedBoxes={(box) => setEditedBoxes(box)}
          date={setDate}
          currentStorageStock={stocks}
        />
      </Modal>
      <Modal showModal={verifyReturnModal} setShowModal={setVerifyReturnModal}>
        <VerifyReturnBoxesForm
          setVerifyReturnModal={setVerifyReturnModal}
          setShowReturnModal={setShowReturnModal}
          editedBoxes={editedBoxes}
          order={order}
          date={date}
          setCourierOrdered={setCourierOrdered}
          visibleSpinner={visibleSpinner}
        />
      </Modal>
      <Modal
        showModal={verifyReceiveReturnModal}
        setShowModal={setVerifyReceiveReturnModal}
      >
        <VerifyReceiveReturnBoxesForm
          setVerifyReceiveReturnModal={setVerifyReceiveReturnModal}
          setShowReceiveModal={setShowReceiveModal}
          editedBoxes={editedBoxes}
          setRefresh={setRefresh}
          order={order}
          date={date}
          setCourierOrdered={setCourierOrdered}
          visibleSpinner={visibleSpinner}
          showError={(value) => showError(value)}
        />
      </Modal>
      <Modal
        showModal={pickupBoxesBefore}
        setShowModal={(value: boolean) => {
          setPickupBoxesBefore(value);
          !isDesktop && setShowMobileBoxes(true);
        }}
        className="pickupBoxesBeforeDeadlineModal"
      >
        <PickupBoxesBeforeDeadlineModal
          setPickupBoxesBefore={setPickupBoxesBefore}
          setShowReceiveModal={setShowReceiveModal}
          selectedAllBoxLength={selectedAllBoxLength}
          selectedBoxLength={selectedBox.length}
          storageTransportPrice={storageTransportPrice}
          amortizationTransportPrice={amortizationTransportPrice}
        />
      </Modal>
      <Modal
        showModal={courierOrdered}
        setShowModal={(closeModal: boolean) => {
          setCourierOrdered(closeModal);
          if (closeModal === false) {
            setRefresh(true);
            setShowReceiveModal(false);
          }
        }}
        className="dialog--middle"
      >
        <CompletedSentForm
          setShowConfirm={setCourierOrdered}
          title={translate('courierOrdered.title')}
          message={
            stocks[0].stockStatus === StockStatus.STOCK_RFRF
              ? translate('courierOrdered.message')
              : translate('hidingPlaceBlock.courierText')
          }
          buttonText={translate('courierOrdered.buttonText')}
          buttonOnClick={(closeModal: boolean) => {
            setRefresh(closeModal);
            setShowReceiveModal(false);
          }}
        />
      </Modal>

      <Modal showModal={showMobileBoxes} setShowModal={setShowMobileBoxes}>
        <div className="box-container">
          <HidingPlaceElementHeader
            stocks={stocks}
            headerpayload={headerPayload}
            selectedBox={selectedBox}
            selectedAllBoxLength={selectedAllBoxLength}
            amortizationTransportPrice={amortizationTransportPrice}
          />

          {checkAreAvailableBoxes(stocks) && (
            <div className="mb-3 selectAll">
              <input
                onChange={toggleSelectedAll}
                id={'select-all-' + order.id}
                className="custom-checkbox mr-3"
                type="checkbox"
                checked={selectedAll}
              />
              <label
                className="form-check-label"
                htmlFor={'select-all-' + order.id}
              >
                {translate('hidingPlaceBlock.hidingPlaceElement.checkAllBoxes')}
              </label>
            </div>
          )}
          {stocks.map((box) => (
            <StorageBox
              key={box.id}
              box={box}
              selectedBox={selectedBox}
              onBoxClick={(box) => {
                clickedBox(box);
              }}
              onBtnClick={(box) => {
                setShowMobileBoxes(!showMobileBoxes);
                setDescription(box);
                setShowDescriptionModal(true);
              }}
            />
          ))}
          {getModalActionButton()}
        </div>
      </Modal>
      <>
        <div className="hiding-element_subtitle">
          <HidingPlaceElementHeader
            stocks={stocks}
            headerpayload={headerPayload}
            selectedBox={selectedBox}
            selectedAllBoxLength={selectedAllBoxLength}
            amortizationTransportPrice={amortizationTransportPrice}
          />

          <div className="hiding-element_button-group-mobile">
            {stocks.map((stock, idx) => (
              <img src={boxImage} alt="box" key={idx + 'img'} />
            ))}
            {getCardActionButton()}
            <button
              className="btn btn-dark btn-100"
              onClick={() => setShowModal(!showModal)}
            >
              {translate('hidingPlaceBlock.hidingPlaceElement.seeDetail')}
            </button>
          </div>
        </div>
        {checkAreAvailableBoxes(stocks) && (
          <div className="mb-3 d-none d-md-block ">
            <input
              onChange={toggleSelectedAll}
              id={'select-all-' + order.id}
              className="custom-checkbox mr-3"
              type="checkbox"
              checked={selectedAll}
            />
            <label
              className="form-check-label"
              htmlFor={'select-all-' + order.id}
            >
              {translate('hidingPlaceBlock.hidingPlaceElement.checkAllBoxes')}
            </label>
          </div>
        )}
        {isDesktop && (
          <div className="box-container row">
            {stocks.map((box) => (
              <StorageBox
                key={box.id}
                box={box}
                selectedBox={selectedBox}
                onBoxClick={(box) => clickedBox(box)}
                onBtnClick={(box) => setDescription(box)}
              />
            ))}
          </div>
        )}
      </>
    </>
  );
};
