import React, { useState } from 'react';
import './BillForm.scss';
import FormField from '@component/FormField/FormField';
import P, { H5 } from '@component/Text/Text';
import { User } from '@models/user.model';
import { translate } from '@module/Translate/Translate';
import { editUserPersonalDetailsAction } from '@network/Actions/CurrentUser';

interface Props {
  setShowModal: (value: boolean) => void;
  user: User;
  refreshUser: () => void;
  setSpinner: (value: boolean) => void;
  setError: (value: boolean) => void;
}

const BillForm = ({
  setShowModal,
  user,
  refreshUser,
  setSpinner,
  setError,
}: Props) => {
  const currentPersonalDetails = user.personalDetails;
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    street: '',
    postalCode: '',
    city: '',
    ...currentPersonalDetails,
  });

  const closeModalAndRefresh = () => {
    refreshUser();
    setShowModal(false);
  };

  const handleSubmit = (e: any) => {
    setSpinner(true);
    e.preventDefault();
    editUserPersonalDetailsAction(user.id, user.personalDetails?.id, formData)
      .then(() => {
        closeModalAndRefresh();
      })
      .catch(() => {
        setError(true);
        setShowModal(false);
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const displayCurrentPersonalDetails = () => {
    if (currentPersonalDetails)
      return (
        <div className="bill__data">
          <H5>{`${currentPersonalDetails.firstName} ${currentPersonalDetails.lastName}`}</H5>
          <H5>{`${currentPersonalDetails.street || ''}`} </H5>
          <H5>
            {`${currentPersonalDetails.postalCode || ''} ${
              currentPersonalDetails.city || ''
            }`}{' '}
          </H5>
        </div>
      );
  };

  return (
    <div className="billModal">
      <P className="bill__title">dashboard.billModalDashboard.billData</P>
      <P className="bill__text">dashboard.billModalDashboard.address</P>
      <div>{displayCurrentPersonalDetails()}</div>
      <form onSubmit={handleSubmit}>
        <FormField
          label={translate('dashboard.billModalDashboard.firstName')}
          name={formData.firstName}
          onChange={(e: any) =>
            setFormData({ ...formData, firstName: e.target.value })
          }
        />
        <FormField
          label={translate('dashboard.billModalDashboard.lastName')}
          name={formData.lastName}
          onChange={(e: any) =>
            setFormData({ ...formData, lastName: e.target.value })
          }
        />
        <FormField
          label={translate('dashboard.billModalDashboard.street')}
          name={formData.street}
          onChange={(e: any) =>
            setFormData({ ...formData, street: e.target.value })
          }
        />
        <FormField
          label={translate('dashboard.billModalDashboard.city')}
          name={formData.city}
          onChange={(e: any) =>
            setFormData({ ...formData, city: e.target.value })
          }
        />
        <FormField
          required={true}
          label={translate('dashboard.billModalDashboard.postalCode')}
          name={formData.postalCode}
          onChange={(e: any) =>
            setFormData({ ...formData, postalCode: e.target.value })
          }
          pattern={'^[0-9]{2}-[0-9]{3}$'}
          placeholder={'XX-XXX'}
          title={translate('errors.postalCodeError')}
        />
        <button
          className="bill__button btn mx-auto w-100"
          type="submit"
          disabled={
            !formData.firstName ||
            !formData.lastName ||
            !formData.street ||
            !formData.postalCode ||
            !formData.city
          }
        >
          {translate('dashboard.billModalDashboard.saveButton')}
        </button>
      </form>
    </div>
  );
};

export default BillForm;
