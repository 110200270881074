import React from 'react';
import peanut from '@images/faq/background-peanut.png';
import background from '@images/faq/background-path.png';
import { translate } from '@module/Translate/Translate';
import useWindowHeight from '@view/FAQ/hooks/useWindowHeight';
import './Layout.scss';

type layoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: layoutProps): JSX.Element => {
  const { height } = useWindowHeight();
  const howManyPeanuts = Math.ceil(height / 120);
  return (
    <div
      className="faq-layout container"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'repeat-y',
      }}
    >
      {[...Array(howManyPeanuts)].map((number, index) => (
        <div
          key={index}
          className={index % 2 === 0 ? 'faq-layout_even' : 'faq-layout_odd'}
        >
          <img
            src={peanut}
            alt={translate('faq.lauout.alt2')}
            className="peanut"
            style={{
              paddingTop: index * (Math.floor(Math.random() * 90) + 300) + 'px',
              paddingRight:
                index % 2 === 0
                  ? 0
                  : Math.floor(Math.random() * 100) + 50 + 'px',
              paddingLeft:
                index % 2 === 0
                  ? Math.floor(Math.random() * 100) + 50 + 'px'
                  : 0,
            }}
          />
        </div>
      ))}

      {children}
    </div>
  );
};

export default Layout;
