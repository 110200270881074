import React, { useState } from 'react';
import './Footer.scss';
import { useFormik } from 'formik';
import * as Helpers from '@view/RegistrationStepper/helpers/helpers';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Modal from '@component/Modal/Modal';
import Spinner from '@component/Spinner/Spinner';
import SquirrelButton from '@component/SquirrelButton/SquirrelButton';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import { signUpToNewsletterAction } from '@network/Actions/Newsletter';
import { P, H1, H3, H5, H2 } from '@component/Text/Text';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import { useSelector } from 'react-redux';
import { showAppleStoreLink, showGooglePlayLink } from './LinkToAppStore';

const Footer = ({ customFooter = false }: any) => {
  const [initialValues] = useState(Helpers.getFormData());
  const [signedToNewsLetter, setSignedToNewsLetter] = useState(false);
  const [showSquirrelButton, setShowSquirrelButton] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [visibleSpinner, setVisibleSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const { user } = useSelector<RootState, UserState>((state) => state.user);

  const formik = useFormik({
    initialValues: {
      newsletterConfirmed: initialValues.newsletterConfirmed ?? '',
      email: initialValues.email ?? '',
    },
    validationSchema: Yup.object({
      newsletterConfirmed: Yup.boolean()
        .oneOf([true], translate('errors.acceptRules'))
        .required(translate('errors.fieldRequired')),
      email: Yup.string()
        .email(translate('errors.incorrectEmail'))
        .required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values) => {
      Object.assign({}, initialValues, values);
      setVisibleSpinner(true);
      signUpToNewsletterAction(values.email)
        .then(() => {
          setSignedToNewsLetter(true);
        })
        .catch(() => setShowError(true))
        .finally(() => setVisibleSpinner(false));
    },
  });

  const [showСontactModal, setShowСontactModal] = useState(false);

  const [newsletterOpened, setNewsletterOpened] = useState(false);
  const [policyOpened, setPolicyOpened] = useState(false);

  return (
    <>
      {visibleSpinner && <Spinner />}
      <Modal showModal={showСontactModal} setShowModal={setShowСontactModal}>
        <div className="contact-modal-wrapper">
          <h2>{translate('footer.contactForm.contact')}</h2>
          <div className="contact-modal-wrapper_description">
            <P>footer.contactForm.name</P>
            <span>{translate('footer.contactForm.nameValue')}</span>
          </div>
          <div className="contact-modal-wrapper_description">
            <P>footer.contactForm.nip</P>
            <span>{translate('footer.contactForm.nipValue')}</span>
          </div>
          <div className="contact-modal-wrapper_description">
            <P>footer.contactForm.regon</P>
            <span>{translate('footer.contactForm.regonValue')}</span>
          </div>
          <div className="contact-modal-wrapper_description">
            <P>footer.contactForm.krs</P>
            <span>{translate('footer.contactForm.krsValue')}</span>
          </div>
          <div className="contact-modal-wrapper_description">
            <P>footer.contactForm.officeAdress</P>
            <span>{translate('footer.contactForm.officeAdressValue')}</span>
          </div>
          <div className="contact-modal-wrapper_description">
            <P>footer.contactForm.phone</P>
            <span>{translate('footer.contactForm.phoneValue')}</span>
          </div>
          <div className="contact-modal-wrapper_description">
            <P>footer.contactForm.email</P>
            <span>{translate('footer.contactForm.emailValue')}</span>
          </div>
        </div>
      </Modal>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={
            user.id
              ? translate('errors.badRequest')
              : translate('errors.orderCannotCreate')
          }
          activeContactLink={user.id ? true : false}
        />
      </Modal>
      <div className={`footer ${customFooter ? 'footer--custom' : ''}`}>
        <SquirrelButton
          isVisible={showSquirrelButton}
          modalToShow={showContactForm}
          setSpinner={(value) => setVisibleSpinner(value)}
          showError={() => setShowError(!showError)}
        />
        <div className="footer_container">
          <H1 className="footer_title">
            footer.haveQuestion
            <br />
            footer.contactUs
          </H1>
          <div className="footer_content">
            <div className="footer_registration col-xl-5">
              <form className="footer-form" onSubmit={formik.handleSubmit}>
                <div className="footer_send-us d-block d-xl-none">
                  <H3 className="footer_newsletter-link">footer.signUp</H3>
                  <P className="footer_send-us-subtitle text-center mb-0">
                    footer.needHelp
                  </P>
                  <H5 className="footer_send-us-subtitle bold text-center">
                    <a
                      className="pb-2 showMorcik"
                      title="Pokaż Morcika"
                      onClick={() => {
                        setShowSquirrelButton(false);
                        setShowContactForm(!showContactForm);
                      }}
                    >
                      {translate('footer.fillForm')}
                    </a>
                  </H5>
                </div>

                <div className="footer_call-us d-block d-xl-none">
                  <H3 className="footer_newsletter-link">footer.call</H3>
                  <a
                    href={`tel:${translate('footer.contactNumber')}`}
                    className="footer_call-us-subtitle text-center"
                  >
                    <P className="contact_tell">footer.contactNumber</P>
                  </a>
                </div>

                <H3 className="footer_newsletter-link">footer.newsletter</H3>

                <div className="registration-form-license form-group">
                  <div className="footer_newsletter">
                    {signedToNewsLetter ? (
                      <div className="title-newsletter-signed">
                        {translate('footer.signedToNewsletter')}
                      </div>
                    ) : (
                      <>
                        <label
                          htmlFor="email"
                          className={`d-none d-sm-block font-weight-bold footer-form_label ${
                            formik.touched.email && formik.errors.email
                              ? 'error-label'
                              : ''
                          }`}
                        >
                          {translate('footer.email')}
                        </label>
                        <input
                          type="email"
                          name="email"
                          className={`custom-input ${
                            formik.touched.email && formik.errors.email
                              ? 'error-input'
                              : ''
                          }`}
                          id="newsletter_email"
                          autoComplete="off"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error-feedback">
                            {`${formik.errors.email}`}
                          </div>
                        ) : null}

                        <div className="d-flex">
                          <input
                            className="custom-checkbox"
                            type="checkbox"
                            id="newsletterConfirmed"
                            name="newsletterConfirmed"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.newsletterConfirmed}
                            checked={formik.values.newsletterConfirmed}
                          />
                          <label
                            className="newsletter-accept_label"
                            htmlFor="newsletterConfirmed"
                          ></label>
                          <div className="newsletter-accept">
                            <p className="newsletter-accept_title">
                              {translate('footer.accept')}
                              <span
                                className="title-accent"
                                onClick={() =>
                                  setNewsletterOpened(!newsletterOpened)
                                }
                              >
                                {newsletterOpened
                                  ? translate('footer.btnLess')
                                  : translate('footer.btnMore')}
                              </span>
                            </p>
                          </div>
                        </div>

                        {newsletterOpened && (
                          <P className="newsletter-accept_text">
                            footer.acceptText
                          </P>
                        )}
                        {formik.touched.newsletterConfirmed &&
                        formik.errors.newsletterConfirmed ? (
                          <div className="error-feedback pb-2">
                            {`${formik.errors.newsletterConfirmed}`}
                          </div>
                        ) : null}
                        <button
                          type="submit"
                          className="footer_submit-button btn btn-100"
                        >
                          {translate('newsletter.submit')}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
              <div className="newsletter-policy">
                <P className="newsletter-policy_title">
                  {translate('footer.adminDataTitle')}&nbsp;
                  <span
                    className="title-accent"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setPolicyOpened(!policyOpened);
                    }}
                  >
                    {policyOpened
                      ? translate('footer.btnLess')
                      : translate('footer.btnMore')}
                  </span>
                </P>
                {policyOpened && (
                  <div>
                    <P className="newsletter-policy_text">
                      footer.adminDataTextFirst
                    </P>
                    <br />
                    <P className="newsletter-policy_text">
                      footer.adminDataTextSecond
                    </P>
                    <P className="newsletter-policy_text">
                      {translate('footer.policyViewMore')}&nbsp;
                      <Link
                        className="newsletter-policy_link"
                        to="/polityka-prywatnosci"
                        data-e2e="privacy-policy"
                      >
                        <span>{translate('footer.policyPrivacy')}</span>
                      </Link>
                    </P>
                  </div>
                )}
              </div>
            </div>
            <div className="footer_contact col-3 d-none d-xl-block">
              <div className="contact-title">
                <a
                  className="pb-2 showMorcik contact-text"
                  title="Polaż Morcika"
                  onClick={() => {
                    setShowSquirrelButton(false);
                    setShowContactForm(!showContactForm);
                  }}
                >
                  {translate('footer.talkMorcik')}
                </a>
                <P className="contact_subtitle">footer.writeMorcik</P>
              </div>
              <div>
                <H2 className="pb-2 mt-3 contact-text">footer.call</H2>
                <a href={`tel:${translate('footer.contactNumber')}`}>
                  <P className="contact_tell">footer.contactNumber</P>
                </a>
              </div>
            </div>

            <div className="footer_socials_line d-lg-none" />

            <div className="footer_social col-xl-4">
              <div className="footer_social-wrapper">
                <div className="footer-contact_container d-none d-xl-block">
                  <Link to="/sklep" data-e2e="shop">
                    <P>footer.store</P>
                  </Link>
                  <Link to="/wynajem" data-e2e="rent">
                    <P>footer.rent</P>
                  </Link>
                  <Link to="/przechowaj" data-e2e="how-it-works">
                    <P>footer.storage</P>
                  </Link>
                  <Link to="/przeprowadz" data-e2e="move">
                    <P>footer.move</P>
                  </Link>
                </div>

                <div className="footer-policy_container">
                  <span onClick={() => setShowСontactModal(true)}>
                    <P className="privacy-policy_text contact">
                      {translate('footer.contact')}
                    </P>
                  </span>
                  <Link to="/polityka-prywatnosci" data-e2e="privacy-policy">
                    <P className="privacy-policy_text">footer.privacyPolicy</P>
                  </Link>
                  <Link to="/regulamin" data-e2e="website-regulations">
                    <P className="website-regulations_text">
                      footer.usingWebsite
                    </P>
                  </Link>
                </div>
              </div>

              <div className="footer_line" />

              <div className="footer-social_container">
                <div className="d-xl-none">
                  <Link to="/">{translate('footer.webSite')}</Link>
                </div>
                <div>
                  <SocialLinks />
                </div>
              </div>
            </div>
          </div>
          <div className="footer_subtitle d-none d-xl-block">
            <P>footer.subtitle</P>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

const SocialLinks = () => {
  return (
    <div className="socialLinks_container">
      <a
        data-e2e="istagram"
        href="https://www.instagram.com/skitraj.pl/"
        target="instagram"
      >
        <div className="socialLinks_instagram">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 38 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.138 15.6709C17.0347 15.6709 15.3521 17.3536 15.3521 19.4569C15.3521 21.5602 17.0347 23.2429 19.138 23.2429C21.2413 23.2429 22.924 21.5602 22.924 19.4569C22.924 17.3536 21.2413 15.6709 19.138 15.6709Z"
              fill="#E78004"
            />
            <path
              d="M23.905 9.57129H14.3699C11.6356 9.57129 9.39209 11.8148 9.39209 14.5491V24.0842C9.39209 26.8185 11.6356 29.0621 14.3699 29.0621H23.905C26.6393 29.0621 28.8829 26.8185 28.8829 24.0842V14.5491C28.8128 11.8148 26.6393 9.57129 23.905 9.57129ZM19.1375 25.3462C15.9124 25.3462 13.2482 22.682 13.2482 19.4569C13.2482 16.2318 15.9124 13.5676 19.1375 13.5676C22.3626 13.5676 25.0268 16.2318 25.0268 19.4569C25.0969 22.7521 22.4327 25.3462 19.1375 25.3462ZM25.4474 14.5491C24.6762 14.5491 24.0452 13.9182 24.0452 13.1469C24.0452 12.3757 24.6762 11.7447 25.4474 11.7447C26.2187 11.7447 26.8497 12.3757 26.8497 13.1469C26.8497 13.9182 26.2187 14.5491 25.4474 14.5491Z"
              fill="#E78004"
            />
            <path
              d="M19 0.457031C8.55351 0.457031 0 9.01054 0 19.457C0 29.9736 8.55351 38.457 19 38.457C29.4465 38.457 38 29.9035 38 19.457C38 9.01054 29.5166 0.457031 19 0.457031ZM31.059 24.0843C31.059 28.0806 27.8339 31.3057 23.8376 31.3057H14.3727C10.3764 31.3057 7.15129 28.0806 7.15129 24.0843V14.5493C7.15129 10.553 10.3764 7.32788 14.3727 7.32788H23.9077C27.9041 7.32788 31.1292 10.553 31.1292 14.5493V24.0843H31.059Z"
              fill="#E78004"
            />
          </svg>
        </div>
      </a>

      <a
        data-e2e="facebook"
        href="https://www.facebook.com/skitrajpl"
        target="facebook"
      >
        <div className="socialLinks_facebook">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 39 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1044_5602)">
              <path
                d="M19.5977 0.457031C9.10491 0.457031 0.597656 8.72122 0.597656 18.9142C0.597656 29.1071 9.10491 37.3713 19.5977 37.3713C30.0904 37.3713 38.5977 29.1071 38.5977 18.9142C38.5977 8.72122 30.0904 0.457031 19.5977 0.457031ZM24.3477 12.7618C24.3477 12.7618 22.8973 12.7618 22.2102 12.7618C21.3583 12.7618 21.181 13.1017 21.181 13.9584C21.181 14.6721 21.181 15.838 21.181 15.838H24.3477L24.0167 18.9142H21.181V29.6808H16.431V18.9142H13.2643V15.838H16.431C16.431 15.838 16.431 14.6583 16.431 12.2881C16.431 9.56717 17.9051 8.14751 21.2269 8.14751C21.77 8.14751 24.3477 8.14751 24.3477 8.14751V12.7618Z"
                fill="#E78004"
              />
            </g>
            <defs>
              <clipPath id="clip0_1044_5602">
                <rect
                  width="38"
                  height="36.9143"
                  fill="white"
                  transform="translate(0.597656 0.457031)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
      </a>

      <a
        data-e2e="youtube"
        href="https://www.youtube.com/channel/UC_XLo1bdzRGVfMIfM-jUSlw"
        target="youtube"
      >
        <div className="socialLinks_youtube">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 40 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 0C9.50725 0 1 8.50725 1 19C1 29.4928 9.50725 38 20 38C30.4928 38 39 29.4928 39 19C39 8.50725 30.4928 0 20 0ZM27.0316 26.7457C23.7034 26.9737 16.2903 26.9737 12.9668 26.7457C9.36317 26.4987 8.94358 24.7348 8.91667 19C8.94358 13.2541 9.36792 11.5013 12.9668 11.2543C16.2903 11.0263 23.705 11.0263 27.0316 11.2543C30.6368 11.5013 31.0548 13.2652 31.0833 19C31.0548 24.7459 30.6321 26.4987 27.0316 26.7457ZM16.8333 15.2918L24.6186 18.9937L16.8333 22.7082V15.2918Z"
              fill="#E78004"
            />
          </svg>
        </div>
      </a>
      <a
        data-e2e="linkedin"
        href="https://www.linkedin.com/company/skitraj-pl"
        target="linkedin"
      >
        <div className="socialLinks_linkedin">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M10,16H8v-6h2V16z M9,9.1
              C8.4,9.1,7.9,8.6,7.9,8c0-0.6,0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1C10.1,8.6,9.6,9.1,9,9.1z M17,16h-2v-2.9c0-1.9-2-1.7-2,0V16h-2v-6h2
              v1.1c0.9-1.6,4-1.7,4,1.5V16z"
              fill="#E78004"
            />
          </svg>
        </div>
      </a>
      {showGooglePlayLink()}
      {showAppleStoreLink()}
    </div>
  );
};
