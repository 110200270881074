import React from 'react';
import './Rent.scss';
import { Link } from 'react-router-dom';
import box from '@images/rent-page/pudelko.png';
import morcik from '@images/rent-page/morcik.png';
import { translate } from '@module/Translate/Translate';
import { H1 } from '@component/Text/Text';

function Rent() {
  return (
    <div className="rent-container">
      <div className="container">
        <img
          src={morcik}
          alt="morcik"
          className="d-block d-xl-none image-morcik-mobile"
        />

        <div className="title-group">
          <H1 className="title-group_title">
            {translate('rentPage.rentBlock.title')}
          </H1>
          <h3 className="title-group_subtitle">
            {translate('rentPage.rentBlock.subtitle')}{' '}
          </h3>
          <Link
            to={{
              pathname: '/zamowienie',
              state: {
                defaultIndex: 1,
              },
            }}
            className="rent-link"
          >
            <button type="button" className="rent-link_btn" data-e2e="rent">
              {translate('rentPage.rentBlock.buttonText')}
            </button>
          </Link>
        </div>

        <img src={box} alt="box" className="box" />
        <img src={morcik} alt="morcik" className="morcik" />
      </div>
    </div>
  );
}

export default Rent;
