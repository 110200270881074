import React, { FunctionComponent } from 'react';
import './OfferCard.scss';
import OfferCardI from './OfferCardI.type';
import { Link } from 'react-router-dom';
import { H3 } from '@component/Text/Text';

export const OfferCard: FunctionComponent<
  React.PropsWithChildren<OfferCardI>
> = (props) => {
  return (
    <>
      <section
        className={`homepage-offer-card  d-lg-block ${
          !props.active && 'd-none'
        }`}
      >
        <div className="d-flex flex-column pt-4 px-0 shadow-lg bg-white rounded-top rounded-bottom x12 h-100 justify-content-between rounded">
          <H3 className="h2 d-flex font-weight-bold py-4 px-4 mb-4 fs44 fw500 offer_subtitle">
            {props.title}
          </H3>

          <div className="d-flex flex-column px-4 h-100">{props.children}</div>

          <Link
            to={{
              pathname: props.link,
              state: { defaultIndex: props.defaultIndex },
            }}
            className="homepage-offer-card_button text-white px-4 py-4 rounded-bottom x12 fs21 fw700"
          >
            {props.text}
          </Link>
        </div>
      </section>
    </>
  );
};

export default OfferCard;
