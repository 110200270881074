import { FindBasketProductDto } from '@rootTypes/products/find-basket-product.dto';

export const setCounterValue = (
  size: string,
  id: number,
  stateStore: FindBasketProductDto[],
) => {
  const box = stateStore.find((item) => item.id === id);
  if (box) return box.value;
  return 0;
};
