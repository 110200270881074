import Spinner from '@component/Spinner/Spinner';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../store';
import { UserState } from '../../Type/Custom';

const PrivateRoute: React.FC<
  React.PropsWithChildren<{
    component: React.FC<React.PropsWithChildren<unknown>>;
    currentPanel?: string;
  }>
> = (props) => {
  const userData = useSelector<RootState, UserState>((state) => state.user);

  if (userData.loading) {
    return <Spinner />;
  }

  if (userData?.user?.id) {
    if (userData?.user?.isPrivacyConfirmed && userData?.user?.isRODOConfirmed) {
      return <props.component {...props} />;
    } else {
      return <Redirect to="/policyConfirm" />;
    }
  } else {
    return <Redirect to="/" />;
  }
};
export default PrivateRoute;
