import { useCallback } from 'react';
import { createMessageData } from './CreateMessageData';

interface IMessageContent {
  text: string;
}

export default function MessageContent({ text }: IMessageContent) {
  const createText = useCallback(() => {
    const messageData = createMessageData(text);
    return messageData.text.map((messagePart: string, counter: number) => {
      if (messageData.startsWithBold) {
        if (counter % 2 !== 1) {
          return <b key={counter}>{messagePart}</b>;
        } else {
          return messagePart;
        }
      } else {
        if (counter % 2 === 1) {
          return <b key={counter}>{messagePart}</b>;
        } else {
          return messagePart;
        }
      }
    });
  }, [text]);

  return <div className="message-item_message">{createText()}</div>;
}
