import React from 'react';
import './Slide.scss';
import defaultBox from '@images/offer/defaultBox.png';
import { DescriptionTypeEnum } from '@rootTypes/product-descriptions/description-type.enum';
import { ImageTypeEnum } from '@rootTypes/product-images/image-type.enum';

const Slide = ({ item }: any) => {
  const image: any = item.images?.find(
    ({ type }: any) => type === ImageTypeEnum.carousel,
  );
  return (
    <>
      <div className="carousel-slide text-center">
        <div className="carousel-image-container">
          <div className="carousel-wrapper">
            <img
              className="mx-auto carousel-image"
              src={image ? image.url : defaultBox}
              alt={item.name}
            />
          </div>
          <p className="mobile slide-title">
            {item.name.split(' ')[0]}
            <span className="text-primary">
              &nbsp;{item.name.split(' ')[1]}
            </span>
          </p>
          <p className="mobile slide-subtitle">
            {
              item.descriptions.find(
                (description: any) =>
                  description.type === DescriptionTypeEnum.offer,
              )?.text
            }
          </p>
        </div>
      </div>
    </>
  );
};

export default Slide;
