import React, { useEffect, useState } from 'react';
import './MyRentalBlock.scss';
import { RentalPlace } from './components/RentalPlace/RentalPlace';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { H2, H5 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { getUserStocksAction } from '@network/Actions/Stocks';
import { OrderStatus } from '@rootTypes/orders/order-status.enum';
import { StockType } from '@rootTypes/stocks/stock-type.enum';
import { RootState } from '@src/store';
import { StocksState } from '@type/Custom';
import { useSelector, useDispatch } from 'react-redux';
import { SERVER_URL } from '@/envConfig';

interface Props {
  userId: number;
  setSpinner: (value: boolean) => void;
}

function MyRentalBlock({ userId, setSpinner }: Props) {
  const [search, setSearch] = useState<string>('');
  const [refresh, setRefresh] = useState(false);
  const changeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const { stocks } = useSelector<RootState, StocksState>(
    (state) => state.stocks,
  );
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    dispatch(getUserStocksAction(userId, StockType.RENT, OrderStatus.A, error));
  }, []);

  useEffect(() => {
    if (refresh) {
      dispatch(
        getUserStocksAction(userId, StockType.RENT, OrderStatus.A, error),
      );
    }
  }, [refresh]);

  const error = () => {
    setShowError(true);
  };
  const formatInvoiceUrl = () => {
    return `${SERVER_URL}/api/utils/additional/instrukcja.pdf`;
  };
  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <div className="rental-container">
        <div className="container-header rental-container-header">
          <div>
            <H2 className="container-title rental-container-header_title">
              myRentalBlock.title
            </H2>
            <H5 className="container-subtitle rental-container-header_subtitle">
              myRentalBlock.subtitle
            </H5>
          </div>
          <div className="rental-container_input-group">
            <label htmlFor="rent_find">
              {translate('myRentalBlock.searchHeader')}
            </label>
            <input
              type="text"
              id="rent_find"
              className="custom-input"
              placeholder={translate('myRentalBlock.inputPlaceholder')}
              value={search}
              onChange={changeEvent}
            />
          </div>
        </div>

        {stocks && stocks.length > 0 && (
          <RentalPlace
            stocks={stocks}
            search={search}
            refresh={setRefresh}
            visibleSpinner={setSpinner}
          />
        )}
      </div>
    </>
  );
}
export default MyRentalBlock;
