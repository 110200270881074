import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import { addProductInBasket } from '@network/Actions/Basket';
import { FindBasketProductDto } from '@rootTypes/products/find-basket-product.dto';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface ITotal {
  total: number;
  toggleState: number;
  rentWeeks: number;
  storageMonths: number;
  stateStore: FindBasketProductDto[];
  stateRent: FindBasketProductDto[];
  ceilTotalRentPrice: number;
  celiTotalKeepPrice: number;
}

export default function DisplayTotal({
  total,
  toggleState,
  rentWeeks,
  storageMonths,
  stateStore,
  stateRent,
  ceilTotalRentPrice,
  celiTotalKeepPrice,
}: ITotal) {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const onHandleSubmit = () => {
    //Storage
    if (toggleState === 1) {
      stateStore
        .filter((item) => item.value > 0)
        .map((item) => dispatch(addProductInBasket({ data: item })));
    }
    //Rent
    if (toggleState === 2) {
      stateRent
        .filter((item) => item.value > 0)
        .map((item) => dispatch(addProductInBasket({ data: item })));
    }
  };

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.serverError')}
          activeContactLink={false}
        />
      </Modal>
      <div className="price-container d-flex flex-column align-items-center justify-content-between">
        <div className="offer__white-box bg-white d-flex flex-column align-items-center w-100 h-100">
          <h2
            className="price-container_title d-flex fs64 fw700 offer__zl fs64"
            data-total="0"
          >
            {total.toFixed(2)} {translate('default.currency')}
          </h2>
          <p
            id="period-label"
            className="pb-0 text-center d-flex flex-column offer__monthly"
            data-period_monthly_label="Miesięcznie"
            data-period_weekly_label="Tygodniowo"
          >
            <span className="price-container_subtitle period-label-placeholder fs18 fs-lg-24 fw500">
              {toggleState === 1
                ? translate('calculator.monthlyForBox')
                : translate('calculator.weeklyForBox')}
            </span>
          </p>
          <div className="week-totalPrice">
            <p className="week-totalPrice_price">
              {toggleState === 1
                ? celiTotalKeepPrice.toFixed(2)
                : ceilTotalRentPrice.toFixed(2)}
              {translate('default.currency')}
            </p>
            <p className="week-totalPrice_text">
              {translate('calculator.weekTotalPruce')}
            </p>
          </div>
        </div>
        <Link
          to={{
            pathname: '/zamowienie',
            state: {
              defaultIndex: toggleState === 1 ? 0 : 1,
              weekQuantity: rentWeeks,
              monthQuantity: storageMonths,
            },
          }}
          onClick={() => onHandleSubmit()}
          className="price-container_btn calculator__action bg-white text-cream fs18 fs-lg-21 fw700 rounded-bottom x12 w-100"
        >
          <b>{translate('calculator.orderAService')}</b>
        </Link>
      </div>
    </>
  );
}
