import { GenerateResetPasswordTokenDto } from '@rootTypes/auth/generate-reset-password-token.dto';
import { ResetPasswordDto } from '@rootTypes/auth/reset-password.dto';
import axios from 'axios';
import { http } from '../NetworkHTTP';

export async function resetPasswordAction(
  email: GenerateResetPasswordTokenDto,
  onError: (error: any) => void,
  onSucces: () => void,
) {
  return await http
    .post(`auth/reset-password`, email)
    .then((res) => {
      if (res.status === 200) onSucces();
    })
    .catch((error) => onError(error));
}

export async function setNewPasswordAction(
  token: string,
  resetPasswordDTO: ResetPasswordDto,
  onError: (error: any) => void,
  onTokenExpired: () => void,
  onSucces: () => void,
) {
  return await http
    .post(`auth/reset-password/${token}/`, resetPasswordDTO)
    .then((res) => {
      if (res.status === 200) onSucces();
    })
    .catch((error: any) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) return onTokenExpired();
        return onError(error.response?.data);
      }
      return onError(error?.response?.data?.response?.error);
    });
}
