import React, { ComponentType } from 'react';

export interface ShowIfProps {
  show?: boolean;
}

const ShowIfHOC = <T extends ShowIfProps>(
  Component: ComponentType<React.PropsWithChildren<T>>,
) =>
  function ShowIfHOC(props: T): null | React.ReactElement {
    return Object.prototype.hasOwnProperty.call(props, 'show') &&
      props.show === false ? null : (
      <Component {...props} />
    );
  };
export default ShowIfHOC;
