import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthenticationStep from './components/AuthenticationStep/AuthenticationStep';
import AddressStep from './components/AddressStep/AddressStep';
import PaymentStep from './components/PaymentStep/PaymentStep';
import SummaryStep from './components/SummaryStep/SummaryStep';
import CompletedStep from './components/CompletedStep/CompletedStep';
import ConfirmEmailStep from './components/ConfirmEmailStep/ConfirmEmailStep';
import VerifyEmail from './components/ForgotPassword/VerifyEmail/VerifyEmail';
import './RegistrationStepper.scss';
import RegistrationStepperI from './RegistrationStepper.type';
import * as Helpers from './helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import MorcikLazy from '@images/registration/morcik_lazy.png';
import MainTemplate from '@component/MainTemplate/MainTemplate';
import Modal from '@component/Modal/Modal';
import RegistrationProgress from '@component/RegistrationProgress/RegistrationProgress';
import Spinner from '@component/Spinner/Spinner';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import ConfirmNumberForm from '@form/ConfirmNumberForm/ConfirmNumberForm';
import { translate } from '@module/Translate/Translate';
import { deleteOrderAction } from '@network/Actions/CurrentOrder';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import { Helmet } from 'react-helmet';

const RegistrationStepper: FunctionComponent<
  React.PropsWithChildren<RegistrationStepperI>
> = ({
  priorityStep,
  authenticationType = 'register',
  hideStepper = false,
}) => {
  const { state = { activeStep: 0 } }: any = useLocation();
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const user = Object.keys(userData.user);
  const calculatingStep = priorityStep ? priorityStep : state.activeStep;
  const [showError, setShowError] = useState(false);
  const [isNipError, setIsNipError] = useState(false);
  const [visibleSpinner, setVisibleSpinner] = useState(false);

  const showStepper =
    calculatingStep > 1 && calculatingStep <= 6 && !hideStepper;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(deleteOrderAction());
      Helpers.ClearFormData();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state]);

  const renderStep = function () {
    switch (
      calculatingStep !== 10
        ? (user.length === 0 || !userData.user.isActive) &&
          calculatingStep !== 7 &&
          calculatingStep !== 8
          ? 0
          : calculatingStep
        : calculatingStep
    ) {
      case 0:
        return <AuthenticationStep authenticationType={authenticationType} />;
      case 1:
      case 2:
      case 3:
        return (
          <AddressStep
            showError={(bool: boolean) => setShowError(bool)}
            visibleSpinner={(bool: boolean) => setVisibleSpinner(bool)}
          />
        );
      case 4:
        return (
          <PaymentStep
            showError={(bool: boolean) => setShowError(bool)}
            visibleSpinner={(bool: boolean) => setVisibleSpinner(bool)}
            setIsNipError={setIsNipError}
          />
        );
      case 5:
        return (
          <SummaryStep
            showError={(bool: boolean) => setShowError(bool)}
            visibleSpinner={(bool: boolean) => setVisibleSpinner(bool)}
          />
        );
      case 6:
        return <CompletedStep />;
      case 7:
        return (
          <ConfirmEmailStep
            showError={(bool: boolean) => setShowError(bool)}
            visibleSpinner={(bool: boolean) => setVisibleSpinner(bool)}
          />
        );
      case 8:
        return <VerifyEmail verify={undefined} />;
      case 9:
        return (
          <ConfirmNumberForm
            nextStep={4}
            showError={(bool: boolean) => setShowError(bool)}
            visibleSpinner={(bool: boolean) => setVisibleSpinner(bool)}
          />
        );
      case 10:
        return <CompletedStep />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Skitraj.pl | {window.location.pathname.substring(1)} </title>
        <meta name="robots" content="noindex,nofollow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MainTemplate customMobile={true}>
        <Modal showModal={showError} setShowModal={setShowError}>
          <AdminContactForm
            setShowConfirm={setShowError}
            message={
              isNipError
                ? translate('errors.badNIP')
                : translate('errors.badRequest')
            }
          />
        </Modal>
        {visibleSpinner && <Spinner />}
        <article className="registration-stepper no-gutters row">
          <div className="container registration-stepper_container">
            <div className="no-gutters row">
              {!userData.user.isActive ? (
                <h1 className="registration-stepper_title">
                  {translate('registrationStepper.title')}
                </h1>
              ) : (
                <h1 className="registration-stepper_title">
                  {translate('registrationStepper.titleOrder')}
                </h1>
              )}
            </div>

            <div
              className={`registration-stepper_content ${
                state.activeStep === 5 && 'summary-stepper'
              }`}
            >
              {showStepper ? (
                <RegistrationProgress
                  activeStep={user.length === 0 ? 0 : state.activeStep}
                />
              ) : (
                <div className="d-none d-md-block mt-5 mb-3" />
              )}
              {renderStep()}
              {state.activeStep === 5 && (
                <img alt="morcik" className="background_img" src={MorcikLazy} />
              )}
            </div>
          </div>
        </article>
      </MainTemplate>
    </>
  );
};

export default RegistrationStepper;
