import './ContactWithoutLoginForm.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { H1, P } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { sendEmail } from '@network/Actions/Email';
import React, { useState } from 'react';
import Logo from '@images/customer/landing/logo_skitraj.svg';
import { morcikEvent } from '@src/GTM/GTM';

export default function ContactWithoutLoginForm({
  setShowModal,
  setShowConfirm,
  setSpinner,
  showConfirm,
}: any) {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(translate('errors.fieldRequired')),
      email: Yup.string()
        .email(translate('errors.incorrectEmail'))
        .required(translate('errors.fieldRequired')),
      message: Yup.string()
        .min(10, translate('errors.leastCharactersText'))
        .required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values) => {
      setShowModal(false);
      setSpinner(true);
      sendEmail({
        email: values.email,
        subject: 'Zadaj mi pytanie',
        message: {
          name: values.firstName,
          email: values.email,
          messageDetails: values.message,
        },
      })
        .then(() => {
          morcikEvent();
          setShowConfirm(true);
        })
        .catch(() => {
          showConfirm();
        })
        .finally(() => setSpinner(false));
    },
  });

  const [showMoreInfo, setShowMoreInfo] = useState(false);

  return (
    <form className="contact-without-login-form" onSubmit={formik.handleSubmit}>
      <img className="brand-img d-block d-md-none" src={Logo} alt="Skitraj" />

      <H1 className="contact-without-login_title form-group">
        contactWithoutLoginForm.askMe
      </H1>
      <P className="form-group text">contactWithoutLoginForm.otherQuestions</P>

      <div className="text-left form-group">
        <label
          htmlFor="first_name"
          className="font-weight-bold contact-without-login_label"
        >
          <span
            className={`visible-for-individual ${
              formik.touched.firstName && formik.errors.firstName
                ? 'error-label'
                : ''
            }`}
          >
            {translate('contactWithoutLoginForm.fullName')}
          </span>
        </label>
        <input
          type="text"
          name="firstName"
          data-e2e="firstName"
          className={`custom-input ${
            formik.touched.firstName && formik.errors.firstName
              ? 'error-input'
              : ''
          }`}
          id="firstName"
          autoComplete="off"
          placeholder={translate('contactWithoutLoginForm.fullName')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
        />
        {formik.touched.firstName && formik.errors.firstName ? (
          <div className="error-feedback">{formik.errors.firstName}</div>
        ) : null}
      </div>

      <div className="text-left form-group">
        <label
          htmlFor="email"
          className={`font-weight-bold registration-form_label ${
            formik.touched.email && formik.errors.email ? 'error-label' : ''
          }`}
        >
          {translate('contactWithoutLoginForm.email')}
        </label>
        <input
          type="email"
          name="email"
          data-e2e="email"
          className={`custom-input ${
            formik.touched.email && formik.errors.email ? 'error-input' : ''
          }`}
          placeholder={translate('contactWithoutLoginForm.email')}
          id="email"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error-feedback">{formik.errors.email}</div>
        ) : null}
      </div>

      <div className="text-left form-group contact-without-login_textarea-container">
        <label
          htmlFor="message"
          className={`font-weight-bold registration-form_label ${
            formik.touched.message && formik.errors.message ? 'error-label' : ''
          }`}
        >
          {translate('contactWithoutLoginForm.message')}
        </label>
        <textarea
          name="message"
          data-e2e="message"
          className={`contact-without-login_textarea form-control ${
            formik.touched.message && formik.errors.message ? 'error-input' : ''
          }`}
          id="message"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
        />
        {formik.touched.message && formik.errors.message ? (
          <div className="error-feedback">{formik.errors.message}</div>
        ) : null}
      </div>

      <div className="contact-without-login_info">
        <p className="contact-without-login_info-title">
          {translate('contactWithoutLoginForm.dataAdministrator')}
          <span
            className="title-accent contact-without-login_info-interactive"
            onClick={() => setShowMoreInfo(!showMoreInfo)}
          >
            {showMoreInfo
              ? translate('contactWithoutLoginForm.btnLess')
              : translate('contactWithoutLoginForm.btnMore')}
          </span>
        </p>
      </div>

      {showMoreInfo && (
        <p className="contact-without-login_info-text">
          {translate('contactWithoutLoginForm.dataAdministratorInfo')}
        </p>
      )}

      <div>
        <div>
          <button
            type="submit"
            data-e2e="sendMessage"
            className="btn mx-auto w-100 contact-form_button"
            disabled={!(formik.isValid && formik.dirty)}
          >
            {translate('contactWithoutLoginForm.sentMessage')}
          </button>
        </div>
      </div>
    </form>
  );
}
