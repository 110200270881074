import { H2 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

export default function CalculationBlockHeader() {
  return (
    <div className="calculator_wrapper text-center text-lg-left">
      <H2 className="calculator-container_title fw700 offer__header">
        {translate('calculator.howMuch')}
      </H2>
      <p className="calculator-container_subtitle fs16 fs-lg-28 fw400">
        {translate('calculator.selectTimeAndTypeOfService')}
      </p>
    </div>
  );
}
