import { useState, useEffect } from 'react';
import './RegistrationForm.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PasswordValidator } from '@rootTypes/auth/password-validator';
import * as Helpers from '@view/RegistrationStepper/helpers/helpers';
import { translate } from '@module/Translate/Translate';
import { isEmpty } from '@util/isEmpty';
import P from '@component/Text/Text';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID } from '@/envConfig';
import UserTypeSwitch from './components/UserTypeSwitch';
import UserNameInpt from './components/UserNameInpt';
import UserSurnameInput from './components/UserSurnameInput';
import MailInput from './components/MailInput';
import PasswordRegisterInput from './components/PasswordRegisterInput';
import ConfirmPrivaces from './components/ConfirmPrivaces';
import RegisterUserSocial from './components/RegisterUserSocial';
import PrivacyRegister from './components/PrivacyRegister';
import PasswordConfirmRegisterInput from './components/PasswordConfirmRegisterInput';

const clientId: string | any = GOOGLE_CLIENT_ID;

export default function RegistrationForm({ onSubmit, validation }: any) {
  const [initialValues] = useState(Helpers.getFormData());
  const passwordValidator = new PasswordValidator();
  const [policyOpened, setPolicyOpened] = useState(false);
  const [userType, setUserType] = useState('individual');

  const formik = useFormik({
    initialValues: {
      firstName: initialValues.firstName ?? '',
      lastName: initialValues.lastName ?? '',
      email: initialValues.email ?? '',
      name: initialValues.name ?? '',
      password: initialValues.password ?? '',
      repassword: initialValues.repassword ?? '',
      isPrivacyConfirmed: initialValues.isPrivacyConfirmed ?? false,
      isRODOConfirmed: initialValues.isRODOConfirmed ?? false,
      isEmailsConfirmed: initialValues.isEmailsConfirmed ?? false,
      isNewsletterConfirmed: initialValues.isNewsletterConfirmed ?? false,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .test('', translate(`errors.invalidPasswordDetail`), function (value) {
          return value ? !passwordValidator.validatePassword(value) : false;
        })
        .required(translate('errors.fieldRequired')),
      repassword: Yup.string()
        .test('', translate('errors.passwordNotMatch'), function (value) {
          return this.parent.password === value;
        })
        .required(translate('errors.fieldRequired')),
      email: Yup.string()
        .email(translate('errors.incorrectEmail'))
        .required(translate('errors.fieldRequired')),
      isPrivacyConfirmed: Yup.boolean()
        .oneOf([true], translate('errors.acceptRules'))
        .required(translate('errors.fieldRequired')),
      isRODOConfirmed: Yup.boolean()
        .oneOf([true], translate('errors.acceptRules'))
        .required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values: any, actions) => {
      const result = Object.assign({}, initialValues, values);
      result.isCompanyAccount = userType !== 'individual';
      onSubmit(result, actions);
    },
  });

  useEffect(() => {
    if (validation.message && validation.message.field === 'password') {
      formik.setFieldError(
        'password',
        translate(`errors.${validation.message}`),
      );
    }
  }, [validation]);

  const passwordError = (function () {
    if (formik.touched.password && formik.errors.password) {
      return (
        <div className="error-feedback">{`${formik.errors.password}`}</div>
      );
    } else if (formik.touched.repassword && formik.errors.repassword) {
      return (
        <div className="error-feedback">{`${formik.errors.repassword}`}</div>
      );
    }
    return null;
  })();

  function allFieldsIsFull() {
    if (userType === 'individual') {
      delete formik.values.name;
      return isEmpty(formik.values);
    } else {
      delete formik.values.firstName;
      delete formik.values.lastName;
      return isEmpty(formik.values, 'restOfName');
    }
  }

  useEffect(() => {
    if (userType === 'individual') {
      formik.values.firstName = '';
      formik.values.lastName = '';
      formik.registerField('firstName', {
        validate(value: string) {
          if (!value) {
            return translate('errors.fieldRequired');
          }
          return '';
        },
      });
      formik.registerField('lastName', {
        validate(value: string) {
          if (!value) {
            return translate('errors.fieldRequired');
          }
          return '';
        },
      });
      formik.unregisterField('name');
    } else {
      formik.values.name = '';
      formik.unregisterField('firstName');
      formik.unregisterField('lastName');
      formik.registerField('name', {
        validate(value: string) {
          if (!value) {
            return translate('errors.fieldRequired');
          }
          return '';
        },
      });
    }
    if (validation && validation.field) {
      formik.setFieldError(
        validation.field,
        translate(`errors.${validation.message}`),
      );
    }
  }, [userType, validation]);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <form
        className="registration-form"
        id="registration-form"
        name="registration-form"
        onSubmit={formik.handleSubmit}
      >
        <UserTypeSwitch userType={userType} setUserType={setUserType} />
        <UserNameInpt formik={formik} userType={userType} />
        <UserSurnameInput formik={formik} userType={userType} />
        <MailInput formik={formik} />
        <PasswordRegisterInput formik={formik} passwordError={passwordError} />
        <PasswordConfirmRegisterInput
          formik={formik}
          passwordError={passwordError}
        />
        <ConfirmPrivaces formik={formik} />
        <div className="mb-lg-3">
          <button
            disabled={!(formik.isValid || allFieldsIsFull())}
            type="submit"
            className="btn mt-3 btn-100"
          >
            {translate('registrationUser.registrationPanel.register')}
          </button>
        </div>
        {/* {Socjale} */}
        {userType === 'individual' && (
          <RegisterUserSocial
            isRODOConfirmed={formik.values.isRODOConfirmed}
            isPrivacyConfirmed={formik.values.isPrivacyConfirmed}
          />
        )}
        {/* Polityka prywatności */}
        <div>
          <P className="registration-form_policy-title">
            {translate('footer.adminDataTitle')}&nbsp;
            <span onClick={() => setPolicyOpened(!policyOpened)}>
              {policyOpened
                ? translate('footer.btnLess')
                : translate('footer.btnMore')}
            </span>
          </P>
          {policyOpened && <PrivacyRegister />}
        </div>
      </form>
    </GoogleOAuthProvider>
  );
}
