import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import {
  DEFAULT_SETTINGS_FULFILLED,
  DEFAULT_SETTINGS_PENDING,
  DEFAULT_SETTINGS_REJECTED,
} from './Types';
import { http } from '../NetworkHTTP';

export async function editDefaultSettingsAction(
  id: number,
  defaultSettings: any,
) {
  return await http.patch(`default-settings/${id}`, defaultSettings);
}

export const getDefaultSettingsAction = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: DEFAULT_SETTINGS_PENDING,
      });
      const { data } = await http.get(`default-settings`);
      dispatch({
        type: DEFAULT_SETTINGS_FULFILLED,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: DEFAULT_SETTINGS_REJECTED,
        payload: 'Nie udało się pobrać domyślnych ustawień.',
      });
    }
  };
};
