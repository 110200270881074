import TextWithLink from '@component/testWithLink/TextWithLink';
import P from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import React, { useState } from 'react';

export default function ConfirmPrivaces({ formik }: { formik: any }) {
  const [newsletterOpened, setNewsletterOpened] = useState(false);

  return (
    <div className="registration-form-license mb-lg-3">
      <div className="col-12 register-reminder-text px-0">
        <ul className="list-unstyled pt-3">
          <li
            className="form-check d-flex flex-column pt-2 pl-0"
            data-e2e="service-policy-checkbox"
          >
            <input
              className="custom-checkbox form-check-input"
              type="checkbox"
              id="isPrivacyConfirmed"
              name="isPrivacyConfirmed"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.isPrivacyConfirmed}
              checked={formik.values.isPrivacyConfirmed}
            />
            <label htmlFor="isPrivacyConfirmed">
              <div className="d-flex flex-line required-checkbox">
                <span>*</span>
                <TextWithLink
                  className="registration-form_label fs18"
                  regulationText={translate('regulations.serwisPolicy.text')}
                  linkName={translate('regulations.serwisPolicy.link')}
                  linkTo={'/regulamin'}
                  dataE2E={'website-regulations'}
                />
              </div>
            </label>
          </li>
          {formik.touched.isPrivacyConfirmed &&
          formik.errors.isPrivacyConfirmed ? (
            <div className="error-feedback pb-2">
              {`${formik.errors.isPrivacyConfirmed}`}
            </div>
          ) : null}
          <li
            className="form-check d-flex flex-column pt-2 pl-0"
            data-e2e="privacy-policy-checkbox"
          >
            <input
              className="custom-checkbox form-check-input"
              type="checkbox"
              id="isRODOConfirmed"
              name="isRODOConfirmed"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.isRODOConfirmed}
              checked={formik.values.isRODOConfirmed}
            />
            <label htmlFor="isRODOConfirmed">
              <div className="d-flex flex-line required-checkbox">
                <span>*</span>
                <TextWithLink
                  className="registration-form_label fs18"
                  regulationText={translate('regulations.privacyPolicy.text')}
                  linkName={translate('regulations.privacyPolicy.link')}
                  linkTo={'/polityka-prywatnosci'}
                  dataE2E={'privacy-policy'}
                />
              </div>
            </label>
          </li>
          {formik.touched.isRODOConfirmed && formik.errors.isRODOConfirmed ? (
            <div className="error-feedback pb-2">
              {`${formik.errors.isRODOConfirmed}`}
            </div>
          ) : null}
          <li
            className="form-check d-flex flex-column pt-2 pl-0"
            data-e2e="isNewsletterConfirmed"
          >
            <input
              className="custom-checkbox form-check-input"
              type="checkbox"
              id="isNewsletterConfirmed"
              name="isNewsletterConfirmed"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.isNewsletterConfirmed}
              checked={formik.values.isNewsletterConfirmed}
            />
            <label htmlFor="isNewsletterConfirmed">
              <P className="newsletter_label-title">
                {translate('footer.accept')}
                <span onClick={() => setNewsletterOpened(!newsletterOpened)}>
                  {newsletterOpened
                    ? translate('footer.btnLess')
                    : translate('footer.btnMore')}
                </span>
              </P>
            </label>

            {newsletterOpened && (
              <P className="newsletter_label-text">
                {translate('footer.acceptText')}
              </P>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}
