import { useEffect, useState } from 'react';
import Spinner from '@component/Spinner/Spinner';
import { translate } from '@module/Translate/Translate';
import { getAddressAction } from '@network/Actions/CurrentUser';
import { formatDateToLocalTime } from '@util/Dates';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FindUserAddressDto } from '@rootTypes/user-addresses/find-user-address.dto';

interface IProps {
  userId: number;
}

export default function AddressesTable({ userId }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [addressesList, setAddressesList] = useState<any>();

  const getData = async () => {
    setIsLoading(true);

    getAddressAction(userId)
      .then(({ data }: { data: FindUserAddressDto }) => {
        setAddressesList(data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  if (addressesList)
    return (
      <DataTable
        value={addressesList}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        lazy
        loading={isLoading}
        stripedRows
        selectionPageOnly
        emptyMessage="No data found"
        className="datatable-responsive"
      >
        <Column field="id" sortable header={translate('adminTool.users.id')} />
        <Column
          field="name"
          sortable
          header={translate('adminTool.users.firstName')}
        />
        <Column
          field="restOfName"
          sortable
          header={translate('adminTool.users.lastName')}
        />
        <Column
          field="street"
          sortable
          header={translate('adminTool.users.street')}
        />
        <Column
          field="postalCode"
          sortable
          header={translate('adminTool.users.postalCode')}
        />
        <Column
          field="city"
          sortable
          header={translate('adminTool.users.city')}
        />
        <Column
          field="createdAt"
          sortable
          header={translate('adminTool.users.createdAt')}
          body={formatDateToLocalTime}
        />
        <Column
          field="updatedAt"
          sortable
          header={translate('adminTool.users.updatedAt')}
          body={formatDateToLocalTime}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.users.updatedBy')}
        />
      </DataTable>
    );
  else return <Spinner />;
}
