import {
  DELIVERY_STATUSES_FULFILLED,
  DELIVERY_STATUSES_PENDING,
  DELIVERY_STATUSES_REJECTED,
} from '../Network/Actions/Types';
import { DeliveryStatusesState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const deliveryStatusesReducer = (
  state: DeliveryStatusesState = {
    loading: false,
    deliveryStatuses: { items: [], meta: { totalItems: 0 } },
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case DELIVERY_STATUSES_PENDING:
      return {
        loading: true,
        deliveryStatuses: state.deliveryStatuses,
        error: null,
      };
    case DELIVERY_STATUSES_FULFILLED:
      return { loading: false, deliveryStatuses: action.payload, error: null };
    case DELIVERY_STATUSES_REJECTED:
      return {
        loading: false,
        deliveryStatuses: state.deliveryStatuses,
        error: action.payload,
      };
    default:
      return state;
  }
};
