import React from 'react';
import { H5 } from '../../../../../../../Component/Text/Text';
import { User } from '../../../../../../../models/user.model';
import { translate } from '../../../../../../../Module/Translate/Translate';

interface Props {
  user: User;
}

export default function PersonalData({ user }: Props) {
  return (
    <div className="col col-6">
      <h2 className="users-header">{translate('adminTool.users.userData')}</h2>
      <br />
      <H5 className="users-h5">{translate('adminTool.users.firstName')}</H5>
      <p>{user.personalDetails?.firstName}</p>
      <H5 className="users-h5">{translate('adminTool.users.lastName')}</H5>
      <p>{user.personalDetails?.lastName}</p>
    </div>
  );
}
