import React from 'react';
import './SidebarItemMobile.scss';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '@src/store';
import { NotificationsState } from '@type/Custom';
import SidebarSubItem from '../SidebarSubItem/SidebarSubItem';

const SidebarItemMobile = ({ setActivePanel, activePanel, item }: any) => {
  const isActiveItem = () => {
    if (activePanel === item.template) {
      return true;
    }
    return !!item.children.find((i: any) => i.template === activePanel);
  };

  const clickElement = function () {
    if (!!item.children.length) {
      setActivePanel(item.children[0]);
    }

    setActivePanel(item);
  };

  const { unreadCount } = useSelector<RootState, NotificationsState>(
    (state) => state.notifications,
  );

  const messageCount = item.template === 'messages' ? unreadCount : 0;

  return (
    <>
      <li className={`mobile-sidebar-item ${isActiveItem() ? 'open' : ''}`}>
        <Link
          to="#"
          className={`mobile-sidebar-item_title ${isActiveItem() && 'active'}`}
          onClick={() => clickElement()}
        >
          {item.displayName}{' '}
          {!!messageCount && (
            <span className="mobile-sidebar-item_count">{messageCount}</span>
          )}
        </Link>

        {!!item.children.length && (
          <ul className={`collapse list-unstyled ${isActiveItem() && 'show'}`}>
            {item.children.map((child: any, i: any) => (
              <SidebarSubItem
                key={i}
                setActivePanel={setActivePanel}
                activePanel={activePanel}
                item={child}
              />
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

export default SidebarItemMobile;
