import { translate } from '@module/Translate/Translate';
import React from 'react';

interface ICalculatorButton {
  text: string;
  onCLick: () => void;
  data: string;
  addModeActive: boolean;
}

export default function CalculatorButton(props: ICalculatorButton) {
  return (
    <button
      data-mode={props.data}
      data-e2e={props.data}
      className={
        'calculator-container_btn mode-button offer__button bg-white w-100 h2 btn border rounded x8 fs18 fs-lg-24 fw500 py-3 ' +
        (props.addModeActive ? 'mode-active' : '')
      }
      onClick={props.onCLick}
    >
      {translate(props.text)}
    </button>
  );
}
