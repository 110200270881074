import React, { useEffect, useState } from 'react';
import Counter from '../Counter/Counter';
import './Box.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  addProductInBasket,
  removeProductFromBasket,
  decreaseProductInBasket,
} from '@network/Actions/Basket';
import { FindProductDto } from '@rootTypes/products/find-product.dto';
import { RootState } from '@src/store';
import { BasketState } from '@type/Custom';
import { HashLink } from 'react-router-hash-link';
import ReactTooltip from 'react-tooltip';
import { ProductType } from '@rootTypes/products/product-type.enum';

interface IProps {
  stepValueCounter: number;
  boxData: FindProductDto;
}

export default function Box({ boxData, stepValueCounter }: IProps) {
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const onCounterIncreaseClick = (value: any) => {
    dispatch(
      addProductInBasket({ data: { ...boxData, value }, stepValueCounter }),
    );
    setValue(value);
  };

  const onCounterDecreaseClick = (value: any) => {
    if (value === 0) dispatch(removeProductFromBasket(boxData));
    if (value !== 0)
      dispatch(
        decreaseProductInBasket({ ...boxData, value }, stepValueCounter),
      );
    setValue(value);
  };

  useEffect(() => {
    const box = state.products.find(
      (product) =>
        product.type === boxData.type &&
        product.group === boxData.group &&
        product.id === boxData.id,
    );

    box ? setValue(+box.value) : setValue(0);
  }, [boxData, state.products]);

  const getDescription: any = () => {
    const descriptions: any = boxData.descriptions.filter(
      (description: any) => {
        // TODO it would be good to add type tooltip then we can have full flexibility
        //textForToolTip not exist and return null
        if (boxData.type === description.type && description.textForToolTip)
          return true;
      },
    )[0];
    return descriptions ? descriptions.text : null;
  };

  const getHyperLinkPerBoxType = () => {
    switch (boxData.type) {
      case ProductType.storage:
        return 'przechowaj';
      case ProductType.rent:
        return 'wynajem';
      default:
        return 'DlaFirm';
    }
  };

  const getDescriptionOrSizes = () => {
    const description = getDescription();
    return description ? (
      <>{`${description}`}</>
    ) : (
      <>
        {`${boxData.productDetails.depth} cm x ${boxData.productDetails.width} cm
        x ${boxData.productDetails.height} cm`}
      </>
    );
  };

  const rebuildToolTip = () => {
    ReactTooltip.rebuild();
  };
  const hideToolTip = () => {
    ReactTooltip.hide();
  };

  const [tooltipMessage] = useState(getDescription());

  return (
    <>
      <HashLink
        className="box-details"
        smooth
        to={`/${getHyperLinkPerBoxType()}#box` + boxData.name.split(' ')[1]}
        scroll={(el) =>
          el.scrollIntoView({ behavior: 'auto', block: 'center' })
        }
      >
        <ReactTooltip id="show-box-tooltip" multiline={true} />
        <p className="order-box">
          {boxData.name.split(' ')[0]}&nbsp;
          <span>{boxData.name.split(' ')[1]}</span>
        </p>
        <span
          data-for="show-box-tooltip"
          data-tip={`${tooltipMessage ? tooltipMessage : ''}`}
          onLoad={rebuildToolTip}
          onClickCapture={hideToolTip}
          className="box-description"
        >
          {getDescriptionOrSizes()}
        </span>
      </HashLink>
      <Counter
        stepValue={stepValueCounter}
        onCounterIncreaseClick={(value) => onCounterIncreaseClick(value)}
        onCounterDecreaseClick={(value) => onCounterDecreaseClick(value)}
        counterValue={value}
        extraClass="order-counter-small"
      />
    </>
  );
}
