export const store = [
  {
    title: 'offer.primaryBlockStore.infoBlock.item-1.title',
    text: 'offer.primaryBlockStore.infoBlock.item-1.text',
  },
  {
    title: 'offer.primaryBlockStore.infoBlock.item-2.title',
    text: 'offer.primaryBlockStore.infoBlock.item-2.text',
  },
  {
    title: 'offer.primaryBlockStore.infoBlock.item-3.title',
    text: 'offer.primaryBlockStore.infoBlock.item-3.text',
  },
  {
    title: 'offer.primaryBlockStore.infoBlock.item-4.title',
    text: 'offer.primaryBlockStore.infoBlock.item-4.text',
  },
  {
    title: 'offer.primaryBlockStore.infoBlock.item-5.title',
    text: 'offer.primaryBlockStore.infoBlock.item-5.text',
  },
  {
    title: 'offer.primaryBlockStore.infoBlock.item-6.title',
    text: 'offer.primaryBlockStore.infoBlock.item-6.text',
  },
];

export const move = [
  {
    title: 'offer.primaryBlockMove.infoBlock.item-1.title',
    text: 'offer.primaryBlockMove.infoBlock.item-1.text',
  },
  {
    title: 'offer.primaryBlockMove.infoBlock.item-2.title',
    text: 'offer.primaryBlockMove.infoBlock.item-2.text',
  },
  {
    title: 'offer.primaryBlockMove.infoBlock.item-3.title',
    text: 'offer.primaryBlockMove.infoBlock.item-3.text',
  },
  {
    title: 'offer.primaryBlockMove.infoBlock.item-4.title',
    text: 'offer.primaryBlockMove.infoBlock.item-4.text',
  },
];

export const rent = [
  {
    title: 'offer.primaryBlockRent.infoBlock.item-1.title',
    text: 'offer.primaryBlockRent.infoBlock.item-1.text',
  },
  {
    title: 'offer.primaryBlockRent.infoBlock.item-2.title',
    text: 'offer.primaryBlockRent.infoBlock.item-2.text',
  },
  {
    title: 'offer.primaryBlockRent.infoBlock.item-3.title',
    text: 'offer.primaryBlockRent.infoBlock.item-3.text',
  },
  {
    title: 'offer.primaryBlockRent.infoBlock.item-4.title',
    text: 'offer.primaryBlockRent.infoBlock.item-4.text',
  },
  {
    title: 'offer.primaryBlockRent.infoBlock.item-5.title',
    text: 'offer.primaryBlockRent.infoBlock.item-5.text',
  },
  {
    title: 'offer.primaryBlockRent.infoBlock.item-6.title',
    text: 'offer.primaryBlockRent.infoBlock.item-6.text',
  },
];
