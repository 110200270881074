import React from 'react';
import { createRoot } from 'react-dom/client';
import './src/index.scss';
import './src/Style/bootstrap.min.css';
import WebApp from '../client/src/WebApp';
import { Provider } from 'react-redux';
import { store } from '../client/src/store';
import 'dotenv/config';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  //  <React.StrictMode>
  <Provider store={store}>
    <WebApp />
  </Provider>,
  // </React.StrictMode>,
);
