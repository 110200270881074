import Social from '@component/Social/Social';
import P, { H1 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { Link } from 'react-router-dom';
import Logo from '@images/logo.png';
import Morcik_sad from '@images/morcik_sad.png';
import './InvalidToken.scss';
import { Helmet } from 'react-helmet';

const InvalidToken = () => {
  return (
    <>
      <Helmet>
        <title>Skitraj.pl | {window.location.pathname.substring(1)}</title>
        <meta name="robots" content="noindex,nofollow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="invalid-token">
        <div className="invalid-token_container container content padding20">
          <div className="row">
            <div className="col text">
              <Link className="link" to={'/'}>
                <img
                  src={Logo}
                  alt={translate('invalidToken.alt1')}
                  className="invalid-token_logo logo"
                />
              </Link>
              <div className="row">
                <img
                  src={Morcik_sad}
                  alt={translate('invalidToken.alt2')}
                  className="invalid-token_img"
                />
              </div>

              <H1 className="invalid-token_title">invalidToken.title</H1>
              <P className="invalid-token_subtitle">invalidToken.subtitle</P>

              <Social />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvalidToken;
