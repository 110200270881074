import { translate } from '@module/Translate/Translate';
import {
  getAllPushNotificationsTokensAction,
  pushMultipleSystemNotifications,
  pushProfiledSystemNotifications,
  showPushNotificationsAction,
} from '@network/Actions/PushNotifications';
import { NotificationTypeEnum } from '@rootTypes/notifications/notification-type.enum';
import { OrderStatus } from '@rootTypes/orders/order-status.enum';
import { PNGroupEnum } from '@rootTypes/push-notifications/push-notifications-group.enum';
import { StockType } from '@rootTypes/stocks/stock-type.enum';
import { FindUserDto } from '@rootTypes/users/find-user.dto';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import UniversalList from './components/UniversalList/UniversalList';
import UniversalMultiDropdown from './components/UniversalMuktiSelect/UniversalMultiDropdown';
import UsersList from './components/UsersList/UsersList';
import './PushNotificationsPanel.scss';
const PushNotificationsPanel = () => {
  const [notificationFormTitle, setNotificationFormTitle] = useState('');
  const [notificationFormMessage, setNotificationFormMessage] = useState('');
  const [usersGroup, setUsersGroup] = useState({ group: PNGroupEnum.ALL });
  const [isSystemNotification, setIsSystemNotification] = useState(false);
  const [isProfiled, setProfiled] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<FindUserDto[]>([]);
  const [selectedStatus, setSelectedStatus] = useState(OrderStatus.A);
  const [selectedType, setSelectedType] = useState(StockType.STORAGE);
  const changeGroup = (propsGroup: PNGroupEnum) => {
    setUsersGroup({ group: propsGroup });
    setIsSystemNotification(false);
    setProfiled(false);
  };
  const { user } = useSelector<RootState, UserState>((state) => state.user);
  const sendPushNotifications = async (e: any) => {
    e.preventDefault();
    if (isSystemNotification && selectedUsers && selectedUsers.length > 0) {
      await pushMultipleSystemNotifications({
        users: selectedUsers,
        notification: {
          message: notificationFormMessage,
          title: notificationFormTitle,
          type: NotificationTypeEnum.administration,
        },
      });
    } else if (isProfiled) {
      await pushProfiledSystemNotifications(user.id, {
        status: selectedStatus,
        orderType: selectedType,
        notification: {
          message: notificationFormMessage,
          title: notificationFormTitle,
          type: NotificationTypeEnum.administration,
        },
      });
    } else {
      const response = await getAllPushNotificationsTokensAction(
        usersGroup.group,
      );
      if (response) {
        const tokenArr = response.data.map((obj: any) => {
          return obj.token;
        });
        showPushNotificationsAction({
          data: {
            title: notificationFormTitle,
            body: notificationFormMessage,
          },
          tokens: tokenArr,
        });
      } else {
        console.error({
          service: 'PushNotificationsPanel',
          method: 'sendPushNotifications',
          error: 'tokens array is empty',
        });
      }
    }
    setNotificationFormTitle('');
    setNotificationFormMessage('');
  };

  return (
    <div className="push-notifications-panel">
      <form className={'notification-form'}>
        <div className="notification-inputs-container">
          <div className="inputs-container-group">
            <label htmlFor="#notification_title">
              {translate('adminTool.notification.messageTitle')}
            </label>
            <input
              type="text"
              className={'notification-input'}
              maxLength={100}
              placeholder={translate('adminTool.notification.title')}
              value={notificationFormTitle}
              onChange={(e) => {
                setNotificationFormTitle(e.target.value);
              }}
              id="notification_title"
              required={true}
            />
          </div>

          <div className="inputs-container-group">
            <label htmlFor="#notification_contaner">
              {translate('adminTool.notification.messageContent')}
            </label>
            <textarea
              maxLength={200}
              className="notification-input notification-input-content"
              placeholder={translate('adminTool.notification.message')}
              value={notificationFormMessage}
              onChange={(e) => {
                setNotificationFormMessage(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="inputs-container">
          <div>
            <div className="input-group">
              <input
                className="input-radio"
                type="radio"
                name="notify"
                id="all"
                value={PNGroupEnum.ALL}
                placeholder={PNGroupEnum.ALL}
                defaultChecked={true}
                onChange={(e) => changeGroup(PNGroupEnum.ALL)}
              />
              <label htmlFor="all" className={`d-sm-block label--orange`}>
                {translate('adminTool.notification.allUsers')}
              </label>
            </div>
            <div className="input-group">
              <input
                className="input-radio"
                type="radio"
                name="notify"
                id="guests"
                value={PNGroupEnum.GUEST}
                placeholder={PNGroupEnum.GUEST}
                onChange={(e) => changeGroup(PNGroupEnum.GUEST)}
              />
              <label htmlFor="guests" className={`d-sm-block label--orange`}>
                {translate('adminTool.notification.guests')}
              </label>
            </div>
            <div className="input-group">
              <input
                className="input-radio"
                type="radio"
                name="notify"
                id="logged"
                value={PNGroupEnum.LOGGED}
                placeholder={PNGroupEnum.LOGGED}
                onChange={(e) => changeGroup(PNGroupEnum.LOGGED)}
              />
              <label htmlFor="logged" className={`d-sm-block label--orange`}>
                {translate('adminTool.notification.loggedUser')}
              </label>
            </div>
            <div className="input-group">
              <input
                className="input-radio"
                type="radio"
                name="notify"
                id="isSystemNotification"
                onChange={(e) => {
                  setProfiled(false);
                  setIsSystemNotification(true);
                }}
              />
              <label
                htmlFor="isSystemNotification"
                className={`d-sm-block label--orange`}
              >
                {translate('adminTool.notification.systemNotification')}
              </label>
            </div>
            <div className="input-group">
              <input
                className="input-radio"
                type="radio"
                name="notify"
                id="profiled"
                onChange={(e) => {
                  setProfiled(true);
                  setIsSystemNotification(false);
                }}
              />
              <label htmlFor="profiled" className={`d-sm-block label--orange`}>
                {translate('adminTool.notification.profiled')}
              </label>
            </div>
          </div>

          <button
            disabled={
              notificationFormTitle.length === 0 ||
              notificationFormMessage.length === 0 ||
              (isProfiled && selectedType.length === 0)
            }
            className="btn btn-100 button"
            onClick={sendPushNotifications}
          >
            {translate('adminTool.notification.send')}
          </button>
        </div>
      </form>
      <div className="notify-users-list">
        {isSystemNotification && (
          <div>
            <label htmlFor="#notification_title">
              {translate('adminTool.notification.selectUsers')}
            </label>
            <UsersList setSelected={setSelectedUsers} />
          </div>
        )}
      </div>
      <div className="notify-status-list">
        {isProfiled && (
          <div className="optionsContainer">
            <UniversalList
              label={translate('adminTool.notification.selectStatuses')}
              renderList={OrderStatus}
              onChange={setSelectedStatus}
              default={selectedStatus}
            />
            <UniversalMultiDropdown
              label={translate('adminTool.notification.selectOrderType')}
              default={selectedType}
              renderList={StockType}
              onChange={setSelectedType}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PushNotificationsPanel;
