import React, { FC, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { FindProductDto } from '../../../../types/products/find-product.dto';

interface Props {
  data: FindProductDto;
  onCounterClick: (value: number, data: FindProductDto) => void;
  stepValue: number;
  counterValue: number;
}

const PackageCounter: FC<React.PropsWithChildren<Props>> = ({
  data,
  onCounterClick,
  stepValue,
  counterValue,
}) => {
  const onHandleClick = (value: number) => {
    onCounterClick(value, data);
  };
  useEffect(() => {
    onHandleClick(counterValue);
  }, []);

  const [tooltipMessage] = useState(data.name);

  const rebuildToolTip = () => {
    ReactTooltip.rebuild();
  };
  const hideToolTip = () => {
    ReactTooltip.hide();
  };

  return (
    <div
      data-box={data.name.split(' ')[0]}
      className="calculator-container_item offer__box-container col d-flex flex-row  h3"
    >
      <div className="box fs18 fs-lg-24 fw500 bg-white px-3 rounded-left">
        <ReactTooltip id="show-box-name" multiline={true} />

        <p
          className="box_name"
          data-for="show-box-name"
          data-tip={`${tooltipMessage ? tooltipMessage : ''}`}
          onLoad={rebuildToolTip}
          onClickCapture={hideToolTip}
        >
          {data.name.split(' ')[0]}&nbsp;
          <span className="text-primary">{data.name.split(' ')[1]}</span>
        </p>
      </div>
      <div className="amount-selector d-flex flex-row bg-primary text-white rounded-right x8">
        <div className="c-pointer js-subtract">
          <button
            className={`fs18 fs-lg-24 fw400`}
            onClick={() => onHandleClick(counterValue - stepValue)}
            disabled={counterValue === 0}
          >
            <svg
              width="18"
              height="4"
              viewBox="0 0 18 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ismobile"
            >
              <path d="M17.6561 3.5H0.656067V0.5H17.6561V3.5Z" fill="white" />
            </svg>
            <span className="isdesktop">-</span>
          </button>
        </div>
        <div className="js-amount" data-value="0">
          <p className="value">{counterValue}</p>
        </div>
        <div className="c-pointer js-add">
          <button
            className="fs18 fs-lg-24 fw400"
            onClick={() => onHandleClick(counterValue + stepValue)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ismobile"
            >
              <path
                d="M10.7402 7.21698H17.6561V10.1887H10.7402V18H7.5719V10.1887H0.656067V7.21698H7.5719V0H10.7402V7.21698Z"
                fill="white"
              />
            </svg>
            <span className="isdesktop">+</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackageCounter;
