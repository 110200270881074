import React from 'react';
import { Link } from 'react-router-dom';
import ProductPackage from '../ProductPackage/ProductPackage';
import './ProductPackageList.scss';
import { translate } from '../../../../Module/Translate/Translate';
import { FindProductDto } from '../../../../../../types/products/find-product.dto';

interface Props {
  text: string;
  children: JSX.Element;
  productsData: FindProductDto[];
  stepValueCounter: number;
}

export default function ProductPackageList({
  text,
  children,
  productsData,
  stepValueCounter,
}: Props) {
  return (
    <>
      <div className="order-product-package-list">
        {children}
        <h3>{text}</h3>
        <ul>
          {productsData.map((product) => (
            <li key={product.code}>
              <ProductPackage
                productData={product}
                stepValueCounter={stepValueCounter}
              />
            </li>
          ))}
        </ul>
        <Link to="/wynajem" className="order-product-package-list-link">
          <button className="order-product-package-list_btn">
            {translate('orderPanel.packageListButton')}
          </button>
        </Link>
      </div>
    </>
  );
}
