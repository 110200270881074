import React from 'react';
import './RentalDetails.scss';
import { H1, P } from '../../../../../../Component/Text/Text';
import { translate } from '../../../../../../Module/Translate/Translate';
import { FindRentStockDto } from '../../../../../../../../types/stocks/find-rent-stock.dto';
import AddressInformation from '../../../../../../Component/AddressInformation/AddressInformation';
import unifiedFormatDate from '../../../../../../Util/Dates';

export const RentalDetails = (props: {
  stock: FindRentStockDto;
  closeModal: (arg: boolean) => void;
  additionalPeriodPrice: number;
}) => {
  return (
    <>
      <div className="details-container">
        <H1 className="details-container_number">{props.stock.stockNumber}</H1>
        <P className="details-container_title">
          myRentalBlock.rentalDetails.rental
        </P>
        <P className="details-container_text_colored">
          {props.stock.products && props.stock.products.length === 1
            ? props.stock.products[0].name
            : translate('myRentalBlock.rentalBox.mixName')}
        </P>
        <div className="packs-container">
          {props.stock.products &&
            props.stock.products.map((pack: any) => {
              return (
                <div key={pack.id} className="pack-info">
                  <P className="pack-info_text_bold">
                    {`${pack.quantity}x ${pack.name}`}&nbsp;
                  </P>
                </div>
              );
            })}
        </div>
        <P className="details-container_title">
          myRentalBlock.rentalDetails.rentalTime
        </P>
        <P className="details-container_text_colored">
          {`${unifiedFormatDate(
            new Date(props.stock.startDate),
          )} - ${unifiedFormatDate(
            new Date(
              props.stock.continuationDate
                ? props.stock.continuationDate
                : props.stock.endDate,
            ),
          )}`}
        </P>
        <P className="details-container_title">
          myRentalBlock.rentalDetails.rentalWeeklyCost
        </P>
        <P className="details-container_text_colored">
          {props.additionalPeriodPrice} {translate('default.currency')}{' '}
          {translate('myRentalBlock.rentalDetails.endContract')}
        </P>
        <div className="address-container row">
          <div className="col">
            <P className="details-container_title">
              myRentalBlock.rentalDetails.rentalDeliveryAddress
            </P>
            <AddressInformation item={props.stock.deliveryAddress} />
          </div>
          <div className="col">
            <P className="details-container_title">
              myRentalBlock.rentalDetails.rentalPickupAddress
            </P>
            <AddressInformation item={props.stock.returnAddress} />
          </div>
        </div>
        <div className="footer-container">
          <button
            className="btn details-container_btn"
            onClick={() => props.closeModal(false)}
          >
            {translate('myRentalBlock.rentalDetails.comeback')}
          </button>
        </div>
      </div>
    </>
  );
};
