import React from 'react';
import Box from '../Box/Box';
import './BoxList.scss';
import { translate } from '../../../../Module/Translate/Translate';
import { FindProductDto } from '../../../../../../types/products/find-product.dto';
import { DefaultSettingsState } from '../../../../Type/Custom';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { DefaultSettingsTypeEnum } from '../../../../../../types/default-settings/default-settings-type.enum';

interface IProps {
  stepValueBoxCounter: number;
  productsData: FindProductDto[];
}

export default function BoxList({ stepValueBoxCounter, productsData }: IProps) {
  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);
  const setting: any = defaultSettings.find(
    (elem) =>
      elem.type === DefaultSettingsTypeEnum.defaultMinValueForWarehouseStock,
  );
  return (
    <>
      <div className="order-box-list">
        <h2 className="order-box-list_title">
          {translate('orderPanel.boxListTitle')}
        </h2>
        <ul>
          {productsData.map((box: any) => (
            <li className="box-wrapper" key={box.id}>
              <Box boxData={box} stepValueCounter={stepValueBoxCounter} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
