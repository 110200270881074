export const createMessageData = (
  message: string,
): {
  startsWithBold: boolean;
  text: string[];
} => {
  const replacedBoldMessages = message.replace(/<\/b>/gim, '<b>');
  return {
    startsWithBold: message.startsWith('<b>'),
    text: replacedBoldMessages.split('<b>'),
  };
};
