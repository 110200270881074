import React from 'react';
import './CreditCards.scss';

import Header from './components/Header/Header';
import CardWrapper from './components/CardWrapper/CardWrapper';

const CreditCards = () => {
  return (
    <>
      <div className="cardswrapper">
        <Header />
        <CardWrapper />
      </div>
    </>
  );
};

export default CreditCards;
