import React, { useRef, useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { ImageTypeEnum } from '../../../../../../../../types/product-images/image-type.enum';
import './Images.scss';
import { H5 } from '../../../../../../Component/Text/Text';
import { translate } from '../../../../../../Module/Translate/Translate';

interface Props {
  setImage?: any;
  productId?: number;
}

const types = [
  {
    label: translate('adminTool.products.miniature'),
    value: ImageTypeEnum.miniature,
  },
  { label: translate('adminTool.products.offer'), value: ImageTypeEnum.offer },
  { label: translate('adminTool.products.shop'), value: ImageTypeEnum.store },
  {
    label: translate('adminTool.products.carousel'),
    value: ImageTypeEnum.carousel,
  },
  {
    label: translate('adminTool.products.gallery'),
    value: ImageTypeEnum.gallery,
  },
];

export default function Images({ setImage, productId }: Props) {
  const fileUploadRef = useRef(null);
  const [selectedType, setSelectedType] = useState(ImageTypeEnum.miniature);

  const imageUploader = (event: any) => {
    setImage(
      {
        file: event.files[event.files.length - 1],
        type: selectedType,
      },
      productId,
    );
  };

  const itemTemplate = (file: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <div>{file.name}</div>
        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <span
          style={{ fontSize: '1.0em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          {translate('adminTool.products.drag')}
        </span>
      </div>
    );
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
        {cancelButton}
      </div>
    );
  };

  const chooseOptions = {
    icon: 'pi pi-fw pi-images',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
  };
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  return (
    <div>
      <H5 className="product-h5">
        {translate('adminTool.products.imageType')}
      </H5>
      <Dropdown
        value={selectedType}
        options={types}
        className="type-dropdown"
        optionLabel="label"
        optionValue="value"
        onChange={(e) => setSelectedType(e.value)}
        placeholder={translate('adminTool.products.imageTypeSelect')}
        itemTemplate={(option) => {
          return <span>{option.label}</span>;
        }}
      />
      <H5 className="product-h5">
        {translate('adminTool.products.imageUploader')}
      </H5>
      <FileUpload
        ref={fileUploadRef}
        name="demo[]"
        multiple
        accept=".jpg,.png"
        maxFileSize={1000000}
        auto
        customUpload
        uploadHandler={imageUploader}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        cancelOptions={cancelOptions}
      />
    </div>
  );
}
