import React from 'react';
import Logo from '@images/logo.png';
import Morcik_sad from '@images/morcik_sad.png';
import { Link, useHistory } from 'react-router-dom';
import './PageNotFound.scss';
import Social from '@component/Social/Social';
import { H1 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import arrowIcon from '../../Asset/images/modal/arrowIcon.png';
const PageNotFound = () => {
  const history = useHistory();
  return (
    <>
      <div className="page-notfound">
        <div
          className="dialog_close"
          data-e2e="close-modal"
          aria-label={translate('modal.closeModal')}
          onClick={() => history.goBack()}
        >
          <img
            className="d-block d-md-none"
            src={arrowIcon}
            alt={translate('modal.closeModal')}
          />
        </div>
        <div className="page-notfound_container container content padding20">
          <div className="row">
            <div className="col text">
              <Link className="link" to={'/'}>
                <img
                  src={Logo}
                  alt="logo"
                  className="page-notfound_logo logo"
                />
              </Link>
              <div className="row">
                <img
                  src={Morcik_sad}
                  alt="Morcik"
                  className="page-notfound_img"
                />
              </div>
              <H1 className="page-notfound_title">pageNotFound.title</H1>
              <Social />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
