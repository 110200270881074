import {
  ADMIN_STOCKS_FAIL,
  ADMIN_STOCKS_REQUEST,
  ADMIN_STOCKS_SUCCESS,
  STOCKS_FAIL,
  STOCKS_REQUEST,
  STOCKS_SUCCESS,
} from '../Network/Actions/Types';
import { StocksAdminState, StocksState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const stocksReducer = (
  state: StocksState = { loading: false, stocks: [], error: null },
  action: Action,
) => {
  switch (action.type) {
    case STOCKS_REQUEST:
      return { loading: true, stocks: state.stocks, error: null };
    case STOCKS_SUCCESS:
      return { loading: false, stocks: action.payload, error: null };
    case STOCKS_FAIL:
      return { loading: false, stocks: state.stocks, error: action.payload };
    default:
      return state;
  }
};

export const adminStocksReducer = (
  state: StocksAdminState = {
    loading: false,
    data: { items: [], meta: { totalItems: 0 } },
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case ADMIN_STOCKS_REQUEST:
      return { loading: true, data: state.data, error: null };
    case ADMIN_STOCKS_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case ADMIN_STOCKS_FAIL:
      return { loading: false, data: state.data, error: action.payload };
    default:
      return state;
  }
};
