import React from 'react';
import SidebarSubItem from '../SidebarSubItem/SidebarSubItem';
import './SidebarItemDesktop.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { NotificationsState } from '../../../../Type/Custom';

const SidebarItemDesktop = ({
  openSubMenu,
  addOpenSubMenu,
  setActivePanel,
  activePanel,
  item,
}: any) => {
  const { unreadCount } = useSelector<RootState, NotificationsState>(
    (state) => state.notifications,
  );
  const isActiveItem = () => {
    if (activePanel === item.template) {
      return true;
    }
    return !!item.children.find((i: any) => i.template === activePanel);
  };

  const clickElement = function () {
    if (!!item.children.length) {
      addOpenSubMenu();
      return;
    }

    setActivePanel(item);
  };

  return (
    <>
      <li className={`desktop-sidebar-item ${openSubMenu ? 'open' : ''}`}>
        <a
          className={`desktop-sidebar-item_title ${isActiveItem() && 'active'}`}
          onClick={() => clickElement()}
        >
          {item.displayName}{' '}
          {item.template === 'messages' && unreadCount ? (
            <span className="desktop-sidebar-item_count">{unreadCount}</span>
          ) : (
            <span> </span>
          )}
        </a>

        {!!item.children.length && (
          <ul className={`collapse list-unstyled ${openSubMenu && 'show'}`}>
            {item.children.map((child: any, i: any) => (
              <SidebarSubItem
                key={i}
                setActivePanel={setActivePanel}
                activePanel={activePanel}
                item={child}
              />
            ))}
          </ul>
        )}
      </li>
    </>
  );
};

export default SidebarItemDesktop;
