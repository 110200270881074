import { handleAppleLogin } from '@network/Actions/Login';
import AppleLogin from 'react-apple-login';
import { useDispatch } from 'react-redux';
import AppleLogo from '@images/apple-logo.png';
import { FacebookGoogleEvents } from '@rootTypes/auth/facebook-google-auth.enum';
import { APPLE_CLIENT_ID, APPLE_REDIRECT_ADDRESS } from '@/envConfig';

export default function AppleLoginButton({ disabled, loginOrRegister }: any) {
  const dispatch = useDispatch();
  return (
    <AppleLogin
      redirectURI={APPLE_REDIRECT_ADDRESS as string}
      clientId={APPLE_CLIENT_ID as string}
      scope={'name email'}
      usePopup={true}
      callback={async (e) => {
        if (e?.authorization?.id_token) {
          if (e?.user) {
            dispatch(handleAppleLogin(e.authorization.id_token, e.user));
          } else {
            dispatch(handleAppleLogin(e.authorization.id_token));
          }
        }
      }}
      render={(props) => (
        <img
          src={AppleLogo}
          alt="socialIcon"
          className={`soсial-apple-img ${
            disabled ? 'soсial-apple-img_disabled' : ''
          }`}
          onClick={(e) => {
            if (disabled) {
              return;
            }
            e.preventDefault();
            if (
              //@ts-ignore
              window.isWebViewClient === true ||
              //@ts-ignore
              window.window.isWebViewIOSClient === true
            ) {
              //@ts-ignore
              window.ReactNativeWebView.postMessage(
                loginOrRegister === 'login'
                  ? FacebookGoogleEvents.APPLE_LOGIN
                  : FacebookGoogleEvents.APPLE_REGISTER,
              );
            } else props.onClick();
          }}
        />
      )}
    />
  );
}
