import './AdminPanelNavigation.scss';
import { Link, useHistory } from 'react-router-dom';
import Logo from '@images/customer/landing/logo_skitraj.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import { logoutAction } from '@network/Actions/Login';
import { translate } from '@module/Translate/Translate';
import { checkIsAllowed } from '@util/UserValidation';

export function AdminPanelNavigation() {
  const dispatch = useDispatch();
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const history = useHistory();

  const handleLogout = async (): Promise<any> => {
    dispatch(logoutAction());
    history.push('/');
  };

  return (
    <div className="admin-navigation">
      <div className="admin-navigation_placeholder" />
      <div className="admin-navigation_container row no-gutters">
        <div className="w-100 px-3">
          <div className="row no-gutters py-xl-4 d-flex justify-content-between">
            <div className="brand-panel col-3 p-0">
              <Link to="/">
                <img
                  className="brand-img"
                  src={Logo}
                  alt={translate('navigation.skitraj')}
                />
              </Link>
            </div>
            <div className="user-panel col-3 p-0">
              <div className="row d-flex flex-row no-gutters justify-content-end h-100">
                {!checkIsAllowed(userData?.user) ? (
                  <Link
                    to="/logowanie"
                    className="login-btn col btn btn-outline-primary my-auto ml-2"
                  >
                    <span className="fw700 btn-heading">
                      {translate('navigation.login')}
                    </span>
                  </Link>
                ) : (
                  <button
                    onClick={() => handleLogout()}
                    data-e2e="logout"
                    className="login-btn col btn btn-outline-primary my-auto ml-2"
                  >
                    <span className="fw700 btn-heading">Wyloguj</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPanelNavigation;
