import React from 'react';
import { RentalBox } from '../RentalBox/RentalBox';
import './RentalPlace.scss';
import { H5 } from '../../../../../../Component/Text/Text';
import { FindRentStockDto } from '../../../../../../../../types/stocks/find-rent-stock.dto';
import { translate } from '@module/Translate/Translate';
import { SERVER_URL } from '@/envConfig';

export const RentalPlace = (props: {
  stocks: FindRentStockDto[];
  search: string;
  refresh: (refresh: boolean) => void;
  visibleSpinner: (value: boolean) => void;
}) => {
  const formatInvoiceUrl = () => {
    return `${SERVER_URL}/api/utils/additional/instrukcja.pdf`;
  };
  const filteredStocks = props.stocks
    .filter((el: FindRentStockDto) => {
      if (el.isActive) {
        return el;
      }
    })
    .filter((el: FindRentStockDto) =>
      el.stockNumber
        ? el.stockNumber
            .toString()
            .toLocaleLowerCase()
            .includes(props.search.toLocaleLowerCase())
        : el,
    );

  return (
    <div className="rental-place">
      {filteredStocks.length > 0 && (
        <div className="rental-place_titleWrapper">
          <H5 className="subtitle-title">
            myRentalBlock.rentalPlace.rentedTitle
          </H5>
          <span className="subtitle-text">
            {translate('myRentalBlock.rentalPlace.rentedSubtitle')}
          </span>
          <span className="subtitle-link">
            <a
              href="https://www.youtube.com/watch?v=Dr64RJHwC3A&ab_channel=Skitraj%E2%80%A4pl"
              target="youtube"
            >
              {translate('myRentalBlock.rentalPlace.rentedTutorial')}
            </a>
          </span>
          <span className="subtitle-text">
            {translate('myRentalBlock.rentalPlace.rentedOr')}
          </span>
          <a
            className="subtitle-link"
            href={formatInvoiceUrl()}
            download={`skitraj-${'instruction.pdf'}`}
            target="_blank"
            rel="noreferrer"
          >
            {translate('myRentalBlock.rentalPlace.rentedInstruction')}
          </a>
        </div>
      )}
      <div className="box-container row">
        {filteredStocks &&
          filteredStocks.map((el: FindRentStockDto) => {
            return (
              <RentalBox
                key={el.id}
                stock={el}
                refresh={props.refresh}
                visibleSpinner={props.visibleSpinner}
              />
            );
          })}
      </div>
    </div>
  );
};
