import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './PaymentHistory.scss';
import Modal from '@component/Modal/Modal';
import P, { H2, H5 } from '@component/Text/Text';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import { paymentHistoryAction } from '@network/Actions/Payment';
import { PaymentHistoryDTO } from '@rootTypes/payments/dto/payment-history.dto';
import { RootState } from '@src/store';
import { PaymentHistoryState } from '@type/Custom';
import SingleOrderPaymentHistory from './components/SingleOrderPaymentHistory';

interface Props {
  userId: number;
  setSpinner: (value: boolean) => void;
}

export default function PaymentHistory({ userId, setSpinner }: Props) {
  const [search, setSearch] = useState<string>('');
  const [showConfirm, setShowConfirm] = useState(false);

  const changeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };
  const { history } = useSelector<RootState, PaymentHistoryState>(
    (state) => state.paymentHistory,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentHistoryAction(userId, error));
  }, []);

  const error = () => {
    setShowConfirm(true);
  };

  return (
    <>
      <Modal showModal={showConfirm} setShowModal={setShowConfirm}>
        <AdminContactForm
          setShowConfirm={setShowConfirm}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <div className="payment-history">
        <div>
          <div className="container-header payment-header">
            <div className="payment-header_title-group">
              <H2 className="payment-header_title">
                {translate('orderHistory.title')}
              </H2>
              <H5 className="container-subtitle payment-header_text">
                {translate('orderHistory.subtitle')}
              </H5>
            </div>
            <div className="payment-history_input-group">
              <label htmlFor="find-order">
                {translate('orderHistory.findOrder')}
              </label>
              <input
                className="custom-input"
                type="text"
                id="find-order"
                placeholder={translate('orderHistory.orderNumber')}
                value={search}
                onChange={changeEvent}
              />
            </div>
          </div>

          <div className="payment-table">
            <div className="payment-table_wrapper">
              <P className="payment-table_id">
                {translate('orderHistory.orderNumberHeader')}
              </P>
            </div>
            <div className="payment-table_wrapper">
              <P className="payment-table_title">
                {translate('orderHistory.dateHeader')}
              </P>
            </div>
            <div className="payment-table_wrapper">
              <P className="payment-table_title">
                {translate('orderHistory.billingPeroidHeader')}
              </P>
            </div>
            <div className="payment-table_wrapper">
              <P className="payment-table_title">
                {translate('orderHistory.paymentMethodHeader')}
              </P>
            </div>
            <div className="payment-table_wrapper">
              <P className="payment-table_title">
                {translate('orderHistory.sumHeader')}
              </P>
            </div>
            <div className="payment-table_wrapper">
              <P className="payment-table_title">
                {translate('orderHistory.fvHeader')}
              </P>
            </div>
            <span className="line orange-border" />
          </div>

          <div className="payment-table_content">
            {history &&
              history.length > 0 &&
              history
                .filter((order: PaymentHistoryDTO) =>
                  order.id
                    .toString()
                    .toLocaleLowerCase()
                    .includes(search.toLocaleLowerCase()),
                )
                .map((order: PaymentHistoryDTO) => {
                  return (
                    <SingleOrderPaymentHistory
                      key={order.id}
                      order={order}
                      showConfirm={() => setShowConfirm(true)}
                      visibleSpinner={(value) => setSpinner(value)}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
}
