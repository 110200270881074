import React, { useState } from 'react';
import QuestionAndAnswer from './QuestionAndAnswer/QuestionAndAnswer';
import Layout from './Layout/Layout';
import './Faq.scss';
import { translate } from '../../../../Module/Translate/Translate';
import { H3 } from '@component/Text/Text';

type faqProps = {
  data: {
    id: string;
    type: string;
    question: string;
    answer: string;
    subAnswers?: any;
    link?: any;
    exLink?: any;
  }[];
};

const Faq = ({ data }: faqProps): JSX.Element => {
  const [activeElement, setActiveElement] = useState({ id: '', type: '' });
  return (
    <Layout>
      <div className="faq-category">
        <div>
          <H3 className="faq-category_title">faq.category.title-general</H3>
          {data
            .filter((item) => item.type === 'general')
            .map((filteredItem) => {
              return (
                <QuestionAndAnswer
                  key={filteredItem.id}
                  question={filteredItem.question}
                  answer={filteredItem.answer}
                  subAnswers={filteredItem.subAnswers}
                  type={filteredItem.type}
                  id={filteredItem.id}
                  link={filteredItem.link}
                  exLink={filteredItem.exLink}
                  onClick={(id, type) => setActiveElement({ id, type })}
                  active={
                    activeElement.id === filteredItem.id &&
                    activeElement.type === filteredItem.type
                  }
                />
              );
            })}
          <h3 className="faq-category_title">
            {translate('faq.category.title-orders')}
          </h3>
          {data
            .filter((item) => item.type === 'orders')
            .map((filteredItem) => {
              return (
                <QuestionAndAnswer
                  key={filteredItem.id}
                  question={filteredItem.question}
                  answer={filteredItem.answer}
                  subAnswers={filteredItem.subAnswers}
                  type={filteredItem.type}
                  id={filteredItem.id}
                  link={filteredItem.link}
                  exLink={filteredItem.exLink}
                  onClick={(id, type) => setActiveElement({ id, type })}
                  active={
                    activeElement.id === filteredItem.id &&
                    activeElement.type === filteredItem.type
                  }
                />
              );
            })}
          <h3 className="faq-category_title">
            {translate('faq.category.title-boxes')}
          </h3>
          {data
            .filter((item) => item.type === 'boxes')
            .map((filteredItem) => {
              return (
                <QuestionAndAnswer
                  key={filteredItem.id}
                  question={filteredItem.question}
                  answer={filteredItem.answer}
                  subAnswers={filteredItem.subAnswers}
                  type={filteredItem.type}
                  id={filteredItem.id}
                  link={filteredItem.link}
                  exLink={filteredItem.exLink}
                  onClick={(id, type) => setActiveElement({ id, type })}
                  active={
                    activeElement.id === filteredItem.id &&
                    activeElement.type === filteredItem.type
                  }
                />
              );
            })}
          <h3 className="faq-category_title">
            {translate('faq.category.title-delivery-and-return')}
          </h3>
          {data
            .filter((item) => item.type === 'delivery-and-return')
            .map((filteredItem) => {
              return (
                <QuestionAndAnswer
                  key={filteredItem.id}
                  question={filteredItem.question}
                  answer={filteredItem.answer}
                  subAnswers={filteredItem.subAnswers}
                  type={filteredItem.type}
                  id={filteredItem.id}
                  link={filteredItem.link}
                  exLink={filteredItem.exLink}
                  onClick={(id, type) => setActiveElement({ id, type })}
                  active={
                    activeElement.id === filteredItem.id &&
                    activeElement.type === filteredItem.type
                  }
                />
              );
            })}
          <h3 className="faq-category_title">
            {translate('faq.category.title-payment')}
          </h3>
          {data
            .filter((item) => item.type === 'payment')
            .map((filteredItem) => {
              return (
                <QuestionAndAnswer
                  key={filteredItem.id}
                  question={filteredItem.question}
                  answer={filteredItem.answer}
                  subAnswers={filteredItem.subAnswers}
                  type={filteredItem.type}
                  id={filteredItem.id}
                  link={filteredItem.link}
                  exLink={filteredItem.exLink}
                  onClick={(id, type) => setActiveElement({ id, type })}
                  active={
                    activeElement.id === filteredItem.id &&
                    activeElement.type === filteredItem.type
                  }
                />
              );
            })}
          <h3 className="faq-category_title">
            {translate('faq.category.title-other')}
          </h3>
          {data
            .filter((item) => item.type === 'other')
            .map((filteredItem) => {
              return (
                <QuestionAndAnswer
                  key={filteredItem.id}
                  question={filteredItem.question}
                  answer={filteredItem.answer}
                  subAnswers={filteredItem.subAnswers}
                  type={filteredItem.type}
                  id={filteredItem.id}
                  link={filteredItem.link}
                  exLink={filteredItem.exLink}
                  onClick={(id, type) => setActiveElement({ id, type })}
                  active={
                    activeElement.id === filteredItem.id &&
                    activeElement.type === filteredItem.type
                  }
                />
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default Faq;
