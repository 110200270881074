import { sortObjectArrayByString } from './SortObjectArryByString';

/**
 * Groups array by specyfied files
 *
 * @Description
 * This function groups passed object. into matrix of grouped objects
 *
 * @unit: in /e2e/unit/groupObject/groupObject.test.ts
 *
 * @example output and expected behavior :/e2e/unit/groupObject/groupObject.test.ts
 *
 *
 * @param order Array of objects
 * @param sortingFiles group filed name
 * @returns Orders Array
 */
export const objectGroupper = (order: any, groupFiled: string): [][] | [] => {
  const groupedArray: any = []; //matrix of all grupped arrays
  let insideGroup: any = []; //single gropu assigned while grupping
  let lastSortingFiled = ''; //
  try {
    if (groupFiled === '' || typeof groupFiled !== 'string')
      throw new Error('Empty grupping filed');

    const sortedOrders = sortObjectArrayByString(order, groupFiled);
    sortedOrders.forEach((object: any, count: number) => {
      if (
        lastSortingFiled === '' ||
        lastSortingFiled !== object[`${groupFiled}`]
      ) {
        if (insideGroup.length !== 0) groupedArray.push(insideGroup);
        lastSortingFiled = object[`${groupFiled}`];
        insideGroup = [];
      }
      insideGroup.push(object);
      if (count === sortedOrders.length - 1) {
        groupedArray.push(insideGroup);
      }
    });

    return groupedArray;
  } catch (error) {
    console.error(
      `we could not group this array parameters ${order} and ${groupFiled} are invalid`,
    );
    return order || [];
  }
};
