import React from 'react';
import Parcels from '../../../../Asset/images/customer/landing/paczuszek-3.png';
import './BoxesBlock.scss';
import { H2, P } from '../../../../Component/Text/Text';
import { translate } from '../../../../Module/Translate/Translate';
import { HashLink } from 'react-router-hash-link';
import { ProductsState } from '../../../../Type/Custom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ProductGroup } from '../../../../../../types/products/product-group.enum';
import { ProductType } from '../../../../../../types/products/product-type.enum';
import { DescriptionTypeEnum } from '../../../../../../types/product-descriptions/description-type.enum';
import { BoxesSort } from '../../../../Util/BoxesSort';

export function BoxesBlock() {
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  return (
    <div className="boxes-background">
      <article className="d-block boxes-section row no-gutters padded-small">
        <img
          className="parcels sized-md"
          src={Parcels}
          alt={translate('homePage.boxesBlock.parcels')}
        />
        <div className="container production">
          <div className="row no-gutters mb-5">
            <H2 className="production_title col-12 col-md-8">
              homePage.boxesBlock.boxForYou
            </H2>
          </div>
          <div className="production_slider slider-articles no-gutters">
            {BoxesSort(
              products.filter(
                (product) =>
                  product.group === ProductGroup.box &&
                  product.type === ProductType.storage &&
                  product.isPromo,
              ),
            ).map((item: any, index) => (
              <div key={index} className="production-card">
                <div className="box d-flex flex-column bg-white rounded-top pt-4 px-4 pt-lg-5 px-lg-5">
                  <P className="production-card_title h2 d-flex fs21 fs-lg-24 fs-lg-44 fw500 mb-lg-4">
                    {item.name.split(' ')[0]}
                    <span className="text-primary">
                      &nbsp;{item.name.split(' ')[1]}
                    </span>
                  </P>
                  <P className="production-card_subtitle h3 d-flex fs16 fs-lg-18 fs-lg-24 fw400 mb-4">
                    {
                      item.descriptions.find(
                        (description: any) =>
                          description.type === DescriptionTypeEnum.store,
                      )?.text
                    }
                  </P>
                  <div className="production-card_control">
                    <HashLink
                      className="production-card_button box__action d-flex mt-auto px-3 px-lg-5 py-4 text-primary-light border-top border-shadow fs18 fs-lg-21 fw700"
                      smooth
                      to={'DlaFirm#box' + item.name.split(' ')[1]}
                      scroll={(el) =>
                        el.scrollIntoView({ behavior: 'auto', block: 'center' })
                      }
                    >
                      {translate('homePage.boxesBlock.seeSpecification')}
                    </HashLink>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </article>
    </div>
  );
}

export default BoxesBlock;
