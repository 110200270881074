import { createRef, useState } from 'react';
import React from 'react';
import './SingleOrder.scss';
import '../OrderHistory.scss';
import Arrow from '../../../../../Asset/images/dashboard/arrow.png';
import { H3 } from '../../../../../Component/Text/Text';
import { FindOrderDto } from '../../../../../../../types/orders/find-order.dto';
import { translate } from '../../../../../Module/Translate/Translate';
import { OrderType } from '../../../../../../../types/orders/order-type.enum';
import { StockType } from '../../../../../../../types/stocks/stock-type.enum';
import { FindStockDto } from '../../../../../../../types/stocks/find-stock.dto';
import unifiedFormatDate from '../../../../../Util/Dates';
import SingleOrderHistory from './SingleOrderHistory';

const SingleOrder: React.FC<
  React.PropsWithChildren<{ order: FindOrderDto }>
> = ({ order }: { order: FindOrderDto }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const container = createRef<HTMLDivElement>();
  const onModuleToggle = () => {
    setIsModalOpen(!isModalOpen);

    if (!isModalOpen) {
      container.current!.style.marginBottom = '0';
      container.current!.style.borderBottomLeftRadius = '0';
      container.current!.style.borderBottomRightRadius = '0';
    } else {
      container.current!.style.marginBottom = '1rem';
      container.current!.style.borderBottomLeftRadius = '1rem';
      container.current!.style.borderBottomRightRadius = '1rem';
    }
  };

  const orderTypeChecker = (data: OrderType) => {
    switch (data) {
      case OrderType.RENT:
        return translate('orderHistory.rent');
      case OrderType.SELL:
        return translate('orderHistory.sell');
      case OrderType.STORAGE:
        return translate('orderHistory.storage');
      case OrderType.RENTSELL:
        return translate('orderHistory.rentSell');
      case OrderType.STORAGERENT:
        return translate('orderHistory.storageRent');
      case OrderType.STORAGERENTSELL:
        return translate('orderHistory.storageRentSell');
      case OrderType.STORAGESELL:
        return translate('orderHistory.storageSell');
    }
  };
  const showProducts = (stocks: FindStockDto[]) => {
    const products: Record<string, any>[] = [];

    stocks.map((stock: FindStockDto) => {
      stock.products.map((product: any) => {
        if (products.length === 0) {
          products.push({
            quantity: product.quantity,
            name: product.name,
            id: product.id,
          });
          return;
        }

        const box = products.find((item) => item.id === product.id);

        if (!box) {
          products.push({
            quantity: product.quantity,
            name: product.name,
            id: product.id,
          });
        } else {
          products.map((item) => {
            if (item.id === product.id) {
              item.quantity = item.quantity + product.quantity;
              return item;
            }
          });
        }
      });
    });

    return products;
  };
  const getPeriod = (stock: FindStockDto) => (
    <p>{`${
      stock.stockType !== StockType.RENT ? stock.period : ''
    } (${unifiedFormatDate(new Date(stock.startDate))}-${unifiedFormatDate(
      new Date(stock.continuationDate ? stock.continuationDate : stock.endDate),
    )})`}</p>
  );

  const stocksSell = order.stocks.filter(
    (stock) => stock.stockType === StockType.SELL,
  );
  const stocksRent = order.stocks.filter(
    (stock) => stock.stockType === StockType.RENT,
  );
  const stocksStorage = order.stocks.filter(
    (stock) => stock.stockType === StockType.STORAGE,
  );

  return (
    <div className="single-order-block">
      <div
        className="singleContainer"
        ref={container}
        onClick={() => {
          onModuleToggle();
        }}
      >
        <p className="underscore singleContainer-title">{order.id}</p>

        <p className="singleContainer-title">{`${
          order.createdAt ? unifiedFormatDate(new Date(order.createdAt)) : ''
        }`}</p>

        <div className="center-text">
          <p className="singleContainer-title">
            {orderTypeChecker(order.orderType)}
          </p>
          <div className={`single-order_icon ${isModalOpen ? 'open' : ''}`}>
            <img
              alt="arrow"
              src={Arrow}
              onClick={() => {
                onModuleToggle();
              }}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="singleContainer-content">
          <div className="info row">
            <div className="info-element col col-6 col-lg-4">
              <H3>{translate('orderHistory.singleOrder.deliveryAddress')}</H3>
              <div>
                <p>{order.deliveryAddress.name}</p>
                <p>{order.deliveryAddress.restOfName || ''}</p>
                <p>{order.deliveryAddress.street}</p>
                <p>
                  {order.deliveryAddress.postalCode}{' '}
                  {order.deliveryAddress.city}
                </p>
              </div>
            </div>

            {order.returnedAddress && (
              <div className="info-element col col-6 col-lg-4">
                <H3>
                  {translate('orderHistory.singleOrder.rentalPickupAddress')}
                </H3>
                <div>
                  <p>{order.returnedAddress.name} </p>
                  <p>{order.returnedAddress.restOfName || ''}</p>
                  <p>{order.returnedAddress.street}</p>
                  <p>
                    {order.returnedAddress.postalCode}{' '}
                    {order.returnedAddress.city}
                  </p>
                </div>
              </div>
            )}

            {order.userCompanyDetails && (
              <div className="info-element col col-6 col-lg-4">
                {order.isCompany ? (
                  <h3>{translate('orderHistory.singleOrder.invoiceData')}</h3>
                ) : (
                  <h3>{translate('orderHistory.singleOrder.taxData')}</h3>
                )}

                <div>
                  {order.userCompanyDetails.taxNumber && (
                    <p>
                      {translate('orderHistory.singleOrder.taxNumber')}{' '}
                      {order.userCompanyDetails.taxNumber}
                    </p>
                  )}

                  <p>{order.userCompanyDetails.name}</p>
                  <p>{order.userCompanyDetails.restOfName || ''}</p>
                  <p>{order.userCompanyDetails.street}</p>
                  <p>
                    {order.userCompanyDetails.postalCode}{' '}
                    {order.userCompanyDetails.city}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="orderDetail">
            <h2 className="orderDetail-title ">
              {translate('orderHistory.singleOrder.orderDetails')}
            </h2>
            <div className="orderDetail-items">
              <div className="coln-1">
                <h3>{translate('orderHistory.singleOrder.storageService')}</h3>
                {stocksStorage.length !== 0 ? (
                  showProducts(stocksStorage).map((item) => (
                    <p className="underline" key={item.id}>
                      {`${item.quantity} x ${item.name}`}
                    </p>
                  ))
                ) : (
                  <p>{translate('orderHistory.singleOrder.nothing')}</p>
                )}
                {stocksStorage.length !== 0 && getPeriod(stocksStorage[0])}
              </div>
              <div className="coln-2">
                <H3>{translate('orderHistory.singleOrder.rentalService')}</H3>
                {stocksRent.length !== 0 ? (
                  showProducts(stocksRent).map((item) => (
                    <p className="underline" key={item.id}>
                      {`${item.quantity} x ${item.name}`}
                    </p>
                  ))
                ) : (
                  <p>{translate('orderHistory.singleOrder.nothing')}</p>
                )}
                {stocksRent.length !== 0 && getPeriod(stocksRent[0])}
              </div>
              <div className="coln-3">
                <h3>{translate('orderHistory.singleOrder.store')}</h3>
                {stocksSell.length !== 0 ? (
                  showProducts(stocksSell).map((item) => (
                    <p className="underline" key={item.id}>
                      {`${item.quantity} x ${item.name}`}
                    </p>
                  ))
                ) : (
                  <p>{translate('orderHistory.singleOrder.nothing')}</p>
                )}
              </div>
            </div>
          </div>

          <div className="statusHistory">
            <h2 className="statusHistory-title">
              {translate('orderHistory.singleOrder.statusesHistory')}
            </h2>
            <h3 className="statusHistory-subTitle">
              {translate('orderHistory.singleOrder.yourOrder')}
            </h3>
            <SingleOrderHistory orderNo={order.id} />
          </div>
          <></>
        </div>
      )}
    </div>
  );
};

export default SingleOrder;
