import './HomePage.scss';
import CalculatorBlock from '@component/CalculatorBlock/CalculatorBlock';
import MainTemplate from '@component/MainTemplate/MainTemplate';
import Modal from '@component/Modal/Modal';
import Spinner from '@component/Spinner/Spinner';
import SquirrelButton from '@component/SquirrelButton/SquirrelButton';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import { getProductsAction } from '@network/Actions/Products';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PrimaryBlock from './components/PrimaryBlock/PrimaryBlock';
import SeeMoreBlock from './components/SeeMoreBlock/SeeMoreBlock';
import PackagesBlock from './components/PackagesBlock/PackagesBlock';
import { Helmet } from 'react-helmet';
import { LocalBusiness } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import WeOfferBlock from './components/WeOfferBlock/WeOfferBlock';
import { ProductsState, UserState } from '@type/Custom';
import { RootState } from '@src/store';
import { ProductType } from '@rootTypes/products/product-type.enum';
import ContactBlock from './components/ContactBlock/ContactBlock';
import CarouselBlock from './components/CarouselBlock/CarouselBlock';
import BoxesBlock from './components/BoxesBlock/BoxesBlock';

const HomePage = () => {
  const dispatch = useDispatch();
  const [visibleSpinner, setVisibleSpinner] = useState(false);
  const [showError, setShowError] = useState(false);
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  const { user } = useSelector<RootState, UserState>((state) => state.user);

  useEffect(() => {
    dispatch(getProductsAction(true, ProductGroup.box));
  }, []);

  const carouselProducts = products.filter(
    (product) =>
      product.group === ProductGroup.box &&
      product.type === ProductType.storage &&
      product.isPromo,
  );
  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp<LocalBusiness>({
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: 'Skitraj.pl Sp. z o.o.',
            image: [
              'https://skitraj.pl/a504edcb7ac3a5caa48c820457c26a57.svg',
              'https://skitraj.pl/e7edf43e3aaaa6a22f5b144eb9d64e4a.jpg',
            ],
            telephone: '+48 32 733 62 39',
            email: 'biuro@skitraj.pl',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Zygmunta Starego 22/44',
              addressLocality: 'Gliwice',
              addressRegion: 'śląskie',
              addressCountry: 'Polska',
              postalCode: '44-100',
            },
            review: {
              '@type': 'Review',
              reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
                bestRating: '5',
              },
              author: {
                '@type': 'Person',
                name: 'Bogumił Michałowski',
              },
            },
            geo: {
              '@type': 'GeoCoordinates',
              latitude: 50.29003278245357,
              longitude: 18.656436655805173,
            },
            url: 'https://skitraj.pl/',
          }),
        ]}
      >
        <title>
          Przechowanie, przeprowadzki i pudełka na wynajem | skitraj.pl
        </title>
        <meta
          name="description"
          content="Bezpieczne przechowywanie, sprawne przeprowadzki i wygodne pudełka! Usługi dostosowane do Twoich potrzeb na czas remontu lub przeprowadzki."
        />
        <meta name="robots" content="index,follow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {visibleSpinner && <Spinner />}
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <MainTemplate>
        <PrimaryBlock />
        <WeOfferBlock />
        <CarouselBlock products={carouselProducts} />
        <BoxesBlock />
        <CalculatorBlock />
        <PackagesBlock />
        <SeeMoreBlock />
        <SquirrelButton
          setSpinner={(value) => setVisibleSpinner(value)}
          showError={() => setShowError(!showError)}
        />
        <ContactBlock
          visibleSpinner={(value) => setVisibleSpinner(value)}
          showError={(value) => setShowError(value)}
          user={user}
        />
      </MainTemplate>
    </>
  );
};

export default HomePage;
