import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPolicyAction,
  editPolicyAction,
  getPoliciesAction,
} from '../../../../Network/Actions/Policies';
import { RootState } from '../../../../store';
import { PoliciesState } from '../../../../Type/Custom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Policies.scss';
import { translate } from '../../../../Module/Translate/Translate';
import { Messages } from 'primereact/messages';
import Modal from '../../../../Component/Modal/Modal';
import FormPolicy from './components/FormPolicy';
import { UserProps } from '../../interfaces/interfaces';
import { formatDateToLocalTime } from '../../../../Util/Dates';

const LIMIT_ON_PAGE = 10;

export default function Policies({ user }: UserProps) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [policyModal, setPolicyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const message: any = useRef(null);
  const { policies }: PoliciesState = useSelector<RootState, PoliciesState>(
    ({ policies }) => policies,
  );
  useEffect(() => {
    if (isLoading) {
      dispatch(getPoliciesAction());
      setIsLoading(false);
    }
  }, [isLoading]);

  const setPolicy = (policy: any) => {
    addPolicyAction(policy)
      .then(() => {
        setPolicyModal(false);
        setIsLoading(true);
        showMessage('success', translate('adminTool.policy.added'));
      })
      .catch((error: any) => {
        const errMsg = 'Something went wrong. Contact with app administartor.';
        showMessage('error', errMsg);
      });
  };

  const updatePolicy = async (policy: any, file: any) => {
    await editPolicyAction(+policy.id, policy, file)
      .then(() => {
        setIsLoading(true);
        showMessage('success', translate('adminTool.policy.updated'));
      })
      .catch((error: any) => {
        const errMsg = error.response.data.message
          ? error.response.data.message
          : 'Something went wrong. Contact with app administartor.';
        showMessage('error', errMsg);
      });
  };

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div>
          <button
            type="submit"
            className={`submitForm m-auto button_accept`}
            onClick={() => setPolicyModal(true)}
          >
            {translate('buttons.add')}
          </button>
        </div>
      </div>
    );
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <FormPolicy
        policy={data}
        setPolicy={setPolicy}
        updatePolicy={updatePolicy}
        editor={user}
      />
    );
  };

  return (
    <div className="policy-container">
      <Messages ref={message} />
      <Modal
        showModal={policyModal}
        setShowModal={setPolicyModal}
        className={'dialog--large'}
      >
        <FormPolicy
          policy={null}
          setPolicy={setPolicy}
          updatePolicy={updatePolicy}
          editor={user}
        />
      </Modal>
      <DataTable
        value={policies}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        loading={isLoading}
        stripedRows
        header={renderHeader}
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        editMode="row"
        className="datatable-responsive"
        rows={LIMIT_ON_PAGE}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="id" sortable header="Id" />
        <Column
          field="type"
          sortable
          header={translate('adminTool.policy.type')}
        />
        <Column
          field="title"
          sortable
          header={translate('adminTool.policy.title')}
        />
        <Column
          field="status"
          sortable
          header={translate('adminTool.policy.status')}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.policy.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.policy.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.policy.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
