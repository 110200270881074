import {
  PRODUCTS_FAIL,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
} from '../Network/Actions/Types';
import { ProductsState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: Record<string, unknown>;
}
export const productReducer = (
  state: ProductsState = { loading: false, products: [], error: null },
  action: Action,
) => {
  switch (action.type) {
    case PRODUCTS_REQUEST:
      return { loading: true, products: state.products, error: null };
    case PRODUCTS_SUCCESS:
      return { loading: false, products: action.payload, error: null };
    case PRODUCTS_FAIL:
      return {
        loading: false,
        products: state.products,
        error: action.payload,
      };
    default:
      return state;
  }
};
