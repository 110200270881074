import { OrderType } from '@rootTypes/orders/order-type.enum';
import { CurrencyEnum } from '@rootTypes/payments/enums/payment-currency.enum';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';

export function checkoutEvent(step: number) {
  try {
    if (step) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: step,
            },
          },
        },
      });
    }
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

export function sendProductsEvent(products: any[], step: number) {
  try {
    if (products && products.length > 0 && step) {
      const dataForSent: any[] = [];
      products.map((item: any) => {
        dataForSent.push({
          id: item.id,
          name: item.name,
          quantity: item.value,
        });
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: step,
            },
            products: [...dataForSent],
          },
        },
      });
    } else {
      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: step,
            },
          },
        },
      });
    }
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

export function removeProductEvent(products: any, stepValueCounter?: number) {
  const preparedProducts = prepareProductsArray(products, stepValueCounter);
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: preparedProducts,
        },
      },
    });
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

export function addProductEvent(product: any, stepValueCounter?: number) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        add: {
          products: [
            {
              id: product.id,
              name: product.name,
              quantity: stepValueCounter || product.value,
              type: product.type,
            },
          ],
        },
      },
    });
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

export function signUpEvent(accountType: string, methodType: string) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'sign_up',
      account_type: accountType,
      method_type: methodType,
    });
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

export function loginEvent(userId: number) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'authentication',
      userID: userId,
    });
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

export function newsletterEvent() {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'newsletter',
    });
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

export function morcikEvent() {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'zadaj_pytanie',
    });
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

export function paymentEvent(data: any, paymentId: number, callback: any) {
  try {
    if (data) {
      const period = preparePeriod(data.periods, data.orderType);
      const products = prepareProducts(data.products, data.orderType);
      let paymentType = 'przelewy24';

      if (data.paymentType === PaymentMethodEnum.CARD) {
        paymentType = 'karta płatnicza';
      }
      if (data.paymentType === PaymentMethodEnum.BLIK) {
        paymentType = 'blik';
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currencyCode: CurrencyEnum.PLN,
          purchase: {
            actionField: {
              id: paymentId,
              affiliation: 'skitraj.pl',
              revenue: +data.summaryPrice,
              tax: 23.0,
              coupon: '',
              dimension1: period,
              dimension2: paymentType,
            },
            products: [...products],
          },
        },
        eventCallback: function () {
          callback();
        },
        eventTimeout: 2000,
      });
    }
  } catch (error) {
    //..TO DO ERROR HANDLIG
  }
}

function prepareProductsArray(products: any, stepValueCounter?: number) {
  const filteredProducts = products.map((item: any) => {
    return {
      id: item.id,
      name: item.name,
      quantity: stepValueCounter || item.value,
      type: item.type,
    };
  });

  return filteredProducts;
}

function prepareProducts(products: any, orderType: OrderType) {
  if (orderType === OrderType.STORAGE) {
    return products.filter((item: any) => {
      if (item.type === ProductType.storage) {
        return {
          id: item.id,
          name: item.name,
          price: item.monthPrice * item.quantity,
          quantity: item.quantity,
        };
      }
    });
  }
  if (orderType === OrderType.RENT) {
    return products.filter((item: any) => {
      if (item.type === ProductType.rent) {
        return {
          id: item.id,
          name: item.name,
          price: item.monthPrice * item.quantity,
          quantity: item.quantity,
        };
      }
    });
  }
  if (orderType === OrderType.SELL) {
    return products.filter((item: any) => {
      if (item.type === ProductType.storage) {
        return {
          id: item.id,
          name: item.name,
          price: item.monthPrice * item.quantity,
          quantity: item.quantity,
        };
      }
    });
  }
}

function preparePeriod(periods: any, orderType: OrderType) {
  if (orderType === OrderType.SELL) return '-';
  periods = periods.filter((item: any) => {
    if (item.type === (orderType === OrderType.RENT ? 'weeks' : 'months'))
      return item;
  });
  if (periods && periods.length > 0) {
    return +periods[0].date.split(' ')[0];
  } else {
    return '-';
  }
}
