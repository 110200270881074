import FacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import { useContext } from 'react';
import FacebookIcon from '@images/fb_color.png';
import { ExternalLoginContext } from '@view/RegistrationStepper/components/AuthenticationStep/components/LoginUser/LoginUser';
import { FacebookGoogleEvents } from '@rootTypes/auth/facebook-google-auth.enum';
import { FACEBOOK_APP_ID } from '@/envConfig';
import { EMethodType } from '@rootTypes/GTM/method-type.enum';
import { signUpEvent } from '@src/GTM/GTM';
import { AccountTypes } from '@rootTypes/orders/account-type.enum';

const isUser = (
  variableToCheck: any,
): variableToCheck is ReactFacebookLoginInfo =>
  (variableToCheck as ReactFacebookLoginInfo).email !== undefined;

const isError = (
  variableToCheck: any,
): variableToCheck is ReactFacebookFailureResponse =>
  (variableToCheck as ReactFacebookFailureResponse).status !== undefined;

const onFailure = () => {
  //Will be used only if the google service is not avaialble
  //We can return modal or better option is to show notification to the user
  //for now i am leaving this empty
};

interface IProps {
  disabled?: boolean;
  loginOrRegister: 'login' | 'register';
}

function FacebookAuth({ disabled, loginOrRegister }: IProps) {
  const handleExternalLogin: any = useContext<any>(ExternalLoginContext);

  const onSuccess = (
    res: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
  ): void => {
    if (isUser(res)) {
      signUpEvent(AccountTypes.PRIVATE, EMethodType.facebook);

      handleExternalLogin(res.accessToken, 'facebook');
    } else if (isError(res)) {
      //Will be used only if the profile is not avaialble
      //We can return modal or better option is to show notification to the user
      //for now i am leaving this empty
    }
  };

  return (
    <div
      style={{
        boxSizing: 'border-box',
        justifyContent: 'center',
      }}
    >
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        fields="email"
        scope="public_profile,email"
        onClick={(e) => {
          if (
            //@ts-ignore
            window.isWebViewClient === true ||
            //@ts-ignore
            window.window.isWebViewIOSClient === true
          ) {
            //!!! isWebViewClient is required don't remove it or mobile webView would not enable fb login
            e.preventDefault();
            //@ts-ignore
            window.ReactNativeWebView.postMessage(
              loginOrRegister === 'login'
                ? FacebookGoogleEvents.FACEBOOK_LOGIN
                : FacebookGoogleEvents.FACEBOOK_REGISTER,
            );
          }
        }}
        callback={onSuccess}
        onFailure={onFailure}
        icon={<img src={FacebookIcon} alt="socialIcon" />}
        textButton={''}
        cssClass="button_social"
        isDisabled={disabled}
      />
    </div>
  );
}

export default FacebookAuth;
