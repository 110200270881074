import React, { useRef } from 'react';
import './EditDefaultSettings.scss';
import { translate } from '../../../../../../Module/Translate/Translate';
import { H5 } from '../../../../../../Component/Text/Text';
import { useFormik } from 'formik';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { editDefaultSettingsAction } from '../../../../../../Network/Actions/DefaultSettings';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';

export default function EditDefaultSettings({
  defaultSettings,
  setRefresh,
  editor,
}: any) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      limit: defaultSettings ? defaultSettings.limit : '',
      isActive: defaultSettings.isActive,
      updatedBy: editor.email,
    },

    onSubmit: (values: any) => {
      editDefaultSettingsAction(defaultSettings.id, {
        limit: values.limit,
        isActive: values.isActive,
        updatedBy: values.updatedBy,
      })
        .then(() => {
          setRefresh(true);
          showMessage('success', translate('default.message.success'));
        })
        .catch((error: any) => {
          showMessage('error', translate('default.message.fail') + error);
        });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const statuses = [
    { label: translate('adminTool.rents.isActive'), value: true },
    { label: translate('adminTool.rents.notActive'), value: false },
  ];

  return (
    <div className="default-settings-main-container border-details">
      <div className="default-settings-header-container">
        <Messages ref={message} />
      </div>
      <div className="default-settings-data-container">
        <div className="default-settings-top-container">
          <div className="default-settings-info-left-container">
            <H5 className="default-settings-h5">
              {translate('adminTool.defaultSettings.name')}
            </H5>
            <p>
              {translate(
                'adminTool.defaultSettings.enums.' + defaultSettings.type,
              )}
            </p>
          </div>
          <div className="default-settings-info-right-container">
            <form
              className="default-settings-form"
              onSubmit={formik.handleSubmit}
            >
              <label className="rents-h5">Status</label>
              <Dropdown
                value={formik.values.isActive}
                options={statuses}
                className="type-dropdown"
                name="isActive"
                optionLabel="label"
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <FormFieldFormik
                name="limit"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.limit}
                label={translate(
                  'adminTool.defaultSettings.enums.' + defaultSettings.type,
                )}
                onBlur={formik.handleBlur}
              />
              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.values.limit !== defaultSettings.limit ||
                  formik.values.isActive !== defaultSettings.isActive
                    ? ''
                    : 'inactive'
                }`}
              >
                {translate('adminTool.defaultSettings.btn')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
