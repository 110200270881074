import { translate } from '../../../../../../Module/Translate/Translate';
import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './Options.scss';
import { H5 } from '../../../../../../Component/Text/Text';
import { FindProductDto } from '../../../../../../../../types/products/find-product.dto';
import { UpdateProductDto } from '../../../../../../../../types/products/update-product.dto';

interface Props {
  product: FindProductDto;
  onEditSubmit: any;
}

const types = [
  {
    label: translate('adminTool.products.visible'),
    value: true,
  },
  {
    label: translate('adminTool.products.notVisible'),
    value: false,
  },
  {
    label: translate('adminTool.products.notApplicable'),
    value: null,
  },
];

const wmsReturnableOptions = [
  {
    label: translate('adminTool.products.returnable'),
    value: false,
  },
  {
    label: translate('adminTool.products.notReturnable'),
    value: true,
  },
];

export const Options = ({ product, onEditSubmit }: Props): JSX.Element => {
  const [productPayload, setProductPayload] = useState<any>({
    isVisibleOnOrderPanel: product.isVisibleOnOrderPanel,
    isPromo: product.isPromo,
    isNonReturnableToWms: product.isNonReturnableToWms,
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setProductPayload((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const checkIsValid = () => {
    let updateProductDto: UpdateProductDto;
    try {
      updateProductDto = productPayload;
    } catch (error) {
      console.error(error);
      return;
    }
    return true;
  };

  const handleSubmit = () => {
    const payload = Object.assign(productPayload, { id: product.id });
    onEditSubmit(payload);
  };

  return (
    <React.Fragment>
      <H5 className="product-h5">adminTool.products.isNonReturnableToWms</H5>
      <Dropdown
        value={productPayload.isNonReturnableToWms}
        options={wmsReturnableOptions}
        className="type-dropdown"
        name="isNonReturnableToWms"
        optionLabel="label"
        optionValue="value"
        onChange={(e) => handleChange(e)}
      />
      <br />
      <H5 className="product-h5">adminTool.products.productFeature</H5>
      <Dropdown
        value={productPayload.isVisibleOnOrderPanel}
        options={types}
        className="type-dropdown"
        name="isVisibleOnOrderPanel"
        optionLabel="label"
        optionValue="value"
        onChange={(e) => handleChange(e)}
        placeholder={translate(
          'adminTool.products.visibleOnOrderPanelPlaceholder',
        )}
      />
      <Dropdown
        value={productPayload.isPromo}
        options={types}
        className="type-dropdown"
        name="isPromo"
        optionLabel="label"
        optionValue="value"
        onChange={(e) => handleChange(e)}
        placeholder={translate('adminTool.products.visibleAsPromoted')}
      />
      <br />
      <button
        type="submit"
        onClick={() => handleSubmit()}
        className={`submitForm invoice__button m-auto button_accept ${
          checkIsValid() ? '' : 'inactive'
        }`}
      >
        {translate('adminTool.products.updateOptions')}
      </button>
    </React.Fragment>
  );
};

export default Options;
