import React, { useEffect, useState } from 'react';
import './ShowPolicy.scss';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '@component/Footer/Footer';
import Modal from '@component/Modal/Modal';
import Navigation from '@component/Navigation/Navigation';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import { getPublishedPoliciesAction } from '@network/Actions/Policies';
import { RootState } from '@src/store';
import { PublishedPoliciesState, UserState } from '@type/Custom';
import { Helmet } from 'react-helmet';

const ShowPolicy = ({ typeOfPolicy }: any) => {
  const dispatch = useDispatch();

  const { publishedPolicies }: PublishedPoliciesState = useSelector<
    RootState,
    PublishedPoliciesState
  >(({ publishedPolicies }) => publishedPolicies);
  const { user } = useSelector<RootState, UserState>((state) => state.user);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    dispatch(
      getPublishedPoliciesAction(typeOfPolicy, () => setShowError(true)),
    );
  }, [typeOfPolicy]);

  const getFile = (policy: string): string => {
    switch (policy) {
      case 'Privacy':
        return 'polityka.pdf';
      case 'Website':
        return 'regulamin.pdf';
    }
    return '';
  };

  return (
    <>
      <Helmet>
        <title>Skitraj.pl | {window.location.pathname.substring(1)} </title>
        <meta name="robots" content="noindex,nofollow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={
            user.id
              ? translate('errors.badRequest')
              : translate('errors.orderCannotCreate')
          }
          activeContactLink={user.id ? true : false}
        />
      </Modal>
      <Navigation />
      <div className="show-policy">
        <div className="container show-policy_container">
          <div
            dangerouslySetInnerHTML={{ __html: publishedPolicies.content }}
            className="show-policy_content"
          />
          <a
            className="btn btn-small btn-dark show-policy_button"
            href={publishedPolicies.fileUrl}
            download={`skitraj-${getFile(typeOfPolicy)}`}
            target="_self"
          >
            {typeOfPolicy === 'Privacy'
              ? translate('policy.downloadPolicy')
              : translate('policy.downloadPDF')}
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShowPolicy;
