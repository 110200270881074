import React from 'react';
import './CommentType.scss';

interface IProps {
  id: string;
  name: string;
  changeCommentType?: any;
  defaultCheck?: boolean;
  style?: any;
}

export const CommentType: React.FC<React.PropsWithChildren<IProps>> = ({
  id,
  name,
  changeCommentType,
  defaultCheck,
  style,
}) => {
  return (
    <div className="comment-type" style={style}>
      <input
        checked={defaultCheck}
        type="radio"
        id={id}
        name="commentType"
        onChange={() => changeCommentType()}
      />
      <label htmlFor={id} data-e2e={`label-${id}`}>
        {name}
      </label>
    </div>
  );
};
