import React, { useState } from 'react';
import './RentalBox.scss';
import Modal from '../../../../../../Component/Modal/Modal';
import { P, H1 } from '../../../../../../Component/Text/Text';
import { RentalDetails } from '../RentalDetails/RentalDetails';
import { translate } from '../../../../../../Module/Translate/Translate';
import ReturnBoxesForm from '../../../../../../Form/ReturnBoxesForm/ReturnBoxesForm';
import artworkIcon from '../../../../../../Asset/images/dashboard/artworkIcon.png';
import { StockStatus } from '../../../../../../../../types/stocks/stock-status.enum';
import { FindRentStockDto } from '../../../../../../../../types/stocks/find-rent-stock.dto';
import VerifyReturnBoxesForm from '../../../../../../Form/VerifyReturnBoxesForm/VerifyReturnBoxesForm';
import CompletedSentForm from '../../../../../../Form/CompletedSentForm/CompletedSentForm';
import unifiedFormatDate from '../../../../../../Util/Dates';

export const RentalBox = (props: {
  stock: FindRentStockDto;
  refresh: (refresh: boolean) => void;
  visibleSpinner: (value: boolean) => void;
}) => {
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showReturnModal, setShowReturnModal] = useState<boolean>(false);
  const closeModal = (isOpen: boolean): void => {
    setShowDetailsModal(isOpen);
  };
  const [verifyReturnModal, setVerifyReturnModal] = useState<boolean>(false);
  const [courierOrdered, setCourierOrdered] = useState<boolean>(false);

  const [date, setDate] = useState<any>(null);

  return (
    <>
      <Modal
        showModal={showDetailsModal}
        setShowModal={setShowDetailsModal}
        className={'rent-info-modal'}
      >
        <RentalDetails
          stock={props.stock}
          closeModal={closeModal}
          additionalPeriodPrice={props.stock.continuationRentPrice}
        />
      </Modal>

      <Modal
        showModal={showReturnModal}
        setShowModal={setShowReturnModal}
        className={'rent-courier-modal dialog--large '}
      >
        <ReturnBoxesForm
          setShowConfirm={setShowReturnModal}
          stock={props.stock}
          setVerifyReturnModal={setVerifyReturnModal}
          date={setDate}
        />
      </Modal>

      <Modal showModal={verifyReturnModal} setShowModal={setVerifyReturnModal}>
        <VerifyReturnBoxesForm
          setVerifyReturnModal={setVerifyReturnModal}
          setShowReturnModal={setShowReturnModal}
          stock={props.stock}
          date={date}
          setCourierOrdered={setCourierOrdered}
          visibleSpinner={props.visibleSpinner}
        />
      </Modal>

      <Modal
        showModal={courierOrdered}
        setShowModal={setCourierOrdered}
        className="dialog--middle"
      >
        <CompletedSentForm
          setShowConfirm={setCourierOrdered}
          title={translate('courierOrdered.title')}
          message={translate('courierOrdered.messageRent')}
          buttonText={translate('courierOrdered.buttonText')}
          buttonOnClick={props.refresh}
        />
      </Modal>

      <div className="rental-box col col-12 col-xl-6">
        {props.stock.stockStatus === StockStatus.STOCK_R ||
        props.stock.stockStatus === StockStatus.STOCK_RFR ? (
          <div
            className="rental-box-element accent-element"
            key={props.stock.id}
          >
            <img src={artworkIcon} alt="artwork" />
            <P className="rental-box-element_title accent">
              myRentalBlock.rentalBox.header
            </P>
            <H1 className="rental-box-element_number accent">
              {props.stock.stockNumber}
            </H1>
            <P className="rental-box-element_text accent">
              myRentalBlock.rentalDetails.orderCourier
            </P>
            <P className="rental-box-element_text accent spacing text-bold">
              {unifiedFormatDate(new Date(props.stock.returnDate))}
            </P>
            <P className="rental-box-element_text accent">
              myRentalBlock.rentalDetails.rentalPickupAddress
            </P>
            <P className="rental-box-element_text accent text-bold">
              {props.stock.returnAddress.street}
            </P>
            <P className="rental-box-element_text accent text-bold">
              {`${props.stock.returnAddress.postalCode} ${props.stock.returnAddress.city}`}
            </P>
          </div>
        ) : (
          <div className="rental-box-element" key={props.stock.id}>
            <P className="rental-box-element_title">
              myRentalBlock.rentalBox.header
            </P>
            {props.stock.stockStatus === StockStatus.STOCK_STTC ||
            props.stock.stockStatus === StockStatus.STOCK_ATC ? (
              <H1 className="rental-box-element_number">
                {props.stock.stockNumber}
              </H1>
            ) : (
              <H1 className="rental-box-element_number">-</H1>
            )}

            <P className="rental-box-element_name">
              {props.stock.products && props.stock.products.length === 1
                ? props.stock.products[0].name
                : translate('myRentalBlock.rentalBox.mixName')}
            </P>
            <P className="rental-box-element_text">
              myRentalBlock.rentalBox.rentalDate
            </P>
            <P className="rental-box-element_text spacing">
              {unifiedFormatDate(new Date(props.stock.startDate))}
            </P>
            <P className="rental-box-element_text">
              myRentalBlock.rentalBox.rentalPeriod
            </P>
            <P className="rental-box-element_text">{`${unifiedFormatDate(
              new Date(props.stock.startDate),
            )}-${unifiedFormatDate(
              props.stock.continuationDate
                ? new Date(props.stock.continuationDate)
                : new Date(props.stock.endDate),
            )}`}</P>

            {props.stock.stockStatus === StockStatus.STOCK_ATC ? (
              <button
                className="rental-box_btn btn btn-small btn-100"
                onClick={() => setShowReturnModal(true)}
              >
                {translate('myRentalBlock.rentalBox.rentalCourier')}
              </button>
            ) : (
              <button
                className="rental-box_btn btn btn-small btn-100"
                disabled={true}
              >
                {translate('myRentalBlock.rentalBox.rentalCourier')}
              </button>
            )}
            <button
              className="rental-box_btn btn btn-small btn-dark btn-100"
              onClick={() => setShowDetailsModal(true)}
            >
              {translate('myRentalBlock.rentalBox.rentalDetail')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};
