import { calculatePriceWithDiscount } from '@util/ProductsCalculate';

//PROMOCJE: tu wyliczam cene dla przechowania
export const calculateCeilStorePriceByMonth = (
  storageMonths: number,
  transportStorePrice: number,
  boxesPriceStackedStore: number,
  boxesPriceNotStackedStore: number,
  discount?: number,
) => {
  if (storageMonths < 1) {
    return 0;
  } else {
    const storePriceByMonth =
      transportStorePrice / storageMonths +
      (boxesPriceStackedStore + boxesPriceNotStackedStore);

    return calculatePriceWithDiscount({ price: storePriceByMonth, discount });
  }
};
