import React, { useState } from 'react';
import './AddNewCommentFormMobile.scss';
import Morcik from '@images/offer/Morcik_kmina.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Rating from '@component/Rating/Rating';
import P from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { addNewCommentAction } from '@network/Actions/Reviews';
import { ReviewTypeEnum } from '@rootTypes/reviews/review-type.enum';
import { CommentType } from '../../CommentType/CommentType';

export const AddNewCommentFormMobile: React.FC<
  React.PropsWithChildren<{
    onHandleSubmit: () => void;
    isLoggedUser: any;
    showError: (value: boolean) => void;
    visibleSpinner: (value: boolean) => void;
  }>
> = ({ onHandleSubmit, isLoggedUser, showError, visibleSpinner }) => {
  const [rating, setRating] = useState(0);
  const [commentType, setCommentType] = useState(ReviewTypeEnum.storage);
  const [confirmedOpened, setConfirmedOpened] = useState(false);

  const handleChange = (value: any) => {
    setRating(value);
    formik.values.rating = value;
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      text: '',
      type: commentType,
      rating: rating,
      confirmed: isLoggedUser ? true : false,
    },
    validationSchema: Yup.object({
      rating: Yup.number()
        .nullable()
        .min(1, translate('offer.contact.ratingRequired'))
        .required(translate('offer.contact.ratingRequired')),
      name: Yup.string().required(translate('offer.contact.nameRequired')),
      text: Yup.string().required(translate('offer.contact.textRequired')),
    }),
    onSubmit: (values) => {
      visibleSpinner(true);
      values.rating = rating;
      values.type = commentType;
      addNewCommentAction(values)
        .then(() => {
          onHandleSubmit();
          clearForms(values);
        })
        .catch(() => {
          showError(true);
        })
        .finally(() => {
          visibleSpinner(false);
        });
    },
  });

  const clearForms = (values: any) => {
    values.name = '';
    values.text = '';
  };

  return (
    <>
      <div className="addNewcommentWrapper-mobile mw-100 mt-20">
        <P className="addNewcommentWrapper-mobile_title">
          {translate('offer.contact.addNewComment')}
        </P>
        <img className="morick-mobile" src={Morcik} alt="Morcik" />
        <form className="commentTypeWrapper" onSubmit={formik.handleSubmit}>
          <CommentType
            changeCommentType={() => setCommentType(ReviewTypeEnum.storage)}
            style={{ width: '100%' }}
            defaultCheck={commentType === ReviewTypeEnum.storage}
            id="storageCommentMobile"
            data-e2e="opinion-storage"
            name={translate('offer.weOfferBlock.storeIt')}
          />
          <div className="d-flex align-items-center justify-content-between w-100">
            <CommentType
              changeCommentType={() => setCommentType(ReviewTypeEnum.rent)}
              defaultCheck={commentType === ReviewTypeEnum.rent}
              id="rentCommentMobile"
              data-e2e="opinion-rent"
              style={{ width: '100%' }}
              name={translate('offer.weOfferBlock.rent')}
            />
            <CommentType
              changeCommentType={() => setCommentType(ReviewTypeEnum.shop)}
              defaultCheck={commentType === ReviewTypeEnum.shop}
              id="shopCommentMobile"
              data-e2e="opinion-shop"
              style={{ width: '100%' }}
              name={translate('offer.weOfferBlock.shop')}
            />
          </div>
          <CommentType
            changeCommentType={() => setCommentType(ReviewTypeEnum.move)}
            defaultCheck={commentType === ReviewTypeEnum.move}
            id="moveCommentMobile"
            data-e2e="opinion-move"
            name={translate('offer.weOfferBlock.move')}
          />
          <Rating value={rating} onChange={handleChange} />
          {rating === 0 ? (
            <div className="error-feedback">{formik.errors.rating}</div>
          ) : null}
          <div style={{ zIndex: 1, width: '100%' }}>
            <div className="commentInput-mobile mw-100">
              <label className="label--orange" htmlFor="name">
                <span>{translate('offer.contact.name')}</span>
              </label>
              <input
                type="text"
                name="name"
                data-e2e="name"
                id="name"
                className={`custom-input ${
                  formik.touched.name && formik.errors.name ? 'error-input' : ''
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="error-feedback">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="commentInput-mobile mw-100">
              <label className="label--orange" htmlFor="text">
                <span>{translate('offer.contact.comment')}</span>
              </label>
              <textarea
                name="text"
                id="text"
                data-e2e="description"
                className={`contact-form_input form-control ${
                  formik.touched.text && formik.errors.text ? 'error-input' : ''
                }`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.text}
              />
              {formik.touched.text && formik.errors.text ? (
                <div className="error-feedback">{formik.errors.text}</div>
              ) : null}
            </div>
            {!isLoggedUser && (
              <div className="confirmed">
                <div className="confirmed_group">
                  <input
                    className="custom-checkbox"
                    type="checkbox"
                    id="confirmedMobile"
                    name="confirmed"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.confirmed}
                  />
                  <label
                    className="confirmed-accept_label"
                    htmlFor="confirmedMobile"
                  >
                    <div className="confirmed-accept">
                      <p className="confirmed-accept_title">
                        {translate('offer.contact.confirmed.accept')}{' '}
                        <span
                          className="title-accent"
                          onClick={() => setConfirmedOpened(!confirmedOpened)}
                        >
                          {confirmedOpened
                            ? translate('offer.contact.confirmed.btnLess')
                            : translate('offer.contact.confirmed.btnMore')}
                        </span>
                      </p>
                    </div>
                  </label>
                </div>
                {confirmedOpened && (
                  <p className="confirmed-accept_text">
                    {translate('offer.contact.confirmed.acceptText')}
                    <Link
                      className="confirmed-accept_link"
                      to="/polityka-prywatnosci"
                      data-e2e="privacy-policy"
                    >
                      <span>{translate('offer.contact.confirmed.policy')}</span>
                    </Link>
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="d-flex w-100">
            <button
              className="btn newCommentSubmit-mobile mw-100"
              type="submit"
              disabled={!formik.values.confirmed}
            >
              {translate('offer.contact.addNewComment')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
