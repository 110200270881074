import React from 'react';
import './CompleteSendComment.scss';
import { H2 } from '../../Component/Text/Text';
import { translate } from '../../Module/Translate/Translate';
import CompletedIcon from '../../Asset/images/completedProcess.png';

interface Props {
  message: string;
}

function CompleteSendComment({ message }: Props) {
  return (
    <>
      <div className="sent-comment" data-e2e="completedSentComment">
        <img
          src={CompletedIcon}
          className="sent-comment_img"
          alt={translate('completedSentForm.completed')}
        />
        <H2 className="sent-comment_message" data-e2e="messageText">
          {message}
        </H2>
      </div>
    </>
  );
}

export default CompleteSendComment;
