import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { loginReducer } from './Reducers/LoginReducer';
import { userReducer } from './Reducers/UserReducer';
import { productReducer } from './Reducers/ProductReducer';
import { basketReducer } from './Reducers/BasketReducer';
//get all reducers and push them to combine reducers method
import { reviewsReducer } from './Reducers/ReviewsReducer';
import { notModifiedReviewsReducer } from '@reducers/NotModifiedReviewsReducer';
import { notificationsState } from './Reducers/NotificationsReducer';
import { orderReducer } from './Reducers/OrderReducer';
import { complaintsReducer } from './Reducers/ComplaintsReducer';
import { numberReducer } from './Reducers/NumberConfirmationReducer';
import { usersReducer } from './Reducers/UsersReducer';
import { ordersReducer } from './Reducers/OrdersReducer';
import { transportPriceReducer } from './Reducers/TransportPriceReducer';
import { defaultSettingsReducer } from './Reducers/DefaultSettingsReducer';
import { currentOrderReducer } from './Reducers/CurrentOrderReducer';
import { productPackagesReducer } from './Reducers/ProductPackagesReducer';
import { paymentHistoryReducer } from './Reducers/PaymentHistoryReducer';
import { adminStocksReducer, stocksReducer } from './Reducers/StocksReducer';
import { orderBlockedDatesReducer } from './Reducers/OrderBlockedDateReducer';
import { limitsReducer } from './Reducers/LimitsReducer';
import { unpaidOrdersReducer } from './Reducers/UnpaidOrdersReducer';
import { policiesReducer } from './Reducers/PoliciesReducer';
import { publishedPoliciesReducer } from './Reducers/PolicyReducer';
import {
  paymentsReducer,
  changePaymentMethodReducer,
} from './Reducers/PaymentsReducer';
import { deliveryStatusesReducer } from './Reducers/DeliveryStatuses.Reducer';
import { serverNetworkErrorReducer } from './Reducers/ServerNetworkErrorReducer';
import { languageReducer } from '@reducers/LanguageReducer';
import { newComplaintsReducer } from '@reducers/NewComplaintsReducer';
import { AdminOrderErrorsReducer } from '@reducers/AdminOrderErrosReducer';
import { promotionReducer } from '@reducers/PromotionReducer';
import { analyticsReducer } from '@reducers/AnalyticsReducer';
//get all reducers and push them to combine reducers method
const reducers = combineReducers({
  auth: loginReducer,
  user: userReducer,
  product: productReducer,
  notifications: notificationsState,
  basket: basketReducer,
  reviews: reviewsReducer,
  notModifiedRevies: notModifiedReviewsReducer,
  order: orderReducer,
  orders: ordersReducer,
  requestId: numberReducer,
  complaints: complaintsReducer,
  users: usersReducer,
  transportPrice: transportPriceReducer,
  defaultSettings: defaultSettingsReducer,
  deliveryStatuses: deliveryStatusesReducer,
  currentOrder: currentOrderReducer,
  productPackage: productPackagesReducer,
  paymentHistory: paymentHistoryReducer,
  payments: paymentsReducer,
  unpaidOrders: unpaidOrdersReducer,
  orderBlockedDates: orderBlockedDatesReducer,
  stocks: stocksReducer,
  adminStocks: adminStocksReducer,
  limits: limitsReducer,
  policies: policiesReducer,
  publishedPolicies: publishedPoliciesReducer,
  serverNetworkError: serverNetworkErrorReducer,
  userPaymentMethod: changePaymentMethodReducer,
  language: languageReducer,
  newComplaints: newComplaintsReducer,
  adminErrors: AdminOrderErrorsReducer,
  promotion: promotionReducer,
  analytics: analyticsReducer,
});
//init the store
const initialState = {};
//in case of passing multiple middle wares
const middleware = [thunk];

export const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

export type RootState = ReturnType<typeof store.getState>;
