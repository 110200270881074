import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from '../Network/Actions/Types';
import { AuthState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const loginReducer = (
  state: AuthState = { loading: false, accessToken: '', error: null },
  action: Action,
) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true, accessToken: '', error: null };
    case USER_LOGIN_SUCCESS:
      return { loading: false, accessToken: action.payload, error: null };
    case USER_LOGIN_FAIL:
      return { loading: false, accessToken: '', error: action.payload };
    case USER_LOGOUT:
      return { loading: false, accessToken: '', error: null };
    default:
      return state;
  }
};
