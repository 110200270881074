export const SERVER_PORT = process.env.SERVER_PORT;
export const SERVER_HOST = process.env.SERVER_HOST;
// export const SERVER_URL = `${SERVER_HOST}:${SERVER_PORT}`;
export const SERVER_URL = process.env.SERVER_URL;
export const GOOGLE_CLIENT_ID: string | any = process.env.GOOGLE_CLIENT_ID;
export const FACEBOOK_APP_ID: string | any = process.env.FACEBOOK_APP_ID;
export const NOTIFICATIONS_PAGE_SIZE = 10;
export const INVOICES_ENDPOINT = process.env.INVOICES_ENDPOINT;
export const ANALYTICS_TOKEN = process.env.ANALYTICS_TOKEN;

export const FIREBASE_API_KEY = process.env.FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID = process.env.FIREBASE_MEASUREMENT_ID;

export const FIREBASE_VAPID_KEY = process.env.FIREBASE_VAPID_KEY;

export const HOTJAR_ID = process.env.HOTJAR_ID;
export const HOTJAR_SNIPPED_VERSION = process.env.HOTJAR_SNIPPED_VERSION;
export const P24_URL = process.env.P24_URL;

export const GTM_TOKEN = process.env.GTM_TOKEN;

export const GOOGLE_PLAY_LINK = process.env.GOOGLE_PLAY_LINK;
export const APPLE_STORE_LINK = process.env.APPLE_STORE_LINK;
export const VONAGE_PIN_EXPIRY = process.env.VONAGE_PIN_EXPIRY;

export const ATTEMPTS_INTERVAL = process.env.ATTEMPTS_INTERVAL;
export const APPLE_REDIRECT_ADDRESS = process.env.APPLE_REDIRECT_ADDRESS;
export const APPLE_CLIENT_ID = process.env.APPLE_CLIENT_ID;

//Cookies
export const SKITRAJ_CONSENT_NAME = process.env.SKITRAJ_CONSENT_NAME;
export const SKITRAJ_CONSENT_VERSION = process.env.SKITRAJ_CONSENT_VERSION;
export const SKITRAJ_CONSENT_DISABLED = process.env.SKITRAJ_CONSENT_DISABLED;
export const SKITRAJ_COOKIEBOOT_CONSENT_NAME =
  process.env.SKITRAJ_COOKIEBOOT_CONSENT_NAME;

//Forms
export const SKITRAJ_MOVE_TO_FORM_ID = process.env.SKITRAJ_MOVE_TO_FORM_ID;
