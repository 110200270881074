import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Morcik from '@images/registration/morcik_happy.png';
import { useHistory } from 'react-router-dom';
import './CompletedStep.scss';
import * as Helpers from '../../helpers/helpers';
import { deleteOrderAction } from '@network/Actions/CurrentOrder';
import { useDispatch } from 'react-redux';
import P, { H1 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { PaymentTypeEnum } from '@rootTypes/payments/enums/payment-type.enum';

const CompletedStep = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const searchParam = new URLSearchParams(location.search).get('orderType');

  const onSubmit = useCallback(
    (values: any) => {
      if (
        searchParam === PaymentTypeEnum.transport ||
        location.pathname === '/return-boxes'
      ) {
        history.push({
          pathname: '/panel-klienta-moja-skrytka',
          state: { activePanel: 'hidingPlace' },
        });
      } else {
        Helpers.ClearFormData();
        dispatch(deleteOrderAction());
        history.push('/panel-klienta');
      }
    },
    [history, location],
  );

  return (
    <>
      <div className="completed-step">
        <H1 className="completed-step_title text-center d-none d-md-block">
          {searchParam === PaymentTypeEnum.single
            ? 'completedStep.thanks'
            : 'completedStep.order'}
        </H1>

        <img
          className="completed-step_img d-flex mw-100 mb-3"
          src={Morcik}
          alt="Morcik"
        />

        <div className="completed-step_content">
          <H1 className="completed-step_title text-center d-block d-md-none">
            {searchParam === PaymentTypeEnum.single
              ? 'completedStep.thanks'
              : 'completedStep.order'}
          </H1>

          <P className="completed-step_subtitle text-center mb-5">
            {translate('completedStep.emailInfo')}
          </P>
        </div>

        <button
          className="completed-step_btn btn btn-large w-100 m-auto"
          onClick={onSubmit}
        >
          {translate('completedStep.goToClientPanel')}
        </button>
      </div>
    </>
  );
};

export default CompletedStep;
