import React from 'react';
import './PaymentCardSmall.scss';

const PaymentCardSmall = ({ src }: any) => {
  return (
    <div className="payment-card-small">
      <img alt="card" src={src} />
    </div>
  );
};

export default PaymentCardSmall;
