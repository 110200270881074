import { PeriodCategory } from '@rootTypes/calendar/categories-enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { Variety } from '@rootTypes/variety/variety-type.enum';
import { calculateFullMonth } from './Dates';

interface IProps {
  startDate: Date;
  type: ProductType.rent | ProductType.storage;
  periods?: Record<string, string>[];
  rangeDate?: number;
}

export const initEndDateOfPeriod = ({
  startDate,
  type,
  periods,
  rangeDate,
}: IProps): Date => {
  const initDate = new Date(startDate);
  const quantityDayInWeek = 7;

  if (type === ProductType.rent) {
    const period =
      periods &&
      periods.find(
        (period) =>
          period.type === Variety.weeks &&
          period.category === PeriodCategory.period,
      );

    const rentDate =
      period &&
      new Date(
        initDate.setDate(
          initDate.getDate() + +period.date.slice(0, 2) * quantityDayInWeek - 1,
        ),
      );

    return rentDate ? rentDate : initDate;
  }

  if (type === ProductType.storage) {
    if (rangeDate) {
      return calculateFullMonth(startDate, rangeDate);
    }
    const period =
      periods &&
      periods.find(
        (period) =>
          period.type === Variety.months &&
          period.category === PeriodCategory.period,
      );
    if (!period) {
      return initDate;
    }
    return calculateFullMonth(startDate, +period.date.slice(0, 2));
  }
  return initDate;
};
