import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import {
  NOT_MODIFIED_REVIEWS_FAIL,
  NOT_MODIFIED_REVIEWS_REQUEST,
  NOT_MODIFIED_REVIEWS_SUCCESS,
} from './Types';
import http from '../NetworkHTTP';

export const getNotModifiedReviewsAction = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: NOT_MODIFIED_REVIEWS_REQUEST,
      });

      const { data } = await http.get('reviews/count');

      dispatch({
        type: NOT_MODIFIED_REVIEWS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NOT_MODIFIED_REVIEWS_FAIL,
        payload: 0,
      });
    }
  };
};
