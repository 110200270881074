import React from 'react';
import './Storage.scss';
import { Link } from 'react-router-dom';
import box from '@images/rent-page/pudelko.png';
import morcik from '@images/rent-page/morcik-rent.png';
import { translate } from '@module/Translate/Translate';
import { H1 } from '@component/Text/Text';

function StorageBlock() {
  return (
    <div className="storage-container">
      <div className="container">
        <img
          src={morcik}
          alt="morcik"
          className="d-block d-xl-none image-morcik-mobile"
        />

        <div className="title-group">
          <H1 className="title-group_title">
            {translate('howItWorks.storageBlock.title')}
          </H1>
          <h3 className="title-group_subtitle">
            {translate('howItWorks.storageBlock.subtitle')}{' '}
          </h3>
          <h3 className="title-group_text">
            {translate('howItWorks.storageBlock.text')}
            <span className="accent">
              {translate('howItWorks.storageBlock.textAccent')}
            </span>
          </h3>
          <Link
            to={{
              pathname: '/zamowienie',
              state: {
                defaultIndex: 1,
              },
            }}
          >
            <button type="button" className="storage-link_btn">
              {translate('howItWorks.storageBlock.buttonText')}
            </button>
          </Link>
        </div>

        <img src={box} alt="box" className="box" />
        <img src={morcik} alt="morcik" className="morcik" />
      </div>
    </div>
  );
}

export default StorageBlock;
