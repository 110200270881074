import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  POLICIES_FAIL,
  POLICIES_SUCCESS,
  POLICIES_REQUEST,
  PUBLISHED_POLICY_FAIL,
  PUBLISHED_POLICY_REQUEST,
  PUBLISHED_POLICY_SUCCES,
} from './Types';
import { http } from '@network/NetworkHTTP';
import { RootState } from '@src/store';

export const getPoliciesAction = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: POLICIES_REQUEST,
      });

      const { data } = await http.get(`policies`);
      dispatch({
        type: POLICIES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POLICIES_FAIL,
        payload: 'Nie udało się pobrać regulaminów.',
      });
    }
  };
};

export async function addPolicyAction(policy: any) {
  let data: any[];
  try {
    data = await http.post(`policies`, policy);
  } catch (error: any) {
    throw error;
  }
  return data;
}

export async function editPolicyAction(id: number, policy: any, file: any) {
  let data: any[];
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', policy.type);
    formData.append('title', policy.title);
    formData.append('content', policy.content);
    formData.append('status', policy.status);
    formData.append('updatedBy', policy.updatedBy);
    data = await http.patch(`policies/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error: any) {
    throw error;
  }
  return data;
}

export const getPublishedPoliciesAction = (
  type: string,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: PUBLISHED_POLICY_REQUEST,
      });
      const { data } = await http.get(`policies/status/published/${type}`);
      dispatch({
        type: PUBLISHED_POLICY_SUCCES,
        payload: data,
      });
    } catch (error) {
      onError();
      dispatch({
        type: PUBLISHED_POLICY_FAIL,
        payload: 'Nie udało się pobrać regulaminów.',
      });
    }
  };
};

export async function downloadPolicy(fileName: string) {
  const { data } = await http.get(`utils/policies/${fileName}`, {
    responseType: 'blob',
  });
  return data;
}
