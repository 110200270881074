import { Variety } from '../../../types/variety/variety-type.enum';

export const isSingular = (quantity: number): boolean => {
  return quantity == 1;
};

export const isPrular = (quantity: number): boolean => {
  return (
    quantity % 10 >= 2 &&
    quantity % 10 <= 4 &&
    (quantity % 100 < 10 || quantity % 100 >= 20)
  );
};

export const varietyOfPlural = (quantity: number, type: Variety): string => {
  const variety = {
    months: {
      singularNominativ: 'miesiąc',
      pluralNominativ: 'miesiące',
      pluralGenitive: 'miesięcy',
    },
    weeks: {
      singularNominativ: 'tydzień',
      pluralNominativ: 'tygodnie',
      pluralGenitive: 'tygodni',
    },
    hours: {
      singularNominativ: 'godzinę',
      pluralNominativ: 'godziny',
      pluralGenitive: 'godzin',
    },
  };

  if (isSingular(quantity)) return variety[type].singularNominativ;
  if (isPrular(quantity)) return variety[type].pluralNominativ;

  return variety[type].pluralGenitive;
};
