import React, { useState } from 'react';

import './ModalCard.scss';

import mcard from '../../../../../../Asset/images/card/mcard2.png';
import visa from '../../../../../../Asset/images/card//visa.png';
import closeicon from '../../../../../../Asset/images/card/closeicon.png';
import lineIcon from '../../../../../../Asset/images/card/line.png';
import arroBack from '../../../../../../Asset/images/shop/arrow-back.png';
import { translate } from '../../../../../../Module/Translate/Translate';

interface IData {
  name: string;
  number: number;
  month: number;
  year: number;
  CVV: number;
  checked: boolean;
  id: number;
}
interface IProps {
  title: string;
  onClick: () => void;
  closeModal: () => void;
  children?: JSX.Element;
  cardData?: IData | null;
}

const ModalCard = ({
  title,
  onClick,
  closeModal,
  children,
  cardData,
}: IProps) => {
  const [name, setName] = useState('');
  const [number, setNumber] = useState<null | number>(null);
  const [month, setMonth] = useState<null | number>(null);
  const [year, setYear] = useState<null | number>(null);
  const [CVV, setCVV] = useState<null | number>(null);
  const [checked, setChecked] = useState(false);

  const handleSubmit = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    onClick();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-wrapper">
        <h2 className="modal-wrapper_title">{title}</h2>

        <img
          src={arroBack}
          alt={translate('dashboard.cardWrapper.arrowBack')}
          className="arrowBack"
          onClick={() => closeModal()}
        />

        {cardData && (
          <div className="card-data">
            <p>{translate('dashboard.cardWrapper.currentCard')}</p>
            <p>{cardData.name}</p>
            <p>{cardData.number}</p>
            <p>
              {cardData.month}/{cardData.year}
            </p>
          </div>
        )}

        <img
          src={closeicon}
          alt={translate('dashboard.cardWrapper.close')}
          className="closeIcon"
          onClick={() => closeModal()}
        />

        <div className="modal-wrapper-cards">
          <div className="modal-wrapper-cards_front">
            <label className="namelabel">
              {translate('dashboard.cardWrapper.name')}
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="inputname"
            />
            <label className="numberlabel">
              {translate('dashboard.cardWrapper.cardNumber')}
            </label>
            <input
              type="number"
              value={number!}
              onChange={(e) => setNumber(parseInt(e.target.value))}
              className="inputnumber"
            />

            <div className="label-container">
              <div>
                <label className="datelabel">
                  {translate('dashboard.cardWrapper.date')}
                </label>
                <input
                  type="number"
                  value={month!}
                  onChange={(e) => setMonth(parseInt(e.target.value))}
                  className="inputmonth"
                />
                <img
                  src={lineIcon}
                  alt="slash symbol icon"
                  className="slash-icon"
                />
                <input
                  type="number"
                  value={year!}
                  onChange={(e) => setYear(parseInt(e.target.value))}
                  className="inputyear"
                />
              </div>
              <div className="mobile-cvv">
                <label className="CVVlabel">
                  {translate('dashboard.cardWrapper.cvv')}
                </label>
                <input
                  type="number"
                  value={CVV!}
                  onChange={(e) => setCVV(parseInt(e.target.value))}
                  className="CVVinput"
                />
              </div>
              <div className="images-group">
                <img src={mcard} alt="mcard" className="mcard" />

                <img src={visa} alt="visa" className="visa" />
              </div>
            </div>
          </div>
          <div className="modal-wrapper-cards_back">
            <div className="black"></div>
            <div className="back-input">
              <label className="CVVlabel">
                {translate('dashboard.cardWrapper.cvv')}
              </label>
              <input
                type="number"
                value={CVV!}
                onChange={(e) => setCVV(parseInt(e.target.value))}
                className="CVVinput"
              />
            </div>
          </div>
        </div>
        <div className="accept-group">
          <div className="accept-group_input">
            <input
              type="radio"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              className="checkbox"
            />
            <label className="default">
              {translate('dashboard.cardWrapper.agreement')}
            </label>
          </div>
          <button
            type="submit"
            onClick={(e) => handleSubmit(e)}
            className="saveBtn"
          >
            {translate('dashboard.cardWrapper.save')}
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalCard;
