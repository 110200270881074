import React from 'react';
import './B2B.scss';
import box from '@images/offer/box.png';
import morcik from '@images/rent-page/morcik.png';
import { translate } from '@module/Translate/Translate';
import { H1 } from '@component/Text/Text';

function handleClick(elementId: string) {
  const element = document.getElementById(elementId);

  element && element.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

function B2B() {
  return (
    <div className="b2b-container">
      <div className="container">
        <img
          src={morcik}
          alt="morcik"
          className="d-block d-xl-none image-morcik-mobile"
        />

        <div className="title-group">
          <H1 className="title-group_title">{translate('offer.b2b.title')}</H1>
          <h3 className="title-group_subtitle">
            {translate('offer.b2b.subtitle')}
          </h3>
          <div className="title-group_btns">
            <button
              className="btn btn-large b2b-link"
              onClick={() => handleClick('title-store')}
            >
              {translate('offer.b2b.store')}
            </button>
            <button
              className="btn btn-large b2b-link"
              onClick={() => handleClick('title-move')}
            >
              {translate('offer.b2b.move')}
            </button>
            <button
              className="btn btn-large b2b-link"
              onClick={() => handleClick('title-rent')}
            >
              {translate('offer.b2b.rent')}
            </button>
          </div>
        </div>

        <img src={box} alt="box" className="box" />
        <img src={morcik} alt="morcik" className="morcik" />
      </div>
    </div>
  );
}

export default B2B;
