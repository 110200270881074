import React, { useEffect, useState } from 'react';
import './SquirrelButton.scss';
import Morcik from '@images/squirrelButton/squirrel.png';
import MorcikConfirm from '@images/squirrelButton/squirrel-active.png';
import Modal from '@component/Modal/Modal';
import CompletedSentForm from '@form/CompletedSentForm/CompletedSentForm';
import ContactWithoutLoginForm from '@form/ContactWithoutLoginForm/ContactWithoutLoginForm';
import { translate } from '@module/Translate/Translate';
interface Props {
  isVisible?: boolean;
  modalToShow?: boolean;
  setSpinner: (value: boolean) => void;
  showError: () => void;
}

const SquirrelButton = ({
  isVisible = true,
  modalToShow = false,
  showError,
  setSpinner,
}: Props) => {
  useEffect(() => {
    setShowModal(modalToShow);
  }, [modalToShow]);

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  const [showConfirm, setShowConfirm] = useState(false);

  const ShowSquirrelButton = (isShow = isVisible) => {
    if (isShow) {
      return (
        <div className="squirrels-button" onClick={openModal}>
          <img
            className="base-form"
            data-e2e="morcik"
            src={Morcik}
            alt={translate('squirrelButton.squirrel')}
          />
          <img
            className="super-form"
            src={MorcikConfirm}
            alt={translate('squirrelButton.squirrelSearch')}
          />
        </div>
      );
    }
  };

  return (
    <>
      <Modal
        className="dialog--middle"
        showModal={showModal}
        setShowModal={setShowModal}
      >
        <ContactWithoutLoginForm
          setShowModal={setShowModal}
          setShowConfirm={setShowConfirm}
          setSpinner={setSpinner}
          showConfirm={showError}
        />
      </Modal>

      <Modal
        className="dialog--large"
        showModal={showConfirm}
        setShowModal={setShowConfirm}
      >
        <CompletedSentForm setShowConfirm={setShowConfirm} />
      </Modal>

      {ShowSquirrelButton()}
    </>
  );
};

export default SquirrelButton;
