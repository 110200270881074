import { translate } from '@module/Translate/Translate';
import { getAdminToolUsers } from '@network/Actions/Orders';
import { formatDateToLocalTime } from '@util/Dates';
import useDebounce from '@util/Debounse';
import { UserProps } from '@view/AdminTool/interfaces/interfaces';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import EditOrder from './components/EditOrder';

const LIMIT_ON_PAGE = 7;

export default function Orders({ user }: UserProps) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const [orders, setOrders] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [search, setSearch] = useState('');

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
  });

  useEffect(() => {
    if (mount) {
      getData();
      setMount(false);
    } else if (refresh) {
      getData();
      setRefresh(false);
    } else if (search) {
      searchData();
    } else {
      getData();
    }
  }, [refresh, lazyParams]);

  useEffect(() => {
    if (mount) {
      return;
    }
    if (search !== '') {
      setLazyParams({ ...lazyParams, first: 0 });
      searchData();
    } else {
      setLazyParams({ ...lazyParams, first: 0 });
      setRefresh(true);
    }
  }, [useDebounce(search, 500)]);

  const getData = async () => {
    setLoading(true);
    const data = await getAdminToolUsers(lazyParams);
    setTotalItems(data.meta.totalItems);
    setOrders(data.items);
    setLoading(false);
  };

  const labelChecker = (rowData: any, item: any) => {
    if (!item?.field) return;
    if (item.field === 'isActive') {
      return rowData[item.field]
        ? translate('adminTool.rents.isActive')
        : translate('adminTool.rents.notActive');
    } else if (item.field === 'user.isCompanyAccount') {
      return rowData.user.isCompanyAccount
        ? translate('adminTool.orders.company')
        : translate('adminTool.orders.person');
    }
  };

  const onPage = (event: any) => {
    setLazyParams({ ...event });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event });
  };

  const rowExpansionTemplate = (data: any) => {
    return <EditOrder order={data} setRefresh={setRefresh} editor={user} />;
  };

  const searchData = () => {
    setLoading(true);
    getAdminToolUsers({
      ...lazyParams,
      search,
    })
      .then((data) => {
        setTotalItems(data.meta.totalItems);
        setOrders(data.items);
      })
      .finally(() => setLoading(false));
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div></div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={translate('adminTool.orders.search')}
          />
        </span>
      </div>
    );
  };

  return (
    <div className="products-table-container">
      {!loading && orders ? (
        <DataTable
          value={orders}
          responsiveLayout="scroll"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          paginator
          header={renderHeader}
          lazy
          loading={loading}
          first={lazyParams.first}
          totalRecords={totalItems}
          stripedRows
          selectionPageOnly
          emptyMessage={translate('adminTool.common.nodata')}
          className="datatable-responsive"
          rows={LIMIT_ON_PAGE}
          expandedRows={expandedRows}
          onRowToggle={(e: any) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          onPage={onPage}
          onSort={onSort}
          sortOrder={lazyParams.sortOrder}
          sortField={lazyParams.sortField}
        >
          <Column expander style={{ width: '3em' }} />
          <Column
            field="id"
            sortable
            header={translate('adminTool.orders.orderId')}
          />
          <Column
            field="orderType"
            sortable
            header={translate('adminTool.orders.orderType')}
          />
          <Column
            field="status"
            sortable
            header={translate('adminTool.orders.status')}
          />
          <Column
            field="user.email"
            header={translate('adminTool.orders.email')}
          />
          <Column
            field="promotion.name"
            header={translate('adminTool.orders.promotion.name')}
          />
          <Column
            field="promotion.discount"
            header={translate('adminTool.orders.promotion.discount')}
          />
          <Column
            field="amount"
            header={translate('adminTool.orders.amount')}
          />
          <Column
            field="user.isCompanyAccount"
            header={translate('adminTool.orders.entity')}
            body={labelChecker}
          />
          <Column
            field="createdAt"
            body={formatDateToLocalTime}
            sortable
            header={translate('adminTool.orders.createdAt')}
          />
          <Column
            field="updatedAt"
            body={formatDateToLocalTime}
            sortable
            header={translate('adminTool.orders.updatedAt')}
          />
          <Column
            field="updatedBy"
            sortable
            header={translate('adminTool.orders.updatedBy')}
          />
        </DataTable>
      ) : (
        <div>{translate('appState.loading')}</div>
      )}
    </div>
  );
}
