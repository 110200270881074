import React, {
  FunctionComponent,
  useState,
  useEffect,
  SyntheticEvent,
} from 'react';
import './MultiFieldsInput.scss';
import { formatFields, getValue } from './utils';
import MultiFieldsInputT from './MultiFieldsInput.type';

const MultiFieldsInput: FunctionComponent<
  React.PropsWithChildren<MultiFieldsInputT>
> = ({
  autoFocus = true,
  inputs,
  error = '',
  label = '',
  name,
  onBlur,
  onChange,
  value = '',
  classes = {
    container: '',
    label: '',
    input: '',
    error: '',
    inputContainer: '',
  },
  type = 'text',
}: MultiFieldsInputT) => {
  const [fieldsValues, setFieldsValues] = useState(
    formatFields(value, name, inputs),
  );

  const handleBlur = (e: SyntheticEvent) => {
    const { name: fieldName, value } = e.target as HTMLInputElement;
    onBlur({
      name,
      value: getValue({
        ...fieldsValues,
        [fieldName]: value,
      } as any),
    });
  };

  const handleChange = (e: SyntheticEvent) => {
    const { name: fieldName, value } = e.target as HTMLInputElement;

    setFieldsValues({
      ...fieldsValues,
      [fieldName]: value,
    });

    if (autoFocus) {
      const index = parseInt(fieldName.substring(fieldName.length - 1));
      if (value.length === inputs[index].maxLength) {
        const nextInput = document.querySelector(
          `input[name='${name}${index + 1}']`,
        ) as HTMLInputElement;
        if (nextInput !== null) {
          nextInput.focus();
        }
      }
    }
  };
  useEffect(() => {
    if (onChange) {
      const value: any = getValue(fieldsValues as any);
      onChange({
        name,
        value,
      });
    }
  }, [fieldsValues]);

  return (
    <div className={classes.container}>
      {label && (
        <label htmlFor={name} className={classes.label}>
          {label}
        </label>
      )}

      <div className={classes.inputContainer}>
        {inputs.map((field, index) => {
          return (
            <input
              {...field}
              key={`${name}-${index}`}
              name={`${name}${index}`}
              value={fieldsValues[`${name}${index}`] as string}
              className={classes.input}
              onFocus={(e: any) => {
                e.target.select();
              }}
              onBlur={handleBlur}
              onInput={handleChange}
              onChange={handleChange}
              type={type}
            />
          );
        })}
      </div>
      {error !== '' && <div className={classes.error}>{error}</div>}
    </div>
  );
};

export default MultiFieldsInput;
