import React from 'react';
import './HowTo.scss';
import { data } from './data';
import { H2, H3 } from '@component/Text/Text';

function HowTo() {
  return (
    <div className="rent-howTo-container">
      <div className="title-group">
        <H2 className="title-group_title">rentPage.howToBlock.title</H2>
        <H3 className="title-group_subtitle">rentPage.howToBlock.subtitle</H3>
      </div>
      <ul className="howTo-list">
        {data.map((item: any) => (
          <li key={item.id} className="howTo-list-item">
            <img src={item.image} alt="phone" className="img-desktop" />
            <img src={item.imageMobile} alt="phone" className="img-mobile" />

            <div className="item-title-group">
              <H3 className="item-title-group_title">{item.title}</H3>
              <p className="item-title-group_subtitle">{item.subtitle}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HowTo;
