import React, { useEffect } from 'react';

import MainTemplate from '../../Component/MainTemplate/MainTemplate';
import Title from './Components/Title/Title';
import CheckThePackage from '../RentPage/components/CheckThePackage/CheckThePackage';
import './Package.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ProductsState } from '../../Type/Custom';
import { RootState } from '../../store';
import { ProductGroup } from '../../../../types/products/product-group.enum';
import { ProductType } from '../../../../types/products/product-type.enum';
import { getProductsAction } from '../../Network/Actions/Products';
import { BoxesSort } from '../../Util/BoxesSort';
import { Helmet } from 'react-helmet';

export default function Package() {
  const dispatch = useDispatch();
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );

  useEffect(() => {
    dispatch(
      getProductsAction(true, ProductGroup.packageBox, ProductType.rent),
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Skitraj.pl | {window.location.pathname.substring(1)}</title>
        <meta
          name="description"
          content="Zapraszamy do zapoznania się z pełną ofertą naszych pakietów przeprowadzonych. Zawierają one nie tylko pudełka, ale również niezbędne akcesoria pakowe. Ceny produktów zawierają 23% VAT."
        />
        <meta name="robots" content="index,follow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MainTemplate>
        <div className="package-block">
          <div className="package-wrapper container">
            <Title />
            <div className="rent-package-list row">
              {BoxesSort(products).map((product, id) => (
                <div key={id} className="package-container col-12 col-lg-4">
                  <CheckThePackage key={product.id} data={product} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </MainTemplate>
    </>
  );
}
