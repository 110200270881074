import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import {
  CURRENT_ORDER_FULFILLED,
  CURRENT_ORDER_PENDING,
  CURRENT_ORDER_REJECTED,
} from './Types';
import { CurrentOrderState } from '../../Type/Custom';

export const currentOrderAction = (
  orderData: any,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const currentOrder: CurrentOrderState = state.currentOrder;

    try {
      dispatch({
        type: CURRENT_ORDER_PENDING,
      });
      dispatch({
        type: CURRENT_ORDER_FULFILLED,
        payload: { ...currentOrder.order, ...orderData },
      });
    } catch (err) {
      dispatch({
        type: CURRENT_ORDER_REJECTED,
        payload: 'Przepraszamy ale serwis niedostępny. Spróbuj później.',
      });
    }
  };
};

export const deleteOrderAction = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return (dispatch) => {
    try {
      dispatch({
        type: CURRENT_ORDER_PENDING,
      });
      dispatch({
        type: CURRENT_ORDER_FULFILLED,
        payload: {},
      });
    } catch (err) {
      dispatch({
        type: CURRENT_ORDER_REJECTED,
        payload: 'Przepraszamy ale serwis niedostępny. Spróbuj później.',
      });
    }
  };
};
