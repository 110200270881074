import React, { useState } from 'react';
import Peanut from '@images/offer/peanut.png';
import OfferCard from './components/OfferCard/OfferCard';
import OfferCardItem from './components/OfferCardItem/OfferCardItem';
import offerCardItems from './offer-card-items.json';
import './WeOfferBlock.scss';
import { H2 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

const WeOfferBlock = () => {
  const [tab, setTab] = useState<'store' | 'rent' | 'shop' | 'move'>('store');

  return (
    <>
      <article
        id="boxes"
        className="homepage-we-offer-block row no-gutters mh-space offer primary-section"
      >
        <div className="container">
          <div className="row no-gutters py-5 offer_buy">
            <H2 className="offer_title  fs30 fs-lg-52 fw700">
              homePage.weOfferBlock.mainText
            </H2>
          </div>
          <div className="offer_wrapper no-gutters js-offer-wrapper">
            <button
              className={`offer_button offer__button--store js-change-tab ${
                tab === 'store' && 'offer_button--active'
              }`}
              onClick={() => setTab('store')}
            >
              {translate('homePage.weOfferBlock.storeIt')}
            </button>
            <button
              className={`offer_button offer__button--move js-change-tab ${
                tab === 'move' && 'offer_button--active'
              }`}
              onClick={() => setTab('move')}
            >
              {translate('homePage.weOfferBlock.move')}
            </button>
            <button
              className={`offer_button js-change-tab ${
                tab === 'rent' && 'offer_button--active'
              }`}
              onClick={() => setTab('rent')}
            >
              {translate('homePage.weOfferBlock.rent')}
            </button>
            <button
              className={`offer_button js-change-tab ${
                tab === 'shop' && 'offer_button--active'
              }`}
              onClick={() => setTab('shop')}
            >
              {translate('homePage.weOfferBlock.buy')}
            </button>

            <img
              src={Peanut}
              alt="peanut"
              className="offer_photo offer_photo--1"
            />
          </div>

          <div className="homepage-offerCard-wrapper">
            <OfferCard
              title={translate('homePage.weOfferBlock.storeWeOffers.title')}
              active={tab === 'store'}
              link="/przechowaj"
              defaultIndex={0}
              text={translate('homePage.weOfferBlock.storeWeOffers.btnText')}
            >
              <img
                src={Peanut}
                alt="peanut"
                className="offer_photo offer_photo--2"
              />
              <img
                src={Peanut}
                alt="peanut"
                className="offer_photo offer_photo--3"
              />

              <div className="z-index-1">
                {offerCardItems.store.map((item, index) => (
                  <OfferCardItem key={index} item={item} />
                ))}
              </div>
            </OfferCard>

            <OfferCard
              title={translate('homePage.weOfferBlock.moveWeOffers.title')}
              active={tab === 'move'}
              link="/przeprowadz"
              text={translate('homePage.weOfferBlock.moveWeOffers.btnText')}
            >
              <img
                src={Peanut}
                alt="peanut"
                className="offer_photo offer_photo--card-2"
              />
              <div className="z-index-1">
                {offerCardItems.move.map((item, index) => (
                  <OfferCardItem key={index} item={item} />
                ))}
              </div>
            </OfferCard>

            <OfferCard
              title={translate('homePage.weOfferBlock.rentWeOffers.title')}
              active={tab === 'rent'}
              link="/wynajem"
              text={translate('homePage.weOfferBlock.rentWeOffers.btnText')}
            >
              <img
                src={Peanut}
                alt="peanut"
                className="offer_photo offer_photo--4"
              />

              {offerCardItems.rent.map((item, index) => (
                <OfferCardItem key={index} item={item} />
              ))}
            </OfferCard>

            <OfferCard
              title={translate('homePage.weOfferBlock.shopWeOffers.title')}
              active={tab === 'shop'}
              link="/sklep"
              text={translate('homePage.weOfferBlock.shopWeOffers.btnText')}
            >
              <img
                src={Peanut}
                alt="peanut"
                className="offer_photo offer_photo--card-4"
              />
              {offerCardItems.shop.map((item, index) => (
                <OfferCardItem key={index} item={item} />
              ))}
            </OfferCard>
          </div>
        </div>
      </article>
    </>
  );
};

export default WeOfferBlock;
