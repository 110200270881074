import { FindBasketProductDto } from '../../../types/products/find-basket-product.dto';
import { FindStockDto } from '../../../types/stocks/find-stock.dto';

export const calculatePriceWithDiscount = ({
  price,
  discount = 0,
}: {
  price: number;
  discount?: number;
}): number => {
  const priceWithDiscount = (1 - discount / 100) * price;
  return priceWithDiscount;
};

export const calculateStoreBoxesPrice = (
  state: FindBasketProductDto[],
  discount?: number,
) => {
  return state.reduce((prev, next) => {
    const price =
      next.promotionPrice !== 0 ? next.promotionPrice : next.monthPrice;
    return prev + next.value * calculatePriceWithDiscount({ price, discount });
  }, 0);
};

export const calculateRentBoxesPrice = (
  state: FindBasketProductDto[],
  discount?: number,
) => {
  return state.reduce((prev, next) => {
    const price =
      next.promotionPrice !== 0 ? next.promotionPrice : next.weekPrice;
    return (
      prev +
      next.value * calculatePriceWithDiscount({ price: price / 5, discount })
    );
  }, 0);
};

export const calculateRentPackagePrice = (
  state: FindBasketProductDto[],
  rentTime: string,
) => {
  return state.reduce((prev, next) => {
    return (
      prev +
      next.value * next.promotionPrice * parseInt(rentTime.slice(0, 2)) +
      (next.weekPrice - next.promotionPrice) * next.value
    );
  }, 0);
};

/**
 * Wylicza cene pakietow zakladajac ze po zakonczeniu oplaty pierwszej pobiera cene regularna
 * Nie wiem dlaczego tak liczymy kupujac w cenie tygodniowej, potem cena po znizce hmmm
 * Tu sadze ze powinna byc promocja doliczona rowniez
 */
export const calculateRentPackageContinuationPrice = (
  state: FindBasketProductDto[],
) => {
  return state.reduce((prev, next) => {
    return parseFloat((prev + next.value * next.promotionPrice).toFixed(2));
  }, 0);
};

/**
 *
 * Calculate price in the basked for total price
 * @param state
 * @param discount it is set
 */
export const calculateShopPrice = (
  state: FindBasketProductDto[],
  discount: number,
) => {
  return state.reduce((totalPrice, product) => {
    const price =
      product.promotionPrice !== 0 ? product.promotionPrice : product.price;
    const productTotalPrice =
      product.value * calculatePriceWithDiscount({ price, discount });
    const totalPriceWithProduct = totalPrice + productTotalPrice;
    return parseFloat(totalPriceWithProduct.toFixed(2));
  }, 0);
};

export const calculateProductsQuantity = (state: FindBasketProductDto[]) => {
  return state.reduce((prev, next) => {
    return prev + next.value;
  }, 0);
};

export const calculateAdditionalStorePeriodPrice = (stocks: FindStockDto[]) => {
  return stocks
    .map((stock) => {
      return { ...stock.products[0], discount: stock.promotion?.discount || 0 };
    })
    .reduce((prev, next) => {
      const price =
        next.promotionPrice !== 0 ? next.promotionPrice : next.monthPrice;
      return parseFloat(
        prev +
          next.quantity *
            calculatePriceWithDiscount({ price, discount: next.discount }),
      );
    }, 0);
};
