import React, { useState } from 'react';
import './ReclamationTab.scss';
import Modal from '@component/Modal/Modal';
import { H2, H5 } from '@component/Text/Text';
import CompletedSentForm from '@form/CompletedSentForm/CompletedSentForm';
import { translate } from '@module/Translate/Translate';
import { FindUserDto } from '@rootTypes/users/find-user.dto';
import { ReclamationForm } from '@form/ReclamationForm/ReclamationForm';
interface Props {
  user: FindUserDto;
  setSpinner: (value: boolean) => void;
}

export default function ReclamationTab({ user, setSpinner }: Props) {
  const [formSubmit, setFormSubmit] = useState(false);

  return (
    <div className="reclamation-tab">
      <Modal
        showModal={formSubmit}
        setShowModal={setFormSubmit}
        className="dialog-ml"
      >
        <CompletedSentForm
          setShowConfirm={setFormSubmit}
          title={translate('reclamationTab.formAccepted')}
          message={translate('reclamationTab.message')}
        />
      </Modal>
      {!formSubmit && (
        <div className="support-header">
          <H2 className="support-header_title">reclamationTab.complaints</H2>
          <H5 className="support-header_subtitle">
            reclamationTab.damagedProduct
          </H5>
        </div>
      )}

      <ReclamationForm
        user={user}
        onHandleSubmit={() => setFormSubmit(true)}
        setSpinner={(value) => setSpinner(value)}
      />
    </div>
  );
}
