import React from 'react';
import './DeleteAddressForm.scss';
import { translate } from '../../Module/Translate/Translate';

export const DeleteAddressForm = ({
  item,
  setShowModal,
  removeAddress,
}: any) => {
  const removeCard = function () {
    removeAddress();
    setShowModal(false);
  };

  return (
    <div className="delete-address-form">
      <p className="delateAction__title mt-0">
        {translate('addressBlock.deleteAddressNotify')}
      </p>
      <p className="delateAction__text">
        {translate('addressBlock.deleteNotify')}
      </p>
      <div>
        <p className="deleteAction__addressInfo">
          {item.name} {item.restOfName || ''}
        </p>
        <p className="deleteAction__addressInfo">{item.street}</p>
        <p className="deleteAction__addressInfo">
          {item.postalCode} {item.city}
        </p>
        <button className="deleteAction__button mb-0" onClick={removeCard}>
          {translate('addressBlock.deleteAddress')}
        </button>
      </div>
    </div>
  );
};

export default DeleteAddressForm;
