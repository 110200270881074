import React, { useState } from 'react';
import CreateAddressItem from './components/CreateAddressItem/CreateAddressItem';
import AddressCardItem from './components/AddressCardItem/AddressCardItem';
import './AddressBlock.scss';
import P, { H1 } from '@component/Text/Text';
import { User, Address } from '@models/user.model';
import { translate } from '@module/Translate/Translate';
import {
  createAddressAction,
  deleteAddressAction,
  editAddressAction,
} from '@network/Actions/CurrentUser';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';

interface Props {
  user: User;
  refreshUser: () => void;
  addresses: any;
  setSpinner: (value: boolean) => void;
}

export const AddressBlock = ({
  user,
  refreshUser,
  addresses,
  setSpinner,
}: Props) => {
  const [showError, setShowError] = useState(false);

  const createNewAddress = function (item: Address) {
    setSpinner(true);
    createAddressAction(user.id, item)
      .then(() => {
        refreshUser();
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => setSpinner(false));
  };

  const removeAddress = function (id: number) {
    setSpinner(true);

    deleteAddressAction(user.id, id)
      .then(() => {
        refreshUser();
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => setSpinner(false));
  };

  const editAddress = function (id: number, item: Address) {
    setSpinner(true);
    editAddressAction(user.id, id, item)
      .then(() => {
        refreshUser();
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => setSpinner(false));
  };

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <div className="addressWrapper">
        <div className="container-header addressTitleWrapper">
          <H1 className="container-title d-none d-lg-block addressTitle">
            {translate('addressBlock.address')}
          </H1>
          <P className="container-subtitle addressText">
            {translate('addressBlock.changeAddresses')}
          </P>
        </div>

        <div className="pl-0 pr-0 w-100">
          <div className="container-fluid pl-0 pr-0">
            <div className="d-flex flex-wrap addressWrapper_row">
              <CreateAddressItem
                createNewAddress={createNewAddress}
                active={addresses.length < 5}
              />

              {addresses &&
                addresses.map((item: Address, index: number) => (
                  <AddressCardItem
                    key={index}
                    user={user}
                    item={item}
                    editAddress={(value: Address) =>
                      editAddress(item.id, value)
                    }
                    removeAddress={() => removeAddress(item.id)}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressBlock;
