const STEPPER_FORM_DATA_KEY = 'stepper-form-data';

export function setFormData(formData: unknown) {
  console.warn('setFormData', formData);
  sessionStorage.setItem(STEPPER_FORM_DATA_KEY, JSON.stringify(formData));
}

export function getFormData() {
  const rawFormData = sessionStorage.getItem(STEPPER_FORM_DATA_KEY);

  if (!rawFormData) {
    return [];
  }

  console.warn('getFormData', JSON.parse(rawFormData));
  return JSON.parse(rawFormData);
}

export function ClearFormData() {
  console.warn('ClearFormData');
  sessionStorage.removeItem(STEPPER_FORM_DATA_KEY);
}
