import React, { FC } from 'react';
import './PersonCardItem.scss';
import editIcon from '../../../../../../Asset/images/card/editIcon.png';
import ReactTooltip from 'react-tooltip';

interface IProps {
  title: any;
  data: any;
  buttonText?: any;
  e2e?: any;
  onClick?: any;
}

export const PersonCardItem: FC<React.PropsWithChildren<IProps>> = ({
  title,
  data,
  buttonText,
  e2e,
  onClick,
}: IProps) => {
  const rebuildToolTip = () => {
    ReactTooltip.rebuild();
  };
  const hideToolTip = () => {
    ReactTooltip.hide();
  };
  return (
    <>
      <div className="person-card-item">
        <div className="wrapper d-flex position-relative">
          <div className="w-100 position-relative">
            <img
              src={editIcon}
              alt="edit icon"
              className="d-block d-lg-none person-card-item_icon"
              data-e2e={`${e2e}onMobile`}
              onClick={onClick}
            />

            <div className="person-card-item_container">
              <p className="title address_item">{title}</p>
              <>
                <ReactTooltip id="show-email-tooltip" multiline={true} />
                <p
                  data-for="show-email-tooltip"
                  data-tip={data}
                  onLoad={rebuildToolTip}
                  onClickCapture={hideToolTip}
                  className="data address_item data-description"
                >
                  {data}
                </p>
              </>
            </div>

            <div className="d-none d-lg-block btn_placeholder" />

            {onClick && (
              <div className="d-none d-lg-block btn_wrapper w-100 position-absolute">
                <button
                  className="btn btn-dark btn-small"
                  data-e2e={e2e}
                  onClick={onClick}
                >
                  {buttonText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonCardItem;
