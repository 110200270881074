import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { ALL_USERS_FAIL, ALL_USERS_REQUEST, ALL_USERS_SUCCESS } from './Types';
import { http } from '../NetworkHTTP';
import { UpdateUserDto } from '../../../../types/users/update-user.dto';

export const getUsersAction = (
  params: any,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ALL_USERS_REQUEST,
      });

      const { data } = await http.get(
        `users?&role=${params.role}&take=${params.rows}&skip=${params.first}${
          params.sortField != '' || params.sortOrder
            ? `&sortField=${params.sortField}&sortOrder=${params.sortOrder}`
            : ``
        }${params.isArchived ? `&isArchived=${params.isArchived}` : ''}${
          typeof params.filter === 'string' && params.filter !== ''
            ? `&filter=${params.filter}`
            : ''
        }`,
      );

      dispatch({
        type: ALL_USERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_USERS_FAIL,
        payload: 'Nie udało się pobrać uzytkownikow.',
      });
    }
  };
};
export const getAllUserByType = (
  params: any,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ALL_USERS_REQUEST,
      });

      const { data } = await http.get(`users/all`, {
        params: {
          accountType: params.accountType,
          take: params.rows,
          skip: params.first,
          sortOrder: params.sortOrder,
          sortField: params.sortField,
          find: params.filter,
        },
      });

      dispatch({
        type: ALL_USERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_USERS_FAIL,
        payload: 'Nie udało się pobrać uzytkownikow.',
      });
    }
  };
};
export async function editUserAction(id: number, user: UpdateUserDto) {
  return await http.patch(`users/${id}`, user);
}

export async function getGusCompanyDetails(taxNumber: string) {
  const { data } = await http.post(`gus/company/${taxNumber}`);
  return data;
}

export const getLoginAttempts = async (params: any) => {
  const { data } = await http.get(
    `login-attempts?&take=${params.rows}&skip=${params.first}${
      params.sortField != '' || params.sortOrder
        ? `&sortField=${params.sortField}&sortOrder=${params.sortOrder}`
        : ``
    }${params.value ? `&value=${params.value}` : ''}`,
  );
  return data;
};

export async function deleteUserAction() {
  return await http.post(`users/deleteUser`);
}

export async function getLastLoginAttempts(email: string) {
  return await http.get(`login-attempts/last-attempts?&email=${email}`);
}
