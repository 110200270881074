import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from './Types';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { http } from '@network/NetworkHTTP';
import { AuthValidation } from '@rootTypes/auth/auth-validation.enum';
import { requestPermission } from '@src/firebaseInit';
import { RootState } from '@src/store';
import { removeNotifications } from './Notifications';
import { FacebookGoogleEvents } from '@rootTypes/auth/facebook-google-auth.enum';
import { currentUserAction } from './CurrentUser';

export const logoutAction = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      const { data } = await http.get('/auth/logout');

      //@ts-ignore
      if (
        //@ts-ignore
        window.isWebViewClient === true ||
        //@ts-ignore
        window.window.isWebViewIOSClient === true
      )
        //@ts-ignore
        window.ReactNativeWebView.postMessage(FacebookGoogleEvents.LOGOUT); // logout mobile device
      if (data === 'Success logout') {
        dispatch({
          type: USER_LOGOUT,
          payload: {},
        });
        dispatch(removeNotifications());
      }
    } catch (err) {}
  };
};

export const externalUserLoginAction = (
  access_token: string,
  url: string,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const { data } = await http.post(url, { access_token });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });

      return data;
    } catch (error) {
      onError();
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error,
      });
    }
  };
};

export const loginAction = (
  endpoint: string,
  values: any,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    await http
      .post(endpoint, {
        email: values.email,
        password: values.password,
      })
      .then((res: any) => {
        if (!res.data) {
          if (res.response.status >= 500) {
            onError();
            dispatch({
              type: USER_LOGIN_FAIL,
              payload: { message: AuthValidation.SERVER_ERROR },
            });
          } else {
            dispatch({
              type: USER_LOGIN_FAIL,
              payload: res.response.data.error,
            });
          }
        } else {
          requestPermission(values.email);
          dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: res.data,
          });
        }
      })
      .catch((err: any) => {
        if (err.response) {
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: err.response.data.message.error,
          });
        } else {
          onError();
          dispatch({
            type: USER_LOGIN_FAIL,
            payload: { message: AuthValidation.SERVER_ERROR },
          });
        }
      });
  };
};
export const handleAppleLogin = (
  access_token: string,
  user?: { firstName: string; lastName: string },
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: USER_LOGIN_REQUEST,
      });

      const { data } = await http.post('auth/login/apple', {
        access_token,
        user: user && user,
      });

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
      if (data) dispatch(currentUserAction());
      return data;
    } catch (error) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: error,
      });
    }
  };
};
