import React, { useEffect, useState } from 'react';
import RegistrationForm from '@form/RegistrationForm/RegistrationForm';
import { H2 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import logo from '@images/logo.png';
import './RegistrationUser.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  externalUserLoginAction,
  handleAppleLogin,
  logoutAction,
} from '@network/Actions/Login';
import { RootState } from '@/src/store';
import { registrationAction } from '@network/Actions/Registration';
import { useHistory } from 'react-router';
import getApiEndPoint from '@network/NetworkApiEndpoint';
import { currentUserAction } from '@network/Actions/CurrentUser';
import { UserState } from '@type/Custom';
import { FindUserDto } from '@rootTypes/users/find-user.dto';
import { ExternalLoginContext } from '../LoginUser/LoginUser';
import { updateUserGroupByTokenAction } from '@network/Actions/PushNotifications';
import { FacebookGoogleEvents } from '@rootTypes/auth/facebook-google-auth.enum';
import { signUpEvent } from '@src/GTM/GTM';
import { EMethodType } from '@rootTypes/GTM/method-type.enum';
import { AccountTypes } from '@rootTypes/orders/account-type.enum';

interface IProps {
  changeAuthentication: any;
  showError: (value: boolean) => void;
}

const RegistrationUser = ({ changeAuthentication, showError }: IProps) => {
  const dispatch = useDispatch();
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const history = useHistory();
  const [validation, setValidation] = useState('');

  useEffect(() => {
    const handleMobileMessage = (e: any) => {
      try {
        const fromWebView = JSON.parse(e.data);
        if (
          fromWebView.evetnType === FacebookGoogleEvents.FACEBOOK_REGISTER &&
          fromWebView.token !== ''
        )
          onLoginExternalUser(fromWebView.token, 'facebook');
        if (
          fromWebView.evetnType === FacebookGoogleEvents.GOOGLE_REGISTER &&
          fromWebView.token !== ''
        )
          onLoginExternalUser(fromWebView.token, 'google');
        if (fromWebView.evetnType === FacebookGoogleEvents.APPLE_REGISTER) {
          const appleResponse = JSON.parse(fromWebView.response);
          if (appleResponse.user) {
            dispatch(
              handleAppleLogin(appleResponse.id_token, appleResponse.user),
            );
          } else {
            dispatch(handleAppleLogin(appleResponse.id_token));
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (
      //@ts-ignore
      window.isWebViewClient === true
    ) {
      document.addEventListener('message', handleMobileMessage);
    } else if (
      //@ts-ignore
      window.window.isWebViewIOSClient === true
    ) {
      //@ts-ignore
      window.addEventListener('message', handleMobileMessage);
    }
    return () => {
      if (
        //@ts-ignore
        window.isWebViewClient === true
      ) {
        document.removeEventListener('message', handleMobileMessage);
      } else if (
        //@ts-ignore
        window.window.isWebViewIOSClient === true
      ) {
        //@ts-ignore
        window.removeEventListener('message', handleMobileMessage);
      }
    };
  }, []);

  async function onRegisterUser(values: any) {
    signUpEvent(
      values.isCompanyAccount ? AccountTypes.COMPANY : AccountTypes.PRIVATE,
      EMethodType.normal,
    );
    new Promise(() => {
      dispatch(registrationAction(values));
    }).then(async (res) => {
      const PushNotificationToken: string | null = localStorage.getItem(
        'PushNotificationToken',
      );
      if (PushNotificationToken && res) {
        return await updateUserGroupByTokenAction(PushNotificationToken);
      }
    });
  }

  const handleLogout = async (): Promise<any> => {
    dispatch(logoutAction());
  };

  const isEmpty = (obj: object | any) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    const user: FindUserDto = userData.user;
    const error: any = userData.error;

    if (!isEmpty(user) && !user.isActive) {
      history.push('/confirmEmail', user.email);
    } else if (error) {
      setValidation(error);
    }
  }, [userData]);

  const onLoginExternalUser = async (
    token: string,
    endpoint: string,
  ): Promise<any> => {
    const url = getApiEndPoint(endpoint);
    const data: any = await dispatch(
      externalUserLoginAction(token, url, error),
    );
    if (data === 'Success') {
      await dispatch(currentUserAction(error));
      history.push('/panel-klienta');
    } else {
      //its handled in use effect
    }
  };
  const error = () => {
    showError(true);
  };

  return (
    <>
      <React.Fragment>
        <ExternalLoginContext.Provider value={onLoginExternalUser}>
          <div className="registration-panel flex-wrap row">
            <div className="registration-panel_left-container d-none d-md-block col-md-6">
              <H2 className="login-column_label text-center pb-2 pb-md-3 pb-lg-4">
                {translate('registrationUser.loginPanel.title')}
              </H2>

              <div className="form-group">
                <label
                  htmlFor="#"
                  className="d-none d-md-block text-primary font-weight-bold"
                >
                  &nbsp;
                </label>

                {!userData.user?.email ? (
                  <button
                    className="btn w-100 py-3"
                    onClick={() => changeAuthentication('login')}
                  >
                    {translate('registrationUser.loginPanel.logined')}
                  </button>
                ) : (
                  <button
                    className="login-column_button btn w-100 py-3"
                    onClick={handleLogout}
                  >
                    {translate('registrationUser.loginPanel.logout')}
                  </button>
                )}
              </div>
            </div>

            <div className="registration-panel_right-container col-md-6">
              <div className="dividing-line d-block d-md-none" />

              <div className="logo-wrapper">
                <img className="logo-wrapper_logo" src={logo} alt="logo" />
              </div>
              <H2 className="registration-column_label text-center pb-2 pb-md-3 pb-lg-4">
                {translate('registrationUser.registrationPanel.title')}
              </H2>
              <div className="registration-column_subtitle text-center">
                {translate('registrationUser.registrationPanel.possibleOnly')}
              </div>
              <RegistrationForm
                onSubmit={onRegisterUser}
                validation={validation}
              />
            </div>
          </div>
        </ExternalLoginContext.Provider>
      </React.Fragment>
    </>
  );
};

export default RegistrationUser;
