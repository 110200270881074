import {
  ORDER_BLOCKED_DAYS_FULFILLED,
  ORDER_BLOCKED_DAYS_PENDING,
  ORDER_BLOCKED_DAYS_REJECTED,
} from '../Network/Actions/Types';
import { OrderBlockedDaysState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: any;
}
export const orderBlockedDatesReducer = (
  state: OrderBlockedDaysState = {
    loading: false,
    dates: [],
    error: null,
    deleteText: 'Delete',
  },
  action: Action,
) => {
  switch (action.type) {
    case ORDER_BLOCKED_DAYS_PENDING:
      return {
        loading: true,
        dates: state.dates,
        error: null,
        deleteText: 'Delete',
      };
    case ORDER_BLOCKED_DAYS_FULFILLED:
      return {
        loading: false,
        dates: action.payload,
        error: null,
        deleteText: 'Delete',
      };
    case ORDER_BLOCKED_DAYS_REJECTED:
      return {
        loading: false,
        dates: state.dates,
        error: action.payload,
        deleteText: 'Delete',
      };
    default:
      return state;
  }
};
