import React, { useRef } from 'react';
import './EditLimits.scss';
import { useFormik } from 'formik';
import { FormFieldFormik } from '../../../../../Component/FormFieldFormik/FormFieldFormik';
import { editLimitAction } from '../../../../../Network/Actions/Limits';
import { translate } from '../../../../../Module/Translate/Translate';
import { Dropdown } from 'primereact/dropdown';
import { ProductType } from '../../../../../../../types/products/product-type.enum';
import { Messages } from 'primereact/messages';

const statuses = [
  { label: translate('adminTool.limits.statusActive'), value: true },
  { label: translate('adminTool.limits.statusUnActive'), value: false },
];

const types = [
  { label: translate('adminTool.products.rent'), value: ProductType.rent },
  {
    label: translate('adminTool.products.storage'),
    value: ProductType.storage,
  },
  { label: translate('adminTool.products.shop'), value: ProductType.store },
];

const EditLimit = ({ limit, setRefresh, editor }: any): JSX.Element => {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      defaultAmount: limit ? limit.defaultAmount : 0,
      maxAmount: limit ? limit.maxAmount : 0,
      status: limit ? limit.status : false,
      type: limit ? limit.type : null,
      updatedBy: editor.email,
    },
    onSubmit: (values: any) => {
      editLimitAction(limit.id, {
        defaultAmount: values.defaultAmount,
        maxAmount: values.maxAmount,
        status: values.status,
        type: values.type,
        updatedBy: values.updatedBy,
      })
        .then(() => {
          setRefresh(true);
          showMessage('success', translate('adminTool.limits.updateMessage'));
        })
        .catch((error: any) => {
          showMessage('error', error);
        });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const checkIsValid = (values: any): boolean => {
    for (const key in values) {
      if (values[key] === null || values[key] === '') return false;
    }
    return true;
  };

  return (
    <div className="limits-main-container border-details">
      <Messages ref={message} />
      <form className="limits-form" onSubmit={formik.handleSubmit}>
        <FormFieldFormik
          name="defaultAmount"
          type="number"
          onChange={formik.handleChange}
          value={formik.values.defaultAmount}
          label={translate('adminTool.limits.defaultAmount')}
          onBlur={formik.handleBlur}
        />
        {formik.errors.defaultAmount ? (
          translate('errors.fieldRequired')
        ) : (
          <div className="notifications-feedback">{}</div>
        )}
        <FormFieldFormik
          name="maxAmount"
          type="number"
          onChange={formik.handleChange}
          value={formik.values.maxAmount}
          label={translate('adminTool.limits.maxAmount')}
          onBlur={formik.handleBlur}
        />
        {formik.errors.maxAmount ? (
          translate('errors.fieldRequired')
        ) : (
          <div className="notifications-feedback">{}</div>
        )}
        <Dropdown
          value={formik.values.status}
          options={statuses}
          className="type-dropdown"
          name="status"
          optionLabel="label"
          optionValue="value"
          onChange={formik.handleChange}
          placeholder={translate('adminTool.limits.descriptionSelect')}
          itemTemplate={(option) => {
            return <span>{option.label}</span>;
          }}
        />
        {formik.errors.status ? (
          translate('errors.fieldRequired')
        ) : (
          <div className="notifications-feedback">{}</div>
        )}
        <Dropdown
          value={formik.values.type}
          options={types}
          className="type-dropdown"
          name="type"
          optionLabel="label"
          optionValue="value"
          onChange={formik.handleChange}
          placeholder={translate('adminTool.products.descriptionTypeSelect')}
          itemTemplate={(option) => {
            return <span>{option.label}</span>;
          }}
        />
        {formik.errors.type ? (
          translate('errors.fieldRequired')
        ) : (
          <div className="notifications-feedback">{}</div>
        )}
        <button
          type="submit"
          className={`submitForm invoice__button m-auto button_accept ${
            checkIsValid(formik.values) ? '' : 'inactive'
          }`}
        >
          {translate('adminTool.limits.btn')}
        </button>
      </form>
    </div>
  );
};

export default EditLimit;
