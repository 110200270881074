import {
  COMPLAINTS_FAIL,
  COMPLAINTS_REQUEST,
  COMPLAINTS_SUCCESS,
} from '../Network/Actions/Types';
import { ComplaintsState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: Record<string, unknown>;
}
export const complaintsReducer = (
  state: ComplaintsState = {
    loading: false,
    complaints: { items: [], meta: { totalItems: 0 } },
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case COMPLAINTS_REQUEST:
      return { loading: true, complaints: state.complaints, error: null };
    case COMPLAINTS_SUCCESS:
      return { loading: false, complaints: action.payload, error: null };
    case COMPLAINTS_FAIL:
      return {
        loading: false,
        complaints: state.complaints,
        error: action.payload,
      };
    default:
      return state;
  }
};
