import React from 'react';
import { FindImageDto } from '../../../../../../../../types/product-images/find-image.dto';
import { ImageTypeEnum } from '../../../../../../../../types/product-images/image-type.enum';
import './Gallery.scss';
import { translate } from '../../../../../../Module/Translate/Translate';

interface Props {
  images: FindImageDto[];
  removeImage: any;
  productId: number;
}

const labelChecker = (type: ImageTypeEnum) => {
  switch (type) {
    case ImageTypeEnum.carousel:
      return translate('adminTool.products.carousel');
    case ImageTypeEnum.gallery:
      return translate('adminTool.products.gallery');
    case ImageTypeEnum.miniature:
      return translate('adminTool.products.miniature');
    case ImageTypeEnum.offer:
      return translate('adminTool.products.offer');
    case ImageTypeEnum.store:
      return translate('adminTool.products.shop');
  }
};

export default function Gallery({ images, removeImage, productId }: Props) {
  return (
    <div>
      <div className="gallery-container">
        {images &&
          images.map((image: FindImageDto, index: number) => {
            const imgEl = (
              <div className="gallery-item">
                <div className="gallery-image-container">
                  <img
                    alt="galleryImage"
                    src={image.url}
                    style={{
                      cursor: 'pointer',
                      width: '100px',
                      height: '100px',
                    }}
                  />
                  <p>{`${translate(
                    'adminTool.products.imageType',
                  )} ${labelChecker(image.type)}`}</p>
                </div>
                <i
                  className="pi pi-times description-remove-icon"
                  onClick={() => removeImage(image.id, productId)}
                ></i>
              </div>
            );
            return <div key={index}>{imgEl}</div>;
          })}
      </div>
    </div>
  );
}
