import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import notFoundImage from '@images/shop/image-not-availableLight.png';
import './ProductDetailPages.scss';
import ArrowBack from '@images/shop/arrow-back.png';
import { useDispatch, useSelector } from 'react-redux';
import MainTemplate from '@component/MainTemplate/MainTemplate';
import { translate } from '@module/Translate/Translate';
import {
  removeProductFromBasket,
  addProductInBasket,
  decreaseProductInBasket,
} from '@network/Actions/Basket';
import { getProductsByPathAction } from '@network/Actions/Products';
import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '@rootTypes/default-settings/default-settings-type.enum';
import { DescriptionTypeEnum } from '@rootTypes/product-descriptions/description-type.enum';
import { ImageTypeEnum } from '@rootTypes/product-images/image-type.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { RootState } from '@src/store';
import { ProductsState, DefaultSettingsState, BasketState } from '@type/Custom';
import Counter from '../Counter/Counter';
import { toast, ToastContainer } from 'react-toastify';
import { ShopMessage } from '../Toastify/Toastify';
import { Helmet } from 'react-helmet';

export default function ProductDetailPages() {
  const { productPath } = useParams<{ productPath: string }>();
  useEffect(() => {
    dispatch(getProductsByPathAction(productPath, ProductType.store));
  }, []);

  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );

  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  const defaultCountForShop =
    defaultSettings.find(
      (setting) => setting.type === DefaultSettingsTypeEnum.defaultCountForShop,
    )?.limit || UndefinedSettingsType.undefinedCountForShop;

  const [isMatches, setIsMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia('(min-width: 1200px)');
    if (media.matches !== isMatches) {
      setIsMatches(media.matches);
    }
    const listener = () => {
      setIsMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [isMatches]);

  const history = useHistory();

  const stateBasket = useSelector<RootState, BasketState>(
    ({ basket }) => basket,
  );
  const dispatch = useDispatch();
  const [product, setProduct] = useState<null | any>(null);
  useEffect(() => {
    products.find((item) => {
      if (item.pathUrl === productPath) {
        setProduct(item);
      }
    });
  }, [productPath, products]);

  const handleClick = () => {
    history.push('/sklep');
  };

  const [value, setValue] = useState(0);

  const filteredItem =
    product &&
    stateBasket.products.find(
      (item) =>
        item.type === product.type &&
        item.group === product.group &&
        item.id === product.id,
    );

  useEffect(() => {
    filteredItem ? setValue(filteredItem.value) : setValue(0);
  }, [filteredItem]);

  const onCounterIncreaseClick = (value: any) => {
    setValue(value);
  };

  const onCounterDecreaseClick = (value: any) => {
    if (value === 0) dispatch(removeProductFromBasket(product));
    setValue(value);
  };

  const onHandleClick = () => {
    if (filteredItem) {
      if (filteredItem.value < value) {
        dispatch(
          addProductInBasket({
            data: { ...product, value },
            stepValueCounter: value - filteredItem.value,
          }),
        );
      } else if (filteredItem.value > value) {
        dispatch(
          decreaseProductInBasket(
            { ...product, value },
            filteredItem.value - value,
          ),
        );
      } else if (filteredItem.value === value) {
        return;
      }
    } else {
      dispatch(
        addProductInBasket({
          data: { ...product, value },
        }),
      );
    }

    toast(<ShopMessage />, {
      closeOnClick: true,
      containerId: 'shop-product',
      autoClose: 1500,
    });
  };

  const showProductPrices = () => {
    return (
      <div>
        {product.promotionPrice > 0 ? (
          <>
            <span className="old-price">
              {product.price} {translate('default.currency')}
            </span>
            &nbsp;
            {product.promotionPrice} {translate('default.currency')}
          </>
        ) : (
          <>
            {product.price} {translate('default.currency')}
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <title>
          Kup teraz w{' '}
          {product
            ? 'naszym sklepie: ' + product.name
            : window.location.pathname.substring(1)}{' '}
          | skitraj.pl
        </title>
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="description"
          content="Wszystkie najważniejsze przedmioty do przechowywania rzeczy oraz przeprowadzek kupisz w naszym sklepie!"
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MainTemplate>
        {isMatches
          ? product && (
              <div className="product-detail">
                <ToastContainer
                  className="shop-toast"
                  position="top-center"
                  closeOnClick
                  autoClose={1500}
                  containerId="shop-product"
                  enableMultiContainer
                />
                <div className="product-detail_info">
                  <img
                    src={
                      product.images.find(
                        (image: any) => image.type === ImageTypeEnum.miniature,
                      )?.url || notFoundImage
                    }
                    className="product-detail_info-img"
                    alt={product.name}
                  />

                  <div className="product-detail-buy">
                    <div className="product-detail-buy_name">
                      <h1 className="buy-name">{product.name}</h1>
                      <p className="buy-price">{showProductPrices()}</p>
                    </div>
                    <div className="buy-description">
                      <p className="description-vat">
                        {translate('productPage.description-vat')}
                      </p>
                      <p className="description-text">
                        {
                          product.descriptions.find(
                            (description: any) =>
                              description.type === ProductType.store,
                          )?.text
                        }
                      </p>
                    </div>
                    <div className="byu-component">
                      <Counter
                        onCounterIncreaseClick={(value) =>
                          onCounterIncreaseClick(value)
                        }
                        onCounterDecreaseClick={(value) =>
                          onCounterDecreaseClick(value)
                        }
                        counterValue={value}
                        stepValueCounter={defaultCountForShop}
                      />
                      <button
                        type="button"
                        className="add-btn btn"
                        onClick={() => onHandleClick()}
                        disabled={value === 0}
                      >
                        {translate('productPage.addButton')}
                      </button>
                    </div>
                    <div className="byu-dispatch">
                      <p className="byu-dispatch_title">
                        {translate('productPage.dispatchTitle')}
                      </p>
                      <p className="byu-dispatch_text">
                        {translate('productPage.dispatchSubTitle')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-detail_size">
                  <img
                    src={
                      product.images.find(
                        (image: any) => image.type === ImageTypeEnum.gallery,
                      )?.url || notFoundImage
                    }
                    alt={product.name}
                  />
                  <div className="size-text">
                    <p
                      className="text-dimension"
                      dangerouslySetInnerHTML={{
                        __html: product.descriptions.find(
                          (description: any) =>
                            description.type === DescriptionTypeEnum.carousel,
                        )?.text,
                      }}
                    ></p>
                  </div>
                </div>
                {product.descriptions.find(
                  (description: any) =>
                    description.type === DescriptionTypeEnum.offer,
                ) && (
                  <div className="product-detail_description">
                    <img
                      src={
                        product.images.find(
                          (image: any) => image.type === ImageTypeEnum.store,
                        )?.url || notFoundImage
                      }
                      alt={product.name}
                    />
                    <div className="description-text">
                      <p className="title">
                        {translate('productPage.descriptionTitle')}
                      </p>
                      <p className="text">
                        {
                          product.descriptions.find(
                            (description: any) =>
                              description.type === DescriptionTypeEnum.offer,
                          )?.text
                        }
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )
          : product && (
              <div className="product-detail">
                <div className="product-detail_info">
                  <button
                    type="button"
                    className="shop-button-back"
                    onClick={handleClick}
                  >
                    <img src={ArrowBack} alt="arrow back" />
                  </button>
                  <img
                    src={
                      product.images.find(
                        (image: any) => image.type === ImageTypeEnum.miniature,
                      )?.url || notFoundImage
                    }
                    alt={product.name}
                    className="product-detail-image"
                  />
                  <div className="product-detail-buy">
                    <div className="product-detail-buy_name">
                      <h1 className="buy-name">{product.name}</h1>
                      <p className="buy-price">{showProductPrices()}</p>
                    </div>

                    <div className="byu-component">
                      <Counter
                        onCounterIncreaseClick={(value) =>
                          onCounterIncreaseClick(value)
                        }
                        onCounterDecreaseClick={(value) =>
                          onCounterDecreaseClick(value)
                        }
                        counterValue={value}
                        stepValueCounter={defaultCountForShop}
                      />
                      <button
                        type="button"
                        className="add-btn btn"
                        onClick={() => onHandleClick()}
                        disabled={value === 0}
                      >
                        {translate('productPage.addButton')}
                      </button>
                    </div>
                    <div className="product-detail-buy_name d-block d-lg-none w-100">
                      <h1 className="buy-name text-center">{product.name}</h1>
                    </div>
                    <div className="buy-description">
                      <p className="description-vat">
                        {translate('productPage.description-vat')}
                      </p>
                      <p className="description-text">
                        {
                          product.descriptions.find(
                            (description: any) =>
                              description.type === ProductType.store,
                          )?.text
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div className="product-detail_size">
                  <img
                    src={
                      product.images.find(
                        (image: any) => image.type === ImageTypeEnum.gallery,
                      )?.url || notFoundImage
                    }
                    alt={product.name}
                  />
                  <div className="size-text">
                    <p
                      className="text-dimension"
                      dangerouslySetInnerHTML={{
                        __html: product.descriptions.find(
                          (description: any) =>
                            description.type === DescriptionTypeEnum.carousel,
                        )?.text,
                      }}
                    ></p>
                  </div>
                </div>
                {product.descriptions.find(
                  (description: any) =>
                    description.type === DescriptionTypeEnum.offer,
                ) && (
                  <div>
                    <div className="product-detail_description">
                      <div className="description-text">
                        <p className="title">
                          {translate('productPage.descriptionTitle')}
                        </p>
                        <p className="text">
                          {
                            product.descriptions.find(
                              (description: any) =>
                                description.type === DescriptionTypeEnum.offer,
                            )?.text
                          }
                        </p>
                      </div>
                    </div>
                    <div className="separator w-100 d-block d-lg-none" />
                  </div>
                )}
                <div className="byu-dispatch">
                  <p className="byu-dispatch_title">
                    {translate('productPage.dispatchTitle')}
                  </p>
                  <p className="byu-dispatch_text">
                    {translate('productPage.dispatchSubTitle')}
                  </p>
                </div>
              </div>
            )}
      </MainTemplate>
    </div>
  );
}
