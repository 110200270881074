import React, { useState } from 'react';
import './CompanyNameForm.scss';
import FormField from '@component/FormField/FormField';
import P, { H3, H5 } from '@component/Text/Text';
import { User } from '@models/user.model';
import { translate } from '@module/Translate/Translate';
import {
  editUserCompanyDetailsAction,
  editUserPersonalDetailsAction,
} from '@network/Actions/CurrentUser';
import { isEmpty } from '@util/isEmpty';
interface IProps {
  setShowModal: (value: boolean) => void;
  user: User;
  refreshUser: () => void;
  setSpinner: (value: boolean) => void;
  setError: (value: boolean) => void;
}
const CompanyName = ({
  setShowModal,
  user,
  refreshUser,
  setSpinner,
  setError,
}: IProps) => {
  const [formDataCompany, setFormDataCompany] = useState({
    name: user.companyDetails?.name,
    restOfName: user.companyDetails?.restOfName || '',
  });

  const [formDataPersonal, setFormDataPersonal] = useState({
    firstName: user.personalDetails?.firstName,
    lastName: user.personalDetails?.lastName,
  });

  const closeModalAndRefresh = () => {
    refreshUser();
    setShowModal(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSpinner(true);
    if (user.isCompanyAccount) {
      editUserCompanyDetailsAction(
        user.id,
        user.companyDetails?.id,
        formDataCompany,
      )
        .then(() => {
          closeModalAndRefresh();
        })
        .catch(() => {
          setError(true);
          setShowModal(false);
        })
        .finally(() => setSpinner(false));
    } else {
      editUserPersonalDetailsAction(
        user.id,
        user.personalDetails?.id,
        formDataPersonal,
      )
        .then(() => {
          closeModalAndRefresh();
        })
        .catch(() => {
          setError(true);
          setShowModal(false);
        })
        .finally(() => setSpinner(false));
    }
  };

  return (
    <div className="modalwrapper">
      <H3 className="modal-title">
        {user.isCompanyAccount
          ? 'dashboard.companyNameForm.companyName'
          : 'dashboard.companyNameForm.personalName'}
      </H3>
      <P className="modal-text">
        {user.companyDetails
          ? 'dashboard.companyNameForm.prevCompanyName'
          : 'dashboard.companyNameForm.prevName'}
      </P>
      <H5 className="modal-name">
        {user.isCompanyAccount
          ? `${user.companyDetails?.name} ${
              user.companyDetails?.restOfName || ''
            }`
          : `${user.personalDetails?.firstName} ${user.personalDetails?.lastName}`}
      </H5>
      <form>
        <FormField
          label={
            user.isCompanyAccount
              ? translate('dashboard.companyNameForm.companyName')
              : translate('dashboard.companyNameForm.name')
          }
          name={
            user.isCompanyAccount
              ? formDataCompany.name
              : formDataPersonal.firstName
          }
          required={true}
          onChange={(e: any) =>
            user.isCompanyAccount
              ? setFormDataCompany({
                  ...formDataCompany,
                  name: e.target.value,
                })
              : setFormDataPersonal({
                  ...formDataPersonal,
                  firstName: e.target.value,
                })
          }
        />
        <FormField
          label={
            user.isCompanyAccount
              ? translate('dashboard.companyNameForm.restOfName')
              : translate('dashboard.companyNameForm.lastName')
          }
          name={
            user.isCompanyAccount
              ? formDataCompany.restOfName || ''
              : formDataPersonal.lastName
          }
          onChange={(e: any) =>
            user.isCompanyAccount
              ? setFormDataCompany({
                  ...formDataCompany,
                  restOfName: e.target.value,
                })
              : setFormDataPersonal({
                  ...formDataPersonal,
                  lastName: e.target.value,
                })
          }
        />

        <button
          className="btn btn-100"
          onClick={handleSubmit}
          disabled={
            user.isCompanyAccount
              ? !formDataCompany.name
              : !isEmpty(formDataPersonal, 'restOfName')
          }
        >
          {translate('dashboard.companyNameForm.buttonSave')}
        </button>
      </form>
    </div>
  );
};

export default CompanyName;
