import React from 'react';
import './DeleteUserForm.scss';
import { H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { deleteUserAction } from '@network/Actions/Users';
import { useDispatch } from 'react-redux';
import { logoutAction } from '@network/Actions/Login';

interface IProps {
  setShowModal: (setModal: boolean) => void;
  message: string;
  setSpinner: (setModal: boolean) => void;
  showError: (setModal: boolean) => void;
}

function DeleteUserForm({
  setShowModal,
  message,
  setSpinner,
  showError,
}: IProps) {
  const dispatch = useDispatch();
  return (
    <>
      <div className="delete-user-form">
        <H3 className="delete-user-form_text">{message}</H3>
        <div className="buttons-container">
          <button
            type="submit"
            className="btn btn-large btn-100 button-accept"
            onClick={() => {
              setSpinner(true);
              deleteUserAction()
                .then(() => dispatch(logoutAction()))
                .catch(() => showError(true))
                .finally(() => {
                  setSpinner(false);
                  setShowModal(false);
                });
            }}
          >
            {translate('supportTabBlock.btnAccept')}
          </button>
          <button
            type="submit"
            className="btn btn-large btn-100 btn-dark button-cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            {translate('supportTabBlock.btnClose')}
          </button>
        </div>
      </div>
    </>
  );
}

export default DeleteUserForm;
