import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

import Comments from './components/Comments/Comments';
import Users from './components/Users/Users';
import Reviews from './components/Reviews/Reviews';

import AdminPanelTemplate from '../../Component/AdminPanelTemplate/AdminPanelTemplate';
import AdminToolSidebar from '../../Component/AdminToolSidebar/AdminToolSidebar';
import './AdminTool.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../Type/Custom';
import Products from './components/Products/Products';
import Complaints from './components/Complaints/Complaints';
import TransportPrice from './components/TransportPrice/TransportPrice';
import DefaultSettings from './components/DefaultSettings/DefaultSettings';
import Rents from './components/Rents/Rents';
import OrderBlockedDays from './components/OrderBlockedDays/OrderBlockedDays';
import Limits from './components/Limits/Limits';
import { checkIsAllowed } from '../../Util/UserValidation';
import Storages from './components/Storages/Storages';
import Policies from './components/Policies/Policies';
import Payments from './components/Payments/Payments';
import Sells from './components/Sells/Sells';
import Orders from './components/Orders/Orders';
import DeliveryStatuses from './components/DeliveryStatuses/DeliveryStatuses';
import PushNotificationsPanel from './components/Push-notifications/PushNotificationsPanel';
import CompanyUsers from './components/Users/CompanyUsers';
import PrivateUsers from './components/Users/PrivateUsers';
import { Helmet } from 'react-helmet';
import ArchivedUsers from './components/Users/ArchivedUsers';
import LoginAttempts from './components/Users/LoginAttempts';
import Promotions from './components/Promotions/Promotions';

const AdminTool = ({ currentPanel }: any) => {
  const history = useHistory();
  const location = useLocation();
  const { state = { activePanel: currentPanel || 'orders' } }: any =
    useLocation();
  const userData = useSelector<RootState, UserState>((state) => state.user);

  useEffect(() => {
    //@todo temp redirect to access denied page
    const user: any = userData?.user;
    if (!checkIsAllowed(user)) {
      history.push('/page-access-denied');
    }
  }, [userData]);
  // page on new URL
  const changeUrl = true;
  const setActivePanel = useCallback(
    (item: any) => {
      if (changeUrl) {
        history.push(item.href);
      } else {
        history.push({ ...location, state: { activePanel: item.template } });
      }
    },
    [history, location],
  );

  const renderActivePanel = function () {
    switch (state.activePanel) {
      case 'comments':
        return <Comments />;
      case 'users':
        return <Users roles={userData.user.roles} user={userData.user} />;
      case 'orders':
        return <Orders user={userData.user} />;
      case 'archived-user':
        return <ArchivedUsers user={userData.user} />;
      case 'private-users':
        return <PrivateUsers user={userData.user} />;
      case 'company-users':
        return <CompanyUsers user={userData.user} />;
      case 'login-attempts':
        return <LoginAttempts />;
      case 'reviews':
        return <Reviews user={userData.user} />;
      case 'products':
        return <Products user={userData.user} />;
      case 'push-notifications':
        return <PushNotificationsPanel />;
      case 'complaints':
        return <Complaints user={userData.user} />;
      case 'transport':
        return <TransportPrice user={userData.user} />;
      case 'defaultSettings':
        return <DefaultSettings user={userData.user} />;
      case 'deliveryStatuses':
        return <DeliveryStatuses user={userData.user} />;
      case 'rents':
        return <Rents user={userData.user} />;
      case 'sells':
        return <Sells user={userData.user} />;
      case 'orderBlockedDays':
        return <OrderBlockedDays user={userData.user} />;
      case 'limits':
        return <Limits user={userData.user} />;
      case 'storages':
        return <Storages user={userData.user} />;
      case 'policies':
        return <Policies user={userData.user} />;
      case 'payments':
        return <Payments user={userData.user} />;
      case 'promotions':
        return <Promotions user={userData.user} />;
    }
  };

  return (
    <>
      <Helmet>
        <title>Skitraj.pl | {window.location.pathname.substring(1)}</title>
        <meta name="description" content="Admin Tool" />
        <meta name="robots" content="noindex,nofollow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <AdminPanelTemplate>
        <div className="admin-tool">
          <AdminToolSidebar
            setActivePanel={setActivePanel}
            activePanel={state.activePanel}
          />
          <div className="flex main_wrapper px-5 pt-5 pb-5">
            {renderActivePanel()}
          </div>
        </div>
      </AdminPanelTemplate>
    </>
  );
};
export default AdminTool;
