import './LogoutBlock.scss';
import { useDispatch } from 'react-redux';
import { logoutAction } from '@network/Actions/Login';
import { H1, H5 } from '@component/Text/Text';

function LogoutBlock() {
  const dispatch = useDispatch();
  const onButtonClick = () => {
    dispatch(logoutAction());
  };

  return (
    <div className="logout-block">
      <H1 className="logout-block_text">dashboard.logoutBlock.title</H1>
      <button className="btn btn-100" type="button" onClick={onButtonClick}>
        <H5 className="logout-block_button-text">
          dashboard.logoutBlock.button
        </H5>
      </button>
    </div>
  );
}

export default LogoutBlock;
