import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { PasswordValidator } from '@rootTypes/auth/password-validator';
import { translate } from '@module/Translate/Translate';
import { setNewPasswordAction } from '@network/Actions/ResetPassword';
import * as Yup from 'yup';
import NewPasswordDesktop from './NewPasswordDesktop/NewPasswordDesktop';
import NewPasswordMobile from './NewPasswordMobile/NewPasswordMobile';

const NewPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isTokenValid, setIsTokenValid] = useState(true);

  const { token } = useParams<{ token: string }>();
  const passwordValidator = new PasswordValidator();

  const history = useHistory();

  const onError = (error: any) => {
    formik.setFieldError('newPassword', translate(`errors.${error.message}`));
  };

  const onTokenExpired = () => {
    setIsTokenValid(false);
  };

  const onSucces = () => {
    history.push('/rejestracja');
  };

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      repNewPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .test('', translate('errors.newPasswordIsTheSame'), function (value) {
          return this.parent.password !== value;
        })
        .test('', translate(`errors.invalidPasswordDetail`), function (value) {
          return value ? !passwordValidator.validatePassword(value) : false;
        })
        .required(translate('errors.fieldRequired')),
      repNewPassword: Yup.string()
        .test('', translate('errors.passwordNotMatch'), function (value) {
          return this.parent.newPassword === value;
        })
        .required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values: any) => {
      setNewPasswordAction(
        token,
        { resetPasswordToken: token, password: values.newPassword },
        onError,
        onTokenExpired,
        onSucces,
      );
    },
  });

  const newPasswordError = (function () {
    if (formik.touched.newPassword && formik.errors.newPassword) {
      return <div className="error-feedback">{formik.errors.newPassword}</div>;
    } else if (formik.touched.repNewPassword && formik.errors.repNewPassword) {
      return (
        <div className="error-feedback">{formik.errors.repNewPassword}</div>
      );
    }

    return null;
  })();

  return (
    <>
      <NewPasswordDesktop
        formik={formik}
        isTokenValid={isTokenValid}
        newPasswordError={newPasswordError}
      />
      <NewPasswordMobile
        formik={formik}
        isTokenValid={isTokenValid}
        newPasswordError={newPasswordError}
      />
    </>
  );
};

export default NewPassword;
