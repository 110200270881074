import React from 'react';
import '../AddressInformation/AddressInformation.scss';
import { useSelector } from 'react-redux';
import { UserState } from '@type/Custom';
import { translate } from '@module/Translate/Translate';
import { RootState } from '@src/store';

const BillingInformation = ({ title, item, onEdit }: any) => {
  function onEditAddress() {
    onEdit();
  }

  const { user } = useSelector<RootState, UserState>((state) => state.user);

  return (
    <>
      <div className="address-information">
        {item && (
          <div>
            {title && (
              <div>
                <h5
                  className="address-information_title d-block d-lg-none mb-3 text-sm-left"
                  dangerouslySetInnerHTML={{
                    __html: title.replace(/[()]/g, ''),
                  }}
                />
                <h5
                  className="address-information_title d-none d-lg-block mb-3 text-sm-left"
                  dangerouslySetInnerHTML={{
                    __html: title.replace(/\n/g, '</br>'),
                  }}
                />
              </div>
            )}
            {user.isCompanyAccount ? (
              <>
                {item.name && (
                  <div className="address-information_row">{item.name}</div>
                )}
                {item.restOfName && (
                  <div className="address-information_row">
                    {item.restOfName || ''}
                  </div>
                )}
                {item.taxNumber && (
                  <div className="address-information_row">
                    NIP: {item.taxNumber}
                  </div>
                )}
                {item.street && (
                  <div className="address-information_row">{item.street}</div>
                )}
                {(item.postalCode || item.city) && (
                  <div className="address-information_row">
                    {item.postalCode} {item.city}
                  </div>
                )}
                {item.phone && (
                  <div className="address-information_row">{item.phone}</div>
                )}
              </>
            ) : (
              <>
                {item.firstName && (
                  <div className="address-information_row">
                    {item.firstName}
                  </div>
                )}
                {item.lastName && (
                  <div className="address-information_row">{item.lastName}</div>
                )}
                {item.street && (
                  <div className="address-information_row">{item.street}</div>
                )}
                {(item.postalCode || item.city) && (
                  <div className="address-information_row">
                    {item.postalCode} {item.city}
                  </div>
                )}
                {item.phone && (
                  <div className="address-information_row">{item.phone}</div>
                )}
              </>
            )}
          </div>
        )}

        <div className="address-information_row mt-3">
          {onEdit && (
            <button onClick={onEditAddress}>
              {translate('summaryInvoice.edit')}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default BillingInformation;
