import {
  PAYMENT_FAIL,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  USER_PAYMENT_METHOD_FAIL,
  USER_PAYMENT_METHOD_SUCCESS,
  USER_PAYMENT_METHOD_REQUEST,
} from '../Network/Actions/Types';
import {
  PaymentMethodChangeState,
  PaymentPaginationState,
} from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const paymentsReducer = (
  state: PaymentPaginationState = {
    loading: false,
    payments: { meta: { totalItems: 0 }, items: [] },
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case PAYMENT_REQUEST:
      return { loading: true, payments: state.payments, error: null };
    case PAYMENT_SUCCESS:
      return { loading: false, payments: action.payload, error: null };
    case PAYMENT_FAIL:
      return {
        loading: false,
        payments: state.payments,
        error: action.payload,
      };
    default:
      return state;
  }
};

export function changePaymentMethodReducer(
  state: PaymentMethodChangeState = {
    loading: false,
    error: false,
    unchanged: false,
  },
  action: Action,
) {
  switch (action.type) {
    case USER_PAYMENT_METHOD_FAIL:
      return {
        loading: false,
        error: true,
        unchanged: true,
      };
    case USER_PAYMENT_METHOD_SUCCESS:
      return {
        loading: false,
        error: false,
        unchanged: false,
      };
    case USER_PAYMENT_METHOD_REQUEST:
      return {
        loading: true,
        error: false,
        unchanged: true,
      };

    default:
      return state;
  }
}
