import { ThunkAction } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import {
  BASKET_ADD_PENDING,
  BASKET_ADD_REJECTED,
  BASKET_ADD_FULFILLED,
  BASKET_DECREASE_PENDING,
  BASKET_DECREASE_REJECTED,
  BASKET_DECREASE_FULFILLED,
  BASKET_DELETE_PENDING,
  BASKET_DELETE_REJECTED,
  BASKET_DELETE_FULFILLED,
  BASKET_DATE_REJECTED,
  BASKET_DATE_FULFILLED,
  BASKET_DATE_PENDING,
  BASKET_PERIOD_PENDING,
  BASKET_PERIOD_FULFILLED,
  BASKET_PERIOD_REJECTED,
  BASKET_DAYS_PENDING,
  BASKET_DAYS_FULFILLED,
  BASKET_DAYS_REJECTED,
  BASKET_STOCKS_PENDING,
  BASKET_STOCKS_FULFILLED,
  BASKET_STOCKS_REJECTED,
} from './Types';
import { BasketState, TStocks } from '../../Type/Custom';
import { setGAEventTracker } from '../../Util/useGAEventTracker';
import { FindBasketProductDto } from '../../../../types/products/find-basket-product.dto';
import { ProductType } from '../../../../types/products/product-type.enum';
import { addProductEvent, removeProductEvent } from '@src/GTM/GTM';

interface IAddProductBasket {
  data: FindBasketProductDto;
  isLocalStorage?: boolean;
  stepValueCounter?: number;
  setShowError?: (isOpen: boolean) => void;
}

export const addProductInBasket = ({
  data,
  isLocalStorage,
  stepValueCounter,
  setShowError,
}: IAddProductBasket): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const basketState: BasketState = state.basket;
    try {
      dispatch({
        type: BASKET_ADD_PENDING,
      });

      if (!isLocalStorage) {
        addProductEvent(data, stepValueCounter);
      }

      setGAEventTracker({
        category: 'Basket',
        action: 'Added product',
        label: `productId: ${data.id}, productName: ${data.name}`,
      });

      if (basketState.products.length === 0) {
        dispatch({
          type: BASKET_ADD_FULFILLED,
          payload: [data],
        });
        return;
      }

      const product = basketState.products.find(
        (product) =>
          product.type === data.type &&
          product.group === data.group &&
          product.id === data.id,
      );

      if (!product) {
        if (data.value !== 0) {
          dispatch({
            type: BASKET_ADD_FULFILLED,
            payload: [...basketState.products, data],
          });
        }
        return;
      }

      const updateProduct = basketState.products.map((product) => {
        if (
          product.type === data.type &&
          product.group === data.group &&
          product.id === data.id
        ) {
          return (product = data);
        }
        return product;
      });

      const filterProduct = updateProduct.filter(
        (product) => product.value !== 0,
      );

      dispatch({
        type: BASKET_ADD_FULFILLED,
        payload: filterProduct,
      });
    } catch (err) {
      setShowError && setShowError(true);
      dispatch({
        type: BASKET_ADD_REJECTED,
        payload: 'Bad request',
      });
    }
  };
};

export const decreaseProductInBasket = (
  data: any,
  stepValueCounter?: number,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const basketState: BasketState = state.basket;
    try {
      dispatch({
        type: BASKET_DECREASE_PENDING,
      });

      setGAEventTracker({
        category: 'Basket',
        action: 'Decrease product',
        label: `productId: ${data.id}, productName: ${data.name}`,
      });

      const updateProduct = basketState.products.map((product) => {
        if (
          product.type === data.type &&
          product.group === data.group &&
          product.id === data.id
        ) {
          return (product = data);
        }
        return product;
      });

      dispatch({
        type: BASKET_DECREASE_FULFILLED,
        payload: updateProduct,
      });

      removeProductEvent([data], stepValueCounter);
    } catch (error) {
      dispatch({
        type: BASKET_DECREASE_REJECTED,
        payload: 'Bad request',
      });
      console.error({
        service: 'basket',
        method: 'decreaseProductInBasket',
        error: error,
      });
    }
  };
};

export const removeProductFromBasket = (
  data: any,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    if (!data.type) {
      return;
    }

    const state = getState();
    const basketState: BasketState = state.basket;

    try {
      dispatch({
        type: BASKET_DELETE_PENDING,
      });

      /**
       * Remove backet during create a new order
       */
      if (data.type === 'remove-all') {
        dispatch({
          type: BASKET_DELETE_FULFILLED,
          payload: [],
        });
        dispatch({
          type: BASKET_PERIOD_FULFILLED,
          payload: [],
        });
        dispatch({
          type: BASKET_DATE_FULFILLED,
          payload: [],
        });
        dispatch({
          type: BASKET_DAYS_FULFILLED,
          payload: [],
        });
        return;
      }

      //TODO: it should be moved as a separete function for removeGroupOfProducts
      if (Object.keys(data).length === 1) {
        const filterProductGroup = basketState.products.filter(
          (product) => product.type !== data.type,
        );

        const removedProductGroup = basketState.products.filter(
          (product) => product.type === data.type,
        );

        dispatch({
          type: BASKET_DELETE_FULFILLED,
          payload: filterProductGroup,
        });
        removeProductEvent(removedProductGroup);
        return;
      }

      const item = basketState.products.find(
        (product) => product.type === data.type && product.id === data.id,
      );
      const filterProduct = basketState.products.filter(
        (product) => product !== item,
      );

      const removedProduct = basketState.products.filter(
        (product) => product === item,
      );

      dispatch({
        type: BASKET_DELETE_FULFILLED,
        payload: filterProduct,
      });

      removeProductEvent(removedProduct);
    } catch (err) {
      dispatch({
        type: BASKET_DELETE_REJECTED,
        payload: 'Bad request',
      });
    }
  };
};

export const addDateInBasket = (
  data: any,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const basketState: BasketState = state.basket;
    try {
      dispatch({
        type: BASKET_DATE_PENDING,
      });

      if (basketState.dates.length === 0) {
        dispatch({
          type: BASKET_DATE_FULFILLED,
          payload: [data],
        });
        return;
      }

      const date = basketState.dates.find(
        (date) => date.type === data.type && date.category === data.category,
      );

      if (!date) {
        dispatch({
          type: BASKET_DATE_FULFILLED,
          payload: [...basketState.dates, data],
        });
        return;
      }

      const updateDate = basketState.dates.map((date) => {
        if (date.type === data.type && date.category === data.category) {
          return (date = data);
        }
        return date;
      });

      dispatch({
        type: BASKET_DATE_FULFILLED,
        payload: updateDate,
      });
    } catch (err) {
      dispatch({
        type: BASKET_DATE_REJECTED,
        payload: 'Bad request',
      });
    }
  };
};

export const addPeriodInBasket = (
  data: any,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const basketState: BasketState = state.basket;
    try {
      dispatch({
        type: BASKET_PERIOD_PENDING,
      });

      if (basketState.periods.length === 0) {
        dispatch({
          type: BASKET_PERIOD_FULFILLED,
          payload: [data],
        });
        return;
      }

      const periods = basketState.periods.find(
        (period) =>
          period.type === data.type && period.category === data.category,
      );

      if (!periods) {
        dispatch({
          type: BASKET_PERIOD_FULFILLED,
          payload: [...basketState.periods, data],
        });
        return;
      }

      const updatePeriod = basketState.periods.map((period) => {
        if (period.type === data.type && period.category === data.category) {
          return (period = data);
        }
        return period;
      });

      dispatch({
        type: BASKET_PERIOD_FULFILLED,
        payload: updatePeriod,
      });
    } catch (err) {
      dispatch({
        type: BASKET_PERIOD_REJECTED,
        payload: 'Bad request',
      });
    }
  };
};

export const addDaysInBasket = (
  data: any,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const basketState: BasketState = state.basket;
    try {
      dispatch({
        type: BASKET_DAYS_PENDING,
      });

      if (basketState.days.length === 0) {
        dispatch({
          type: BASKET_DAYS_FULFILLED,
          payload: [data],
        });
        return;
      }

      const day = basketState.days.find((day) => day.type === data.type);

      if (!day) {
        dispatch({
          type: BASKET_DAYS_FULFILLED,
          payload: [...basketState.days, data],
        });
        return;
      }

      const updateDate = basketState.days.map((day) => {
        if (day.type === data.type) {
          return (day = data);
        }
        return day;
      });

      dispatch({
        type: BASKET_DAYS_FULFILLED,
        payload: updateDate,
      });
    } catch (err) {
      dispatch({
        type: BASKET_DAYS_REJECTED,
        payload: 'Bad request',
      });
    }
  };
};

export const deleteDateInBasket = (
  type: ProductType,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const basketState: BasketState = state.basket;
    try {
      dispatch({
        type: BASKET_DATE_PENDING,
      });
      const filteredDate = basketState.dates.filter(
        (date) => date.type !== type,
      );
      dispatch({
        type: BASKET_DATE_FULFILLED,
        payload: filteredDate,
      });
    } catch (err) {
      dispatch({
        type: BASKET_DATE_REJECTED,
        payload: 'Bad request',
      });
    }
  };
};

export const addStocksInBasket = (
  data: TStocks,
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch) => {
    try {
      dispatch({
        type: BASKET_STOCKS_PENDING,
      });
      dispatch({
        type: BASKET_STOCKS_FULFILLED,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: BASKET_STOCKS_REJECTED,
        payload: 'Bad request',
      });
    }
  };
};

export const deleteStocksInBasket = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return (dispatch) => {
    try {
      dispatch({
        type: BASKET_STOCKS_PENDING,
      });
      dispatch({
        type: BASKET_STOCKS_FULFILLED,
        payload: {},
      });
    } catch (err) {
      dispatch({
        type: BASKET_STOCKS_REJECTED,
        payload: 'Bad request',
      });
    }
  };
};
