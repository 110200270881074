/**
 * THIS HAS TO BEE ABSOLUTE IMPORT BECAUSE UNIT TEST DOES NOT SUPPORT ALIASES → REQUIRES ADDITIONAL CONFIGURATION
 */
import unifiedFormatDate from '../../../../../../../Util/Dates';
import { FindOrderDto } from '../../../../../../../../../types/orders/find-order.dto';
import { FindStockDto } from '../../../../../../../../../types/stocks/find-stock.dto';
import { StockType } from '../../../../../../../../../types/stocks/stock-type.enum';
import { objectGroupper } from '../../../../../../../Util/groupObject';
/**
 * This function creates matrix of rendered deliveries based on status and dalivery dates and addresses
 *
 * @param order
 * @returns
 */

export const createGruppedOrder = (order: FindOrderDto) => {
  try {
    const activeStock = order.stocks.filter(
      (box: FindStockDto) =>
        box.stockType === StockType.STORAGE && box.isActive,
    );
    const gruppedArray: Array<Array<FindStockDto>> = objectGroupper(
      activeStock,
      'stockStatus',
    ); // → groups array based on stock status

    if (gruppedArray) {
      const gruppedByDates: any = [];
      gruppedArray.forEach((parentArray) => {
        //this is single sorting module. In case of future function development  you have to add one
        parentArray.forEach((singleStock: any) => {
          singleStock.startDateToCompare = unifiedFormatDate(
            new Date(
              singleStock.deliveryDate
                ? singleStock.deliveryDate
                : singleStock.startDate,
            ),
          );
        });
        gruppedByDates.push(
          ...objectGroupper(parentArray, 'startDateToCompare'),
        );
      });
      const gruppedByAddresses: any = [];
      if (process.env.GROUP_ORDER_BY_ADDRESS === 'true')
        gruppedByDates.forEach((parentArray: any[]) => {
          parentArray.forEach((singleStock: any) => {
            singleStock.addressDeliveryToCompare = singleStock.returnAddress.id;
          });
          gruppedByAddresses.push(
            ...objectGroupper(parentArray, 'addressDeliveryToCompare'),
          );
        });
      return process.env.GROUP_ORDER_BY_ADDRESS === 'true'
        ? [...gruppedByAddresses]
        : [...gruppedByDates];
    }
    return [];
  } catch (error) {
    console.error({
      from: 'filterOrderDayAndAddress',
      error,
    });
    return [];
  }
};
