import React, { useEffect, useState } from 'react';
import './RentPage.scss';
import MainTemplate from '../../Component/MainTemplate/MainTemplate';
import HowTo from './components/HowTo/HowTo';
import Rent from './components/Rent/Rent';
import Certificate from './components/Certificate/Certificate';
import CheckThePackage from './components/CheckThePackage/CheckThePackage';
import Comparison from './components/Comparison/Comparison';
import { translate } from '../../Module/Translate/Translate';
import { ProductsState } from '../../Type/Custom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ProductGroup } from '../../../../types/products/product-group.enum';
import {
  getProductsAction,
  getProductsActionWithoutState,
} from '../../Network/Actions/Products';
import { ProductType } from '../../../../types/products/product-type.enum';
import { Link } from 'react-router-dom';
import { BoxesSort } from '../../Util/BoxesSort';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { Helmet } from 'react-helmet';
import CarouselBlock from '@view/HowItWorks/components/CarouselBlock/CarouselBlock';
import { H3 } from '@component/Text/Text';
import { DescriptionTypeEnum } from '@rootTypes/product-descriptions/description-type.enum';

function RentPage() {
  const dispatch = useDispatch();
  const [carouselDataProducts, setCarouselDataProducts] = useState([]);
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  const [showError, setShowError] = useState(false);
  function showMoreDektop() {
    if (products.length > 5) {
      return (
        <div style={{ position: 'relative' }}>
          <Link
            to="pakiety-przeprowadzkowe"
            className="btn btn-small btn-secondary d-none d-lg-flex"
            style={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 9999, // Dodanie wysokiego poziomu z-index
              width: '30px', // Szerokość ikony
              height: '30px', // Wysokość ikony
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* Ikona */}
            <div
              style={{
                width: '0',
                height: '0',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: '10px solid black',
              }}
            />
          </Link>
        </div>
      );
    }

    return null; // Dodan
  }
  //TODO: it should be one but I dont understand the MAGIC for mobile here
  function showMoreMobile() {
    if (products.length > 5) {
      return (
        <Link
          to="pakiety-przeprowadzkowe"
          className="btn btn-large btn-secondary d-flex d-lg-none mb-4 mx-auto"
        >
          {translate('buttons.showMore')}
        </Link>
      );
    }
  }

  useEffect(() => {
    dispatch(
      getProductsAction(
        true,
        ProductGroup.packageBox,
        ProductType.rent,
        false,
        setShowError,
      ),
    );
    getProductsActionWithoutState(true, ProductGroup.box).then((res) => {
      const carouselProducts = res.filter(
        (product: { group: ProductGroup; type: ProductType; isPromo: any }) =>
          product.group === ProductGroup.box &&
          product.type === ProductType.storage &&
          product.isPromo,
      );
      setCarouselDataProducts(carouselProducts);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Wynajem pudełek na czas przeprowadzki i remontu | skitraj.pl
        </title>
        <meta
          name="description"
          content="Nasze wygodne opakowania ułatwiają pakowanie i przechowywanie podczas przeprowadzki lub remontu. Z nami zrobisz to prościej!"
        />
        <meta name="robots" content="index,follow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.serverError')}
          activeContactLink={false}
        />
      </Modal>
      <MainTemplate>
        <div className="rentPage-container">
          <Rent />
          <div className="rentPage-wrapper">
            <HowTo />
            <div className="rent-package">
              <div className="rent-package-title-group">
                <h3 className="title">
                  {translate('rentPage.package-group.title')}
                </h3>
                <p className="subtitle">
                  {translate('rentPage.package-group.subtitle')}
                </p>
              </div>
              {showMoreMobile()}
              <div className="rent-package-list">
                {BoxesSort(
                  products.filter(
                    (product) =>
                      product.isPromo === true ||
                      product.isVisibleOnOrderPanel === true,
                  ),
                ).map((product) => (
                  <CheckThePackage key={product.id} data={product} />
                ))}
              </div>
              {showMoreDektop()}
            </div>
          </div>
          {carouselDataProducts.length > 0 && (
            <CarouselBlock
              products={carouselDataProducts}
              title="rentPage.carousel.title"
              descriptionPath={DescriptionTypeEnum.rent}
            />
          )}
          <div className="comparison-wrapper">
            <H3>rentPage.comparisonTitle</H3>
            <Comparison />
          </div>

          <Certificate />
        </div>
      </MainTemplate>
    </>
  );
}

export default RentPage;
