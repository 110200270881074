import React, { PropsWithChildren } from 'react';
import { FunctionComponent } from 'react';
import './Button.style.scss';
import ButtonI from './Button.type';
import Typography from '../../Component/Typography/Typography';

const ButtonHOC = (): FunctionComponent<React.PropsWithChildren<ButtonI>> =>
  //@todo creat universal class "button_accept"
  function ButtonHOC(props: PropsWithChildren<ButtonI>) {
    return (
      <div {...props}>
        <Typography>{props.text}</Typography>
      </div>
    );
  };

export const ButtonAccept = ButtonHOC();

export default ButtonAccept;
