import React, { useContext, useState } from 'react';
import './WarehouseData.scss';
import unifiedFormatDate from '@util/Dates';
import { FormFieldFormik } from '@component/FormFieldFormik/FormFieldFormik';
import { H5 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { FindProductDto } from '@rootTypes/products/find-product.dto';
import { EditProductContext } from '../../Products';

interface Props {
  product: FindProductDto;
}

const WarehouseData = ({ product }: Props) => {
  const [quantity, setQuantity] = useState(product.warehouseStock);
  const editProductContext = useContext<any>(EditProductContext);

  const onSubmit = () => {
    const editProduct = {
      id: product.id,
      warehouseStock: quantity,
      warehouseUpdatedAt: new Date(),
    };
    editProductContext(editProduct);
  };
  return (
    <div className="product-info-container">
      <br />
      <FormFieldFormik
        label={translate('adminTool.products.quantity')}
        type="number"
        name="quantity"
        value={quantity}
        onChange={(e: any) => setQuantity(e.target.value)}
      />
      <br />
      <H5 className="product-h5">
        {translate('adminTool.products.warehouseSyncDate')}
      </H5>
      <br />
      <div className="field-val">
        {product.warehouseUpdatedAt &&
          unifiedFormatDate(new Date(product.warehouseUpdatedAt), true)}
      </div>
      <button
        type="submit"
        className={`submitForm invoice__button m-auto button_accept ${
          quantity ? '' : 'inactive'
        }`}
        onClick={() => onSubmit()}
      >
        {translate('adminTool.products.update')}
      </button>
    </div>
  );
};

export default WarehouseData;
