import React, { useState } from 'react';
import FormField from '../../Component/FormField/FormField';
import './DeliveryAddressForm.scss';
import { translate } from '../../Module/Translate/Translate';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../Type/Custom';
import { isEmpty } from '../../Util/isEmpty';

export const DeliveryAddressForm = ({
  index,
  item,
  onEditAddress,
  edit,
  createAddress,
  updateAddress,
}: any) => {
  const { user } = useSelector<RootState, UserState>((state) => state.user);

  const [formData, setFormData] = useState({
    name: item
      ? item.name
      : '' || user.isCompanyAccount
      ? user.companyDetails?.name
      : user.personalDetails?.firstName || '',
    restOfName: item
      ? item.restOfName
      : '' || user.isCompanyAccount
      ? user.companyDetails?.restOfName
      : user.personalDetails?.lastName || '',
    street: item ? item.street : '',
    postalCode: item ? item.postalCode : '',
    city: item ? item.city : '',
    country: item ? item.country : 'Polska',
    isDefault: item ? item.isDefault : false,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (edit) {
      updateAddress({
        id: item.id,
        name: formData.name,
        restOfName: formData.restOfName || '',
        street: formData.street,
        city: formData.city,
        postalCode: formData.postalCode,
        isDefault: formData.isDefault,
      });
    } else {
      createAddress({
        name: formData.name,
        restOfName: formData.restOfName || '',
        street: formData.street,
        city: formData.city,
        postalCode: formData.postalCode,
        isDefault: formData.isDefault,
      });
    }
    onEditAddress({ ...item, ...formData });
  };

  return (
    <form
      className="registration-create-address-form pb-5"
      onSubmit={handleSubmit}
    >
      <FormField
        name={formData.name}
        label={translate('addressStep.name')}
        onChange={(e: any) =>
          setFormData({ ...formData, name: e.target.value })
        }
      />
      <FormField
        name={formData.restOfName || ''}
        label={translate('addressStep.surname')}
        onChange={(e: any) =>
          setFormData({ ...formData, restOfName: e.target.value })
        }
      />
      <FormField
        name={formData.street}
        isFullWidth
        label={translate('addressStep.street')}
        onChange={(e: any) =>
          setFormData({ ...formData, street: e.target.value })
        }
      />

      <FormField
        name={formData.postalCode}
        label={translate('addressStep.zipCode')}
        onChange={(e: any) =>
          setFormData({ ...formData, postalCode: e.target.value })
        }
        pattern={'^[0-9]{2}-[0-9]{3}$'}
        placeholder={'XX-XXX'}
        title={translate('errors.postalCodeError')}
      />
      <FormField
        name={formData.city}
        label={translate('addressStep.city')}
        onChange={(e: any) =>
          setFormData({ ...formData, city: e.target.value })
        }
      />

      <button
        type="submit"
        className="deliver_button btn w-100"
        disabled={
          user.isCompanyAccount
            ? !isEmpty(formData, 'restOfName')
            : !isEmpty(formData)
        }
      >
        {translate('addressStep.save')}
      </button>
    </form>
  );
};

export default DeliveryAddressForm;
