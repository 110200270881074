import ReactGA from 'react-ga';

type TData = {
  category: string;
  action: string;
  label?: string;
};

let isGAInitialized = false;

export const setGAEventTracker = async (data: TData) => {
  if (!isGAInitialized) return;
  try {
    await ReactGA.event({
      category: data.category,
      action: data.action,
      label: data.label,
    });
  } catch (error) {
    console.error({
      service: 'GA',
      method: 'setGAEventTracker',
      error: error,
    });
  }
};

export const setGAPageview = async () => {
  if (!isGAInitialized) return;
  try {
    await ReactGA.pageview(window.location.pathname + window.location.search);
  } catch (error) {
    console.error({
      service: 'GA',
      method: 'setGAPageview',
      error: error,
    });
  }
};

export const setGAInitialize = async (analyticsToken: string) => {
  try {
    await ReactGA.initialize(analyticsToken);
    isGAInitialized = true;
  } catch (error) {
    console.error({
      service: 'GA',
      method: 'setGAInitialize',
      error: error,
    });
  }
};
