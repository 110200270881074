import React from 'react';
import './SeeMoreBlock.scss';
import { H1, H2, H3 } from '../../../../Component/Text/Text';
import { translate } from '../../../../Module/Translate/Translate';
import { Link } from 'react-router-dom';

const SeeMoreBlock = () => (
  <>
    <article className="overflow-hidden see-more-section justify-content-center align-content-around">
      <H1 className="how-work d-block d-lg-none text-center">
        homePage.seeMoreBlock.howWork
      </H1>
      <div className="container">
        <div className="seeMoreBlock">
          <section className="d-none d-lg-flex flex-column justify-content-around ">
            <div>
              <H2 className="see-more-section_title">
                homePage.seeMoreBlock.title
              </H2>
              <H3 className="see-more-section_subtitle">
                homePage.seeMoreBlock.subtitle
              </H3>
              <div className="row">
                <div className="col">
                  <Link
                    to="/przechowaj"
                    data-e2e="how-it-works"
                    className="see-more-section_btn btn btn-dark btn-lg"
                  >
                    {translate('homePage.seeMoreBlock.howWork')}
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="playerWrapper">
            <div className="player">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/wb2LBMOlG5c"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </section>
        </div>
      </div>
    </article>
  </>
);

export default SeeMoreBlock;
