export enum DateCategory {
  start = "start",
  last = "last",
  pickedLastDate = "pickedLastDate",
}

export enum PeriodCategory {
  period = "period",
  range = "range",
}
