import React, { FC } from 'react';
import './NewPasswordMobile.scss';
import { Link } from 'react-router-dom';
import arrowBack from '../../../../../../Asset/images/shop/arrow-back.png';
import logo from '../../../../../../Asset/images/logo.png';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { translate } from '../../../../../../Module/Translate/Translate';
import { TokenExpired } from '../../../../../../Component/TokenExpired/TokenExpired';
import { isEmpty } from '../../../../../../Util/isEmpty';

interface IProps {
  isTokenValid: boolean;
  formik: any;
  newPasswordError: any;
}
const NewPasswordMobile: FC<React.PropsWithChildren<IProps>> = ({
  isTokenValid,
  formik,
  newPasswordError,
}) => {
  return (
    <div className="new-password-mobile-wrapper">
      <div className="back-icon-wrapper">
        <Link to="/rejestracja">
          <img src={arrowBack} alt="arrow back" />
        </Link>
      </div>
      <div className="wrapper-mobile">
        <div className="mobile-main-content">
          <img className="mobile-main-content_logo" src={logo} alt="logo" />
        </div>
        <div className="mobile-form-wrapper">
          {isTokenValid ? (
            <>
              <p className="mobile-main-content_title">Zresetuj hasło</p>

              <form onSubmit={formik.handleSubmit}>
                <FormFieldFormik
                  label={translate('changePassword.newPassword')}
                  type="password"
                  name="newPassword"
                  placeholder={translate('changePassword.newPassword')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  fieldError={newPasswordError}
                />
                {formik.errors.newPassword ? (
                  <div className="error-feedback">
                    {formik.errors.newPassword}
                  </div>
                ) : (
                  <div className="info-feedback">
                    {translate('changePassword.text')}
                  </div>
                )}

                <FormFieldFormik
                  label={translate('changePassword.repeatPassword')}
                  type="password"
                  name="repNewPassword"
                  placeholder={translate('changePassword.repeatPassword')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.repNewPassword}
                  fieldError={newPasswordError}
                />
                <div className="error-feedback">
                  {formik.errors.repNewPassword}
                </div>
                {formik.isValid && isEmpty(formik.values) ? (
                  <button className="submitButton" type="submit">
                    {translate('resetPassword.submitButton')}
                  </button>
                ) : (
                  <button className="invalid-button" type="submit">
                    {translate('resetPassword.submitButton')}
                  </button>
                )}
              </form>
            </>
          ) : (
            <TokenExpired
              title={translate('errors.restorePasswordTokenExpired')}
              text={translate('errors.resetPasswordTokenExpiredBio')}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPasswordMobile;
