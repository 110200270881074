import { translate } from '@module/Translate/Translate';
import React, { useState } from 'react';
import Slider from 'react-rangeslider';
const months = [2, 3, 6, 9, 12];

interface IKeepSlider {
  isActive: boolean;
  storageMonths: number;
  handleKeepChange: (value: number) => void;
  handleInputKeepRentChange: (event: any) => void;
}

export default function KeepSlider({
  isActive,
  storageMonths,
  handleKeepChange,
  handleInputKeepRentChange,
}: IKeepSlider) {
  const [storeSliderValue, setStoreSliderValue] = useState(3);

  return (
    <>
      <div
        className={`mode-slider ' + ${isActive && 'mode-slider-active'}`}
        data-mode="keep"
      >
        <div className="d-flex">
          <Slider
            value={storeSliderValue}
            step={1}
            min={1}
            max={6}
            tooltip={false}
            onChange={(value: number) => {
              setStoreSliderValue(value);
              /**
               * If input reach index 0 should return 1 (30 days)
               */
              handleKeepChange(value - 2 >= 0 ? months[value - 2] : 1);
            }}
          />
        </div>
        <div className="rangeslider__label col-12">
          <div className="w-100 d-flex flex-row justify-content-between text-primary">
            <span className="fs16 fs-lg-24 fw400">
              30 {translate('calculator.days')}
            </span>
            {months.map((month: number) => (
              <span key={month + 'month'} className="fs16 fs-lg-24 fw400">
                {month} {translate('calculator.months')}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`calculator-input ${isActive && 'calculator-input_active'}`}
      >
        <label htmlFor="month">
          {translate('calculator.numberOfMonthsOfStorage')}
        </label>
        <input
          name="month"
          id="month"
          autoComplete="off"
          type="number"
          pattern="^[0-9]{2}$"
          min={1}
          max={12}
          value={storageMonths}
          onChange={handleInputKeepRentChange}
        />
      </div>
    </>
  );
}
