import { lowerCase } from '../Util/TextUtil';

export default function getApiEndPoint(param: string) {
  param = lowerCase(param);
  switch (param) {
    case 'login':
      return 'auth/login';
    case 'register':
      return 'auth/register';
    case 'activate':
      return 'auth/activate-account';
    case 'google':
      return 'auth/login/google';
    case 'facebook':
      return 'auth/login/facebook';
    case 'orders':
      return 'orders';
    case 'transaction':
      return 'payments/register';
    case 'payments':
      return 'payments';
    case 'invokeTransaction':
      return 'payments/invoke-payment';
    default:
      return '/';
  }
}
