export const sortObjectArrayByString = (
  objectArray: any,
  sortFiled: string,
): [] => {
  return objectArray.sort((objA: any, objB: any) => {
    if (objA[sortFiled] < objB[sortFiled]) {
      return -1;
    } else if (objA[sortFiled] < objB[sortFiled]) {
      return 1;
    } else {
      return 0;
    }
  });
};
