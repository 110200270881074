import {
  PRODUCT_PACKAGES_FAIL,
  PRODUCT_PACKAGES_REQUEST,
  PRODUCT_PACKAGES_SUCCESS,
} from '../Network/Actions/Types';
import { ProductPackagesState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: Record<string, unknown>;
}
export const productPackagesReducer = (
  state: ProductPackagesState = {
    loading: false,
    productPackages: [],
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case PRODUCT_PACKAGES_REQUEST:
      return {
        loading: true,
        productPackages: state.productPackages,
        error: null,
      };
    case PRODUCT_PACKAGES_SUCCESS:
      return { loading: false, productPackages: action.payload, error: null };
    case PRODUCT_PACKAGES_FAIL:
      return {
        loading: false,
        products: state.productPackages,
        error: action.payload,
      };
    default:
      return state;
  }
};
