import PolicyConfirm from '@form/LoginForm/components/PolicyConfirm';
import { PolicyEnum } from '@rootTypes/policies/policy-type.enum';
import { ActivateAccount } from '@view/ActivateAccount/ActivateAccount';
import AdminTool from '@view/AdminTool/AdminTool';
import Dashboard from '@view/Dashboard/Dashboard';
import FAQ from '@view/FAQ/FAQ';
import HomePage from '@view/HomePage/HomePage';
import HowItWorks from '@view/HowItWorks/HowItWorks';
import InvalidToken from '@view/InvalidToken/InvalidToken';
import Newsletter from '@view/Newsletter/Newsletter';
import NewsletterConfirm from '@view/NewsletterConfirm/NewsletterConfirm';
import NewsletterUnsubscribe from '@view/NewsletterUnsubscribe/NewsletterUnsubscribe';
import Offer from '@view/Offer/Offer';
import OrderPanel from '@view/OrderPanel/OrderPanel';
import Package from '@view/Package/Package';
import PageAccessDenied from '@view/PageAccessDenied/PageAccessDenied';
import PageNotFound from '@view/PageNotFound/PageNotFound';
import PageUnavailable from '@view/PageUnavailable/PageUnavailable';
import PageUnderConstruction from '@view/PageUnderConstruction/PageUnderConstruction';
import NewPassword from '@view/RegistrationStepper/components/ForgotPassword/NewPassword/NewPassword';
import RegistrationStepper from '@view/RegistrationStepper/RegistrationStepper';
import RentProductDetails from '@view/RentPage/components/ProductDetails/RentProductDetails';
import RentPage from '@view/RentPage/RentPage';
import ProductDetailPages from '@view/Shop/Components/ProductDetailPages/ProductDetailPages';
import Shop from '@view/Shop/Shop';
import ShowPolicy from '@view/ShowPolicy/ShowPolicy';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import MovePage from '@view/MovePage/MovePage';

const RouterSwitch = () => {
  return (
    <Switch>
      <Route path="/return-boxes">
        <RegistrationStepper hideStepper={true} />
      </Route>
      <Route path="/rejestracja" component={RegistrationStepper} />
      <Route path="/payment/success">
        <RegistrationStepper priorityStep={10} hideStepper={true} />
      </Route>
      <Route path="/logowanie">
        <RegistrationStepper authenticationType={'login'} />
      </Route>
      <Route path="/reset-password/:token" component={NewPassword} />
      <Route path="/confirmEmail">
        <RegistrationStepper priorityStep={7} />
      </Route>
      <Route path="/verifyemail">
        <RegistrationStepper priorityStep={8} />
      </Route>
      <Route path="/activateAccount/:token" component={ActivateAccount} />
      <Route path="/invalidActivateToken" component={InvalidToken} />

      <Route path="/newsletter" component={Newsletter} />
      <Route path="/subskrypcja-potwierdzenie" component={NewsletterConfirm} />
      <Route path="/subskrypcja-rezygnacja" component={NewsletterUnsubscribe} />
      <Route path="/page-unavailable" component={PageUnavailable} />
      <Route path="/page-access-denied" component={PageAccessDenied} />
      <Route
        path="/page-under-construction"
        component={PageUnderConstruction}
      />

      <Route path="/jak-to-dziala" component={HowItWorks} />
      <Route path="/przechowaj" component={HowItWorks} />
      <Route path="/oferta" component={HomePage} />
      <Route path="/DlaFirm" component={Offer} />
      <Route path="/DlaFirm/products" component={Offer} />
      <Route path="/pomoc" component={FAQ} />
      <Route path="/faq" component={FAQ} />

      <Route path="/polityka-prywatnosci">
        <ShowPolicy typeOfPolicy={PolicyEnum.Privacy} />
      </Route>

      <Route path="/privacy-policy">
        <ShowPolicy typeOfPolicy={PolicyEnum.Privacy} />
      </Route>

      <Route path="/regulamin">
        <ShowPolicy typeOfPolicy={PolicyEnum.Website} />
      </Route>
      <Route path="/regulamin-promocji">
        <ShowPolicy typeOfPolicy={PolicyEnum.Promotion} />
      </Route>
      <Route path="/zamowienie" component={OrderPanel} />
      <Route exact path="/sklep" component={Shop} />
      <Route path="/sklep/:productPath" component={ProductDetailPages} />
      <Route exact path="/wynajem" component={RentPage} />
      <Route path="/wynajem/:pathUrl" component={RentProductDetails} />
      <Route exact path="/pakiety-przeprowadzkowe" component={Package} />
      <Route path="/panel-klienta-powiadomienia">
        <PrivateRoute component={Dashboard} currentPanel="messages" />
      </Route>

      <Route path="/panel-klienta-dane">
        <PrivateRoute component={Dashboard} currentPanel="personal" />
      </Route>

      <Route path="/panel-klienta-adresy">
        <PrivateRoute component={Dashboard} currentPanel="address" />
      </Route>

      <Route path="/panel-klienta-moja-skrytka">
        <PrivateRoute component={Dashboard} currentPanel="hidingPlace" />
      </Route>

      <Route path="/panel-klienta-moj-wynajem">
        <PrivateRoute component={Dashboard} currentPanel="myRental" />
      </Route>

      <Route path="/panel-klienta-zamowienia">
        <PrivateRoute component={Dashboard} currentPanel="orderHistory" />
      </Route>

      <Route path="/panel-klienta-oplac">
        <PrivateRoute component={Dashboard} currentPanel="payFor" />
      </Route>

      <Route path="/panel-klienta-historia-platnosci">
        <PrivateRoute component={Dashboard} currentPanel="paymentHistory" />
      </Route>

      <Route path="/user-credit-cards">
        <PrivateRoute component={Dashboard} currentPanel="creditCards" />
      </Route>

      <Route path="/panel-klienta-kontakt">
        <PrivateRoute component={Dashboard} currentPanel="contact" />
      </Route>
      <Route path="/panel-klienta-reklamacja">
        <PrivateRoute component={Dashboard} currentPanel="complaints" />
      </Route>
      <Route path="/panel-klienta-wylogowanie">
        <PrivateRoute component={Dashboard} currentPanel="logout" />
      </Route>

      <Route path="/panel-klienta">
        <PrivateRoute component={Dashboard} />
      </Route>

      <Route exact path="/admin-tool" component={AdminTool} />
      <Route path="/admin-tool-users">
        <AdminTool currentPanel="users" />
      </Route>
      <Route path="/admin-tool-orders">
        <AdminTool currentPanel="orders" />
      </Route>
      <Route path="/admin-tool-private-users">
        <AdminTool currentPanel="private-users" />
      </Route>
      <Route path="/admin-tool-archived-user">
        <AdminTool currentPanel="archived-user" />
      </Route>
      <Route path="/admin-tool-login-attempts">
        <AdminTool currentPanel="login-attempts" />
      </Route>
      <Route path="/admin-tool-company-users">
        <AdminTool currentPanel="company-users" />
      </Route>
      <Route path="/admin-tool-comments">
        <AdminTool currentPanel="comments" />
      </Route>
      <Route path="/admin-tool-reviews">
        <AdminTool currentPanel="reviews" />
      </Route>
      <Route path="/admin-tool-products">
        <AdminTool currentPanel="products" />
      </Route>
      <Route path="/admin-tool-push-notifications">
        <AdminTool currentPanel="push-notifications" />
      </Route>
      <Route path="/admin-tool-complaints">
        <AdminTool currentPanel="complaints" />
      </Route>
      <Route path="/admin-tool-transport">
        <AdminTool currentPanel="transport" />
      </Route>

      <Route path="/admin-tool-default-settings">
        <AdminTool currentPanel="defaultSettings" />
      </Route>

      <Route path="/admin-tool-delivery-statuses">
        <AdminTool currentPanel="deliveryStatuses" />
      </Route>

      <Route path="/admin-tool-rents">
        <AdminTool currentPanel="rents" />
      </Route>
      <Route path="/admin-tool-sells">
        <AdminTool currentPanel="sells" />
      </Route>
      <Route path="/admin-tool-storages">
        <AdminTool currentPanel="storages" />
      </Route>

      <Route path="/admin-tool-order-blocked-days">
        <AdminTool currentPanel="orderBlockedDays" />
      </Route>
      <Route path="/admin-tool-limits">
        <AdminTool currentPanel="limits" />
      </Route>
      <Route path="/admin-tool-policies">
        <AdminTool currentPanel="policies" />
      </Route>
      <Route path="/admin-tool-payments">
        <AdminTool currentPanel="payments" />
      </Route>
      <Route path="/admin-tool-promotions">
        <AdminTool currentPanel="promotions" />
      </Route>
      <Route path="/policyConfirm" component={PolicyConfirm} />

      <Route exact path="/przeprowadz" component={MovePage} />

      <Route exact path="/" component={HomePage} />
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default RouterSwitch;
