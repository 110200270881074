import P, { H5 } from '@component/Text/Text';
import './PaymentForm.scss';
import { translate } from '@module/Translate/Translate';
import { removeProductFromBasket } from '@network/Actions/Basket';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import {
  TransportTypeEnum,
  UndefinedTransportCost,
} from '@rootTypes/transport/transport-type.enum';
import { Variety } from '@rootTypes/variety/variety-type.enum';
import { RootState } from '@src/store';
import {
  BasketState,
  LimitsState,
  PromotionState,
  TransportPriceState,
} from '@type/Custom';
import {
  calculateShopPrice,
  calculateStoreBoxesPrice,
  calculateProductsQuantity,
  calculateRentBoxesPrice,
  calculateRentPackagePrice,
  calculateRentPackageContinuationPrice,
  calculatePriceWithDiscount,
} from '@util/ProductsCalculate';
import { calculateTransportStorePrice } from '@util/TransportPrice';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import deleteIcon from '@images/order-items/close.png';
import './PaymentForm.scss';
import { getTransportAction } from '@network/Actions/TransportPrice';
import {
  removePromotionCode,
  verifyPromotionCode,
} from '@network/Actions/Promotion';
import Modal from '@component/Modal/Modal';
import { calculateCeilTotalRentPrice } from '@component/CalculatorBlock/logic/calculateCeliTotalRentPrice';
import { calculateCeilStorePriceByMonth } from '@component/CalculatorBlock/logic/calculateCeilStorePriceByMonth';
import PromotionErrorModal from '@component/PromotionErrorModal/PromotionErrorModal';

export const PaymentForm = ({
  onSubmit,
  isValidated,
  isSubmitButtonDisable,
}: any) => {
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);
  const dispatch = useDispatch();
  const { limits }: LimitsState = useSelector<RootState, LimitsState>(
    ({ limits }) => limits,
  );

  const { promotion }: PromotionState = useSelector<RootState, PromotionState>(
    ({ promotion }) => promotion,
  );
  const stocks = state.stocks;

  const [storageRange, setStorageRange] = useState<string | null>(null);
  const [rentRange, setRentRange] = useState<string | null>(null);
  const [storageTime, setStorageTime] = useState<number>(1);
  const [rentTime, setRentTime] = useState<string>('0');
  const [promotionCode, setPromotionCode] = useState('');
  const [visiblePromotionForm, setVisiblePromotionForm] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileModal, setIsMobileModal] = useState(false);

  const [contentHeight, setContentHeight] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const height = content.current?.scrollHeight;
    height && setContentHeight(height);
  }, [visiblePromotionForm, promotion]);

  useEffect(() => {
    const media = window.matchMedia('(max-width: 1199.98px)');
    const mediaModal = window.matchMedia('(max-width: 767.98px)');

    if (media.matches !== isMobile) {
      setIsMobile(media.matches);
    }

    if (media.matches !== isMobileModal) {
      setIsMobileModal(mediaModal.matches);
    }

    const listener = () => {
      setIsMobile(media.matches);
    };
    media.addListener(listener);

    const listenerModal = () => {
      setIsMobileModal(media.matches);
    };
    mediaModal.addListener(listenerModal);
    return () => {
      media.removeListener(listener);
      mediaModal.removeListener(listenerModal);
    };
  }, [isMobile, isMobileModal]);

  const { transport }: TransportPriceState = useSelector<
    RootState,
    TransportPriceState
  >(({ transportPrice }) => transportPrice);

  const getStorageTransportPrice = transport.find(
    (item) => item.type === TransportTypeEnum.storage,
  )?.price;

  const storageTransportPrice =
    getStorageTransportPrice === 0
      ? 0
      : getStorageTransportPrice || UndefinedTransportCost;

  const getSingleTransportPrice = transport.find(
    (item) => item.type === TransportTypeEnum.single,
  )?.price;

  const singleTransportPrice =
    getSingleTransportPrice === 0
      ? 0
      : getSingleTransportPrice || UndefinedTransportCost;

  const getStackTransportPrice = transport.find(
    (item) => item.type === TransportTypeEnum.stack,
  )?.price;

  const stackTransportPrice =
    getStackTransportPrice === 0
      ? 0
      : getStackTransportPrice || UndefinedTransportCost;

  const getShopTransportPrice = transport.find(
    (item) => item.type === TransportTypeEnum.shop,
  )?.price;

  const shopTransportPrice =
    getShopTransportPrice === 0
      ? 0
      : getShopTransportPrice || UndefinedTransportCost;

  const location = useLocation();

  const rentState = state.products.filter(
    (product) => product.type === ProductType.rent,
  );

  const storeState = state.products.filter(
    (product) => product.type === ProductType.storage,
  );

  const shopState = state.products.filter(
    (product) => product.group === ProductGroup.shop,
  );

  const stateStoreRange = state.periods.find(
    (date) => date.type === ProductType.storage && date.category === 'range',
  );

  const stateRentRange = state.periods.find(
    (date) => date.type === ProductType.rent && date.category === 'range',
  );

  const stateStorePeriod = state.periods.find(
    (date) => date.type === Variety.months && date.category === 'period',
  );

  const stateRentPeriod = state.periods.find(
    (date) => date.type === Variety.weeks && date.category === 'period',
  );

  useEffect(() => {
    setStorageRange(stateStoreRange ? stateStoreRange?.date : null);
    setRentRange(stateRentRange ? stateRentRange?.date : null);
    setStorageTime(stateStorePeriod ? +stateStorePeriod?.date.slice(0, 2) : 1);
    setRentTime(stateRentPeriod ? stateRentPeriod?.date : '0');
  }, [state]);

  useEffect(() => {
    dispatch(getTransportAction());
  }, []);

  //const shopPriceOLD = calculateShopPrice(shopState, promotion?.discount);
  const shopPrice = useMemo(
    () => calculateShopPrice(shopState, promotion?.discount),
    [shopState, promotion],
  );

  const boxesStore = storeState.filter(
    (item) => item.group === ProductGroup.box,
  );

  const boxesStackedStore = boxesStore.filter((item) => item.isStack);

  const boxesNotStackedStore = boxesStore.filter((item) => !item.isStack);

  const boxesPriceStackedStore = calculateStoreBoxesPrice(boxesStackedStore);

  const boxesPriceNotStackedStore =
    calculateStoreBoxesPrice(boxesNotStackedStore);

  const boxesValueStackedStore = calculateProductsQuantity(boxesStackedStore);

  const boxesValueNotStackedStore =
    calculateProductsQuantity(boxesNotStackedStore);

  const boxesRent = rentState.filter((item) => item.group === ProductGroup.box);

  const boxesPriceRent = calculateRentBoxesPrice(boxesRent);

  const boxesValueRent = calculateProductsQuantity(boxesRent);

  const packageState = rentState.filter(
    (item) => item.group === ProductGroup.packageBox,
  );

  const packagePrice = calculateRentPackagePrice(packageState, rentTime);

  // TODO: TU LICZE PROMOCJE
  const continuationRentPrice = calculatePriceWithDiscount({
    price: calculateRentPackageContinuationPrice(packageState) + boxesPriceRent,
    discount: promotion?.discount,
  });

  const transportStorePrice = calculateTransportStorePrice({
    quantityStackedBoxForStore: boxesValueStackedStore,
    quantityNotStackedBoxForStore: boxesValueNotStackedStore,
    singleTransportPrice,
    stackTransportPrice,
  });

  // TODO: TU LICZE PROMOCJE
  const storePrice = calculatePriceWithDiscount({
    price:
      transportStorePrice +
      (boxesPriceStackedStore + boxesPriceNotStackedStore) * storageTime,
    discount: promotion?.discount,
  });

  // TODO: TU LICZE PROMOCJE
  // TO NIE POWINNO BYC BRANE Z LOGICI CALCULATORA
  const rentPrice = calculateCeilTotalRentPrice(
    parseInt(rentTime.slice(0, 2)),
    boxesPriceRent,
    boxesValueRent,
    stackTransportPrice,
    promotion?.discount,
    packagePrice,
  );

  // TODO: TU LICZE PROMOCJE
  // TO NIE POWINNO BYC BRANE Z LOGICI CALCULATORA
  const ceilStorePriceByMonth = calculateCeilStorePriceByMonth(
    storageTime,
    transportStorePrice,
    boxesPriceStackedStore,
    boxesPriceNotStackedStore,
    promotion?.discount,
  );

  const calculateSummaryPrice = (
    rentPrice: number,
    ceilStorePriceByMonth: number,
    shopPrice: number,
  ) => {
    if (rentPrice + ceilStorePriceByMonth === 0) {
      if (shopPrice === 0) {
        return 0;
      }
      return (shopPrice + shopTransportPrice).toFixed(2);
    }
    return (rentPrice + ceilStorePriceByMonth + shopPrice).toFixed(2);
  };

  const summaryPrice = calculateSummaryPrice(
    rentPrice,
    ceilStorePriceByMonth,
    shopPrice,
  );

  localStorage.setItem('summaryPrice', JSON.stringify(summaryPrice));
  localStorage.setItem(
    'ceilStorePriceByMonth',
    JSON.stringify(ceilStorePriceByMonth),
  );
  localStorage.setItem('storePrice', JSON.stringify(storePrice));
  localStorage.setItem('rentPrice', JSON.stringify(rentPrice));
  localStorage.setItem('shopPrice', JSON.stringify(shopPrice));
  localStorage.setItem(
    'continuationRentPrice',
    JSON.stringify(continuationRentPrice),
  );

  const activeLimits = limits.filter((limit) => limit.status);

  const rebuildToolTip = () => {
    ReactTooltip.rebuild();
  };
  const hideToolTip = () => {
    ReactTooltip.hide();
  };

  const limitStorage = activeLimits.find(
    (item) => item.type === 'storage',
  )?.maxAmount;
  const limitRent = activeLimits.find(
    (item) => item.type === 'rent',
  )?.maxAmount;

  const disabledButton =
    summaryPrice === 0 ||
    ceilStorePriceByMonth > limitStorage ||
    rentPrice > limitRent;
  const isTransportsAmortizationBasket = location.pathname === '/return-boxes';

  const handleCheckPromotion = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(
        verifyPromotionCode(promotionCode, () => setShowErrorModal(true)),
      );
      setPromotionCode('');
    },
    [dispatch, promotionCode],
  );

  return (
    <>
      <Modal
        showModal={showErrorModal}
        setShowModal={setShowErrorModal}
        className={`${isMobileModal && 'promotion-error-modal_mobile'}`}
        dialogClassName={`${
          isMobileModal && 'promotion-error-modal_mobile-mask'
        }`}
      >
        <PromotionErrorModal
          setShowErrorModal={() => {
            setShowErrorModal(false);
          }}
        />
      </Modal>

      <div className="payment-form">
        <div
          className="payment-form_container"
          style={
            isMobile
              ? {
                  marginBottom: `${contentHeight}px`,
                }
              : {}
          }
        >
          <H5 className="payment-form_title desktop">
            {translate('paymentForm.toPay')}
          </H5>
          {isTransportsAmortizationBasket ? (
            <div className="payment-form_summary">
              <P className="summary-title">
                {translate('paymentForm.timeFee')}
              </P>
              <div className="line line--light" />
              {stocks.selectedAllBox ? (
                <div className="transport-product">
                  <P className="transport-info">
                    {translate('paymentForm.amortizationTransport')}
                  </P>
                  <P className="transport-info">
                    {stocks.amortizationTransportPrice.toFixed(2)}{' '}
                    {translate('default.currency')}
                  </P>
                </div>
              ) : (
                <ul className="payment-form_list">
                  {stocks.selectedBox.map((box) => (
                    <li key={box.id} className="item-product transport-product">
                      <P className="transport-info">
                        {translate('paymentForm.additionalTransport')}{' '}
                        {box.stockNumber}
                      </P>
                      <P className="transport-info">
                        {storageTransportPrice.toFixed(2)}{' '}
                        {translate('default.currency')}
                      </P>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : (
            <>
              {storeState.length !== 0 && (
                <div className="payment-form_summary">
                  <P className="summary-title">
                    {translate('paymentForm.periodicFee')}
                  </P>
                  <div className="line line--light" />
                  <div className="payment-form_result">
                    <div className="title-group">
                      <img
                        src={deleteIcon}
                        alt="delete"
                        onClick={() =>
                          dispatch(
                            removeProductFromBasket({
                              type: ProductType.storage,
                            }),
                          )
                        }
                        data-for="delete-tooltip"
                        data-tip={translate('paymentForm.deleteAllTooltip')}
                        onLoad={rebuildToolTip}
                        onClickCapture={hideToolTip}
                        className="delete-img"
                      />
                      <P className="product-name">
                        {translate('paymentForm.storage')}
                      </P>
                    </div>
                    <div className="price-group">
                      <P className="product-price">
                        {ceilStorePriceByMonth.toFixed(2)}{' '}
                        {translate('default.currency')}
                      </P>
                      {ceilStorePriceByMonth > limitStorage ? (
                        <P className="price-error">
                          {translate('paymentForm.limit') +
                            ' ' +
                            limitStorage +
                            ` ${translate('default.currency')}`}
                        </P>
                      ) : null}
                    </div>
                  </div>
                  <ul className="payment-form_list">
                    {storeState.map((item, idx) => (
                      <li key={idx} className="item-product">
                        <img
                          src={deleteIcon}
                          alt="delete"
                          onClick={() =>
                            dispatch(removeProductFromBasket(item))
                          }
                          data-for="delete-tooltip"
                          data-tip={translate('paymentForm.deleteTooltip')}
                          onLoad={rebuildToolTip}
                          onClickCapture={hideToolTip}
                          className="delete-img"
                        />
                        <P className="product-info">
                          {item.value} x {item.name}
                        </P>
                      </li>
                    ))}
                  </ul>
                  <P className="range">
                    {storeState.length !== 0 && storageRange}
                  </P>
                  <P className="subtitle">
                    {translate('paymentForm.subscriptionAmount')}
                    <span className="subtitle--orange">
                      {translate('paymentForm.subscriptionStart')}
                    </span>{' '}
                    {translate('paymentForm.subscriptionOf')}
                    <span className="subtitle--orange">{storageTime}</span>{' '}
                    {translate('paymentForm.subscriptionPeriod')}
                    {translate('paymentForm.totalCost')}
                    <span className="subtitle--orange">
                      {storePrice.toFixed(2)} {translate('default.currency')}
                    </span>
                    .
                  </P>
                </div>
              )}

              {rentState.length !== 0 && (
                <>
                  <div className="payment-form_summary">
                    <P className="summary-title">
                      {translate('paymentForm.timeFee')}
                    </P>
                    <div className="line line--light" />
                    <div className="payment-form_result">
                      <div className="title-group">
                        <img
                          src={deleteIcon}
                          alt="delete"
                          onClick={() =>
                            dispatch(
                              removeProductFromBasket({
                                type: ProductType.rent,
                              }),
                            )
                          }
                          data-for="delete-tooltip"
                          data-tip={translate('paymentForm.deleteTooltip')}
                          onLoad={rebuildToolTip}
                          onClickCapture={hideToolTip}
                          className="delete-img"
                        />
                        <P className="product-name">
                          {translate('paymentForm.rent')}
                        </P>
                      </div>
                      <div className="price-group">
                        <P className="product-price">
                          {rentPrice.toFixed(2)} {translate('default.currency')}
                        </P>
                        {rentPrice > limitRent ? (
                          <P className="price-error">
                            {translate('paymentForm.limit') +
                              ' ' +
                              limitRent +
                              ` ${translate('default.currency')}`}
                          </P>
                        ) : null}
                      </div>
                    </div>
                    <ul className="payment-form_list">
                      {rentState.map((item, idx) => (
                        <li key={idx} className="item-product">
                          <img
                            src={deleteIcon}
                            alt="delete"
                            onClick={() =>
                              dispatch(removeProductFromBasket(item))
                            }
                            data-for="delete-tooltip"
                            data-tip={translate('paymentForm.deleteTooltip')}
                            onLoad={rebuildToolTip}
                            onClickCapture={hideToolTip}
                            className="delete-img"
                          />
                          <P className="product-info">
                            {item.value} x {item.name}
                          </P>
                        </li>
                      ))}
                    </ul>
                    <P className="range">
                      {rentState.length !== 0 && rentRange}
                    </P>
                    <P className="subtitle">
                      {translate('paymentForm.indicatedAmount')}
                      <span className="subtitle--orange">{rentTime[0]}</span>
                      <span className="subtitle">{rentTime.slice(1)}</span>.
                    </P>
                  </div>
                </>
              )}

              {shopState.length !== 0 && (
                <div className="payment-form_summary">
                  <div className="payment-form_result">
                    <div className="title-group">
                      <img
                        src={deleteIcon}
                        alt="delete"
                        onClick={() =>
                          dispatch(
                            removeProductFromBasket({
                              type: ProductType.store,
                            }),
                          )
                        }
                        data-for="delete-tooltip"
                        data-tip={translate('paymentForm.deleteTooltip')}
                        onLoad={rebuildToolTip}
                        onClickCapture={hideToolTip}
                        className="delete-img"
                      />
                      <P className="product-name">
                        {translate('paymentForm.shop')}
                      </P>
                    </div>
                    <div className="price-group">
                      <P className="product-price">
                        {shopPrice.toFixed(2)} {translate('default.currency')}
                      </P>
                    </div>
                  </div>
                  <ul className="payment-form_list">
                    {shopState.map((item, idx) => (
                      <li key={idx} className="item-product shop-item">
                        <div className="shop-item_group">
                          <img
                            src={deleteIcon}
                            alt="delete"
                            onClick={() =>
                              dispatch(removeProductFromBasket(item))
                            }
                            data-for="delete-tooltip"
                            data-tip={translate('paymentForm.deleteTooltip')}
                            onLoad={rebuildToolTip}
                            onClickCapture={hideToolTip}
                            className="delete-img"
                          />
                          <P className="product-info">
                            {item.value} x {item.name}
                          </P>
                        </div>
                        {item.promotionPrice !== 0 ? (
                          // TODO: TU LICZE PROMOCJE
                          // To sa ceny wyswieetlane dla sklepu w koszyku, ale dlaczego tu musi byc liczenie?
                          <P className="product-info">
                            {calculatePriceWithDiscount({
                              price: item.promotionPrice * item.value,
                              discount: promotion?.discount,
                            }).toFixed(2)}{' '}
                            {translate('default.currency')}
                          </P>
                        ) : (
                          // TODO: TU LICZE PROMOCJE
                          <P className="product-info">
                            {calculatePriceWithDiscount({
                              price: item.price * item.value,
                              discount: promotion?.discount,
                            }).toFixed(2)}{' '}
                            {translate('default.currency')}
                          </P>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="payment-form_result shop-transport">
                <P>{translate('paymentForm.delivery')}</P>
                {storePrice + rentPrice !== 0 ? (
                  <P>{translate('paymentForm.free')}</P>
                ) : shopPrice === 0 ? null : (
                  <P>
                    {shopTransportPrice.toFixed(2)}{' '}
                    {translate('default.currency')}
                  </P>
                )}
              </div>
            </>
          )}
          <ReactTooltip id="delete-tooltip" multiline={true} />

          <div className="line" />
          <div className="payment-form_summary pay-now" ref={content}>
            {location.pathname === '/zamowienie' &&
              state.products.length !== 0 && (
                <>
                  <div className="promotion-title">
                    <P className="promotion-title_text">
                      {translate('paymentForm.promotion-code')}
                    </P>
                    <button
                      type="button"
                      className="promotion-title_button"
                      onClick={() =>
                        setVisiblePromotionForm(!visiblePromotionForm)
                      }
                    >
                      {visiblePromotionForm ? (
                        <svg
                          width="18"
                          height="4"
                          viewBox="0 0 18 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.6561 3.5H0.656067V0.5H17.6561V3.5Z"
                            fill="black"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.7402 7.21698H17.6561V10.1887H10.7402V18H7.5719V10.1887H0.656067V7.21698H7.5719V0H10.7402V7.21698Z"
                            fill="black"
                          />
                        </svg>
                      )}
                    </button>
                  </div>
                  {visiblePromotionForm &&
                    (promotion ? (
                      <div className="promotion-code_wrapper">
                        <img
                          src={deleteIcon}
                          alt="delete"
                          onClick={() => dispatch(removePromotionCode())}
                          data-for="delete-tooltip"
                          data-tip={translate(
                            'paymentForm.deletePromotionTooltip',
                          )}
                          onLoad={rebuildToolTip}
                          onClickCapture={hideToolTip}
                          className="delete-promotion-img"
                        />
                        <P>
                          {translate('paymentForm.code')} {promotion.name}{' '}
                          {translate('paymentForm.active')}
                        </P>
                      </div>
                    ) : (
                      <form
                        onSubmit={handleCheckPromotion}
                        className="promotion-form"
                      >
                        <input
                          className="custom-input"
                          value={promotionCode}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPromotionCode(e.target.value)
                          }
                          autoComplete="off"
                        />
                        <button
                          className="btn btn-secondary promotion-form_button"
                          type="submit"
                          disabled={!promotionCode}
                        >
                          {translate('paymentForm.activate')}
                        </button>
                      </form>
                    ))}
                </>
              )}
            <div className="payment-form_result pay-now_title">
              <P className="pay">{translate('paymentForm.payNow')}</P>
              {isTransportsAmortizationBasket ? (
                stocks.selectedAllBox ? (
                  <H5 className="summary-price">
                    {stocks.amortizationTransportPrice.toFixed(2)}{' '}
                    {translate('default.currency')}
                  </H5>
                ) : (
                  <H5 className="summary-price">
                    {(
                      stocks.selectedBox.length * storageTransportPrice
                    ).toFixed(2)}{' '}
                    {translate('default.currency')}
                  </H5>
                )
              ) : (
                <H5 className="summary-price">
                  {summaryPrice} {translate('default.currency')}
                </H5>
              )}
            </div>
            {isTransportsAmortizationBasket ? (
              <React.Fragment>
                <button
                  className="payment-form_button btn btn-large btn-100"
                  onClick={() => onSubmit()}
                  disabled={isValidated}
                >
                  {location.pathname === '/zamowienie'
                    ? translate('paymentForm.confirmOrder')
                    : translate('paymentForm.next')}
                </button>
                {isValidated ? (
                  <div className="error">{translate('errors.address')}</div>
                ) : null}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  className="payment-form_button btn btn-large btn-100"
                  onClick={() => onSubmit()}
                  disabled={
                    disabledButton || isValidated || isSubmitButtonDisable
                  }
                >
                  {location.pathname === '/zamowienie'
                    ? translate('paymentForm.confirmOrder')
                    : translate('paymentForm.next')}
                </button>
                {isSubmitButtonDisable ? (
                  <div className="error">
                    {translate('errors.agreementToPayInTheFuture')}
                  </div>
                ) : null}
                {isValidated ? (
                  <div className="error">{translate('errors.address')}</div>
                ) : null}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentForm;
