import { translate } from '@module/Translate/Translate';
import React, { useState } from 'react';

export default function PasswordRegisterInput({
  passwordError,
  formik,
}: {
  formik: any;
  passwordError: any;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const inputTypeCondition = showPassword ? 'text' : 'password';

  return (
    <div className="mb-lg-3">
      <label
        htmlFor="password"
        className={`d-sm-block font-weight-bold label--orange ${
          passwordError ? 'error-label' : ''
        }`}
      >
        {translate('registrationForm.password')}
      </label>
      <div className="form-password">
        <input
          type={inputTypeCondition}
          name="password"
          className={`custom-input ${passwordError ? 'error-input' : ''}`}
          id="password"
          autoComplete="off"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={translate('registrationForm.password')}
          value={formik.values.password}
        />
        <div
          className="form-password_button"
          onMouseDown={() => setShowPassword(true)}
          onTouchStart={() => setShowPassword(true)}
          onMouseUp={() => setShowPassword(false)}
          onMouseLeave={() => setShowPassword(false)}
          onTouchEnd={() => setShowPassword(false)}
        >
          {translate('registrationForm.show')}
        </div>
      </div>
      {passwordError ? (
        passwordError
      ) : formik.touched.password ? null : (
        <div className="notifications-feedback">
          {translate('errors.needCharacters')}
        </div>
      )}
    </div>
  );
}
