import React from 'react';
import './PaginationBar.scss';

export default function PaginationBar(props: {
  currentPage: number;
  totalPages: number;
  paginate: any;
}) {
  const pageNumbers: number[] = [];

  if (props.currentPage === 1 && props.totalPages >= 3) {
    addPagesToArray(props.currentPage, props.totalPages);
  } else if (
    props.currentPage > 1 &&
    props.currentPage < props.totalPages &&
    props.totalPages >= 3
  ) {
    addPagesToArray(props.currentPage - 1, props.totalPages);
  } else if (props.currentPage === props.totalPages && props.totalPages >= 3) {
    addPagesToArray(props.currentPage - 2, props.totalPages);
  } else if (props.totalPages < 3 && props.currentPage <= props.totalPages) {
    addPagesToArray(props.currentPage, props.totalPages);
  }

  function addPagesToArray(currentPage: number, totalPages: number) {
    let pageNumber = currentPage;

    if (totalPages >= 3) {
      for (let i = 0; i < 3; i++) {
        pageNumbers.push(pageNumber);
        pageNumber++;
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    }
  }

  return (
    <div className="pageNumberSelector">
      <ul className="numberContainer">
        {pageNumbers.map((number) =>
          props.currentPage === number ? (
            <li key={number} className="singleNumber_currentPage">
              {number}
            </li>
          ) : (
            <li
              key={number}
              onClick={() => props.paginate(number)}
              className="singleNumber"
            >
              {number}
            </li>
          ),
        )}
      </ul>
    </div>
  );
}
