import React from 'react';
import CompleteIcon from '@images/offer/icon-artwork.png';
import './OfferCardItem.scss';
import P from '@component/Text/Text';

export const OfferCardItem = ({ item }: any) => {
  return (
    <>
      <div className="homepage-offer-card-item">
        <img src={CompleteIcon} alt="punkt" />
        <P className="fs24 fw400 pl-2 text-break">{item.text}</P>
      </div>
    </>
  );
};

export default OfferCardItem;
