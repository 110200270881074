import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { ProductGroup } from '../../../../../../types/products/product-group.enum';
import { ProductType } from '../../../../../../types/products/product-type.enum';
import {
  addProductAction,
  editProductAction,
  getProductsAction,
  syncPackages,
  syncProducts,
} from '../../../../Network/Actions/Products';
import { RootState } from '../../../../store';
import { ProductsState } from '../../../../Type/Custom';
import './Products.scss';
import EditProduct from './components/Edit/EditProduct';
import { InputText } from 'primereact/inputtext';
import Modal from '../../../../Component/Modal/Modal';
import AddProduct from './components/Add/AddProduct';
import { CreateProductDto } from '../../../../../../types/products/create-product.dto';
import { translate } from '../../../../Module/Translate/Translate';
import { Messages } from 'primereact/messages';
import { UserProps } from '../../interfaces/interfaces';
import { formatDateToLocalTime } from '../../../../Util/Dates';

const LIMIT_ON_PAGE = 20;

export const SetProductContext = React.createContext({});
export const EditProductContext = React.createContext({});

export default function Products({ user }: UserProps) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [showProductModal, setshowProductModal] = useState(false);
  const [mount, setMount] = useState(true);
  const message: any = useRef(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    code: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );

  useEffect(() => {
    if (mount) {
      dispatch(getProductsAction(false));
      setMount(false);
    } else if (refresh) {
      dispatch(getProductsAction(false));
      setRefresh(false);
    }
  }, [refresh]);

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'status') {
      return rowData[item.field]
        ? translate('adminTool.products.accepted')
        : translate('adminTool.products.notAccepted');
    }
    if (item.field === 'type') {
      switch (rowData[item.field]) {
        case ProductType.rent:
          return translate('adminTool.products.rent');
        case ProductType.storage:
          return translate('adminTool.products.storage');
        case ProductType.store:
          return translate('adminTool.products.shop');
      }
    }
    if (item.field === 'group') {
      switch (rowData[item.field]) {
        case ProductGroup.box:
          return translate('adminTool.products.box');
        case ProductGroup.packageBox:
          return translate('adminTool.products.packageBox');
        case ProductGroup.shop:
          return translate('adminTool.products.shop');
      }
    }
    if (item.field === 'isVisibleOnOrderPanel') {
      switch (rowData[item.field]) {
        case true:
          return translate('adminTool.products.visible');
        case false:
          return translate('adminTool.products.notVisible');
        default:
          return translate('adminTool.products.notApplicable');
      }
    }

    if (item.field === 'isPromo') {
      switch (rowData[item.field]) {
        case true:
          return translate('adminTool.products.visible');
        case false:
          return translate('adminTool.products.notVisible');
        default:
          return translate('adminTool.products.notApplicable');
      }
    }
  };

  const imageBodyTemplate = (rowData: any) => {
    const image: any = rowData.images.find(
      ({ type }: any) => type === 'miniature',
    );
    if (image) {
      return <img src={image.url} alt="product" className="product-image" />;
    } else {
      return <p>{translate('adminTool.products.noImage')}</p>;
    }
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <EditProduct
        product={data}
        editor={user}
        setRefresh={setRefresh}
        showMessage={showMessage}
      />
    );
  };

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const setProduct = (product: CreateProductDto) => {
    addProductAction(product)
      .then(() => {
        setRefresh(true);
        setshowProductModal(false);
        showMessage('success', translate('adminTool.products.productAdded'));
      })
      .catch((error: any) => {
        showMessage('error', error);
      });
  };

  const editProduct = (product: any) => {
    product.updatedBy = user.email;
    editProductAction(product)
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.products.updated'));
      })
      .catch((error: any) => {
        if (error.response.status === 400) {
          showMessage(
            'error',
            translate('adminTool.products.updateProductError'),
          );
          return;
        }
        showMessage('error', error);
      });
  };

  const syncWarehouseStocks = () => {
    const wmsIdMag = 'MAG';
    syncProducts(wmsIdMag)
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.products.sync'));
      })
      .catch((error: any) => {
        showMessage('error', translate('adminTool.products.errSync'));
      });
  };

  const syncWarehousePackages = () => {
    syncPackages()
      .then(() => {
        setRefresh(true);
        showMessage('success', translate('adminTool.products.syncPackages'));
      })
      .catch((error: any) => {
        showMessage('error', translate('adminTool.products.errPack'));
      });
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div className="btns">
          <button
            type="submit"
            className={`submitForm invoice__button m-auto button_accept`}
            onClick={() => setshowProductModal(true)}
          >
            {translate('adminTool.products.add')}
          </button>
          <button
            type="submit"
            className={`submitForm invoice__button m-auto button_accept`}
            onClick={() => syncWarehouseStocks()}
          >
            {translate('buttons.syncProducts')}
          </button>
          <button
            type="submit"
            className={`submitForm invoice__button m-auto button_accept`}
            onClick={() => syncWarehousePackages()}
          >
            {translate('buttons.syncPackages')}
          </button>
        </div>

        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder={translate('adminTool.products.search')}
          />
        </span>
      </div>
    );
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    const allFilters = { ...filters };
    allFilters['global'].value = value;

    setFilters(allFilters);
    setGlobalFilterValue(value);
  };

  const primeMsgStyle = {
    position: 'absolute',
    zIndex: 1,
    width: '30rem',
  } as const;

  return (
    <div className="products-table-container">
      <SetProductContext.Provider value={setProduct}>
        <EditProductContext.Provider value={editProduct}>
          <Messages style={primeMsgStyle} ref={message} />
          <Modal
            showModal={showProductModal}
            setShowModal={setshowProductModal}
          >
            <AddProduct setProduct={setProduct} />
          </Modal>

          <DataTable
            value={products}
            responsiveLayout="scroll"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            dataKey="id"
            paginator
            stripedRows
            header={renderHeader}
            selectionPageOnly
            emptyMessage={translate('adminTool.common.nodata')}
            className="datatable-responsive"
            rows={LIMIT_ON_PAGE}
            expandedRows={expandedRows}
            onRowToggle={(e: any) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            globalFilterFields={['name', 'code']}
            filters={filters}
          >
            <Column expander style={{ width: '3em' }} />
            <Column
              field="id"
              sortable
              header={translate('adminTool.products.id')}
            />
            <Column
              field="code"
              filterField="code"
              sortable
              header={translate('adminTool.products.code')}
            />
            <Column
              field="name"
              filterField="name"
              sortable
              header={translate('adminTool.products.name')}
            />
            <Column
              field="image"
              header={translate('adminTool.products.image')}
              body={imageBodyTemplate}
            />
            <Column
              field="group"
              sortable
              header={translate('adminTool.products.group')}
              body={labelChecker}
            />
            <Column
              field="type"
              sortable
              header={translate('adminTool.products.type')}
              body={labelChecker}
            />
            <Column
              field="status"
              sortable
              header={translate('adminTool.products.status')}
              body={labelChecker}
            />
            <Column
              field="isVisibleOnOrderPanel"
              sortable
              header={translate('adminTool.products.isVisibleOnOrderPanel')}
              body={labelChecker}
            />
            <Column
              field="isPromo"
              sortable
              header={translate('adminTool.products.isPromo')}
              body={labelChecker}
            />
            <Column
              field="createdAt"
              sortable
              body={formatDateToLocalTime}
              header={translate('adminTool.products.createdAt')}
            />
            <Column
              field="updatedAt"
              sortable
              body={formatDateToLocalTime}
              header={translate('adminTool.products.updatedAt')}
            />
            <Column
              field="updatedBy"
              sortable
              header={translate('adminTool.products.updatedBy')}
            />
          </DataTable>
        </EditProductContext.Provider>
      </SetProductContext.Provider>
    </div>
  );
}
