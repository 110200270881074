import React, { useState } from 'react';
import './AddressCardItem.scss';
import editIcon from '@images/card/editIcon.png';
import Modal from '@component/Modal/Modal';
import DeleteAddressForm from '@form/DeleteAddressForm/DeleteAddressForm';
import EditAddressForm from '@form/EditAddressForm/EditAdressForm';
import { translate } from '@module/Translate/Translate';

export const AddressCardItem = ({
  item,
  removeAddress,
  editAddress,
  user,
}: any) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <Modal
        className="dialog--large"
        showModal={showEditModal}
        setShowModal={setShowEditModal}
      >
        <EditAddressForm
          item={item}
          setShowModal={setShowEditModal}
          editAddress={editAddress}
          editItem={true}
          removeAddress={() => setShowDeleteModal(!showDeleteModal)}
        />
      </Modal>

      <Modal
        className="dialog--small"
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
      >
        <DeleteAddressForm
          item={item}
          setShowModal={setShowDeleteModal}
          removeAddress={removeAddress}
        />
      </Modal>

      <div className="address-card-item">
        <div className="wrapper d-flex position-relative">
          <div className="w-100 position-relative">
            <img
              src={editIcon}
              alt={translate('addressBlock.altEdit')}
              className="d-block d-lg-none address-card-item_icon"
              onClick={() => setShowEditModal(true)}
            />

            <p className="title">{translate('addressBlock.deliveryAddress')}</p>
            <p className="name">
              {item.name} {item.restOfName || ''}
            </p>

            <div className="user-address-information">
              <div className="address-card-item_container">
                <p className="address_item">{item.street}</p>
              </div>

              <div className="address-card-item_container flex">
                <p className="address_item">
                  {item.postalCode} {item.city}
                </p>
              </div>

              <div className="address-card-item_container">
                <p className="address_item">{user.phone}</p>
              </div>
            </div>

            <div className="d-none d-lg-flex btn_placeholder" />
            <div className="d-none d-lg-flex btn_wrapper w-100 position-absolute">
              <button
                className="btn btn-dark btn-small"
                onClick={() => setShowEditModal(true)}
              >
                {translate('addressBlock.editAddress')}
              </button>
              <button
                className="btn btn-dark btn-small"
                onClick={() => setShowDeleteModal(true)}
              >
                {translate('addressBlock.delete')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressCardItem;
