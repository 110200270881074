import React from 'react';
import { H5 } from '../../../../../../../Component/Text/Text';
import { User } from '../../../../../../../models/user.model';
import { translate } from '../../../../../../../Module/Translate/Translate';

interface Props {
  user: User;
}

export default function CompanyData({ user }: Props) {
  return (
    <div className="col col-6">
      <h2 className="users-header">
        {translate('adminTool.users.companyData')}
      </h2>
      <br />
      <H5 className="users-h5">{translate('adminTool.users.companyName')}</H5>
      <p>{`${user.companyDetails?.name} ${
        user.companyDetails?.restOfName || ''
      }`}</p>
      <H5 className="users-h5">{translate('adminTool.users.taxNumber')}</H5>
      <p>{user.companyDetails?.taxNumber}</p>
      <H5 className="users-h5">{translate('adminTool.users.street')}</H5>
      <p>{user.companyDetails?.street}</p>
      <H5 className="users-h5">{translate('adminTool.users.city')}</H5>
      <p>{user.companyDetails?.city}</p>
      <H5 className="users-h5">{translate('adminTool.users.postalCode')}</H5>
      <p>{user.companyDetails?.postalCode}</p>
    </div>
  );
}
