import Spinner from '@component/Spinner/Spinner';
import { http } from '@network/NetworkHTTP';
import { FindStatusDto } from '@rootTypes/statuses/find-status.dto';
import { OrderStatusDTO } from '@rootTypes/statuses/order-status.dto';
import unifiedFormatDate from '@util/Dates';
import { useEffect, useState } from 'react';

export default function SingleOrderHistory(props: { orderNo: string }) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [statusList, setStatusList] = useState<OrderStatusDTO[]>();

  const getData = async () => {
    setIsLoading(true);
    const { data } = await http.get(`statuses/order/${props.orderNo}`);
    setStatusList(data);
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  if (!isLoading && statusList !== undefined)
    return (
      <ul>
        {statusList.map((status: FindStatusDto, idx: number) => {
          return (
            <li key={idx}>
              <div className="bullet">
                <svg aria-hidden="true" viewBox="0 0 32 32" focusable="false">
                  <circle stroke="none" cx="16" cy="16" r="10"></circle>
                </svg>
              </div>
              <div className="bulletPoints">
                <p className="bulletPoints-date">
                  {' '}
                  {unifiedFormatDate(new Date(status.date), true, true)}{' '}
                </p>
                <p className="bulletPoints-text"> {status.status} </p>
              </div>
            </li>
          );
        })}
      </ul>
    );
  else return <Spinner />;
}
