import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { http } from '@network/NetworkHTTP';
import { RootState } from '@src/store';
import { ORDERS_REQUEST, ORDERS_SUCCESS, ORDERS_FAIL } from './Types';

export const getUserOrdersAction = (
  id: number,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ORDERS_REQUEST,
      });
      const { data } = await http.get(`orders/user/${id}`);

      dispatch({
        type: ORDERS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (err) {
      onError();
      dispatch({
        type: ORDERS_FAIL,
        payload: 'Przepraszamy ale serwis niedostępny. Spróbuj później.',
      });
    }
  };
};

export const getUserActiveOrdersAction = (
  id: number,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ORDERS_REQUEST,
      });
      const { data } = await http.get(`orders/active/user/${id}`);

      dispatch({
        type: ORDERS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (err) {
      onError();
      dispatch({
        type: ORDERS_FAIL,
        payload: 'Przepraszamy ale serwis niedostępny. Spróbuj później.',
      });
    }
  };
};
export const getAdminToolUsers = async (params: any) => {
  try {
    const { data } = await http.get(
      `/orders/admin?take=${params.rows}&skip=${params.first}&sortField=${
        params.sortField
      }&sortOrder=${params.sortOrder}${
        params.search !== undefined
          ? `&search=${params.search.toLowerCase()}`
          : ''
      }`,
    );

    return data;
  } catch (error) {
    console.error({
      from: 'getAdminToolUsers',
      error,
    });
  }
};

export const getAdminToolUserOrders = async (params: any) => {
  return await http.get(
    `/orders/user-orders?take=${params.rows}&skip=${params.first}&sortField=${
      params.sortField
    }${
      params.sortOrder !== null
        ? `&sortOrder=${params.sortOrder}`
        : `&sortOrder=`
    }&isOrderActive=${params.isOrderActive}&userId=${params.userId}`,
  );
};
