import React, { useEffect, useState } from 'react';
import './VerifyReceiveReturnBoxesForm.scss';
import { useSelector } from 'react-redux';
import { FindOrderDto } from '../../../../types/orders/find-order.dto';
import { UpdateStockDto } from '../../../../types/stocks/update-stock.dto';
import P, { H3 } from '../../Component/Text/Text';
import { translate } from '../../Module/Translate/Translate';
import { RootState } from '../../store';
import { UserState } from '../../Type/Custom';
import {
  requestCourierFromMultiStorageClientIW,
  requestCourierFromMultiStorageWarehouseATCF,
} from '../../Network/Actions/Stocks';
import { StockStatus } from '../../../../types/stocks/stock-status.enum';
import unifiedFormatDate from '../../Util/Dates';

interface Props {
  setVerifyReceiveReturnModal: any;
  editedBoxes: UpdateStockDto[];
  setRefresh?: any;
  order: FindOrderDto;
  setShowReceiveModal: any;
  date: Date;
  setCourierOrdered?: any;
  visibleSpinner: (value: boolean) => void;
  showError: (value: boolean) => void;
}

function createWmsOrder(
  editedBoxes: UpdateStockDto[],
  date: Date,
  setVerifyReceiveReturnModal: any,
  setCourierOrdered: any,
  setShowConfirm: any,
  user: any,
  setShowSpinner: (value: boolean) => void,
) {
  setShowSpinner(true);

  if (!editedBoxes) return;
  const pickupTimeFrom = '06:00';
  const pickupTimeTo = '18:00';
  const stocks: any[] = [];
  editedBoxes.forEach((stock: any) => {
    stocks.push(+stock.id);
  });

  if (editedBoxes[0].stockStatus === StockStatus.STOCK_IW) {
    requestCourierFromMultiStorageClientIW({
      stocks: stocks,
      activeDate: date,
      endDate: null,
      pickupTimeFrom: pickupTimeFrom,
      pickupTimeTo: pickupTimeTo,
      phone: user.phone,
      deliveryAddress: editedBoxes[0].deliveryAddress,
      returnedAddress: editedBoxes[0].returnAddress,
    })
      .then(() => {
        setVerifyReceiveReturnModal(false);
        setCourierOrdered(true);
      })
      .catch(() => setShowConfirm(true))
      .finally(() => setShowSpinner(false));
  } else if (editedBoxes[0].stockStatus === StockStatus.STOCK_ATCF) {
    requestCourierFromMultiStorageWarehouseATCF({
      stocks: stocks,
      activeDate: date,
      endDate: null,
      pickupTimeFrom: pickupTimeFrom,
      pickupTimeTo: pickupTimeTo,
      phone: user.phone,
    })
      .then(() => {
        setVerifyReceiveReturnModal(false);
        setCourierOrdered(true);
      })
      .catch(() => {
        setShowConfirm(true);
      })
      .finally(() => setShowSpinner(false));
  }
}

const VerifyReceiveReturnBoxesForm: React.FC<
  React.PropsWithChildren<Props>
> = ({
  setVerifyReceiveReturnModal,
  editedBoxes,
  setShowReceiveModal,
  order,
  date,
  setCourierOrdered,
  visibleSpinner,
  showError,
}) => {
  const { user }: UserState = useSelector<RootState, UserState>(
    ({ user }) => user,
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  useEffect(() => {
    visibleSpinner(showSpinner);
    showError(showConfirm);
  }, [showConfirm, showSpinner]);

  return (
    <>
      <div className="verify-receive-return">
        <div className="verify-receive-return_title-group">
          <H3>verifyReceiveReturnBoxesForm.title</H3>
          <P>verifyReceiveReturnBoxesForm.subtitle</P>
        </div>

        <div className="verify-receive-return_group">
          <P>verifyReceiveReturnBoxesForm.order</P>
          <P className="accent-text">nr {order?.id}</P>
        </div>

        <div className="verify-receive-return_group">
          <P>verifyReceiveReturnBoxesForm.deliveryDate</P>
          <P className="accent-text">{unifiedFormatDate(date)}</P>
        </div>

        <div className="verify-receive-return_group">
          <P>verifyReceiveReturnBoxesForm.addressDelivery</P>
          <P className="accent-text">
            {editedBoxes[0].deliveryAddress?.street}
          </P>
          <P className="accent-text">
            {editedBoxes[0].deliveryAddress?.postalCode}&nbsp;
            {editedBoxes[0].deliveryAddress?.city}
          </P>
          <P className="accent-text">{user.phone}</P>
        </div>

        <div className="verify-receive-return_group">
          <P>verifyReceiveReturnBoxesForm.addressReturn</P>
          <P className="accent-text">{editedBoxes[0].returnAddress?.street}</P>
          <P className="accent-text">
            {editedBoxes[0].returnAddress?.postalCode}&nbsp;
            {editedBoxes[0].returnAddress?.city}
          </P>
          <P className="accent-text">{user.phone}</P>
        </div>

        <button
          className="btn btn-100"
          onClick={() =>
            createWmsOrder(
              editedBoxes,
              date,
              setVerifyReceiveReturnModal,
              setCourierOrdered,
              setShowConfirm,
              user,
              setShowSpinner,
            )
          }
        >
          {translate('verifyReceiveReturnBoxesForm.accept')}
        </button>
        <button
          className="btn btn-dark btn-100"
          onClick={() => {
            setShowReceiveModal(true);
            setVerifyReceiveReturnModal(false);
          }}
        >
          {translate('verifyReceiveReturnBoxesForm.edit')}
        </button>
      </div>
    </>
  );
};

export default VerifyReceiveReturnBoxesForm;
