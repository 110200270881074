import React from 'react';
import './PromotionErrorModal.scss';
import { translate } from '@module/Translate/Translate';
import promotionModalIcon from '@images/rent-page/Union.jpg';
import P from '@component/Text/Text';

interface IProps {
  setShowErrorModal: () => void;
}

function PromotionErrorModal({ setShowErrorModal }: IProps) {
  return (
    <div className="promotion-error-modal">
      <img src={promotionModalIcon} alt="" />
      <P className="promotion-error-modal_text">
        {translate('paymentForm.promotion-error')}
      </P>
      <button
        className="btn promotion-error-modal_button"
        onClick={() => setShowErrorModal()}
      >
        {translate('paymentForm.button-close')}
      </button>
    </div>
  );
}

export default PromotionErrorModal;
