import { P24_URL } from '@/envConfig';
import Modal from '@component/Modal/Modal';
import P, { H1 } from '@component/Text/Text';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import ChangePaymentMethodForm from '@form/ChangePaymentMethodForm/ChangePaymentMethodForm';
import { User } from '@models/user.model';
import { translate } from '@module/Translate/Translate';
import { unpiadOrdersAction, invokePayment } from '@network/Actions/Payment';
import getApiEndPoint from '@network/NetworkApiEndpoint';
import { CreateTransactionResponseDto } from '@rootTypes/payments/dto/create-transaction-response.dto';
import { InvokeTransactionDTO } from '@rootTypes/payments/dto/invoke-transaction.dto';
import { UnpaidOrderDTO } from '@rootTypes/payments/dto/unpaid-orders.dto';
import { RootState } from '@src/store';
import { UnpaidOrderState, PaymentMethodChangeState } from '@type/Custom';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PaymentCard from './components/PaymentCard/PaymentCard';
import './Payment.scss';

interface Props {
  user: User;
  setSpinner: (value: boolean) => void;
}

const Payment = ({ user, setSpinner }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  const { unpaidOrders } = useSelector<RootState, UnpaidOrderState>(
    (state) => state.unpaidOrders,
  );
  const paymentMethodData = useSelector<RootState, PaymentMethodChangeState>(
    (state) => state.userPaymentMethod,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(unpiadOrdersAction(user.id, error));
  }, [paymentMethodData.unchanged]);

  const error = () => {
    setShowError(true);
  };

  const makePayment = (invokeTransaction: InvokeTransactionDTO) => {
    const invokePaymentEndpoint = getApiEndPoint('invokeTransaction');
    setSpinner(true);

    invokePayment(invokePaymentEndpoint, invokeTransaction)
      .then((res: AxiosResponse<CreateTransactionResponseDto>) => {
        if (res.data.transactionToken) {
          window.open(
            `${P24_URL}/trnRequest/${res.data.transactionToken}`,
            '_self',
          );
        }
        if (res.data.redirectUrl) {
          window.open(res.data.redirectUrl, '_self');
        }
      })
      .catch(() => {
        setShowError(true);
      })
      .finally(() => setSpinner(false));
  };

  return (
    <>
      <Modal
        className="dialog--middle"
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
      >
        <ChangePaymentMethodForm
          setShowModal={setIsModalOpen}
          showError={(value) => setShowError(value)}
          setSpinner={(value) => setSpinner(value)}
        />
      </Modal>

      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>

      <div className="payment">
        <div className="payment-header">
          <div className="container-header payment-header_title-wrapper">
            <H1 className="container-title">dashboard.paymentMethods.title</H1>
            <P className="container-subtitle">dashboard.paymentMethods.text</P>
          </div>
          <button
            className="payment-header_btn btn btn-small btn-dark d-none d-lg-flex"
            onClick={() => setIsModalOpen(true)}
          >
            {translate('dashboard.paymentMethods.changePaymentMethod')}
          </button>
        </div>
        <div className="paymentCard-wrapper row mb-5">
          {unpaidOrders.map((payment: UnpaidOrderDTO) => {
            return (
              <PaymentCard
                key={payment.orderId}
                unpaidOrder={payment}
                makePayment={makePayment}
                user={user}
              />
            );
          })}
        </div>
        <div className="payment-footer">
          <button
            className="payment-footer_btn btn btn-dark d-flex d-lg-none"
            onClick={() => setIsModalOpen(true)}
          >
            {translate('dashboard.paymentMethods.changePaymentMethod')}
          </button>
        </div>
      </div>
    </>
  );
};

export default Payment;
