import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { getAdminUnpaidPaymentHistory } from '@network/Actions/Payment';
import { UnpaidOrderDTO } from '@rootTypes/payments/dto/unpaid-orders.dto';
import { translate } from '@module/Translate/Translate';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import P24 from '@images/registration/P24.png';
import Blik from '@images/registration/Blik.png';
import Card from '@images/card/card.png';

interface IProps {
  userId: number;
}

export default function UnpaidPaymentsTable({ userId }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [unpaidPaymentsList, setUnpaidPaymentsList] = useState<any>();
  const getData = async () => {
    setIsLoading(true);

    getAdminUnpaidPaymentHistory(userId)
      .then((data: UnpaidOrderDTO) => {
        setUnpaidPaymentsList(data);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const imageBody = (data: UnpaidOrderDTO) => {
    switch (data.paymentMethod) {
      case PaymentMethodEnum.BLIK:
        return <img src={Blik} alt="Blik" className="adminTool-payments_img" />;
      case PaymentMethodEnum.P24:
        return <img src={P24} alt="P24" className="adminTool-payments_img" />;
      case PaymentMethodEnum.CARD:
        return <img src={Card} alt="Card" className="adminTool-payments_img" />;
      default:
        return null;
    }
  };

  return (
    <DataTable
      value={unpaidPaymentsList}
      responsiveLayout="scroll"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      dataKey="id"
      lazy
      loading={isLoading}
      stripedRows
      selectionPageOnly
      emptyMessage="No data found"
      className="datatable-responsive"
    >
      <Column
        field="orderId"
        header={translate('adminTool.payments.orderId')}
      />
      <Column field="paymentId" header={translate('adminTool.payments.id')} />
      <Column
        field="feeEndDate"
        header={translate('dashboard.paymentMethods.feeTo')}
      />
      <Column field="amount" header={translate('adminTool.payments.amount')} />
      <Column field="currency" header={translate('adminTool.payments.value')} />
      <Column
        field="paymentMethod"
        header={translate('adminTool.payments.method')}
        body={imageBody}
      />
    </DataTable>
  );
}
