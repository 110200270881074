import { Oval } from 'react-loader-spinner';
import './Spinner.scss';

export default function Spinner() {
  return (
    <div className="spinner">
      <Oval
        ariaLabel="loading-indicator"
        color="var(--color-orange)"
        secondaryColor="var(--color-white)"
        strokeWidth={5}
        height={100}
        width={100}
      />
    </div>
  );
}
