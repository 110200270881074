import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import Slide from './components/Slide/Slide';
import 'slick-carousel/slick/slick.css';
import './CarouselBlock.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Peanut from '@images/offer/peanut.png';
import notFoundImage from '@images/shop/image-not-availableLight.png';
import { BasketState, DefaultSettingsState } from '@type/Custom';
import { translate } from '@module/Translate/Translate';
import { addProductInBasket } from '@network/Actions/Basket';
import { DefaultSettingsTypeEnum } from '@rootTypes/default-settings/default-settings-type.enum';
import { DescriptionTypeEnum } from '@rootTypes/product-descriptions/description-type.enum';
import { FindProductDto } from '@rootTypes/products/find-product.dto';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { RootState } from '@src/store';
import { BoxesSort } from '@util/BoxesSort';
import { textFormater } from '@util/TextFormater';
import { ImageTypeEnum } from '@rootTypes/product-images/image-type.enum';
import P, { H2, H3 } from '@component/Text/Text';

function NextArrow(props: any) {
  const { onClick } = props;

  return (
    <div className="arrow-right d-none d-md-flex col-2 justify-content-end carousel-image-height">
      <div className="my-auto c-pointer" style={{ transform: 'rotate(90deg)' }}>
        <div className="triangle" onClick={onClick} />
      </div>
    </div>
  );
}

function PrevArrow(props: any) {
  const { onClick } = props;

  return (
    <div className="arrow-left d-none d-md-flex col-2 justify-content-start carousel-image-height">
      <div
        className="my-auto c-pointer"
        style={{ transform: 'rotate(-90deg)' }}
      >
        <div className="triangle" onClick={onClick} />
      </div>
    </div>
  );
}

interface IProps {
  products: FindProductDto[];
  descriptionPath: DescriptionTypeEnum;
  isSubPath?: boolean;
  title?: string;
}

const CarouselBlock = ({
  products,
  isSubPath,
  title,
  descriptionPath,
}: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  const defaultCarouselBoxByCapacity: number =
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultCarouselBoxByCapacity,
    )?.limit || 0;

  const sorted = [
    ...products.filter(
      ({ productDetails }: FindProductDto) =>
        productDetails.capacity < defaultCarouselBoxByCapacity,
    ),
    ...products.filter(
      ({ productDetails }: FindProductDto) =>
        productDetails.capacity == defaultCarouselBoxByCapacity,
    ),
    ...products.filter(
      ({ productDetails }: FindProductDto) =>
        productDetails.capacity > defaultCarouselBoxByCapacity,
    ),
  ];

  const initSlide =
    sorted.length &&
    sorted.findIndex(
      (box: FindProductDto) =>
        box.productDetails?.capacity === defaultCarouselBoxByCapacity,
    );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const basketState = useSelector<RootState, BasketState>(
    ({ basket }) => basket,
  );

  const onHandleSubmit = (item: any) => {
    const filteredItem = basketState.products.find(
      (product: any) =>
        item.type === product.type &&
        item.group === product.group &&
        item.id === product.id,
    );
    if (filteredItem) {
      dispatch(
        addProductInBasket({
          data: { ...item, value: filteredItem.value + 1 },
        }),
      );
    } else {
      dispatch(addProductInBasket({ data: { ...item, value: 1 } }));
    }

    const findPeriod = basketState.periods.find(
      (period) => period.type === ProductType.storage,
    );
    if (findPeriod) {
      history.push({
        pathname: '/zamowienie',
      });
    } else {
      history.push({
        pathname: '/zamowienie',
        state: {
          monthQuantity: 3,
        },
      });
    }
  };

  const scrollRef: any = useRef(null);
  const executeScroll = () => {
    if (scrollRef) scrollRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (isSubPath) executeScroll();
  }, [scrollRef]);

  const findImage = (productImages: any) => {
    return productImages.find(
      (image: any) => image.type === ImageTypeEnum.offer,
    );
  };

  return (
    <>
      <article
        ref={scrollRef}
        id="carousel-products"
        className="store-carousel-block"
      >
        <div className="container store-carousel-block_container">
          <div className="store-carousel-block_header">
            <H2 className="store-carousel-block_title">
              {title
                ? translate(title)
                : translate('howItWorks.carouselBlock.title')}
            </H2>
          </div>
          <div className="row ">
            <div className="w-100">
              <div className="slider boxes">
                {sorted.length && (
                  <Slider {...settings} initialSlide={initSlide}>
                    {sorted.map((item: any, index: number) => (
                      <div key={index + 'slide'}>
                        <Slide item={item} />
                        <p
                          className="carousel-slide_size"
                          dangerouslySetInnerHTML={{
                            __html: item.descriptions.find(
                              (description: any) =>
                                description.type ===
                                DescriptionTypeEnum.carousel,
                            )?.text,
                          }}
                        ></p>
                        <div className="link-wrapper">
                          <button
                            className="store-carousel-block_link"
                            onClick={() => onHandleSubmit(item)}
                          >
                            {translate('offer.carouselBlock.orderBox')}
                          </button>
                        </div>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          </div>
          {BoxesSort(
            products.filter(
              ({ group, type }: any) =>
                group === ProductGroup.box && type === ProductType.storage,
            ),
          ).map((item: any, idx) => (
            <div
              key={item.id + item.name.split(' ')[1]}
              className="offer__boxS no-gutters align-items-center"
              id={'box' + item.name.split(' ')[1]}
            >
              <div className="store-carousel-block-images">
                {findImage(item.images) && (
                  <>
                    <img src={Peanut} alt="peanut" className="peanut" />
                    {(idx + 1) % 3 === 0 && (
                      <img
                        src={Peanut}
                        alt="peanut"
                        className="peanut-second"
                      />
                    )}
                  </>
                )}

                <img
                  src={
                    item.images.find(
                      (image: any) => image.type === ImageTypeEnum.offer,
                    )?.url ?? notFoundImage
                  }
                  className="img-offer"
                  alt="ubrania"
                />
              </div>
              <div className="text-wrapper">
                <H3>
                  {item.name.split(' ')[0]}&nbsp;
                  <span className="text-primary">
                    {item.name.split(' ')[1]}
                  </span>
                </H3>
                <p
                  className="image-block_size"
                  dangerouslySetInnerHTML={{
                    __html: item.descriptions.find(
                      (description: any) =>
                        description.type === DescriptionTypeEnum.carousel,
                    )?.text,
                  }}
                ></p>
                <P className="text-description">
                  {textFormater(
                    item.descriptions.find(
                      (description: any) =>
                        description.type === descriptionPath,
                    )?.text ?? '',
                  )}
                </P>
              </div>
            </div>
          ))}
        </div>
      </article>
    </>
  );
};

export default CarouselBlock;
