import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Users.scss';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@module/Translate/Translate';
import { UsersState } from '@type/Custom';
import { RootState } from '@src/store';
import { UserRawRoles } from '@models/user.model';
import { formatDateToLocalTime } from '@util/Dates';
import { UserProps } from '@view/AdminTool/interfaces/interfaces';
import EditUser from './components/EditUser';
import { getAllUserByType } from '@network/Actions/Users';
import FilterHeader from './components/components/filter/FilterHeader';
import useDebounce from '@util/Debounse';
const LIMIT_ON_PAGE = 7;

type Props = UserProps;

export default function Users({ user }: Props) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const [filter, setFilter] = useState('');
  const { users }: UsersState = useSelector<RootState, UsersState>(
    ({ users }) => users,
  );
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
    filter: '',
    accountType: 'company',
  });

  useEffect(() => {
    lazyParams.filter = filter;
    if (mount) {
      getData(lazyParams);
      setMount(false);
    } else if (refresh) {
      getData(lazyParams);
      setRefresh(false);
    } else {
      getData(lazyParams);
    }
  }, [refresh, lazyParams, useDebounce(filter, 200)]);
  const getData = (params: any) => {
    setLoading(true);
    dispatch(getAllUserByType(params));
    setLoading(false);
  };

  const onPage = (event: any) => {
    setLazyParams({ ...event, accountType: 'company' });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event, accountType: 'company' });
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'isActive') {
      return rowData[item.field]
        ? translate('adminTool.users.active')
        : translate('adminTool.users.notActive');
    } else if (rowData?.companyDetails?.taxNumber && item.field === 'Nip') {
      return rowData.companyDetails.taxNumber;
    }
  };

  const rowExpansionTemplate = (data: UserRawRoles) => {
    return (
      <EditUser
        user={data}
        setRefresh={setRefresh}
        editor={user}
        visibleAddresses={true}
        visibleNotifications={true}
        visibleOrders={true}
        visibleLoginAttempts={true}
        visiblePayments={true}
        visibleUnpaidPayments={true}
      />
    );
  };

  return (
    <div className="users-container">
      <DataTable
        header={() => (
          <FilterHeader setInputValue={setFilter} inputValue={filter} />
        )}
        value={users.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={loading}
        first={lazyParams.first}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        currentPageReportTemplate={translate(
          'adminTool.users.currentPageReportTemplate',
        )}
        rows={LIMIT_ON_PAGE}
        totalRecords={users.meta.totalItems}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        onPage={onPage}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="id" sortable header={translate('adminTool.users.id')} />
        <Column field="email" header={translate('adminTool.users.email')} />
        <Column field="phone" header={translate('adminTool.users.phone')} />
        <Column
          field="isActive"
          header={translate('adminTool.users.isActive')}
          body={labelChecker}
        />
        <Column field="Nip" header={translate('Nip')} body={labelChecker} />
        <Column
          field="createdAt"
          body={formatDateToLocalTime}
          header={translate('adminTool.users.createdAt')}
        />
        <Column
          field="updatedAt"
          body={formatDateToLocalTime}
          header={translate('adminTool.users.updatedAt')}
        />
        <Column
          field="updatedBy"
          header={translate('adminTool.users.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
