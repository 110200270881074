import {
  COUNT_UNREAD_NOTIFICATIONS_FAIL,
  COUNT_UNREAD_NOTIFICATIONS_REQUEST,
  COUNT_UNREAD_NOTIFICATIONS_SUCCESS,
  DECREMENT_UNREAD_NOTIFICATIONS_REQUEST,
  INCREMENT_UNREAD_NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_DELETE_FULFILLED,
  NOTIFICATIONS_DELETE_REJECTED,
  NOTIFICATIONS_FAIL,
  NOTIFICATIONS_PUSH,
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION_SUCCESS,
} from '../Network/Actions/Types';
import { NotificationsState } from '../Type/Custom';
import update from 'immutability-helper';

import { FindNotificationDto } from '../../../types/notifications/find-notification.dto';

interface Action {
  type: string;
  notification?: FindNotificationDto;
  notifications?: FindNotificationDto[];
  error: string;
  unreadCount?: 0;
  totalCount?: number;
  index?: number;
}
export const notificationsState = (
  state: NotificationsState = {
    loading: false,
    notifications: [],
    totalCount: 0,
    error: null,
    unreadCount: 0,
  },
  action: Action,
) => {
  switch (action.type) {
    case COUNT_UNREAD_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case COUNT_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        unreadCount: action.unreadCount,
      };
    case COUNT_UNREAD_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        unreadCount: 0,
      };
    case INCREMENT_UNREAD_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        unreadCount: state.unreadCount + 1,
      };
    case DECREMENT_UNREAD_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        unreadCount: state.unreadCount > 0 ? state.unreadCount - 1 : 0,
      };
    case NOTIFICATIONS_REQUEST:
      return { ...state, loading: true, error: null };
    case NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: [
          ...state.notifications,
          ...(action.notifications as Record<string, any>[]),
        ],
        error: null,
        totalCount: action.totalCount,
      };
    case UPDATE_NOTIFICATION_SUCCESS:
      const index: number = action.index as number;
      return {
        ...state,
        loading: false,
        error: null,
        notifications: update(state.notifications, {
          [index]: { $set: action.notification as FindNotificationDto },
        }),
      };
    case NOTIFICATIONS_PUSH:
      return {
        ...state,
        totalCount: state.totalCount + 1,
        notifications: [action.notification, ...state.notifications],
      };
    case NOTIFICATIONS_FAIL:
      return { loading: false, notifications: [], error: action.error };
    case NOTIFICATIONS_DELETE_FULFILLED:
      return {
        loading: false,
        notifications: [],
        error: null,
        totalCount: 0,
        unreadCount: 0,
      };
    case NOTIFICATIONS_DELETE_REJECTED:
      return {
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
