import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { http } from '@network/NetworkHTTP';
import { SendCodeDto } from '@rootTypes/sms/send-code.dto';
import { VerifyCodeDto } from '@rootTypes/sms/verify-code.dto';
import { UpdateUserDto } from '@rootTypes/users/update-user.dto';
import { RootState } from '@src/store';
import { NUMBER_REQUEST, NUMBER_SUCCESS, NUMBER_FAIL } from './Types';
import { checkForSmsCookie } from '../../Util/countIntervalRemainingTime';
import { setSmSCookie } from '@util/SetSmSCookie';

export const getRequestIdAction = (
  sendCodeDto: SendCodeDto,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    if (!checkForSmsCookie())
      try {
        dispatch({
          type: NUMBER_REQUEST,
        });
        const { data } = await sendCode(sendCodeDto);
        setSmSCookie();
        dispatch({
          type: NUMBER_SUCCESS,
          payload: data,
        });
      } catch (err) {
        onError();
        dispatch({
          type: NUMBER_FAIL,
          payload: err,
        });
      }
  };
};

export async function sendCode(sendCodeDto: SendCodeDto) {
  return await http.post(`sms/send-code`, sendCodeDto);
}

export async function verifyCode(verifyCodeDto: VerifyCodeDto) {
  return await http.post(`sms/verify-code`, verifyCodeDto);
}

export async function updatePhone(id: number, updateUserDto: UpdateUserDto) {
  return await http.patch(`users/${id}`, updateUserDto);
}
