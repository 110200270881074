import React from 'react';
import './SubmitCourier.scss';
import icon from '../../../../../../Asset/images/IconArtwork.png';
import P from '../../../../../../Component/Text/Text';

const SubmitCourier = () => {
  return (
    <div className="submit-courier">
      <img src={icon} alt="icon" />
      <P className="submit-courier_title">hidingPlaceBlock.courierTitle</P>
      <P className="submit-courier_text">hidingPlaceBlock.courierText</P>
    </div>
  );
};

export default SubmitCourier;
