import React from 'react';
import { DescriptionTypeEnum } from '../../../../../../../../../types/product-descriptions/description-type.enum';
import { FindProductDescriptionDto } from '../../../../../../../../../types/product-descriptions/find-product-description.dto';
import './DescriptionItem.scss';
import { translate } from '../../../../../../../Module/Translate/Translate';

interface Props {
  description: FindProductDescriptionDto;
  removeDescription: any;
  productId: number;
  key: number;
}

const labelChecker = (type: DescriptionTypeEnum) => {
  switch (type) {
    case DescriptionTypeEnum.carousel:
      return translate('adminTool.products.carousel');
    case DescriptionTypeEnum.offer:
      return translate('adminTool.products.offer');
    case DescriptionTypeEnum.store:
      return translate('adminTool.products.shop');
    case DescriptionTypeEnum.rent:
      return translate('adminTool.products.rent');
    case DescriptionTypeEnum.storage:
      return translate('adminTool.products.storage');
    case DescriptionTypeEnum.move:
      return translate('adminTool.products.move');
  }
};

export default function DescriptionItem({
  description,
  removeDescription,
  productId,
  key,
}: Props) {
  return (
    <div className="description-item-container" key={key}>
      <div className="description-item-header">
        <p className="description-item-title">
          {translate('adminTool.products.descriptionFor')}{' '}
          {labelChecker(description.type)}
        </p>
        <i
          className="pi pi-times description-remove-icon"
          onClick={() => removeDescription(description.id, productId)}
        ></i>
      </div>
      <p>{description.text}</p>
    </div>
  );
}
