import React, { useState, useEffect } from 'react';
import 'react-rangeslider/lib/index.css';
import './CalculatorBlock.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ProductType } from '@rootTypes/products/product-type.enum';
import {
  TransportTypeEnum,
  UndefinedTransportCost,
} from '@rootTypes/transport/transport-type.enum';
import { RootState } from '@src/store';
import { TransportPriceState } from '@type/Custom';
import { FindBasketProductDto } from '@rootTypes/products/find-basket-product.dto';

import {
  calculateStoreBoxesPrice,
  calculateProductsQuantity,
  calculateRentBoxesPrice,
} from '@util/ProductsCalculate';
import { calculateTransportStorePrice } from '@util/TransportPrice';
import { useLocation } from 'react-router-dom';
import CoolParcel from '@component/CoolParcel/CoolParcel';
import CalculatorButton from './components/CalculatorButton';
import CalculationBlockHeader from './components/CalculationBlockHeader';
import KeepSlider from './components/KeepSlider';
import { calculateCeilStorePriceByMonth } from './logic/calculateCeilStorePriceByMonth';
import { calculateCeilRentPriceByMonth } from './logic/calculateCeilRentPriceByWeek';
import RentSlider from './components/RentSlider';
import { handleCounterClickMath } from './logic/handleCounterClick';
import OfferStorageBox from './components/OfferStorageBox';
import OfferRentBox from './components/OfferRentBox';
import DisplayTotal from './components/DisplayTotal';
import { calculateCeilTotalRentPrice } from './logic/calculateCeliTotalRentPrice';
import { getTransportAction } from '@network/Actions/TransportPrice';

const CalculatorBlock = () => {
  const [total, setTotal] = useState(0);
  const [toggleState, setToggleState] = useState(1);
  const [rentWeeks, setRentWeeks] = useState(3);
  const [stateStore, setStateStore] = useState<FindBasketProductDto[]>([]);
  const [stateRent, setStateRent] = useState<FindBasketProductDto[]>([]);

  const location = useLocation();
  const dispatch = useDispatch();

  const { transport }: TransportPriceState = useSelector<
    RootState,
    TransportPriceState
  >(({ transportPrice }) => transportPrice);

  const getSingleTransportPrice = transport.find(
    (item) => item.type === TransportTypeEnum.single,
  )?.price;

  const singleTransportPrice =
    getSingleTransportPrice === 0
      ? 0
      : getSingleTransportPrice || UndefinedTransportCost;

  const getStackTransportPrice = transport.find(
    (item) => item.type === TransportTypeEnum.stack,
  )?.price;

  const stackTransportPrice =
    getStackTransportPrice === 0
      ? 0
      : getStackTransportPrice || UndefinedTransportCost;

  const [storageMonths, setStorageMonths] = useState(3);

  function handleInputKeepRentChange(event: any) {
    const value = event.target.value
      ? event.target.value[0] === '0'
        ? event.target.value.substring(1)
        : event.target.value
      : 0;

    const max = +event.target.max;

    if (value > max) {
      event.target.name === 'week' ? setRentWeeks(max) : setStorageMonths(max);
      return;
    }
    event.target.name === 'week'
      ? setRentWeeks(value)
      : setStorageMonths(value);
  }

  const handleCounterClick = (value: number, data: any) => {
    if (data.type === ProductType.storage)
      setStateStore((prevState) =>
        handleCounterClickMath(value, data, prevState),
      );
    if (data.type === ProductType.rent) {
      setStateRent((prevState) =>
        handleCounterClickMath(value, data, prevState),
      );
    }
  };

  const boxesStackedStore = stateStore.filter((item) => item.isStack);

  const boxesNotStackedStore = stateStore.filter((item) => !item.isStack);

  const boxesPriceStackedStore = calculateStoreBoxesPrice(boxesStackedStore, 0);

  const boxesPriceNotStackedStore = calculateStoreBoxesPrice(
    boxesNotStackedStore,
    0,
  );

  const boxesValueStackedStore = calculateProductsQuantity(boxesStackedStore);

  const boxesValueNotStackedStore =
    calculateProductsQuantity(boxesNotStackedStore);

  const rentState = stateRent.filter((item) => item);

  const boxesPriceRent = calculateRentBoxesPrice(rentState, 0);

  const boxesValueRent = calculateProductsQuantity(rentState);

  const transportStorePrice = calculateTransportStorePrice({
    quantityStackedBoxForStore: boxesValueStackedStore,
    quantityNotStackedBoxForStore: boxesValueNotStackedStore,
    singleTransportPrice,
    stackTransportPrice,
  });

  const ceilRentPriceByMonth = calculateCeilRentPriceByMonth(
    rentWeeks,
    boxesPriceRent,
    boxesValueRent,
    stackTransportPrice,
  );
  /**
   * Calculate storage total price
   */
  const ceilStorePriceByMonth = calculateCeilStorePriceByMonth(
    storageMonths,
    transportStorePrice,
    boxesPriceStackedStore,
    boxesPriceNotStackedStore,
  );

  const ceilTotalRentPrice = calculateCeilTotalRentPrice(
    rentWeeks,
    boxesPriceRent,
    boxesValueRent,
    stackTransportPrice,
  );

  const celiTotalKeepPrice = parseFloat(
    (ceilStorePriceByMonth * storageMonths).toFixed(2),
  );

  useEffect(() => {
    toggleState === 1
      ? setTotal(ceilStorePriceByMonth)
      : setTotal(ceilRentPriceByMonth);
  }, [ceilStorePriceByMonth, ceilRentPriceByMonth, toggleState]);

  useEffect(() => {
    dispatch(getTransportAction());
  }, []);

  return (
    <>
      <article
        data-mode="keep"
        className="calculator-section row no-gutters padded padded-small bg-gray offer"
      >
        <div className="container">
          {location.pathname === '/DlaFirm' && <CoolParcel />}
          <div className="row column-reverse-mobile offer__calculator">
            <DisplayTotal
              total={total}
              toggleState={toggleState}
              rentWeeks={rentWeeks}
              storageMonths={storageMonths}
              stateStore={stateStore}
              stateRent={stateRent}
              ceilTotalRentPrice={ceilTotalRentPrice}
              celiTotalKeepPrice={celiTotalKeepPrice}
            />
            <div className="calculator-container d-flex flex-column offer__cost">
              <CalculationBlockHeader />
              <div className="calculator-container_wrapper">
                <CalculatorButton
                  text={'calculator.storage'}
                  data={'keep'}
                  addModeActive={toggleState === 1}
                  onCLick={() => setToggleState(1)}
                />
                <CalculatorButton
                  text={'calculator.rent'}
                  data={'rent'}
                  addModeActive={toggleState === 2}
                  onCLick={() => setToggleState(2)}
                />
              </div>
              {toggleState === 1 && (
                <OfferStorageBox
                  handleCounterClick={handleCounterClick}
                  stateStore={stateStore}
                />
              )}
              {toggleState === 2 && (
                <OfferRentBox
                  handleCounterClick={handleCounterClick}
                  stateStore={stateRent}
                />
              )}

              {/* <!-- Keep slider --> */}
              <KeepSlider
                isActive={toggleState === 1}
                storageMonths={storageMonths}
                handleKeepChange={setStorageMonths}
                handleInputKeepRentChange={handleInputKeepRentChange}
              />
              {/* <!-- Rent slider --> */}
              <RentSlider
                isActive={toggleState === 2}
                rentWeeks={rentWeeks}
                setRentWeeks={setRentWeeks}
                handleInputKeepRentChange={handleInputKeepRentChange}
              />
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default CalculatorBlock;
