import AppleLoginButton from '@component/AppleLogin/AppleLogin';
import FacebookAuth from '@form/LoginForm/FacebookAuth';
import GoogleAuth from '@form/LoginForm/GoogleAuth';
import { translate } from '@module/Translate/Translate';
import React from 'react';

export default function RegisterUserSocial({
  isPrivacyConfirmed,
  isRODOConfirmed,
}: {
  isPrivacyConfirmed: boolean;
  isRODOConfirmed: boolean;
}) {
  return (
    <div>
      <div id="social-buttons" className="social-register-group">
        <p className="social-register-text">
          {translate('navigation.continueRegister')}
        </p>
        <div className="social-register-buttons">
          <GoogleAuth
            loginOrRegister="register"
            disabled={!isPrivacyConfirmed || !isRODOConfirmed}
          />
          <FacebookAuth
            loginOrRegister="register"
            disabled={!isPrivacyConfirmed || !isRODOConfirmed}
          />
          <AppleLoginButton
            loginOrRegister="register"
            disabled={!isPrivacyConfirmed || !isRODOConfirmed}
          />
        </div>
      </div>

      {!isPrivacyConfirmed || !isRODOConfirmed ? (
        <div id="social-error" className="error-feedback social-rules">
          {translate('errors.acceptSocialRules')}
        </div>
      ) : null}
    </div>
  );
}
