import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store';
import { AnyAction } from 'redux';
import { LIMITS_FAIL, LIMITS_SUCCESS, LIMITS_REQUEST } from './Types';
import http from '../NetworkHTTP';

export const getLimitsAction = (
  setShowError?: (isOpen: boolean) => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: LIMITS_REQUEST,
      });

      const { data } = await http.get(`/limits`);

      dispatch({
        type: LIMITS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      setShowError && setShowError(true);
      dispatch({
        type: LIMITS_FAIL,
        payload: 'Nie udało się pobrać limitów.',
      });
    }
  };
};

export async function editLimitAction(id: number, limit: any) {
  const { data } = await http.patch(`limits/${id}`, limit);
  return data;
}
