import React from 'react';
import Background from '@images/offer/move-car.png';
import './PrimaryBlockMove.scss';
import { P, H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

const PrimaryBlockMove = () => (
  <>
    <article
      className="primary-move"
      id={translate('offer.primaryBlockMove.title')}
    >
      <div className="primary-move_container container">
        <section className="move-section">
          <div
            className="primary-move_img car-img"
            style={{ backgroundImage: `url(${Background})` }}
          ></div>
          <div className="primary-move_text">
            <H3 className="primary-move_title">
              offer.primaryBlockMove.subtitle
            </H3>
            <P className="primary-move_subtitle">offer.primaryBlockMove.text</P>
          </div>
        </section>
      </div>
    </article>
  </>
);

export default PrimaryBlockMove;
