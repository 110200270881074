import { UserState } from '../Type/Custom';
import {
  CURRENT_USER_FAIL,
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from '../Network/Actions/Types';
import { User } from '../models/user.model';

interface Action {
  type: string;
  payload?: User;
}
export const userReducer = (
  state: UserState = { loading: false, user: {} as User, error: null },
  action: Action,
) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true, user: state.user, error: null };
    case USER_REGISTER_SUCCESS:
      return { loading: false, user: action.payload, error: null };
    case USER_REGISTER_FAIL:
      return { loading: false, user: state.user, error: action.payload };
    case CURRENT_USER_REQUEST:
      return { loading: true, user: state.user, error: null };
    case CURRENT_USER_SUCCESS:
      return { loading: false, user: action.payload, error: null };
    case CURRENT_USER_FAIL:
      return { loading: false, user: state.user, error: action.payload };
    case USER_LOGOUT:
      return { loading: false, user: action.payload, error: null };
    default:
      return state;
  }
};
