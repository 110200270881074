import { VONAGE_PIN_EXPIRY } from '@/envConfig';

export const setSmSCookie = () => {
  const initTime = Date.now();
  const defaultTimeSMSExpired = 60;
  const timeSMSExpired = VONAGE_PIN_EXPIRY
    ? +VONAGE_PIN_EXPIRY
    : defaultTimeSMSExpired;
  document.cookie = `smsTimeout=${
    initTime + timeSMSExpired * 1000
  }; expires=${new Date(
    initTime + timeSMSExpired * 1000,
  ).toUTCString()}; path=/;`;
};
