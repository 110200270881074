import React from 'react';
import './ChildProducts.scss';
import { H5 } from '../../../../../../Component/Text/Text';
import { translate } from '../../../../../../Module/Translate/Translate';

interface Props {
  productPackages?: any[];
  pPackage?: any;
  handleRemove: any;
  handleEdit: any;
}

interface CProps {
  productPackages?: any[];
  pPackage?: any;
  handleRemove: any;
  handleEdit: any;
}
function CProduct({ pPackage, handleRemove, handleEdit }: CProps) {
  const product = pPackage.child;
  const parent = pPackage.product;

  return (
    <div key={product.id} className="child-container">
      <div className="child-container-product">
        <div className="child-container-product-id">id. {product.id}</div>
        <div className="child-container-product-desc">{product.code}</div>
        <div className="child-container-product-desc">{product.type}</div>
        <div className="child-container-product-desc">{pPackage.quantity}</div>
        <i
          className="pi pi-times package-remove-icon"
          onClick={() => handleRemove(pPackage.id, +parent.id)}
        ></i>
      </div>
    </div>
  );
}

export default function ChildProducts({
  productPackages,
  handleRemove,
  handleEdit,
}: Props) {
  return (
    <div className="product-info-container">
      <br />
      <H5 className="product-h5">
        {translate('adminTool.products.childProductsTitle')}
      </H5>
      <>
        {productPackages &&
          productPackages.map((product: any, key) => (
            <CProduct
              key={product.id}
              pPackage={product}
              handleRemove={handleRemove}
              handleEdit={handleEdit}
            />
          ))}
      </>
    </div>
  );
}
