import { translate } from '@module/Translate/Translate';
import React, { useState } from 'react';
import Slider from 'react-rangeslider';
const weeks = [1, 2, 3, 4, 6, 8];
interface IRentSlider {
  isActive: boolean;
  rentWeeks: number;
  setRentWeeks: (value: number) => void;
  handleInputKeepRentChange: (event: any) => void;
}
export default function RentSlider({
  isActive,
  rentWeeks,
  setRentWeeks,
  handleInputKeepRentChange,
}: IRentSlider) {
  const [rentSliderValue, setRentSliderValue] = useState(3);

  return (
    <>
      <div
        className={`mode-slider ${isActive && 'mode-slider-active'}`}
        data-mode="rent"
      >
        <div className="d-flex" data-mode="rent">
          <Slider
            value={rentSliderValue}
            step={1}
            min={1}
            max={6}
            tooltip={false}
            onChange={(value: number) => {
              setRentSliderValue(value);
              setRentWeeks(weeks[value - 1]);
            }}
          />
        </div>
        <div className="rangeslider__label col-12">
          <div className="w-100 d-flex flex-row justify-content-between text-primary">
            {weeks.map((weekNumber: number) => (
              <span key={weekNumber + 'week'} className="fs16 fs-lg-24 fw400">
                {weekNumber} {translate('calculator.week')}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div
        className={`calculator-input ${isActive && 'calculator-input_active'}`}
      >
        <label htmlFor="week">
          {translate('calculator.numberOfWeeksOfRent')}
        </label>
        <input
          name="week"
          id="week"
          autoComplete="off"
          type="number"
          pattern="([1-8])"
          min="1"
          max="8"
          list="tickmarks"
          value={rentWeeks}
          onChange={handleInputKeepRentChange}
        />
      </div>
    </>
  );
}
