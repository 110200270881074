export class PasswordValidator {
  lowercase = /[a-z]/g;
  uppercase = /(?=.*[A-Z])/g;
  specialCharacter = /[!@#$%^&*]/;
  number = /[0-9]/;
  length = 8;

  validatePassword = (password: string): boolean => {
    if (!password) {
      return true;
    } else {
      if (
        password.length < this.length ||
        !password.match(this.lowercase) ||
        !password.match(this.uppercase) ||
        !password.match(this.number) ||
        !password.match(this.specialCharacter)
      ) {
        return true;
      }

      return false;
    }
  };

  getLowercase = () => this.lowercase;
  getUppercase = () => this.uppercase;
  getspecialCharacter = () => this.specialCharacter;
  getNumbers = () => this.number;
  getLength = () => this.length;
}
