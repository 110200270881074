import React, { useCallback, useEffect, useState } from 'react';
import './ConfirmNumberForm.scss';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Helpers from '../../View/RegistrationStepper/helpers/helpers';
import MultiFieldsInput from '@component/MultiFieldsInput/MultiFieldsInput';
import { H1, H2 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { currentUserAction } from '@network/Actions/CurrentUser';
import {
  updatePhone,
  verifyCode,
  getRequestIdAction,
} from '@network/Actions/NumberConfirmation';
import { RootState } from '@src/store';
import { NumberConfirmationState, UserState } from '@type/Custom';
import ReactTooltip from 'react-tooltip';
import { VONAGE_PIN_EXPIRY } from '@/envConfig';
import { checkForSmsCookie } from '@util/countIntervalRemainingTime';

interface IProps {
  codeLength?: number;
  nextStep?: number;
  showError: (value: boolean) => void;
  visibleSpinner: (value: boolean) => void;
  setVerification?: (value: boolean) => void;
}

export const ConfirmNumberForm = ({
  codeLength = 4,
  nextStep = 3,
  showError,
  visibleSpinner,
  setVerification,
}: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [triggerInterval, setTriggerInterval] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [initialValues] = useState(Helpers.getFormData());
  const { data } = useSelector<RootState, NumberConfirmationState>(
    (state) => state.requestId,
  );

  const { user } = useSelector<RootState, UserState>((state) => state.user);

  const [verifyError, setVerifyError] = useState(null);
  useEffect(() => {
    if (location.pathname === '/panel-klienta-moja-skrytka') {
      dispatch(
        getRequestIdAction(
          {
            phoneNumber: '48' + user.phone,
          },
          () => showError(true),
        ),
      );
    }
  }, []);

  const onSubmit = useCallback(() => {
    history.push({
      ...location,
      state: {
        activeStep: nextStep,
      },
    });
  }, [history, location]);

  const updateUser = () => {
    visibleSpinner(true);

    updatePhone(user.id, {
      phone: user.phone ? user.phone : initialValues.number,
    })
      .then(() => {
        dispatch(currentUserAction());
        onSubmit();
      })
      .catch(function (error) {
        showError(true);
        if (error.response) {
          if (error.response.data.status === 409) {
            setVerifyError(error.response.data.error.message);
          }
        }
      })
      .finally(() => {
        visibleSpinner(false);
      });
  };

  const [deltaTime, setDeltaTime] = useState(0);

  useEffect(() => {
    const isSmSCookie: number = checkForSmsCookie();
    if (isSmSCookie !== 0) {
      setTriggerInterval(true);
      startInterval(isSmSCookie);
    } else if (triggerInterval) {
      startInterval();
    }
  }, [triggerInterval]);

  const startInterval = (cookieRemainingTime?: number) => {
    const initTime = Date.now();
    const defaultTimeSMSExpired = 60;
    const timeSMSExpired = cookieRemainingTime
      ? cookieRemainingTime
      : VONAGE_PIN_EXPIRY
      ? +VONAGE_PIN_EXPIRY
      : defaultTimeSMSExpired;
    if (!cookieRemainingTime) {
      document.cookie = `smsTimeout=${
        initTime + timeSMSExpired * 1000
      }; expires=${new Date(
        initTime + timeSMSExpired * 1000,
      ).toUTCString()}; path=/;`;
    }
    setDeltaTime(
      Math.ceil((initTime + timeSMSExpired * 1000 - Date.now()) / 1000),
    );
    const calculateInterval = setInterval(() => {
      const deltaTime = (initTime + timeSMSExpired * 1000 - Date.now()) / 1000;
      if (deltaTime && deltaTime <= 0) {
        setTriggerInterval(false);
        clearInterval(calculateInterval);
        setDeltaTime(0);
      } else setDeltaTime(Math.ceil(deltaTime));
    }, 1000);
  };

  const verifyRecivedCode = () => {
    visibleSpinner(true);

    verifyCode({ code: code, data: { request_id: data.request_id } })
      .then(() => {
        if (location.pathname === '/return-boxes') {
          onSubmit();
          return;
        } else if (location.pathname === '/panel-klienta-moja-skrytka') {
          setVerification && setVerification(true);
          return;
        }
        updateUser();
      })
      .catch(function (error) {
        if (error.response.status === 500) {
          showError(true);
        } else {
          setVerifyError(error.message);
          if (!triggerInterval) {
            setTriggerInterval(true);
          }
        }
      })
      .finally(() => {
        visibleSpinner(false);
      });
  };
  const rebuildToolTip = () => {
    ReactTooltip.rebuild();
  };
  const hideToolTip = () => {
    if (!deltaTime) ReactTooltip.hide();
  };

  const codeError = () => {
    if (!verifyError) {
      return;
    }
    switch (verifyError) {
      case 'invalid code':
        return translate('errors.invalidCode');
      default:
        return translate('errors.actionFailed');
    }
  };

  const inputs = [];
  for (let i = 0; i < codeLength; i++) {
    inputs.push({
      maxLength: 1,
      placeholder: '',
    });
  }
  return (
    <div className="code-form container code-form-container">
      <H1 className="code-form-title">
        {translate('addressStep.verifyNumber')}
      </H1>
      <H2 className="code-form-subtitle">
        {translate('addressStep.verifyNumberMessage')}
      </H2>

      <MultiFieldsInput
        name="input-code-"
        inputs={inputs}
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        onBlur={() => {}}
        onChange={(e: any) => {
          setCode(e.value);
        }}
        classes={{
          inputContainer: 'code-form-field',
          error: 'verify-error',
        }}
        error={codeError()}
        type="number"
      />

      {verifyError && (
        <>
          <button
            type="button"
            className="button_resend-code"
            onFocus={rebuildToolTip}
            style={{
              color: deltaTime
                ? 'rgb(84, 84, 84)'
                : 'var(--primary-text-color)',
            }}
            onClick={() => {
              if (!deltaTime) {
                setTriggerInterval(true);
                location.pathname === '/return-boxes' ||
                location.pathname === '/panel-klienta-moja-skrytka'
                  ? dispatch(
                      getRequestIdAction(
                        {
                          phoneNumber: '48' + user.phone,
                        },
                        () => showError(true),
                      ),
                    )
                  : dispatch(
                      getRequestIdAction(
                        {
                          phoneNumber: '48' + initialValues.number,
                        },
                        () => showError(true),
                      ),
                    );
              }
            }}
            data-for="show-verify-code-tooltip"
            data-tip={translate('verifyingCode.retryingVerifyCode')}
            onLoad={rebuildToolTip}
            onClickCapture={hideToolTip}
          >
            {translate('addressStep.resendCode')}{' '}
            {deltaTime ? `(${deltaTime})` : ''}
          </button>

          <ReactTooltip
            disable={deltaTime ? false : true}
            id="show-verify-code-tooltip"
            multiline={true}
          />
        </>
      )}

      <button
        className="button_accept code-form_button btn"
        onClick={verifyRecivedCode}
        disabled={code.length !== inputs.length}
      >
        {translate('addressStep.verify')}
      </button>
    </div>
  );
};

export default ConfirmNumberForm;
