import React, { useMemo, useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
import './DataPicker.scss';
import { initEndDateOfPeriod } from '@util/InitEndDateOfPeriod';
import { ProductType } from '@rootTypes/products/product-type.enum';
import unifiedFormatDate from '@util/Dates';
import { translate } from '@module/Translate/Translate';
import { selectedDaysArray } from '@util/SelectedDaysArray';
import { dateGetTime } from '@util/DateGetTime';
import {
  calculateLastDate,
  calculateLastDateWithCalculatorRange,
  calculateLastDaysArray,
} from './helpers/calculateLastDate';

interface Props {
  titleFirstDate: string;
  titleSecondDate: string;
  onDatepickerClick: (
    day: number,
    dateStart: Date,
    dateEnd: Date,
    endPeriodDate: Date,
  ) => void;
  maxStartDate: number;
  minLastDate: number;
  maxLastDate: number;
  holidays: Date[];
  stateStartDate: any;
  stateLastDate: any;
  rangeDate: number;
  type: ProductType.rent | ProductType.storage;
  defaultOrderClosingTime: number;
  periods: Record<string, string>[];
}

export default function Datepicker({
  titleFirstDate,
  titleSecondDate,
  onDatepickerClick,
  maxStartDate,
  minLastDate,
  maxLastDate,
  holidays,
  stateStartDate,
  stateLastDate,
  rangeDate,
  type,
  defaultOrderClosingTime,
  periods,
}: Props) {
  const daysArray = useMemo(() => {
    return selectedDaysArray({
      arrayLength: maxStartDate,
      holidays,
      defaultOrderClosingTime,
    });
  }, []);

  const [startDate, setStartDate] = useState<Date>(
    stateStartDate && dateGetTime(stateStartDate) >= dateGetTime(daysArray[0])
      ? stateStartDate
      : daysArray[0],
  );

  const lastDaysArray = useMemo(() => {
    return calculateLastDaysArray({
      holidays,
      startDate,
      maxLastDate,
    });
  }, [startDate]);

  useEffect(() => {
    const dateStarted = new Date(startDate);

    const dayBeforeMinLastDate = new Date(
      dateStarted.getFullYear(),
      dateStarted.getMonth(),
      dateStarted.getDate() + minLastDate - 2, // 2 because min date in last calendar = minLastDate - 1, i need day before minLastDate
    );

    rangeDate
      ? setLastDate(
          calculateLastDateWithCalculatorRange({
            type,
            dateStarted,
            dayBeforeMinLastDate,
            maxDate: lastDaysArray[lastDaysArray.length - 1],
            startDate: dateStarted,
            range: rangeDate,
            holidays,
            defaultOrderClosingTime,
          }),
        )
      : setLastDate(
          calculateLastDate({
            stateLastDate,
            dayBeforeMinLastDate,
            holidays,
            defaultOrderClosingTime,
            maxDate: lastDaysArray[lastDaysArray.length - 1],
          }),
        );
  }, [startDate, minLastDate]);

  const [lastDate, setLastDate] = useState<Date>(new Date());

  const [endPeriodDate, setEndPeriodDate] = useState<Date>(lastDate);

  useEffect(() => {
    const endDateOfPeriod = initEndDateOfPeriod({
      startDate,
      type,
      periods,
    });
    endDateOfPeriod && setEndPeriodDate(endDateOfPeriod);
  }, [startDate, lastDate, periods]);

  const utcDate = (date: Date) =>
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

  //Block weekends on calendar
  const disableWeekends = (current: Date) => {
    return current.getDay() !== 0 && current.getDay() !== 6;
  };

  useEffect(() => {
    const oneDay = 86400000;
    const days =
      Math.floor((utcDate(lastDate) - utcDate(startDate)) / oneDay) + 1;

    onDatepickerClick(days, startDate, lastDate, endPeriodDate);
  }, [lastDate, startDate, utcDate(endPeriodDate)]);

  return (
    <>
      <div className="order-data-picker">
        <div className="data-picker">
          <h3>{titleFirstDate}</h3>
          <p>{unifiedFormatDate(startDate)}</p>
          <ReactDatePicker
            renderCustomHeader={({
              decreaseMonth,
              increaseMonth,
              nextMonthButtonDisabled,
              prevMonthButtonDisabled,
              monthDate,
            }) => (
              <div className="header">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {translate('orderPanel.datepickerArrowPrev')}
                </button>
                <span className="react-datepicker__current-month">
                  {monthDate.toLocaleString('pl', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {translate('orderPanel.datepickerArrowNext')}
                </button>
              </div>
            )}
            onChange={(date: Date) => setStartDate(date)}
            minDate={daysArray[0]}
            maxDate={daysArray[daysArray.length - 1]}
            selected={startDate}
            inline
            locale={pl}
            fixedHeight={true}
            filterDate={disableWeekends}
            disabledKeyboardNavigation
            excludeDates={holidays}
          />
        </div>
        <div className="data-picker">
          <h3>{titleSecondDate}</h3>
          <p>{unifiedFormatDate(lastDate)}</p>
          <ReactDatePicker
            renderCustomHeader={({
              decreaseMonth,
              increaseMonth,
              nextMonthButtonDisabled,
              prevMonthButtonDisabled,
              monthDate,
            }) => (
              <div className="header">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {translate('orderPanel.datepickerArrowPrev')}
                </button>
                <span className="react-datepicker__current-month">
                  {monthDate.toLocaleString('pl', {
                    month: 'long',
                    year: 'numeric',
                  })}
                </span>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {translate('orderPanel.datepickerArrowNext')}
                </button>
              </div>
            )}
            onChange={(date: Date) => setLastDate(date)}
            minDate={lastDaysArray[0]}
            maxDate={lastDaysArray[lastDaysArray.length - 1]}
            inline
            fixedHeight={true}
            locale={pl}
            selected={lastDate}
            filterDate={disableWeekends}
            disabledKeyboardNavigation
            excludeDates={holidays}
            startDate={lastDate}
            endDate={endPeriodDate}
          />
        </div>
      </div>
    </>
  );
}
