import {
  ORDER_FAIL,
  ORDER_REQUEST,
  ORDER_SUCCESS,
} from '../Network/Actions/Types';
import { OrderState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const orderReducer = (
  state: OrderState = { loading: false, order: {}, error: null },
  action: Action,
) => {
  switch (action.type) {
    case ORDER_REQUEST:
      return { loading: true, order: {}, error: null };
    case ORDER_SUCCESS:
      return { loading: false, order: action.payload, error: null };
    case ORDER_FAIL:
      return { loading: false, order: {}, error: action.payload };
    default:
      return state;
  }
};
