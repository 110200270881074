import React, { useState } from 'react';
import './Calendar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@module/Translate/Translate';
import {
  addDateInBasket,
  addPeriodInBasket,
  addDaysInBasket,
} from '@network/Actions/Basket';
import {
  DateCategory,
  PeriodCategory,
} from '@rootTypes/calendar/categories-enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { Variety } from '@rootTypes/variety/variety-type.enum';
import { RootState } from '@src/store';
import { BasketState } from '@type/Custom';
import unifiedFormatDate from '@util/Dates';
import { varietyOfPlural } from '@util/VarietyOfPlural';
import Datepicker from '../Datepicker/Datepicker';
interface Props {
  titleFirstDate: string;
  titleSecondDate: string;
  textTime: string;
  children: JSX.Element;
  maxStartDate: number;
  minLastDate: number;
  maxLastDate: number;
  type: ProductType.rent | ProductType.storage;
  rangeDate: number;
  defaultOrderClosingTime: number;
  periods: Record<string, string>[];
  textEndDate: string;
  holidays: Date[];
}

export default function Calendar({
  titleFirstDate,
  titleSecondDate,
  textTime,
  children,
  maxStartDate,
  minLastDate,
  maxLastDate,
  type,
  rangeDate,
  defaultOrderClosingTime,
  periods,
  textEndDate,
  holidays,
}: Props) {
  const [time, setTime] = useState<null | string>(null);
  const [period, setPeriod] = useState<null | string>(null);
  const dispatch = useDispatch();
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);

  const stateStartDate = state.dates.find(
    (date) => date.category === DateCategory.start && date.type === type,
  );
  const stateLastDate = state.dates.find(
    (date) =>
      date.category === DateCategory.pickedLastDate && date.type === type,
  );

  const [endDate, setEndDate] = useState('');

  const onDateSelect = (
    day: number,
    startDate: Date,
    lastDate: Date,
    endPeriodDate: Date,
  ) => {
    const dateStart = unifiedFormatDate(startDate);
    const dateEnd = unifiedFormatDate(endPeriodDate);
    setEndDate(dateEnd);
    const period = `${dateStart} - ${dateEnd}`;

    if (day < 0) {
      setTime(null);
      setPeriod(null);
      return;
    }

    setPeriod(period);

    dispatch(
      addDateInBasket({ date: startDate, type, category: DateCategory.start }),
    );
    dispatch(
      addDateInBasket({
        date: endPeriodDate,
        type,
        category: DateCategory.last,
      }),
    );

    dispatch(
      addDateInBasket({
        date: lastDate,
        type,
        category: DateCategory.pickedLastDate,
      }),
    );

    dispatch(
      addPeriodInBasket({ date: period, type, category: PeriodCategory.range }),
    );
    dispatch(addDaysInBasket({ days: day, type }));

    const setPeriot = (quantity: number, type: Variety) => {
      const variety = `${quantity} ${varietyOfPlural(quantity, type)}`;
      setTime(variety);
      dispatch(
        addPeriodInBasket({
          date: variety,
          type,
          category: PeriodCategory.period,
        }),
      );
      return;
    };

    const calculateStoragePeriod = ({ startDate, lastDate }: any) => {
      const startMonth = startDate.getMonth();
      const lastMonth = lastDate.getMonth();
      const months = lastMonth - startMonth;
      const startYear = startDate.getFullYear();
      const lastYear = lastDate.getFullYear();

      if (startYear + 1 === lastYear) {
        return 12 - -months + 1;
      }
      return months + 1;
    };

    const getNumberOfWeeks = (days: number): number => {
      return Math.ceil(days / 7);
    };
    const getNumberOfMonth = (days: number): number => {
      return Math.ceil(days / 30);
    };

    if (type === ProductType.rent) {
      setPeriot(getNumberOfWeeks(day), Variety.weeks);
    }

    if (type === ProductType.storage) {
      setPeriot(getNumberOfMonth(day), Variety.months);
    }
  };

  return (
    <>
      <div className="order-calendar">
        {children}
        <Datepicker
          onDatepickerClick={onDateSelect}
          titleFirstDate={titleFirstDate}
          titleSecondDate={titleSecondDate}
          maxStartDate={maxStartDate}
          minLastDate={minLastDate}
          maxLastDate={maxLastDate}
          holidays={holidays}
          stateLastDate={stateLastDate?.date}
          stateStartDate={stateStartDate?.date}
          rangeDate={rangeDate}
          type={type}
          defaultOrderClosingTime={defaultOrderClosingTime}
          periods={periods}
        />
        <div className="order-calendar_time">
          <div>
            <p className="text">{textTime}</p>
            <p className="period textEnd">
              {translate(`${textEndDate}.first`)}
              <span className="accentBackground">{endDate}</span>
              {translate(`${textEndDate}.last`)}
            </p>
          </div>
          <div>
            <p className="time">{time}</p>
            <p className="period">{period}</p>
          </div>
        </div>
      </div>
    </>
  );
}
