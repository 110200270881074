import { ReactNode } from 'react';
import { t } from '../Module/Translate/Translate';
import { isString } from './Check';

export interface WithChildren {
  children: ReactNode | any;
}

export const TranslateChildrenProps = (
  props: WithChildren,
): string | string[] | ReactNode =>
  !Array.isArray(props.children) && isString(props.children)
    ? t((props.children as string) || '')
    : Array.isArray(props.children) && props.children.every(isString)
    ? props.children.map(t)
    : props.children;

export const upperCase = (s: string): string =>
  s && s[0].toUpperCase() + s.slice(1);
export const lowerCase = (s: string): string =>
  s && s[0].toLowerCase() + s.slice(1);
