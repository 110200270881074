import React from 'react';
import './Comment.scss';
import Rating from '@component/Rating/Rating';
import { translate } from '@module/Translate/Translate';

interface ICommentProps {
  comment: any;
}

export const CommentComponent: React.FC<
  React.PropsWithChildren<ICommentProps>
> = ({ comment }) => {
  return (
    <div className="commentWrapper">
      <div className="commentHeader">
        <p className="name">{comment.name}</p>
        <div>
          <Rating value={comment.rating} />
        </div>
      </div>
      <p>
        <span className="commentType">
          {translate('contactBlock.' + comment.type)}
        </span>
        &ensp;{comment.text}
      </p>
    </div>
  );
};
