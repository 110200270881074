import React, { useEffect, useState } from 'react';
import './CustomSelect.scss';
import Arrow from '../../Asset/images/registration/Arrow 4.png';

interface Props {
  placeholder: string;
  options: any;
  onChange: (value: any) => void;
  value?: any;
}

export default function CustomSelect({
  placeholder,
  options = [],
  onChange,
  value,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  useEffect(() => {
    if (options.length === 0) {
      setSelectedOption('');
    }
  }, [options]);

  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (value: any) => {
    setSelectedOption(value);
    setIsOpen(false);
    onChange(value);
  };

  const renderOptionText = function (option: any) {
    if (typeof option === 'object') {
      return option.street + ', ' + option.postalCode + ' ' + option.city;
    }
    return option;
  };

  return (
    <div className={`custom-select-component ${isOpen && 'open'}`}>
      <div className="dropdown_container">
        <div className="dropdown_header" onClick={toggling}>
          {renderOptionText(selectedOption || placeholder)}
          <img className="dropdown_arrow" src={Arrow} alt="arrow" />
        </div>
        <div className="dropdown_list-container">
          <ul className="dropdown_list">
            {options.map((option: any, index: number) => (
              <li
                className="dropdown_list-item"
                key={index}
                onClick={() => onOptionClicked(option)}
              >
                {renderOptionText(option)}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
