import {
  DEFAULT_SETTINGS_FULFILLED,
  DEFAULT_SETTINGS_PENDING,
  DEFAULT_SETTINGS_REJECTED,
} from '../Network/Actions/Types';
import { DefaultSettingsState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const defaultSettingsReducer = (
  state: DefaultSettingsState = {
    loading: false,
    defaultSettings: [],
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case DEFAULT_SETTINGS_PENDING:
      return {
        loading: true,
        defaultSettings: state.defaultSettings,
        error: null,
      };
    case DEFAULT_SETTINGS_FULFILLED:
      return { loading: false, defaultSettings: action.payload, error: null };
    case DEFAULT_SETTINGS_REJECTED:
      return {
        loading: false,
        defaultSettings: state.defaultSettings,
        error: action.payload,
      };
    default:
      return state;
  }
};
