import React from 'react';
import './Header.scss';
import { H2, H5 } from '../../../../../../Component/Text/Text';

const Header = () => {
  return (
    <div className="container-header header-wrapper">
      <H2 className="header-wrapper_title">dashboard.creditCard.title</H2>
      <H5 className="container-subtitle header-wrapper_description">
        dashboard.creditCard.subtitle
      </H5>
    </div>
  );
};

export default Header;
