import P from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import CompletedIcon from '@images/completedProcess.png';

export const ShopMessage = () => (
  <div className="notify">
    <img
      src={CompletedIcon}
      alt={translate('completedSentForm.completed')}
      className="notify_img"
    />
    <P className="notify_message">shop.added-product</P>
  </div>
);
