import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  ADMIN_ERRORS_REQUEST,
  ADMIN_ERRORS_SUCCESS,
  ADMIN_ERRORS_FAIL,
} from './Types';
import { http } from '@network/NetworkHTTP';
import { RootState } from '@src/store';

export const getOrderErrorsAction = (
  params: any,
  orderId: string,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ADMIN_ERRORS_REQUEST,
      });

      const { data } = await http.get(
        `statuses?&type=${params.type}&take=${params.rows}&skip=${
          params.first
        }&orderId=${orderId}${
          params.sortField != '' || params.sortOrder
            ? `&sortField=${params.sortField}&sortOrder=${params.sortOrder}`
            : ``
        }`,
      );

      dispatch({
        type: ADMIN_ERRORS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_ERRORS_FAIL,
        payload: 'Nie udało się pobrać liste błędów.',
      });
    }
  };
};

export const getAdminErrorCount = async (type: string) => {
  const { data } = await http.get(`statuses/count/today/${type}`);
  return data;
};
