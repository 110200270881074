import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CookiesMorcik from '../../Asset/images/COOKIES MORCIK.png';
import { translate } from '../../Module/Translate/Translate';
import P, { H2 } from '../Text/Text';
import './CookiesInfo.scss';
import { analyticsChange } from '@network/Actions/Analytics';
import { useDispatch } from 'react-redux';
import {
  SKITRAJ_CONSENT_DISABLED,
  SKITRAJ_CONSENT_NAME,
  SKITRAJ_CONSENT_VERSION,
  SKITRAJ_COOKIEBOOT_CONSENT_NAME,
} from '@/envConfig';

const skitrajConsentName = SKITRAJ_CONSENT_NAME || 'SkitrajCookieConsent';
const skitrajCookieVersion = SKITRAJ_CONSENT_VERSION || 'Default';
const cookieBootConsentName =
  SKITRAJ_COOKIEBOOT_CONSENT_NAME || 'CookieConsent';

export enum ConsentThirdParty {
  GoogleAnalitycs = 'skitrajGA',
  Meta = 'skitrajMeta',
}

export enum GoogleConsents {
  // Google Consent Mode V2
  analytics_storage = 'analytics_storage',
  ad_storage = 'ad_storage',
  ad_user_data = 'ad_user_data',
  ad_personalization = 'ad_personalization',
  // Additional
  functionality_storage = 'functionality_storage',
  personalization_storage = 'personalization_storage',
  security_storage = 'security_storage',
}

export enum ConsentType {
  Granted = 'granted',
  Denied = 'denied',
}

export const isConsentGranted = (cookieName: string) => {
  const match = document.cookie.match(
    new RegExp('(^| )' + cookieName + '=([^;]+)'),
  );
  if (match && match[2] === ConsentType.Granted) {
    return true;
  }
  return false;
};

export const isCookieSet = (cookieName: string) => {
  const match = document.cookie.match(
    new RegExp('(^| )' + cookieName + '=([^;]+)'),
  );

  return match ? true : false;
};

export const isSkitrajCookieSet = (
  cookieName: string,
  skitrajCookieVersion: string,
) => {
  const match = document.cookie.match(
    new RegExp('(^| )' + cookieName + '=([^;]+)'),
  );

  if (match) {
    try {
      const cookieValue = decodeURIComponent(match[2]);
      const cookieObject: ISkitrajCookiesConsent = JSON.parse(cookieValue);
      return cookieObject.skitrajCookieVersion === skitrajCookieVersion
        ? true
        : false;
    } catch (error) {
      // console.info('SKITRAJ | Cookies Consent seems to be not set!', error);
      return false;
    }
  }

  return false;
};

export const isNecessaryCookieSet = (
  cookieName: string = cookieBootConsentName,
) => {
  const match = document.cookie.match(
    new RegExp('(^| )' + cookieName + '=([^;]+)'),
  );

  if (match) {
    try {
      const cookieValue = decodeURIComponent(match[2]);
      const cookieObject: ISkitrajCookiesConsent = JSON.parse(cookieValue);
      return cookieObject.necessary;
    } catch (error) {
      // console.info('SKITRAJ | Cookies Consent seems to be not set!', error);
      return false;
    }
  }

  return false;
};

export interface ICookiesConsent {
  stamp: string;
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
  method: string;
  ver: number;
  utc: number;
  region: string;
}

export interface ISkitrajCookiesConsent extends ICookiesConsent {
  ad_storage: ConsentType;
  ad_user_data: ConsentType;
  ad_personalization: ConsentType;
  analytics_storage: ConsentType;
  functionality_storage: ConsentType;
  personalization_storage: ConsentType;
  security_storage: ConsentType;
  [ConsentThirdParty.Meta]: ConsentType;
  [ConsentThirdParty.GoogleAnalitycs]: ConsentType;
  skitrajCookieVersion: string;
}

// function generateCookiesConsentValue(state: ConsentType): string {
//   const consentObject: ICookiesConsent = {
//     stamp: new Date().toISOString(),
//     necessary: state === ConsentType.Granted ? true : false,
//     preferences: state === ConsentType.Granted ? true : false,
//     statistics: state === ConsentType.Granted ? true : false,
//     marketing: state === ConsentType.Granted ? true : false,
//     method: 'explicit',
//     ver: 19,
//     utc: Date.now(),
//     region: 'pl',
//   };

//   return JSON.stringify(consentObject);
// }

function generateSkitrajCookiesConsentValue(
  state: ConsentType,
  skitrajCookieVersion: string | any,
): string {
  const consentObject: ISkitrajCookiesConsent = {
    stamp: new Date().toISOString(),
    necessary: state === ConsentType.Granted ? true : false,
    preferences: state === ConsentType.Granted ? true : false,
    statistics: state === ConsentType.Granted ? true : false,
    marketing: state === ConsentType.Granted ? true : false,
    method: 'explicit',
    ver: 19,
    utc: Date.now(),
    region: 'pl',
    // Google Cokies Consent Mode V2
    ad_storage: state,
    ad_user_data: state,
    ad_personalization: state,
    analytics_storage: state,
    functionality_storage: state,
    personalization_storage: state,
    security_storage: state,
    // Third Party
    [ConsentThirdParty.Meta]: state,
    [ConsentThirdParty.GoogleAnalitycs]: state,
    skitrajCookieVersion,
  };

  return JSON.stringify(consentObject);
}

const CookiesInfo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [acceptedCookie, setAcceptedCookie] = React.useState(false);
  const dispatch = useDispatch();

  const saveCookies = (state: ConsentType) => {
    const maxAge = 31556926; //time for maxAge "skitrajConsent" cookie 1 year
    document.cookie = `${skitrajConsentName}=${generateSkitrajCookiesConsentValue(
      state,
      skitrajCookieVersion,
    )};; max-age=${maxAge};`;
    document.cookie = `${GoogleConsents.analytics_storage}=${state};; max-age=${maxAge};`;
    document.cookie = `${GoogleConsents.ad_storage}=${state};; max-age=${maxAge};`;
    document.cookie = `${GoogleConsents.ad_user_data}=${state};; max-age=${maxAge};`;
    document.cookie = `${GoogleConsents.ad_personalization}=${state};; max-age=${maxAge};`;
    document.cookie = `${GoogleConsents.personalization_storage}=${state};; max-age=${maxAge};`;
    document.cookie = `${GoogleConsents.functionality_storage}=${state};; max-age=${maxAge};`;
    document.cookie = `${GoogleConsents.security_storage}=${state};; max-age=${maxAge};`;
    document.cookie = `${ConsentThirdParty.Meta}=${state};; max-age=${maxAge};`;
    document.cookie = `${ConsentThirdParty.GoogleAnalitycs}=${state};; max-age=${maxAge};`;
    //document.cookie = `${cookieBootConsentName}=${generateCookiesConsentValue(state)};; max-age=${maxAge};`;
  };

  const handleClick = (state: ConsentType) => {
    saveCookies(state);
    dispatch(analyticsChange({ GA: state }));
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    // const isSkitrajConsent = isSkitrajCookieSet(
    //   skitrajConsentName,
    //   skitrajCookieVersion,
    // );

    const isNecessarySkitrajConsent = isNecessaryCookieSet(
      cookieBootConsentName,
    );

    // const getCookie = (name: string) => {
    //   const cookies = document.cookie.split(';');
    //   for (const cookie of cookies) {
    //     const [cookieName, cookieValue] = cookie.split('=');
    //     if (cookieName.trim() === name) {
    //       return cookieValue;
    //     }
    //   }
    //   return null;
    // };

    if (!isNecessarySkitrajConsent) {
      setIsOpen(SKITRAJ_CONSENT_DISABLED === 'false' ? true : false);
    } else {
      //const GAState = getCookie(ConsentThirdParty.GoogleAnalitycs);
      const GAState = ConsentType.Granted;
      GAState && dispatch(analyticsChange({ GA: GAState }));
    }
  }, []);

  if (!isOpen) return null;

  return (
    <div className="cookie-wrapper">
      <div className="cookie-wrapper_image-wrapper">
        <img src={CookiesMorcik} alt="Cookie morcik" />
      </div>
      <div className="cookie-wrapper_content">
        <H2 className="title">cookies.title</H2>
        <P className="text">
          cookies.text
          <Link
            to="/polityka-prywatnosci"
            data-e2e="privacy-policy"
            className="link"
          >
            {translate('cookies.policy')}
          </Link>
        </P>
      </div>
      <div className="cookie-wrapper_buttons">
        <button
          onClick={() => handleClick(ConsentType.Denied)}
          data-e2e="cookieConsent"
          className="btn btn-dark button"
        >
          {translate('cookies.button')}
        </button>
        <button
          onClick={() => handleClick(ConsentType.Granted)}
          data-e2e="cookieConsent"
          className="btn button"
        >
          {translate('cookies.buttonAccept')}
        </button>
      </div>
    </div>
  );
};

export default CookiesInfo;
