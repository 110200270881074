import PaymentCardSmall from '@component/PaymentCardSmall/PaymentCardSmall';
import { translate } from '@module/Translate/Translate';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import React, { useEffect } from 'react';
import './PaymentCard.scss';

const PaymentCard = ({
  index,
  item,
  onSetActive,
  active,
  setIsSubmitButtonDisable,
  setIsChecked,
  isChecked,
  setIsPermissionForPayingInTheFutureGranted,
}: any) => {
  useEffect(() => {
    isChecked && item.id === PaymentMethodEnum.CARD
      ? setIsPermissionForPayingInTheFutureGranted(true)
      : setIsPermissionForPayingInTheFutureGranted(false);
  }, [isChecked]);

  return (
    <>
      <div
        className={`payment-card ${active ? 'active' : ''} col-12 mb-5 px-0`}
        onClick={() => {
          if (item.id !== PaymentMethodEnum.CARD) setIsChecked(false);
          if (!isChecked && item.id === PaymentMethodEnum.CARD) {
            setIsSubmitButtonDisable(true);
          } else {
            setIsSubmitButtonDisable(false);
          }
          onSetActive();
        }}
      >
        <div className="payment-card_container d-flex justify-content-between">
          <div className="payment-card_content d-block">
            <input
              id={'payment-card-' + index}
              className="custom-checkbox m-auto"
              type="radio"
              onChange={() => {
                onSetActive();
              }}
              checked={active}
            />
            <label htmlFor={'payment-card-' + index}>
              <h3 className="payment-card_title">{item.title}</h3>
            </label>
            <div className="payment-card_subtitle d-block d-md-none">
              {item.subtitle}
            </div>
          </div>
          <div className="payment-card_card-container d-flex">
            {item.img.map((src: any, index: any) => (
              <div key={index} className="payment-card_card ml-4">
                <PaymentCardSmall src={src} />
              </div>
            ))}
          </div>
        </div>
        {item.id === PaymentMethodEnum.CARD && (
          <div className="payment-card_agreement">
            <input
              id={'payment-card-checkbox' + index}
              type="checkbox"
              onChange={(event) => {
                if (!event.target.checked) {
                  setIsSubmitButtonDisable(true);
                } else {
                  setIsSubmitButtonDisable(false);
                }
                setIsChecked(event.target.checked);
              }}
              checked={isChecked}
            />
            <label htmlFor={'payment-card-checkbox' + index}>
              <h3 className="payment-card_agreement">
                {translate('paymentStep.paymentAgreement')}
              </h3>
            </label>
          </div>
        )}
        {item.subtitle && (
          <div className="payment-card_subtitle d-none d-md-block">
            {item.subtitle}
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentCard;
