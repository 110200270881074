import { LanguageNameI, Lang } from './Translate.type';

export const DefaultLanguage = Lang.pl;
export const DefaultSeparator = '.';

export const LanguageName: LanguageNameI = {
  [Lang.en]: 'English',
  [Lang.pl]: 'Polski',
  [Lang.ua]: 'Yкраїнська',
};
