import { http } from '../NetworkHTTP';
import { ActivateAccountDTO } from '../../../../types/auth/activate-account.dto';

export async function activateAccountAction(
  url: string,
  activateAccountDTO: ActivateAccountDTO,
) {
  const { data } = await http.post(url, activateAccountDTO);
  localStorage.setItem('activationToken', data.activationToken);
  return data;
}

export async function activateAction(token: string) {
  return await http.post(`auth/activate-account/${token}/`);
}

export async function checkUserIsActive(token: string) {
  return await http.get(`auth/activate-account/check/${token}`);
}
