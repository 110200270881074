import './LinkToAppStore.scss';
import { GOOGLE_PLAY_LINK, APPLE_STORE_LINK } from '@/envConfig';

export function showGooglePlayLink() {
  if (GOOGLE_PLAY_LINK !== '' && GOOGLE_PLAY_LINK !== undefined) {
    return (
      <a
        rel="noreferrer"
        data-e2e="google-play"
        href={`${GOOGLE_PLAY_LINK}`}
        target="_blank"
      >
        <div className="socialLinks_google-play">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
          >
            <metadata>https://skitraj.pl</metadata>
            <path
              d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M7.3,18.8l6.1-6.1l2.2,2.2
              l-7.3,4c-0.3,0.2-0.6,0.2-0.9,0C7.4,18.9,7.3,18.8,7.3,18.8z M12.9,12.1L7,17.9V6.2C7,6.2,12.9,12.1,12.9,12.1z M16.5,9.6l2.9,1.6
              c0.3,0.2,0.5,0.4,0.5,0.8s-0.2,0.6-0.5,0.8l-2.9,1.6l-2.3-2.3C14.1,12.1,16.5,9.6,16.5,9.6z M7.3,5.3l0.2-0.1C7.7,5,8.1,5,8.3,5.1
              l7.4,4.1l-2.2,2.2C13.5,11.4,7.3,5.3,7.3,5.3z"
              fill="#E78004"
            ></path>
          </svg>
        </div>
      </a>
    );
  }
}

export function showAppleStoreLink() {
  if (APPLE_STORE_LINK !== '' && APPLE_STORE_LINK !== undefined) {
    return (
      <a
        rel="noreferrer"
        data-e2e="apple-store"
        href={`${APPLE_STORE_LINK}`}
        target="_blank"
      >
        <div className="socialLinks_apple-store">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
          >
            <metadata>https://skitraj.pl</metadata>
            <path
              d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M14.8,4C15,5.6,13.5,7.3,12,7.2
                C11.8,5.8,13.1,4.1,14.8,4z M14.6,18c-0.9,0-1.2-0.6-2.3-0.6c-1.1,0-1.4,0.5-2.3,0.6c-1.5,0.1-3.8-3.4-3.8-6.4
                c0-2.8,1.9-4.1,3.6-4.2c0.9,0,1.8,0.6,2.3,0.6c0.6,0,1.6-0.8,2.7-0.6c0.5,0,1.7,0.2,2.6,1.4c-2.2,1.4-1.8,4.4,0.5,5.5
                C17.4,15.6,16,18,14.6,18z"
              fill="#E78004"
            ></path>
          </svg>
        </div>
      </a>
    );
  }
}
