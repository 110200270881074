import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import {
  ORDER_BLOCKED_DAYS_FULFILLED,
  ORDER_BLOCKED_DAYS_PENDING,
  ORDER_BLOCKED_DAYS_REJECTED,
} from './Types';
import { http } from '../NetworkHTTP';
import { GetBlockedDatesWithPagination } from '@rootTypes/order-calendar/get-blocker-dates-with-pagination.dto';

export async function unblockDayAction(id: any) {
  return await http.delete(`order-calendar/${id}`);
}

export async function blockDayAction(data: any) {
  return await http.post(`order-calendar/date/block`, data);
}

export const getOrderBlockedDaysAction = (
  setShowError?: (isOpen: boolean) => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ORDER_BLOCKED_DAYS_PENDING,
      });
      const { data } = await http.get(`order-calendar/current-blocked-dates`);
      data.forEach((element: any) => {
        element.deleteText = 'Delete';
      });
      dispatch({
        type: ORDER_BLOCKED_DAYS_FULFILLED,
        payload: data,
      });
    } catch (err) {
      setShowError && setShowError(true);
      dispatch({
        type: ORDER_BLOCKED_DAYS_REJECTED,
        payload:
          'Nie udało się pobrać dni wyłączonych z możliwości zamówienia.',
      });
    }
  };
};

export const getPaginatedOrderBlockedDays = async (
  params: GetBlockedDatesWithPagination,
) => {
  try {
    const { data } = await http.get(
      `order-calendar/paginated-dates?take=${params.take}&skip=${params.skip}${
        params.sortBy && `&sortBy=${params.sortBy}`
      }${params.order && `&order=${params.order}`}`,
    );
    return data;
  } catch (error) {
    console.error({
      from: 'getPaginatedOrderBlockedDays',
      error,
    });
  }
};
