import {
  PAYMENT_UNPAID_ORDERS_FAIL,
  PAYMENT_UNPAID_ORDERS_REQUEST,
  PAYMENT_UNPAID_ORDERS_SUCCESS,
} from '../Network/Actions/Types';
import { UnpaidOrderState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const unpaidOrdersReducer = (
  state: UnpaidOrderState = { loading: false, unpaidOrders: [], error: null },
  action: Action,
) => {
  switch (action.type) {
    case PAYMENT_UNPAID_ORDERS_REQUEST:
      return { loading: true, unpaidOrders: [], error: null };
    case PAYMENT_UNPAID_ORDERS_SUCCESS:
      return { loading: false, unpaidOrders: action.payload, error: null };
    case PAYMENT_UNPAID_ORDERS_FAIL:
      return {
        loading: false,
        unpaidOrders: state.unpaidOrders,
        error: action.payload,
      };
    default:
      return state;
  }
};
