import P from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import React from 'react';
import { Link } from 'react-router-dom';

export default function PrivacyRegister() {
  return (
    <div className="registration-form_policy-text">
      <P>registrationForm.contact</P>
      <P>registrationForm.adminDataTextFirst</P>
      <br />
      <P>registrationForm.adminDataTextSecond</P>
      <P>
        registrationForm.policyViewMore
        <Link
          className="link"
          to="/polityka-prywatnosci"
          data-e2e="privacy-policy"
        >
          &nbsp;
          <span>{translate('registrationForm.policyPrivacy')}</span>
        </Link>
      </P>
    </div>
  );
}
