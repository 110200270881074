import Shoutout from '@component/Shoutout/Shoutout';
import Social from '@component/Social/Social';
import NewsletterForm from '@form/NewsletterForm/NewsletterForm';
import { translate } from '@module/Translate/Translate';
import NutsBackground from '@template/NutsBackground/NutsBackground';
import { Link } from 'react-router-dom';
import Logo from '@images/logo.png';

const Newsletter = () => (
  <div className="newsletter-section">
    <NutsBackground />
    <div className="container content">
      <div className="row">
        <div className="col text">
          <Link className="m-auto" to={'/'}>
            <img
              src={Logo}
              alt={translate('newsletter.alt')}
              className="logo"
            />
          </Link>

          <Shoutout />
          <NewsletterForm initialValues={{}} onSubmit={Promise.resolve} />
          <br />
          <Social />
        </div>
      </div>
    </div>
  </div>
);
export default Newsletter;
