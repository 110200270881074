import React from 'react';
import './HowTo.scss';
import { move, rent } from './data';
import { translate } from '../../../../Module/Translate/Translate';
import { H2, H3 } from '@component/Text/Text';
interface IProps {
  section: 'move' | 'rent';
}
function HowTo({ section }: IProps) {
  const items = section === 'move' ? move : rent;

  return (
    <div
      className={`move-howTo-container ${
        section === 'rent' ? 'color-white' : ''
      }`}
    >
      <div className="title-group">
        <H2 className="title-group_title">movePage.howToBlock.title</H2>
        <H3 className="title-group_subtitle">
          {section === 'move'
            ? translate('movePage.howToBlock.subtitle')
            : translate('rentPage.howToBlock.subtitle')}
        </H3>
      </div>
      <ul className="howTo-list">
        {items.map((item: any) => (
          <li key={item.id} className="howTo-list-item">
            <img src={item.image} alt="phone" className="img-desktop" />
            <img src={item.imageMobile} alt="phone" className="img-mobile" />

            <div className="item-title-group">
              <H3 className="item-title-group_title">{item.title}</H3>
              <p className="item-title-group_subtitle">{item.subtitle}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default HowTo;
