import { useGoogleLogin } from '@react-oauth/google';
import React, { useContext } from 'react';
import GoogleIcon from '@images/google.png';
import { ExternalLoginContext } from '@view/RegistrationStepper/components/AuthenticationStep/components/LoginUser/LoginUser';
import ButtonSocial from '@component/ButtonSocial/ButtonSocial';
import { FacebookGoogleEvents } from '@rootTypes/auth/facebook-google-auth.enum';
import { signUpEvent } from '@src/GTM/GTM';
import { EMethodType } from '@rootTypes/GTM/method-type.enum';
import { AccountTypes } from '@rootTypes/orders/account-type.enum';

interface IProps {
  disabled?: boolean;
  loginOrRegister: 'login' | 'register';
}

function GoogleAuth({ disabled, loginOrRegister }: IProps) {
  const handleExternalLogin: any = useContext<any>(ExternalLoginContext);
  const onSuccess = (res: any): void => {
    try {
      if (res) {
        signUpEvent(AccountTypes.PRIVATE, EMethodType.google);
        handleExternalLogin(res.access_token, 'google');
      } else {
        console.error({
          from: 'GoogleAuth',
          method: 'onSuccess',
          error: res,
        });
      }
    } catch (error) {
      console.error({
        from: 'GoogleAuth',
        method: 'onSuccess',
        error,
      });
    }
  };

  const onError = (e: any) => {
    console.error({
      from: 'GoogleAuth',
      method: 'onFailure',
    });
    console.error(e);
  };

  const googleLoginWebView = () => {
    //@ts-ignore
    window.ReactNativeWebView.postMessage(
      loginOrRegister === 'login'
        ? FacebookGoogleEvents.GOOGLE_LOGIN
        : FacebookGoogleEvents.GOOGLE_REGISTER,
    );
  };

  const signIn = useGoogleLogin({
    onSuccess,
    onError,
  });

  return (
    <ButtonSocial
      onClick={
        //@ts-ignore
        window.isWebViewClient || window.isWebViewIOSClient
          ? googleLoginWebView
          : signIn
      }
      disabled={disabled}
    >
      <img src={GoogleIcon} alt="socialIcon" />
    </ButtonSocial>
  );
}

export default GoogleAuth;
