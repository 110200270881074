import { useEffect, useState } from 'react';
import { translate } from '@module/Translate/Translate';
import { formatDateToLocalTime } from '@util/Dates';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { getAdminToolUserOrders } from '@network/Actions/Orders';

interface IProps {
  userId: number;
  isOrderActive: boolean;
}

const LIMIT_ON_PAGE = 7;

export default function OpenedOrdersTable({ userId, isOrderActive }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [mount, setMount] = useState(true);

  const [orders, setOrders] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    isOrderActive,
    sortField: '',
    sortOrder: null,
  });

  const getData = async () => {
    setIsLoading(true);

    await getAdminToolUserOrders({ userId, ...lazyParams })
      .then(({ data }: any) => {
        setOrders(data.items);
        setTotalItems(data.meta.totalItems);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (mount) {
      getData();
      setMount(false);
    } else if (refresh) {
      getData();
      setRefresh(false);
    } else {
      getData();
    }
  }, [refresh, lazyParams, mount]);

  useEffect(() => {
    getData();
  }, [lazyParams]);

  const onPage = (event: any) => {
    setLazyParams({ ...event, isOrderActive });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event, isOrderActive });
  };

  return (
    <DataTable
      value={orders}
      responsiveLayout="scroll"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      dataKey="id"
      paginator
      lazy
      loading={isLoading}
      first={lazyParams.first}
      totalRecords={totalItems}
      rows={LIMIT_ON_PAGE}
      expandedRows={expandedRows}
      stripedRows
      selectionPageOnly
      emptyMessage="No data found"
      className="datatable-responsive"
      onRowToggle={(e: any) => setExpandedRows(e.data)}
      onPage={onPage}
      onSort={onSort}
      sortOrder={lazyParams.sortOrder}
      sortField={lazyParams.sortField}
    >
      <Column field="id" sortable header={translate('adminTool.users.id')} />
      <Column field="orderType" sortable header="orderType" />
      <Column field="amount" sortable header="amount" />
      <Column field="storagePrice" sortable header="storagePrice" />
      <Column field="rentPrice" sortable header="rentPrice" />
      <Column field="shopPrice" sortable header="shopPrice" />
      <Column field="status" sortable header="status" />
      <Column
        field="createdAt"
        sortable
        header={translate('adminTool.users.createdAt')}
        body={formatDateToLocalTime}
      />
      <Column
        field="updatedAt"
        sortable
        header={translate('adminTool.users.updatedAt')}
        body={formatDateToLocalTime}
      />
      <Column
        field="updatedBy"
        sortable
        header={translate('adminTool.users.updatedBy')}
      />
    </DataTable>
  );
}
