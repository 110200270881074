import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import React, { useRef, useState } from 'react';
import CompanyData from './components/CompanyData/CompanyData';
import PersonalData from './components/PersonalData/PersonalData';
import * as Yup from 'yup';
import './EditUser.scss';
import RolesData from './components/RolesData/RolesData';
import { FormFieldFormik } from '@component/FormFieldFormik/FormFieldFormik';
import { H5 } from '@component/Text/Text';
import { UserRawRoles, User } from '@models/user.model';
import { translate } from '@module/Translate/Translate';
import { editUserAction } from '@network/Actions/Users';
import { UserRole } from '@rootTypes/roles/user-role.enum';
import { checkIsAllowed } from '@util/UserValidation';
import { Panel } from 'primereact/panel';
import AddressesTable from './components/AddressesTable/AddressesTable';
import NotificationsTable from './components/NotificationsTable/NotificationsTable';
import ClosedOrdersTable from './components/OrdersTable/ClosedOrdersTable';
import OpenedOrdersTable from './components/OrdersTable/OpenedOrdersTable';
import LoginAttemptTable from './components/LoginAttemptTable/LoginAttemptTable';
import PaymentsTable from './components/PaymentsTable/PaymentsTable';
import UnpaidPaymentsTable from './components/UnpaidPaymentsTable/UnpaidPaymentsTable';

interface Props {
  user: UserRawRoles;
  editor: User;
  setRefresh: (refresh: boolean) => void;
  visibleAddresses?: boolean;
  visibleNotifications?: boolean;
  visibleOrders?: boolean;
  visibleLoginAttempts?: boolean;
  visiblePayments?: boolean;
  visibleUnpaidPayments?: boolean;
}

const statuses = [
  { label: translate('adminTool.users.active'), value: true },
  { label: translate('adminTool.users.notActive'), value: false },
];
const archived = [
  { label: translate('adminTool.users.archived'), value: true },
  { label: translate('adminTool.users.notArchived'), value: false },
];

export default function EditUser({
  user,
  editor,
  setRefresh,
  visibleAddresses = false,
  visibleNotifications = false,
  visibleOrders = false,
  visibleLoginAttempts = false,
  visiblePayments = false,
  visibleUnpaidPayments = false,
}: Props) {
  const [collapsedActiveOrders, setCollapsedActiveOrders] = useState(true);
  const [collapsedClosedOrders, setCollapsedClosedOrders] = useState(true);
  const [collapsedLoginAttempts, setCollapsedLoginAttempts] = useState(true);
  const [collapsedAddresses, setCollapsedAddresses] = useState(true);
  const [collapsedNotifications, setCollapsedNotifications] = useState(true);
  const [collapsedPayments, setCollapsedPayments] = useState(true);
  const [collapsedUnpaidPayments, setCollapsedUnpaidPayments] = useState(true);

  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      isActive: user ? user.isActive : false,
      phone: user?.phone ? user.phone : '',
      email: user ? user.email : '',
      isArchived: user ? user.isArchived : false,
    },
    validationSchema: Yup.object({
      phone: Yup.string().min(9).max(9),
      email: Yup.string(),
      isActive: Yup.boolean(),
      isArchived: Yup.boolean(),
    }),
    onSubmit: (values: any) => {
      values.phone = values.phone ? values.phone : null;
      values.updatedBy = editor.email;
      editUserAction(user.id, values)
        .then(() => {
          setRefresh(true);
          showMessage('success', translate('default.message.success'));
        })
        .catch((error: any) => {
          showMessage(
            'error',
            translate('default.message.fail') +
              ' ' +
              translate('errors.' + error.response.data.error.message),
          );
        })
        .finally(() => (values.phone = user?.phone ? user.phone : ''));
    },
  });

  const labelChecker = (field: string, data: boolean) => {
    switch (field) {
      case 'roles':
        return data
          ? translate('adminTool.users.admin')
          : translate('adminTool.users.user');
      case 'isActive':
        return data
          ? translate('adminTool.users.active')
          : translate('adminTool.users.notActive');
      case 'isArchive':
        return data
          ? translate('adminTool.users.isArchive')
          : translate('adminTool.users.notArchived');
    }
  };

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  const getOpenedOrdersData = (user: any) => {
    return (
      <Panel
        header={translate('adminTool.users.openedOrders')}
        toggleable
        collapsed={collapsedActiveOrders}
        onToggle={(e) => setCollapsedActiveOrders(e.value as boolean)}
      >
        <ul className="users-addresses">
          <OpenedOrdersTable userId={user.id} isOrderActive={true} />
        </ul>
      </Panel>
    );
  };
  const getClosedOrdersData = (user: any) => {
    return (
      <Panel
        header={translate('adminTool.users.closedOrders')}
        toggleable
        collapsed={collapsedClosedOrders}
        onToggle={(e) => setCollapsedClosedOrders(e.value as boolean)}
      >
        <ul className="users-addresses">
          <ClosedOrdersTable userId={user.id} isOrderActive={false} />
        </ul>
      </Panel>
    );
  };

  const getAddressesData = (user: any) => {
    return (
      <Panel
        header={translate('adminTool.users.addresses')}
        toggleable
        collapsed={collapsedAddresses}
        onToggle={(e) => setCollapsedAddresses(e.value as boolean)}
      >
        <ul className="users-addresses">
          <AddressesTable userId={user.id} />
        </ul>
      </Panel>
    );
  };

  const getLoginAttemptTable = (user: any) => {
    return (
      <Panel
        header={translate('adminTool.users.attempts')}
        toggleable
        collapsed={collapsedLoginAttempts}
        onToggle={(e) => setCollapsedLoginAttempts(e.value as boolean)}
      >
        <ul className="users-addresses">
          <LoginAttemptTable email={user.email} />
        </ul>
      </Panel>
    );
  };

  const getNotificationsData = (user: any) => {
    return (
      <Panel
        header={translate('adminTool.users.notifications')}
        toggleable
        collapsed={collapsedNotifications}
        onToggle={(e) => setCollapsedNotifications(e.value as boolean)}
      >
        <ul className="users-addresses">
          <NotificationsTable userId={user.id} />
        </ul>
      </Panel>
    );
  };

  const getPaymentsData = (user: any) => {
    return (
      <Panel
        header={translate('adminTool.users.payments')}
        toggleable
        collapsed={collapsedPayments}
        onToggle={(e) => setCollapsedPayments(e.value as boolean)}
      >
        <ul className="users-addresses">
          <PaymentsTable userId={user.id} />
        </ul>
      </Panel>
    );
  };

  const getUnpaidPaymentsData = (user: any) => {
    return (
      <Panel
        header={translate('adminTool.users.unpaidPayments')}
        toggleable
        collapsed={collapsedUnpaidPayments}
        onToggle={(e) => setCollapsedUnpaidPayments(e.value as boolean)}
      >
        <ul className="users-addresses">
          <UnpaidPaymentsTable userId={user.id} />
        </ul>
      </Panel>
    );
  };

  return (
    <div className="users-main-container border-details">
      <div className="users-header-container">
        <Messages ref={message} />
        <h2>
          {translate('adminTool.users.detailsFor')}
          {user.email}
        </h2>
      </div>
      <div className="users-data-container justify-content-center row">
        <div className="users-info-left-container col col-12 row">
          <div className="users-info-block col col-">
            <h2 className="users-header">
              {translate('adminTool.users.currentData')}
            </h2>
            <br />
            <H5 className="users-h5">{translate('adminTool.users.email')}</H5>
            <p>{user.email}</p>
            {user.roles_type !== UserRole.SuperUser ? (
              <RolesData
                user={user}
                setRefresh={setRefresh}
                showMessage={showMessage}
                editor={editor}
              />
            ) : null}
            <H5 className="users-h5">{translate('adminTool.users.phone')}</H5>
            <p>{user.phone}</p>
            <H5 className="users-h5">{translate('adminTool.users.isAdmin')}</H5>
            <p>{labelChecker('isAdmin', checkIsAllowed(user))}</p>
            <H5 className="users-h5">
              {translate('adminTool.users.isActive')}
            </H5>
            <p>{labelChecker('isActive', user.isActive)}</p>

            <H5 className="users-h5">
              {translate('adminTool.users.isArchive')}
            </H5>
            <p>{labelChecker('isArchive', user.isArchived)}</p>
          </div>
          <div className="users-edit-block col col-12">
            <h2 className="users-header">
              {translate('adminTool.users.edit')}
            </h2>
            <br />
            <form className="users-form" onSubmit={formik.handleSubmit}>
              <H5 className="users-h5">
                {translate('adminTool.users.isActive')}
              </H5>
              <Dropdown
                value={formik.values.isActive}
                options={statuses}
                className="type-dropdown"
                name="isActive"
                optionLabel="label"
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <H5 className="users-h5">
                {translate('adminTool.users.isArchive')}
              </H5>
              <Dropdown
                value={formik.values.isArchived}
                options={archived}
                className="type-dropdown"
                name="isArchived"
                optionLabel="label"
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <FormFieldFormik
                name="phone"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.phone}
                label={translate('adminTool.users.phone')}
                onBlur={formik.handleBlur}
              />
              <FormFieldFormik
                name="email"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.email}
                label={translate('adminTool.users.email')}
                onBlur={formik.handleBlur}
              />
              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.values.isActive !== user.isActive ||
                  (formik.values.phone && formik.values.phone !== user.phone) ||
                  formik.values.email !== user.email ||
                  formik.values.isArchived !== user.isArchived
                    ? ''
                    : 'inactive'
                }`}
              >
                {translate('adminTool.users.update')}
              </button>
            </form>
          </div>
        </div>
        <div className="users-info-right-container col col-12 row">
          {user.personalDetails && <PersonalData user={user} />}
          {user.companyDetails && <CompanyData user={user} />}
        </div>
      </div>
      {visibleOrders && getOpenedOrdersData(user)}
      {visibleOrders && getClosedOrdersData(user)}
      {visiblePayments && getPaymentsData(user)}
      {visibleUnpaidPayments && getUnpaidPaymentsData(user)}
      {visibleAddresses && getAddressesData(user)}
      {visibleNotifications && getNotificationsData(user)}
      {visibleLoginAttempts && getLoginAttemptTable(user)}
    </div>
  );
}
