import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import http from '../NetworkHTTP';
import {
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from './Types';
import getApiEndPoint from '../NetworkApiEndpoint';
import { activateAccountAction } from './ActivateAccount';
import { requestPermission } from '../../firebaseInit';

export const registrationAction = (
  registrationData: any,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });
    try {
      if (registrationData.isCompanyAccount) {
        registrationData.details = {
          name: registrationData.name,
          restOfName: registrationData.restOfName || '',
        };
      } else {
        registrationData.details = {
          firstName: registrationData.firstName,
          lastName: registrationData.lastName,
        };
      }

      const { data: user } = await http.post('users', {
        email: registrationData.email,
        password: registrationData.password,
        isCompanyAccount: registrationData.isCompanyAccount,
        isRODOConfirmed: registrationData.isRODOConfirmed,
        isPrivacyConfirmed: registrationData.isPrivacyConfirmed,
        isNewsletterConfirmed: registrationData.isNewsletterConfirmed,
        details: registrationData.details,
      });

      const activateAccountPayload = { email: registrationData.email };
      const url = getApiEndPoint('activate');
      await activateAccountAction(url, activateAccountPayload);

      requestPermission(registrationData.email);

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: user,
      });
    } catch (err: any) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: err.response.data.message.error,
      });
    }
  };
};
