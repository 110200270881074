import { FindUserRolesDto } from '../../../types/roles/find-user-roles.dto';
import { UserRole } from '../../../types/roles/user-role.enum';
import { User } from '../models/user.model';

export const checkIsAllowed = (user: User): boolean => {
  if (user !== undefined) {
    const userRole = user.roles?.find(
      (role: FindUserRolesDto) =>
        role.type === UserRole.Admin || role.type === UserRole.SuperUser,
    );
    if (userRole) {
      return true;
    }
  }
  return false;
};

export const checkIsSuperUser = (user: User) => {
  if (user !== undefined) {
    const userRole = user.roles?.find(
      (role: FindUserRolesDto) => role.type === UserRole.SuperUser,
    );
    if (userRole) {
      return true;
    }
  }
  return false;
};

export const getUserRoles = (role: string, filter?: boolean) => {
  const roles: any = [
    { label: UserRole.User, value: UserRole.User },
    { label: UserRole.WarehousePerson, value: UserRole.WarehousePerson },
    { label: UserRole.Admin, value: UserRole.Admin },
  ];
  if (role === UserRole.SuperUser) {
    roles.push({ label: UserRole.SuperUser, value: UserRole.SuperUser });
  }
  if (filter) roles.push({ label: 'All', value: 'All' });
  return roles;
};
