import React, { useEffect, useState } from 'react';
import './HidingPlaceBlock.scss';
import { HidingPlaceElement } from './components/HidingPlaceElement/HidingPlaceElement';
import SubmitCourier from './components/SubmitCourier/SubmitCourier';
import { useDispatch, useSelector } from 'react-redux';
import P, { H1 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { getUserActiveOrdersAction } from '@network/Actions/Orders';
import { FindOrderDto } from '@rootTypes/orders/find-order.dto';
import { OrderStatus } from '@rootTypes/orders/order-status.enum';
import { OrderType } from '@rootTypes/orders/order-type.enum';
import { FindStockDto } from '@rootTypes/stocks/find-stock.dto';
import { RootState } from '@src/store';
import { OrdersState } from '@type/Custom';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';

export interface Props {
  userId: number;
  setSpinner: (value: boolean) => void;
}

function HidingPlaceBlock({ userId, setSpinner }: Props) {
  const [search, setSearch] = useState<string>('');
  const [submitCourier, setSubmitCourier] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { orders } = useSelector<RootState, OrdersState>(
    (state) => state.orders,
  );
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    dispatch(getUserActiveOrdersAction(userId, error));
  }, []);

  useEffect(() => {
    if (refresh) {
      dispatch(getUserActiveOrdersAction(userId, error));
      setRefresh(false);
    }
  }, [refresh]);

  const changeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const error = () => {
    setShowError(true);
  };

  return (
    <>
      <Modal
        showModal={showError}
        setShowModal={setShowError}
        dialogClassName="error-modal"
      >
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <div className="hiding-place">
        {submitCourier ? (
          <SubmitCourier />
        ) : (
          <div className="container-header hiding-place-header">
            <div>
              <H1 className="container-title hiding-place-header_title">
                hidingPlaceBlock.title
              </H1>
              <P className="container-subtitle hiding-place-header_subtitle">
                hidingPlaceBlock.subtitle
              </P>
            </div>
            <div>
              <P className="hiding-place-header_search">
                hidingPlaceBlock.searchHeader
              </P>
              <input
                type="text"
                placeholder={translate('hidingPlaceBlock.inputPlaceholder')}
                value={search}
                onChange={changeEvent}
                className="custom-input"
              />
            </div>
          </div>
        )}
        {orders &&
          orders.length > 0 &&
          orders
            .filter((el: FindOrderDto) =>
              el.id.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
            )
            .filter((el: FindOrderDto) => {
              if (
                el.orderType.includes(OrderType.STORAGE) &&
                (el.status === OrderStatus.A || el.status === OrderStatus.P)
              ) {
                return el;
              }
            })
            .filter((el: FindOrderDto) => {
              if (
                el.stocks.filter((el: FindStockDto) => {
                  if (el.isActive) {
                    return el;
                  }
                }).length > 0
              ) {
                return el;
              }
            })
            .map((el: FindOrderDto, count: number) => (
              <HidingPlaceElement
                shouldBeOpened={count === 0}
                setRefresh={setRefresh}
                key={el.id}
                order={el}
                visibleSpinner={setSpinner}
                showError={(value) => setShowError(value)}
              />
            ))}
      </div>
    </>
  );
}

export default HidingPlaceBlock;
