import React, { FunctionComponent } from 'react';
import { translate } from '../../Module/Translate/Translate';
import TextI from './Text.type';

interface CustomProps {
  id?: string;
}

type Props = CustomProps & React.PropsWithChildren<TextI>;

export const H1: FunctionComponent<Props> = (props) => {
  return <h1 {...props}>{translate(props.children)}</h1>;
};

export const H2: FunctionComponent<Props> = (props) => {
  return <h2 {...props}>{translate(props.children)}</h2>;
};

export const H3: FunctionComponent<Props> = (props) => {
  return <h3 {...props}>{translate(props.children)}</h3>;
};

export const H4: FunctionComponent<Props> = (props) => {
  return <h4 {...props}>{translate(props.children)}</h4>;
};

export const H5: FunctionComponent<Props> = (props) => {
  return <h5 {...props}>{translate(props.children)}</h5>;
};

export const P: FunctionComponent<Props> = (props) => {
  return <p {...props}>{translate(props.children)}</p>;
};

export const B: FunctionComponent<Props> = (props) => {
  return <b {...props}>{translate(props.children)}</b>;
};

export default P;
