import React, { useCallback, useEffect, useState } from 'react';
import './SelectAddress.scss';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import AddressInformation from '@component/AddressInformation/AddressInformation';
import CustomSelect from '@component/CustomSelect/CustomSelect';
import P, { H3 } from '@component/Text/Text';
import DeliveryAddressForm from '@form/DeliveryAddressForm/DeliveryAddressForm';
import { translate } from '@module/Translate/Translate';
import { currentOrderAction } from '@network/Actions/CurrentOrder';
import {
  createAddressAction,
  editAddressAction,
  currentUserAction,
} from '@network/Actions/CurrentUser';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { RootState } from '@src/store';
import { UserState, CurrentOrderState, BasketState } from '@type/Custom';
import { checkoutEvent } from '@src/GTM/GTM';

interface IProps {
  showError: (value: boolean) => void;
  visibleSpinner: (value: boolean) => void;
}

export const SelectAddress = ({ showError, visibleSpinner }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const { order }: CurrentOrderState = useSelector<
    RootState,
    CurrentOrderState
  >(({ currentOrder }) => currentOrder);
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);
  const isReturnBoxesState = location.pathname === '/return-boxes';

  const storageProducts = state.products.filter(
    (item) =>
      item.type === ProductType.rent || item.type === ProductType.storage,
  );
  const isSell =
    storageProducts.length === 0 && !isReturnBoxesState ? true : false;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  window.addEventListener('resize', () =>
    setIsDesktop(window.innerWidth > 768),
  );

  const onlyDelivery = !storageProducts.length && !isDesktop;

  const dispatch = useDispatch();
  useEffect(() => {
    checkoutEvent(3);
  }, []);

  const createAddress = (item: any, isDeliveryAddress: boolean) => {
    visibleSpinner(true);

    createAddressAction(userData.user.id, item)
      .then(({ data }) =>
        isDeliveryAddress
          ? setDeliveryAddresses(data)
          : setReturnedAddresses(data),
      )
      .catch(() => {
        showError(true);
      })
      .finally(() => {
        visibleSpinner(false);
      });
  };

  const updateAddress = (item: any) => {
    editAddressAction(userData.user.id, item.id, item)
      .catch(() => {
        showError(true);
      })
      .finally(() => {
        visibleSpinner(false);
      });
  };

  const refreshUser = () => {
    dispatch(currentUserAction());
  };

  const [showEditAddressDelivery, setShowEditAddressDelivery] = useState(false);
  const [deliveryAddress, setDeliveryAddresses] = useState(
    isReturnBoxesState ? state.stocks.deliveryAddress : order.deliveryAddress,
  );

  function onEditDeliveryAddress(value: any) {
    setShowEditAddressDelivery(false);
    setDeliveryAddresses(value);
    if ((!returnedAddress && !isDesktop && !onlyDelivery) || isSell) {
      setReturnedAddresses(value);
    }
    refreshUser();
  }

  const [showEditAddressReturned, setShowEditAddressReturned] = useState(false);
  const [returnedAddress, setReturnedAddresses] = useState(
    isReturnBoxesState ? state.stocks.returnAddress : order.returnedAddress,
  );

  function onEditReturnedAddress(value: any) {
    setShowEditAddressReturned(false);
    setReturnedAddresses(value);
    refreshUser();
  }

  const onSubmit = useCallback(
    (value: any) => {
      dispatch(
        currentOrderAction({
          deliveryAddress: value.deliveryAddress,
          returnedAddress: value.returnedAddress,
        }),
      );

      history.push({
        ...location,
        state: {
          activeStep: 4,
        },
      });
    },
    [history, location],
  );

  return (
    <div
      className={
        !isSell
          ? 'selected-address flex-wrap d-flex pr-0 pl-0'
          : 'selected-address-single'
      }
    >
      <div
        className={`deliver order-0 col-12 ${
          onlyDelivery ? 'mb-0' : 'col-md-6'
        }`}
      >
        <H3 className="deliver_title">
          {translate('addressStep.deliveryAddress')}
        </H3>
        {!isSell && !onlyDelivery && (
          <P className="deliver_subtitle">
            {translate('addressStep.boxesYour')}
          </P>
        )}

        {deliveryAddress ? (
          <div className="deliver_container text-center">
            <AddressInformation
              item={deliveryAddress}
              onEdit={() => setShowEditAddressDelivery(true)}
            />
          </div>
        ) : (
          <div className="deliver_field">
            <CustomSelect
              placeholder={translate('addressStep.chooseAddress')}
              options={userData.user.addresses}
              onChange={onEditDeliveryAddress}
            />
          </div>
        )}

        {showEditAddressDelivery ? (
          <DeliveryAddressForm
            index={translate('addressStep.deliveryAddress')}
            item={deliveryAddress}
            onEditAddress={onEditDeliveryAddress}
            edit={!!deliveryAddress}
            createAddress={(createdAddress: any) =>
              createAddress(createdAddress, true)
            }
            updateAddress={updateAddress}
          />
        ) : (
          <button
            className="deliver_button btn btn-secondary btn-100"
            onClick={() => {
              setDeliveryAddresses(null);
              setShowEditAddressDelivery(true);
            }}
          >
            {deliveryAddress
              ? isDesktop
                ? translate('addressStep.editAddress')
                : translate('addressStep.change')
              : translate('addressStep.newAddress')}
          </button>
        )}
      </div>

      {!onlyDelivery && !isSell && (isDesktop || deliveryAddress) && (
        <div className="return col-12 col-md-6">
          <H3 className="return_title">
            {translate('addressStep.returnAddress')}
          </H3>
          <P className="return_subtitle">{translate('addressStep.address')}</P>

          {returnedAddress ? (
            <div className="return_container text-center">
              <AddressInformation
                item={returnedAddress}
                onEdit={() => setShowEditAddressReturned(true)}
              />
            </div>
          ) : (
            <div className="deliver_field">
              <CustomSelect
                placeholder={translate('addressStep.chooseAddress')}
                options={userData.user.addresses}
                onChange={onEditReturnedAddress}
              />
            </div>
          )}

          {showEditAddressReturned ? (
            <DeliveryAddressForm
              index={'returned-address'}
              item={returnedAddress}
              onEditAddress={onEditReturnedAddress}
              edit={!!returnedAddress}
              createAddress={createAddress}
              updateAddress={updateAddress}
            />
          ) : (
            <button
              className="return_button btn btn-secondary btn-100"
              onClick={() => {
                setReturnedAddresses(null);
                setShowEditAddressReturned(true);
              }}
            >
              {returnedAddress
                ? isDesktop
                  ? translate('addressStep.editAddress')
                  : translate('addressStep.change')
                : translate('addressStep.newAddress')}
            </button>
          )}
        </div>
      )}

      {deliveryAddress && (onlyDelivery || returnedAddress) && (
        <>
          <div className="selected-address_line d-block d-md-none" />
          <button
            className="btn w-100 selected-address_button"
            onClick={() =>
              onSubmit({
                deliveryAddress: deliveryAddress,
                returnedAddress: returnedAddress,
              })
            }
          >
            {isDesktop
              ? translate('addressStep.goFurther')
              : translate('addressStep.next')}
          </button>
        </>
      )}
    </div>
  );
};

export default SelectAddress;
