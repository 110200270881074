import { useEffect, useMemo, useState } from 'react';
import './SummaryForm.scss';
import * as Yup from 'yup';
import { P24_URL } from '@/envConfig';
import Modal from '@component/Modal/Modal';
import MessageOrderClosingTime from '@form/MessageOrderClosingTime/MessageOrderClosingTime';
import { translate } from '@module/Translate/Translate';
import { orderAction } from '@network/Actions/Order';
import { changeUserPaymentMethod, payment } from '@network/Actions/Payment';
import { updateSomeStocksAction } from '@network/Actions/Stocks';
import { FindBasketProductDto } from '@rootTypes/products/find-basket-product.dto';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { FindStockDto } from '@rootTypes/stocks/find-stock.dto';
import { StockStatus } from '@rootTypes/stocks/stock-status.enum';
import { UpdateStockDto } from '@rootTypes/stocks/update-stock.dto';
import {
  TransportTypeEnum,
  UndefinedTransportCost,
} from '@rootTypes/transport/transport-type.enum';
import { RootState } from '@src/store';
import {
  BasketState,
  CurrentOrderState,
  UserState,
  TransportPriceState,
  OrderBlockedDaysState,
  PromotionState,
} from '@type/Custom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import P, { H3 } from '@component/Text/Text';
import { useFormik } from 'formik';
import { textFormater } from '@util/TextFormater';
import { getUserSettingsAction } from '@network/Actions/CurrentUser';
import { disabledDays } from '@util/DisabledDays';
import { verifyOrderClose } from '@util/Dates';
import { getTransportAction } from '@network/Actions/TransportPrice';
import { paymentEvent } from '@src/GTM/GTM';

interface IProps {
  onSubmit: any;
  data: Record<string, string>;
  storagePeriod?: string;
  state: BasketState;
  rentState: FindBasketProductDto[];
  storeState: FindBasketProductDto[];
  shopState: FindBasketProductDto[];
  dates: Record<string, string | Date>[];
  defaultOrderClosingTime: number;
  showError: (value: boolean) => void;
  visibleSpinner: (value: boolean) => void;
}

export const SummaryForm = ({
  onSubmit,
  data,
  storagePeriod,
  rentState,
  state,
  storeState,
  shopState,
  dates,
  defaultOrderClosingTime,
  showError,
  visibleSpinner,
}: IProps) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { order: currentOrder }: CurrentOrderState = useSelector<
    RootState,
    CurrentOrderState
  >(({ currentOrder }) => currentOrder);
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const { transport }: TransportPriceState = useSelector<
    RootState,
    TransportPriceState
  >(({ transportPrice }) => transportPrice);

  const getStorageTransportPrice = transport.find(
    (item) => item.type === TransportTypeEnum.storage,
  )?.price;

  const storageTransportPrice =
    getStorageTransportPrice === 0
      ? 0
      : getStorageTransportPrice || UndefinedTransportCost;

  const location = useLocation();

  const stocks = state.stocks;

  const orderBlockedDates: OrderBlockedDaysState = useSelector<
    RootState,
    OrderBlockedDaysState
  >(({ orderBlockedDates }) => orderBlockedDates);

  const { promotion }: PromotionState = useSelector<RootState, PromotionState>(
    ({ promotion }) => promotion,
  );

  const disabledAdminDays = orderBlockedDates.dates.map((day) => day.date);

  const holidays = useMemo(
    () => disabledDays(disabledAdminDays),
    [disabledAdminDays],
  );

  const summaryPrice = localStorage.getItem('summaryPrice');
  const parsedSummaryPrice = JSON.parse(summaryPrice ? summaryPrice : '0');
  const storePriceByMonth = localStorage.getItem('ceilStorePriceByMonth');
  const parsedStorePriceByMonth = JSON.parse(
    storePriceByMonth ? storePriceByMonth : '0',
  );
  const [errorMessage, setErrorMessage] = useState('');

  const storePrice = localStorage.getItem('storePrice');
  const parsedStorePrice = JSON.parse(storePrice ? storePrice : '0');
  const rentPrice = localStorage.getItem('rentPrice');
  const parsedRentPrice = JSON.parse(rentPrice ? rentPrice : '0');
  const shopPrice = localStorage.getItem('shopPrice');
  const parsedShopPrice = JSON.parse(shopPrice ? shopPrice : '0');
  const continuationRentPrice = localStorage.getItem('continuationRentPrice');
  const parsedСontinuationRentPrice = JSON.parse(
    continuationRentPrice ? continuationRentPrice : '0',
  );

  function confirmPaymentData(isTransportsAmortizationBasket: boolean) {
    if (!userData.user.isCompanyAccount) {
      currentOrder.billing['name'] = currentOrder.billing['firstName'];
      currentOrder.billing['restOfName'] = currentOrder.billing['lastName'];
      delete currentOrder.billing['firstName'];
      delete currentOrder.billing['lastName'];
    }
    const orderData = prepareOrderForInvoice();
    const periods = state.periods;
    const dates = state.dates;
    const order = {
      dates: dates,
      periods: periods,
      products: orderData,
      orderType: getOrderType(),
      deliveryAddress: currentOrder.deliveryAddress,
      returnedAddress: currentOrder.returnedAddress,
      isCompanyAccount: userData.user.isCompanyAccount,
      email: userData.user.email,
      phone: userData.user.phone,
      billing: currentOrder.billing,
      paymentType: currentOrder.paymentType,
      summaryPrice: parsedSummaryPrice,
      storagePrice: parsedStorePriceByMonth,
      rentPrice: parsedRentPrice,
      shopPrice: parsedShopPrice,
      transportPrice: transport,
      continuationRentPrice: parsedСontinuationRentPrice,
      promotion: promotion,
    };
    //invoke backend function and place order
    placeOrder(order, isTransportsAmortizationBasket);
  }

  const getOrderType = () => {
    //with accoradance to enum values
    let orderType = '';

    if (storeState.length > 0) {
      orderType = 'storage';
    }

    if (rentState.length > 0) {
      if (!orderType) {
        orderType = 'rent';
      } else {
        orderType += ', rent';
      }
    }

    if (shopState.length > 0) {
      if (!orderType) {
        orderType = 'sell';
      } else {
        orderType += ', sell';
      }
    }

    return orderType;
  };

  const getPackagesQuantity = (storeItem: any, productsFromStore: any[]) => {
    let quantity = 0;
    const products: any = productsFromStore.filter(
      (product: any) =>
        product.group === ProductGroup.packageBox &&
        product.type === storeItem.type,
    );
    if (products.length > 0) {
      for (let i = 0; i < products.length; i++) {
        const parentProduct = products[i];
        const childProductQuantity = getChildProductQuantity(
          parentProduct,
          storeItem,
        );
        quantity += childProductQuantity;
      }
    }
    return quantity;
  };

  const getChildProductQuantity = (parentProduct: any, storeItem: any) => {
    for (let i = 0; i < parentProduct.packages.length; i++) {
      const childProduct = parentProduct.packages[i];
      if (childProduct.child.id === storeItem.id) {
        return childProduct.quantity * parentProduct.value;
      }
    }
    return 0;
  };

  const prepareProduct = (storeItem: any, productsFromStore: any[]) => {
    let quantitySum = 0;
    if (productsFromStore.length > 0) {
      const childPackagesQuantity = getPackagesQuantity(
        storeItem,
        productsFromStore,
      );
      quantitySum += childPackagesQuantity + storeItem.value;
    } else {
      quantitySum = storeItem.quantity;
    }

    return {
      id: storeItem.id,
      name: storeItem.name,
      quantity: quantitySum,
      quantityUnit: 'szt',
      tax: 23,
      group: storeItem.group,
      type: storeItem.type,
      isStack: storeItem.isStack,
      size:
        storeItem.group === ProductGroup.box
          ? (storeItem.name + '').split(' ').pop()
          : '',
      totalPriceGross:
        storeItem.price !== 0
          ? parseFloat(
              (Number(storeItem.price) * Number(storeItem.value)).toFixed(2),
            )
          : storeItem.group === ProductGroup.box
          ? parseFloat(
              (
                Number(storeItem.weekPrice) * Number(storeItem.value / 5)
              ).toFixed(2),
            )
          : parseFloat(
              (Number(storeItem.weekPrice) * Number(storeItem.value)).toFixed(
                2,
              ),
            ),
      weekPrice: storeItem.weekPrice,
      promotionPrice: storeItem.promotionPrice,
      price: storeItem.price,
      monthPrice: storeItem.monthPrice,
    };
  };

  const destructurePackages = (
    productsFromStore: any[],
    productsOnInvoice: any[],
    type: string,
  ) => {
    const destructuredProducts: any[] = [];
    const productsByType = productsFromStore.filter(
      (elem: any) => elem.type === type,
    );
    if (productsByType.length > 0) {
      for (let i = 0; i < productsByType.length; i++) {
        const parentProduct = productsByType[i];
        parentProduct.packages.forEach((childProduct: any) => {
          const isFound = productsOnInvoice.find(
            (elem) => elem.id === childProduct.child.id,
          );
          if (!isFound) {
            childProduct.quantity = childProduct.quantity * parentProduct.value;
            destructuredProducts.push(childProduct);
          }
        });
      }
      let change = true;
      while (change) {
        for (let i = 0; i < destructuredProducts.length; i++) {
          const product = destructuredProducts[i];
          const itemQuantity = getProductQuantity(
            product.child.id,
            i,
            destructuredProducts,
          );
          if (destructuredProducts[i]) {
            destructuredProducts[i].quantity += itemQuantity;
            product.child.quantity = destructuredProducts[i].quantity;
          }
          change = itemQuantity > 0;
          if (change) break;
        }
      }
    }

    return destructuredProducts;
  };

  const getProductQuantity = (
    productId: any,
    index: number,
    destructuredProducts: any[],
  ) => {
    let quantity = 0;
    for (let i = 0; i < destructuredProducts.length; i++) {
      if (i === index) continue;
      if (destructuredProducts[i].child.id === productId) {
        quantity += +destructuredProducts[i].quantity;
        destructuredProducts.splice(i, 1);
        break;
      }
    }
    return quantity;
  };

  function prepareOrderForInvoice() {
    const productsFromStoreOrg = [...rentState, ...storeState, ...shopState];
    const productsOnInvoice: Record<string, string | number>[] = [];
    const productsFromStore = JSON.parse(JSON.stringify(productsFromStoreOrg));
    productsFromStore.forEach(function (storeItem: any) {
      if (storeItem.group !== ProductGroup.packageBox) {
        const productOnInvoice: any = prepareProduct(
          storeItem,
          productsFromStore,
        );
        productsOnInvoice.push(productOnInvoice);
      }
    });
    const productsFromStoreFitlered = productsFromStore.filter(
      (product: any) => product.group === ProductGroup.packageBox,
    );
    const types = [ProductType.rent, ProductType.storage, ProductType.store];
    types.forEach((type: string) => {
      const destructuredProducts = destructurePackages(
        productsFromStoreFitlered,
        productsOnInvoice,
        type,
      );
      if (destructuredProducts.length > 0) {
        destructuredProducts.forEach((product: any) => {
          const storeItem: any = prepareProduct(product.child, []);
          productsOnInvoice.push(storeItem);
        });
      }
    });
    return productsOnInvoice;
  }

  const [usetSettings, setUserSettings] = useState<boolean>(false);
  getUserSettingsAction(userData.user.id).then(({ data }) => {
    setUserSettings(data.isPermissionForPayingInTheFutureGranted);
  });

  const placeOrder = (
    orderData: any,
    isTransportsAmortizationBasket: boolean,
  ) => {
    verifyOrderClose({ holidays, defaultOrderClosingTime, dates })
      ? setShowModal(true)
      : dispatch(
          orderAction(
            orderData,
            isTransportsAmortizationBasket,
            (
              transactionToken,
              cardRedirect: boolean,
              order: any,
              paymentId: number,
            ) => {
              visibleSpinner(true);
              if (cardRedirect) {
                const redirectToCard = window.open(transactionToken, '_self');
                const callback = () => redirectToCard;
                paymentEvent(order, paymentId, callback);
              } else {
                const redirectToPayURL = window.open(
                  `${P24_URL}/trnRequest/${transactionToken}`,
                  '_self',
                );
                const callback = () => redirectToPayURL;
                paymentEvent(order, paymentId, callback);
              }
              setErrorMessage('noError');
              visibleSpinner(false);
            },
            (errorMessage) => {
              setErrorMessage(errorMessage);
              showError(true);
              visibleSpinner(false);
            },
          ),
        ) &&
        dispatch(
          changeUserPaymentMethod(
            userData.user.id,
            currentOrder.paymentType,
            usetSettings,
            () => showError(true),
          ),
        );
  };

  useEffect(() => {
    if (errorMessage === 'noError') {
      const result = Object.assign({}, currentOrder, formik.values);
      onSubmit(result);
    }
  }, [errorMessage]);

  useEffect(() => {
    dispatch(getTransportAction());
  }, []);

  const isSell = storeState.length === 0 && rentState.length === 0;
  const isTransportsAmortizationBasket = location.pathname === '/return-boxes';

  const formik = useFormik({
    initialValues: {
      summaryPrivacyPolicy: false,
      summaryRodo: isSell || isTransportsAmortizationBasket ? true : false,
    },
    validationSchema: Yup.object({
      summaryPrivacyPolicy: Yup.boolean()
        .oneOf([true], translate('errors.acceptRules'))
        .required(translate('errors.fieldRequired')),
      summaryRodo: Yup.boolean()
        .oneOf([true], translate('errors.acceptRules'))
        .required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values) => {
      if (isTransportsAmortizationBasket) {
        payAndUpdateStocks(isTransportsAmortizationBasket);
      } else {
        confirmPaymentData(isTransportsAmortizationBasket);
      }
    },
  });

  const amortizationTransportPrice = stocks?.amortizationTransportPrice;
  const additionalTransportPrice =
    stocks.selectedBox?.length * storageTransportPrice;

  const summaryTransportPrice = stocks.selectedAllBox
    ? amortizationTransportPrice
    : additionalTransportPrice;

  const payAndUpdateStocks = (isTransportsAmortizationBasket: boolean) => {
    const editedBoxes:
      | UpdateStockDto[]
      | {
          id: number;
          returnDate?: Date;
        }[] = [];
    stocks.selectedBox.map((item: FindStockDto) => {
      editedBoxes.push({
        id: item.id,
        deliveryDate: stocks.date,
        deliveryAddress: stocks.deliveryAddress,
        returnAddress: stocks.returnAddress,
      });
    });
    visibleSpinner(true);
    payment(
      {
        amount: summaryTransportPrice,
        orderId: stocks.orderId,
        paymentType: currentOrder.paymentType,
        email: userData.user.email,
        stockInfo: {
          date: stocks.date,
          newStatus: StockStatus.STOCK_RFD,
          prevStatus: stocks.selectedBox[0].stockStatus,
          stocks: editedBoxes,
        },
      },
      isTransportsAmortizationBasket,
    )
      .then(() => {
        dispatch(
          changeUserPaymentMethod(
            userData.user.id,
            currentOrder.paymentType,
            usetSettings,
            () => showError(true),
          ),
        );
        updateSomeStocksAction(editedBoxes)
          .then(() => {
            onSubmit();
          })
          .catch(() => showError(true));
      })
      .catch(() => showError(true))
      .finally(() => visibleSpinner(false));
  };
  const verifyCurrentOrder = () => {
    if (!isSell) {
      if (!currentOrder.returnedAddress) {
        return true;
      }
    }
    if (!currentOrder.deliveryAddress) {
      return true;
    }
    if (!currentOrder.billing) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <MessageOrderClosingTime setShowModal={setShowModal} />
      </Modal>
      <form
        className="summary-form position-relative text-center"
        onSubmit={formik.handleSubmit}
      >
        <H3 className="summary-form_title">{translate('summaryStep.toPay')}</H3>
        {isTransportsAmortizationBasket ? (
          <H3 className="summary-form_price">
            {summaryTransportPrice} {translate('default.currency')}
          </H3>
        ) : (
          parsedSummaryPrice !== 0 && (
            <H3 className="summary-form_price">
              {parsedSummaryPrice} {translate('default.currency')}
            </H3>
          )
        )}

        <P className="summary-form_vat font-weight-bold">
          {!!data.vat && (
            <span>
              {translate('summaryStep.payNow') +
                data.vat +
                translate('summaryStep.vat')}
            </span>
          )}
        </P>
        {isTransportsAmortizationBasket
          ? null
          : parsedStorePrice !== 0 &&
            parsedStorePriceByMonth !== 0 && (
              <P className="summary-form_store">
                {translate('summaryStep.amountIncludes')}
                <span className="form-data">
                  {translate('summaryStep.amountIncludesOne')}
                </span>
                {translate('summaryStep.amountIncludesOf')}
                <span className="form-data">{storagePeriod}</span>
                &#32;
                {translate('summaryStep.subscriptionFees')}
                <span className="form-data">
                  {parsedStorePriceByMonth.toFixed(2)}
                  {translate('summaryStep.currency')}
                </span>
                . &#32;
                {translate('summaryStep.storageCost')}
                <span className="form-data">
                  {parsedStorePrice.toFixed(2)}
                  {translate('summaryStep.currency')}
                </span>
                .
              </P>
            )}

        {!isSell && !isTransportsAmortizationBasket && <div className="line" />}

        <ul className="list-unstyled mb-0">
          <li className="form-check d-flex flex-column pl-0">
            <input
              className="form-check-input custom-checkbox custom-checkbox--top"
              type="checkbox"
              id="summaryPrivacyPolicy"
              name="summaryPrivacyPolicy"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.summaryPrivacyPolicy}
            />
            <label htmlFor="summaryPrivacyPolicy" className="accept-label">
              <div className="accept-label_group">
                <span className="form-data">*</span>
                <span>{translate('summaryStep.acceptPolicy1')}</span>
                <Link to="/regulamin" className="privacy-link" target="_blank">
                  {translate('summaryStep.acceptProvisions')}
                </Link>
                <span>
                  {textFormater(translate('summaryStep.acceptPolicy2'))}
                </span>
              </div>
            </label>
          </li>
          {formik.touched.summaryPrivacyPolicy &&
          formik.errors.summaryPrivacyPolicy ? (
            <div className="error-feedback pb-2">
              {formik.errors.summaryPrivacyPolicy}
            </div>
          ) : null}
          {!isSell && !isTransportsAmortizationBasket && (
            <li className="form-check d-flex flex-column pt-2 pl-0">
              <input
                className="form-check-input custom-checkbox custom-checkbox--top"
                type="checkbox"
                id="summaryRodo"
                name="summaryRodo"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.summaryRodo}
              />
              <label htmlFor="summaryRodo" className="accept-label">
                <div className="accept-label_group">
                  <span className="form-data">*</span>
                  <span>
                    {textFormater(translate('summaryStep.takeSomething'))}
                  </span>
                </div>
              </label>
            </li>
          )}
          {formik.touched.summaryRodo && formik.errors.summaryRodo ? (
            <div className="error-feedback pb-2">
              {formik.errors.summaryRodo}
            </div>
          ) : null}
        </ul>
        {isTransportsAmortizationBasket ? (
          <button
            type="submit"
            className="summary-form_button btn btn-large btn-100"
            disabled={
              !(
                formik.isValid &&
                (formik.dirty ||
                  (!!formik.values.summaryRodo &&
                    !!formik.values.summaryPrivacyPolicy))
              ) ||
              stocks.selectedBox?.length === 0 ||
              verifyCurrentOrder()
            }
          >
            {translate('summaryStep.confirmPay')}
          </button>
        ) : (
          <button
            type="submit"
            className="summary-form_button btn btn-large btn-100"
            disabled={
              !(
                formik.isValid &&
                (formik.dirty ||
                  (!!formik.values.summaryRodo &&
                    !!formik.values.summaryPrivacyPolicy))
              ) ||
              parsedSummaryPrice === 0 ||
              verifyCurrentOrder()
            }
          >
            {translate('summaryStep.confirmPay')}
          </button>
        )}

        {verifyCurrentOrder() && (
          <div className="error-feedback pb-2">
            {translate('errors.emptyOrderData')}
          </div>
        )}

        {errorMessage && (
          <div className="error-feedback pb-2">
            {translate(`errors.${errorMessage}`)}
          </div>
        )}
      </form>
    </>
  );
};

export default SummaryForm;
