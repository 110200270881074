import { useState, useEffect } from 'react';
import P from '@component/Text/Text';
import { MultiSelect } from 'primereact/multiselect';
// import './UniversalListyStyle.scss';
interface singleSelectInterface {
  label: string;
  value: string;
}

export default function UniversalMultiDropdown(props: {
  renderList: object;
  onChange: any;
  default?: string;
  label: string;
}) {
  const [phaseOption, setPhaseOption] = useState<any[]>([]);
  const [itemList, setItemList] = useState<singleSelectInterface[]>([]);
  useEffect(() => {
    const renderItems = getRolesOptions(props.renderList);
    setPhaseOption([renderItems[0].value]);
    setItemList(renderItems);
  }, []);

  const getRolesOptions = (renderItems: object): singleSelectInterface[] => {
    return Object.values(renderItems).map((value: string) => ({
      label: value,
      value,
    }));
  };

  return (
    <div className="universalList-container">
      <P className="universalList-label">{props.label}</P>
      <MultiSelect
        display="chip"
        value={phaseOption}
        className="multiSelect"
        options={itemList}
        onChange={(e) => {
          setPhaseOption(e.target.value);
          props.onChange(e.target.value);
        }}
      />
    </div>
  );
}
