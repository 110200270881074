export enum DefaultSettingsTypeEnum {
  defaultOrderClosingTime = "defaultOrderClosingTime",
  defaultCountForStorage = "defaultCountForStorage",
  defaultCountForRent = "defaultCountForRent",
  defaultCountForShop = "defaultCountForShop",
  defaultCountForPackage = "defaultCountForPackage",
  defaultCarouselBoxByCapacity = "defaultCarouselBoxByCapacity",
  defaultPaymentTerminationDays = "defaultPaymentTerminationDays",
  defaultPaymentDemandTerminationDays = "defaultPaymentDemandTerminationDays",
  defaultOrderTerminationStorage = "defaultOrderTerminationStorage",
  defaultOrderTerminationRent = "defaultOrderTerminationRent",

  defaultMaxNumberOfDaysToStartTheRentService = "defaultMaxNumberOfDaysToStartTheRentService",
  defaultMinNumberOfDaysOfDuragionOfTheRentService = "defaultMinNumberOfDaysOfDuragionOfTheRentService",
  defaultMaxNumberOfDaysOfDuragionOfTheRentService = "defaultMaxNumberOfDaysOfDuragionOfTheRentService",

  defaultMaxNumberOfDaysToStartTheStorageService = "defaultMaxNumberOfDaysToStartTheStorageService",
  defaultMinNumberOfDaysOfDuragionOfTheStorageService = "defaultMinNumberOfDaysOfDuragionOfTheStorageService",
  defaultMaxNumberOfDaysOfDuragionOfTheStorageService = "defaultMaxNumberOfDaysOfDuragionOfTheStorageService",

  defaultMinValueForWarehouseStock = "defaultMinValueForWarehouseStock",
}

export const UndefinedSettingsType = {
  undefinedOrderClosingTime: 12,
  undefinedCountForStorage: 1,
  undefinedCountForRent: 5,
  undefinedCountForShop: 1,
  undefinedCountForPackage: 1,
  defaultPaymentTerminationDays: 2,
  defaultPaymentDemandTerminationDays: 1,
  defaultOrderTerminationStorage: 14,
  defaultOrderTerminationRent: 3,

  undefinedMaxNumberOfDaysToStartTheRentalService: 7,
  undefinedMinNumberOfDaysOfDuragionOfTheRentService: 5,
  undefinedMaxNumberOfDaysOfDuragionOfTheRentService: 56,
  undefinedMaxNumberOfDaysToStartTheStorageService: 7,
  undefinedMinNumberOfDaysOfDuragionOfTheStorageService: 21,
  undefinedMaxNumberOfDaysOfDuragionOfTheStorageService: 360,
  undefinedMinValueForWarehouseStock: 3,
};
