import { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import P from '@component/Text/Text';
import './UniversalListyStyle.scss';
interface singleSelectInterface {
  label: string;
  value: string;
}

export default function UniversalList(props: {
  renderList: object;
  onChange: any;
  default: string;
  label: string;
}) {
  const [phaseOption, setPhaseOption] = useState<string>(props.default);
  const [itemList, setItemList] = useState<singleSelectInterface[]>([]);
  useEffect(() => {
    const renderItems = getRolesOptions(props.renderList);
    setPhaseOption(renderItems[0].value);
    setItemList(renderItems);
  }, []);

  const getRolesOptions = (renderItems: object): singleSelectInterface[] => {
    return Object.values(renderItems).map((value: string) => ({
      label: value,
      value,
    }));
  };

  return (
    <div className="universalList-container">
      <P className="universalList-label">{props.label}</P>
      <Dropdown
        value={phaseOption}
        options={itemList}
        className="universalList-select"
        name="status"
        optionLabel="label"
        optionValue="value"
        onChange={(e: any) => {
          setPhaseOption(e.target.value);
          props.onChange(e.target.value);
        }}
        itemTemplate={(option) => {
          return <span>{option.label}</span>;
        }}
      />
    </div>
  );
}
