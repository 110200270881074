interface PropsTransportStorePrice {
  singleTransportPrice: number;
  stackTransportPrice: number;
  quantityStackedBoxForStore: number;
  quantityNotStackedBoxForStore: number;
  numberOfBoxesInOne?: number;
}

export const calculateTransportStorePrice = ({
  singleTransportPrice,
  stackTransportPrice,
  quantityStackedBoxForStore,
  quantityNotStackedBoxForStore,
  numberOfBoxesInOne = 5,
}: PropsTransportStorePrice): number => {
  const calculate =
    Math.ceil(quantityStackedBoxForStore / numberOfBoxesInOne) *
      (quantityStackedBoxForStore === 1
        ? singleTransportPrice
        : stackTransportPrice) +
    quantityStackedBoxForStore * singleTransportPrice +
    quantityStackedBoxForStore * singleTransportPrice +
    Math.ceil(quantityStackedBoxForStore / numberOfBoxesInOne) *
      (quantityStackedBoxForStore === 1
        ? singleTransportPrice
        : stackTransportPrice) +
    Math.ceil(quantityNotStackedBoxForStore / numberOfBoxesInOne) *
      (quantityNotStackedBoxForStore === 1
        ? singleTransportPrice
        : stackTransportPrice) +
    quantityNotStackedBoxForStore * singleTransportPrice +
    quantityNotStackedBoxForStore * singleTransportPrice +
    Math.ceil(quantityNotStackedBoxForStore / numberOfBoxesInOne) *
      (quantityNotStackedBoxForStore === 1
        ? singleTransportPrice
        : stackTransportPrice);

  return calculate;
};

interface PropsAmortizationTransportPrice {
  transportStorePrice: number;
  period: number;
  unpaidPeriods: number;
}

export const calculateAmortizationTransportPrice = ({
  transportStorePrice,
  period,
  unpaidPeriods,
}: PropsAmortizationTransportPrice): number =>
  Math.ceil((transportStorePrice / period) * unpaidPeriods * 100) / 100;
