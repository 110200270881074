import React, { useState } from 'react';
import Background from '@images/customer/landing/skitraj-zdj-sklep-baner.png';
import './LookingMore.scss';
import { translate } from '@module/Translate/Translate';
import { H2 } from '@component/Text/Text';
import Modal from '@component/Modal/Modal';
import ContactWithoutLoginForm from '@form/ContactWithoutLoginForm/ContactWithoutLoginForm';
import CompletedSentForm from '@form/CompletedSentForm/CompletedSentForm';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import Spinner from '@component/Spinner/Spinner';
const LookingMoreBlock = () => {
  const [contactModal, setContactModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [visibleSpinner, setVisibleSpinner] = useState(false);

  return (
    <>
      {visibleSpinner && <Spinner />}
      <Modal
        showModal={contactModal}
        setShowModal={() => {
          setContactModal(!contactModal);
        }}
      >
        <ContactWithoutLoginForm
          setShowModal={() => {
            setContactModal(!contactModal);
          }}
          setShowConfirm={() => {
            setShowConfirmation(true);
          }}
          setSpinner={(value: boolean) => {
            setVisibleSpinner(value);
          }}
          showConfirm={() => {
            setShowError(true);
          }}
        />
      </Modal>
      <Modal
        className="dialog--large"
        showModal={showConfirmation}
        setShowModal={() => setShowConfirmation(false)}
      >
        <CompletedSentForm setShowConfirm={() => setShowConfirmation(false)} />
      </Modal>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <article className="looking-more-section">
        <div className="looking-more-section_container">
          <img
            src={Background}
            className="img-fluid"
            alt={translate('homePage.lookingMoreBlock.provider')}
          />
          <section className="looking-more-wrapper">
            <div className="flex justify-content-center flex-column">
              <H2 className="looking-more-section_subtitle">
                homePage.lookingMoreBlock.notFind
              </H2>
              <div className="">
                <div className="looking-more-section_navigation">
                  <button
                    onClick={() => {
                      setContactModal(true);
                    }}
                    className="looking-more-section_btn btn btn-large btn-80"
                  >
                    {translate('homePage.lookingMoreBlock.goStore')}
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </>
  );
};
export default LookingMoreBlock;
