import P, { H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { getUserSettingsAction } from '@network/Actions/CurrentUser';
import { changeUserPaymentMethod } from '@network/Actions/Payment';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import PaymentCard from '@view/RegistrationStepper/components/PaymentStep/components/PaymentCard/PaymentCard';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { paymentData } from './payment-data';

interface IProps {
  setShowModal: (value: boolean) => void;
  showError: (value: boolean) => void;
  setSpinner: (value: boolean) => void;
}

export function ChangePaymentMethodForm({
  setShowModal,
  showError,
  setSpinner,
}: IProps) {
  const dispatch = useDispatch();

  const { user } = useSelector<RootState, UserState>((state) => state.user);

  const [isSubmitButtonDisable, setIsSubmitButtonDisable] =
    useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [
    isPermissionForPayingInTheFutureGranted,
    setIsPermissionForPayingInTheFutureGranted,
  ] = useState<boolean>(false);

  useEffect(() => {
    setSpinner(true);
    getUserSettingsAction(user.id)
      .then(({ data }) => {
        setActivePaymentType(
          data.paymentMethod ? data.paymentMethod : PaymentMethodEnum.P24,
        );
        setIsChecked(data.isPermissionForPayingInTheFutureGranted);
      })
      .catch(() => setActivePaymentType(PaymentMethodEnum.P24))
      .finally(() => setSpinner(false));
  }, []);

  const [activePaymentType, setActivePaymentType] = useState(
    PaymentMethodEnum.P24,
  );
  const onSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(
      changeUserPaymentMethod(
        user.id,
        activePaymentType,
        isPermissionForPayingInTheFutureGranted,
        () => showError(true),
      ),
    );
    setShowModal(false);
  };

  return (
    <form className="payment-form" onSubmit={onSubmit}>
      <div className="payment-modal_header">
        <H3 className="payment-modal_header_title">
          dashboard.paymentMethods.paymentMethod
        </H3>
        <P className="payment-modal_header_text">
          dashboard.paymentMethods.modalPaymentMethod
        </P>
      </div>
      <div className="payment-modal_radio-wrapper">
        <div className="payment-modal_radio-wrapper_radio-item">
          <div className="radioHeader d-flex flex-wrap w-100">
            {paymentData.paymentMethod
              .filter((item: any) => {
                if (item.id !== PaymentMethodEnum.CARD) {
                  return item;
                } else {
                  if (process.env.CARD_PAYMENT === 'true') {
                    return item;
                  }
                }
                return null;
              })
              .map((item) => {
                return (
                  <PaymentCard
                    key={item.id}
                    index={item.id}
                    item={item}
                    active={item.id === activePaymentType}
                    onSetActive={() => setActivePaymentType(item.id)}
                    setIsSubmitButtonDisable={(value: boolean) => {
                      setIsSubmitButtonDisable(value);
                    }}
                    isChecked={isChecked}
                    setIsChecked={(value: boolean) => {
                      setIsChecked(value);
                    }}
                    setIsPermissionForPayingInTheFutureGranted={(
                      value: boolean,
                    ) => setIsPermissionForPayingInTheFutureGranted(value)}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <button
        disabled={isSubmitButtonDisable}
        type="submit"
        className="payment-form_button btn btn-large btn-100"
      >
        {translate('dashboard.paymentMethods.saveChanges')}
      </button>
    </form>
  );
}

export default ChangePaymentMethodForm;
