import React from 'react';
import { FindStockDto } from '../../../../../../../../types/stocks/find-stock.dto';
import { StockStatus } from '../../../../../../../../types/stocks/stock-status.enum';
import { StockType } from '../../../../../../../../types/stocks/stock-type.enum';
import { translate } from '../../../../../../Module/Translate/Translate';
import { dateGetTime } from '../../../../../../Util/DateGetTime';
import unifiedFormatDate from '../../../../../../Util/Dates';
import { HeaderStorage } from './HeaderStorage';
import './HidingPlaceElementHeader.scss';

interface Props {
  stocks: FindStockDto[];
  headerpayload: any;
  selectedBox: any;
  selectedAllBoxLength: boolean;
  amortizationTransportPrice: number;
}

const selectDescriptionForStocks = (stocks: FindStockDto[]): FindStockDto => {
  const filteredStocks = stocks.filter((el: FindStockDto) => {
    if (el.stockType === StockType.STORAGE) {
      return el;
    }
  });
  return filteredStocks[0];
};

const HidingPlaceElementHeader: React.FC<React.PropsWithChildren<Props>> = ({
  stocks,
  headerpayload,
  selectedBox,
  selectedAllBoxLength,
  amortizationTransportPrice,
}) => {
  const stock = selectDescriptionForStocks(stocks);
  switch (stock.stockStatus) {
    case StockStatus.STOCK_RFS:
      return (
        <HeaderStorage
          title={StockStatus.STOCK_RFS}
          firstBtnHandleClick={() =>
            headerpayload.setShowModal(!headerpayload.showModal)
          }
          firstBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderDetail',
          )}
          date={unifiedFormatDate(new Date(stock.startDate))}
          darkFirstButton={true}
        />
      );

    case StockStatus.STOCK_STTC:
      return (
        <HeaderStorage
          title={StockStatus.STOCK_STTC}
          firstBtnHandleClick={() =>
            headerpayload.setShowModal(!headerpayload.showModal)
          }
          firstBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderDetail',
          )}
          darkFirstButton={true}
        />
      );

    case StockStatus.STOCK_ATC:
      return (
        <HeaderStorage
          title={StockStatus.STOCK_ATC}
          firstBtnHandleClick={() => headerpayload.setShowReturnModal(true)}
          firstBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderCourier',
          )}
          firstBtnDisabled={
            stocks.filter((item) => !item.description).length > 0
          }
          secondBtnHandleClick={() =>
            headerpayload.setShowModal(!headerpayload.showModal)
          }
          secondBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderDetail',
          )}
          secondButton={true}
        />
      );
    case StockStatus.STOCK_RFRF:
      return (
        <HeaderStorage
          title={StockStatus.STOCK_RFRF}
          firstBtnHandleClick={() =>
            headerpayload.setShowModal(!headerpayload.showModal)
          }
          firstBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderDetail',
          )}
          date={unifiedFormatDate(new Date(stock.returnDate))}
          darkFirstButton={true}
        />
      );

    case StockStatus.STOCK_IW:
      return (
        <HeaderStorage
          title={StockStatus.STOCK_IW}
          firstBtnHandleClick={() => {
            //TODO odebranie pudełek przed upływem umowy, do ustalenia data
            if (selectedAllBoxLength && amortizationTransportPrice === 0) {
              (dateGetTime(new Date()) <
                dateGetTime(new Date(stocks[0].endDate)) ||
                stocks[0].continuationDate != null) &&
                headerpayload.setShowReceiveModal(true);
            } else
              dateGetTime(new Date()) <
                dateGetTime(new Date(stocks[0].endDate)) ||
              stocks[0].continuationDate === null
                ? headerpayload.setPickupBoxesBefore(true)
                : headerpayload.setShowReceiveModal(true);
          }}
          firstBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.receiveBoxes',
          )}
          firstBtnDisabled={!selectedBox.length}
          secondBtnHandleClick={() =>
            headerpayload.setShowModal(!headerpayload.showModal)
          }
          secondBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderDetail',
          )}
          secondButton={true}
        />
      );
    case StockStatus.STOCK_RFD:
      return (
        <HeaderStorage
          title={StockStatus.STOCK_RFD}
          firstBtnHandleClick={() =>
            headerpayload.setShowModal(!headerpayload.showModal)
          }
          firstBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderDetail',
          )}
          date={unifiedFormatDate(new Date(stock.deliveryDate))}
          darkFirstButton={true}
        />
      );
    case StockStatus.STOCK_ATCF:
      return (
        <HeaderStorage
          title={StockStatus.STOCK_ATCF}
          firstBtnHandleClick={() => headerpayload.setShowReturnModal(true)}
          firstBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.returnBoxes',
          )}
          firstBtnDisabled={
            stocks.filter((item) => !item.description).length > 0
          }
          secondBtnHandleClick={() =>
            headerpayload.setShowModal(!headerpayload.showModal)
          }
          secondBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderDetail',
          )}
          secondButton={true}
        />
      );
    case StockStatus.STOCK_RFRE:
      return (
        <HeaderStorage
          title={StockStatus.STOCK_RFRE}
          firstBtnHandleClick={() =>
            headerpayload.setShowModal(!headerpayload.showModal)
          }
          firstBtnText={translate(
            'hidingPlaceBlock.hidingPlaceElement.orderDetail',
          )}
          date={unifiedFormatDate(new Date(stock.returnDate))}
          darkFirstButton={true}
        />
      );
    default:
      return <></>;
  }
};

export default HidingPlaceElementHeader;
