import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.scss';
import Spinner from './Component/Spinner/Spinner';
import {
  addDateInBasket,
  addDaysInBasket,
  addPeriodInBasket,
  addProductInBasket,
  addStocksInBasket,
} from './Network/Actions/Basket';
import { currentUserAction } from './Network/Actions/CurrentUser';
import { getDefaultSettingsAction } from './Network/Actions/DefaultSettings';
import { RootState } from './store';
import Router from './View/Router/Router';
import Modal from './Component/Modal/Modal';
import AdminContactForm from './Form/AdminContactForm/AdminContactForm';
import { translate } from './Module/Translate/Translate';
import PushNotification from './Component/Notifications/PushNotification';
import { UserState } from '@type/Custom';
import { countUnreadNotificationsAction } from '@network/Actions/Notifications';
import { currentOrderAction } from '@network/Actions/CurrentOrder';
import {
  removePromotionCode,
  verifyPromotionCode,
} from '@network/Actions/Promotion';
import PromotionErrorModal from '@component/PromotionErrorModal/PromotionErrorModal';
import { ConsentType } from '@component/CookiesInfo/CookiesInfo';
import { ANALYTICS_TOKEN, GTM_TOKEN } from '@/envConfig';
import { setGAInitialize } from '@util/useGAEventTracker';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
declare global {
  interface Window {
    dataLayer: any;
  }
}

export const initializeAnalytics = (isGranted = true) => {
  if (isGranted) {
    const gtmToken: string | any = GTM_TOKEN;
    const analyticsToken: string | any = ANALYTICS_TOKEN;

    setGAInitialize(analyticsToken);

    ReactGA.set({
      analytics_storage: ConsentType.Granted,
      ad_storage: ConsentType.Granted,
      ad_user_data: ConsentType.Granted,
      ad_personalization: ConsentType.Granted,
    });

    TagManager.initialize({
      gtmId: gtmToken,
    });
  }
};

initializeAnalytics();

const App = () => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const state = useSelector<RootState, RootState>((state) => state);
  const [showError, setShowError] = useState(false);
  const { user } = useSelector<RootState, UserState>((state) => state.user);
  const [showPromotionError, setShowPromotionError] = useState(false);

  // const { GA } = useSelector<RootState, AnalyticsState>(
  //   (state) => state.analytics,
  // );

  const visibleSpinner = Object.values(state).find((item) => item.loading);

  const errorServer = Object.values(state.serverNetworkError).find(
    (error) => error,
  );

  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const isGoogleAnalitycsConsent = isConsentGranted(
  //     ConsentThirdParty.GoogleAnalitycs,
  //   );

  //   if (isGoogleAnalitycsConsent) {
  //     initializeAnalytics();
  //   }
  // }, [GA]);

  useEffect(() => {
    errorServer ? setShowError(true) : setShowError(false);
  }, [errorServer]);

  useEffect(() => {
    try {
      dispatch(currentUserAction());
      dispatch(getDefaultSettingsAction());
    } catch {
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user.id) {
      dispatch(countUnreadNotificationsAction(user.id));
    }
  }, [user.id]);

  function setLocalhostToState() {
    const storageProduct = localStorage.getItem('products');
    if (storageProduct) {
      const parsedProduct = JSON.parse(storageProduct);
      if (parsedProduct) {
        for (const product of parsedProduct) {
          dispatch(addProductInBasket({ data: product, isLocalStorage: true }));
        }
      }
    }

    const storageDates = localStorage.getItem('dates');
    if (storageDates) {
      const parsedDates = JSON.parse(storageDates);
      if (parsedDates) {
        for (const date of parsedDates) {
          date.date = new Date(date.date);
          dispatch(addDateInBasket(date));
        }
      }
    }

    const storagePeriods = localStorage.getItem('periods');
    if (storagePeriods) {
      const parsedPeriods = JSON.parse(storagePeriods);
      if (parsedPeriods) {
        for (const period of parsedPeriods) {
          dispatch(addPeriodInBasket(period));
        }
      }
    }

    const storageDays = localStorage.getItem('days');
    if (storageDays) {
      const parsedDays = JSON.parse(storageDays);
      if (parsedDays) {
        for (const period of parsedDays) {
          dispatch(addDaysInBasket(period));
        }
      }
    }

    const storageStocks = localStorage.getItem('stocks');
    if (storageStocks) {
      const parsedStocks = JSON.parse(storageStocks);
      if (parsedStocks) {
        dispatch(addStocksInBasket(parsedStocks));
      }
    }

    const currentOrder = localStorage.getItem('currentOrder');
    if (currentOrder) {
      const parsedCurrentOrder = JSON.parse(currentOrder);
      if (parsedCurrentOrder) {
        dispatch(currentOrderAction(parsedCurrentOrder));
      }
    }

    const promotion = localStorage.getItem('promotion');
    if (promotion) {
      const parsedPromotion = JSON.parse(promotion);
      if (parsedPromotion) {
        dispatch(
          verifyPromotionCode(parsedPromotion.code, () => {
            setShowPromotionError(true);
            dispatch(removePromotionCode());
          }),
        );
      }
    }

    isFirstRender.current = false;
  }
  if (isFirstRender.current) {
    setLocalhostToState();
  }
  return (
    <>
      {/* <MetaPixel /> */}
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.serverError')}
          activeContactLink={false}
        />
      </Modal>
      <Modal
        showModal={showPromotionError}
        setShowModal={setShowPromotionError}
      >
        <PromotionErrorModal
          setShowErrorModal={() => {
            setShowPromotionError(false);
          }}
        />
      </Modal>

      {<PushNotification />}
      {visibleSpinner && <Spinner />}
      {loading ? <Spinner /> : <Router />}
    </>
  );
};
export default App;
