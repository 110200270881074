import React from 'react';
import './PaymentCard.scss';
import P24 from '@images/registration/P24.png';
import Blik from '@images/registration/Blik.png';
import Card from '@images/card/card.png';
import P from '@component/Text/Text';
import { User } from '@models/user.model';
import { translate } from '@module/Translate/Translate';
import { InvokeTransactionDTO } from '@rootTypes/payments/dto/invoke-transaction.dto';
import { UnpaidOrderDTO } from '@rootTypes/payments/dto/unpaid-orders.dto';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';

interface Props {
  unpaidOrder: UnpaidOrderDTO;
  makePayment: (invoikeTransactionDTO: InvokeTransactionDTO) => void;
  user: User;
}

const PaymentCard: React.FC<React.PropsWithChildren<Props>> = ({
  unpaidOrder,
  makePayment,
  user,
}) => {
  const paymentIcon = (method: PaymentMethodEnum) => {
    let currentPaymentType;

    switch (method) {
      case PaymentMethodEnum.P24:
        currentPaymentType = P24;
        break;
      case PaymentMethodEnum.BLIK:
        currentPaymentType = Blik;
        break;
      case PaymentMethodEnum.CARD:
        currentPaymentType = Card;
        break;
      default:
        currentPaymentType = P24;
        break;
    }

    return currentPaymentType;
  };

  const onClick = () => {
    const invoikeTransactionDTO: InvokeTransactionDTO = {
      paymentId: unpaidOrder.paymentId,
      email: user.email,
      method: unpaidOrder.paymentMethod,
      description: '',
    };

    makePayment(invoikeTransactionDTO);
  };

  return (
    <div className="col col-12 col-md-6 col-xl-4">
      <div className="paymentcard-wrapper">
        <div className="paymentcard-wrapper_detailItem">
          <P className="paymentcard-wrapper_price">
            {unpaidOrder.amount} {translate('default.currency')}
          </P>
        </div>
        <div className="paymentcard-wrapper_detailItem">
          <P className="paymentcard-wrapper_date">
            dashboard.paymentMethods.feeTo
          </P>
          <p className="paymentcard-wrapper_date_span">
            {unpaidOrder.feeEndDate}
          </p>
        </div>
        <div className="paymentcard-wrapper_detailItem">
          <P className="paymentcard-wrapper_orderId">
            dashboard.paymentMethods.orderTo
          </P>
          <p className="paymentcard-wrapper_orderId">
            nr {unpaidOrder.orderId}
          </p>
        </div>
        <div className="paymentcard-wrapper_detailItem">
          <P className="paymentcard-wrapper_paymentMethod">
            dashboard.paymentMethods.paymentMethod
          </P>
          <img
            className="itemBox_downloadPdfImg"
            src={paymentIcon(unpaidOrder.paymentMethod)}
            alt="itemBox"
          />
        </div>
        <button
          className="paymentcard-wrapper_button btn"
          onClick={() => onClick()}
        >
          {translate('dashboard.paymentMethods.submitButton')}
        </button>
      </div>
    </div>
  );
};

export default PaymentCard;
