import React from 'react';
import Logo from '@images/logo.png';
import Morcik_sad from '@images/morcik_sad.png';
import { Link } from 'react-router-dom';
import './PageUnavailable.scss';
import Social from '@component/Social/Social';
import P, { H1 } from '@component/Text/Text';

const PageUnavailable = () => {
  return (
    <>
      <div className="page-unavailable-section">
        <div className="page-unavailable_container container content padding20">
          <div className="row">
            <div className="col text">
              <div className="row">
                <Link className="m-auto" to={'/'}>
                  <img
                    src={Logo}
                    alt="Skitraj"
                    className="page-unavailable_logo logo"
                  />
                </Link>
              </div>
              <div className="row">
                <img
                  src={Morcik_sad}
                  alt="Skitraj"
                  className="page-unavailable_img"
                />
              </div>

              <H1 className="page-unavailable_title">pageUnavailable.title</H1>
              <P className="page-unavailable_subtitle">
                pageUnavailable.content.info
                <br />
                pageUnavailable.content.promise
              </P>

              <Social />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageUnavailable;
