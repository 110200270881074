import React from 'react';
import Background from '@images/offer/rent.png';
import './PrimaryBlockMove.scss';
import { P, H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

const PrimaryBlockRent = () => (
  <>
    <article
      className="primary-move primary-rent-color"
      id={translate('offer.primaryBlockRent.title')}
    >
      <div className="primary-move_container container">
        <section className="move-section reverse">
          <div
            className="primary-move_img primary-rent-color"
            style={{ backgroundImage: `url(${Background})` }}
          ></div>
          <div className="primary-move_text">
            <H3 className="primary-move_title">
              offer.primaryBlockRent.subtitle
            </H3>
            <P className="primary-move_subtitle">offer.primaryBlockRent.text</P>
          </div>
        </section>
      </div>
    </article>
  </>
);

export default PrimaryBlockRent;
