import React from 'react';
import { Link } from 'react-router-dom';
import { P } from '../../../../../../Component/Text/Text';
import { translate } from '../../../../../../Module/Translate/Translate';
import './SummaryOrder.scss';

interface IProps {
  data: any[];
  link: string;
  orderState?: number;
  date?: string;
  title: string;
}

const SummaryOrder = ({ data, link, orderState, date, title }: IProps) => {
  return data.length !== 0 ? (
    <>
      <div className="summary-order">
        <P className="summary-order_title text-sm-left">{title}</P>

        {data.map((item: any, index: any) => (
          <div
            key={index}
            className="summary-order_row text-sm-left text-warning font-weight-bold"
          >
            {item.value} x {item.name}
          </div>
        ))}
        {date && <div className="summary-order_row text-sm-left">{date}</div>}

        <div className="summary-order_row text-sm-left mt-3">
          <Link
            to={{
              pathname: link,
              state: {
                defaultIndex: orderState,
              },
            }}
          >
            {translate('summaryOrder.edit')}
          </Link>
        </div>
      </div>
    </>
  ) : null;
};

export default SummaryOrder;
