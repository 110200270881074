import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../store';
import {
  VERIFY_PROMOTION_PENDING,
  VERIFY_PROMOTION__FULFILLED,
  VERIFY_PROMOTION__REJECTED,
} from './Types';
import { http } from '../NetworkHTTP';
import { UpdatePromotionDto } from '@rootTypes/promotions/update-promotion.dto';
import { CreatePromotionDto } from '@rootTypes/promotions/create-promotion.dto';

export const verifyPromotionCode = (
  code: string,
  error: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: VERIFY_PROMOTION_PENDING,
      });
      const { data } = await http.get(`promotions/by-code/${code}`);
      dispatch({
        type: VERIFY_PROMOTION__FULFILLED,
        payload: data,
      });
    } catch (err) {
      error();
      dispatch({
        type: VERIFY_PROMOTION__REJECTED,
        payload: 'Nie udało się zweryfikować promocji.',
      });
    }
  };
};

export const removePromotionCode = (): ThunkAction<
  Promise<void>,
  RootState,
  unknown,
  AnyAction
> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    dispatch({
      type: VERIFY_PROMOTION_PENDING,
    });
    dispatch({
      type: VERIFY_PROMOTION__FULFILLED,
      payload: null,
    });
  };
};

export const getAllPromotions = async (params: {
  rows: number;
  first: number;
  search?: string;
  sortField?: string;
  sortOrder?: string | null;
}) => {
  const query = `${params.search ? `&search=${params.search}` : ''}${
    params.sortField ? `&sortField=${params.sortField}` : ''
  }${params.sortOrder ? `&sortOrder=${params.sortOrder}` : ''}`;
  try {
    const { data } = await http.get(
      `promotions?take=${params.rows}&skip=${params.first}${query}`,
    );
    return data;
  } catch (error) {
    console.error({
      from: 'getAllPromotions',
      error,
    });
  }
};
export const getPromotionById = async (id: number) => {
  await http.get(`promotions/${id}`);
};

export const updatePromotion = async (
  id: number,
  data: Partial<UpdatePromotionDto>,
) => {
  await http.patch(`promotions/${id}`, data);
};

export const createPromotion = async (data: CreatePromotionDto) => {
  await http.post('promotions', data);
};
