import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { http } from '@network/NetworkHTTP';
import { OrderStatus } from '@rootTypes/orders/order-status.enum';
import { StockType } from '@rootTypes/stocks/stock-type.enum';
import { UpdateStockDto } from '@rootTypes/stocks/update-stock.dto';
import { RootState } from '@src/store';
import {
  STOCKS_REQUEST,
  STOCKS_SUCCESS,
  STOCKS_FAIL,
  ADMIN_STOCKS_REQUEST,
  ADMIN_STOCKS_SUCCESS,
  ADMIN_STOCKS_FAIL,
} from './Types';

export const getUserStocksAction = (
  id: number,
  stockType: StockType,
  orderStatus: OrderStatus,
  onError: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: STOCKS_REQUEST,
      });
      const { data } = await http.get(
        `orders/user/stocks/${id}?stockType=${stockType}&orderStatus=${orderStatus}`,
      );

      dispatch({
        type: STOCKS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (err) {
      onError();
      dispatch({
        type: STOCKS_FAIL,
        payload: 'Przepraszamy ale serwis niedostępny. Spróbuj później.',
      });
    }
  };
};

export const getAllStocksAction = (
  params: any,
  email: string,
  stockType: StockType,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: ADMIN_STOCKS_REQUEST,
      });
      const { data } = await http.get(`orders/all/stocks`, {
        params: {
          stockType: stockType,
          sortField: params.sortField,
          sortOrder: params.sortOrder,
          take: params.rows,
          skip: params.first,
          email: email,
        },
      });

      dispatch({
        type: ADMIN_STOCKS_SUCCESS,
        payload: data,
      });

      return data;
    } catch (err) {
      dispatch({
        type: ADMIN_STOCKS_FAIL,
        payload: 'Przepraszamy ale serwis niedostępny. Spróbuj później.',
      });
    }
  };
};

export async function updateStockAction(
  id: number,
  updateStockDto: UpdateStockDto,
) {
  return await http.patch(`stocks/${id}`, updateStockDto);
}

export async function updateSomeStocksAction(
  updateStocksDto: UpdateStockDto[],
) {
  return await http.post(`stocks/some`, updateStocksDto);
}
//Phase 2(4 in wms)
export async function requestCourierFromRentRFR(orderId: string, data: any) {
  return await http.post(`orders/rent/return/${orderId}`, data);
}
//Phase 2
export async function requestCourierFromStorageRFRF(
  orderId: string,
  data: any,
) {
  return await http.post(`orders/storage/return/${orderId}`, data);
}
//Phase 4 multi
export async function requestCourierFromMultiStorageWarehouseATCF(data: any) {
  return await http.post(`orders/storage/multiple/stock/warehouse`, data);
}
//Phase 3 multi
export async function requestCourierFromMultiStorageClientIW(data: any) {
  return await http.post(`orders/storage/multiple/stock/client`, data);
}
