import React, { useState, useEffect } from 'react';
import { translate } from '../../../Module/Translate/Translate';
import { Link } from 'react-router-dom';
import { H2 } from '../../../Component/Text/Text';
import Morcik_confirm from '../../../Asset/images/morcikConfirm.png';
import './PolicyConfirm.scss';
import { editUserAction } from '../../../Network/Actions/Users';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserAction } from '../../../Network/Actions/CurrentUser';
import { RootState } from '../../../store';
import { UserState } from '../../../Type/Custom';
import { useHistory } from 'react-router-dom';
import TextWithLink from '../../../Component/testWithLink/TextWithLink';
import Modal from '../../../Component/Modal/Modal';
import AdminContactForm from '../../AdminContactForm/AdminContactForm';
import Spinner from '../../../Component/Spinner/Spinner';
import Arrow from '../../../Asset/images/modal/arrowIcon.png';
import { Helmet } from 'react-helmet';

const PolicyConfirm = () => {
  const [state, setState] = useState({
    summaryRodo: false,
    summaryPrivacyPolicy: false,
    summaryEmail: false,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const [edited, setEdited] = useState(false);
  const [id, setId] = useState(0);
  const [error, setError] = useState(false);
  const [policyOpened, setPolicyOpened] = useState(false);
  const [newsletterOpened, setNewsletterOpened] = useState(false);
  const [showError, setShowError] = useState(false);
  const [visibleSpinner, setVisibleSpinner] = useState(false);

  const onChange = (event: any, value: boolean) => {
    const { name } = event.target;
    setState((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const acceptTerms = () => {
    if (state.summaryPrivacyPolicy && state.summaryRodo && id) confirm();
  };

  const confirm = async () => {
    setVisibleSpinner(true);
    try {
      await editUserAction(id, {
        isPrivacyConfirmed: state.summaryPrivacyPolicy,
        isRODOConfirmed: state.summaryRodo,
      });
      await dispatch(currentUserAction());
      setEdited(true);
    } catch (error) {
      setShowError(true);
      setError(true);
    } finally {
      setVisibleSpinner(false);
    }
  };

  useEffect(() => {
    if (edited) history.push('/');
    setId(userData.user.id);
  }, [edited]);

  function prevPage() {
    history.push({
      pathname: '/',
    });
  }

  return (
    <>
      <Helmet>
        <title>Skitraj.pl | Polityka prywatności</title>
        <meta name="description" content="Polityka prywatności w Skitraj.pl" />
        <meta name="robots" content="noindex,nofollow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      {visibleSpinner && <Spinner />}

      <div className="policy-confirm ">
        <img
          src={Arrow}
          alt="Arrow"
          onClick={prevPage}
          className="policy-confirm_arrowBack"
        />
        <img
          src={Morcik_confirm}
          alt="Skitraj"
          className="newsletter-confirm_img"
        />

        <div>
          <H2>{translate('summaryStep.acceptPolicy3')}</H2>
        </div>

        <ul className="list-policy">
          <li className="d-flex">
            <input
              className="custom-checkbox "
              type="checkbox"
              id="summaryPrivacyPolicy"
              name="summaryPrivacyPolicy"
              onChange={(e) => onChange(e, !state.summaryPrivacyPolicy)}
              checked={state.summaryPrivacyPolicy}
            />
            <label htmlFor="summaryPrivacyPolicy">
              <div className="flex-line required-checkbox">
                <span className={' first-char_orange'}>* </span>
                <TextWithLink
                  className="registration-form_label fs18 "
                  regulationText={`${translate(
                    'regulations.serwisPolicy.text',
                  )}`}
                  linkName={translate('regulations.serwisPolicy.link')}
                  linkTo={'/regulamin'}
                  dataE2E={'website-regulations'}
                />
              </div>
            </label>
          </li>
          {/* pPolicy */}
          <li className="d-flex justify-content-center">
            <input
              className="custom-checkbox"
              type="checkbox"
              id="summaryRodo"
              name="summaryRodo"
              onChange={(e) => onChange(e, !state.summaryRodo)}
              checked={state.summaryRodo}
            />
            <label htmlFor="summaryRodo">
              <div className="flex-line required-checkbox">
                <span className={' first-char_orange'}>* </span>
                <TextWithLink
                  className="registration-form_label fs18"
                  regulationText={`${translate(
                    'regulations.privacyPolicy.text',
                  )}`}
                  linkName={translate('regulations.privacyPolicy.link')}
                  linkTo={'/polityka-prywatnosci'}
                  dataE2E={'privacy-policy'}
                />
              </div>
            </label>
          </li>
          <li className="d-flex justify-content-center">
            <input
              className="custom-checkbox"
              type="checkbox"
              id="summaryEmail"
              name="summaryEmail"
              onChange={(e) => onChange(e, !state.summaryEmail)}
              checked={state.summaryEmail}
            />
            <label htmlFor="summaryEmail">
              <div className="d-flex flex-line required-checkbox">
                <span className={' first-char_orange'}></span>
                <div className="polices-confirm">
                  <p className="polices-confirm_title">
                    {translate('footer.accept')}
                    &nbsp;
                    <span
                      className="title-accent"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setNewsletterOpened(!newsletterOpened);
                      }}
                    >
                      <u>
                        {newsletterOpened
                          ? translate('footer.btnLess')
                          : translate('footer.btnMore')}
                      </u>
                    </span>
                  </p>
                  {newsletterOpened && (
                    <div>
                      <p className="polices-confirm_text">
                        {translate('footer.acceptText')}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </label>
          </li>
        </ul>
        <button
          className="button_accept code-form_button btn"
          disabled={!state.summaryPrivacyPolicy || !state.summaryRodo}
          onClick={() => acceptTerms()}
        >
          {translate('paymentForm.next')}
        </button>
        {error && (
          <div className="row">
            <div className="update-error">
              {translate('errors.serverError')}{' '}
            </div>
          </div>
        )}
        <div className="polices-confirm">
          <p className="polices-confirm_title">
            {translate('footer.adminDataTitle')}&nbsp;
            <span
              className="title-accent"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPolicyOpened(!policyOpened);
              }}
            >
              <u>
                {policyOpened
                  ? translate('footer.btnLess')
                  : translate('footer.btnMore')}
              </u>
            </span>
          </p>
          {policyOpened && (
            <div>
              <p className="polices-confirm_text">
                {translate('footer.adminDataTextFirst')}
              </p>
              <br />
              <p className="polices-confirm_text">
                {translate('footer.adminDataTextSecond')}
              </p>
              <p className="polices-confirm_text">
                {translate('footer.policyViewMore')}&nbsp;
                <Link
                  className="polices-confirm_link"
                  to="/polityka-prywatnosci"
                  data-e2e="privacy-policy"
                >
                  <span>{translate('footer.policyPrivacy')}</span>
                </Link>
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PolicyConfirm;
