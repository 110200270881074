import { translate } from '@module/Translate/Translate';
import { getStockToOrder } from '@network/Actions/GetStockToOrder';
import { StockType } from '@rootTypes/stocks/stock-type.enum';
import { formatDateToLocalTime } from '@util/Dates';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Panel } from 'primereact/panel';
import { useEffect, useState } from 'react';
import './StockToOrdersStyles.scss';
const LIMIT_ON_PAGE = 3;

export default function StockToOrderTable(props: {
  orderId: string;
  stockType: StockType;
  sectionLabel: string;
}) {
  const [collapsed, setCollapsed] = useState(true);
  const [tableData, setTableData] = useState<any>();
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    sortField: 'createdAt',
    sortOrder: null,
  });
  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };
  const getData = async (
    take: number,
    skip: number,
    sortOrder: 0 | 1,
    sortField = 'id',
  ) => {
    setIsLoading(true);
    const { data } = await getStockToOrder({
      orderId: props.orderId,
      stockType: props.stockType,
      take,
      skip,
      sortField,
      sortOrder: sortOrder,
    });
    setTotalItems(data.meta.totalItems);
    setTableData(data.items);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(LIMIT_ON_PAGE, 0, 1);
  }, []);

  useEffect(() => {
    getData(
      LIMIT_ON_PAGE,
      lazyParams.first,
      lazyParams.sortOrder === -1 ? 1 : 0,
      lazyParams.sortField,
    );
  }, [JSON.stringify(lazyParams)]);

  return (
    <Panel
      className="stock-to-order-container"
      header={props.sectionLabel}
      toggleable
      collapsed={collapsed}
      onToggle={(e) => setCollapsed(e.value as boolean)}
    >
      <ul className="order-statuses">
        <DataTable
          value={tableData}
          responsiveLayout="scroll"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          rows={LIMIT_ON_PAGE}
          paginator
          lazy
          stripedRows
          selectionPageOnly
          emptyMessage="No data found"
          loading={isLoading}
          onPage={onPage}
          onSort={onSort}
          sortOrder={lazyParams.sortOrder}
          sortField={lazyParams.sortField}
          totalRecords={totalItems}
        >
          <Column
            field="id"
            sortable
            header={translate('adminTool.orders.orderId')}
          />
          <Column
            field="externalOrderId"
            sortable
            header={translate('adminTool.orders.orderId')}
          />
          <Column
            field="isActive"
            body={(item: boolean) =>
              item
                ? translate('adminTool.orders.isActive')
                : translate('adminTool.orders.notActive')
            }
            sortable
            header={translate('adminTool.orders.isActive')}
          />
          <Column
            field="stockStatus"
            sortable
            header={translate('adminTool.orders.productStatus')}
          />
          <Column
            field="period"
            sortable
            header={translate('adminTool.orders.period')}
          />
          <Column
            field="startDate"
            sortable
            body={formatDateToLocalTime}
            header={translate('adminTool.orders.startDate')}
          />
          <Column
            field="userEndDate"
            sortable
            body={formatDateToLocalTime}
            header={translate('adminTool.orders.userEndDate')}
          />
          <Column
            field="endDate"
            sortable
            body={formatDateToLocalTime}
            header={translate('adminTool.orders.endDateStock')}
          />
          <Column
            field="updatedAt"
            sortable
            body={formatDateToLocalTime}
            header={translate('adminTool.orders.updatedAt')}
          />
          <Column
            field="updatedBy"
            sortable
            header={translate('adminTool.orders.updatedBy')}
          />
        </DataTable>
      </ul>
    </Panel>
  );
}
