import React, { useState, useEffect } from 'react';
import './ContactBlock.scss';
import { useDispatch, useSelector } from 'react-redux';
import PaginationBar from '@component/Pagination/PaginationBar';
import Rating from '@component/Rating/Rating';
import { translate } from '@module/Translate/Translate';
import { getReviewsAction, getAverageGrades } from '@network/Actions/Reviews';
import { RootState } from '@src/store';
import { ReviewsState } from '@type/Custom';
import { FindUserDto } from '@rootTypes/users/find-user.dto';
import { CommentComponent } from './components/Comment/Comment';
import { AddNewCommentForm } from './components/AddNewCommentForm/AddNewCommentForm';
import { H2 } from '@component/Text/Text';

const LIMIT_ON_PAGE = 3;
interface IProps {
  visibleSpinner: (value: boolean) => void;
  showError: (value: boolean) => void;
  user: FindUserDto;
}

const ContactBlock = ({ visibleSpinner, showError, user }: IProps) => {
  const dispatch = useDispatch();
  const { reviews }: ReviewsState = useSelector<RootState, ReviewsState>(
    ({ reviews }) => reviews,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [getAverage, setAverageGrades] = useState([]);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    dispatch(
      getReviewsAction(currentPage, LIMIT_ON_PAGE, (value) => showError(value)),
    );
  }, [currentPage]);

  useEffect(() => {
    getAverageGrades().then((data: []) => setAverageGrades(data));
  }, []);

  return (
    <article className="offer-contact-block row no-gutters mh-space">
      <div className="container commentBlock">
        <div className="containerWrapper">
          <H2 className="container__title">
            {translate('contactBlock.opinion')}
          </H2>
          <p className="container__text">{translate('contactBlock.title')}</p>
        </div>

        <div className="ratingWrapper">
          {getAverage.map((item: any, index) => (
            <div key={index} className="ratingItem">
              <Rating value={(item.sum / item.count).toFixed(1)} />
              <p>{translate(`contactBlock.${item.review_type}`)}</p>
            </div>
          ))}
        </div>

        <div className="commentSection">
          {Object.keys(reviews).length > 0 &&
            reviews.items.map(
              (review: any, index: React.Key | null | undefined) => (
                <CommentComponent key={index} comment={review} />
              ),
            )}
        </div>

        {Object.keys(reviews).length > 0 && (
          <PaginationBar
            currentPage={currentPage}
            paginate={paginate}
            totalPages={reviews.meta.totalPages}
          />
        )}

        <AddNewCommentForm
          visibleSpinner={visibleSpinner}
          showError={showError}
          user={user}
        />
      </div>
    </article>
  );
};

export default ContactBlock;
