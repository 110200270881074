import { t } from '../../Module/Translate/Translate';
import { TypographyProps } from './Typography.type';

export const parser =
  (props: TypographyProps) =>
  (value: string): string => {
    value = t(value);
    if (props.upper) {
      value = value.toUpperCase();
    }
    return value;
  };
