import React, { useState } from 'react';
import './CreateAddressItem.scss';
import icon from '@images/dashboard/addIcon.jpg';
import Modal from '@component/Modal/Modal';
import EditAddressForm from '@form/EditAddressForm/EditAdressForm';
import { translate } from '@module/Translate/Translate';

export const CreateAddressItem = ({ createNewAddress, active = true }: any) => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  return (
    <>
      <Modal
        className="dialog--large"
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
      >
        <EditAddressForm
          setShowModal={setShowCreateModal}
          createNewAddress={createNewAddress}
        />
      </Modal>

      <div className="create-address-item address-card-item">
        <div className={`wrapper wrapper--create ${active ? '' : 'inactive'}`}>
          <p className="create-address-item_title">
            {translate('addressBlock.addNewAddress')}
          </p>
          <div
            className={`iconWrapper`}
            onClick={() => {
              if (active) {
                setShowCreateModal(true);
              }
            }}
          >
            <img src={icon} alt={translate('addressBlock.altAdd')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAddressItem;
