import React, { useEffect, useState } from 'react';
import './Promotion.scss';
import { translate } from '@module/Translate/Translate';
import { formatDateToLocalTime } from '@util/Dates';
import useDebounce from '@util/Debounse';
import { UserProps } from '@view/AdminTool/interfaces/interfaces';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { getAllPromotions } from '@network/Actions/Promotion';
import EditOrCreatePromotion from './components/EditComplaints/EditPromotion';

const LIMIT_ON_PAGE = 3;

export default function Promotions({ user }: UserProps) {
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const [promotions, setPromotions] = useState<any>([]);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [search, setSearch] = useState('');
  const [modalCreate, setModalCreate] = useState(false);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
  });

  useEffect(() => {
    if (mount) {
      getPromotions();
      setMount(false);
    } else if (refresh) {
      getPromotions();
      setRefresh(false);
    } else if (search) {
      searchData();
    } else {
      getPromotions();
    }
  }, [refresh, lazyParams]);

  useEffect(() => {
    if (mount) {
      return;
    }
    if (search !== '') {
      setLazyParams({ ...lazyParams, first: 0 });
      searchData();
    } else {
      setLazyParams({ ...lazyParams, first: 0 });
      setRefresh(true);
    }
  }, [useDebounce(search, 500)]);

  const getPromotions = async () => {
    setLoading(true);
    const data = await getAllPromotions(lazyParams);
    setTotalItems(data.meta.totalItems);
    setPromotions(data.items);
    setLoading(false);
  };

  const onPage = (event: any) => {
    setLazyParams({ ...event });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event });
  };

  const rowExpansionTemplate = (data: any) => {
    return <EditOrCreatePromotion promotion={data} setRefresh={setRefresh} />;
  };

  const searchData = () => {
    setLoading(true);
    getAllPromotions({
      ...lazyParams,
      search,
    })
      .then((data) => {
        setTotalItems(data.meta.totalItems);
        setPromotions(data.items);
      })
      .finally(() => setLoading(false));
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex justify-content-between align-items-center">
          <button className="btn" onClick={() => setModalCreate(!modalCreate)}>
            {modalCreate
              ? `${translate('adminTool.promotions.hide')}`
              : `${translate('adminTool.promotions.add')}`}
          </button>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={translate('adminTool.promotions.search')}
              className="search-input"
            />
          </span>
        </div>
        {modalCreate && (
          <EditOrCreatePromotion
            isCreate
            setRefresh={(value: boolean) => {
              setRefresh(value);
              setModalCreate(false);
            }}
          />
        )}
      </>
    );
  };
  return (
    <div className="promotion-table-container">
      {!loading && promotions ? (
        <DataTable
          value={promotions}
          responsiveLayout="scroll"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          dataKey="id"
          paginator
          header={renderHeader}
          lazy
          loading={loading}
          first={lazyParams.first}
          totalRecords={totalItems}
          stripedRows
          selectionPageOnly
          emptyMessage={translate('adminTool.common.nodata')}
          className="datatable-responsive"
          rows={LIMIT_ON_PAGE}
          expandedRows={expandedRows}
          onRowToggle={(e: any) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          onPage={onPage}
          onSort={onSort}
          sortOrder={lazyParams.sortOrder}
          sortField={lazyParams.sortField}
        >
          <Column expander style={{ width: '3em' }} />
          <Column
            field="id"
            sortable
            header={translate('adminTool.promotions.id')}
          />
          <Column
            field="name"
            sortable
            header={translate('adminTool.promotions.name')}
          />
          <Column
            field="code"
            sortable
            header={translate('adminTool.promotions.code')}
          />
          <Column
            field="discount"
            header={translate('adminTool.promotions.discount')}
          />
          <Column
            field="count"
            sortable
            header={translate('adminTool.promotions.count')}
          />
          <Column
            field="status"
            sortable
            header={translate('adminTool.promotions.status')}
          />
          <Column
            field="createdAt"
            body={formatDateToLocalTime}
            sortable
            header={translate('adminTool.orders.createdAt')}
          />
          <Column
            field="updatedAt"
            body={formatDateToLocalTime}
            sortable
            header={translate('adminTool.orders.updatedAt')}
          />
          <Column
            field="updatedBy"
            sortable
            header={translate('adminTool.orders.updatedBy')}
          />
        </DataTable>
      ) : (
        <div>{translate('appState.loading')}</div>
      )}
    </div>
  );
}
