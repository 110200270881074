export const calculateCeilTotalRentPrice = (
  rentWeeks: number,
  boxesPriceRent: number,
  boxesValueRent: number,
  stackTransportPrice: number,
  discount?: number,
  packagePrice?: number,
) => {
  const transportRentPrice = (boxesValueRent / 5) * stackTransportPrice * 2;

  const rentPrice = parseFloat(
    (
      transportRentPrice +
      boxesPriceRent * rentWeeks +
      (packagePrice ?? 0)
    ).toFixed(2),
  );

  if (discount) {
    const rentPriceWithDiscount = parseFloat(
      (
        (1 - discount / 100) *
        (transportRentPrice + boxesPriceRent * rentWeeks + (packagePrice ?? 0))
      ).toFixed(2),
    );
    return rentPriceWithDiscount;
  }

  return rentPrice;
};
