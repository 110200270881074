import {
  VERIFY_PROMOTION_PENDING,
  VERIFY_PROMOTION__FULFILLED,
  VERIFY_PROMOTION__REJECTED,
} from '../Network/Actions/Types';
import { PromotionState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const promotionReducer = (
  state: PromotionState = { loading: false, promotion: null, error: null },
  action: Action,
) => {
  switch (action.type) {
    case VERIFY_PROMOTION_PENDING:
      return { loading: true, promotion: state.promotion, error: null };
    case VERIFY_PROMOTION__FULFILLED:
      localStorage.setItem('promotion', JSON.stringify(action.payload));
      return { loading: false, promotion: action.payload, error: null };
    case VERIFY_PROMOTION__REJECTED:
      return {
        loading: false,
        promotion: state.promotion,
        error: action.payload,
      };
    default:
      return state;
  }
};
