import React from 'react';
import Background from '@images/offer/storage.jpg';
import './PrimaryBlockStore.scss';
import { H2, H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

const PrimaryBlockStore = () => (
  <>
    <article
      className="offer-primary-section"
      id={translate('offer.primaryBlockStore.title')}
    >
      <div className="offer-primary-section_container container">
        <H2 className="title title-store" id="title-store">
          offer.primaryBlockStore.title
        </H2>
        <section className="offer-from-container">
          <div className="offer-primary-section_text">
            <H3 className="offer-welcomeText">
              offer.primaryBlockStore.subtitle
            </H3>
            <H3 className="primaryBlock-text text-warning">
              offer.primaryBlockStore.price
            </H3>
          </div>
          <div
            className="offer-primary-section_container "
            style={{ backgroundImage: `url(${Background})` }}
          ></div>
        </section>
      </div>
    </article>
  </>
);

export default PrimaryBlockStore;
