import React, { useState } from 'react';
import './Move.scss';
import box from '@images/rent-page/pudelko.png';
import morcik from '@images/move-page/morcik.png';
import { translate } from '@module/Translate/Translate';
import Modal from '@component/Modal/Modal';
import { H1, H3 } from '@component/Text/Text';
import { SKITRAJ_MOVE_TO_FORM_ID } from '@/envConfig';
import { Link } from 'react-router-dom';

function Move() {
  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className="dialog--large moveForm-modal"
      >
        <div className="moveForm-modal_iframe">
          <iframe
            className="iframe"
            src={`https://forms.office.com/pages/responsepage.aspx?id=${SKITRAJ_MOVE_TO_FORM_ID}&embed=true`}
            allowFullScreen
            title="move-form"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </Modal>
      <div className="move-container">
        <div className="container">
          <img
            src={morcik}
            alt="morcik"
            className="d-block d-xl-none image-morcik-mobile"
          />

          <div className="title-group">
            <H1 className="title-group_title">movePage.moveBlock.title</H1>
            <H3 className="title-group_subtitle">
              {translate('movePage.moveBlock.subtitle1')}
              <b>{translate('movePage.moveBlock.bolded')}</b>
              {translate('movePage.moveBlock.subtitle2')}
              <Link
                to={{
                  pathname: '/regulamin-promocji',
                }}
                className="accent"
              >
                {translate('movePage.moveBlock.linkText')}
              </Link>
            </H3>

            <button type="button" className="move-link_btn" onClick={openModal}>
              {translate('movePage.moveBlock.buttonText')}
            </button>
          </div>

          <img src={box} alt="box" className="box" />
          <img src={morcik} alt="morcik" className="morcik" />
        </div>
      </div>
    </>
  );
}

export default Move;
