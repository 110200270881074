import React from 'react';

import MainTemplate from '../../Component/MainTemplate/MainTemplate';
import Title from './Components/Title/Title';
import ProductList from './Components/ProductList/ProductList';
import './Shop.scss';
import { Helmet } from 'react-helmet';

export default function Shop() {
  return (
    <>
      <Helmet>
        <title>
          Sklep z ekologicznymi pudełkami i art. pakowymi | skitraj.pl
        </title>
        <meta
          name="description"
          content="Kompleksowe rozwiązania dla przechowywania i transportu Twoich rzeczy! Nasz sklep oferuje wytrzymałe pudełka i różne artykuły do pakowania."
        />
        <meta name="robots" content="index,follow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MainTemplate>
        <div className="shop-block">
          <div className="shop-wrapper container">
            <Title />
            <ProductList />
          </div>
        </div>
      </MainTemplate>
    </>
  );
}
