import { CHANGE_SITE_LANGUAGE } from '@network/Actions/Types';
import { Lang } from '@module/Translate/Translate.type';
import { DefaultLanguage } from '@module/Translate/Translate.const';

interface Action {
  type: string;
  payload: { language: Lang };
}

export const languageReducer = (
  state: { language: Lang } = { language: DefaultLanguage },
  action: Action,
) => {
  switch (action.type) {
    case CHANGE_SITE_LANGUAGE:
      return { language: action.payload.language };
    default:
      return state;
  }
};
