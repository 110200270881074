import { FindBasketProductDto } from '@rootTypes/products/find-basket-product.dto';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { ProductType } from '@rootTypes/products/product-type.enum';
import { RootState } from '@src/store';
import { DefaultSettingsState, ProductsState } from '@type/Custom';
import { BoxesSort } from '@util/BoxesSort';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { findDefaultCountForStorage } from '../logic/defaultCountForStorage';
import { setCounterValue } from '../logic/setCounterValue';
import PackageCounter from '../PackageCounter';

interface IOfferStorageBox {
  handleCounterClick: (data: any, value: any) => void;
  stateStore: FindBasketProductDto[];
}

export default function OfferStorageBox({
  handleCounterClick,
  stateStore,
}: IOfferStorageBox) {
  const [defaultCountForStorage, setDefaultCountForStorage] = useState(0);
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  useEffect(() => {
    const defaultCount = findDefaultCountForStorage(defaultSettings);
    setDefaultCountForStorage(defaultCount);
  }, [defaultSettings]);

  return (
    <div className="calculator-container_items offer__box">
      {BoxesSort(
        products.filter(
          (product) =>
            product.group === ProductGroup.box &&
            product.type === ProductType.storage,
        ),
      ).map((box) => (
        <PackageCounter
          key={box.id}
          onCounterClick={(value, data) => handleCounterClick(value, data)}
          stepValue={defaultCountForStorage}
          data={box}
          counterValue={setCounterValue(
            box.name.split(' ')[1],
            box.id,
            stateStore,
          )}
        />
      ))}
    </div>
  );
}
