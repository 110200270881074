import React from 'react';
import Packages from '@images/customer/packages.png';
import './PackagesBlock.scss';
import { H2, H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { Link } from 'react-router-dom';

const PackagesBlock = () => (
  <div className="packages-section">
    <article className="container relative">
      <div className="textSection">
        <H2 className="packages-section_title">homePage.packagesBlock.title</H2>
        <H3 className="packages-section_subtitle">
          homePage.packagesBlock.subtitle
        </H3>
        <div className="row">
          <div className=" buttonSection desktopButton">
            <Link
              to="/przeprowadz"
              className="package-section_btn btn btn-large"
            >
              {translate('homePage.packagesBlock.button')}
            </Link>
          </div>
        </div>
      </div>
      <img src={Packages} alt="packages" />
      <div className="buttonSection mobileButton">
        <Link
          to="/przeprowadz"
          className="package-section_btn btn btn-large btn-80"
        >
          {translate('homePage.packagesBlock.button')}
        </Link>
      </div>
    </article>
  </div>
);

export default PackagesBlock;
