import { SERVER_NETWORK_ERROR } from '../Network/Actions/Types';
import { ServerNetworkError } from '../Type/Custom';

interface Action {
  type: string;
  payload: string | null;
}
export const serverNetworkErrorReducer = (
  state: ServerNetworkError = { error: null },
  action: Action,
) => {
  switch (action.type) {
    case SERVER_NETWORK_ERROR:
      return {
        error: action.payload,
      };
    default:
      return state;
  }
};
