import React, { useCallback } from 'react';
import CompletedIcon from '../../Asset/images/completedProcess.png';
import './RegistrationProgress.scss';
import { P } from '../Text/Text';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

const RegistrationProgress = ({ activeStep }: any) => {
  const history = useHistory();
  const location = useLocation();

  const changeActiveStep = useCallback(
    (values: any) => {
      history.push({
        ...location,
        state: {
          activeStep: values,
          addressStep: 0,
        },
      });
    },
    [history, location],
  );

  return (
    <>
      <div className="registration-step">
        <div
          onClick={() => {
            if (activeStep > 0) {
              changeActiveStep(0);
            }
          }}
          className={`registration-step_item ${activeStep === 0 && 'active'} ${
            activeStep > 0 && 'completed'
          }`}
        >
          {activeStep === 0 ? (
            <span className="registration-step_text">1</span>
          ) : (
            <img
              src={CompletedIcon}
              alt="Skitraj"
              className="registration-step_text"
            />
          )}
          <P className="registration-step_subtitle">
            registrationProgress.login
          </P>
        </div>
        <div
          className={`registration-step_line ${activeStep > 0 && 'completed'}`}
        />

        <div
          onClick={() => {
            if (activeStep > 3) {
              changeActiveStep(1);
            }
          }}
          className={`registration-step_item ${
            activeStep > 0 && activeStep < 4 && 'active'
          } ${activeStep > 3 && 'completed'}`}
        >
          {activeStep <= 3 ? (
            <span className="registration-step_text">2</span>
          ) : (
            <img
              src={CompletedIcon}
              alt="Skitraj"
              className="registration-step_text"
            />
          )}
          <P className="registration-step_subtitle">
            registrationProgress.address
          </P>
        </div>
        <div
          className={`registration-step_line ${activeStep > 3 && 'completed'}`}
        />

        <div
          onClick={() => {
            if (activeStep > 4) {
              changeActiveStep(4);
            }
          }}
          className={`registration-step_item ${activeStep === 4 && 'active'} ${
            activeStep > 4 && 'completed'
          }`}
        >
          {activeStep <= 4 ? (
            <span className="registration-step_text">3</span>
          ) : (
            <img
              src={CompletedIcon}
              alt="Skitraj"
              className="registration-step_text"
            />
          )}
          <P className="registration-step_subtitle">
            registrationProgress.payment
          </P>
        </div>
        <div
          className={`registration-step_line ${activeStep > 4 && 'completed'}`}
        />

        <div
          onClick={() => {
            if (activeStep > 5) {
              changeActiveStep(5);
            }
          }}
          className={`registration-step_item ${activeStep === 5 && 'active'} ${
            activeStep > 5 && 'completed'
          }`}
        >
          {activeStep <= 5 ? (
            <span className="registration-step_text">4</span>
          ) : (
            <img
              src={CompletedIcon}
              alt="Skitraj"
              className="registration-step_text"
            />
          )}
          <P className="registration-step_subtitle">registrationProgress.sum</P>
        </div>
        <div
          className={`registration-step_line ${activeStep > 5 && 'completed'}`}
        />

        <div
          onClick={() => {
            if (activeStep > 6) {
              changeActiveStep(6);
            }
          }}
          className={`registration-step_item ${activeStep === 6 && 'active'} ${
            activeStep > 6 && 'completed'
          }`}
        >
          {activeStep <= 6 ? (
            <span className="registration-step_text">5</span>
          ) : (
            <img
              src={CompletedIcon}
              alt="Skitraj"
              className="registration-step_text"
            />
          )}
          <P className="registration-step_subtitle">
            registrationProgress.skitrane
          </P>
        </div>
      </div>
    </>
  );
};

export default RegistrationProgress;
