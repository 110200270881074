import React from 'react';
import { CreateProductDto } from '../../../../../../../../types/products/create-product.dto';
import ProductInfo from '../Info/ProductInfo';

interface Props {
  setProduct: (product: CreateProductDto) => void;
}

export default function AddProduct({ setProduct }: Props) {
  return (
    <div>
      <ProductInfo editMode={false} setProduct={setProduct} />
    </div>
  );
}
