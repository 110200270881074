import React from 'react';
import './CoolParcel.scss';
import Parcels from '../../Asset/images/offer/paczuszek-1.png';

const CoolParcel = () => {
  return (
    <>
      <div className="cool-parcel row no-gutters">
        <img src={Parcels} alt="wycena" className="parcels" />
      </div>
    </>
  );
};

export default CoolParcel;
