import { InputText } from 'primereact/inputtext';
import React from 'react';
import { translate } from '@module/Translate/Translate';

export default function FilterHeader(props: {
  setInputValue: any;
  inputValue: any;
  inputPlaceholder?: string;
}) {
  return (
    <div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={props.inputValue}
          onChange={(e) => {
            props.setInputValue(e.target.value);
          }}
          placeholder={translate(props.inputPlaceholder)}
        />
      </span>
    </div>
  );
}

FilterHeader.defaultProps = {
  inputPlaceholder: 'adminTool.common.searchByEmailPhoneAndId',
};
