import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import './Reviews.scss';
import { Messages } from 'primereact/messages';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { translate } from '@module/Translate/Translate';
import {
  getAllReviewsAction,
  editReviewAction,
} from '@network/Actions/Reviews';
import { RootState } from '@src/store';
import { ReviewsState } from '@type/Custom';
import { formatDateToLocalTime } from '@util/Dates';
import { UserProps } from '@view/AdminTool/interfaces/interfaces';

const LIMIT_ON_PAGE = 7;
const CURRENT_PAGE = 1;

const statuses = [
  { label: 'Accepted', value: true },
  { label: 'Unaccepted', value: false },
];

function Reviews({ user }: UserProps) {
  const dispatch = useDispatch();
  const [rowEdited, setRowEdited] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { reviews }: ReviewsState = useSelector<RootState, ReviewsState>(
    ({ reviews }) => reviews,
  );
  const message: any = useRef(null);
  useEffect(() => {
    dispatch(getAllReviewsAction(CURRENT_PAGE, LIMIT_ON_PAGE));
    if (rowEdited) {
      setRowEdited(false);
    }
  }, [rowEdited]);

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'isAccepted') {
      return rowData[item.field] ? (
        <span className="status-badge status-true">Accepted</span>
      ) : (
        <span className="status-badge status-false">Unaccepted</span>
      );
    }
    if (item.field === 'type') {
      switch (rowData[item.field]) {
        case 'rent':
          return translate('adminTool.reviews.rent');
        case 'storage':
          return translate('adminTool.reviews.storage');
        case 'shop':
          return translate('adminTool.reviews.shop');
        case 'move':
          return translate('adminTool.reviews.move');
        default:
          return rowData[item.field];
      }
    }
  };

  const onRowEditComplete = async (e: any) => {
    try {
      delete e.newData.updatedAt;
      await editReviewAction(e.newData.id, e.newData);
      setRowEdited(true);
      showMessage('success', translate('adminTool.complaints.updateMessage'));
    } catch (error) {
      showMessage('error', translate('adminTool.complaints.fail'));
    }
  };

  const statusEditor = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        optionLabel="label"
        optionValue="value"
        onChange={(e) => options.editorCallback(e.value)}
        placeholder={translate('adminTool.reviews.newstatus')}
        itemTemplate={(option) => {
          switch (option.value) {
            case true:
              return (
                <span className="status-badge status-true">{option.label}</span>
              );
            case false:
              return (
                <span className="status-badge status-false">
                  {option.label}
                </span>
              );
          }
        }}
      />
    );
  };

  const cellEditor = (options: any) => {
    if (options.field === 'isAccepted') return statusEditor(options);
  };

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  return (
    <div className="reviews-container">
      <Messages ref={message} />
      <InputText
        value={globalFilterValue}
        onChange={onGlobalFilterChange}
        placeholder={translate('adminTool.common.search')}
      />
      <DataTable
        value={reviews.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        stripedRows
        selectionPageOnly
        onRowEditComplete={onRowEditComplete}
        editMode="row"
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        rows={LIMIT_ON_PAGE}
        filters={filters}
      >
        <Column
          field="id"
          sortable
          header={translate('adminTool.reviews.id')}
        />
        <Column
          field="rating"
          sortable
          header={translate('adminTool.reviews.rating')}
        />
        <Column
          field="type"
          sortable
          header={translate('adminTool.reviews.type')}
          body={labelChecker}
        />
        <Column
          field="text"
          sortable
          header={translate('adminTool.reviews.text')}
        />
        <Column
          field="name"
          sortable
          header={translate('adminTool.reviews.name')}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.reviews.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.reviews.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.reviews.updatedBy')}
        />
        <Column
          field="isAccepted"
          sortable
          header={translate('adminTool.reviews.isAccepted')}
          editor={(options) => cellEditor(options)}
          body={labelChecker}
        />
        <Column
          rowEditor
          headerStyle={{ width: '5%', minWidth: '4rem' }}
          bodyStyle={{ textAlign: 'center' }}
        ></Column>
      </DataTable>
    </div>
  );
}

export default Reviews;
