import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@module/Translate/Translate';
import {
  addProductInBasket,
  decreaseProductInBasket,
  removeProductFromBasket,
} from '@network/Actions/Basket';
import { ProductGroup } from '@rootTypes/products/product-group.enum';
import { RootState } from '@src/store';
import { BasketState } from '@type/Custom';
import Counter from '../Counter/Counter';
import './ProductPackage.scss';
import { Link } from 'react-router-dom';

export default function ProductPackage({ productData, stepValueCounter }: any) {
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const onCounterIncreaseClick = (value: any) => {
    dispatch(
      addProductInBasket({ data: { ...productData, value }, stepValueCounter }),
    );
    setValue(value);
  };

  const onCounterDecreaseClick = (value: any) => {
    if (value === 0) dispatch(removeProductFromBasket(productData));
    if (value !== 0)
      dispatch(
        decreaseProductInBasket({ ...productData, value }, stepValueCounter),
      );
    setValue(value);
  };

  useEffect(() => {
    const item = state.products.find(
      (product) =>
        product.type === productData.type &&
        product.group === productData.group &&
        product.id === productData.id,
    );

    item ? setValue(+item.value) : setValue(0);
  }, [state.products, productData]);

  return (
    <div className="order-product-package">
      <Link
        to={`/wynajem/${productData.pathUrl}`}
        className="order-product-package_group"
      >
        <img
          src={
            productData.images.find((image: any) => image.type === 'miniature')
              .url
          }
          alt={productData.name}
        />
        <div className="order-product-package_info">
          <p className="name">{productData.name}</p>
          <p className="description">
            {productData.packages.map((item: any, idx: number) => {
              if (item.child.group === ProductGroup.box) {
                if (idx === 0) {
                  return `${item.quantity} x ${item.child.name}`;
                } else {
                  return `/ ${item.quantity} x ${item.child.name} `;
                }
              }
            })}
          </p>
          <div className="price mobile">
            {productData.weekPrice} {translate('default.currency')}
          </div>
        </div>
      </Link>
      <div className="order-product-package_price">
        <Counter
          extraClass="order-counter-tall"
          stepValue={stepValueCounter}
          onCounterIncreaseClick={(value) => onCounterIncreaseClick(value)}
          onCounterDecreaseClick={(value) => onCounterDecreaseClick(value)}
          counterValue={value}
        />
        <div className="price desktop">
          {productData.weekPrice} {translate('default.currency')}
        </div>
      </div>
    </div>
  );
}
