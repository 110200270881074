import { FC } from 'react';
import './NewPasswordDesktop.scss';
import { translate } from '../../../../../../Module/Translate/Translate';
import P from '../../../../../../Component/Text/Text';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { TokenExpired } from '../../../../../../Component/TokenExpired/TokenExpired';
import Navigation from '../../../../../../Component/Navigation/Navigation';
import Footer from '../../../../../../Component/Footer/Footer';
import { isEmpty } from '../../../../../../Util/isEmpty';

interface IProps {
  isTokenValid: boolean;
  formik: any;
  newPasswordError: any;
}

const NewPassword: FC<React.PropsWithChildren<IProps>> = ({
  isTokenValid,
  formik,
  newPasswordError,
}: IProps) => {
  return (
    <div className="new-password-wrapper-desktop">
      <Navigation />
      <div className="new-passord-container container">
        <P className="container title">verifyEmail.templateTitle</P>
        <div className="new-password">
          {isTokenValid ? (
            <>
              <P>resetPassword.templateTitle</P>
              <form
                onSubmit={formik.handleSubmit}
                className="new-password_form"
              >
                <div className="new-password_wrapper">
                  <div className="new-password_wrapper_password-field">
                    <FormFieldFormik
                      label={translate('changePassword.newPassword')}
                      type="password"
                      name="newPassword"
                      placeholder={translate('changePassword.newPassword')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                      fieldError={newPasswordError}
                    />
                    {formik.errors.newPassword ? (
                      <div className="error-feedback">
                        {formik.errors.newPassword}
                      </div>
                    ) : (
                      <div className="info-feedback">
                        {translate('changePassword.text')}
                      </div>
                    )}
                  </div>

                  <div className="new-password_wrapper_password-field">
                    <FormFieldFormik
                      label={translate('changePassword.repeatPassword')}
                      type="password"
                      name="repNewPassword"
                      placeholder={translate('changePassword.repeatPassword')}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.repNewPassword}
                      fieldError={newPasswordError}
                    />
                    <div className="error-feedback">
                      {formik.errors.repNewPassword}
                    </div>
                  </div>
                </div>

                {formik.isValid && isEmpty(formik.values) ? (
                  <button className="submitBtn" type="submit">
                    {translate('resetPassword.submitButton')}
                  </button>
                ) : (
                  <button className="invalid-button" type="submit">
                    {translate('resetPassword.submitButton')}
                  </button>
                )}
              </form>
            </>
          ) : (
            <TokenExpired
              title={translate('errors.restorePasswordTokenExpired')}
              text={translate('errors.resetPasswordTokenExpiredBio')}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewPassword;
