import React, { FunctionComponent, PropsWithChildren } from 'react';
import Form, { FormProps } from '../Module/Form/Form';
import { ANY } from '../Type/Type';

export interface FormHocProps<FormValues>
  extends FormHocOuterProps<FormValues>,
    FormHocInnerProps<FormValues> {
  source?: ANY;
}

interface FormHocOuterProps<FormValues> {
  initialValues: FormValues;
  onSubmit: (data: FormValues) => Promise<void>;
  // submit: string
}

interface FormHocInnerProps<FormValues> {
  form: FormProps<FormValues>;
  set: <T = string>(field: string, validate?: boolean) => (value: T) => void;
}

interface FormInnerI<FormValues>
  extends FormHocOuterProps<FormValues>,
    FormHocInnerProps<FormValues> {}
interface FormOuterI<FormValues> extends FormHocOuterProps<FormValues> {
  source?: ANY;
}

const FormHOC = <FormValues,>(
  Component: FunctionComponent<React.PropsWithChildren<FormInnerI<FormValues>>>,
) =>
  function FormHOC(props: PropsWithChildren<FormOuterI<FormValues>>) {
    return (
      <Form initialValues={props.initialValues} onSubmit={props.onSubmit}>
        {(form: FormProps<any>) => {
          const set =
            (field: string, validate = true) =>
            (value: any) => {
              form.setFieldValue(field, value, validate);
            };
          return <Component {...props} form={form} set={set} />;
        }}
      </Form>
    );
  };

export default FormHOC;
