import React from 'react';
import './AddressInformation.scss';
import { translate } from '../../Module/Translate/Translate';

const AddressInformation = ({ title, item, onEdit }: any) => {
  function onEditAddress() {
    onEdit();
  }

  return (
    <>
      <div className="address-information">
        {item && (
          <div>
            {title && (
              <div>
                <h5
                  className="address-information_title d-block d-lg-none mb-3 text-md-left"
                  dangerouslySetInnerHTML={{
                    __html: title.replace(/[()]/g, ''),
                  }}
                />
                <h5
                  className="address-information_title d-none d-lg-block mb-3 text-md-left"
                  dangerouslySetInnerHTML={{
                    __html: title.replace(/\n/g, '</br>'),
                  }}
                />
              </div>
            )}

            {item.name && (
              <div className="text-md-left address-information_row">
                {item.name}
              </div>
            )}

            {item.restOfName && (
              <div className="text-md-left address-information_row">
                {item.restOfName || ''}
              </div>
            )}

            {item.taxNumber && (
              <div className="text-md-left address-information_row">
                NIP: {item.taxNumber}
              </div>
            )}

            {item.street && (
              <div className="text-md-left address-information_row">
                {item.street}
              </div>
            )}

            {(item.postalCode || item.city) && (
              <div className="text-md-left address-information_row">
                {item.postalCode} {item.city}
              </div>
            )}
            {item.phone && (
              <div className="text-md-left address-information_row">
                {item.phone}
              </div>
            )}
          </div>
        )}
        {onEdit && (
          <div className="address-information_edit text-md-left address-information_row">
            <button onClick={onEditAddress}>
              {translate('addressStep.edit')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default AddressInformation;
