import React, { useState } from 'react';
import './PersonDataBlock.scss';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { User } from '@models/user.model';
import Modal from '@component/Modal/Modal';
import P, { H1 } from '@component/Text/Text';
import CompanyName from '@form/CompanyNameForm/CompanyNameForm';
import CompletedSentForm from '@form/CompletedSentForm/CompletedSentForm';
import EmailAddress from '@form/EmailAddressForm/EmailAddressForm';
import Password from '@form/PasswordForm/PasswordForm';
import PhoneNumber from '@form/PhoneNumberForm/PhoneNumberForm';
import { translate } from '@module/Translate/Translate';
import PersonCardItem from './components/PersonCardItem/PersonCardItem';
import Invoice from '@form//InvoiceForm/InvoiceForm';
import Bill from '@form//BillForm/BillForm';

interface Props {
  setActivePanel: any;
  user: User;
  refreshUser: () => void;
  setSpinner: (value: boolean) => void;
}

const PersonData = ({
  user,
  setActivePanel,
  refreshUser,
  setSpinner,
}: Props) => {
  const [companyModal, setCompanyModal] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [billModal, setBillModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [phoneModal, setPhoneModal] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [showError, setShowError] = useState(false);

  const getNameTitle = () => {
    return user.companyDetails ? (
      <PersonCardItem
        title={translate('dashboard.personDataBlock.company')}
        data={`${user.companyDetails?.name} ${
          user.companyDetails?.restOfName || ''
        }`}
        buttonText={translate('dashboard.personDataBlock.editName')}
        e2e="editName"
        onClick={() => setCompanyModal(true)}
      />
    ) : (
      <PersonCardItem
        title={translate('dashboard.personDataBlock.name')}
        data={`${user.personalDetails?.firstName} ${user.personalDetails?.lastName}`}
        buttonText={translate('dashboard.personDataBlock.editData')}
        e2e="editData"
        onClick={() => setCompanyModal(true)}
      />
    );
  };

  const getInvoiceOrBill = () => {
    return user.companyDetails ? (
      <Invoice
        refreshUser={refreshUser}
        setShowModal={setInvoiceModal}
        user={user}
        setError={(value: boolean) => setShowError(value)}
        setSpinner={setSpinner}
      />
    ) : (
      <Bill
        refreshUser={refreshUser}
        setShowModal={setBillModal}
        user={user}
        setError={(value: boolean) => setShowError(value)}
        setSpinner={setSpinner}
      />
    );
  };

  // TODO: Add proper receipt/invoice data to personal data

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <Modal
        showModal={formSubmit}
        setShowModal={setFormSubmit}
        className="dialog-ml"
      >
        <CompletedSentForm
          setShowConfirm={() => {
            setFormSubmit(false);
            refreshUser();
          }}
          title={translate('passwordForm.passwordChange')}
          message={' '}
        />
      </Modal>
      <Modal showModal={companyModal} setShowModal={setCompanyModal}>
        <CompanyName
          refreshUser={refreshUser}
          user={user}
          setShowModal={setCompanyModal}
          setError={(value: boolean) => setShowError(value)}
          setSpinner={setSpinner}
        />
      </Modal>
      <Modal showModal={emailModal} setShowModal={setEmailModal}>
        <EmailAddress
          setActivePanel={setActivePanel}
          setShowModal={setEmailModal}
          data={user.email}
        />
      </Modal>
      <Modal
        showModal={user.companyDetails ? invoiceModal : billModal}
        setShowModal={user.companyDetails ? setInvoiceModal : setBillModal}
      >
        {getInvoiceOrBill()}
      </Modal>
      <Modal showModal={passwordModal} setShowModal={setPasswordModal}>
        <Password
          refreshUser={() => setFormSubmit(!formSubmit)}
          user={user}
          setShowModal={setPasswordModal}
          setError={(value: boolean) => setShowError(value)}
          setSpinner={setSpinner}
        />
      </Modal>
      <Modal showModal={phoneModal} setShowModal={setPhoneModal}>
        <PhoneNumber
          setActivePanel={setActivePanel}
          setShowModal={setPhoneModal}
        />
      </Modal>
      <div className="personData__wrapper">
        <div className="container-header personData_header">
          <H1 className="container-title d-none d-lg-block personData__title">
            dashboard.personDataBlock.accountData
          </H1>
          <P className="container-subtitle personData__text">
            dashboard.personDataBlock.info
          </P>
        </div>

        <div className="pl-0 pr-0 w-100">
          <div className="container-fluid pl-0 pr-0">
            <div className="d-flex flex-wrap personData__wrapper_row">
              {getNameTitle()}
              <PersonCardItem
                title={translate('dashboard.personDataBlock.phoneNumber')}
                data={user.phone?.toString()}
                buttonText={translate('dashboard.personDataBlock.editNumber')}
                e2e="editNumber"
                onClick={() => setPhoneModal(true)}
              />
              <PersonCardItem
                title={translate('dashboard.personDataBlock.mail')}
                data={user.email}
                buttonText={translate('dashboard.personDataBlock.editMail')}
                e2e="editEmail"
                onClick={() => setEmailModal(true)}
              />
              {/* TODO: Add password type here */}
              <PersonCardItem
                title={translate('dashboard.personDataBlock.password')}
                data="**********"
                buttonText={translate('dashboard.personDataBlock.editPassword')}
                e2e="editPassword"
                onClick={() => setPasswordModal(true)}
              />
              {user.isCompanyAccount ? (
                <PersonCardItem
                  title={translate(
                    'dashboard.invoiceModalDashboard.invoiceData',
                  )}
                  data={`${user.companyDetails?.name || ''}\n${
                    user.companyDetails?.restOfName || ''
                  }\n${user.companyDetails?.street || ''}\n${
                    user.companyDetails?.postalCode || ''
                  } ${user.companyDetails?.city || ''}\n${
                    user.companyDetails?.taxNumber || ''
                  }`}
                  buttonText={translate(
                    'dashboard.invoiceModalDashboard.editButton',
                  )}
                  e2e="editInvoiceData"
                  onClick={() => setInvoiceModal(true)}
                />
              ) : (
                <PersonCardItem
                  title={translate('dashboard.billModalDashboard.billData')}
                  data={`${user.personalDetails?.firstName || ''} ${
                    user.personalDetails?.lastName || ''
                  }\n${user.personalDetails?.street || ''}\n${
                    user.personalDetails?.postalCode || ''
                  } ${user.personalDetails?.city || ''}`}
                  buttonText={translate(
                    'dashboard.billModalDashboard.editButton',
                  )}
                  e2e="editBillData"
                  onClick={() => setBillModal(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonData;
