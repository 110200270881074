import {
  LIMITS_FAIL,
  LIMITS_REQUEST,
  LIMITS_SUCCESS,
} from '../Network/Actions/Types';
import { LimitsState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: any;
}

export const limitsReducer = (
  state: LimitsState = {
    loading: false,
    limits: [],
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case LIMITS_REQUEST:
      return { loading: true, limits: state.limits, error: null };
    case LIMITS_SUCCESS:
      return { loading: false, limits: action.payload, error: null };
    case LIMITS_FAIL:
      return { loading: false, limits: state.limits, error: null };
    default:
      return state;
  }
};
