import React, { useState } from 'react';
import './InfoBlock.scss';

import { store, rent, move } from './items';
import { H3, P } from '@component/Text/Text';
import { Link } from 'react-router-dom';
import { translate } from '@module/Translate/Translate';
import Modal from '@component/Modal/Modal';
import { SKITRAJ_MOVE_TO_FORM_ID } from '@/envConfig';
interface IProps {
  section: 'store' | 'move' | 'rent';
}
const InfoBlock = ({ section }: IProps) => {
  const items = section === 'store' ? store : section === 'move' ? move : rent;
  const linkText =
    section === 'store'
      ? 'offer.primaryBlockStore.infoBlock.buttonText'
      : section === 'move'
      ? 'offer.primaryBlockMove.infoBlock.buttonText'
      : 'offer.primaryBlockRent.infoBlock.buttonText';
  const linkPath =
    section === 'store'
      ? '/zamowienie'
      : section === 'move'
      ? '/przeprowadz'
      : '/wynajem';

  const [showModal, setShowModal] = useState(false);
  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        className="dialog--large form-modal"
      >
        <div className="form-modal_iframe">
          <iframe
            className="iframe"
            src={`https://forms.office.com/pages/responsepage.aspx?id=${SKITRAJ_MOVE_TO_FORM_ID}&embed=true`}
            allowFullScreen
            title="move-form"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </Modal>
      <div className={`info-block ${section === 'move' ? 'color-gray' : ''}`}>
        <div className="container">
          <div className="info-block-list">
            {items.map((item, index) => (
              <div className="info-block-item" key={index}>
                <H3 className="info-block-item_title">{item.title}</H3>
                <P className="info-block-item_text">{item.text}</P>
              </div>
            ))}
          </div>
          {section !== 'move' && (
            <Link
              to={
                section === 'rent'
                  ? {
                      pathname: '/zamowienie',
                      state: {
                        defaultIndex: 1,
                      },
                    }
                  : linkPath
              }
              className="info-block_btn btn btn-large"
            >
              {translate(linkText)}
            </Link>
          )}
          {section === 'move' && (
            <button
              onClick={openModal}
              className="info-block_btn btn btn-large"
            >
              {translate(linkText)}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default InfoBlock;
