import { FC } from 'react';
import './TokenExpired.scss';

interface IProps {
  title: string;
  text: string;
}

export const TokenExpired: FC<React.PropsWithChildren<IProps>> = ({
  title,
  text,
}: IProps) => {
  return (
    <div className="wraper">
      <div className="wraper_title">{title}</div>
      <div className="wraper_text">{text}</div>
    </div>
  );
};
