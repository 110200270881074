import React from 'react';
import Logo from '../../Asset/images/logo.png';
import Morcik_under_construction from '../../Asset/images/under-construction.png';
import Social from '../../Component/Social/Social';
import { H1, P } from '../../Component/Text/Text';
import { Link } from 'react-router-dom';
import './PageUnderConstruction.scss';

export default function PageUnderContruction() {
  return (
    <>
      <div className="under-construction-section">
        <div className="under-construction_container container content padding20">
          <div className="row">
            <div className="col text">
              <div className="row">
                <Link className="m-auto" to={'/'}>
                  <img
                    src={Logo}
                    alt="Skitraj"
                    className="under-construction_logo logo"
                  />
                </Link>
              </div>
              <div className="row">
                <img
                  src={Morcik_under_construction}
                  alt="Skitraj"
                  className="under-construction_img"
                />
              </div>
              <H1 className="under-construction_title">
                pageUnderConstruction.title
              </H1>
              <P className="under-construction_subtitle">
                pageUnderConstruction.subtitle
                <br />
                pageUnderConstruction.comingSoon
              </P>
              <Social />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
