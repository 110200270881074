import React from 'react';
import './AddressStep.scss';
import { useLocation } from 'react-router';
import ConfirmNumberForm from '@form/ConfirmNumberForm/ConfirmNumberForm';
import RegistrationNumberForm from '@form/RegistrationNumberForm/RegistrationNumberForm';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import { useSelector } from 'react-redux';
import SelectAddress from './components/SelectAddress/SelectAddress';

interface IProps {
  showError: (value: boolean) => void;
  visibleSpinner: (value: boolean) => void;
}

const AddressStep = ({ showError, visibleSpinner }: IProps) => {
  const { state = { activeStep: 0 } }: any = useLocation();
  const { user } = useSelector<RootState, UserState>((state) => state.user);

  const renderStep = function () {
    if (user.phone) {
      return (
        <SelectAddress showError={showError} visibleSpinner={visibleSpinner} />
      );
    }

    switch (state.activeStep) {
      case 1:
        return <RegistrationNumberForm showError={showError} />;
      case 2:
        return (
          <ConfirmNumberForm
            showError={showError}
            visibleSpinner={visibleSpinner}
          />
        );
      case 3:
        return (
          <SelectAddress
            showError={showError}
            visibleSpinner={visibleSpinner}
          />
        );
    }
  };

  return <>{renderStep()}</>;
};

export default AddressStep;
