import { useEffect, useState } from 'react';
import { translate } from '@module/Translate/Translate';
import { formatDateToLocalTime } from '@util/Dates';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { getLastLoginAttempts } from '@network/Actions/Users';

interface IProps {
  email: string;
}

export default function LoginAttemptTable({ email }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastLoginAttempt, setLastLoginAttempt] = useState<any>([]);

  const getData = async () => {
    setIsLoading(true);

    getLastLoginAttempts(email)
      .then(({ data }) => {
        setLastLoginAttempt(() => {
          const arrayData = [];
          const successAttempt = data.successAttempt;
          successAttempt && arrayData.push(successAttempt);
          const failAttempt = data.failAttempt;
          failAttempt && arrayData.push(failAttempt);
          return arrayData;
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'address') {
      return `${(rowData.address && ',') || ''} ${
        (rowData.city && ',') || ''
      } ${rowData.country ?? ''}`;
    }
    if (item.field === 'position') {
      return `${(rowData.longitude && 'x') || ''}${rowData.latitude ?? ''}`;
    }
  };

  return (
    <DataTable
      value={lastLoginAttempt}
      responsiveLayout="scroll"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      dataKey="id"
      lazy
      loading={isLoading}
      stripedRows
      selectionPageOnly
      emptyMessage="No data found"
      className="datatable-responsive"
    >
      <Column field="id" sortable header={translate('adminTool.users.id')} />
      <Column
        field="loginName"
        sortable
        header={translate('adminTool.users.email')}
      />
      <Column field="ip" sortable header="ip" />
      <Column field="status" sortable header="status" />
      <Column field="address" sortable header="user-agent" />
      {/* <Column field="position" sortable header="position" body={labelChecker} /> */}

      <Column
        field="createdAt"
        sortable
        body={formatDateToLocalTime}
        header={translate('adminTool.users.createdAt')}
      />
      <Column
        field="updatedAt"
        body={formatDateToLocalTime}
        sortable
        header={translate('adminTool.users.updatedAt')}
      />
      <Column
        field="updatedBy"
        sortable
        header={translate('adminTool.users.updatedBy')}
      />
    </DataTable>
  );
}
