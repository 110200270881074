import Social from '@component/Social/Social';
import P, { H1 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '@images/logo.png';
import Morcik_confirm from '@images/morcik_confirm.png';
import './NewsletterConfirm.scss';

const NewsletterConfirm = () => {
  return (
    <>
      <div className="newsletter-confirm-section">
        <div className="newsletter-confirm_container container content padding20">
          <div className="row">
            <div className="col text">
              <div className="row">
                <Link className="m-auto" to={'/'}>
                  <img
                    src={Logo}
                    alt={translate('newsletter.alt')}
                    className="newsletter-confirm_logo logo"
                  />
                </Link>
              </div>
              <div className="row">
                <img
                  src={Morcik_confirm}
                  alt={translate('newsletter.alt')}
                  className="newsletter-confirm_img"
                />
              </div>

              <H1 className="newsletter-confirm_title">
                newsletterConfirm.title
              </H1>
              <P className="newsletter-confirm_subtitle">
                newsletterConfirm.content
              </P>

              <Social />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsletterConfirm;
