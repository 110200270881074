import React from 'react';
import './TextWithLink.style.scss';

const TextWithLink: React.FC<
  React.PropsWithChildren<{
    className?: string;
    regulationText: string;
    linkName: string;
    linkTo: string;
    linkTarget?: string;
    dataE2E?: string;
  }>
> = ({
  regulationText,
  linkName,
  linkTo,
  className,
  linkTarget = '_findbu_gs',
  dataE2E,
}) => {
  function splitText() {
    const splittedText = regulationText.split(linkName);
    return splittedText;
  }

  return (
    <a
      href={linkTo}
      target={linkTarget}
      data-e2e={dataE2E}
      className={'boxhead ' + className}
      rel="noreferrer noopener"
    >
      {splitText()[0]}
      <b>
        <u>{linkName}</u>
      </b>
      {splitText()[1]}
    </a>
  );
};

export default TextWithLink;
