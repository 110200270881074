import React from 'react';
import './BoxLockBlock.scss';
import { H2, H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

export function BoxLockBlock() {
  return (
    <article className="box-lock-section row no-gutters padded px-md-0">
      <section className="col-12 col-md-8 offset-md-2 text-center px-4 mb-5">
        <H2 className="pb-3 box-lock-section_title">
          {translate('howItWorks.boxLockBlock.notSure')}
        </H2>
        <H3 className="box-lock-section_subtitle">
          {translate('howItWorks.boxLockBlock.wePrepared')}
          <b>{translate('howItWorks.boxLockBlock.rushHelp')}</b>
          {translate('howItWorks.boxLockBlock.havePrepared')}
        </H3>
      </section>
      <div className="player">
        <iframe
          src="https://www.youtube.com/embed/oTCe0N46FNE"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </div>
    </article>
  );
}

export default BoxLockBlock;
