import {
  NUMBER_FAIL,
  NUMBER_REQUEST,
  NUMBER_SUCCESS,
} from '../Network/Actions/Types';
import { NumberConfirmationState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: Record<string, unknown>;
}
export const numberReducer = (
  state: NumberConfirmationState = {
    loading: false,
    data: {},
    error: null,
  },
  action: Action,
) => {
  switch (action.type) {
    case NUMBER_REQUEST:
      return { loading: true, data: null, error: null };
    case NUMBER_SUCCESS:
      return { loading: false, data: action.payload, error: null };
    case NUMBER_FAIL:
      return { loading: false, data: {}, error: action.payload };
    default:
      return state;
  }
};
