import React from 'react';
import './TimelineBlock.scss';
import { H3, P } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import img1m from '@images/customer/howto/howto-01-mobile.jpg';
import img1 from '@images/customer/howto/howto-01.jpg';
import img2m from '@images/customer/howto/howto-02-mobile.jpg';
import img2 from '@images/customer/howto/howto-02.jpg';
import img3m from '@images/customer/howto/howto-03-mobile.jpg';
import img3 from '@images/customer/howto/howto-03.jpg';
import img4m from '@images/customer/howto/howto-04-mobile.jpg';
import img4 from '@images/customer/howto/howto-04.jpg';
import img5m from '@images/customer/howto/howto-05-mobile.jpg';
import img5 from '@images/customer/howto/howto-05.png';
import img6m from '@images/customer/howto/howto-06-mobile.jpg';
import img6 from '@images/customer/howto/howto-06.jpg';
import img7m from '@images/customer/howto/howto-07-mobile.jpg';
import img7 from '@images/customer/howto/howto-07.jpg';
import img8m from '@images/customer/howto/howto-08-mobile.jpg';
import img8 from '@images/customer/howto/howto-08.jpg';
import imgt1 from '@images/customer/howto/timeline-icon-1.png';
import imgt2 from '@images/customer/howto/timeline-icon-2.png';
import imgt3 from '@images/customer/howto/timeline-icon-3.png';
import imgt4 from '@images/customer/howto/timeline-icon-4.png';
import imgt5 from '@images/customer/howto/timeline-icon-5.png';
import imgt6 from '@images/customer/howto/timeline-icon-6.png';
import imgt7 from '@images/customer/howto/timeline-icon-7.png';
import imgt8 from '@images/customer/howto/timeline-icon-8.png';
import { Link } from 'react-router-dom';
import { textFormater } from '@util/TextFormater';

export function TimelineBlock() {
  return (
    <article className="timeline-section px-lg-0">
      <div className="row no-gutters section-separator timeline-section_gray timeline-line-before px-2">
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-right border-dark py-4 dot-container">
          <div
            className="timeline-section_container d-flex timeline-column ml-auto"
            style={{ transform: 'translate(0%, -30%)' }}
          >
            <div className="layer-wrapper layer-wrapper--left">
              <div className="layer-1">
                <div className="timeline-lefty-image-wrapper">
                  <div className="position-relative">
                    <img
                      className="d-block d-lg-none"
                      src={img1m}
                      alt="timeLine"
                    />
                    <img
                      className="timeline-lefty-image d-none d-lg-block"
                      src={img1}
                      alt="timeLine"
                    />
                    <span className="timeline-text timeline-text--1">01</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-none d-lg-flex timeline-dot py-auto"
            style={{ transform: 'translateY(-50%)' }}
          >
            <img src={imgt1} className="d-flex mx-auto" alt="timeLine" />
          </div>
        </section>
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-left border-dark py-4">
          <div className="d-flex timeline-column w-100">
            <div className="timeline-righty">
              <H3 className="timeline-section_title d-flex text-primary fw700">
                howItWorks.timelineBlock.manyThings
              </H3>
              <P className="timeline-section_text">
                {textFormater(
                  translate('howItWorks.timelineBlock.moreFreeSpace'),
                )}
              </P>
            </div>
          </div>
        </section>
      </div>

      <div className="row no-gutters section-separator timeline-section_gray timeline-line-before px-2">
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-right border-dark py-4 md-order-1">
          <div className="d-flex timeline-column timeline-column--left w-100">
            <div className="timeline-lefty">
              <H3 className="timeline-section_title d-flex text-primary">
                howItWorks.timelineBlock.goWebsite
              </H3>
              <P className="timeline-section_text">
                {textFormater(
                  translate('howItWorks.timelineBlock.packagingProducts'),
                )}
              </P>
            </div>
          </div>
        </section>
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-left border-dark py-4 md-order-0 dot-container pb-50">
          <div
            className="timeline-section_container  d-flex timeline-column"
            style={{ transform: 'translate(0%, -10%)' }}
          >
            <div className="layer-wrapper layer-wrapper--right">
              <div className="layer-1">
                <div className="timeline-righty-image-wrapper">
                  <div className="position-relative">
                    <img
                      className="d-block d-lg-none"
                      src={img2m}
                      alt="timeLine"
                    />
                    <img
                      className="timeline-righty-image d-none d-lg-block"
                      src={img2}
                      alt="timeLine"
                    />
                    <span className="timeline-text timeline-text--2">02</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-none d-lg-flex timeline-dot py-auto"
            style={{ transform: 'translateY(30%)' }}
          >
            <img src={imgt2} className="d-flex mx-auto w-auto" alt="timeLine" />
          </div>
        </section>
      </div>

      <div className="row no-gutters section-separator bg-white timeline-line-before px-2 sec-3-mb">
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-right border-dark py-4 dot-container">
          <div
            className="timeline-section_container  d-flex timeline-column ml-auto"
            style={{ transform: 'translate(0%, -25%)' }}
          >
            <div className="layer-wrapper layer-wrapper--left">
              <div className="layer-1">
                <div className="timeline-lefty-image-wrapper">
                  <div className="position-relative">
                    <img
                      className="d-block d-lg-none"
                      src={img3m}
                      alt="timeLine"
                    />
                    <img
                      className="timeline-lefty-image d-none d-lg-block"
                      src={img3}
                      alt="timeLine"
                    />
                    <span className="timeline-text timeline-text--3">03</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-none d-lg-flex timeline-dot py-auto"
            style={{ transform: 'translateY(-50%)' }}
          >
            <img
              src={imgt3}
              className="d-flex mx-auto"
              style={{ maxWidth: '85px' }}
              alt="timeLine"
            />
          </div>
        </section>
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-left border-dark py-4 pb-5">
          <div className="d-flex timeline-column w-100">
            <div className="timeline-righty">
              <H3 className="timeline-section_title d-flex text-primary fs28 fw700">
                howItWorks.timelineBlock.bookAppointment
              </H3>
              <P className="timeline-section_text">
                {textFormater(
                  translate('howItWorks.timelineBlock.afterSelecting'),
                )}
              </P>
            </div>
          </div>
        </section>
      </div>

      <div className="row no-gutters section-separator bg-white timeline-line-before px-2">
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-right border-dark py-4 md-order-1">
          <div className="d-flex timeline-column timeline-column--left w-100">
            <div className="timeline-lefty">
              <H3 className="timeline-section_title d-flex text-primary fs28 fw700">
                howItWorks.timelineBlock.bringBoxes
              </H3>
              <P className="timeline-section_text">
                {textFormater(translate('howItWorks.timelineBlock.bringThem'))}
              </P>
            </div>
          </div>
        </section>
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-left border-dark py-4 md-order-0 dot-container pb-150">
          <div
            className="timeline-section_container  d-flex timeline-column"
            style={{ transform: 'translate(0%, -10%)' }}
          >
            <div className="layer-wrapper layer-wrapper--right">
              <div className="layer-1">
                <div className="timeline-righty-image-wrapper">
                  <div className="position-relative">
                    <img
                      className="d-block d-lg-none"
                      src={img4m}
                      alt="howItWorks"
                    />
                    <img
                      className="timeline-righty-image d-none d-lg-block"
                      src={img4}
                      alt="howitWorks"
                    />
                    <span className="timeline-text timeline-text--4">04</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-none d-lg-flex timeline-dot py-auto"
            style={{ transform: 'translateY(0%)' }}
          >
            <img
              src={imgt4}
              className="d-flex mx-auto"
              style={{ maxWidth: '100px' }}
              alt="emptyBox"
            />
          </div>
        </section>
      </div>

      <div className="row no-gutters section-separator timeline-section_cream timeline-line-before px-2">
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-right border-dark py-4 dot-container">
          <div
            className="timeline-section_container d-flex timeline-column ml-auto"
            style={{ transform: 'translate(0%, -22%)' }}
          >
            <div className="layer-wrapper layer-wrapper--left">
              <div className="layer-1">
                <div className="timeline-lefty-image-wrapper">
                  <div className="position-relative">
                    <img className="d-block d-lg-none" src={img5m} alt="box" />
                    <img
                      className="timeline-lefty-image d-none d-lg-block"
                      src={img5}
                      alt="peanutsBox"
                    />
                    <span className="timeline-text timeline-text--5">05</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-none d-lg-flex timeline-dot py-auto"
            style={{ transform: 'translateY(-50%)' }}
          >
            <img
              src={imgt5}
              className="d-flex mx-auto"
              style={{ maxWidth: '100px' }}
              alt="peanutBox"
            />
          </div>
        </section>
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-left border-dark py-4">
          <div className="d-flex timeline-column w-100">
            <div className="timeline-righty">
              <H3 className="timeline-section_title d-flex text-primary fs28 fw700">
                howItWorks.timelineBlock.packIt
              </H3>
              <P className="timeline-section_text">
                {textFormater(
                  translate('howItWorks.timelineBlock.rememberAbout.text'),
                )}
                <a
                  href="https://www.youtube.com/watch?v=oTCe0N46FNE"
                  target="_findbu_gs"
                  className="timeline-text-link"
                >
                  {translate('howItWorks.timelineBlock.rememberAbout.link')}
                </a>
                {textFormater(
                  translate('howItWorks.timelineBlock.rememberAbout.subtext'),
                )}
              </P>
            </div>
          </div>
        </section>
      </div>

      <div className="row no-gutters section-separator timeline-section_cream timeline-line-before px-2">
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-right border-dark py-4 md-order-1">
          <div className="d-flex timeline-column timeline-column--left w-100">
            <div className="timeline-lefty">
              <H3 className="timeline-section_title d-flex text-primary fs28 fw700">
                howItWorks.timelineBlock.pickUp
              </H3>
              <P className="timeline-section_text">
                {textFormater(translate('howItWorks.timelineBlock.whenReady'))}
              </P>
            </div>
          </div>
        </section>
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-left border-dark py-4 md-order-0 dot-container">
          <div
            className="timeline-section_container d-flex timeline-column sec-6-mb"
            style={{ transform: 'translate(0%, -10%)' }}
          >
            <div className="layer-wrapper layer-wrapper--right">
              <div className="layer-1">
                <div className="timeline-righty-image-wrapper">
                  <div className="position-relative">
                    <img
                      className="d-block d-lg-none"
                      src={img6m}
                      alt="truck"
                    />
                    <img
                      className="timeline-righty-image d-none d-lg-block"
                      src={img6}
                      alt="truck"
                    />
                    <span className="timeline-text timeline-text--6">06</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-none d-lg-flex timeline-dot py-auto"
            style={{ transform: 'translateY(0%)' }}
          >
            <img
              src={imgt6}
              className="d-flex mx-auto"
              style={{ maxWidth: '100px' }}
              alt="truck"
            />
          </div>
        </section>
      </div>

      <div className="row no-gutters section-separator bg-white timeline-line-before px-2">
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-right border-dark py-4 dot-container">
          <div
            className="timeline-section_container d-flex timeline-column ml-auto"
            style={{ transform: 'translate(0%, -25%)' }}
          >
            <div className="layer-wrapper layer-wrapper--left">
              <div className="layer-1">
                <div className="timeline-lefty-image-wrapper">
                  <div className="position-relative">
                    <img
                      className="d-block d-lg-none"
                      src={img7m}
                      alt="howItWorks"
                    />
                    <img
                      className="timeline-lefty-image d-none d-lg-block"
                      src={img7}
                      alt="howItWorks"
                    />
                    <span className="timeline-text timeline-text--7">07</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-none d-lg-flex timeline-dot py-auto"
            style={{ transform: 'translateY(-50%)' }}
          >
            <img
              src={imgt7}
              className="d-flex mx-auto"
              style={{ maxWidth: '100px' }}
              alt="storage"
            />
          </div>
        </section>
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-left border-dark py-4">
          <div className="d-flex timeline-column w-100">
            <div className="timeline-righty">
              <H3 className="timeline-section_title d-flex text-primary fs28 fw700">
                howItWorks.timelineBlock.storeIt
              </H3>
              <P className="timeline-section_text">
                {textFormater(
                  translate('howItWorks.timelineBlock.safelyTransported'),
                )}
              </P>
            </div>
          </div>
        </section>
      </div>

      <div className="row no-gutters section-separator bg-white step8 px-2">
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-dark py-4 md-order-1">
          <div className="d-flex timeline-column timeline-column--left w-100">
            <div className="timeline-lefty">
              <H3 className="timeline-section_title d-flex text-primary fs28 fw700">
                howItWorks.timelineBlock.refund
              </H3>
              <P className="timeline-section_text">
                {textFormater(
                  translate('howItWorks.timelineBlock.customerPanel'),
                )}
              </P>
            </div>
          </div>
        </section>
        <section className="col-12 px-2 px-lg-0 col-lg-6 d-flex border-dark py-4 md-order-0 dot-container">
          <div
            className="timeline-section_container d-flex timeline-column"
            style={{ transform: 'translate(0%, -5%)' }}
          >
            <div className="layer-wrapper layer-wrapper--right">
              <div className="layer-1">
                <div className="timeline-righty-image-wrapper">
                  <div className="position-relative">
                    <img
                      className="d-block d-lg-none"
                      src={img8m}
                      alt="howItWorks"
                    />
                    <img
                      className="timeline-righty-image d-none d-lg-block"
                      src={img8}
                      alt="howItWorks"
                    />
                    <span className="timeline-text timeline-text--8">08</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-none d-lg-flex top-0 timeline-dot py-auto"
            style={{ transform: 'translateY(0%)' }}
          >
            <img
              src={imgt8}
              className="d-flex mx-auto"
              style={{ maxWidth: '100px' }}
              alt="truck"
            />
          </div>
        </section>
      </div>
      <Link to="/zamowienie" className="timeline-link btn btn-large">
        {translate('howItWorks.boxLockBlock.skitrajIt')}
      </Link>
    </article>
  );
}

export default TimelineBlock;
