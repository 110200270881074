import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Users.scss';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@module/Translate/Translate';
import { UsersState } from '@type/Custom';
import { RootState } from '@src/store';
import { UserRawRoles } from '@models/user.model';
import { formatDateToLocalTime } from '@util/Dates';
import { UserProps } from '@view/AdminTool/interfaces/interfaces';
import EditUser from './components/EditUser';
import { getUsersAction } from '@network/Actions/Users';
import useDebounce from '@util/Debounse';
import FilterHeader from './components/components/filter/FilterHeader';

const LIMIT_ON_PAGE = 7;

type Props = UserProps;

export default function ArchivedUsers({ user }: Props) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const [filter, setFilter] = useState('');

  const { users }: UsersState = useSelector<RootState, UsersState>(
    ({ users }) => users,
  );
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
    isArchived: true,
    role: '',
    filter: '',
  });

  useEffect(() => {
    setLazyParams((e: any) => ({ ...e, filter, first: 0 }));
    getData(lazyParams);
    setMount(false);
  }, [useDebounce(filter, 200)]);

  useEffect(() => {
    if (mount) {
      getData(lazyParams);
      setMount(false);
    } else if (refresh) {
      getData(lazyParams);
      setRefresh(false);
    } else {
      getData(lazyParams);
    }
  }, [refresh, lazyParams]);
  const getData = (params: any) => {
    setLoading(true);

    dispatch(getUsersAction(params));
    setLoading(false);
  };

  const onPage = (event: any) => {
    setLazyParams({ ...event, role: '' });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event, role: '' });
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'firstName') {
      if (rowData.isCompanyAccount) {
        return rowData.companyDetails?.name ?? '';
      } else {
        return rowData.personalDetails?.firstName ?? '';
      }
    }
    if (item.field === 'lastName') {
      if (rowData.isCompanyAccount) {
        return rowData.companyDetails?.restOfName ?? '';
      } else {
        return rowData.personalDetails?.lastName ?? '';
      }
    }

    if (item.field === 'isActive') {
      return rowData[item.field]
        ? translate('adminTool.users.active')
        : translate('adminTool.users.notActive');
    }
  };

  const rowExpansionTemplate = (data: UserRawRoles) => {
    return <EditUser user={data} setRefresh={setRefresh} editor={user} />;
  };

  return (
    <div className="users-container">
      <DataTable
        value={users.items}
        header={() => (
          <FilterHeader
            inputPlaceholder="adminTool.common.searchByEmailAndId"
            setInputValue={setFilter}
            inputValue={filter}
          />
        )}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={loading}
        first={lazyParams.first}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        currentPageReportTemplate={translate(
          'adminTool.users.currentPageReportTemplate',
        )}
        rows={LIMIT_ON_PAGE}
        totalRecords={users.meta.totalItems}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        onPage={onPage}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="id" sortable header={translate('adminTool.users.id')} />
        <Column
          field="email"
          sortable
          header={translate('adminTool.users.email')}
        />
        <Column
          field="phone"
          sortable
          header={translate('adminTool.users.phone')}
        />
        <Column
          field="firstName"
          sortable
          header="firstName"
          body={labelChecker}
        />
        <Column
          field="lastName"
          sortable
          header="lastName"
          body={labelChecker}
        />
        <Column
          field="isActive"
          sortable
          header={translate('adminTool.users.isActive')}
          body={labelChecker}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.users.createdAt')}
        />
        <Column
          field="updatedAt"
          body={formatDateToLocalTime}
          sortable
          header={translate('adminTool.users.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.users.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
