import React from 'react';
import Background from '../../../../Asset/images/customer/landing/skitraj-zdj-glowne-baner.jpg';
import './PrimaryBlock.scss';
import { H1, H3 } from '../../../../Component/Text/Text';
import { translate } from '../../../../Module/Translate/Translate';
import { Link } from 'react-router-dom';

const PrimaryBlock = () => (
  <>
    <article className="primary-section">
      <div
        className="primary-section_container container"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <section className="pl-from-container">
          <div className="primary-section_text">
            <H1 className="welcomeText">
              homePage.primaryBlock.enjoyExtra
              <br />
              homePage.primaryBlock.placeYour
              <br />
              homePage.primaryBlock.apartment
            </H1>
            <H3 className="primaryBlock-text">
              homePage.primaryBlock.mobileLocker
              <br />
              homePage.primaryBlock.whichSafe
              <br />
              homePage.primaryBlock.yourBelongings
              <br />
            </H3>
          </div>
          <div className="primary-section_navigation">
            <Link
              to="/zamowienie"
              className="primary-section_btn btn btn-large"
              data-e2e="skitrajTo"
            >
              {translate('homePage.primaryBlock.skitrajIt')}
            </Link>
          </div>
        </section>
      </div>
    </article>
  </>
);

export default PrimaryBlock;
