import React, { useEffect, useState } from 'react';
import Button from '../../Component/Button/Button';
import { Link } from 'react-router-dom';
import './LoginForm.scss';
import FacebookAuth from './FacebookAuth';
import GoogleAuth from './GoogleAuth';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ATTEMPTS_INTERVAL, GOOGLE_CLIENT_ID } from '@/envConfig';
import { translate } from '@module/Translate/Translate';
import { AuthValidation } from '@rootTypes/auth/auth-validation.enum';
import { varietyOfPlural } from '@util/VarietyOfPlural';
import { Variety } from '@rootTypes/variety/variety-type.enum';
import AppleLoginButton from '@component/AppleLogin/AppleLogin';
const clientId: string | any = GOOGLE_CLIENT_ID;

function LoginForm({ userData, onSubmit, onLogout, validation }: any) {
  const [showPassword, setShowPassword] = useState(false);
  const inputTypeCondition = showPassword ? 'text' : 'password';
  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required(translate('errors.fieldRequired')),
      password: Yup.string().required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values: any, actions) => {
      onSubmit(values, actions);
    },
  });

  useEffect(() => {
    if (validation && validation.field) {
      formik.setFieldError(
        validation.field,
        translate(`errors.${validation.message}`),
      );
    }
  }, [validation]);

  const defaultAttemptsInterval = 1;

  if (!userData.user?.isActive) {
    return (
      <form className="login-form" onSubmit={formik.handleSubmit}>
        <div className="col-12 p-0">
          <div className="mb-lg-3">
            <label
              htmlFor="email"
              className={`d-sm-block label--orange ${
                formik.touched.email && formik.errors.email ? 'error-label' : ''
              }`}
            >
              {translate('registrationForm.email')}
            </label>
            <input
              type="email"
              name="email"
              className={`custom-input ${
                formik.touched.email && formik.errors.email ? 'error-input' : ''
              }`}
              id="email"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={translate('registrationForm.email')}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error-feedback" data-e2e="error-email">
                {`${formik.errors.email}`}
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="form-group">
            <label
              htmlFor="password"
              className={`d-sm-block label--orange ${
                formik.touched.password && formik.errors.password
                  ? 'error-label'
                  : ''
              }`}
            >
              {translate('registrationForm.password')}
            </label>
            <div className="form-password">
              <input
                type={inputTypeCondition}
                name="password"
                className={`custom-input ${
                  formik.touched.password && formik.errors.password
                    ? 'error-input'
                    : ''
                }`}
                id="password"
                autoComplete="off"
                required
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={translate('registrationForm.password')}
                value={formik.values.password}
              />
              <div
                className="form-password_button"
                onMouseDown={() => setShowPassword(true)}
                onTouchStart={() => setShowPassword(true)}
                onMouseUp={() => setShowPassword(false)}
                onMouseLeave={() => setShowPassword(false)}
                onTouchEnd={() => setShowPassword(false)}
              >
                {translate('registrationForm.show')}
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="error-feedback">{`${formik.errors.password}`}</div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="actions-wrapper">
          <div style={{ width: '100%' }}>
            <div className="forgot_password col-12 p-0 order-lg-3">
              <button
                type="submit"
                data-e2e="login"
                className="btn mt-3 btn-100"
              >
                {translate('registrationForm.login')}
              </button>
              {validation && !validation.field && (
                <label
                  className={`d-sm-block label--orange error-label`}
                  data-e2e="error-email"
                >
                  {validation.message === AuthValidation.TO_MANY_ATTEMPTS
                    ? `${translate(`errors.${validation.message}`)} ${
                        ATTEMPTS_INTERVAL
                          ? ATTEMPTS_INTERVAL
                          : defaultAttemptsInterval
                      } ${varietyOfPlural(
                        ATTEMPTS_INTERVAL
                          ? +ATTEMPTS_INTERVAL
                          : defaultAttemptsInterval,
                        Variety.hours,
                      )}`
                    : translate(`errors.${validation.message}`)}
                </label>
              )}
            </div>
            <div className="pointer-wrapper">
              <Link to="/verifyemail">
                <p className="pointer pointer-text" data-e2e="forgot-password">
                  {translate('registrationForm.forgotPassword')}
                </p>
              </Link>
            </div>
          </div>
          <div className="social-group">
            <p className="socialText">
              {translate('navigation.continueLogin')}
            </p>
            <div className="social-buttons">
              <GoogleOAuthProvider clientId={clientId}>
                <GoogleAuth loginOrRegister="login" />
              </GoogleOAuthProvider>
              <FacebookAuth loginOrRegister="login" />
              <AppleLoginButton disabled={false} loginOrRegister="login" />
            </div>
          </div>
        </div>
      </form>
    );
  } else {
    return (
      <div className="login-form">
        <Button
          className="contact-form_button button_accept"
          onClick={() => onLogout()}
          text="Wyloguj"
        />
      </div>
    );
  }
}

export default LoginForm;
