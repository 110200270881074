import React, { useEffect, useMemo, useState } from 'react';
import './ReceiveBoxesForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { addStocksInBasket } from '@network/Actions/Basket';
import { currentOrderAction } from '@network/Actions/CurrentOrder';
import { getRequestIdAction } from '@network/Actions/NumberConfirmation';
import { StockType } from '@rootTypes/stocks/stock-type.enum';
import { dateGetTime } from '@util/DateGetTime';
import unifiedFormatDate from '@util/Dates';
import { getLimitsAction } from '@network/Actions/Limits';
import { getOrderBlockedDaysAction } from '@network/Actions/OrderBlockedDays';
import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '@rootTypes/default-settings/default-settings-type.enum';
import CustomSelect from '@component/CustomSelect/CustomSelect';
import P, { H1, H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { FindOrderDto } from '@rootTypes/orders/find-order.dto';
import { StockStatus } from '@rootTypes/stocks/stock-status.enum';
import { UpdateStockDto } from '@rootTypes/stocks/update-stock.dto';
import { RootState } from '@src/store';
import {
  UserState,
  DefaultSettingsState,
  OrderBlockedDaysState,
} from '@type/Custom';
import { FindUserAddressDto } from '@rootTypes/user-addresses/find-user-address.dto';
import AddressInformation from '@component/AddressInformation/AddressInformation';
import { FindStockDto } from '@rootTypes/stocks/find-stock.dto';
import { Address } from '@models/user.model';
import EditAddressForm from '@form/EditAddressForm/EditAdressForm';
import { disabledDays } from '@util/DisabledDays';
import { selectedDaysArray } from '@util/SelectedDaysArray';
import Modal from '@component/Modal/Modal';
import { textFormaterOrder } from '@util/TextFormater';

interface Props {
  setShowConfirm: (value: boolean) => void;
  order: FindOrderDto;
  setVerifyReceiveReturnModal: (value: boolean) => void;
  setEditedBoxes: (box: any) => void;
  date: (date: Date) => void;
  amortizationTransportPrice: number;
  selectedBox: FindStockDto[];
  selectedAllBoxLength: boolean;
  showError: (value: boolean) => void;
}

const ReceiveBoxesForm: React.FC<React.PropsWithChildren<Props>> = ({
  setShowConfirm,
  order,
  setVerifyReceiveReturnModal,
  setEditedBoxes,
  date,
  amortizationTransportPrice,
  selectedBox,
  selectedAllBoxLength,
  showError,
}) => {
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const [editAdressModal, setEditAdressModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getOrderBlockedDaysAction());
    dispatch(getLimitsAction());
  }, []);

  const storageStocks = (stocks: FindStockDto[]) => {
    return stocks.filter((stock) => stock.stockType === StockType.STORAGE);
  };

  const getEndDateForCharge = (stocks: FindStockDto[]) => {
    const stock = storageStocks(stocks)[0];
    return stock.continuationDate ? stock.continuationDate : stock.endDate;
  };

  const { dates }: OrderBlockedDaysState = useSelector<
    RootState,
    OrderBlockedDaysState
  >(({ orderBlockedDates }) => orderBlockedDates);

  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  const defaultOrderClosingTime =
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultOrderClosingTime,
    )?.limit || UndefinedSettingsType.undefinedOrderClosingTime;

  const disabledAdminDays = dates.map((day) => day.date);

  const holidays = useMemo(
    () => disabledDays(disabledAdminDays),
    [disabledAdminDays],
  );

  const days = useMemo(() => {
    return selectedDaysArray({
      arrayLength: 6,
      holidays,
      defaultOrderClosingTime,
    });
  }, []);

  const weekday = [
    translate('returnBoxesForm.weekdays.0'),
    translate('returnBoxesForm.weekdays.1'),
    translate('returnBoxesForm.weekdays.2'),
    translate('returnBoxesForm.weekdays.3'),
    translate('returnBoxesForm.weekdays.4'),
    translate('returnBoxesForm.weekdays.5'),
    translate('returnBoxesForm.weekdays.6'),
  ];

  const [editAddressItem, setEditAddressItem] = useState({});

  const [deliveryAddress, setDeliveryAddresses] =
    useState<FindUserAddressDto>();

  function onEditDeliveryAddress(value: any) {
    setDeliveryAddresses(value);
  }

  const [pickedAddress, setPickedAddress] = useState<FindUserAddressDto>();

  function onEditPickedAddress(value: FindUserAddressDto) {
    setPickedAddress(value);
  }

  const editAddress = function (value: Address) {
    if (deliveryAddress === editAddressItem) {
      setDeliveryAddresses(value);
    }
    if (pickedAddress === editAddressItem) {
      setPickedAddress(value);
    }

    setEditAddressItem({});
  };
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [currentServiceDaysIndex, setCurrentServiceDaysIndex] =
    useState<any>(null);
  const [nextServiceDaysIndex, setNextServiceDaysIndex] = useState<any>(null);

  const handleSubmitBeforeDate = function () {
    if (selectedAllBoxLength && amortizationTransportPrice === 0) {
      const editedBoxes:
        | UpdateStockDto[]
        | {
            id: number;
            deliveryAddress: FindUserAddressDto | undefined;
            returnAddress: FindUserAddressDto | undefined;
            stockStatus: StockStatus;
            deliveryDate: Date;
          }[] = [];
      selectedBox.map((item: FindStockDto) => {
        editedBoxes.push({
          id: item.id,
          deliveryAddress: deliveryAddress,
          returnAddress: pickedAddress,
          stockStatus: item.stockStatus,
          deliveryDate: new Date(selectedDate),
        });
        setEditedBoxes(editedBoxes);
      });
      date(new Date(selectedDate));
      setVerifyReceiveReturnModal(true);
      setShowConfirm(false);
    } else {
      dispatch(
        currentOrderAction({
          deliveryAddress: deliveryAddress,
          returnedAddress: pickedAddress,
        }),
      );
      dispatch(
        addStocksInBasket({
          selectedBox,
          amortizationTransportPrice,
          selectedAllBox: selectedAllBoxLength,
          deliveryAddress: deliveryAddress,
          returnAddress: pickedAddress,
          orderId: order.id,
          date: selectedDate,
        }),
      );
      dispatch(
        getRequestIdAction(
          {
            phoneNumber: '48' + userData.user.phone,
          },
          () => showError(true),
        ),
      );
      history.push({
        pathname: '/return-boxes',
        state: {
          activeStep: 9,
        },
      });
    }
  };

  const handleSubmitAfterDate = function () {
    const editedBoxes:
      | UpdateStockDto[]
      | {
          id: number;
          deliveryAddress: FindUserAddressDto | undefined;
          returnAddress: FindUserAddressDto | undefined;
          stockStatus: StockStatus;
          deliveryDate: Date;
        }[] = [];
    selectedBox.map((item: FindStockDto) => {
      editedBoxes.push({
        id: item.id,
        deliveryAddress: deliveryAddress,
        returnAddress: pickedAddress,
        stockStatus: item.stockStatus,
        deliveryDate: new Date(selectedDate),
      });
      setEditedBoxes(editedBoxes);
    });
    date(new Date(selectedDate));
    setVerifyReceiveReturnModal(true);
    setShowConfirm(false);
  };

  const currentServiceDays = days.filter(
    (day) =>
      dateGetTime(day) <=
      dateGetTime(new Date(storageStocks(order.stocks)[0].endDate)),
  );
  const nextServiceDays = days.filter(
    (day) =>
      dateGetTime(day) >
      dateGetTime(new Date(storageStocks(order.stocks)[0].endDate)),
  );
  //TODO odebranie pudełek przed upływem umowy, do ustalenia data
  const isBeforeDate =
    dateGetTime(new Date()) <
    dateGetTime(new Date(storageStocks(order.stocks)[0].endDate));
  const boxesList = selectedBox
    .map((i, index) => (index > 0 ? '/' : '') + i.stockNumber)
    .toString();

  return (
    <>
      {Object.keys(editAddressItem).length !== 0 && (
        <Modal
          showModal={editAdressModal}
          setShowModal={setEditAdressModal}
          className="dialog--middle"
        >
          <EditAddressForm
            item={editAddressItem}
            editAddress={editAddress}
            editItem={true}
            setShowModal={(value: boolean) => setEditAdressModal(value)}
          />
        </Modal>
      )}
      <div className="receive-boxes">
        <H1 className="receive-boxes_title">
          {translate('receiveBoxesForm.receiveTheBoxes')}
        </H1>
        <H3 className="receive-boxes_storage-title text-left mb-1">
          {translate('receiveBoxesForm.storage')}
        </H3>
        {selectedBox && (
          <>
            <H3 className="receive-boxes_list text-left mb-3">
              <span>{selectedBox.length}</span>
              <span>x {translate('receiveBoxesForm.box')}</span>
            </H3>
            <H3 className="receive-boxes_storage-list text-left mb-3">
              <span className="list-text">
                {textFormaterOrder(boxesList, 72)}
              </span>
            </H3>
          </>
        )}
        <div className="receive-boxes_selected row d-flex pr-0 pl-0 mb-4">
          <div className="delivery-address col order-0 col-12 col-md-6">
            <H1 className="delivery-address_title ">
              {translate('receiveBoxesForm.boxDeliveryAddress')}
            </H1>

            <P className="delivery-address_subtitle"></P>

            <div className="delivery-address_field">
              <CustomSelect
                placeholder={translate('addressStep.chooseAddress')}
                options={userData.user.addresses}
                onChange={onEditDeliveryAddress}
              />
            </div>
            <div className="mt-3">
              <AddressInformation
                item={deliveryAddress}
                onEdit={() => {
                  setEditAddressItem(deliveryAddress ? deliveryAddress : {});
                  setEditAdressModal(true);
                }}
              />
            </div>
          </div>

          <div className="pickup-address col col-12 col-md-6">
            <H1 className="pickup-address_title">
              {translate('receiveBoxesForm.boxPickupAddress')}
            </H1>

            <P className="pickup-address_subtitle">
              {translate('receiveBoxesForm.notPossibleChange')}
            </P>

            <div className="pickup-address_field">
              <CustomSelect
                placeholder={translate('addressStep.chooseAddress')}
                options={userData.user.addresses}
                onChange={onEditPickedAddress}
              />
            </div>
            <div className="mt-3">
              <AddressInformation
                item={pickedAddress}
                onEdit={() => {
                  setEditAddressItem(pickedAddress ? pickedAddress : {});
                  setEditAdressModal(true);
                }}
              />
            </div>
          </div>
        </div>

        <div className="mb-4">
          <H3 className="receive-boxes_title">
            {translate('receiveBoxesForm.deadline')}
          </H3>
          <P className="receive-boxes_date-subtitle">
            {translate('receiveBoxesForm.chooseDate')}
          </P>
          {currentServiceDays && currentServiceDays.length !== 0 && (
            <div className="delivery-date row">
              {currentServiceDays.map((item, index) => (
                <div key={index} className="delivery-date_col col">
                  <div
                    className={`delivery-date_item text-center ${
                      index === currentServiceDaysIndex ? 'active' : ''
                    }`}
                    onClick={() => {
                      setNextServiceDaysIndex(null);
                      setCurrentServiceDaysIndex(index);
                      setSelectedDate(item);
                    }}
                  >
                    {weekday[item.getDay()]} <br />
                    {unifiedFormatDate(item)}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {nextServiceDays && nextServiceDays.length !== 0 && (
          <div className="mb-4">
            <P className="text-left">
              {translate('receiveBoxesForm.choosingDatesAfter')}&nbsp;
              {unifiedFormatDate(
                //new Date(storageStocks(order.stocks)[0].endDate),
                new Date(getEndDateForCharge(order.stocks)),
              )}
              ,&nbsp;
              {translate('receiveBoxesForm.weWillAddAFee')}
              <br />
              {translate('receiveBoxesForm.storageOfBoxes')}
            </P>

            <div className="next-month-date row">
              {nextServiceDays.map((item, index) => (
                <div key={index} className="col">
                  <div
                    className={`next-month-date_item text-center ${
                      index === nextServiceDaysIndex ? 'active' : ''
                    }`}
                    onClick={() => {
                      setCurrentServiceDaysIndex(null);
                      setNextServiceDaysIndex(index);
                      setSelectedDate(item);
                    }}
                  >
                    {weekday[item.getDay()]} <br />
                    {unifiedFormatDate(item)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {isBeforeDate ? (
          selectedAllBoxLength && amortizationTransportPrice === 0 ? (
            <button
              type="submit"
              className="contact-form_button btn mx-auto w-100"
              disabled={!selectedDate || !deliveryAddress || !pickedAddress}
              onClick={handleSubmitBeforeDate}
            >
              {translate('receiveBoxesForm.sendAMessage')}
            </button>
          ) : (
            <button
              type="submit"
              className="contact-form_button btn mx-auto w-100"
              disabled={!selectedDate || !deliveryAddress || !pickedAddress}
              onClick={handleSubmitBeforeDate}
            >
              {translate('receiveBoxesForm.buttonCourier')}
            </button>
          )
        ) : (
          <button
            type="submit"
            className="contact-form_button btn mx-auto w-100"
            disabled={!selectedDate || !deliveryAddress || !pickedAddress}
            onClick={handleSubmitAfterDate}
          >
            {translate('receiveBoxesForm.sendAMessage')}
          </button>
        )}
      </div>
    </>
  );
};

export default ReceiveBoxesForm;
