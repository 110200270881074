import React, { useRef } from 'react';
import './EditTransportPrice.scss';
import { translate } from '../../../../../../Module/Translate/Translate';
import { H5 } from '../../../../../../Component/Text/Text';
import { useFormik } from 'formik';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { editTransportAction } from '../../../../../../Network/Actions/TransportPrice';
import { Messages } from 'primereact/messages';

export default function EditTransportPrice({
  transport,
  setRefresh,
  editor,
}: any) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      price: transport ? transport.price : '',
      updatedBy: editor.email,
    },
    onSubmit: (values: any) => {
      editTransportAction(transport.id, {
        price: values.price,
        updatedBy: values.updatedBy,
      })
        .then(() => {
          setRefresh(true);
          showMessage('success', translate('default.message.success'));
        })
        .catch((error: any) => {
          showMessage('error', translate('default.message.fail') + error);
        });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  return (
    <div className="transport-main-container border-details">
      <div className="default-settings-header-container">
        <Messages ref={message} />
      </div>
      <div className="transport-data-container">
        <div className="transport-top-container">
          <div className="transport-info-left-container">
            <H5 className="transport-h5">
              {translate('adminTool.transports.name')}
            </H5>
            <p>{transport.type}</p>
          </div>
          <div className="transport-info-right-container">
            <form className="transport-form" onSubmit={formik.handleSubmit}>
              <FormFieldFormik
                name="price"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.price}
                label={translate('adminTool.transports.value')}
                onBlur={formik.handleBlur}
              />
              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.values.price !== transport.price ? '' : 'inactive'
                }`}
              >
                {translate('adminTool.transports.btn')}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
