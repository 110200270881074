import React from 'react';
import './OrderDetailForm.scss';
import { translate } from '../../Module/Translate/Translate';
import { FindOrderDto } from '../../../../types/orders/find-order.dto';
import { FindStockDto } from '../../../../types/stocks/find-stock.dto';
import { StockStatus } from '../../../../types/stocks/stock-status.enum';
import AddressInformation from '../../Component/AddressInformation/AddressInformation';
import unifiedFormatDate from '../../Util/Dates';

interface Props {
  setShowConfirm: any;
  data: FindOrderDto;
  stocks: FindStockDto[];
  additionalStorePeriodPrice: number;
}

const showProducts = (stocks: FindStockDto[]) => {
  const products: Record<string, any>[] = [];

  stocks.map((stock: FindStockDto) => {
    stock.products.map((product: any) => {
      const stockId = stock.stockNumber
        ? stock.stockNumber
        : translate('orderDetailForm.missingStackNumber');

      if (products.length === 0) {
        products.push({
          quantity: product.quantity,
          name: product.name,
          id: product.id,
          stockNumber: stockId,
        });
        return;
      }

      const box = products.find((item) => item.id === product.id);

      if (!box) {
        products.push({
          quantity: product.quantity,
          name: product.name,
          id: product.id,
          stockNumber: stockId,
        });
      } else {
        products.map((item) => {
          if (item.id === product.id) {
            item.quantity = item.quantity + product.quantity;
            item.stockNumber = `${item.stockNumber}/${stockId}`;
            return item;
          }
        });
      }
    });
  });

  return products;
};

const OrderDetailForm: React.FC<React.PropsWithChildren<Props>> = ({
  setShowConfirm,
  data,
  stocks,
  additionalStorePeriodPrice,
}) => {
  const isAnyStockWithReturnDate = data.stocks.find((item: FindStockDto) => {
    if (item.stockStatus === StockStatus.STOCK_STTC && item.deliveryDate) {
      return item;
    }
  });
  return (
    <div>
      <div className="order-detail-form">
        <div className="order-detail-form_title">
          <p className="order-detail-form_title-id">{data.id}</p>
          <p className="order-detail-form_title-text">
            {isAnyStockWithReturnDate &&
              translate('orderDetailForm.youWillHaveTheBoxes') +
                ' ' +
                unifiedFormatDate(
                  new Date(isAnyStockWithReturnDate.deliveryDate),
                )}
          </p>
        </div>
        <div>
          {showProducts(stocks).map((item) => (
            <div key={item.id} className="order-detail-form order-item">
              <span className="order-detail-form order-item_title">
                {`${item.quantity}x ${item.name}: `}
              </span>
              <span>{item.stockNumber}</span>
            </div>
          ))}
        </div>
        <div className="order-detail-form_storage-data">
          <p className="order-detail-form_sub-title">
            {translate('orderDetailForm.storageTime')}
          </p>
          <p className="textOrange">{`${unifiedFormatDate(
            new Date(stocks[0].startDate),
          )}-${unifiedFormatDate(
            new Date(
              stocks[0].continuationDate
                ? stocks[0].continuationDate
                : stocks[0].endDate,
            ),
          )}`}</p>
        </div>
        <div className="month-wrapper">
          <p className="order-detail-form_sub-title">
            {translate('orderDetailForm.monthlyCost')}
          </p>
          <p className="textOrange">{`${additionalStorePeriodPrice.toFixed(
            2,
          )} ${translate('default.currency')}`}</p>
        </div>

        <div className="order-detail-form_address row">
          <div className="address-wrapper col col-12 col-md-6">
            <p className="order-detail-form_sub-title">
              {translate('orderDetailForm.boxDeliveryAddress')}
            </p>
            <AddressInformation item={stocks[0].deliveryAddress} />
          </div>
          <div className="address-wrapper col col-12 col-md-6">
            <p className="order-detail-form_sub-title">
              {translate('orderDetailForm.boxPickupAddress')}
            </p>
            <AddressInformation item={stocks[0].returnAddress} />
          </div>
        </div>

        <button
          className="btn order-detail-form_btn w-100"
          onClick={() => setShowConfirm(false)}
        >
          {translate('orderDetailForm.comeback')}
        </button>
      </div>
    </div>
  );
};

export default OrderDetailForm;
