import React from 'react';
import './CompletedSentForm.scss';
import CompletedIcon from '@images/completedProcess.png';
import P, { H1 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

interface Props {
  setShowConfirm: (confirm: boolean) => void;
  message?: string;
  title?: string;
  buttonText?: string;
  buttonOnClick?: any;
}

function CompletedSentForm({
  setShowConfirm,
  title,
  message,
  buttonText,
  buttonOnClick,
}: Props) {
  return (
    <>
      <div className="completed-sent-form" data-e2e="completedSentForm">
        <img
          src={CompletedIcon}
          className="completed-sent-form_img"
          alt={translate('completedSentForm.completed')}
        />

        <H1 className="completed-sent-form_title" data-e2e="messageTitle">
          {title ? title : translate('completedSentForm.messageSent')}
        </H1>
        <P className="completed-sent-form_message " data-e2e="messageText">
          {message ? message : translate('completedSentForm.message')}
        </P>

        <button
          type="submit"
          data-e2e="closeForm"
          className="btn mx-auto w-100 contact-form_button"
          onClick={() => {
            setShowConfirm(false);
            buttonOnClick && buttonOnClick(true);
          }}
        >
          {buttonText ? buttonText : translate('completedSentForm.closeForm')}
        </button>
      </div>
    </>
  );
}

export default CompletedSentForm;
