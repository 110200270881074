import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import P24 from '@images/registration/P24.png';
import Blik from '@images/registration/Blik.png';
import Card from '@images/card/card.png';

import './SummaryCard.scss';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { CurrentOrderState } from '@type/Custom';
import { RootState } from '@src/store';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import PaymentCardSmall from '@component/PaymentCardSmall/PaymentCardSmall';
import { translate } from '@module/Translate/Translate';

const SummaryCard = () => {
  const history = useHistory();
  const location = useLocation();
  const { order }: CurrentOrderState = useSelector<
    RootState,
    CurrentOrderState
  >(({ currentOrder }) => currentOrder);

  const paymentIcon = (function () {
    let currentPaymentType;

    switch (order.paymentType) {
      case PaymentMethodEnum.P24:
        currentPaymentType = P24;
        break;
      case PaymentMethodEnum.BLIK:
        currentPaymentType = Blik;
        break;
      case PaymentMethodEnum.CARD:
        currentPaymentType = Card;
        break;
      default:
        currentPaymentType = P24;
        break;
    }

    return currentPaymentType;
  })();

  const onEditCard = useCallback(() => {
    history.push({
      ...location,
      state: {
        activeStep: 4,
      },
    });
  }, [history, location]);

  return (
    <>
      <div className="summary-card px-0">
        <PaymentCardSmall src={paymentIcon} />
        <div className="mt-3">
          <button onClick={onEditCard}>{translate('summaryCard.edit')}</button>
        </div>
      </div>
    </>
  );
};

export default SummaryCard;
