import {
  PAYMENT_HISTORY_FAIL,
  PAYMENT_HISTORY_REQUEST,
  PAYMENT_HISTORY_SUCCESS,
} from '../Network/Actions/Types';
import { PaymentHistoryState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const paymentHistoryReducer = (
  state: PaymentHistoryState = { loading: false, history: [], error: null },
  action: Action,
) => {
  switch (action.type) {
    case PAYMENT_HISTORY_REQUEST:
      return { loading: true, history: state.history, error: null };
    case PAYMENT_HISTORY_SUCCESS:
      return { loading: false, history: action.payload, error: null };
    case PAYMENT_HISTORY_FAIL:
      return { loading: false, history: state.history, error: action.payload };
    default:
      return state;
  }
};
