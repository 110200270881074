import BillingInformation from '@component/BillingInformation/BillingInformation';
import { H3 } from '@component/Text/Text';
import BillingForm from '@form/BillingForm/BillingForm';
import CompanyBillingForm from '@form/CompanyBillingForm/CompanyBillingForm';
import PaymentForm from '@form/PaymentForm/PaymentForm';
import { translate } from '@module/Translate/Translate';
import { currentOrderAction } from '@network/Actions/CurrentOrder';
import {
  editUserCompanyDetailsAction,
  editUserPersonalDetailsAction,
  currentUserAction,
  getUserSettingsAction,
} from '@network/Actions/CurrentUser';
import { changeUserPaymentMethod } from '@network/Actions/Payment';
import { FindCompanyDetailsDto } from '@rootTypes/comapny-details/find-company-details.dto';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import { FindPersonalDetailsDto } from '@rootTypes/personal-details/find-personal-details.dto';
import { UserValidation } from '@rootTypes/users/user-validation.enum';
import { checkoutEvent } from '@src/GTM/GTM';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import PaymentCard from './components/PaymentCard/PaymentCard';
import { paymentData } from './payment-data';

import './PaymentStep.scss';
export const ValidateFormContext = React.createContext({});

interface IProps {
  showError: (value: boolean) => void;
  visibleSpinner: (value: boolean) => void;
  setIsNipError?: (value: boolean) => void;
}

const PaymentStep = ({ showError, visibleSpinner, setIsNipError }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const dispatch = useDispatch();
  const [isOpenEditForm, setIsOpenEditForm] = useState(false);

  const [activePaymentType, setActivePaymentType] = useState(
    PaymentMethodEnum.P24,
  );

  const [isSubmitButtonDisable, setIsSubmitButtonDisable] =
    useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [
    isPermissionForPayingInTheFutureGranted,
    setIsPermissionForPayingInTheFutureGranted,
  ] = useState<boolean>(false);

  const isCompany = userData.user.isCompanyAccount;

  const [showEditAddress, setShowEditAddress] = useState(false);
  const [addressesCompany, setAddressesCompany] =
    useState<FindCompanyDetailsDto>();

  const [addressesPersonal, setAddressesPersonal] =
    useState<FindPersonalDetailsDto>();

  useEffect(() => {
    isCompany
      ? setAddressesCompany(userData.user.companyDetails)
      : setAddressesPersonal(userData.user.personalDetails);

    if (isCompany) {
      const addressesCompany = userData.user.companyDetails;
      if (addressesCompany) {
        const check =
          addressesCompany.name &&
          addressesCompany.street &&
          addressesCompany.city &&
          addressesCompany.postalCode &&
          addressesCompany.taxNumber;
        if (check && !isSubmitButtonDisable) {
          setIsValidated(true);
        } else {
          setIsValidated(false);
        }
      }
    } else {
      const addressesPersonal = userData.user.personalDetails;
      if (addressesPersonal) {
        const check =
          addressesPersonal.firstName &&
          addressesPersonal.lastName &&
          addressesPersonal.street &&
          addressesPersonal.city &&
          addressesPersonal.postalCode;
        if (check && !isSubmitButtonDisable) {
          setIsValidated(true);
        } else {
          setIsValidated(false);
        }
      }
    }
    checkoutEvent(4);
    visibleSpinner(true);
    getUserSettingsAction(userData.user.id)
      .then(({ data }) => {
        setActivePaymentType(
          data.paymentMethod ? data.paymentMethod : PaymentMethodEnum.P24,
        );
        setIsChecked(data.isPermissionForPayingInTheFutureGranted);
      })
      .catch(() => setActivePaymentType(PaymentMethodEnum.P24))
      .finally(() => visibleSpinner(false));
  }, []);

  function onEditAddress(value: any) {
    visibleSpinner(true);
    dispatch(
      currentOrderAction({
        billing: value,
      }),
    );
    if (isCompany) {
      setAddressesCompany(value);
      editUserCompanyDetailsAction(
        userData.user.id,
        userData.user.companyDetails?.id,
        value,
      )
        .then((v) => {
          setShowEditAddress(false);
        })
        .catch((error) => {
          if (
            error.response.data.message.error.message ===
              UserValidation.WRONG_TAX_NUMBER &&
            setIsNipError
          ) {
            setIsNipError(true);
            setIsOpenEditForm(true);
          }
          showError(true);
        })
        .finally(() => {
          visibleSpinner(false);
        });
    } else {
      setAddressesPersonal(value);
      editUserPersonalDetailsAction(
        userData.user.id,
        userData.user.personalDetails?.id,
        value,
      )
        .catch(() => {
          showError(true);
        })
        .finally(() => {
          visibleSpinner(false);
          setShowEditAddress(false);
        });
    }
    dispatch(currentUserAction());
  }

  const onSubmit = () => {
    if (isCompany) {
      addressesCompany &&
        dispatch(
          currentOrderAction({
            billing: {
              name: addressesCompany.name,
              restOfName: addressesCompany.restOfName || '',
              street: addressesCompany.street,
              city: addressesCompany.city,
              postalCode: addressesCompany.postalCode,
              taxNumber: +addressesCompany.taxNumber,
            },
            paymentType: activePaymentType,
            isPermissionForPayingInTheFutureGranted:
              isPermissionForPayingInTheFutureGranted,
          }),
        );
    } else {
      addressesPersonal &&
        dispatch(
          currentOrderAction({
            billing: {
              firstName: addressesPersonal.firstName,
              lastName: addressesPersonal.lastName,
              street: addressesPersonal.street,
              city: addressesPersonal.city,
              postalCode: addressesPersonal.postalCode,
              taxNumber: null,
            },
            paymentType: activePaymentType,
          }),
        );
    }

    dispatch(
      changeUserPaymentMethod(
        userData.user.id,
        activePaymentType,
        isPermissionForPayingInTheFutureGranted,
        () => showError(true),
      ),
    );

    history.push({
      ...location,
      state: {
        activeStep: 5,
      },
    });
  };

  const validateForm = (isValidated: boolean) => {
    setIsValidated(isValidated);
  };

  return (
    <>
      <div className="payment-step d-flex">
        <ValidateFormContext.Provider value={validateForm}>
          <div className="payment-step_container">
            <div>
              {isCompany ? (
                <H3 className="payment-step_title font-weight-bold">
                  {translate('paymentStep.billing')}
                </H3>
              ) : (
                <H3 className="payment-step_title font-weight-bold">
                  {translate('paymentStep.billingPrivate')}
                </H3>
              )}

              {isCompany &&
                (showEditAddress || !isValidated ? (
                  <CompanyBillingForm
                    item={addressesCompany}
                    onEditAddress={(value) => {
                      onEditAddress(value);
                      setIsOpenEditForm(false);
                    }}
                    showError={showError}
                    visibleSpinner={visibleSpinner}
                    setIsNipError={setIsNipError}
                  />
                ) : (
                  <div className="orders pt-4 address">
                    <div className="orders_container w-100 col-12 mb-5 px-0">
                      <BillingInformation
                        item={addressesCompany}
                        onEdit={() => {
                          setShowEditAddress(true);
                          setIsOpenEditForm(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              {!isCompany &&
                (showEditAddress || !isValidated ? (
                  <BillingForm
                    item={addressesPersonal}
                    onEditAddress={(value: Record<string, string | number>) => {
                      onEditAddress(value);
                      setIsOpenEditForm(false);
                    }}
                  />
                ) : (
                  <div className="orders pt-4 address">
                    <div className="orders_container w-100 col-12 mb-5 px-0">
                      <BillingInformation
                        item={addressesPersonal}
                        onEdit={() => {
                          setShowEditAddress(true);
                          setIsOpenEditForm(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>

            <div>
              <H3 className="payment-step_title font-weight-bold">
                {translate('paymentStep.paymentType')}
              </H3>

              <div className="orders pt-4">
                <div className="orders_container d-flex flex-wrap w-100">
                  {paymentData.paymentMethod
                    .filter((item: any) => {
                      if (item.id !== PaymentMethodEnum.CARD) {
                        return item;
                      } else {
                        if (process.env.CARD_PAYMENT === 'true') {
                          return item;
                        }
                      }
                    })
                    .map((item, index) => (
                      <PaymentCard
                        key={index}
                        index={item.id}
                        item={item}
                        active={item.id === activePaymentType}
                        onSetActive={() => setActivePaymentType(item.id)}
                        setIsSubmitButtonDisable={(value: boolean) => {
                          setIsSubmitButtonDisable(value);
                        }}
                        isChecked={isChecked}
                        setIsChecked={(value: boolean) => {
                          setIsChecked(value);
                        }}
                        setIsPermissionForPayingInTheFutureGranted={(
                          value: boolean,
                        ) => setIsPermissionForPayingInTheFutureGranted(value)}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="payment-step_form-container">
            <PaymentForm
              onSubmit={onSubmit}
              isValidated={!isValidated || isOpenEditForm}
              isSubmitButtonDisable={isSubmitButtonDisable}
            />
          </div>
        </ValidateFormContext.Provider>
      </div>
    </>
  );
};

export default PaymentStep;
