import React from 'react';
import './MessagesList.scss';
import MessageItem from '../MessageItem/MessageItem';
import { useDispatch } from 'react-redux';
import { User } from '@models/user.model';
import {
  updateNotificationAction,
  decrementUnreadNotificationsAction,
} from '@network/Actions/Notifications';
import { FindNotificationDto } from '@rootTypes/notifications/find-notification.dto';

interface IProps {
  data: FindNotificationDto[];
  user: User;
  setShowError: (value: boolean) => void;
}

function MessagesList({ data, user, setShowError }: IProps) {
  const dispatch = useDispatch();
  const onMessageClick = async (
    notification: FindNotificationDto,
    index: number,
  ) => {
    if (notification.isRead) return;
    await dispatch(
      updateNotificationAction(
        user.id,
        notification.id,
        { isRead: true },
        index,
        error,
      ),
    );
    dispatch(decrementUnreadNotificationsAction());
  };

  const error = () => {
    setShowError(true);
  };

  return (
    <ul className="message-list">
      {data.map((item: FindNotificationDto, index: number) => (
        <li
          key={index}
          className={`message-list_item ${!item.isRead ? '' : 'opened'}`}
          onClick={() => onMessageClick(item, index)}
        >
          <MessageItem
            type={item.type}
            message={item.message}
            title={item.title}
            createdAt={item.createdAt}
            isRead={item.isRead}
            id={item.id}
            isLinkToShow={item.isLinkToShow}
          />
        </li>
      ))}
    </ul>
  );
}

export default MessagesList;
