import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import P24 from '../../../..//Asset/images/registration/P24.png';
import Blik from '../../../..//Asset/images/registration/Blik.png';
import Card from '../../../..//Asset/images/card/card.png';
import './Payments.scss';
import { translate } from '@module/Translate/Translate';
import { getAllPayments } from '@network/Actions/Payment';
import { FindPaymentAdminDTO } from '@rootTypes/payments/dto/find-payment-admin.dto';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import { PaymentStatusEnum } from '@rootTypes/payments/enums/payment-status.enum';
import { PaymentTypeEnum } from '@rootTypes/payments/enums/payment-type.enum';
import { RootState } from '@src/store';
import { PaymentPaginationState } from '@type/Custom';
import { formatDateToLocalTime } from '@util/Dates';
import useDebounce from '@util/Debounse';
import { UserProps } from '@view/AdminTool/interfaces/interfaces';
import { InputText } from 'primereact/inputtext';
import EditPayments from './EditPayments/EditPayments';

const LIMIT_ON_PAGE = 10;

export default function Payments({ user }: UserProps) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const [searched, setSearched] = useState('');

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
  });
  const { payments }: PaymentPaginationState = useSelector<
    RootState,
    PaymentPaginationState
  >((state) => state.payments);

  useEffect(() => {
    getData({ ...lazyParams, orderNumber: searched });
    setRefresh(false);
  }, [refresh]);

  const getData = async (params: {
    rows: number;
    first: number;
    orderNumber: string;
    sortField: string;
    sortOrder: number | null;
  }) => {
    setLoading(true);
    dispatch(getAllPayments(params));
    setLoading(false);
  };
  useEffect(() => {
    if (mount) {
      getData({ ...lazyParams, orderNumber: searched });
      setMount(false);
    } else if (refresh) {
      getData({ ...lazyParams, orderNumber: searched });
      setRefresh(false);
    } else {
      getData({ ...lazyParams, orderNumber: searched });
    }
  }, [refresh, lazyParams, useDebounce(searched, 500)]);

  const rowExpansionTemplate = (data: any) => {
    return (
      <EditPayments payments={data} setRefresh={setRefresh} editor={user} />
    );
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div></div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={searched}
            onChange={(e: any) => {
              setSearched(e.target.value);
            }}
            placeholder={translate('adminTool.payments.search')}
          />
        </span>
      </div>
    );
  };

  const imageBody = (data: FindPaymentAdminDTO) => {
    switch (data.method) {
      case PaymentMethodEnum.BLIK:
        return <img src={Blik} alt="Blik" className="adminTool-payments_img" />;
      case PaymentMethodEnum.P24:
        return <img src={P24} alt="P24" className="adminTool-payments_img" />;
      case PaymentMethodEnum.CARD:
        return <img src={Card} alt="Card" className="adminTool-payments_img" />;
      default:
        return null;
    }
  };
  const statusBody = (data: FindPaymentAdminDTO) => {
    switch (data.status) {
      case PaymentStatusEnum.cancelled:
        return translate('adminTool.payments.cancelled');
      case PaymentStatusEnum.success:
        return translate('adminTool.payments.success');
      case PaymentStatusEnum.failed:
        return translate('adminTool.payments.failed');
      case PaymentStatusEnum.pending:
        return translate('adminTool.payments.pending');
      default:
        return null;
    }
  };
  const typeBody = (data: FindPaymentAdminDTO) => {
    switch (data.type) {
      case PaymentTypeEnum.recurrent:
        return translate('adminTool.payments.recurrentPay');
      case PaymentTypeEnum.single:
        return translate('adminTool.payments.singlePay');
      case PaymentTypeEnum.transport:
        return translate('adminTool.payments.transportPay');
      default:
        return null;
    }
  };

  const onPage = (event: any) => {
    setLazyParams({ ...event });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event });
  };

  return (
    <div className="products-table-container">
      <DataTable
        value={payments.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={loading}
        first={lazyParams.first}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} payments"
        rows={LIMIT_ON_PAGE}
        totalRecords={payments.meta.totalItems}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        onPage={onPage}
        header={renderHeader}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
      >
        <Column expander style={{ width: '3em' }} />
        <Column
          field="id"
          sortable
          header={translate('adminTool.payments.id')}
        />
        <Column
          field="orderId"
          header={translate('adminTool.payments.orderId')}
        />
        <Column
          field="email"
          sortable
          header={translate('adminTool.payments.email')}
        />
        <Column
          field="type"
          sortable
          body={typeBody}
          header={translate('adminTool.payments.type')}
        />
        <Column
          field="method"
          body={imageBody}
          header={translate('adminTool.payments.method')}
        />
        <Column
          field="amount"
          sortable
          header={translate('adminTool.payments.amount')}
        />
        <Column
          field="currency"
          header={translate('adminTool.payments.value')}
        />
        <Column
          field="status"
          sortable
          body={statusBody}
          header={translate('adminTool.payments.status')}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.payments.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.payments.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.payments.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
