import { translate } from '@module/Translate/Translate';
import { getUsersAction } from '@network/Actions/Users';
import { FindUserDto } from '@rootTypes/users/find-user.dto';
import { RootState } from '@src/store';
import { UsersState } from '@type/Custom';
import { formatDateToLocalTime } from '@util/Dates';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const LIMIT_ON_PAGE = 5;
interface Props {
  setSelected: (usersIds: FindUserDto[]) => void;
}
export default function UsersList({ setSelected }: Props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const { users }: UsersState = useSelector<RootState, UsersState>(
    ({ users }) => users,
  );
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
    role: '',
  });
  const [selectedUsers, setSelectedUsers] = useState(null);

  useEffect(() => {
    if (mount) {
      getData(lazyParams);
      setMount(false);
    } else {
      getData(lazyParams);
    }
  }, [lazyParams]);

  const getData = (params: any) => {
    setLoading(true);
    dispatch(getUsersAction(params));
    setLoading(false);
  };

  const onPage = (event: any) => {
    setLazyParams({ ...event, role: '' });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event, role: '' });
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'isActive') {
      return rowData[item.field]
        ? translate('adminTool.users.active')
        : translate('adminTool.users.notActive');
    } else if (item.field === 'roles') {
      return rowData[item.field][0].type;
    }
  };

  return (
    <div className="users-container">
      <DataTable
        value={users.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={loading}
        first={lazyParams.first}
        stripedRows
        selectionPageOnly
        emptyMessage="No data found"
        className="datatable-responsive"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
        rows={LIMIT_ON_PAGE}
        totalRecords={users.meta.totalItems}
        onPage={onPage}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
        selection={selectedUsers}
        onSelectionChange={(e) => {
          setSelectedUsers(e.value);
          setSelected(e.value);
        }}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: '3rem' }}
          exportable={false}
        ></Column>
        <Column field="id" sortable header={translate('adminTool.users.id')} />
        <Column
          field="email"
          sortable
          header={translate('adminTool.users.email')}
        />
        <Column
          field="phone"
          sortable
          header={translate('adminTool.users.phone')}
        />
        <Column
          field="isActive"
          sortable
          header={translate('adminTool.users.isActive')}
          body={labelChecker}
        />
        <Column
          field="roles"
          header={translate('adminTool.users.roles')}
          body={labelChecker}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.users.createdAt')}
        />
        <Column
          field="updatedAt"
          body={formatDateToLocalTime}
          sortable
          header={translate('adminTool.users.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.users.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
