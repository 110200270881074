import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './Comments.scss';
import { useDispatch, useSelector } from 'react-redux';
import { StatusType } from '@rootTypes/statuses/status-type.enum';
import { translate } from '@module/Translate/Translate';
import { getOrderErrorsAction } from '@network/Actions/AdminOrderErros';
import { RootState } from '@src/store';
import { AdminOrderErrorsState } from '@type/Custom';
import { InputText } from 'primereact/inputtext';
import useDebounce from '@util/Debounse';
import { formatDateToLocalTime } from '@util/Dates';

const LIMIT_ON_PAGE = 10;

export default function Comments() {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const [searchedOrderId, setSearchedOrderId] = useState('');

  const { data }: AdminOrderErrorsState = useSelector<
    RootState,
    AdminOrderErrorsState
  >(({ adminErrors }) => adminErrors);

  const type = StatusType.ERROR;

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    type,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
  });

  const getData = (params: any) => {
    setLoading(true);
    dispatch(getOrderErrorsAction(params, searchedOrderId));
    setLoading(false);
  };

  useEffect(() => {
    if (mount) {
      getData(lazyParams);
      setMount(false);
    } else if (refresh) {
      getData(lazyParams);
      setRefresh(false);
    } else {
      getData(lazyParams);
    }
  }, [refresh, lazyParams, mount, useDebounce(searchedOrderId, 500)]);

  const onPage = (event: any) => {
    setLazyParams({ ...event, type });
  };

  const onSort = (event: any) => {
    setLazyParams({ ...event, type });
  };

  return (
    <div>
      <InputText
        value={searchedOrderId}
        onChange={(e: any) => setSearchedOrderId(e.target.value)}
        placeholder={translate('adminTool.comments.placeholder')}
      />
      <DataTable
        value={data.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        lazy
        loading={loading}
        first={lazyParams.first}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} errors"
        rows={LIMIT_ON_PAGE}
        totalRecords={data.meta.totalItems}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        onPage={onPage}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
      >
        <Column field="id" sortable header="id" />
        <Column
          field="order.id"
          sortable
          header={translate('adminTool.comments.orderId')}
        />
        <Column
          field="status"
          sortable
          header={translate('adminTool.comments.status')}
        />
        <Column
          field="origin"
          sortable
          header={translate('adminTool.comments.origin')}
        />
        <Column
          field="type"
          sortable
          header={translate('adminTool.comments.type')}
        />
        <Column
          field="createdAt"
          sortable
          header={translate('adminTool.comments.createdAt')}
          body={formatDateToLocalTime}
        />
        <Column
          field="updatedAt"
          sortable
          header={translate('adminTool.comments.updatedAt')}
          body={formatDateToLocalTime}
        />
        <Column
          field="description"
          sortable
          header={translate('adminTool.comments.description')}
        />
      </DataTable>
    </div>
  );
}
