import React, { useContext } from 'react';
import { FindProductDto } from '../../../../../../../../types/products/find-product.dto';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ProductType } from '../../../../../../../../types/products/product-type.enum';
import { ProductGroup } from '../../../../../../../../types/products/product-group.enum';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { Dropdown } from 'primereact/dropdown';
import './ProductInfo.scss';
import { H5 } from '../../../../../../Component/Text/Text';
import { translate } from '../../../../../../Module/Translate/Translate';
import { SetProductContext, EditProductContext } from '../../Products';

interface Props {
  product?: FindProductDto;
  editMode: boolean;
  setProduct: any;
  ppId?: any;
}

const types = [
  { label: translate('adminTool.products.rent'), value: ProductType.rent },
  {
    label: translate('adminTool.products.storage'),
    value: ProductType.storage,
  },
  { label: translate('adminTool.products.shop'), value: ProductType.store },
];

const groups = [
  { label: translate('adminTool.products.box'), value: ProductGroup.box },
  {
    label: translate('adminTool.products.packageBox'),
    value: ProductGroup.packageBox,
  },
  { label: translate('adminTool.products.shop'), value: ProductGroup.shop },
];

const stacks = [
  { label: translate('adminTool.products.isStack'), value: true },
  { label: translate('adminTool.products.notStack'), value: false },
];

const statuses = [
  { label: translate('adminTool.products.accepted'), value: true },
  { label: translate('adminTool.products.notAccepted'), value: false },
];

export default function ProductInfo({ product, editMode, setProduct }: Props) {
  const setProductContext = useContext<any>(SetProductContext);
  const editProductContext = useContext<any>(EditProductContext);
  const formik = useFormik({
    initialValues: {
      code: editMode && product ? product.code : '',
      status: editMode && product ? product.status : false,
      isStack: editMode && product ? product.isStack : false,
      name: editMode && product ? product.name : '',
      type: editMode && product ? product.type : '',
      group: editMode && product ? product.group : '',
      price: editMode && product ? product.price : 0,
      promotionPrice: editMode && product ? product.promotionPrice : 0,
      weekPrice: editMode && product ? product.weekPrice : 0,
      monthPrice: editMode && product ? product.monthPrice : 0,
      barCode: editMode && product ? product.barCode : '',
      vat: editMode && product ? product.vat : 0,
    },
    validationSchema: Yup.object({
      code: Yup.string().required(),
      status: Yup.boolean(),
      isStack: Yup.boolean(),
      name: Yup.string().required(),
      type: Yup.string().required(),
      group: Yup.string().required(),
      price: Yup.number(),
      promotionPrice: Yup.number(),
      weekPrice: Yup.number(),
      monthPrice: Yup.number(),
      barCode: Yup.string().required(),
      vat: Yup.number(),
    }),
    onSubmit: (values: any) => {
      if (editMode && product) {
        editProductContext({ id: product.id, ...values });
      } else {
        setProductContext(values);
      }
    },
  });

  function checkAllFieldsAreFilled(obj: any) {
    for (const key in formik) {
      switch (key) {
        case 'name':
          if (obj[key] === null || obj[key] === '') return false;
          break;
        case 'code':
          if (obj[key] === null || obj[key] === '') return false;
          break;
        case 'type':
          if (obj[key] === null || obj[key] === '') return false;
          break;
        case 'group':
          if (obj[key] === null || obj[key] === '') return false;
          break;
        case 'barcode':
          if (obj[key] === null || obj[key] === '') return false;
          break;
      }
    }
    return true;
  }

  const checkIsValid = () => {
    return formik.isValid && checkAllFieldsAreFilled(formik.values);
  };

  return (
    <div className="product-info-container">
      <form onSubmit={formik.handleSubmit}>
        <FormFieldFormik
          label={translate('adminTool.products.code')}
          type="text"
          name="code"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.code}
        />
        <br />
        {editMode && (
          <>
            <H5 className="product-h5">
              {translate('adminTool.products.status')}
            </H5>
            <Dropdown
              value={formik.values.status}
              options={statuses}
              className="type-dropdown"
              name="status"
              optionLabel="label"
              optionValue="value"
              onChange={formik.handleChange}
              placeholder={translate('adminTool.products.statusSelect')}
              itemTemplate={(option) => {
                return <span>{option.label}</span>;
              }}
            />
            <br />
            <H5 className="product-h5">
              {translate('adminTool.products.stack')}
            </H5>
            <Dropdown
              value={formik.values.isStack}
              options={stacks}
              className="type-dropdown"
              name="isStack"
              optionLabel="label"
              optionValue="value"
              onChange={formik.handleChange}
              placeholder={translate('adminTool.products.stackSelect')}
              itemTemplate={(option) => {
                return <span>{option.label}</span>;
              }}
            />
            <br />{' '}
          </>
        )}
        <FormFieldFormik
          label={translate('adminTool.products.name')}
          type="text"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        <br />
        <H5 className="product-h5">{translate('adminTool.products.type')}</H5>
        <Dropdown
          value={formik.values.type}
          options={types}
          className="type-dropdown"
          name="type"
          optionLabel="label"
          optionValue="value"
          onChange={formik.handleChange}
          placeholder={translate('adminTool.products.typeSelect')}
          itemTemplate={(option) => {
            return <span>{option.label}</span>;
          }}
        />
        <br />
        <H5 className="product-h5">{translate('adminTool.products.group')}</H5>
        <Dropdown
          value={formik.values.group}
          options={groups}
          className="type-dropdown"
          name="group"
          optionLabel="label"
          optionValue="value"
          onChange={formik.handleChange}
          placeholder={translate('adminTool.products.groupSelect')}
          itemTemplate={(option) => {
            return <span>{option.label}</span>;
          }}
        />
        {editMode && (
          <>
            {' '}
            <FormFieldFormik
              label={translate('adminTool.products.price')}
              type="number"
              name="price"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.price}
            />
            <br />
            <FormFieldFormik
              label={translate('adminTool.products.promotionPrice')}
              type="number"
              name="promotionPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.promotionPrice}
            />
            <br />
            <FormFieldFormik
              label={translate('adminTool.products.weekPrice')}
              type="number"
              name="weekPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.weekPrice}
            />
            <br />
            <FormFieldFormik
              label={translate('adminTool.products.monthPrice')}
              type="number"
              name="monthPrice"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.monthPrice}
            />
            <br />{' '}
          </>
        )}
        <FormFieldFormik
          label={translate('adminTool.products.barCode')}
          type="text"
          name="barCode"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.barCode}
        />
        <br />
        {editMode && (
          <>
            {' '}
            <FormFieldFormik
              label={translate('adminTool.products.vat')}
              type="number"
              name="vat"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.vat}
            />
            <br />{' '}
          </>
        )}

        <button
          type="submit"
          className={`btn btn-100 submitForm invoice__button m-auto button_accept ${
            checkIsValid() ? '' : 'inactive'
          }`}
          disabled={
            !formik.values.name &&
            !formik.values.code &&
            !formik.values.type &&
            !formik.values.group &&
            !formik.values.barCode
          }
        >
          {editMode
            ? translate('adminTool.products.update')
            : translate('adminTool.products.add')}
        </button>
      </form>
    </div>
  );
}
