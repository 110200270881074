import React from 'react';
import Telephone from '../../../../Asset/images/customer/landing/phone.png';
import TelephoneMobile from '../../../../Asset/images/customer/landing/telefon-mobile.png';
import Package from '../../../../Asset/images/customer/landing/paczka.png';
import PackageMobile from '../../../../Asset/images/customer/landing/paczka-mobile.png';
import FullPackageMobile from '../../../../Asset/images/customer/landing/full-package-mobile.png';
import Basket from '../../../../Asset/images/customer/landing/koszyk.png';
import BasketMobile from '../../../../Asset/images/customer/landing/basket-mobile.png';
import DirectionRight from '../../../../Asset/images/customer/landing/kierunek-prawo.png';
import DirectionLeft from '../../../../Asset/images/customer/landing/kierunek-lewo.png';
import Warehouse from '../../../../Asset/images/customer/landing/magazyn.png';
import ArrowRight from '../../../../Asset/images/customer/landing/arrow-right.png';
import ArrowLeft from '../../../../Asset/images/customer/landing/arrow-left.png';
import './HowtoBlock.scss';
import { H2, H3, P } from '../../../../Component/Text/Text';
import { translate } from '../../../../Module/Translate/Translate';

const HowtoBlock = () => (
  <>
    <article className="howto-section">
      <div className="container">
        <div className="main-container-wrapper">
          <H2 className="howto-section_title">
            homePage.howtoBlock.skitrajItUs
          </H2>
          <H3 className="howto-section_subtitle">
            homePage.howtoBlock.skitramyEverything
          </H3>
        </div>

        <div className="howto-section_content">
          <div className="content-wrapper">
            <div className="icon-container">
              <img
                src={Telephone}
                className="img-fluid d-none d-lg-block"
                alt={translate('homePage.howtoBlock.telephone')}
              />
              <img
                src={TelephoneMobile}
                className="img-fluid d-block d-lg-none"
                alt={translate('homePage.howtoBlock.telephone')}
              />
            </div>
            <div className="row no-gutters">
              <div className="text-center">
                <H3 className="text-warning">
                  homePage.howtoBlock.bookAppointment
                </H3>
                <P className="subtitle-warning">
                  homePage.howtoBlock.datePicking
                </P>
              </div>
            </div>
          </div>

          <div className="content-wrapper">
            <div className="icon-container">
              <img
                src={Package}
                className="img-fluid d-none d-lg-block"
                alt={translate('homePage.howtoBlock.package')}
              />
              <img
                src={PackageMobile}
                className="img-fluid d-block d-lg-none"
                alt={translate('homePage.howtoBlock.package')}
              />
            </div>
            <div className="row no-gutters">
              <div className="text-center">
                <H3 className="text-warning">homePage.howtoBlock.weWill</H3>
                <P className="subtitle-warning">
                  homePage.howtoBlock.deliverEmpty
                </P>
              </div>
            </div>
          </div>

          <div className="content-wrapper">
            <div className="icon-container">
              <img
                src={Basket}
                className="img-fluid d-none d-lg-block"
                alt={translate('homePage.howtoBlock.package')}
              />
              <img
                src={BasketMobile}
                className="img-fluid d-block d-lg-none"
                alt={translate('homePage.howtoBlock.package')}
              />
            </div>
            <div className="row no-gutters">
              <div className="text-center">
                <H3 className="text-warning">homePage.howtoBlock.pickUp</H3>
                <P className="subtitle-warning">
                  homePage.howtoBlock.parcelsReady
                </P>
              </div>
            </div>
          </div>

          <div className="content-wrapper">
            <div className="icon_arrow d-none d-lg-block">
              <img
                src={DirectionRight}
                style={{ transform: 'translate(0, -25px)' }}
                alt={translate('homePage.howtoBlock.shipment')}
              />
              <img
                src={DirectionLeft}
                style={{ transform: 'translate(-100%, 25px)' }}
                alt={translate('homePage.howtoBlock.return')}
              />
            </div>
            <div className="icon_arrow mobile">
              <img
                src={ArrowRight}
                style={{ transform: 'translate(0, -15px)' }}
                alt={translate('homePage.howtoBlock.shipment')}
              />
              <img
                src={ArrowLeft}
                style={{ transform: 'translate(-100%, 15px)' }}
                alt={translate('homePage.howtoBlock.return')}
              />
            </div>
            <div className="icon-container">
              <img
                src={Warehouse}
                className="img-fluid d-none d-lg-block"
                alt={translate('homePage.howtoBlock.package')}
              />
              <img
                src={FullPackageMobile}
                className="img-fluid d-block d-lg-none"
                alt={translate('homePage.howtoBlock.package')}
              />
            </div>
            <div className="row no-gutters">
              <div className="text-center">
                <H3 className="text-warning ">
                  homePage.howtoBlock.transportSkipasses
                </H3>
                <P className="subtitle-warning">
                  homePage.howtoBlock.containersMoved
                </P>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </>
);

export default HowtoBlock;
