import { useFormik } from 'formik';
import React, { useRef } from 'react';
import { FindSellStockDto } from '../../../../../../../types/stocks/find-sell-stock.dto';
import { StockStatus } from '../../../../../../../types/stocks/stock-status.enum';
import { translate } from '../../../../../Module/Translate/Translate';
import * as Yup from 'yup';
import { updateStockAction } from '../../../../../Network/Actions/Stocks';
import { Messages } from 'primereact/messages';
import { H5 } from '../../../../../Component/Text/Text';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import './EditSells.scss';
import { User } from '../../../../../models/user.model';

interface Props {
  sell: FindSellStockDto;
  editor: User;
  setRefresh: (refresh: boolean) => void;
}

const statuses = [
  { label: translate('adminTool.sells.isActive'), value: true },
  { label: translate('adminTool.sells.notActive'), value: false },
];

const deliveredStatuses = [
  {
    label: translate('adminTool.sells.inWarehouse'),
    value: StockStatus.STOCK_IW,
  },
  {
    label: translate('adminTool.sells.sendedToTheCustomer'),
    value: StockStatus.STOCK_STTC,
  },
  {
    label: translate('adminTool.sells.atTheCustomer'),
    value: StockStatus.STOCK_ATC,
  },
  {
    label: translate('adminTool.sells.finished'),
    value: StockStatus.STOCK_F,
  },
];

export default function EditSells({ sell, editor, setRefresh }: Props) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      isActive: sell ? sell.isActive : false,
      stockStatus: sell ? sell.stockStatus : null,
      stockNumber: sell.stockNumber ? sell.stockNumber : '',
    },
    validationSchema: Yup.object({
      stockNumber: Yup.string()
        .required()
        .matches(/^[A-Z0-9]+$/)
        .max(6),
    }),
    onSubmit: (values: any) => {
      if (values.stockStatus === StockStatus.STOCK_F) {
        values.isActive = false;
      }
      values.updatedBy = editor.email;
      updateStockAction(sell.id, values).then((res: any) => {
        if (res.response) {
          showMessage(
            'error',
            translate(`adminTool.sells.${res.response.data.error.message}`),
          );
        } else {
          setRefresh(true);
          showMessage('success', translate('adminTool.sells.updateMessage'));
        }
      });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  return (
    <div className="sells-main-container border-details">
      <div className="sells-header-container">
        <Messages ref={message} />
      </div>
      <div className="sells-data-container">
        <div className="sells-top-container">
          <div className="sells-info-left-container">
            <H5 className="sells-h5">adminTool.sells.email</H5>
            <p>{sell.userEmail}</p>
            <H5 className="sells-h5">adminTool.sells.orderId</H5>
            <p>{sell.orderId}</p>
            <H5 className="sells-h5">adminTool.sells.stockStatus</H5>
            <p>{translate(`adminTool.sells.${sell.stockStatus}`)}</p>
          </div>
          <div className="sells-info-middle-container">
            <form className="sells-form" onSubmit={formik.handleSubmit}>
              <H5 className="sells-h5">adminTool.sells.stockId</H5>
              <InputText
                name="stockNumber"
                value={formik.values.stockNumber}
                onChange={formik.handleChange}
                disabled={sell.stockNumber ? true : false}
                placeholder={translate('adminTool.sells.stockId')}
              />
              <H5 className="sells-h5">adminTool.sells.status</H5>
              <Dropdown
                value={formik.values.isActive}
                options={statuses}
                className="type-dropdown"
                name="isActive"
                optionLabel="label"
                disabled={sell.stockNumber ? false : true}
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <H5 className="sells-h5">adminTool.sells.stockStatus</H5>
              <Dropdown
                value={formik.values.stockStatus}
                options={deliveredStatuses}
                className="type-dropdown"
                name="stockStatus"
                optionLabel="label"
                optionValue="value"
                disabled={sell.stockNumber && sell.isActive ? false : true}
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.isValid && formik.values.stockNumber !== ''
                    ? sell.stockNumber
                      ? formik.values.isActive !== sell.isActive ||
                        formik.values.stockStatus !== sell.stockStatus
                        ? ''
                        : 'inactive'
                      : ''
                    : 'inactive'
                }`}
              >
                {translate('adminTool.sells.update')}
              </button>
            </form>
          </div>
          <div className="sells-info-right-container">
            <H5 className="sells-h5">adminTool.sells.productsList</H5>
            {sell.products.map((item: any) => {
              return <p key={item.id}>{`${item.quantity}x ${item.name}`}</p>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
