import React from 'react';
import { ReactElement } from 'react';
import { TypographyProps } from './Typography.type';

export const TouchableHOC = (
  el: ReactElement,
  { onClick }: TypographyProps,
): ReactElement => <div onClick={onClick}>{el}</div>;

export default TouchableHOC;
