import React from 'react';
import './OrderPanel.scss';
import MainTemplate from '../../Component/MainTemplate/MainTemplate';
import { translate } from '../../Module/Translate/Translate';
import Panel from './components/Panel/Panel';
import { H1 } from '../../Component/Text/Text';
import { Helmet } from 'react-helmet';

const OrderPanel = (props: any) => {
  return (
    <div className="order-panel-wrapper">
      <Helmet>
        <title>Boxy magazynowe na wynajem | skitraj.pl</title>
        <meta
          name="description"
          content="Zamawiając produkty pakowe wraz z usługą, otrzymasz je w terminie dostarczenia usługi. Jesli potrzebujesz produktów pakowych w innym terminie, stwórz osobne zamowienie"
        />
        <meta name="robots" content="index,follow"></meta>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <MainTemplate>
        <div className="order-panel">
          <div className="container">
            <div className="order-panel_container">
              <H1 className="title">{translate('orderPanel.title')}</H1>
              <Panel location={props.location.state} />
            </div>
          </div>
        </div>
      </MainTemplate>
    </div>
  );
};

export default OrderPanel;
