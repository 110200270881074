import React from 'react';
import './ExplanationBlock.scss';
import { H2, H3 } from '../../../../Component/Text/Text';

const ExplanationBlock = () => (
  <>
    <article className="explanation-section row no-gutters px-4 padded">
      <section className="mx-lg-auto text-center">
        <H2 className="explanation-section_title fs52 fw700">
          howItWorks.explanationBlock.title
        </H2>
        <H3 className="explanation-section_subtitle fs28 fw400">
          howItWorks.explanationBlock.subtitle
        </H3>
      </section>
    </article>
  </>
);

export default ExplanationBlock;
