import {
  NOT_MODIFIED_REVIEWS_FAIL,
  NOT_MODIFIED_REVIEWS_REQUEST,
  NOT_MODIFIED_REVIEWS_SUCCESS,
} from '../Network/Actions/Types';
import { NotModifiedReviewsState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: number;
}

export const notModifiedReviewsReducer = (
  state: NotModifiedReviewsState = { loading: false, reviews: 0, error: null },
  action: Action,
) => {
  switch (action.type) {
    case NOT_MODIFIED_REVIEWS_REQUEST:
      return { loading: true, reviews: state.reviews, error: null };
    case NOT_MODIFIED_REVIEWS_SUCCESS:
      return { loading: false, reviews: action.payload, error: null };
    case NOT_MODIFIED_REVIEWS_FAIL:
      return { loading: false, reviews: state.reviews, error: action.payload };
    default:
      return state;
  }
};
