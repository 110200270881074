import React from 'react';
import { Link } from 'react-router-dom';
import Product from '../Product/Product';
import './ProductList.scss';
import { translate } from '../../../../Module/Translate/Translate';
import { FindProductDto } from '../../../../../../types/products/find-product.dto';
import P from '../../../../Component/Text/Text';

interface Props {
  text: string;
  children: JSX.Element;
  productsData: FindProductDto[];
  stepValueCounter: number;
}

export default function ProductList({
  text,
  children,
  productsData,
  stepValueCounter,
}: Props) {
  return (
    <div className="order-product-list">
      {children}
      <P className="order-product-list_text">{text}</P>
      <ul>
        {productsData.map((product: any) => (
          <li key={product.id}>
            <Product
              productData={product}
              stepValueCounter={stepValueCounter}
            />
          </li>
        ))}
      </ul>
      <Link
        to={{
          pathname: '/sklep',
        }}
        className="order-product-link"
      >
        <button className="order-product-list_btn">
          {translate('orderPanel.productListButton')}
        </button>
      </Link>
    </div>
  );
}
