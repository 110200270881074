import { H5 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import { FindNotificationDto } from '@rootTypes/notifications/find-notification.dto';
import { NotificationTypeEnum } from '@rootTypes/notifications/notification-type.enum';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MessageContent from './helpers/MessageContent';
import './MessageItem.scss';
import unifiedFormatDate from '@util/Dates';

const routes = {
  [NotificationTypeEnum.userRental]: 'panel-klienta-moj-wynajem',
  [NotificationTypeEnum.userPaymentHistory]: 'panel-klienta-historia-platnosci',
  [NotificationTypeEnum.userOrder]: 'panel-klienta-zamowienia',
  [NotificationTypeEnum.userPayment]: 'panel-klienta-oplac',
  [NotificationTypeEnum.userStorage]: 'panel-klienta-moja-skrytka',
  [NotificationTypeEnum.userActivation]: 'user-activation',
  [NotificationTypeEnum.administration]: 'administration',
};

export default function MessageItem({
  type,
  message,
  title,
  createdAt,
  isRead,
  isLinkToShow,
  id,
}: FindNotificationDto): JSX.Element {
  const [textButton, setTextButton] = useState('');
  useEffect(() => {
    switch (type) {
      case NotificationTypeEnum.userPayment:
        setTextButton(translate('messagesBlock.pay'));
        break;
      case NotificationTypeEnum.userPaymentHistory:
        setTextButton(translate('messagesBlock.paymentHistory'));
        break;
      case NotificationTypeEnum.userOrder:
        setTextButton(translate('messagesBlock.orderHistory'));
        break;
      case NotificationTypeEnum.userStorage:
        setTextButton(translate('messagesBlock.hiddingBlock'));
        break;
      case NotificationTypeEnum.userRental:
        setTextButton(translate('messagesBlock.myRental'));
        break;
      default:
        setTextButton('');
    }
  }, [type]);

  function showLink(isToShow: any) {
    return isToShow ? (
      <Link to={`/${routes[type]}`}>
        {textButton && (
          <button className={`message-item_button ${isRead ? 'opened' : ''}`}>
            {textButton}
          </button>
        )}
      </Link>
    ) : (
      ''
    );
  }

  return (
    <>
      {!isRead && <span className="message-item_icon"></span>}
      <p className={`message-item_date ${isRead ? '' : 'p--orange'}`}>
        {unifiedFormatDate(new Date(createdAt), true, true).replace(/,/gim, '')}
      </p>

      <H5 className={`message-item_title ${isRead ? '' : 'h5--orange'}`}>
        {title}
      </H5>
      <MessageContent text={message} />
      {showLink(isLinkToShow)}
    </>
  );
}
