import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '@module/Translate/Translate';
import {
  addProductInBasket,
  removeProductFromBasket,
  decreaseProductInBasket,
} from '@network/Actions/Basket';
import { RootState } from '@src/store';
import { BasketState } from '@type/Custom';
import Counter from '../Counter/Counter';
import './Product.scss';
import { Link } from 'react-router-dom';

export default function Product({ productData, stepValueCounter }: any) {
  const state = useSelector<RootState, BasketState>(({ basket }) => basket);

  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  useEffect(() => {
    const product = state.products.find(
      (stateProduct) =>
        stateProduct.type === productData.type &&
        stateProduct.group === productData.group &&
        stateProduct.id === productData.id,
    );

    product ? setValue(+product.value) : setValue(0);
  }, [productData, state.products]);

  const onCounterIncreaseClick = (value: any) => {
    dispatch(
      addProductInBasket({ data: { ...productData, value }, stepValueCounter }),
    );
    setValue(value);
  };

  const onCounterDecreaseClick = (value: any) => {
    if (value === 0) dispatch(removeProductFromBasket(productData));
    if (value !== 0)
      dispatch(
        decreaseProductInBasket({ ...productData, value }, stepValueCounter),
      );
    setValue(value);
  };

  return (
    <div className="order-product">
      <Link
        to={`/sklep/${productData.pathUrl}`}
        className="order-product_group"
      >
        <img
          src={
            productData.images.find((image: any) => image.type === 'miniature')
              ?.url
          }
          alt={productData.name}
        />
        <div className="order-product_info">
          <p>{productData.name}</p>
          {productData.promotionPrice !== 0 ? (
            <div className="product-price mobile">
              <span className="old-price">
                {productData.price} {translate('default.currency')}
              </span>
              &nbsp;
              {productData.promotionPrice} {translate('default.currency')}
            </div>
          ) : (
            <div className="product-price mobile">
              {productData.price} {translate('default.currency')}
            </div>
          )}
        </div>
      </Link>
      <div className="order-product_price">
        <Counter
          extraClass="order-counter-tall"
          stepValue={stepValueCounter}
          onCounterIncreaseClick={(value) => onCounterIncreaseClick(value)}
          onCounterDecreaseClick={(value) => onCounterDecreaseClick(value)}
          counterValue={value}
        />
        {productData.promotionPrice !== 0 ? (
          <div className="product-price desktop">
            <span className="old-price">
              {productData.price} {translate('default.currency')}
            </span>
            &nbsp;
            {productData.promotionPrice} {translate('default.currency')}
          </div>
        ) : (
          <div className="product-price desktop">
            {productData.price} {translate('default.currency')}
          </div>
        )}
      </div>
    </div>
  );
}
