import {
  TRANSPORT_FULFILLED,
  TRANSPORT_PENDING,
  TRANSPORT_REJECTED,
} from '../Network/Actions/Types';
import { TransportPriceState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: string;
}
export const transportPriceReducer = (
  state: TransportPriceState = { loading: false, transport: [], error: null },
  action: Action,
) => {
  switch (action.type) {
    case TRANSPORT_PENDING:
      return { loading: true, transport: state.transport, error: null };
    case TRANSPORT_FULFILLED:
      return { loading: false, transport: action.payload, error: null };
    case TRANSPORT_REJECTED:
      return {
        loading: false,
        transport: state.transport,
        error: action.payload,
      };
    default:
      return state;
  }
};
