import FormField from '@component/FormField/FormField';
import { translate } from '@module/Translate/Translate';
import { getGusCompanyDetails } from '@network/Actions/Users';
import { FindCompanyDetailsDto } from '@rootTypes/comapny-details/find-company-details.dto';
import { isEmpty } from '@util/isEmpty';
import { ValidateFormContext } from '@view/RegistrationStepper/components/PaymentStep/PaymentStep';
import React, { useState, useContext } from 'react';
import './CompanyBillingForm.scss';

interface IProps {
  item?: FindCompanyDetailsDto;
  onEditAddress: (value: Record<string, string | number>) => void;
  showError: (value: boolean) => void;
  visibleSpinner: (value: boolean) => void;
  setIsNipError?: (value: boolean) => void;
}

export const CompanyBillingForm = ({
  item,
  onEditAddress,
  showError,
  visibleSpinner,
  setIsNipError,
}: IProps) => {
  const validateForm = useContext<any>(ValidateFormContext);
  const [formData, setFormData] = useState({
    taxNumber: item ? item.taxNumber : '',
    name: item ? item.name : '',
    restOfName: item ? item.restOfName : '',
    street: item ? item.street : '',
    postalCode: item ? item.postalCode || item.postalCode : '',
    city: item ? item.city : '',
  });

  const isNull = (val: any) => {
    if (val) return val;
    return '';
  };

  const checkIsFilled = () => {
    return (
      (formData.taxNumber &&
        formData.name &&
        formData.street &&
        formData.postalCode &&
        formData.city) !== ''
    );
  };

  async function downloadData(e: any, taxNumber: any) {
    e.preventDefault();
    visibleSpinner(true);
    if (setIsNipError) setIsNipError(false);
    await getGusCompanyDetails(taxNumber)
      .then((data) => {
        if (data.errorCode === '4') if (setIsNipError) setIsNipError(true);
        data.errorCode
          ? showError(true)
          : setFormData({
              taxNumber: +data.nip,
              name: data.nazwa,
              street:
                data.ulica + ' ' + data.nrNieruchomosci + '/' + data.nrLokalu,
              city: data.miejscowosc,
              postalCode: data.kodPocztowy,
              restOfName: '',
            });
        validateForm(checkIsFilled());
      })
      .catch(() => {
        showError(true);
      })
      .finally(() => visibleSpinner(false));
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (setIsNipError) setIsNipError(false);
    onEditAddress({
      taxNumber: isNull(+formData.taxNumber),
      name: isNull(formData.name),
      restOfName: isNull(formData.restOfName),
      street: isNull(formData.street),
      city: isNull(formData.city),
      postalCode: isNull(formData.postalCode),
    });
    validateForm(checkIsFilled());
  };

  return (
    <form className="company-billing-form pb-5" onSubmit={handleSubmit}>
      <div className="form-field-wrapper">
        <div className="d-flex m-0">
          <FormField
            name={formData.taxNumber}
            label={translate('companyBillingForm.nip')}
            onChange={(e: any) =>
              setFormData({ ...formData, taxNumber: e.target.value })
            }
            pattern={'^[0-9]{10}$'}
            placeholder={'XXXXXXXXXX'}
            title={translate('errors.NIPCodeError')}
          />
          <button
            onClick={(e) => downloadData(e, formData.taxNumber)}
            disabled={
              !formData.taxNumber ||
              formData.taxNumber.toString().length !== 10 ||
              !parseInt(formData.taxNumber.toString())
            }
            className="btn company-billing-form_download-btn"
          >
            {translate('companyBillingForm.downloadData')}
          </button>
        </div>
      </div>

      <FormField
        name={formData.name}
        label={translate('companyBillingForm.companyName')}
        onChange={(e: any) =>
          setFormData({ ...formData, name: e.target.value })
        }
      />
      <FormField
        name={formData.restOfName || ''}
        label={
          <>
            {translate('companyBillingForm.longCompanyName')}
            <span className="optional-field">
              {translate('companyBillingForm.optional')}
            </span>
          </>
        }
        onChange={(e: any) =>
          setFormData({ ...formData, restOfName: e.target.value })
        }
      />
      <FormField
        name={formData.street}
        isFullWidth
        label={translate('paymentStep.street')}
        onChange={(e: any) =>
          setFormData({ ...formData, street: e.target.value })
        }
      />

      <FormField
        name={formData.postalCode}
        label={translate('paymentStep.zipCode')}
        onChange={(e: any) =>
          setFormData({ ...formData, postalCode: e.target.value })
        }
        pattern={'^[0-9]{2}-[0-9]{3}$'}
        placeholder={'XX-XXX'}
        title={translate('errors.postalCodeError')}
      />
      <FormField
        name={formData.city}
        label={translate('paymentStep.city')}
        onChange={(e: any) =>
          setFormData({ ...formData, city: e.target.value })
        }
      />

      <button
        type="submit"
        className="btn btn-100 mt-5"
        disabled={
          !isEmpty(formData, 'restOfName') ||
          formData.taxNumber.toString().length !== 10 ||
          !parseInt(formData.taxNumber.toString())
        }
      >
        {translate('paymentStep.changeAddress')}
      </button>
    </form>
  );
};

export default CompanyBillingForm;
