export enum StockStatus {
  STOCK_RFS = "readyForSent",
  STOCK_IW = "inWarehouse",
  STOCK_STTC = "sentToTheCustomer",
  STOCK_ATC = "atTheCustomer",
  STOCK_RFRF = "requestForReturnFull",
  STOCK_RFD = "requestForDeliver",
  STOCK_ATCF = "atTheCustomerFull",
  STOCK_RFRE = "requestForReturnEmpty",
  STOCK_RFR = "requestForReturn",
  STOCK_R = "returned",
  STOCK_F = "finished",
  STOCK_ERR = "error, contact with the deliver",
  STOCK_UNKNOWN = "unknown",
  STOCK_SHIPMENT_CHANGE = "changes in the shipment",
  STOCK_RETURN_TO_SENDER = "returned to sender",
  STOCK_RETURN_TO_WAREHOUSE = "return to warehouse",
  STOCK_RETURN_TO_CLIENT = "return to client",
  STOCK_ERR_COURIER = "cannot order courier in WMS",
}
