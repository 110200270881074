import phone from '@images/move-page/phone.png';
import box from '@images/move-page/box.png';
import phoneCheck from '@images/move-page/phone-check.png';
import car from '@images/move-page/car.png';
import { translate } from '@module/Translate/Translate';

export const data = [
  {
    id: 1,
    image: phone,
    imageMobile: phone,
    title: translate('movePage.howToBlock.data.item-1.title'),
    subtitle: translate('movePage.howToBlock.data.item-1.subtitle'),
  },
  {
    id: 2,
    image: box,
    imageMobile: box,
    title: translate('movePage.howToBlock.data.item-2.title'),
    subtitle: translate('movePage.howToBlock.data.item-2.subtitle'),
  },
  {
    id: 3,
    image: phoneCheck,
    imageMobile: phoneCheck,
    title: translate('movePage.howToBlock.data.item-3.title'),
    subtitle: translate('movePage.howToBlock.data.item-3.subtitle'),
  },
  {
    id: 4,
    image: car,
    imageMobile: car,
    title: translate('movePage.howToBlock.data.item-4.title'),
    subtitle: translate('movePage.howToBlock.data.item-4.subtitle'),
  },
];
