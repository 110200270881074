import {
  REVIEWS_FAIL,
  REVIEWS_SUCCESS,
  REVIEWS_REQUEST,
  REVIEWS_ALL_FAIL,
  REVIEWS_ALL_SUCCESS,
  REVIEWS_ALL_REQUEST,
} from '../Network/Actions/Types';
import { ReviewsState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: Record<string, unknown>;
}

export const reviewsReducer = (
  state: ReviewsState = { loading: false, reviews: {}, error: null },
  action: Action,
) => {
  switch (action.type) {
    case REVIEWS_REQUEST:
      return { loading: true, reviews: state.reviews, error: null };
    case REVIEWS_SUCCESS:
      return { loading: false, reviews: action.payload, error: null };
    case REVIEWS_FAIL:
      return { loading: false, reviews: state.reviews, error: action.payload };
    case REVIEWS_ALL_REQUEST:
      return { loading: true, reviews: state.reviews, error: null };
    case REVIEWS_ALL_SUCCESS:
      return { loading: false, reviews: action.payload, error: null };
    case REVIEWS_ALL_FAIL:
      return { loading: false, reviews: state.reviews, error: action.payload };
    default:
      return state;
  }
};
