import React from 'react';
import './AdminContactForm.scss';
import { Link } from 'react-router-dom';
import { H3 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';

interface IProps {
  setShowConfirm: (confirm: boolean) => void;
  message: string;
  activeContactLink?: boolean;
}

function AdminContactForm({
  setShowConfirm,
  message,
  activeContactLink = true,
}: IProps) {
  return (
    <>
      <div className="admin-contact-form">
        <H3 className="admin-contact-form_text">
          {message}&nbsp;
          {activeContactLink && (
            <Link
              to="/panel-klienta-kontakt"
              className="admin-contact-form_link"
            >
              {translate('dashboard.adminContactForm.adminContact')}
            </Link>
          )}
        </H3>

        <button
          type="submit"
          className="btn btn-100 admin-contact-form_button"
          onClick={() => setShowConfirm(false)}
        >
          {translate('dashboard.adminContactForm.closeBtn')}
        </button>
      </div>
    </>
  );
}

export default AdminContactForm;
