import React, { useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './DesktopNavigation.scss';
import Bag from '@images/bag.png';
import Logo from '@images/customer/landing/logo_skitraj.svg';
import { useDispatch, useSelector } from 'react-redux';
import User from '@images/customer/landing/user-desktop.png';
import Arrow from '@images/modal/arrowIcon.png';
// import LangPicker from '@component/LangPicker/LangPicker';
import { translate } from '@module/Translate/Translate';
import { logoutAction } from '@network/Actions/Login';
import { RootState } from '@src/store';
import { UserState, BasketState, NotificationsState } from '@type/Custom';
import { checkIsAllowed } from '@util/UserValidation';
import DesktopNavigationI from './DesktopNavigation.type';

export const DesktopNavigation: React.FC<
  React.PropsWithChildren<DesktopNavigationI>
> = ({ customMobile = false }) => {
  const { state = { activeStep: 0 } }: any = useLocation();
  const showBackArrow = state.activeStep !== 6;

  const { unreadCount } = useSelector<RootState, NotificationsState>(
    (state) => state.notifications,
  );

  const navbar = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const basketState = useSelector<RootState, BasketState>(
    ({ basket }) => basket,
  );
  const itemsCounter = basketState.products.reduce((prev, next) => {
    return prev + +next.value;
  }, 0);

  const handleLogout = async (): Promise<any> => {
    dispatch(logoutAction());
    history.push({
      pathname: '/',
    });
  };

  function prevPage() {
    if (
      history.location.pathname === '/rejestracja' ||
      history.location.pathname === '/return-boxes'
    ) {
      history.goBack();
    } else {
      history.push({
        pathname: '/',
      });
    }
  }

  const isDesktop = window.innerWidth > 992;
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (navbar && navbar.current && isDesktop) {
      if (
        document.body.scrollTop > 60 ||
        document.documentElement.scrollTop > 60
      ) {
        navbar.current.style.padding = '.1rem 0';
      } else {
        navbar.current.style.padding = '1.5rem 0';
      }
    }
  }

  return (
    <div className={`desktop-navigation`}>
      <div className="desktop-navigation_placeholder" />
      <div className="desktop-navigation_container row no-gutters">
        <div className="container">
          <div
            ref={navbar}
            id="desktop-navigation_content-header"
            className="desktop-navigation_content no-gutters"
            style={{ padding: isDesktop ? '1.5rem 0' : '1rem 0 .75rem' }}
          >
            <div className="brand-panel ">
              <div
                className={
                  customMobile
                    ? 'desktop-navigation--custom'
                    : 'desktop-navigation--lang'
                }
              >
                <Link
                  to="/"
                  className="brand_container"
                  data-e2e="brand-container"
                >
                  <img
                    className="brand-img"
                    src={Logo}
                    alt={translate('navigation.skitraj')}
                  />
                </Link>
                {/* <LangPicker /> */}
                {showBackArrow && (
                  <div className="navigation-arrow_container">
                    <img
                      className="arrow_img"
                      src={Arrow}
                      onClick={prevPage}
                      alt={translate('navigation.skitraj')}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="navigation-panel  d-none d-xl-block">
              <div className="row d-flex justify-content-center flex-row no-gutters h-100">
                <Link
                  to="/przechowaj"
                  data-e2e="how-it-works"
                  className="nav-a"
                >
                  {translate('navigation.howWorking')}
                </Link>
                <Link to="/przeprowadz" data-e2e="move" className="nav-a">
                  {translate('navigation.move')}
                </Link>
                <Link to="/wynajem" data-e2e="rent" className="nav-a">
                  {translate('navigation.rent')}
                </Link>
                <Link to="/sklep" data-e2e="sell" className="nav-a">
                  {translate('navigation.sell')}
                </Link>
                <Link to="/DlaFirm" data-e2e="offer" className="nav-a">
                  {translate('navigation.offer')}
                </Link>
                {/* <Link to="/blog" data-e2e="blog" className="nav-a">
                  {translate('navigation.blog')}
                </Link> */}
                <Link to="/pomoc" data-e2e="faq" className="nav-a">
                  {translate('navigation.frequentlyAsked')}
                </Link>
                {checkIsAllowed(userData?.user) && ( //roles.includes(1)
                  <Link
                    to="/admin-tool"
                    data-e2e="admin-tool"
                    className="nav-a"
                  >
                    Admin Tool
                  </Link>
                )}
              </div>
            </div>
            <div className="user-panel  d-none d-xl-block">
              <div className="d-flex flex-row no-gutters justify-content-end h-100">
                {/* <LangPicker /> */}
                <Link to="/zamowienie" data-e2e="order" className="link">
                  {itemsCounter === 0 ? (
                    <div className="order-btn btn btn-small my-auto">
                      <span className="d-block fw700 btn-heading">
                        {translate('navigation.order')}
                      </span>
                    </div>
                  ) : (
                    <div className="basket">
                      <span className="cart-items-counter">{itemsCounter}</span>

                      <img
                        className="cart-bag c-pointer mr-1"
                        data-e2e="cart-bag"
                        src={Bag}
                        alt={translate('navigation.orders')}
                        onClick={() => {
                          history.push(`/zamowienie`);
                          if (history.location.pathname === '/zamowienie') {
                            document.documentElement.scrollTop = 0;
                          }
                        }}
                      />
                    </div>
                  )}
                </Link>

                {userData.user?.isActive && (
                  <Link
                    data-e2e="user-panel"
                    className="user-panel-link"
                    to="/panel-klienta"
                  >
                    <div className="col text-right my-auto px-0">
                      {unreadCount > 0 && (
                        <span className="notifications-counter"></span>
                      )}
                      <img src={User} alt={translate('navigation.users')} />
                    </div>
                  </Link>
                )}
                {!userData.user?.isActive ? (
                  <Link
                    to="/logowanie"
                    data-e2e="login"
                    className="login-btn btn btn-secondary btn-small"
                  >
                    <span className="fw700 btn-heading">
                      {translate('navigation.login')}
                    </span>
                  </Link>
                ) : (
                  <button
                    onClick={() => handleLogout()}
                    data-e2e="logout"
                    className="login-btn btn btn-secondary btn-small"
                  >
                    <span className="fw700 btn-heading">
                      {translate('navigation.logOut')}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
