import React from 'react';
import './Navigation.scss';
import { DesktopNavigation } from './DesktopNavigation/DesktopNavigation';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import NavigationI from './Navigation.type';

const Navigation: React.FC<React.PropsWithChildren<NavigationI>> = ({
  customMobile = false,
}) => (
  <nav className="navigation">
    <DesktopNavigation customMobile={customMobile} />
    <MobileNavigation customMobile={customMobile} />
  </nav>
);

export default Navigation;
