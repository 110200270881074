import React from 'react';
import './Title.scss';
import { translate } from '../../../../Module/Translate/Translate';
import { H1, P } from '../../../../Component/Text/Text';

export default function Title() {
  return (
    <div className="shop-description">
      <H1 className="shop-description_title">{translate('shop.title')}</H1>
      <P className="shop-description_subTitle">
        shop.inviteYou
        <br className="d-none d-lg-block" />
        shop.offerDurable
        <br className="d-none d-lg-block" />
        shop.eachProduct
      </P>
    </div>
  );
}
