import React, { useState } from 'react';
import './DashboardSideBar.scss';
import sidebarItem from './sidebar-item.json';
import SidebarItemDesktop from './components/SidebarItemDesktop/SidebarItemDesktop';
import SidebarItemMobile from './components/SidebarItemMobile/SidebarItemMobile';
import { translate } from '@module/Translate/Translate';

const DashboardSideBar = ({ setActivePanel, activePanel }: any) => {
  const [openSubMenuDesktop, setOpenSubMenuDesktop] = useState([0]);

  function addNewSubMenu(index: number) {
    const elementIsPresent = openSubMenuDesktop.indexOf(index);

    if (elementIsPresent >= 0) {
      setOpenSubMenuDesktop(openSubMenuDesktop.filter((e) => e !== index));
    } else {
      setOpenSubMenuDesktop([...openSubMenuDesktop, index]);
    }
  }

  return (
    <nav className="sidebar-wrapper">
      <div className="sidebar-navigation">
        <p className="sidebar-navigation_title pb-0">
          {translate('homePage.customerPanel')}
        </p>

        <ul className="sidebar-navigation_container list-unstyled components d-lg-block d-none">
          {sidebarItem
            .filter((item) => item.href !== 'panel-klienta-wylogowanie')
            .map((item, index) => (
              <SidebarItemDesktop
                key={index}
                openSubMenu={
                  !!openSubMenuDesktop.filter((e) => e === index).length
                }
                addOpenSubMenu={() => addNewSubMenu(index)}
                setActivePanel={setActivePanel}
                activePanel={activePanel}
                item={item}
              />
            ))}
        </ul>

        <ul className="sidebar-navigation_container list-unstyled components d-lg-none d-flex">
          {sidebarItem.map((item, index) => (
            <SidebarItemMobile
              key={index}
              setActivePanel={setActivePanel}
              activePanel={activePanel}
              item={item}
            />
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default DashboardSideBar;
