import { RootState } from '@src/store';
import { NewComplaintsState } from '@type/Custom';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAdminErrorCount } from '@network/Actions/AdminOrderErros';
import { StatusType } from '@rootTypes/statuses/status-type.enum';
import { NotModifiedReviewsState } from '@type/Custom';

const SidebarItem = ({ setActivePanel, activePanel, item }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(!!item.openDefault);

  const [adminErrorCount, setAdminErrorCount] = useState(null);

  const getData = async () => {
    getAdminErrorCount(StatusType.ERROR).then((data) =>
      setAdminErrorCount(data),
    );
  };

  useEffect(() => {
    getData();
  }, []);

  const renderActivePanelCount = () => {
    switch (item.template) {
      case 'comments':
        return adminErrorCount;
      default:
        return null;
    }
  };

  const isActiveItem = () => {
    if (activePanel === item.template) {
      return true;
    }

    return false;
  };

  const hasActiveChild = () => {
    if (item.children) {
      return !!item.children.find((i: any) => i.template === activePanel);
    }

    return false;
  };

  const clickElement = function () {
    if (!!item.children && !!item.children.length) {
      setSidebarOpen((prev: any) => !prev);
      return;
    }

    setActivePanel(item);
  };
  return (
    <li>
      <a
        className={`admin-sidebar-navigation_item`}
        data-active={isActiveItem()}
        data-opened={sidebarOpen}
        data-has-child={!!item.children && !!item.children.length}
        data-has-active-child={hasActiveChild()}
        onClick={() => clickElement()}
      >
        {item.displayName}{' '}
        {renderActivePanelCount() ? (
          <span>{renderActivePanelCount()}</span>
        ) : null}
      </a>

      {!!item.children && !!item.children.length && (
        <ul className={`collapse list-unstyled ${sidebarOpen ? 'show' : ''}`}>
          {item.children.map((child: any, i: any) => (
            <SidebarSubItem
              key={i}
              setActivePanel={setActivePanel}
              activePanel={activePanel}
              item={child}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const SidebarSubItem = ({ setActivePanel, activePanel, item }: any) => {
  const { complaints } = useSelector<RootState, NewComplaintsState>(
    (state) => state.newComplaints,
  );
  const { reviews } = useSelector<RootState, NotModifiedReviewsState>(
    (state) => state.notModifiedRevies,
  );

  const getNotifications = (itemName: string) => {
    switch (itemName) {
      case 'Complaints':
        if (complaints > 0) {
          return <span>{complaints}</span>;
        }
        break;
      case 'Reviews':
        if (reviews > 0) {
          return <span>{reviews}</span>;
        }
        break;
      default:
        return;
    }
  };

  return (
    <li>
      <a
        className={`admin-sidebar-navigation_sub-item ${
          activePanel === item.template && 'active'
        }`}
        onClick={() => setActivePanel(item)}
      >
        {item.displayName}
        {getNotifications(item.displayName)}
      </a>
    </li>
  );
};

export default SidebarItem;
