import { FindBasketProductDto } from '@rootTypes/products/find-basket-product.dto';

export const handleCounterClickMath = (
  value: number,
  data: any,
  storeValue: FindBasketProductDto[],
) => {
  if (storeValue.length === 0) {
    return [{ ...data, value }];
  }
  const box = storeValue.find((item) => item.id === data.id);
  if (!box) {
    return [...storeValue, { ...data, value }];
  }
  const updateBox = storeValue.map((item) => {
    if (item.id === data.id) {
      item.value = value;
      return item;
    }
    return item;
  });
  return updateBox;
};
