import {
  NEW_COMPLAINTS_FAIL,
  NEW_COMPLAINTS_REQUEST,
  NEW_COMPLAINTS_SUCCESS,
} from '../Network/Actions/Types';
import { NewComplaintsState } from '../Type/Custom';

interface Action {
  type: string;
  payload?: number;
}

export const newComplaintsReducer = (
  state: NewComplaintsState = { loading: false, complaints: 0, error: null },
  action: Action,
) => {
  switch (action.type) {
    case NEW_COMPLAINTS_REQUEST:
      return { loading: true, complaints: state.complaints, error: null };
    case NEW_COMPLAINTS_SUCCESS:
      return { loading: false, complaints: action.payload, error: null };
    case NEW_COMPLAINTS_FAIL:
      return {
        loading: false,
        complaints: state.complaints,
        error: action.payload,
      };
    default:
      return state;
  }
};
