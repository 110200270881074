import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { getAdminPaymentHistory } from '@network/Actions/Payment';
import {
  BillingDTO,
  PaymentHistoryDTO,
} from '@rootTypes/payments/dto/payment-history.dto';
import { translate } from '@module/Translate/Translate';
import P24 from '@images/registration/P24.png';
import Blik from '@images/registration/Blik.png';
import Card from '@images/card/card.png';
import Download from '@images/dashboard/download.png';
import { SERVER_URL, INVOICES_ENDPOINT } from '@/envConfig';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';

interface IProps {
  userId: number;
}

export default function PaymentsTable({ userId }: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paymentsList, setPaymentsList] = useState<any>();
  const getData = async () => {
    setIsLoading(true);

    getAdminPaymentHistory(userId)
      .then((data: PaymentHistoryDTO[]) => {
        const payments: any[] = [];
        data.map((payment) =>
          payment.billingHistory.flatMap((billing) => {
            payments.push({ orderId: payment.id, ...billing });
          }),
        );
        setPaymentsList(payments);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getData();
  }, []);

  const formatInvoiceUrl = (invoiceId: string): string => {
    return `${SERVER_URL}${INVOICES_ENDPOINT}${invoiceId}.pdf`;
  };

  const methodBody = (billing: BillingDTO) => {
    switch (billing.method) {
      case PaymentMethodEnum.BLIK:
        return <img src={Blik} alt="Blik" className="adminTool-payments_img" />;
      case PaymentMethodEnum.P24:
        return <img src={P24} alt="P24" className="adminTool-payments_img" />;
      case PaymentMethodEnum.CARD:
        return <img src={Card} alt="Card" className="adminTool-payments_img" />;
      default:
        return null;
    }
  };
  const getInvoiceBody = (billing: BillingDTO) => {
    if (billing?.invoiceNumber) {
      return (
        <a
          href={formatInvoiceUrl(billing.invoiceNumber.toString())}
          download="skitraj-document.pdf"
          target="_self"
        >
          <img
            className="itemBox_downloadPdfImg"
            src={Download}
            alt="Dokument PDF"
          />
        </a>
      );
    }
  };

  return (
    <DataTable
      value={paymentsList}
      responsiveLayout="scroll"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      dataKey="id"
      lazy
      loading={isLoading}
      stripedRows
      selectionPageOnly
      emptyMessage="No data found"
      className="datatable-responsive"
    >
      <Column field="orderId" header="Nr zamówienia" />
      <Column field="id" header={translate('adminTool.users.id')} />
      <Column field="date" header={translate('adminTool.payments.date')} />
      <Column
        field="billingPeroid"
        header={translate('adminTool.payments.billingPeriod')}
      />
      <Column
        field="method"
        header={translate('adminTool.payments.method')}
        body={methodBody}
      />
      <Column field="amount" header={translate('adminTool.payments.amount')} />
      <Column field="currency" header={translate('adminTool.payments.value')} />
      <Column
        field="invoiceNumber"
        header={translate('adminTool.payments.fv')}
        body={getInvoiceBody}
      />
    </DataTable>
  );
}
