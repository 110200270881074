import { FindStockDto } from '../../../../../../../../types/stocks/find-stock.dto';
import { StockStatus } from '../../../../../../../../types/stocks/stock-status.enum';
import P, { H3 } from '../../../../../../Component/Text/Text';
import { translate } from '../../../../../../Module/Translate/Translate';

interface IProps {
  box: FindStockDto;
  selectedBox: FindStockDto[];
  onBoxClick: (box: FindStockDto) => void;
  onBtnClick: (box: FindStockDto) => void;
}

const checkStatus = function (item: FindStockDto): boolean {
  if (
    item.stockStatus === StockStatus.STOCK_ATC ||
    item.stockStatus === StockStatus.STOCK_RFS ||
    item.stockStatus === StockStatus.STOCK_STTC
  ) {
    return true;
  }
  return false;
};

export const StorageBox = ({
  box,
  selectedBox,
  onBoxClick,
  onBtnClick,
}: IProps) => {
  return (
    <div key={box.id} className="col col-xs-12 col-md-6 col-xl-4">
      <div
        className={`hiding-place-box-element ${
          selectedBox.indexOf(box) >= 0 ? 'active' : ''
        } ${
          box.stockNumber === null ? 'hiding-place-box-element_no-data' : ''
        }`}
        onClick={() => onBoxClick(box)}
      >
        <div className="hiding-place-box-element_title-group">
          <P className="hiding-place-box-element_title">
            hidingPlaceBlock.hidingPlaceElement.singleBox.header
          </P>
          {box.stockNumber === null ? (
            <P className="hiding-place-box-element_number-no-data">-----</P>
          ) : (
            <H3 className="hiding-place-box-element_number">
              {box.stockNumber}
            </H3>
          )}
          {!box.description ? (
            <P className="hiding-place-box-element_text">
              hidingPlaceBlock.hidingPlaceElement.singleBox.commentPlaceholder
            </P>
          ) : (
            <P>{box.description}</P>
          )}
        </div>
        {checkStatus(box) &&
          (box.description ? (
            <button
              className="btn btn-small btn-100"
              disabled={
                !checkStatus(box) ||
                box.stockStatus === StockStatus.STOCK_RFS ||
                box.stockStatus === StockStatus.STOCK_STTC
              }
              onClick={() => onBtnClick(box)}
            >
              {translate(
                'hidingPlaceBlock.hidingPlaceElement.singleBox.editDescription',
              )}
            </button>
          ) : (
            <button
              className="btn btn-small btn-100"
              disabled={
                !checkStatus(box) ||
                box.stockStatus === StockStatus.STOCK_RFS ||
                box.stockStatus === StockStatus.STOCK_STTC
              }
              onClick={() => onBtnClick(box)}
            >
              {translate(
                'hidingPlaceBlock.hidingPlaceElement.singleBox.addDescription',
              )}
            </button>
          ))}
      </div>
    </div>
  );
};
