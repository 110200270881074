import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  activateAccountAction,
  checkUserIsActive,
} from '../../../../Network/Actions/ActivateAccount';
import getApiEndPoint from '../../../../Network/NetworkApiEndpoint';
import { H1, P } from '../../../../Component/Text/Text';
import { translate } from '../../../../Module/Translate/Translate';
import morcik from '../../../../Asset/images/morcikConfirm.png';
import './ConfirmEmailStep.scss';
import { useDispatch } from 'react-redux';
import { currentUserAction } from '../../../../Network/Actions/CurrentUser';

interface IProps {
  showError: (value: boolean) => void;
  visibleSpinner: (value: boolean) => void;
}

const ConfirmEmailStep = ({ showError, visibleSpinner }: IProps) => {
  const state = useLocation<{ email: string }>().state;
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(async () => {
      const token = localStorage.getItem('activationToken');
      if (token) {
        const { data } = await checkUserIsActive(token);
        if (data) {
          localStorage.removeItem('activationToken');
          localStorage.setItem('email', state ? state.toString() : '');
          history.push('/logowanie');
        }
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(currentUserAction());
  }, []);

  const onSubmit = () => {
    const url = getApiEndPoint('activate');
    if (state) {
      visibleSpinner(true);
      activateAccountAction(url, {
        email: state ? state.toString() : '',
      })
        .catch(() => showError(true))
        .finally(() => {
          visibleSpinner(false);
        });
    }
  };

  return (
    <>
      <div className="container confirm-email-step">
        <div className="confirm-email-step_content">
          <div>
            <img className="confirm-email-step_img" src={morcik} alt="morcik" />
          </div>
          <H1 className="confirm-email-step_title" data-e2e="confirmEmailTitle">
            {translate('confirmEmail.title')}
          </H1>
          <P>{translate('confirmEmail.contentDesktop')}</P>
          <P>{translate('confirmEmail.dontHaveMail')}</P>
          <div style={{ cursor: 'pointer' }} onClick={() => onSubmit()}>
            <P
              className="confirm-email-step_send-again"
              data-e2e="resendActicationCode"
            >
              {translate('confirmEmail.sendAgain')}
            </P>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEmailStep;
