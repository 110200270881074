import { useFormik } from 'formik';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { FindStorageStockDto } from '../../../../../../../types/stocks/find-storage-stock.dto';
import { StockStatus } from '../../../../../../../types/stocks/stock-status.enum';
import { H5 } from '../../../../../Component/Text/Text';
import { translate } from '../../../../../Module/Translate/Translate';
import { updateStockAction } from '../../../../../Network/Actions/Stocks';
import { Calendar } from 'primereact/calendar';
import './EditStorage.scss';
import unifiedFormatDate from '../../../../../Util/Dates';
import { User } from '../../../../../models/user.model';

interface Props {
  storage: FindStorageStockDto;
  editor: User;
  setRefresh: (refresh: boolean) => void;
}

const statuses = [
  { label: translate('adminTool.storage.isActive'), value: true },
  { label: translate('adminTool.storage.notActive'), value: false },
];

const deliveredStatuses = [
  {
    label: translate('adminTool.storage.readyForSent'),
    value: StockStatus.STOCK_RFS,
  },
  {
    label: translate('adminTool.storage.sendedToTheCustomer'),
    value: StockStatus.STOCK_STTC,
  },
  {
    label: translate('adminTool.storage.atTheCustomer'),
    value: StockStatus.STOCK_ATC,
  },
  {
    label: translate('adminTool.storage.requestForReturnFull'),
    value: StockStatus.STOCK_RFRF,
  },
  {
    label: translate('adminTool.storage.inWarehouse'),
    value: StockStatus.STOCK_IW,
  },
  {
    label: translate('adminTool.storage.requestForDeliver'),
    value: StockStatus.STOCK_RFD,
  },
  {
    label: translate('adminTool.storage.atTheCustomerFull'),
    value: StockStatus.STOCK_ATCF,
  },
  {
    label: translate('adminTool.storage.requestForReturnEmpty'),
    value: StockStatus.STOCK_RFRE,
  },
  {
    label: translate('adminTool.storage.returned'),
    value: StockStatus.STOCK_R,
  },
];

export default function EditStorage({ storage, editor, setRefresh }: Props) {
  const message: any = useRef(null);
  const formik = useFormik({
    initialValues: {
      isActive: storage ? storage.isActive : false,
      stockStatus: storage ? storage.stockStatus : null,
      stockNumber: storage.stockNumber ? storage.stockNumber : '',
      deliveryDate: storage.deliveryDate ? storage.deliveryDate : null,
    },
    validationSchema: Yup.object({
      stockNumber: Yup.string()
        .required()
        .matches(/^[A-Z0-9]+$/)
        .max(6),
    }),
    onSubmit: (values: any) => {
      if (values.stockStatus === StockStatus.STOCK_R) {
        values.isActive = false;
      }
      values.updatedBy = editor.email;
      updateStockAction(storage.id, values).then((res: any) => {
        if (res.response) {
          showMessage(
            'error',
            translate(`adminTool.storage.${res.response.data.error.message}`),
          );
        } else {
          setRefresh(true);
          showMessage('success', translate('adminTool.storage.updateMessage'));
        }
      });
    },
  });

  const showMessage = (type: string, content: string) => {
    message.current.show([
      {
        severity: type,
        summary: '',
        detail: content,
        sticky: false,
      },
    ]);
  };

  return (
    <div className="storages-main-container border-details">
      <div className="storages-header-container">
        <Messages ref={message} />
      </div>
      <div className="storages-data-container">
        <div className="storages-top-container">
          <div className="storages-info-left-container">
            <H5 className="storages-h5">adminTool.storage.email</H5>
            <p>{storage.userEmail}</p>
            <H5 className="storages-h5">adminTool.storage.orderId</H5>
            <p>{storage.orderId}</p>
            <H5 className="storages-h5">adminTool.storage.stockStatus</H5>
            <p>{translate(`adminTool.storage.${storage.stockStatus}`)}</p>
            <H5 className="storages-h5">adminTool.storage.returnDate</H5>
            <p>
              {storage.returnDate
                ? unifiedFormatDate(new Date(storage.returnDate))
                : translate('adminTool.storage.nothing')}
            </p>
          </div>
          <div className="storages-info-middle-container">
            <form className="storages-form" onSubmit={formik.handleSubmit}>
              <H5 className="storages-h5">adminTool.storage.stockId</H5>
              <InputText
                name="stockNumber"
                value={formik.values.stockNumber}
                onChange={formik.handleChange}
                disabled={storage.stockNumber ? true : false}
                placeholder={translate('adminTool.storage.stockId')}
              />
              <H5 className="storages-h5">adminTool.storage.status</H5>
              <Dropdown
                value={formik.values.isActive}
                options={statuses}
                className="type-dropdown"
                name="isActive"
                optionLabel="label"
                disabled={storage.stockNumber ? false : true}
                optionValue="value"
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              <H5 className="storages-h5">adminTool.storage.stockStatus</H5>
              <Dropdown
                value={formik.values.stockStatus}
                options={deliveredStatuses}
                className="type-dropdown"
                name="stockStatus"
                optionLabel="label"
                optionValue="value"
                disabled={
                  storage.stockNumber && storage.isActive ? false : true
                }
                onChange={formik.handleChange}
                itemTemplate={(option) => {
                  return <span>{option.label}</span>;
                }}
              />
              {formik.values.stockStatus === StockStatus.STOCK_RFS ? (
                <>
                  <H5 className="storages-h5">
                    adminTool.storage.deliveryDate
                  </H5>
                  <Calendar
                    id="deliveryDate"
                    name="deliveryDate"
                    value={
                      new Date(
                        formik.values.deliveryDate
                          ? formik.values.deliveryDate
                          : new Date(),
                      )
                    }
                    onChange={formik.handleChange}
                    dateFormat="mm.dd.yy"
                  />
                </>
              ) : (
                storage.deliveryDate && (
                  <p>{unifiedFormatDate(new Date(storage.deliveryDate))}</p>
                )
              )}
              <button
                type="submit"
                className={`submitForm invoice__button m-auto button_accept ${
                  formik.isValid && formik.values.stockNumber !== ''
                    ? storage.stockNumber
                      ? formik.values.isActive !== storage.isActive ||
                        formik.values.stockStatus !== storage.stockStatus
                        ? ''
                        : 'inactive'
                      : ''
                    : 'inactive'
                }`}
              >
                {translate('adminTool.storage.update')}
              </button>
            </form>
          </div>
          <div className="storages-info-right-container">
            <H5 className="storages-h5">adminTool.storage.productsList</H5>
            {storage.products.map((item: any) => {
              return <p key={item.id}>{`${item.quantity}x ${item.name}`}</p>;
            })}
          </div>
          <div className="storages-info-last-container">
            <H5 className="storages-h5">adminTool.storage.returnAddress</H5>
            <p>{`${storage.returnAddress?.street ?? ''}${
              storage.returnAddress?.street ? ',' : ''
            }`}</p>
            <p>
              {`${storage.returnAddress?.postalCode ?? ''} 
              ${storage.returnAddress?.city ?? ''}`}
            </p>
            <H5 className="storages-h5">adminTool.storage.deliveryAddress</H5>
            <p>{`${storage.deliveryAddress?.street ?? ''}${
              storage.deliveryAddress?.street ? ',' : ''
            } `}</p>
            <p>
              {`${storage.deliveryAddress?.postalCode ?? ''} 
              ${storage.deliveryAddress?.city ?? ''}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
