import { FunctionComponent, PropsWithChildren } from 'react';
import './ButtonSocial.style.scss';
import { ButtonSocialI } from '../Button/Button.type';

const ButtonHOC = (
  type: string,
): FunctionComponent<React.PropsWithChildren<ButtonSocialI>> =>
  function ButtonSocial(props: PropsWithChildren<ButtonSocialI>) {
    return (
      <button
        type="button"
        className="button_social pointer"
        {...props}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    );
  };

export const ButtonSocial = ButtonHOC('button_social');

export default ButtonSocial;
