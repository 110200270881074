import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import './ParentProduct.scss';
import { H5 } from '../../../../../../Component/Text/Text';
import { translate } from '../../../../../../Module/Translate/Translate';

import { useDispatch } from 'react-redux';
import ChildProducts from '../Child/ChildProducts';
import { FormFieldFormik } from '../../../../../../Component/FormFieldFormik/FormFieldFormik';
import { FindProductDto } from '../../../../../../../../types/products/find-product.dto';

interface Props {
  product: any;
  setProductPackage: any;
  updateProductPackage: any;
  removeProductPackage: any;
  getProductPackagesAction: any;
  products: FindProductDto[];
}

export default function ParentProduct({
  product,
  setProductPackage,
  updateProductPackage,
  removeProductPackage,
  getProductPackagesAction,
  products,
}: Props) {
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [quantity, setQuantity] = useState<number>(0);
  const [refresh, setRefresh] = useState(true);
  const [productsFiltered, setProductsFiltered] = useState([]);
  const parentProductId = product.id;
  const [productPackages, setProductPackages] = useState([]);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!selectedProduct || quantity <= 0) {
      return;
    }
    const payload = createPackage(
      quantity,
      selectedProduct.id,
      parentProductId,
    );
    setProductPackage(payload);
    setSelectedProduct(null);
    setQuantity(0);
    setRefresh(true);
  };
  const handleRemove = (packageId: number, id: number) => {
    removeProductPackage(packageId, id);
    setSelectedProduct(null);
    setQuantity(0);
    setRefresh(true);
  };
  const handleSelect = (product: any) => {
    setSelectedProduct(product);
  };

  const handleChangeQuantity = (quantity: number) => {
    setQuantity(quantity);
  };

  const handleEdit = (
    quantity: number,
    productId: number,
    packageId: number,
  ) => {
    if (quantity > 0) {
      const payload = { quantity: quantity };
      updateProductPackage(payload, productId, packageId);
      setSelectedProduct(null);
      setQuantity(0);
      setRefresh(true);
    }
  };
  const createPackage = (
    quantity: number,
    childId: number,
    productId: number,
  ) => {
    const productPackage = {
      quantity: quantity,
      child: childId,
      product: productId,
    };
    return productPackage;
  };

  useEffect(() => {
    let data;
    const fetchData = async (): Promise<any> => {
      data = await dispatch(getProductPackagesAction(parentProductId));
      const getProducts = (): [] => {
        const arrOfProducts: any = [];
        products.forEach(function (product) {
          if (
            product.group !== 'packageBox' &&
            product.id !== parentProductId
          ) {
            arrOfProducts.push({
              label: product.code + ', ' + product.type,
              value: product,
            });
          }
        });
        return arrOfProducts;
      };
      setProductsFiltered(getProducts());
      setProductPackages(data);
    };
    fetchData();
    setRefresh(false);
  }, [refresh]);

  if (refresh) {
    return <div>{translate('adminTool.products.loading')}</div>;
  }

  return (
    <div className="product-info-container">
      <br />
      <H5 className="product-h5">
        {translate('adminTool.products.parentProductTitle')}
      </H5>
      <br />
      <Dropdown
        value={selectedProduct}
        options={productsFiltered}
        className="type-dropdown"
        name="products"
        optionLabel="label"
        optionValue="value"
        onChange={(e) => handleSelect(e.target.value)}
        placeholder={translate('adminTool.products.parentProductPlaceholder')}
        itemTemplate={(option) => {
          return <span>{option.label}</span>;
        }}
      />
      <FormFieldFormik
        label={translate('adminTool.products.quantity')}
        type="number"
        name="quantity"
        onChange={(e: any) => handleChangeQuantity(e.target.value)}
        value={quantity}
      />
      <br />
      <button
        type="button"
        onClick={() => handleSubmit()}
        disabled={selectedProduct ? false : true}
        className={`submitForm invoice__button m-auto button_accept`}
      >
        {translate('adminTool.products.add')}
      </button>
      <br />
      <ChildProducts
        productPackages={productPackages}
        handleRemove={handleRemove}
        handleEdit={handleEdit}
      />
    </div>
  );
}
