import unifiedFormatDate from './Dates';

interface IProps {
  arrayLength: number;
  holidays: Date[];
  defaultOrderClosingTime: number;
  newDate?: Date; // used to test
}

export const selectedDaysArray = ({
  arrayLength,
  holidays,
  defaultOrderClosingTime,
  newDate, // used to test
}: IProps) => {
  const dateNow = newDate ? newDate : new Date();
  const array: Date[] = [];
  const dayIndexFriday = 5;
  const dayIndexSaturday = 6;
  const dayIndexSunday = 0;

  while (array.length !== arrayLength + 1) {
    if (dateNow.getDay() === dayIndexFriday) {
      dateNow.setDate(dateNow.getDate() + 3);
    } else if (dateNow.getDay() === dayIndexSaturday) {
      dateNow.setDate(dateNow.getDate() + 2);
    } else {
      dateNow.setDate(dateNow.getDate() + 1);
    }

    const isHoliday = holidays.find(
      (holiday) => unifiedFormatDate(holiday) === unifiedFormatDate(dateNow),
    );
    if (!isHoliday && dateNow.getDay() !== 0 && dateNow.getDay() !== 6) {
      array.push(new Date(dateNow));
    }
  }
  const hours = dateNow.getHours();
  const getDayIndex = new Date(newDate ? newDate : Date.now()).getDay();

  if (
    hours >= defaultOrderClosingTime ||
    getDayIndex === dayIndexSaturday ||
    getDayIndex === dayIndexSunday
  ) {
    array.shift();
  } else {
    array.pop();
  }

  return array;
};
