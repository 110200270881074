import React from 'react';
import './CheckThePackage.scss';
import { Link } from 'react-router-dom';
import { translate } from '../../../../Module/Translate/Translate';
import { ImageTypeEnum } from '../../../../../../types/product-images/image-type.enum';
import { H3 } from '@component/Text/Text';

function CheckThePackage({ data }: any) {
  return (
    <div className="package">
      <H3 className="package_title">
        {data.name.split(' ')[0]}
        <span>{data.name.split(' ')[1]}</span>
      </H3>
      <ul>
        {data.packages.map((item: any, idx: number) => {
          return <li key={idx}>{`${item.quantity} x ${item.child.name}`}</li>;
        })}
      </ul>
      <div className="package_image">
        <img
          src={
            data.images.find((image: any) => image.type === ImageTypeEnum.offer)
              ?.url
          }
          alt={data.name}
        />
      </div>
      <div className="package_info">
        <p className="price">
          {data.weekPrice} {translate('default.currency')}
        </p>
        <p className="perweek"> {translate('rentPage.packageItem.perWeek')}</p>
        <p className="perweek_text">{translate('rentPage.packageItem.vat')}</p>
        <p className="nextPayment">
          <span className="nextPayment_price">
            {data.promotionPrice} {translate('default.currency')}{' '}
          </span>
          <span className="nextPayment_next">
            {translate('rentPage.packageItem.nextPayment')}
          </span>
        </p>
      </div>
      <Link to={`/wynajem/${data.pathUrl}`}>
        <button className="package_btn">
          {translate('rentPage.packageItem.buttonText')}
        </button>
      </Link>
    </div>
  );
}

export default CheckThePackage;
