import React, { useState } from 'react';
import './VerifyReturnBoxesForm.scss';
import { useSelector } from 'react-redux';
import { FindOrderDto } from '../../../../types/orders/find-order.dto';
import { FindRentStockDto } from '../../../../types/stocks/find-rent-stock.dto';
import { StockStatus } from '../../../../types/stocks/stock-status.enum';
import { UpdateStockDto } from '../../../../types/stocks/update-stock.dto';
import P, { H3 } from '../../Component/Text/Text';
import { translate } from '../../Module/Translate/Translate';
import {
  requestCourierFromRentRFR,
  requestCourierFromMultiStorageClientIW,
  requestCourierFromStorageRFRF,
  requestCourierFromMultiStorageWarehouseATCF,
} from '../../Network/Actions/Stocks';
import { RootState } from '../../store';
import { UserState } from '../../Type/Custom';
import Modal from '../../Component/Modal/Modal';
import AdminContactForm from '../AdminContactForm/AdminContactForm';
import { StockType } from '../../../../types/stocks/stock-type.enum';
import AddressInformation from '../../Component/AddressInformation/AddressInformation';
import unifiedFormatDate from '../../Util/Dates';
interface Props {
  setVerifyReturnModal: any;
  editedBoxes?: UpdateStockDto[];
  order?: FindOrderDto;
  setShowReturnModal: any;
  stock?: FindRentStockDto;
  date: Date;
  setCourierOrdered?: any;
  visibleSpinner: (value: boolean) => void;
}
const VerifyReturnBoxesForm: React.FC<React.PropsWithChildren<Props>> = ({
  setVerifyReturnModal,
  editedBoxes,
  setShowReturnModal,
  order,
  stock,
  date,
  setCourierOrdered,
  visibleSpinner,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const { user }: UserState = useSelector<RootState, UserState>(
    ({ user }) => user,
  );

  const pickupTimeFrom = '06:00';
  const pickupTimeTo = '18:00';

  const handleSubmit = () => {
    visibleSpinner(true);

    //applies only to storage
    if (editedBoxes) {
      //phase 2
      if (editedBoxes[0].stockStatus === StockStatus.STOCK_ATC) {
        requestCourierFromStorageRFRF(order?.id ? order.id : '', {
          activeDate: date,
          expirationDate: null,
          pickupTimeFrom: pickupTimeFrom,
          pickupTimeTo: pickupTimeTo,
          phone: user.phone,
        })
          .then(() => {
            setVerifyReturnModal(false);
            setCourierOrdered(true);
          })
          .catch(() => {
            setShowConfirm(true);
          })
          .finally(() => visibleSpinner(false));
      }
      //phase 3 or 4
      const stocks: any[] = [];
      editedBoxes.forEach((stock: any) => {
        stocks.push(+stock.id);
      });

      if (editedBoxes[0].stockStatus === StockStatus.STOCK_IW) {
        requestCourierFromMultiStorageClientIW({
          stocks: stocks,
          activeDate: date,
          endDate: null,
          pickupTimeFrom: pickupTimeFrom,
          pickupTimeTo: pickupTimeTo,
          phone: user.phone,
          deliveryAddress: editedBoxes[0].deliveryAddress,
          returnedAddress: editedBoxes[0].returnAddress,
        })
          .then(() => {
            setVerifyReturnModal(false);
            setCourierOrdered(true);
          })
          .catch(() => {
            setShowConfirm(true);
          })
          .finally(() => visibleSpinner(false));
      } else if (editedBoxes[0].stockStatus === StockStatus.STOCK_ATCF) {
        requestCourierFromMultiStorageWarehouseATCF({
          stocks: stocks,
          activeDate: date,
          endDate: null,
          pickupTimeFrom: pickupTimeFrom,
          pickupTimeTo: pickupTimeTo,
          phone: user.phone,
        })
          .then(() => {
            setVerifyReturnModal(false);
            setCourierOrdered(true);
          })
          .catch(() => {
            setShowConfirm(true);
          })
          .finally(() => visibleSpinner(false));
      }
    }
    //applies only to rent
    if (stock) {
      requestCourierFromRentRFR(stock.orderId ? stock.orderId : '', {
        activeDate: date,
        expirationDate: null,
        pickupTimeFrom: pickupTimeFrom,
        pickupTimeTo: pickupTimeTo,
        phone: user.phone,
      })
        .then(() => {
          setVerifyReturnModal(false);
          setCourierOrdered(true);
        })
        .catch(() => {
          setShowConfirm(true);
        })
        .finally(() => visibleSpinner(false));
    }
  };

  const storageStocks = order?.stocks.filter(
    (stock) => stock.stockType === StockType.STORAGE,
  );

  return (
    <>
      <Modal showModal={showConfirm} setShowModal={setShowConfirm}>
        <AdminContactForm
          setShowConfirm={setShowConfirm}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <div className="verify-return">
        <div className="verify-return_title-group">
          <H3>verifyReturnBoxesForm.title</H3>
          <P>verifyReturnBoxesForm.subtitle</P>
        </div>
        {order && (
          <div className="verify-return_group">
            <P className="group-title">verifyReturnBoxesForm.order</P>
            <P className="accent-text">nr {order?.id}</P>
          </div>
        )}
        {stock && (
          <div className="verify-return_group">
            <P className="group-title">verifyReturnBoxesForm.order</P>
            <P className="accent-text">nr {stock.orderId}</P>
          </div>
        )}
        <div className="verify-return_group">
          <P className="group-title">verifyReturnBoxesForm.deliveryDate</P>
          <P className="accent-text">{unifiedFormatDate(date)}</P>
        </div>
        {order && storageStocks && (
          <div className="verify-return_group">
            <P className="group-title">verifyReturnBoxesForm.address</P>
            <AddressInformation item={storageStocks[0].returnAddress} />
            <P className="accent-text">{user.phone}</P>
          </div>
        )}
        {stock && (
          <div className="verify-return_group">
            <P className="group-title">verifyReturnBoxesForm.address</P>
            <AddressInformation item={stock.returnAddress} />
            <P className="accent-text">{user.phone}</P>
          </div>
        )}
        <button
          className="btn btn-100"
          onClick={() => {
            handleSubmit();
          }}
        >
          {translate('verifyReturnBoxesForm.accept')}
        </button>
        <button
          className="btn btn-dark btn-100"
          onClick={() => {
            setShowReturnModal(true);
            setVerifyReturnModal(false);
          }}
        >
          {translate('verifyReturnBoxesForm.edit')}
        </button>
      </div>
    </>
  );
};
export default VerifyReturnBoxesForm;
