import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { DefaultSettingsState } from '../../../../Type/Custom';
import EditDefaultSettings from './components/EditDefaultSettings/EditDefaultSettings';
import { getDefaultSettingsAction } from '../../../../Network/Actions/DefaultSettings';
import { translate } from '../../../../Module/Translate/Translate';
import { UserProps } from '../../interfaces/interfaces';
import { formatDateToLocalTime } from '../../../../Util/Dates';

export default function DefaultSettings({ user }: UserProps) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  useEffect(() => {
    getData();
    setRefresh(false);
  }, [refresh]);

  const getData = async () => {
    setLoading(true);
    await dispatch(getDefaultSettingsAction());
    setLoading(false);
  };

  const rowExpansionTemplate = (data: any) => {
    return (
      <EditDefaultSettings
        defaultSettings={data}
        setRefresh={setRefresh}
        editor={user}
      />
    );
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'isActive') {
      return rowData[item.field]
        ? translate('adminTool.rents.isActive')
        : translate('adminTool.rents.notActive');
    }
  };

  const getEnumTranslation = (rowData: any, item: any) => {
    return translate(`adminTool.defaultSettings.enums.${rowData[item.field]}`);
  };

  return (
    <div className="products-table-container">
      <DataTable
        value={defaultSettings}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        lazy
        loading={loading}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="id" sortable header="Id" />
        <Column
          field="type"
          sortable
          header={translate('adminTool.defaultSettings.name')}
          body={getEnumTranslation}
        />
        <Column
          field="limit"
          header={translate('adminTool.defaultSettings.value')}
        />
        <Column field="isActive" sortable header="State" body={labelChecker} />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.defaultSettings.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.defaultSettings.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.defaultSettings.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
