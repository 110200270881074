import React, { useState } from 'react';
import './AddDescriptionModal.scss';
import { translate } from '../../Module/Translate/Translate';
import P from '../../Component/Text/Text';
import { FindStockDto } from '../../../../types/stocks/find-stock.dto';
import { updateStockAction } from '../../Network/Actions/Stocks';
import unifiedFormatDate from '../../Util/Dates';

interface Props {
  selectedBox?: FindStockDto;
  setShowDescriptionModal: any;
  setRefresh: any;
  description: string;
  visibleSpinner: (value: boolean) => void;
  setShowError: (value: boolean) => void;
}

const AddDescriptionModal: React.FC<React.PropsWithChildren<Props>> = ({
  selectedBox,
  setShowDescriptionModal,
  setRefresh,
  description,
  visibleSpinner,
  setShowError,
}) => {
  const [desc, setDesc] = useState(description);
  const setDescription = () => {
    if (selectedBox) {
      visibleSpinner(true);
      updateStockAction(selectedBox?.id, { description: desc })
        .then(() => {
          setShowDescriptionModal(false);
          setRefresh(true);
        })
        .catch(() => {
          setShowDescriptionModal(false);
          setShowError(true);
        })
        .finally(() => {
          visibleSpinner(false);
        });
    }
  };

  return (
    <div className="add-description-modal">
      <P className="title-nr-box-orange">{selectedBox?.stockNumber}</P>
      <P className="subtitle">hidingPlaceBlock.statusReturn</P>
      <P className="text">
        {translate(
          `hidingPlaceBlock.hidingPlaceElement.orderTitle${selectedBox?.stockStatus}`,
        )}
      </P>
      <P className="subtitle">hidingPlaceBlock.timeReturn</P>
      {selectedBox && (
        <P className="text">{`${unifiedFormatDate(
          new Date(selectedBox?.startDate),
        )}-${unifiedFormatDate(new Date(selectedBox?.endDate))}`}</P>
      )}

      <div className="new-description-wrapper">
        <label className="d-sm-block label--black " htmlFor="description">
          {translate('hidingPlaceBlock.description')}
        </label>
        <textarea
          id="description"
          className="custom-textarea form-control"
          value={desc}
          onChange={(e: any) => setDesc(e.target.value)}
          maxLength={200}
        ></textarea>

        <button
          disabled={desc && desc.length > 0 ? false : true}
          onClick={setDescription}
          className="btn btn-100"
        >
          {translate('hidingPlaceBlock.save')}
        </button>
      </div>
    </div>
  );
};

export default AddDescriptionModal;
