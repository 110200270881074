import P24 from '@images/registration/P24.png';
import Blik from '@images/registration/Blik.png';
import Card from '@images/card/card.png';
import { PaymentMethodEnum } from '@rootTypes/payments/enums/payment-method.enum';
import { translate } from '@module/Translate/Translate';

export const paymentData = {
  paymentMethod: [
    {
      id: PaymentMethodEnum.P24,
      title: translate('paymentsType.P24Title'),
      subtitle: translate('paymentsType.P24SubTitle'),
      img: [P24],
    },
    {
      id: PaymentMethodEnum.BLIK,
      title: translate('paymentsType.blikTitle'),
      subtitle: translate('paymentsType.blikSubTitle'),
      img: [Blik],
    },
    {
      id: PaymentMethodEnum.CARD,
      title: translate('paymentsType.cardTitle'),
      subtitle: '',
      img: [Card],
    },
  ],
};
