import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '@rootTypes/default-settings/default-settings-type.enum';
import { DefaultSettingsDto } from '@rootTypes/default-settings/default-settings.dto';

export const findDefaultCountForStorage = (
  defaultSettings: DefaultSettingsDto[],
) => {
  return (
    defaultSettings.find(
      (setting) =>
        setting.type === DefaultSettingsTypeEnum.defaultCountForStorage,
    )?.limit || UndefinedSettingsType.undefinedCountForStorage
  );
};
