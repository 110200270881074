import React from 'react';
import Footer from '../../Component/Footer/Footer';
import Navigation from '../../Component/Navigation/Navigation';
import MainTemplateI from './MainTemplate.type';
import './MainTemplate.scss';
import CookiesInfo from '../CookiesInfo/CookiesInfo';

const MainTemplate: React.FC<React.PropsWithChildren<MainTemplateI>> = ({
  children,
  customMobile = false,
  customFooter = false,
}) => {
  return (
    <div className="main-template">
      <Navigation customMobile={customMobile} />
      {children}
      <div
        className={`footer-container ${
          customMobile ? 'footer-container--custom' : ''
        }`}
      >
        <CookiesInfo />
        <Footer customFooter={customFooter} />
      </div>
    </div>
  );
};

export default MainTemplate;
