import React, { useEffect, useState } from 'react';
import logo from '@images/logo.png';
import './LoginUser.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { H2 } from '@component/Text/Text';
import LoginForm from '@form/LoginForm/LoginForm';
import { translate } from '@module/Translate/Translate';
import { activateAccountAction } from '@network/Actions/ActivateAccount';
import { currentUserAction } from '@network/Actions/CurrentUser';
import {
  loginAction,
  externalUserLoginAction,
  logoutAction,
  handleAppleLogin,
} from '@network/Actions/Login';
import getApiEndPoint from '@network/NetworkApiEndpoint';
import { AuthValidation } from '@rootTypes/auth/auth-validation.enum';
import { RootState } from '@src/store';
import { UserState } from '@type/Custom';
import { checkIsAllowed } from '@util/UserValidation';
import { updateUserGroupByTokenAction } from '@network/Actions/PushNotifications';
import { FacebookGoogleEvents } from '@rootTypes/auth/facebook-google-auth.enum';

export const ExternalLoginContext = React.createContext({});

interface IProps {
  changeAuthentication: any;
  showError: (value: boolean) => void;
}

const LoginUser = ({ changeAuthentication, showError }: IProps) => {
  const history = useHistory();
  const userData = useSelector<RootState, UserState>((state) => state.user);
  const userLogin = useSelector<RootState, any>((state) => state.auth);
  const dispatch = useDispatch();
  const [validation, setValidation] = useState('');

  const [email, setEmail] = useState('');

  async function onLoginUser(values: any, actions: any) {
    setEmail(values.email);
    const url = getApiEndPoint('login');
    new Promise(() => {
      dispatch(loginAction(url, values, error));
    }).then(async (res) => {
      const PushNotificationToken: string | null = localStorage.getItem(
        'PushNotificationToken',
      );
      if (PushNotificationToken && res) {
        return await updateUserGroupByTokenAction(PushNotificationToken);
      }
    });
  }

  useEffect(() => {
    const handleMobileMessage = (e: any) => {
      try {
        const fromWebView = JSON.parse(e.data);
        if (
          fromWebView.evetnType === FacebookGoogleEvents.FACEBOOK_LOGIN &&
          fromWebView.token !== ''
        )
          onLoginExternalUser(fromWebView.token, 'facebook');
        if (
          fromWebView.evetnType === FacebookGoogleEvents.GOOGLE_LOGIN &&
          fromWebView.token !== ''
        )
          onLoginExternalUser(fromWebView.token, 'google');
        if (fromWebView.evetnType === FacebookGoogleEvents.APPLE_LOGIN) {
          const appleResponse = JSON.parse(fromWebView.response);
          if (appleResponse.user) {
            dispatch(
              handleAppleLogin(appleResponse.id_token, appleResponse.user),
            );
          } else {
            dispatch(handleAppleLogin(appleResponse.id_token));
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (
      //@ts-ignore
      window.isWebViewClient === true
    ) {
      document.addEventListener('message', handleMobileMessage);
    } else if (
      //@ts-ignore
      window.window.isWebViewIOSClient === true
    ) {
      //@ts-ignore
      window.addEventListener('message', handleMobileMessage);
    }
    return () => {
      if (
        //@ts-ignore
        window.isWebViewClient === true
      ) {
        document.removeEventListener('message', handleMobileMessage);
      } else if (
        //@ts-ignore
        window.window.isWebViewIOSClient === true
      ) {
        //@ts-ignore
        window.removeEventListener('message', handleMobileMessage);
      }
    };
  }, []);

  const onLoginExternalUser = async (
    token: string,
    endpoint: string,
  ): Promise<any> => {
    const url = getApiEndPoint(endpoint);
    const data: any = await dispatch(
      externalUserLoginAction(token, url, error),
    );
    if (data === 'Success') {
      await dispatch(currentUserAction(error));
    } else {
      //its handled in use effect
    }
  };

  const onLogoutUser = () => {
    dispatch(logoutAction());
  };

  const isEmpty = (obj: object | any) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    const user: any = userData.user;
    const error: any = userData.error || userLogin.error;

    if (userLogin.accessToken.msg === 'Success' && !userData.loading) {
      if (localStorage.getItem('email')) {
        localStorage.removeItem('email');
      }
      dispatch(currentUserAction(error));
    }

    if (!isEmpty(user) && user.isActive) {
      if (!user.isRODOConfirmed || !user.isPrivacyConfirmed) {
        history.push('/policyConfirm');
        return;
      }

      if (checkIsAllowed(user)) {
        history.push('/admin-tool');
        return;
      }

      history.push('/');
    } else if (error) {
      if (email.length > 0) {
        if (userLogin.error) {
          switch (userLogin.error.message) {
            case AuthValidation.NOT_ACTIVE: {
              const url = getApiEndPoint('activate');
              activateAccountAction(url, { email: email }).then(() => {
                history.push('/confirmEmail', email);
              });
              break;
            }
            default:
              setValidation(userLogin.error);
          }
        }
      }
    }
  }, [userData, userLogin]);

  const error = () => {
    showError(true);
  };

  return (
    <React.Fragment>
      <ExternalLoginContext.Provider value={onLoginExternalUser}>
        <div className="login-panel d-flex row">
          <div className="login-panel_left-container col-md-6">
            <div className="dividing-line d-block d-md-none" />

            <div className="logo-wrapper">
              <img className="logo-wrapper_logo" src={logo} alt="logo" />
            </div>
            <H2 className="login-column_label text-center pb-2 pb-md-3 pb-lg-4">
              {translate('registrationUser.loginPanel.title')}
            </H2>
            <label
              htmlFor="#"
              className="d-none d-md-block text-primary font-weight-bold"
            >
              &nbsp;
            </label>

            <LoginForm
              userData={userData}
              onSubmit={onLoginUser}
              onLogout={onLogoutUser}
              validation={validation}
            />
          </div>

          <div className="login-panel_right-container d-none d-md-block col-md-6">
            <H2 className="login-column_label text-center pb-2 pb-md-3 pb-lg-4">
              {translate('registrationUser.registrationPanel.title')}
            </H2>
            <label
              htmlFor="#"
              className="d-none d-md-block text-primary font-weight-bold"
            >
              &nbsp;
            </label>

            <div className="form-group">
              <button
                className="registration-column_button btn btn-dark btn-100 w-100 py-3"
                data-e2e="register"
                onClick={() => changeAuthentication('register')}
              >
                {translate('registrationUser.registrationPanel.register')}
              </button>
            </div>

            <div className="registration-column_subtitle">
              {translate('registrationUser.registrationPanel.possibleOnly')}
            </div>

            <div className="text-center registration-column_title">
              {translate('registrationUser.registrationPanel.rememberAbout')}
            </div>
          </div>
        </div>
      </ExternalLoginContext.Provider>
    </React.Fragment>
  );
};

export default LoginUser;
