import React from 'react';
import AdminPanelNavigation from './components/AdminPanelNavigation/AdminPanelNavigation';
import MainTemplateI from './AdminPanelTemplate.type';

const AdminPanelTemplate: React.FC<React.PropsWithChildren<MainTemplateI>> = ({
  children,
}) => {
  return (
    <>
      <div style={{ height: '100vh', overflow: 'hidden' }}>
        <AdminPanelNavigation />
        {children}
      </div>
    </>
  );
};

export default AdminPanelTemplate;
