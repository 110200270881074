import React, { useEffect, useState } from 'react';
import Product from '../Product/Product';
import './ProductList.scss';
import { DefaultSettingsState, ProductsState } from '../../../../Type/Custom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { ProductGroup } from '../../../../../../types/products/product-group.enum';
import { getProductsAction } from '../../../../Network/Actions/Products';
import {
  DefaultSettingsTypeEnum,
  UndefinedSettingsType,
} from '../../../../../../types/default-settings/default-settings-type.enum';
import { BoxesSort } from '../../../../Util/BoxesSort';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';

export default function ProductList({ url = 'sklep' }: any) {
  const dispatch = useDispatch();
  const { products }: ProductsState = useSelector<RootState, ProductsState>(
    ({ product }) => product,
  );
  const [showError, setShowError] = useState(false);
  const { defaultSettings }: DefaultSettingsState = useSelector<
    RootState,
    DefaultSettingsState
  >(({ defaultSettings }) => defaultSettings);

  const setting =
    defaultSettings.find(
      (elem) =>
        elem.type === DefaultSettingsTypeEnum.defaultMinValueForWarehouseStock,
    )?.limit || UndefinedSettingsType.undefinedMinValueForWarehouseStock;

  useEffect(() => {
    dispatch(
      getProductsAction(
        true,
        ProductGroup.shop,
        undefined,
        setting,
        setShowError,
      ),
    );
  }, []);

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.serverError')}
          activeContactLink={false}
        />
      </Modal>
      <div className="shop-productList">
        <div className="row shop-productList_list">
          {BoxesSort(products).map((product: any) => (
            <div
              key={product.id}
              className="col-6 col-md-4 shop-productList_item-container"
            >
              <div className="shop-productList_item">
                <Product productData={product} url={url} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
