import { translate } from '@module/Translate/Translate';
import React, { useState } from 'react';

export default function PasswordConfirmRegisterInput({
  formik,
  passwordError,
}: {
  formik: any;
  passwordError: any;
}) {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const inputConfirmTypeCondition = showConfirmPassword ? 'text' : 'password';

  return (
    <div className="mb-lg-3">
      <label
        htmlFor="password_confirmation"
        className={`d-sm-block font-weight-bold label--orange ${
          passwordError ? 'error-label' : ''
        }`}
      >
        {translate('registrationForm.conformPassword')}
      </label>
      <div className="form-password">
        <input
          type={inputConfirmTypeCondition}
          name="repassword"
          className={`custom-input ${passwordError ? 'error-input' : ''}`}
          id="repassword"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={translate('registrationForm.conformPassword')}
          value={formik.values.repassword}
        />
        <div
          className="form-password_button"
          onMouseDown={() => setShowConfirmPassword(true)}
          onTouchStart={() => setShowConfirmPassword(true)}
          onMouseUp={() => setShowConfirmPassword(false)}
          onMouseLeave={() => setShowConfirmPassword(false)}
          onTouchEnd={() => setShowConfirmPassword(false)}
        >
          {translate('registrationForm.show')}
        </div>
      </div>
    </div>
  );
}
