import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { resetPasswordAction } from '../../../../../Network/Actions/ResetPassword';
import morcik from '../../../../../Asset/images/morcikConfirm.png';
import { H1, P } from '../../../../../Component/Text/Text';
import { translate } from '../../../../../Module/Translate/Translate';
import { FormFieldFormik } from '../../../../../Component/FormFieldFormik/FormFieldFormik';
import { isEmpty } from '../../../../../Util/isEmpty';
import './VerifyEmail.scss';
import logo from '../../../../../Asset/images/logo.png';
import { textFormater } from '@util/TextFormater';

interface Props {
  verify: any;
}

const VerifyEmail: FC<React.PropsWithChildren<Props>> = ({ verify }) => {
  const [emailValid, setEmailValid] = useState(true);
  const [isVerified, setVeryfied] = useState(false);

  const onWrongEmail = (error: any) => {
    setEmailValid(false);
    if (error.response) {
      formik.setFieldError('email', error.response.data.message.message);
    }
  };

  const onSucces = () => {
    setVeryfied(true);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Niepoprawny email')
        .required('To pole jest wymagane'),
    }),
    onSubmit: (values: any) => {
      setEmailValid(true);
      resetPasswordAction({ email: values.email }, onWrongEmail, onSucces);
    },
  });

  const emailError = (function () {
    if (!emailValid) {
      return <div className="verify_error">Podany e-mail nie istnieje</div>;
    }
    if (formik.touched.email && formik.errors.email) {
      return <div className="verify_error">{formik.errors.email}</div>;
    }
    return null;
  })();

  return (
    <>
      <>
        <form className="verify container" onSubmit={formik.handleSubmit}>
          {isVerified ? (
            <>
              <img
                className="verify_img d-flex mx-auto"
                src={morcik}
                alt="morcik"
              />
              <P className="verify_title">
                {translate('verifyEmail.checkEmail')}
              </P>
              <P className="verify_text">
                {textFormater(translate('verifyEmail.emailSentInfo'))}
              </P>
            </>
          ) : (
            <>
              <img
                className="mobile-main-content_logo d-block d-md-none mx-auto"
                src={logo}
                alt="logo"
              />
              <H1 className="verify_title">verifyEmail.emailHeader</H1>
              <P className="verify_text">verifyEmail.emailText</P>
              <div className="new-email-wrapper" data-e2e="email">
                <FormFieldFormik
                  type="text"
                  name="email"
                  label={translate('registrationForm.email')}
                  placeholder="E-mail"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  fieldError={emailError}
                />
              </div>
              <div className="verify_error">{formik.errors.email}</div>
              <button
                className="verify_btn w-100 btn btn--orange"
                type="submit"
                data-e2e="submit"
                disabled={!(formik.isValid && isEmpty(formik.values))}
              >
                {translate('verifyEmail.buttonSubmit')}
              </button>
            </>
          )}
        </form>
      </>
    </>
  );
};

export default VerifyEmail;
