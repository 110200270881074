import Social from '@component/Social/Social';
import P, { H1 } from '@component/Text/Text';
import { translate } from '@module/Translate/Translate';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '@images/logo.png';
import Morcik_sad from '@images/morcik_sad.png';
import './NewsletterUnsubscribe.scss';

const NewsletterUnsubscribe = () => {
  return (
    <>
      <div className="newsletter-unsubscribe-section">
        <div className="newsletter-unsubscribe_container container content padding20">
          <div className="row">
            <div className="col text">
              <div className="row">
                <Link className="m-auto" to={'/'}>
                  <img
                    src={Logo}
                    alt={translate('newsletter.alt')}
                    className="newsletter-unsubscribe_logo logo"
                  />
                </Link>
              </div>
              <div className="row">
                <img
                  src={Morcik_sad}
                  alt={translate('newsletter.alt')}
                  className="newsletter-unsubscribe_img"
                />
              </div>

              <H1 className="newsletter-unsubscribe_title">
                newsletterReject.title
              </H1>
              <P className="newsletter-unsubscribe_subtitle">
                newsletterReject.content.info
                <br />
                newsletterReject.content.sorry
              </P>

              <Social />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsletterUnsubscribe;
