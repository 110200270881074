import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  COMPLAINTS_FAIL,
  COMPLAINTS_REQUEST,
  COMPLAINTS_SUCCESS,
} from './Types';
import { http } from '../NetworkHTTP';
import { CreateComplaintDto } from '@rootTypes/complaints/create-complaint.dto';
import { SearchComplaintDTO } from '@rootTypes/complaints/search-complaint.dto';
import { UpdateComplaintDto } from '@rootTypes/complaints/update-complaint.dto';
import { RootState } from '@src/store';

export const getComplaintsAction = (
  params: any,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
  ): Promise<void> => {
    try {
      dispatch({
        type: COMPLAINTS_REQUEST,
      });

      const { data } = await http.get(
        `complaints?take=${params.rows}&skip=${params.first}${
          params.sortField != '' || params.sortOrder
            ? `&sortField=${params.sortField}&sortOrder=${params.sortOrder}`
            : ``
        }`,
      );

      dispatch({
        type: COMPLAINTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMPLAINTS_FAIL,
        payload: 'Nie udało się pobrać reklamacji.',
      });
    }
  };
};

export const getComplaints = async (params: {
  skip: number;
  take: number;
  sortField?: string;
  order?: number | null;
}) => {
  try {
    const { data } = await http.get(
      `complaints?take=${params.take}&skip=${params.skip}${
        params.sortField !== '' || params.order
          ? `&sortField=${params.sortField}&sortOrder=${params.order}`
          : ``
      }`,
    );
    return data;
  } catch (error) {
    console.error({
      from: 'getComplaints',
      error,
    });
  }
};

export const findComplaints = async (params: SearchComplaintDTO) => {
  try {
    const { data } = await http.get(
      `complaints/search?take=${params.take}&skip=${params.skip}&searchedValue=${params.searchedValue}&sortField=${params.sortField}&sortOrder=${params.sortOrder}`,
    );
    return data;
  } catch (error) {
    console.error({
      from: 'findComplaints',
      error,
    });
  }
};

export async function addComplaintAction(comaplaint: CreateComplaintDto) {
  return await http.post(`complaints`, comaplaint);
}

export async function editComplaintAction(
  id: number,
  comaplaint: UpdateComplaintDto,
) {
  return await http.patch(`complaints/${id}`, comaplaint);
}
