/**
 *
 * Calculates number of seconds remaining from cookies
 *
 * @returns  Number of seconds remining to countdown
 */
export const checkForSmsCookie = (): number => {
  const documentCookie = document.cookie
    .split(';')
    .filter((element: string) => {
      if (element.includes('smsTimeout')) {
        return element;
      }
    });
  if (documentCookie.length !== 0) {
    const remainingTime = documentCookie[0].split('=')[1];

    if (isNaN(+remainingTime)) return 0;
    else {
      const cookieTime = Math.ceil((+remainingTime - Date.now()) / 1000);
      if (cookieTime >= 0) return cookieTime;
      else return 0;
    }
  } else {
    return 0;
  }
};
