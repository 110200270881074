import React, { CSSProperties } from 'react';
import './FormField.scss';
interface Props {
  label: any;
  isFullWidth?: boolean;
  onChange?: any;
  name?: any;
  required?: boolean;
  pattern?: string;
  placeholder?: string;
  title?: string;
  type?: string;
}
const inputStyle: CSSProperties = {
  width: '100%',
};
export const FormField: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  isFullWidth,
  onChange,
  name,
  required,
  pattern,
  placeholder,
  title,
  type = 'text',
}) => {
  return (
    <div className="form-field-wrapper">
      <label htmlFor="name">{label}</label>
      {isFullWidth ? (
        <input
          type={type}
          value={name}
          required={required || false}
          pattern={pattern}
          placeholder={placeholder}
          name={name}
          style={inputStyle}
          onChange={onChange}
          title={title}
          className="custom-input"
        />
      ) : (
        <input
          type={type}
          value={name}
          name={name}
          onChange={onChange}
          pattern={pattern}
          placeholder={placeholder}
          title={title}
          className="custom-input"
        />
      )}
    </div>
  );
};
export default FormField;
