import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FindSellStockDto } from '../../../../../../types/stocks/find-sell-stock.dto';
import { StockStatus } from '../../../../../../types/stocks/stock-status.enum';
import { StockType } from '../../../../../../types/stocks/stock-type.enum';
import { translate } from '../../../../Module/Translate/Translate';
import { getAllStocksAction } from '../../../../Network/Actions/Stocks';
import { RootState } from '../../../../store';
import { StocksAdminState } from '../../../../Type/Custom';
import { formatDateToLocalTime } from '../../../../Util/Dates';
import useDebounce from '../../../../Util/Debounse';
import { UserProps } from '../../interfaces/interfaces';
import EditSells from './components/EditSells';

const LIMIT_ON_PAGE = 7;

export default function Sells({ user }: UserProps) {
  const dispatch = useDispatch();
  const [expandedRows, setExpandedRows] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mount, setMount] = useState(true);
  const { data } = useSelector<RootState, StocksAdminState>(
    (state) => state.adminStocks,
  );
  const [searched, setSearched] = useState('');

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: LIMIT_ON_PAGE,
    sortField: '',
    sortOrder: null,
  });

  useEffect(() => {
    if (mount) {
      getData(lazyParams, searched);
      setMount(false);
    } else if (refresh) {
      getData(lazyParams, searched);
      setRefresh(false);
    } else {
      getData(lazyParams, searched);
    }
  }, [refresh, lazyParams, useDebounce(searched, 500)]);

  const getData = (params: any, search: string) => {
    setLoading(true);
    dispatch(getAllStocksAction(params, search, StockType.SELL));
    setLoading(false);
  };

  const labelChecker = (rowData: any, item: any) => {
    if (item.field === 'isActive') {
      return rowData[item.field]
        ? translate('adminTool.sells.isActive')
        : translate('adminTool.sells.notActive');
    }
    if (item.field === 'stockStatus') {
      return Object.keys(StockStatus)[
        Object.values(StockStatus).indexOf(rowData[item.field])
      ];
    }
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };

  const rowExpansionTemplate = (data: FindSellStockDto) => {
    return <EditSells sell={data} setRefresh={setRefresh} editor={user} />;
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div></div>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={searched}
            onChange={(e: any) => setSearched(e.target.value)}
            placeholder={translate('adminTool.sells.search')}
          />
        </span>
      </div>
    );
  };

  return (
    <div className="products-table-container">
      <DataTable
        value={data.items}
        responsiveLayout="scroll"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        dataKey="id"
        paginator
        header={renderHeader}
        lazy
        loading={loading}
        first={lazyParams.first}
        totalRecords={data.meta.totalItems}
        stripedRows
        selectionPageOnly
        emptyMessage={translate('adminTool.common.nodata')}
        className="datatable-responsive"
        rows={LIMIT_ON_PAGE}
        expandedRows={expandedRows}
        onRowToggle={(e: any) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        onPage={onPage}
        onSort={onSort}
        sortOrder={lazyParams.sortOrder}
        sortField={lazyParams.sortField}
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="id" sortable header={translate('adminTool.sells.id')} />
        <Column
          field="stockNumber"
          sortable
          header={translate('adminTool.sells.stockId')}
        />
        <Column field="userEmail" header={translate('adminTool.sells.email')} />
        <Column
          field="orderId"
          sortable
          header={translate('adminTool.sells.orderId')}
        />
        <Column
          field="isActive"
          sortable
          header={translate('adminTool.sells.status')}
          body={labelChecker}
        />
        <Column
          field="stockStatus"
          sortable
          header={translate('adminTool.sells.stockStatus')}
          body={labelChecker}
        />
        <Column
          field="createdAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.sells.createdAt')}
        />
        <Column
          field="updatedAt"
          sortable
          body={formatDateToLocalTime}
          header={translate('adminTool.sells.updatedAt')}
        />
        <Column
          field="updatedBy"
          sortable
          header={translate('adminTool.sells.updatedBy')}
        />
      </DataTable>
    </div>
  );
}
