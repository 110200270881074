import React from 'react';
import { P, H1 } from '../Text/Text';
import './Shoutout.scss';

const Shoutout = () => (
  <div className="shoutout-container">
    <H1 className="content-title">{'shoutout.title'}</H1>
    <div className="content-text">
      <P>
        {'shoutout.content.ohno'}
        <br className="line-break d-md-none" />
        {'shoutout.content.allDelicacies'}
        <br />
        {'shoutout.content.timefor'}
      </P>
      <P>
        {'shoutout.content.knowFuture'}
        <br className="line-break d-md-none" />
        {'shoutout.content.subscribe'}
        <br />
        {'shoutout.content.start'}
      </P>
    </div>
  </div>
);

export default Shoutout;
