import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RootState } from '@/src/store';
import { AnyAction } from 'redux';
import { Address } from '@models/user.model';
import { ChangePasswordDto } from '@rootTypes/users/change-password.dto';
import { http } from '@network/NetworkHTTP';
import { requestPermission } from '@src/firebaseInit';
import {
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAIL,
} from './Types';
import { loginEvent } from '@src/GTM/GTM';
import { UserState } from '@type/Custom';

export const currentUserAction = (
  onError?: () => void,
): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>,
    getState,
  ): Promise<void> => {
    try {
      dispatch({
        type: CURRENT_USER_REQUEST,
      });
      const state = getState();
      const userState: UserState = state.user;
      const { data: user } = await http.get('users/current');

      if (userState.user?.id !== user.id) loginEvent(user.id);
      if (user.isCompanyAccount) {
        const { data: companyDetails } = await http.get(
          `users/${user.id}/company-details`,
        );
        user.companyDetails = companyDetails;
      } else {
        const { data: personalDetails } = await http.get(
          `users/${user.id}/personal-details`,
        );
        user.personalDetails = personalDetails;
      }
      const { data: roles } = await http.get(`users/${user.id}/roles/main`);

      user.roles = roles;

      const { data: addresses } = await http.get(`users/${user.id}/addresses`);

      user.addresses = addresses;

      await requestPermission(user.email);

      dispatch({
        type: CURRENT_USER_SUCCESS,
        payload: user,
      });
    } catch (err) {
      onError && onError();
      await requestPermission();
      dispatch({
        type: CURRENT_USER_FAIL,
        payload: 'Zaloguj się jeszcze raz, Twoja sesja wygasła.',
      });
    }
  };
};

export async function editUserCompanyDetailsAction(
  userId: number,
  detailsId: any,
  details: any,
) {
  return await http.patch(
    `users/${userId}/company-details/${detailsId}`,
    details,
  );
}

export async function editUserPersonalDetailsAction(
  userId: number,
  detailsId: any,
  details: any,
) {
  return await http.patch(
    `users/${userId}/personal-details/${detailsId}`,
    details,
  );
}

export async function changeUserPasswordAction(
  userId: number,
  changePasswordDTO: ChangePasswordDto,
  onError: (error: any) => void,
  onSucces: () => void,
) {
  return await http
    .post(`users/${userId}/change-password`, changePasswordDTO)
    .then((res) => {
      if (res.status === 201) onSucces();
    })
    .catch((error) => onError(error?.response?.data?.response?.error));
}

export async function createAddressAction(userId: number, address: Address) {
  return await http.post(`users/${userId}/addresses`, address);
}

export async function deleteAddressAction(userId: number, adddresId: number) {
  return await http.delete(`users/${userId}/addresses/${adddresId}`);
}

export async function editAddressAction(
  userId: number,
  id: number,
  adddres: Address,
) {
  return await http.patch(`users/${userId}/addresses/${id}`, adddres);
}

export const getUserSettingsAction = async (userId: number) => {
  return await http.get(`users/${userId}/user-settings`);
};

export async function getAddressAction(userId: number) {
  return await http.get(`users/${userId}/addresses`);
}
