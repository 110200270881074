import React from 'react';
import msmall from '../../../../../../Asset/images/card/msmall.png';
import closeicon from '../../../../../../Asset/images/card/closeicon.png';
import './ModalDelete.scss';
import arroBack from '../../../../../../Asset/images/shop/arrow-back.png';

interface IData {
  name: string;
  number: number;
  month: number;
  year: number;
  CVV: number;
  checked: boolean;
  id: number;
}

interface IProps {
  data: IData;
  closeModal: () => void;
  deleteCard: () => void;
}

const ModalDelete = ({ data, closeModal, deleteCard }: IProps) => {
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    deleteCard();
    closeModal();
  };

  return (
    <div className="modalwrapperdelete">
      <div className="delete">
        <img
          src={arroBack}
          alt="arrow back"
          className="arrowBack"
          onClick={() => closeModal()}
        />

        <h2 className="delete-title">Usunąć kartę?</h2>
        <img
          src={closeicon}
          alt="close"
          className="closeIcon"
          onClick={() => closeModal()}
        />
        <p className="message">
          Jeśli usuniesz tą kartę, nie pojawi się ona już w zapisanych kartach
          płatniczych do Twoich przyszyłch zamówień.
        </p>
        <p className="nameCard">{data.name}</p>
        <p className="number">{data.number}</p>
        <p className="date">
          {data.month}/{data.year}
        </p>
        <img className="mlogo" src={msmall} alt="msmall" />
        <button className="deletebtn" onClick={(e) => handleSubmit(e)}>
          Usuń kartę
        </button>
      </div>
    </div>
  );
};

export default ModalDelete;
