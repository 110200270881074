import React from 'react';
import './Counter.scss';

interface IProps {
  stepValue: number;
  onCounterIncreaseClick: (value: number) => void;
  onCounterDecreaseClick: (value: number) => void;
  counterValue: number;
  extraClass?: string;
}

export default function Counter({
  stepValue,
  onCounterIncreaseClick,
  onCounterDecreaseClick,
  counterValue,
  extraClass,
}: IProps) {
  return (
    <>
      <div className={`order-counter ${extraClass && extraClass}`}>
        <button
          className="order-counter_btn"
          onClick={() => onCounterDecreaseClick(counterValue - stepValue)}
          disabled={counterValue === 0}
        >
          <svg
            width="18"
            height="4"
            viewBox="0 0 18 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ismobile"
          >
            <path d="M17.6561 3.5H0.656067V0.5H17.6561V3.5Z" fill="white" />
          </svg>
          <span className="isdesktop">-</span>
        </button>
        <p className="order-counter_value">{counterValue}</p>
        <button
          className="order-counter_btn"
          onClick={() => onCounterIncreaseClick(counterValue + stepValue)}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ismobile"
          >
            <path
              d="M10.7402 7.21698H17.6561V10.1887H10.7402V18H7.5719V10.1887H0.656067V7.21698H7.5719V0H10.7402V7.21698Z"
              fill="white"
            />
          </svg>
          <span className="isdesktop">+</span>
        </button>
      </div>
    </>
  );
}
