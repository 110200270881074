import { useState } from 'react';
import FormField from '../../Component/FormField/FormField';
import './EditAddressForm.scss';
import { translate } from '../../Module/Translate/Translate';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../Type/Custom';
import { isEmpty } from '../../Util/isEmpty';

export const EditAddressForm = ({
  item,
  setShowModal,
  createNewAddress,
  editAddress,
  removeAddress,
  editItem = false,
}: any) => {
  const { user } = useSelector<RootState, UserState>((state) => state.user);

  const [formData, setFormData] = useState({
    name: item
      ? item.name
      : '' || user.isCompanyAccount
      ? user.companyDetails?.name
      : user.personalDetails?.firstName || '',
    restOfName: item
      ? item.restOfName
      : '' || user.isCompanyAccount
      ? user.companyDetails?.restOfName
      : user.personalDetails?.lastName || '',
    street: item ? item.street : '',
    postalCode: item ? item.postalCode : '',
    city: item ? item.city : '',
    isDefault: item ? item.isDefault : false,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    editItem ? editAddress(formData) : createNewAddress(formData);
    if (setShowModal) {
      setShowModal(false);
    }
  };

  return (
    <form className="edit-address-form" onSubmit={handleSubmit}>
      <p className="modalTitle">
        {editItem
          ? translate('addressBlock.editAddress')
          : translate('addressBlock.addNewAddress')}
      </p>
      <div className="formPair">
        <FormField
          name={formData.name}
          label={translate('addressBlock.name')}
          onChange={(e: any) =>
            setFormData({ ...formData, name: e.target.value })
          }
        />
        <div className="formPair__space" />
        <FormField
          name={formData.restOfName || ''}
          label={translate('addressBlock.restOfName')}
          onChange={(e: any) =>
            setFormData({ ...formData, restOfName: e.target.value })
          }
        />
      </div>
      <FormField
        name={formData.street}
        isFullWidth
        label={translate('addressBlock.street')}
        onChange={(e: any) =>
          setFormData({ ...formData, street: e.target.value })
        }
      />
      <div className="formPair">
        <FormField
          name={formData.postalCode}
          label={translate('addressBlock.postalCode')}
          onChange={(e: any) =>
            setFormData({ ...formData, postalCode: e.target.value })
          }
          pattern={'^[0-9]{2}-[0-9]{3}$'}
          placeholder={'XX-XXX'}
          title={translate('errors.postalCodeError')}
        />
        <div className="formPair__space" />
        <FormField
          name={formData.city}
          label={translate('addressBlock.city')}
          onChange={(e: any) =>
            setFormData({ ...formData, city: e.target.value })
          }
        />
      </div>

      <div className="submitWrapper">
        {editItem ? (
          <>
            <button
              type="submit"
              className="btn submitForm w-100"
              disabled={
                user.isCompanyAccount
                  ? !isEmpty(formData, 'restOfName')
                  : !isEmpty(formData)
              }
            >
              {translate('addressBlock.saveChanges')}
            </button>
            <button
              className="btn btn-dark submitForm d-md-none w-100"
              onClick={removeAddress}
            >
              {translate('addressBlock.deleteAddress')}
            </button>
          </>
        ) : (
          <button
            type="submit"
            className="btn submitForm w-100"
            disabled={
              user.isCompanyAccount
                ? !isEmpty(formData, 'restOfName')
                : !isEmpty(formData)
            }
          >
            {translate('addressBlock.addNewAddress')}
          </button>
        )}
      </div>
    </form>
  );
};

export default EditAddressForm;
