import React, { useEffect, useState } from 'react';
import './MessagesBlock.scss';
import MessagesList from './components/MessagesList/MessagesList';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFICATIONS_PAGE_SIZE } from '@/envConfig';
import Modal from '@component/Modal/Modal';
import { H5 } from '@component/Text/Text';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import {
  countUnreadNotificationsAction,
  getNotifcationsAction,
  removeNotifications,
} from '@network/Actions/Notifications';
import { RootState } from '@src/store';
import { UserState, NotificationsState } from '@type/Custom';

function MessagesBlock() {
  const dispatch = useDispatch();
  const { user } = useSelector<RootState, UserState>((state) => state.user);
  const { notifications, totalCount } = useSelector<
    RootState,
    NotificationsState
  >((state) => state.notifications);

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    dispatch(getNotifcationsAction(user.id, 0, NOTIFICATIONS_PAGE_SIZE, error));
    dispatch(removeNotifications());
    dispatch(countUnreadNotificationsAction(user.id));
  }, []);

  const getNextPageOfNotifications = () => {
    dispatch(
      getNotifcationsAction(
        user.id,
        notifications.length,
        NOTIFICATIONS_PAGE_SIZE,
        error,
      ),
    );
  };

  const error = () => {
    setShowError(true);
  };

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>
      <div className="message-block">
        {notifications.length === 0 && (
          <H5 className="message-no_content">
            {translate('messagesBlock.noMessages')}
          </H5>
        )}
        <MessagesList
          data={notifications}
          user={user}
          setShowError={(value) => setShowError(value)}
        />
        <button
          hidden={
            totalCount === notifications.length - 1 ||
            notifications.length === 0
          }
          type="button"
          className="message-block_btn btn btn-small btn-dark"
          onClick={getNextPageOfNotifications}
        >
          {translate('messagesBlock.seeYouLater')}
        </button>
      </div>
    </>
  );
}

export default MessagesBlock;
