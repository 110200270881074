import React from 'react';
import { translate } from '../../Module/Translate/Translate';
import { H3 } from '../../Component/Text/Text';
import './EmailAddressForm.scss';
interface Props {
  setActivePanel: any;
  setShowModal: (value: boolean) => void;
  data: string;
}

const EmailAddress = ({ setShowModal, setActivePanel, data }: Props) => {
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setShowModal(false);
    setActivePanel({
      displayName: 'Kontakt',
      template: 'contact',
      href: 'panel-klienta-kontakt',
    });
  };

  return (
    <div className="modalwrapper">
      <H3 className="modal-title">{translate('emailForm.title')}</H3>
      <p className="text">{translate('emailForm.subTitle')}</p>
      <p className="text-bold">{data}</p>
      <p className="text">{translate('emailForm.subTitle2')}</p>
      <div>
        <button onClick={handleSubmit} className="btn btn-100">
          {translate('emailForm.formButton')}
        </button>
      </div>
    </div>
  );
};

export default EmailAddress;
