import React, { useEffect, useState } from 'react';
import './ReclamationForm.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getUserOrdersAction } from '@network/Actions/Orders';
import { RootState } from '@src/store';
import { OrdersState } from '@type/Custom';
import { ServiceType } from '@rootTypes/complaints/service-type.enum';
import { addComplaintAction } from '@network/Actions/Complaints';
import Modal from '@component/Modal/Modal';
import AdminContactForm from '@form/AdminContactForm/AdminContactForm';
import { translate } from '@module/Translate/Translate';
import P, { H5 } from '@component/Text/Text';
import CustomSelect from '@component/CustomSelect/CustomSelect';
import { isEmpty } from '@util/isEmpty';

interface Props {
  onHandleSubmit: () => void;
  user: any;
  setSpinner: (value: boolean) => void;
}

export const ReclamationForm = ({
  onHandleSubmit,
  user,
  setSpinner,
}: Props) => {
  const [service, setService] = useState<ServiceType>(ServiceType.rent);
  const [showError, setShowError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserOrdersAction(user.id, () => setShowError(true)));
  }, []);

  const { orders } = useSelector<RootState, OrdersState>(
    (state) => state.orders,
  );

  const ordersByType = orders.filter(({ orderType }) =>
    orderType.includes(service),
  );

  const formik = useFormik({
    initialValues: {
      username: user.isCompanyAccount
        ? user.companyDetails
          ? `${user.companyDetails?.name} ${
              user.companyDetails?.restOfName
                ? user.companyDetails?.restOfName
                : ''
            }`
          : ''
        : user.personalDetails
        ? `${user.personalDetails?.firstName} ${user.personalDetails?.lastName}`
        : '',
      email: user ? user.email : '',
      phone: user ? user.phone : parseInt(''),
      service: service,
      orderNumber: '',
      description: '',
    },

    validationSchema: Yup.object({
      username: Yup.string().required(translate('errors.fieldRequired')),
      email: Yup.string().required(translate('errors.fieldRequired')),
      phone: Yup.number().required(translate('errors.fieldRequired')),
      service: Yup.string().required(translate('errors.fieldRequired')),
      orderNumber: Yup.string().required(translate('errors.fieldRequired')),
      description: Yup.string().required(translate('errors.fieldRequired')),
    }),
    onSubmit: (values) => {
      setSpinner(true);
      addComplaintAction(values)
        .then(() => {
          onHandleSubmit();
          formik.resetForm();
        })
        .catch(() => {
          setShowError(true);
        })
        .finally(() => {
          setSpinner(false);
        });
    },
  });

  const onRadioClick = () => {
    formik.values.orderNumber = '';
  };

  return (
    <>
      <Modal showModal={showError} setShowModal={setShowError}>
        <AdminContactForm
          setShowConfirm={setShowError}
          message={translate('errors.badRequest')}
        />
      </Modal>

      <form className="reclamation-form" onSubmit={formik.handleSubmit}>
        <H5 className="reclamation-form_title">reclamationForm.selectForm</H5>
        <P className="reclamation-form_subtitle container-header">
          reclamationForm.selectService
        </P>

        <div className="reclamation-form_group">
          <label htmlFor="first_name" className="label--orange">
            {translate('reclamationForm.nameSurname')}
          </label>

          <input
            type="text"
            name="username"
            className="custom-input"
            id="username"
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
          />
          {formik.errors.username && (
            <div className="error-feedback">
              {translate('errors.fieldRequired')}
            </div>
          )}
        </div>

        <div className="reclamation-form_input-group">
          <div className="reclamation-form_group small-input">
            <label
              htmlFor="email"
              className={`label--orange ${
                formik.touched.email && formik.errors.email ? 'error-label' : ''
              }`}
            >
              {translate('reclamationForm.email')}
            </label>
            <input
              type="email"
              name="email"
              className="custom-input"
              id="email"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.errors.email && (
              <div className="error-feedback">
                {translate('errors.fieldRequired')}
              </div>
            )}
          </div>

          <div className="reclamation-form_group small-input">
            <label htmlFor="phone" className="label--orange">
              {translate('reclamationForm.phoneNumber')}
            </label>
            <input
              type="number"
              name="phone"
              className="custom-input"
              id="phone"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.errors.phone && (
              <div className="error-feedback">
                {translate('errors.fieldRequired')}
              </div>
            )}
          </div>
        </div>

        <div className="complaint-form_group">
          <H5 className="group-title">reclamationForm.selectOrders</H5>
          <P className="group-subtitle">reclamationForm.selectAService</P>
          <div role="group" className="button-group">
            <input
              type="radio"
              name="service"
              onClick={onRadioClick}
              onChange={() => {
                formik.setFieldValue('service', ServiceType.storage);
                setService(ServiceType.storage);
              }}
              id="storage"
              value={ServiceType.storage}
            />
            <label htmlFor="storage" className="button-group_button first">
              <H5>reclamationForm.storage</H5>
            </label>
            <input
              type="radio"
              name="service"
              onClick={onRadioClick}
              onChange={() => {
                formik.setFieldValue('service', ServiceType.rent);
                setService(ServiceType.rent);
              }}
              id="rent"
              value={ServiceType.rent}
            />
            <label htmlFor="rent" className="button-group_button">
              <H5>reclamationForm.rent</H5>
            </label>
            <input
              type="radio"
              name="service"
              onClick={onRadioClick}
              onChange={() => {
                formik.setFieldValue('service', ServiceType.sell);
                setService(ServiceType.sell);
              }}
              id="shop"
              value={ServiceType.sell}
            />
            <label htmlFor="shop" className="button-group_button">
              <H5>reclamationForm.store</H5>
            </label>
            {formik.errors.service && (
              <div className="error-feedback">
                {translate('errors.fieldRequired')}
              </div>
            )}
          </div>

          <div className="reclamation-form_select">
            <label htmlFor="orderNumber" className="label--orange">
              {translate('reclamationForm.orderNo')}
            </label>
            {orders && orders.length >= 0 && (
              <CustomSelect
                value={formik.values.orderNumber}
                options={ordersByType.map((order) => order.id)}
                onChange={(value: any) => {
                  formik.setFieldValue('orderNumber', value);
                }}
                placeholder={translate('reclamationForm.orderNo')}
              />
            )}
            {formik.errors.orderNumber && (
              <div className="error-feedback">
                {translate('errors.fieldRequired')}
              </div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="message" className="label--orange">
            {translate('reclamationForm.descriptionComplaint')}
          </label>
          <textarea
            name="description"
            className="custom-input"
            id="description"
            autoComplete="off"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
          />
          {formik.errors.orderNumber && (
            <div className="error-feedback">
              {translate('errors.fieldRequired')}
            </div>
          )}
        </div>

        <button
          type="submit"
          className="btn reclamation-form_btn"
          disabled={formik.isValid && isEmpty(formik.values) ? false : true}
        >
          {translate('reclamationForm.send')}
        </button>
      </form>
    </>
  );
};
